import React from 'react';

import SKILL_EXPERIENCES from '../../../../../helpers/constants/Skills/Experience';

export const OVERALL_EXPERIENCE = {
  level80: {
    title: `${SKILL_EXPERIENCES.LEVEL_80.label} —`,
    color: SKILL_EXPERIENCES.LEVEL_80.color,
  },
  confident: {
    title: `${SKILL_EXPERIENCES.CONFIDENT.label} —`,
    color: SKILL_EXPERIENCES.CONFIDENT.color,
  },
  commercialUse: {
    title: `${SKILL_EXPERIENCES.COMMERCIAL_USE.label} —`,
    color: SKILL_EXPERIENCES.COMMERCIAL_USE.color,
  },
  petProjects: {
    title: `${SKILL_EXPERIENCES.PET_PROJECTS.label} —`,
    color: SKILL_EXPERIENCES.PET_PROJECTS.color,
  },
};

export interface Emotion {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
}

export const MINIMUM_USERS_KNOWN_BY = 5;
