import * as Actions from '../../constants/VacancyPage/Recommendations';
import moment from 'moment';
import Subtypes from '../../constants/__common__/Subtypes';
import processCommonEvent from '../../utils/processCommonEvent';
import _ from 'lodash';
import {LIMIT} from '../../constants/__common__/PaginationConstants';

const InitialState = {
  recommendationsTable: {
    showArchived: false,
    showInactive: false,
    isLoading: false,
    errorMessage: '',
    objects: []
  },
  recommendationModal: {
    show: false,
    isLoading: false,
    errorMessage: '',
    selected: {
      applicant: '',
      recommendedBy: [''],
      timestamp: moment().format(),
      active: true,
      email: '',
      vacancyId: 0,
      statuses: []
    }
  },
  emailConfirmationModal: {
    show: false,
    isLoading: false
  },
  usersEmail: 'untitle@email.here'
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.GET_RECOMMENDATIONS:
      return getRecommendations(action, state);

    case Actions.ADD_RECOMMENDATION:
    case Actions.EDIT_RECOMMENDATION:
      return processRecommendationModalEvents(action, state);

    case Actions.RECOMMENDATION_MODAL:
      return recommendationModalToggle(action, state);

    case Actions.EMAIL_CONFIRMATION_MODAL:
      return emailConformationModalToggle(action, state);

    case Actions.SEND_EMAIL_TO_APPLICANT:
      return processEmailConfirmationModalEvents(action, state);

    case Actions.ONLY_ACTIVE_RECOMMENDATIONS_VISIBILITY_TOGGLE:
      return {
        ...state,
        recommendationsTable: {
          ...state.recommendationsTable,
          showInactive: !state.recommendationsTable.showInactive,
          showArchived: !state.recommendationsTable.showInactive
        }
      };

    case Actions.UPDATE_RECOMMENDATIONS_LIST_WITH_NEW_ONE:
      const newRecommendation = {
        active: action.payload.recommendation.active,
        applicant: action.payload.recommendation.applicant,
        email: action.payload.recommendation.email,
        affiliate: action.payload.position.affiliate.name,
        affiliateId: action.payload.position.affiliate.affiliateId,
        position: action.payload.position.position,
        recommendationId: action.payload.recommendation.recommendationId,
        recommendedBy: action.payload.recommendation.recommendedBy,
        statuses: [{statusId: 1, timestamp: action.payload.recommendation.date}],
        vacancyId: action.payload.position.vacancyId
      };
      const needToPickSliceWithoutLastOne = state.recommendationsTable.objects.length === LIMIT;
      const existedObjects = needToPickSliceWithoutLastOne ?
        state.recommendationsTable.objects.slice(0, -1) :
        state.recommendationsTable.objects;

      return {
        ...state,
        recommendationsTable: {
          ...state.recommendationsTable,
          objects: [newRecommendation, ...existedObjects]
        }
      };

    case Actions.UPDATE_RECOMMENDATIONS_LIST_WITH_UPDATED:
      const clonedObjectsToUpdate = _.cloneDeep(state.recommendationsTable.objects);
      const recommendationToUpdateIndex = clonedObjectsToUpdate.indexOf(clonedObjectsToUpdate
        .find(recommendation => recommendation.recommendationId === action.payload.recommendationId));

      clonedObjectsToUpdate[recommendationToUpdateIndex] = {
        active: action.payload.active,
        applicant: action.payload.applicant,
        email: action.payload.email,
        affiliate: action.payload.affiliate,
        affiliateId: action.payload.affiliateId,
        position: action.payload.position,
        recommendationId: action.payload.recommendationId,
        recommendedBy: action.payload.recommendedBy,
        statuses: action.payload.statuses,
        vacancyId: action.payload.vacancyId,
        archived: action.payload.archived
      };

      return {
        ...state,
        recommendationsTable: {...state.recommendationsTable, objects: clonedObjectsToUpdate}
      };

    case Actions.WRITE_USERS_EMAIL_AS_RECOMMENDERS_EMAIL:
      return {
        ...state,
        usersEmail: action.payload,
        recommendationModal: {
          ...state.recommendationModal,
          recommendedBy: [action.payload]
        }
      };

    case Actions.CHANGE_SELECTED_RECOMMENDATION:
      let selectedRecommendation;

      if (action.payload.recommendationId) {
        selectedRecommendation = {
          ...action.payload,
          archived: Boolean(action.payload.statuses.find(status => status.statusId === 3)),
          recommendedBy: action.payload.recommendedBy && action.payload.recommendedBy.map(user => user.email)
        }
      } else {
        selectedRecommendation = {
          ...state.recommendationModal.selected,
          recommendedBy: [state.usersEmail]
        }
      }

      return {
        ...state,
        recommendationModal: {
          ...state.recommendationModal,
          selected: selectedRecommendation
        }
      };

    case Actions.CHANGE_POSITION_TO_RECOMMEND:
      return {
        ...state,
        recommendationModal: {
          ...state.recommendationModal,
          selected: {
            ...state.recommendationModal.selected,
            vacancyId: action.payload
          }
        }
      };

    case Actions.RECOMMENDATIONS_INITIATE:
      return InitialState;

    default:
      return state;
  }
}

const getRecommendations = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, recommendationsTable: {...state.recommendationsTable, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        recommendationsTable: {
          ...state.recommendationsTable,
          isLoading: false,
          errorMessage: '',
          objects: action.payload.objects
        }
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        recommendationsTable: {...state.recommendationsTable, isLoading: false, errorMessage: action.payload}
      };
    default:
      return state;
  }
};

const recommendationModalToggle = (action, state) => processCommonEvent.modalToggle('recommendationModal', {
  ...InitialState.recommendationModal,
  selected: {
    ...InitialState.recommendationModal.selected,
    recommendedBy: [state.usersEmail]
  }
})(action, state);

const emailConformationModalToggle = (action, state) => processCommonEvent.modalToggle('emailConfirmationModal', InitialState.emailConfirmationModal)(action, state);

const processRecommendationModalEvents = (action, state) => processCommonEvent.request('recommendationModal')(action, state);

const processEmailConfirmationModalEvents = (action, state) => processCommonEvent.request('emailConfirmationModal')(action, state);

export default {
  InitialState,
  reducer
}
