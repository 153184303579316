import React from 'react';
import classNames from 'classnames/bind';
import S from './styles.module.css';
import TableHeaderCell from './Head/Cell';
import TableHeader from './Head';
import TableBody from './Body';
import TableRow from './Row';
import BodyCell from './Body/Cell';
import TablePagination from './Pagination';
import {
  TableComponentPreloader,
  TableComponentPagination
} from './tableTypes';

const cx = classNames.bind(S);

interface TableComponentProps {
  highlighted?: boolean;
  preloader?: TableComponentPreloader;
  className?: string;
  style?: React.CSSProperties;
  hidden?: boolean;
  pagination?: TableComponentPagination;
}

class Table extends React.Component<TableComponentProps, {}> {
  public static Header = TableHeader;
  public static Body = TableBody;
  public static Row = TableRow;
  public static Cell = BodyCell;
  public static HeaderCell = TableHeaderCell;
  public static Pagination = TablePagination;

  render(): React.ReactNode {
    const {
      highlighted,
      preloader = { isLoading: false },
      className,
      style,
      children,
      hidden,
      pagination
    } = this.props;
    const classNames = cx(
      {
        'qs-table': true,
        'qs-table--highlighted': highlighted,
        'qs-table--is-loading': preloader.isLoading,
        'qs-table--hidden': hidden
      },
      className
    );

    return (
      <div className={cx('qs-table__wrapper')}>
        <table className={classNames} style={style}>
          {React.Children.map(
            children as React.ReactElement,
            (child: React.ReactElement) =>
              React.cloneElement(child, { preloader })
          )}
        </table>
        {pagination ? <TablePagination {...pagination} /> : null}
      </div>
    );
  }
}

export default Table;
