import React, { useEffect } from 'react';

type Handler = React.Dispatch<React.SetStateAction<boolean> | MouseEvent | TouchEvent>;

export const useOnClickOutside = (ref: React.MutableRefObject<any>, handler: Handler) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ ref, handler ],
  );
};
