/* eslint-disable import/prefer-default-export */
import Subtypes from '../../V1/constants/__common__/Subtypes';
import { TablesRedux } from '../../helpers/types/_common';

export const handleTableDataAction = (state: any, action: any) => {
  switch (action.subType) {
    case Subtypes.Request.Loading:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName as keyof TablesRedux],
          loading: true,
        },
      };
    case Subtypes.Request.Success:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName as keyof TablesRedux],
          ...action.payload,
          meta: {
            limit: action.payload.meta.limit,
            offset: action.payload.meta.offset,
            totalCount: action.payload.meta.totalCount,
          },
          loading: false,
        },
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName as keyof TablesRedux],
          loading: false,
        },
      };
    default:
      return state;
  }
};
