import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Input, Popover } from 'antd';
import { positionValues } from 'react-custom-scrollbars';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross18.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { BaseUserOutput } from 'api/User/types';
import { searchByAnyItem } from 'api/Common/requests';
import { SearchItemPage } from 'api/Common/types';
import { PartnerCardOutput } from 'api/Partners/types';
import { BaseEntityOutput } from 'api/Entities/types';
import { INCREASED_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';

import GeneralSearchSelectedPartners from './components/GeneralSearchSelectedPartners/GeneralSearchSelectedPartners';
import GeneralSearchSelectedEntities from './components/GeneralSearchSelectedEntities/GeneralSearchSelectedEntities';
import GeneralSearchSelectedEmployees from './components/GeneralSearchSelectedEmployees/GeneralSearchSelectedEmployees';
import { FilteredItemsChangeIds, FilteredItemsState } from './types';
import s from './styles.module.sass';
import GeneralSearchSearchBlock from './components/GeneralSearchSearchBlock/GeneralSearchSearchBlock';

interface GeneralFiltersButtonProps {
  onSearch?: (ids: FilteredItemsChangeIds) => void;
  isProfilesViewerMode: boolean;
  buttonClassName?: string;
}

const DEFAULT_FILTERED_ITEMS_STATE = { employees: {}, entities: {}, partners: {}, searchItemIds: [], lastUpdatedSearchItemIds: [] };

const GeneralFiltersButton: React.FC<GeneralFiltersButtonProps> = ({ onSearch, buttonClassName, isProfilesViewerMode }) => {
  const inputRef = useRef<Input>(null);

  const [ paginatedSearchItems, setPaginatedSearchItems ] = useState<SearchItemPage>();
  const [ loading, setLoading ] = useState(false);
  const [ visible, setVisible ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>();
  const [ filteredItems, setFilteredItems ] = useState<FilteredItemsState>(DEFAULT_FILTERED_ITEMS_STATE);

  useEffect(() => {
    if (visible) {
      inputRef?.current?.focus();
      // TODO: Не пашет, странно
    }

    if (visible && !paginatedSearchItems) {
      handleGetUsers();
    }
  }, [ visible ]);

  if (isProfilesViewerMode) return null;

  const handleGetUsers = () => {
    if (paginatedSearchItems && (paginatedSearchItems?.objects?.length >= (paginatedSearchItems?.meta?.totalCount || 0))) {
      return;
    }

    setLoading(true);

    searchByAnyItem({
      query: searchValue,
      limit: paginatedSearchItems?.meta?.limit || 40,
      offset: (paginatedSearchItems && ((paginatedSearchItems?.meta?.offset || 0) >= 0))
        ? ((paginatedSearchItems?.meta?.offset || 0) + INCREASED_PAGINATION_RAW.limit)
        : 0,
    })
      .then((res: SearchItemPage) => {
        setPaginatedSearchItems(prevUserState => ({
          meta: res.meta,
          objects: prevUserState ? [ ...prevUserState.objects, ...res.objects ] : [ ...res.objects ],
        }));
      })
      .finally(() => setLoading(false));
  };

  const handleUpdate = (values: positionValues) => {
    const { top } = values;

    if (top > 0.8) {
      if (!loading) {
        handleGetUsers();
      }
    }
  };

  const handleDebounceOnSearch = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    setSearchValue(event.target.value);

    searchByAnyItem({
      query: event.target.value,
      limit: 40,
      offset: 0,
    })
      .then(res => setPaginatedSearchItems(res))
      .finally(() => setLoading(false));
  }, 500);

  const getFilteredItemIds = () => ({
    userId: _.map(filteredItems.employees, 'userId'),
    entityId: _.map(filteredItems.entities, 'entityId'),
    partnerId: _.map(filteredItems.partners, 'partnerId'),
  });

  const handleItemClick = (entity: BaseEntityOutput | PartnerCardOutput | BaseUserOutput | null, searchItemId: number, fieldName: keyof FilteredItemsState) => {
    if (!entity) {
      return;
    }

    const updatedEntities = filteredItems[fieldName][searchItemId]
      ? _.omit(filteredItems[fieldName], searchItemId)
      : { ...filteredItems[fieldName], [searchItemId]: entity };

    const updatedSearchItemIds = () => (_.includes(filteredItems.searchItemIds, searchItemId)
      ? _.filter(filteredItems.searchItemIds, i => i !== searchItemId)
      : [ ...filteredItems.searchItemIds, searchItemId ]);

    setFilteredItems({
      ...filteredItems,
      searchItemIds: updatedSearchItemIds(),
      [fieldName]: updatedEntities,
    });
  };

  const renderSettingsBlock = () => (
    <div className={s.settingsBlock}>
      <div className={s.filteredItemsWrapper}>
        <GeneralSearchSelectedEmployees
          filteredItems={filteredItems}
          onClick={handleItemClick}
        />

        <GeneralSearchSelectedEntities
          filteredItems={filteredItems}
          onClick={handleItemClick}
        />

        <GeneralSearchSelectedPartners
          filteredItems={filteredItems}
          onClick={handleItemClick}
        />
      </div>
    </div>
  );

  const popoverContent = () => (
    <div className={s.generalSearchPopoverLayout}>
      <div className={s.generalSearchPopoverHeader}>

        <Input
          ref={inputRef}
          onChange={e => handleDebounceOnSearch(e)}
          placeholder="Search employees, entities, or anything..."
          className={s.generalSearchInput}
          allowClear
        />
        <div className={s.searchSettingsHeader}>
          <Button
            type="text"
            className={s.clearButton}
            disabled={_.isEmpty(filteredItems.searchItemIds)}
            onClick={() => setFilteredItems({
              ...filteredItems,
              ..._.omit(DEFAULT_FILTERED_ITEMS_STATE, 'lastUpdatedSearchItemIds'),
            })}
          >
            <CloseIcon className={s.clearWrapperIcon} />
            Clear filters
          </Button>
        </div>

      </div>
      <div className={s.generalSearchPopoverContent}>

        <GeneralSearchSearchBlock
          filteredItems={filteredItems}
          loading={loading}
          handleItemClick={handleItemClick}
          searchedItems={paginatedSearchItems?.objects}
          handleUpdate={handleUpdate}
        />

        {renderSettingsBlock()}
      </div>

    </div>
  );

  const renderFilterCounter = () => {
    if (!_.isEmpty(filteredItems.searchItemIds)) {
      return `${filteredItems.searchItemIds.length} filters applied`;
    }

    return 'All items';
  };

  const onVisibleChange = (visible: boolean) => {
    const { searchItemIds, lastUpdatedSearchItemIds } = filteredItems;

    if (!visible) {
      const isSearchIdsChanged = _.isEqual(_.sortBy(searchItemIds), _.sortBy(lastUpdatedSearchItemIds));

      if (isSearchIdsChanged) {
        setVisible(visible);
        return;
      }

      onSearch?.(getFilteredItemIds());
      setFilteredItems({ ...filteredItems, lastUpdatedSearchItemIds: filteredItems.searchItemIds });
    }

    setVisible(visible);
  };

  return (
    <div className={s.generalSearchLayout}>
      <Popover
        overlayClassName={s.generalSearchPopover}
        trigger="click"
        content={popoverContent()}
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        <Button
          className={cx(buttons.qsButton, s.generalSearchButton, buttonClassName,
            { [s.generalSearchButtonOpened]: (visible || !_.isEmpty(filteredItems.searchItemIds)) })}
          size="large"
        >
          <FontAwesomeIcon icon="filter" />
          {renderFilterCounter()}
        </Button>
      </Popover>
    </div>
  );
};

export default GeneralFiltersButton;
