import * as Actions from '../constants/Partners';
import _ from 'lodash';
import Subtypes from '../constants/__common__/Subtypes';

const InitialState = {
  activities: [],
  objects: [],
  isPartnersLoading: false,
  isActivitiesLoading: false,
  error: false,
  ghost: {
    show: false,
    name: '',
    partnerId: 0,
    partnerColor: '',
    mask: 'DEFAULT',
    activityId: 0,
    activityColor: '',
  }
};

function setDefaultsActivities(partners, activities) {
  partners.forEach(partner => {
    let activity = activities.find(activity => activity.partner === partner.id);
    if (partner.selectedActivity === -1 && activity) partner.selectedActivity = activity.id;
  });
  return partners;
}

function receiveActivities(state, activities) {
  let partners = _.cloneDeep(state.objects);
  if (partners > []) partners = setDefaultsActivities(partners, activities);
  return { ...state, activities: activities, objects: partners, isActivitiesLoading: false };
}

function receivePartners(state, partners) {
  if (state.activities > [])
    partners = setDefaultsActivities(partners, state.activities);
  return { ...state, objects: partners, isPartnersLoading: false };
}

function changeSinglePartner(state, payload) {
  const { partnerId, activityId } = payload;
  let partners = _.cloneDeep(state.objects);
  let partner = partners.find(partner => partner.id === partnerId);
  if (partner) partner.selectedActivity = activityId;

  return { ...state, objects: partners };
}

function reducer(state = InitialState, action) {
  switch(action.type) {
    case Actions.GET_PARTNERS:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, isPartnersLoading: true };
        case Subtypes.Request.Success:
          return receivePartners(state, action.payload);
        case Subtypes.Request.Error:
          return { ...state, error: true, isPartnersLoading: false };
        default:
          return state;
      }
      
    case Actions.GET_ACTIVITIES:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, isActivitiesLoading: true };
        case Subtypes.Request.Success:
          return receiveActivities(state, action.payload);
        case Subtypes.Request.Error:
          return { ...state, error: true, isActivitiesLoading: false };
        default:
          return state;
      }
    
    case Actions.CHANGE_SINGLE_PARTNER:
      return changeSinglePartner(state, action.payload);
    case Actions.RECEIVE_GHOST_PARTNER_DATA:
      return { ...state, ghost: action.payload };
    case Actions.HIDE_GHOST_PARTNER:
      return { ...state, ghost: { ...state.ghost, show: false } };
    case Actions.PARTNERS_INITIATE:
      return InitialState;
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
