import ToolsTabSidebar from 'components/Sidebars/ToolsTabSidebar';
import PartnerInfo from 'components/PartnerInfo/PartnerInfo';
import MobileTimesheet from 'components/Mobile/MobileTimesheet/MobileTimesheet';

import Sandbox from '../../../components/Sandbox';
import Profile from '../../../components/ProfileTabContent';
import { AccessEnum, RoutesList } from '../../../components/Root/helpers/types';
import {
  PATH_ADMINISTRATION,
  PATH_PARTNER_INFO,
  PATH_PROFILE,
  PATH_REPORTS,
  PATH_TOOLS,
  PATH_TRACKER,
  ROUTE_SPECIALISTS,
  TAB_CURATORS_TIMESHEET,
  TAB_EMPLOYEES,
  WELCOME_PAGE,
} from './MenuCases';
import AdministrationTabContent from '../../../components/PartnersTabContent';
import ToolsTabContent from '../../../components/ToolsTabContent';
import Specialists from '../../../components/Specialists';
import WelcomeTrackerPage from '../../../components/WelcomeTrackerPage';
import AdministrationTabSidebar from '../../../components/Sidebars/AdministationTabSidebar';
import ProfileTabSidebar from '../../../components/Sidebars/ProfileTabSidebar';
import TrackerSidebar from '../../../components/Sidebars/NewTrackerSidebar';
import NewTrackerTabContent from '../../../components/NewTrackerTabContent';
import EditProfile from '../../../components/EditProfile';
import ReportsPage from '../../../components/ReportsPage/ReportsPage';
import CuratorsView from '../../../components/CuratorsView';

const ROUTES: RoutesList = {
  sandbox: {
    path: '/sandbox',
    component: Sandbox,
    accessType: AccessEnum.Admin,
  },

  timesheet: {
    path: PATH_TRACKER,
    component: NewTrackerTabContent,
    mobileComponent: MobileTimesheet,
    accessType: AccessEnum.User,
    sidebar: TrackerSidebar,
  },

  profile: {
    path: `${PATH_PROFILE}/:tab`,
    component: Profile,
    mobileComponent: Profile,
    sidebar: ProfileTabSidebar,
    accessType: AccessEnum.User,
  },

  report: {
    path: `${PATH_REPORTS}/:tab?`,
    component: ReportsPage,
    mobileComponent: ReportsPage,
    accessType: AccessEnum.User,
  },

  'edit-employee': {
    path: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/:userId/:tab?`,
    component: EditProfile,
    accessType: [ AccessEnum.Admin, AccessEnum.ProfilesViewer ],
    exact: false,
  },

  administration: {
    path: `${PATH_ADMINISTRATION}/:tab`,
    component: AdministrationTabContent,
    sidebar: AdministrationTabSidebar,
    accessType: [ AccessEnum.Admin, AccessEnum.Curator ],
  },

  'curated-employees': {
    path: `${PATH_ADMINISTRATION}/${TAB_CURATORS_TIMESHEET}/:userId/:tab?`,
    component: CuratorsView,
    accessType: AccessEnum.Curator,
  },

  'partner-info': {
    path: `${PATH_PARTNER_INFO}/:partnerId/:partnerTab`,
    component: PartnerInfo,
    accessType: AccessEnum.Admin,
  },

  specialists: {
    path: ROUTE_SPECIALISTS,
    component: Specialists,
    accessType: [ AccessEnum.Admin, AccessEnum.ProfilesViewer ],
  },

  tools: {
    path: `${PATH_TOOLS}/:tab`,
    component: ToolsTabContent,
    sidebar: ToolsTabSidebar,
    accessType: AccessEnum.Admin,
  },
};

export const COMMON_PAGES = {
  welcome: {
    path: WELCOME_PAGE,
    component: WelcomeTrackerPage,
    accessType: AccessEnum.User,
  },
};

export default ROUTES;
