import { Button, Divider, Modal, Popover, Space, Switch, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ReactComponent as SettingsIcon } from 'helpers/icons/settings-24px.svg';
import popover from 'helpers/styles/components/popover.module.sass';
import switches from 'helpers/styles/components/switches.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import buttons from 'helpers/styles/components/buttons.module.sass';
import modals from 'helpers/styles/components/modals.module.sass';
import { logout, updateUserPreferences } from 'redux/User/actions';
import divider from 'helpers/styles/components/divider.module.sass';
import { ReactComponent as ASLogo } from 'helpers/icons/AppStore.svg';
import GPLogo from 'helpers/icons/GooglePlayStore.svg';

import { APP_STORE_LINK, GOOGLE_STORE_LINK } from '../Login/helpers/constants';
import { ReactComponent as Logout } from '../Root/components/Menu/helpers/icons/Logout.svg';
import s from './styles.module.sass';
import { ASK_CONFIRMATION_ON_DELETE_HOURS, ASK_CONFIRMATION_ON_OVERWRITE_HOURS } from '../../helpers/constants/_common/constants';
import { ChangedPreferencesState } from './types';

const SettingsPopover: React.FC = () => {
  const dispatch: DispatchType = useDispatch();

  const { preferences } = useSelector((state: Redux) => state.login);

  const [ opened, setOpened ] = useState(false);
  const [ changedPreferences, setChangedPreferences ] = useState<ChangedPreferencesState>({});

  useEffect(() => {
    if (opened) {
      setChangedPreferences({
        askConfirmationOnOverwriteHours: preferences.askConfirmationOnOverwriteHours,
        askConfirmationOnDeleteHours: preferences.askConfirmationOnDeleteHours,
      });
    }
  }, [ opened ]);

  const handleOnLogout = () => {
    Modal.confirm({
      className: modals.qsConfirmAntdModal,
      title: 'Logout',
      content: 'All sessions will be closed.',
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      okText: 'Logout',
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      icon: null,
      width: 500,
      onOk: () => dispatch(logout({ isGlobalLogout: true })),
    });
  };

  const onSwitchChange = (field: string, value: boolean) => {
    setChangedPreferences({ ...changedPreferences, [field]: value });

    changeSettings({ ...changedPreferences, [field]: value });
  };

  const changeSettings = useMemo(() => _.debounce((changedPreferences) => {
    dispatch(updateUserPreferences(changedPreferences));
  }, 1000), []);

  const content = () => (
    <div className={s.settingsWrapper}>
      <header className={s.settingsHeader}>
        <h1>Settings</h1>
      </header>

      <Space direction="vertical" size={16}>
        <Space>
          <Switch
            checked={!changedPreferences?.askConfirmationOnOverwriteHours}
            className={switches.trackerSwitch}
            defaultChecked={!preferences.askConfirmationOnOverwriteHours}
            onChange={checked => onSwitchChange(ASK_CONFIRMATION_ON_OVERWRITE_HOURS, !checked)}
          />
          <Typography.Text>Do not show warning when overwriting existing hours</Typography.Text>
        </Space>

        <Space>
          <Switch
            checked={!changedPreferences?.askConfirmationOnDeleteHours}
            className={switches.trackerSwitch}
            defaultChecked={!preferences.askConfirmationOnDeleteHours}
            onChange={checked => onSwitchChange(ASK_CONFIRMATION_ON_DELETE_HOURS, !checked)}
          />
          <Typography.Text>Do not show warning for deleting spent hours from timesheet</Typography.Text>
        </Space>
      </Space>

      <Divider className={divider.trackerDivider} />

      <Button
        type="primary"
        className={cx(buttons.qsButtonPrimary, s.logoutButton)}
        onClick={handleOnLogout}
      >
        <Logout fill="white" />
        <span>Logout from all devices</span>
      </Button>

      <Divider className={divider.trackerDivider} />

      <Space className={s.loginPageFooter} size={20} direction="vertical">
        <Typography.Text>You can use mobile apps to access Time Tracker</Typography.Text>
        <Space size={20} align="start">
          <a href={APP_STORE_LINK}>
            <ASLogo className={s.appleStore} />
          </a>
          <a href={GOOGLE_STORE_LINK} className={s.googleStoreLogo}>
            <img src={GPLogo} alt="Google Play Logo" />
          </a>
        </Space>
      </Space>
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="rightBottom"
      overlayClassName={cx(popover.dotsPopoverOverlay, s.settingsPopoverLayout)}
      onVisibleChange={setOpened}
      content={content()}
    >
      <Button
        type="text"
        shape="circle"
        className={s.settingsButton}
      >
        <SettingsIcon className={cx(s.settingsIcon, { [s.settingsIconOpened]: opened })} />
      </Button>
    </Popover>
  );
};

export default SettingsPopover;
