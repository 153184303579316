import { AxiosError, AxiosInstance } from 'axios';
import _ from 'lodash';

import { externalRedirectToLogin } from 'V1/actions/Login/LoginActions';
import authRequests from 'V1/requests/Auth';
import TokenStore from 'api/_request/helpers/jwtTokenStore';

import configuredStore from '../_reducer/configureStore';
import { setError } from './actions';
import { ApiErrorObject } from '../../api/_request/types';

const apiErrorHandler = async (error: AxiosError, apiClient: AxiosInstance, errorObject?: ApiErrorObject) => {
  const errorStatus = error?.response?.status || '';

  if (error?.code === 'ECONNABORTED') {
    configuredStore.dispatch(setError({ error: 'Request timeout' }));
    return Promise.reject(error);
  }

  if (!_.includes([ 404, 409 ], errorStatus) && errorObject && _.has(errorObject, errorStatus) && !errorObject[errorStatus].hidden) {
    const defaultGetErrorMessage = (error: any) => error.message;
    const getErrorMessage = _.get(errorObject, errorStatus).description || defaultGetErrorMessage;

    configuredStore.dispatch(setError({ error: getErrorMessage(error) }));

    return Promise.reject(error);
  }

  switch (errorStatus) {
    case 400:
      if (_.get(errorObject, `${errorStatus}.hidden`)) {
        return Promise.reject(error);
      }

      configuredStore.dispatch(setError({ error: `${error.message}` }));
      return Promise.reject(error);
    case 401: {
      const isTokenRequestRoute = error?.response?.config.url === '/api/v3/auth/token/';

      if (isTokenRequestRoute) {
        return configuredStore.dispatch(externalRedirectToLogin());
      }

      const res = await authRequests.updateTokens();
      const { refreshToken, accessToken, expiresIn } = res;

      if (accessToken) {
        TokenStore.setJWTData({
          refreshToken,
          accessToken,
          expiresIn,
        });

        const response = await apiClient({
          ...error?.response?.config,
          headers: {
            common: !error.response?.config.skipAuth ? {
              Authorization: `Bearer ${accessToken}`,
            } : {},
          },
        });

        return response.data;
      } else {
        configuredStore.dispatch(externalRedirectToLogin());
        throw error;
      }
    }
    case 404:
      if (_.get(errorObject, `${error?.response?.status}.hidden`)) {
        return Promise.reject(error);
      }

      if (errorObject && _.has(errorObject, 404)) {
        notFoundErrorInterceptor(errorObject, error);

        return;
      }

      configuredStore.dispatch(setError({ error: `404: ${error?.config?.url || ''} not found` }));

      return Promise.reject(error);

    case 405:
      configuredStore.dispatch(setError({ error: `405: ${error.response?.config?.url} - ${error.response?.statusText}` }));
      return Promise.reject(error);

    case 409:
      if (_.get(errorObject, `${error?.response?.status}.hidden`)) {
        return Promise.reject(error);
      }

      if (errorObject && _.has(errorObject, 409)) {
        conflictErrorInterceptor(errorObject, error);

        return;
      }

      if (error?.response?.data?.detail || error?.response?.data?.error) {
        const errorField = error?.response?.data?.field ? `${error?.response?.data?.field}: ` : '';
        const errorText = error?.response?.data?.detail || error?.response?.data?.error;

        configuredStore.dispatch(setError({ error: `${errorField}${errorText}` }));
      }

      return Promise.reject(error);

    case 500:
      configuredStore.dispatch(setError({
        error: `Sorry, something goes wrong. Here is error ID: ${error?.response?.data?.referenceId || ''}, please,
        contact support team on Slack and supply this ID for quicker investigation`,
      }));
      break;
    default:
      configuredStore.dispatch(setError(error?.response?.data));
      return Promise.reject(error);
  }

  return Promise.reject(error);
};

const notFoundErrorInterceptor = (errorObject: ApiErrorObject, error: AxiosError) => {
  const currentErrorObject = _.get(errorObject, 404);
  const errorDescription = currentErrorObject.description ? currentErrorObject.description(error) : `404: ${error?.config?.url || ''} not found`;

  configuredStore.dispatch(setError({ error: errorDescription }));
};

const conflictErrorInterceptor = (errorObject: ApiErrorObject, error: AxiosError) => {
  const currentErrorObject = _.get(errorObject, 409);

  if (error?.response?.data?.detail || currentErrorObject.description) {
    const errorDescription = currentErrorObject.description ? currentErrorObject.description(error) : error?.response?.data?.detail;

    configuredStore.dispatch(setError({ error: errorDescription }));
  }
};

export default apiErrorHandler;
