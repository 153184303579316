import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import Button from 'components/_shared/Button';
import { CalendarDay, DayType } from 'components/BusinessCalendar/helpers/types';

import { ReactComponent as GearIcon } from './helpers/gearIcon.svg';
import S from './helpers/styles.module.sass';
import { MonthInnerProps, MonthTableProps, MonthInnerType, AddDaysStatuses } from './helpers/types';
import Day from '../../../Day/Day';
import { getShortDaysNames } from './helpers/helpers';
import { getMonthNameByNumber } from '../../../../../_shared/BusinessMonthSelector/helpers/helpers';

const MonthInner: React.FC<MonthInnerProps> = ({ monthInnerData, monthInnerType, onRemoveAllDays, onDayStatusChange, onDayAmountChange, onDrawerOpen }) => {
  const [ editedMonth, changeEditedMonth ] = useState<keyof AddDaysStatuses | null>(null);
  const [ reservedDays, changeReservedDays ] = useState<CalendarDay[]>([]);
  const drawerMode = monthInnerType === MonthInnerType.Drawer;

  const clickOutsideHandler = (e: any) => {
    if (
      editedMonth
      && !e.target.closest(`[data-month-key=${editedMonth}]`)
      && !e.target.closest(`.${S.addDaysButton}`)
    ) {
      changeEditedMonth(null);
      const daysToRestore = reservedDays.length ? reservedDays : monthInnerData.days[editedMonth] || undefined;
      if (onRemoveAllDays) onRemoveAllDays(editedMonth, daysToRestore);
    }
  };

  useEffect(() => {
    if (drawerMode) {
      document?.addEventListener('click', clickOutsideHandler);

      return () => {
        document?.removeEventListener('click', clickOutsideHandler);
      };
    }
  }, [ clickOutsideHandler ]);

  if (!monthInnerData) return null;

  const monthInnerRef = React.createRef<HTMLDivElement>();
  const { month } = monthInnerData;
  const daysList = getShortDaysNames();
  const monthTitle = getMonthNameByNumber(month);
  const daysInCurrentMonth = dayjs(monthInnerData.days.currentMonth[0].day).daysInMonth();
  const currentMonthDaysAmount = _.filter(monthInnerData.days.currentMonth, dayObj => dayObj.dayType === DayType.Original).length;
  const isModified = monthInnerData.days.prevMonth.filter(dayObj => dayObj.dayType === DayType.Original).length
    || monthInnerData.days.nextMonth.filter(dayObj => dayObj.dayType === DayType.Original).length
    || daysInCurrentMonth !== currentMonthDaysAmount;

  const renderDaysHeader = () => daysList.map(dayName => (
    <div key={dayName} className={S.dayName}>{dayName}</div>
  ));

  const renderMonthButtons = (data: { monthKey: keyof AddDaysStatuses, days: CalendarDay[] }) => {
    const addDaysStatus = editedMonth === data.monthKey;
    const daysIsExisted = data.days.filter(day => day.dayType === DayType.Original).length > 0;

    if (data.monthKey === 'currentMonth') return null;

    if (addDaysStatus) {
      return (
        <div>
          { daysIsExisted && (
            <Button
              className={cx(S.addDaysButton)}
              onClick={() => onRemoveAllDays && onRemoveAllDays(data.monthKey)}
            >
              Remove all
            </Button>
          )}
          <Button
            type="primary"
            style={{ marginLeft: '5px' }}
            className={cx(S.addDaysButton, S.active)}
            onClick={() => {
              changeEditedMonth(null);
              changeReservedDays([]);
            }}
          >
            Apply
          </Button>
        </div>
      );
    }

    return (
      <Button
        type="primary"
        className={cx(S.addDaysButton)}
        onClick={() => {
          changeEditedMonth(data.monthKey);
          changeReservedDays(monthInnerData.days[data.monthKey]);
        }}
      >
        {daysIsExisted ? 'Select days' : 'Add days' }
      </Button>
    );
  };

  const renderTitle = (data: { monthName: string, monthKey: keyof AddDaysStatuses, days: CalendarDay[] }) => (drawerMode ? (
    <div className={S.monthSubTitleWrapper}>
      <span className={S.monthSubTitle}>
        {data.monthName}
      </span>
      {renderMonthButtons({ monthKey: data.monthKey, days: data.days })}
    </div>
  ) : (
    <div className={S.monthNameWrapper}>
      <span className={S.monthName}>
        {data.monthName}
      </span>
    </div>
  ));

  const renderTable = (monthTableData: MonthTableProps) => {
    const { addDaysStatus, days, monthKey } = monthTableData;
    const onlyOriginalDays = _.filter(days, day => day?.dayType === DayType.Original);
    const noEnoughDays = !onlyOriginalDays.length;
    const daysToRender = addDaysStatus ? days : onlyOriginalDays;

    if (noEnoughDays && !addDaysStatus) return null;

    const getNiceDayOffset = (day: string) => {
      if (!day) return 0;
      const dayJsDay = dayjs(day).get('day');
      return dayJsDay === 0 ? 6 : dayJsDay - 1;
    };

    const emptyDaysAmount = getNiceDayOffset(daysToRender[0]?.day);
    const weeks = Math.floor((daysToRender.length + emptyDaysAmount - 1) / 7) + 1;

    const weeksBlanks = Array(weeks).fill(null);
    const daysBlanks = Array(7).fill(null);

    const renderWeek = (rowNumber: number) => (
      <tr key={`rowNumber-${rowNumber}`} className={`rowNumber-${rowNumber}`}>
        {daysBlanks.map((e, i) => {
          const currentIndex = i + rowNumber * 7 - emptyDaysAmount;
          const day = daysToRender[currentIndex];

          return (
            // eslint-disable-next-line react/no-array-index-key
            <td key={i}>
              {Boolean(day) && (
                <Day
                  key={day.day}
                  day={day}
                  addingDaysMode={Boolean(addDaysStatus)}
                  currentPeriodMonth={monthInnerData.month}
                  currentPeriodYear={monthInnerData.year}
                  monthInnerType={monthInnerType}
                  onDayStatusChange={onDayStatusChange}
                  onDayAmountChange={onDayAmountChange}
                  monthKey={monthKey}
                />
              )}
            </td>
          );
        })}
      </tr>
    );

    return (
      <table className={S.monthTable}>
        <tbody>
          {
            weeksBlanks.map((e, i) => renderWeek(i))
          }
        </tbody>
      </table>
    );
  };

  const renderMonthTables = () => _.map(monthInnerData.days, (days, monthKey: keyof AddDaysStatuses) => {
    const addDaysStatus = monthKey !== 'currentMonth' ? editedMonth === monthKey : false;
    const notCurrentMonthNumber = monthKey === 'prevMonth' ? month - 1 : month + 1;
    const monthNumber = monthKey === 'currentMonth' ? month : notCurrentMonthNumber;
    const monthName = getMonthNameByNumber(monthNumber);

    if (!days?.length) return null;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        key={monthKey}
        data-month-key={monthKey}
        className={cx(S[monthKey], S.monthDays, {
          [S.drawerMonthDays]: drawerMode,
          [S.notEditing]: editedMonth && (monthKey !== editedMonth),
        })}
      >
        {renderTitle({
          monthName,
          monthKey,
          days,
        })}
        <div className={cx(S.monthDaysInner, { [S.addDaysStatusActive]: addDaysStatus })}>
          {renderTable({
            addDaysStatus,
            monthNumber,
            monthKey,
            days,
          })}
        </div>
      </div>
    );
  });

  return (
    <div ref={monthInnerRef} className={cx(S.monthInner, { [S.drawerMode]: drawerMode })}>
      <div className={cx(S.monthHeader, S.monthInnerPadding)}>
        <div className={S.monthTitleWrapper}>
          <span className={S.monthTitle}>{monthTitle}</span>
          {isModified && <span className={S.monthTitleModified}>Modified</span>}
        </div>
        {!drawerMode && (
          <Button
            type="link"
            onClick={() => onDrawerOpen && onDrawerOpen(monthInnerData)}
            className="gearButton"
            icon={<GearIcon className={S.gearIcon} />}
          />
        )}
      </div>
      <div className={cx(S.daysList, S.monthInnerPadding)}>
        {renderDaysHeader()}
      </div>
      <div className={cx(S.daysWrapper, S.monthInnerPadding, { [S.limited]: drawerMode })}>
        <div className={S.days}>
          {renderMonthTables()}
        </div>
      </div>
    </div>
  );
};

export default MonthInner;
