import React, { useState } from 'react';
import { Button, Checkbox, List, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames/bind';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross18.svg';

import s from './helpers/styles.module.sass';

interface TableSelectionListProps {
  renderTitle: (row: any) => React.ReactNode | string;
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  onChangeCheckbox?: (event: CheckboxChangeEvent, targetRow: any) => void;
  resetSelections: () => void;
}

const TableSelectionList: React.FC<TableSelectionListProps> = ({ selectedRows, setSelectedRows, renderTitle, onChangeCheckbox, resetSelections }) => {
  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const onPopoverVisibleChange = (visible: boolean) => {
    if (!visible) {
      const updatedSelectedRows = _.filter(selectedRows, [ 'checked', true ]);
      setSelectedRows?.(updatedSelectedRows);
    }

    setPopoverVisible(visible);
  };

  const handleOnChangeCheckbox = (event: CheckboxChangeEvent, targetRow: any) => {
    if (onChangeCheckbox) {
      onChangeCheckbox(event, targetRow);
      return;
    }

    const { userId } = targetRow;
    const checked = event?.target?.checked;

    const updatedSelectedRows = _.map(selectedRows, (s) => {
      if (s.userId === userId) {
        return { ...s, checked };
      }

      return s;
    });

    setSelectedRows(updatedSelectedRows);
  };

  const content = (
    <div className={s.selectionPopover}>
      <div className={s.selectionPopoverHeader}>
        <Button
          type="text"
          size="small"
          className={s.clearButton}
          onClick={resetSelections}
        >
          <div className={s.clearButtonWrapper}>
            <CloseIcon className={s.clearWrapperIcon} />
            Clear
          </div>

        </Button>
      </div>
      <div className={s.selectionPopoverBody}>
        <List
          itemLayout="horizontal"
          dataSource={selectedRows}
          renderItem={row => (
            <List.Item className={s.selectionPopoverItem}>
              <Checkbox
                onChange={event => handleOnChangeCheckbox(event, row)}
                checked={row.checked}
                style={{ marginRight: 8 }}
              >
                {renderTitle?.(row)}
              </Checkbox>
            </List.Item>
          )}
        />
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      overlayClassName={s.selectionCountPopover}
      content={content}
      onVisibleChange={onPopoverVisibleChange}
      placement="bottomLeft"
      align={{ offset: [ 0, -10 ] }}
    >
      <Button
        className={cx(s.selectionCountButton, { [s.open]: popoverVisible })}
        type="text"
        size="small"
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </Button>
    </Popover>
  );
};

export default TableSelectionList;
