import * as Actions from '../constants/HotProjects';
import hotProjectsRequest from '../requests/HotProjects';
import partnersRequests from '../requests/Partner';
import loginRequests from '../requests/User';
import Subtypes from '../constants/__common__/Subtypes';
import {getObjectsWithPaginationData} from '../constants/__common__/PaginationConstants';
import * as LoginActions from './Login/LoginActions';

export function getHotProjects(data) {
  return dispatch => {
    dispatch({type: Actions.GET_HOT_PROJECTS, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.getHotProjects(getObjectsWithPaginationData(data))
      .then(res => dispatch({type: Actions.GET_HOT_PROJECTS, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.GET_HOT_PROJECTS, subtype: Subtypes.Request.Error, payload: error.message}))
  };
}

export function changeMatchMySkills() {
  return { type: Actions.CHANGE_MATCH_MY_SKILLS };
}

export function changeNoParticipants() {
  return { type: Actions.CHANGE_NO_PARTICIPANTS };
}

export function changeStatus(status) {
  return { type: Actions.CHANGE_STATUS, payload: status };
}

export function openHotProjectModal(data) {
  return {type: Actions.HOT_PROJECT_MODAL_VIEW, subtype: Subtypes.Modal.Show, payload: data}
}

export function closeHotProjectModal() {
  return {type: Actions.HOT_PROJECT_MODAL_VIEW, subtype: Subtypes.Modal.Hide}
}

export function openHotProjectConfirmationModal(selected, callback) {
  return {type: Actions.HOT_PROJECT_CONFIRMATION_MODAL_VIEW, subtype: Subtypes.Modal.Show, payload: {selected, callback}}
}

export function closeHotProjectConfirmationModal() {
  return {type: Actions.HOT_PROJECT_CONFIRMATION_MODAL_VIEW, subtype: Subtypes.Modal.Hide}
}

export function toggleHelpRequestStatus(hotProjectId, helpRequestId, status) {
  return dispatch => {
    dispatch({type: Actions.TOGGLE_HELP_REQUEST_STATUS, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.toggleHelpRequestStatus(hotProjectId, helpRequestId, status)
      .then(() => dispatch({type: Actions.TOGGLE_HELP_REQUEST_STATUS, subtype: Subtypes.Request.Success, payload: {helpRequestId, status}}))
      .catch(() => dispatch({type: Actions.TOGGLE_HELP_REQUEST_STATUS, subtype: Subtypes.Request.Error}));
  }
}

export function addHotProject(hotProject) {
  return dispatch => {
    dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.addHotProject(hotProject)
      .then(res => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function makeHelpOffer(user, hotProjectId, helpRequestId) {
  return dispatch => {
    dispatch({type: Actions.MAKE_HELP_OFFER, subtype: Subtypes.Request.Loading});

    return loginRequests.makeUsersOffer(user.userId || user.id, hotProjectId, helpRequestId)
      .then(res => dispatch({type: Actions.MAKE_HELP_OFFER, subtype: Subtypes.Request.Success, payload: {helpOfferId: res, user}}))
      .catch(error => dispatch({type: Actions.MAKE_HELP_OFFER, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function addHelpRequest(helpRequest, hotProjectId) {
  return dispatch => {
    dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.addHelpRequest(helpRequest, hotProjectId)
      .then(res => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function editHotProject(editedHotProject) {
  return dispatch => {
    dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.editHotProject(editedHotProject)
      .then(res => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.ADD_HOT_PROJECT_MODAL_PROCESS, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function updateHotProjectAsRead(hotProjectId) {
  return {type: Actions.UPDATE_HOT_PROJECT_AS_READ, payload: hotProjectId}
}

export function updateHotProjectsListWithNewOne(hotProject) {
  return {type: Actions.UPDATE_HOT_PROJECTS_LIST_WITH_NEW_ONE, payload: hotProject}
}

export function updateExistedHotProject(hotProject) {
  return {type: Actions.UPDATE_EXISTED_HOT_PROJECTS_LIST, payload: hotProject}
}

export function markAllHotProjectsAsRead(userId) {
  return dispatch => {
    dispatch({type: Actions.MARK_ALL_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Loading});

    hotProjectsRequest.markAllHotProjectsAsRead(userId)
      .then(() => {
        dispatch(LoginActions.getUnreadCounts());
        return dispatch({type: Actions.MARK_ALL_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Success});
      })
      .catch(error => dispatch({type: Actions.MARK_ALL_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function markSelectedHotProjectsAsRead(userId, hotProjectIdsToMark) {
  return dispatch => {
    dispatch({type: Actions.MARK_SELECTED_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Loading});

    hotProjectsRequest.markAllHotProjectsAsRead(userId, hotProjectIdsToMark)
      .then(() => {
        dispatch(LoginActions.getUnreadCounts());
        dispatch({type: Actions.UPDATE_SELECTED_HOT_PROJECTS_AS_READ, payload: hotProjectIdsToMark});
        return dispatch({type: Actions.MARK_SELECTED_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Success});
      })
      .catch(error => dispatch({type: Actions.MARK_SELECTED_HOT_PROJECTS_AS_READ, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function getAllPartners(data) {
  return dispatch => {
    dispatch({type: Actions.GET_ALL_PARTNERS, subtype: Subtypes.Request.Request});

    return partnersRequests.getAll(getObjectsWithPaginationData(data))
      .then(res => dispatch({type: Actions.GET_ALL_PARTNERS, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.GET_ALL_PARTNERS, subtype: Subtypes.Request.Error, payload: error}))
  }
}

export function getHotProjectById(hotProjectId) {
  return dispatch => {
    dispatch({type: Actions.GET_HOT_PROJECT_BY_ID, subtype: Subtypes.Request.Loading});

    return hotProjectsRequest.getHotProjectById(hotProjectId)
      .then(res => {
        if (!res.isViewed) {
          loginRequests.toggleHotProjectReadMark(hotProjectId).then(() => {
            dispatch(LoginActions.getUnreadCounts());
            dispatch(updateHotProjectAsRead(hotProjectId));
          });
        }
        return dispatch({type: Actions.GET_HOT_PROJECT_BY_ID, subtype: Subtypes.Request.Success, payload: res})
      })
      .catch(error => dispatch({type: Actions.GET_HOT_PROJECT_BY_ID, subtype: Subtypes.Request.Error, payload: error}))
  }
}

export function changeSelectedHelpRequest(helpRequest) {
  return {type: Actions.CHANGE_SELECTED_HELP_REQUEST, payload: helpRequest};
}

export function updateUnreadHotProjectsCount(count) {
  return {type: Actions.UPDATE_HOT_PROJECTS_UNREAD_COUNT, payload: count};
}

export function handleHotProjectSelection(checked, hotProjectId) {
  return {type: Actions.HANDLE_HOT_PROJECT_SELECTION, payload: {checked, hotProjectId}}
}
