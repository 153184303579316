import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import _ from 'lodash';

import { BREAKPOINT_TABLET_WIDE } from 'helpers/constants/_common/mobile';

import { DispatchType, Redux } from '../../helpers/types/_common';
import { clearError } from '../../redux/ErrorHandler/actions';
import { ErrorHandlerError } from './helpers/types';
import S from './styles.module.sass';

const ErrorHandler: FC = () => {
  const dispatch: DispatchType = useDispatch();
  const error = useSelector((state: Redux) => state.errorHandler);
  const { innerWidth: screenWidth } = window;

  const showError = (e: ErrorHandlerError) => {
    message.error({
      content: e?.error || '',
      className: (screenWidth > BREAKPOINT_TABLET_WIDE) ? S.desktopErrorModal : S.mobileErrorModal,
      key: e.errorId,
      onClose: () => dispatch(clearError()),
    });
  };

  useEffect(() => {
    if (!_.isEmpty(error)) {
      showError(error);
    }
  }, [ error ]);

  return null;
};
export default ErrorHandler;
