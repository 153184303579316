import { DispatchType } from 'helpers/types/_common';
import * as TablesActions from 'helpers/constants/_common/actions/Tables';
import Subtypes from 'V1/constants/__common__/Subtypes';
import * as partnerRequests from 'api/Partners/requests';
import { GetPartnerParticipantsBody } from 'api/Partners/types';

export const getPartnersTable = (params: any, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return partnerRequests.getPartnersTable(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const getPartnerParticipantsAction = (partnerId: number, params: GetPartnerParticipantsBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return partnerRequests.getPartnerParticipantsTable(partnerId, params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};
