import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Button, Spin } from 'antd';

import BasicTable from 'components/_shared/Table';
import { Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { SpecialistsRow } from 'api/Specialists/types';
import EngKnowledgeLevel from 'components/_shared/EngKnowledgeLevel';
import { UserDetails } from 'api/User/types';
import buttons from 'helpers/styles/components/buttons.module.sass';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { Meta, Sorter } from 'api/_request/types';
import { DEFAULT_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';
import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';

import EmployeeDrawer from '../../../../../../../Drawers/EmployeeDrawer';
import { attitudesColumns, updateColumns } from '../../../../../../helpers/helpers';
import { MAX_ELEMENTS_FOR_SCROLL } from '../../../../../../helpers/constants';
import S from './helpers/styles.module.sass';
import { RequestSettingsState } from '../../helpers/types';
import { EmployeeDrawerData } from '../../../../../../../Drawers/EmployeeDrawer/helpers/types';

const cx = classNames.bind(S);

export interface RequestSpecialistsTableProps {
  settings: RequestSettingsState;
  fetchRequestCandidates: (pagination: Meta, sorter?: Sorter) => void;
  handleRemoveFromCandidate: (userId: number) => Promise<any>;
  isViewMode: boolean;
}

const CandidatesTable: React.FC<RequestSpecialistsTableProps> = ({ settings, fetchRequestCandidates, handleRemoveFromCandidate, isViewMode }) => {
  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.REQUEST_CANDIDATES]);

  const [ candidateId, setCandidateId ] = useState<number | null>(null);
  const [ drawerCandidateLoading, setDrawerCandidateLoading ] = useState(false);
  const [ employeeDrawerData, setEmployeeDrawerData ] = useState<EmployeeDrawerData>({ userId: undefined, visible: false });

  useEffect(() => {
    if (settings?.requestData) {
      fetchRequestCandidates(DEFAULT_PAGINATION_RAW, sorter);
    }
  }, [ settings.skillsColumns ]);

  const onCell = (record: SpecialistsRow) => ({
    onClick: () => {
      setEmployeeDrawerData({ userId: record.userId, visible: true });
    },
  });

  const onCloseDrawer = () => {
    setEmployeeDrawerData({ userId: undefined, visible: false });

    setDrawerCandidateLoading(false);
  };

  const renderColumns = {
    fullName: {
      onCell,
      render: (text: string, record: SpecialistsRow) => {
        const logo = record.avatarUrl
          ? <img src={record.avatarUrl} alt="avatar" className={cx('author-avatar')} />
          : <TrackerLogo className={cx('author-avatar', 'author-avatar--qs')} />;

        return (
          <div className={cx('name-cell')}>
            {logo}
            {`${record.forename} ${record.surname}`}
          </div>
        );
      },
    },
    occupation: {
      onCell,
      render: (text: string) => OCCUPATIONS[text].name,
    },

    englishKnowledge: {
      onCell,
      render: (engLevel: string) => <EngKnowledgeLevel englishKnowledgeLevel={engLevel} />,
    },

    ...attitudesColumns(settings.skills, onCell),
  };

  const handleRemoveCandidate = (record: SpecialistsRow) => {
    if (candidateId) {
      return;
    }

    setCandidateId(record.userId);

    return handleRemoveFromCandidate(record.userId)
      .finally(() => {
        setCandidateId(null);
      });
  };

  const drawerCandidateButton = (user?: UserDetails) => {
    if (isViewMode) {
      return null;
    }

    const removeCandidate = () => {
      if (!user?.userId) {
        return;
      }
      setDrawerCandidateLoading(true);

      return handleRemoveFromCandidate(user.userId)
        .finally(() => onCloseDrawer());
    };

    return (
      <Button
        className={buttons.qsButtonPrimary}
        type="primary"
        key={user?.userId}
        loading={drawerCandidateLoading}
        onClick={removeCandidate}
      >
        <div className={buttons.iconTextWrapper}>
          <FontAwesomeIcon icon="minus-circle" />
          Remove from Candidates
        </div>

      </Button>
    );
  };

  const rowSelection = {
    renderCell: (checked: boolean, record: SpecialistsRow) => (
      <FontAwesomeIcon
        icon={record.userId === candidateId ? 'spinner' : [ 'fas', 'star' ]}
        onClick={() => handleRemoveCandidate(record)}
        className={cx('star-select', { 'star-select--loading': record.userId === candidateId })}
        spin={record.userId === candidateId}
      />
    ),
    columnTitle: <FontAwesomeIcon icon={[ 'fas', 'star' ]} style={{ color: '#727981' }} />,
  };

  const renderEmptyCandidates = () => {
    if (!_.isEmpty(objects)) {
      return null;
    }

    if (isViewMode) {
      return (
        <div className={cx('empty-candidates-title')}>No engineers was starred for this project.</div>
      );
    }

    return (
      <Spin spinning={loading} wrapperClassName={cx('empty-candidates-title')}>
        No engineers was starred for this project yet. Use&nbsp;
        <FontAwesomeIcon icon={[ 'fas', 'star' ]} style={{ color: '#727981' }} />
        &nbsp;icon to select candidates
      </Spin>
    );
  };

  const renderTable = () => {
    if (_.isEmpty(objects)) {
      return null;
    }

    return (
      <BasicTable
        initialRequest={false}
        tableName={TABLE_NAMES.REQUEST_CANDIDATES}
        getData={fetchRequestCandidates}
        rowKey="userId"
        columns={updateColumns(false, settings.skillsColumns)}
        className={cx('candidates_table', { 'no-data': _.isEmpty(objects) })}
        pagination={meta}
        loading={loading}
        dataSource={objects}
        sortDirections={[ 'descend', 'ascend' ]}
        scroll={{ x: 1000, y: objects.length > MAX_ELEMENTS_FOR_SCROLL ? 180 : undefined }}
        rowClassName={cx('bordered-row')}
        rowSelection={isViewMode ? undefined : rowSelection}
        renderColumns={renderColumns}
      />
    );
  };

  return (
    <div className={cx('candidates')}>
      <div className={cx('candidates_total')}>
        Candidates
        {renderEmptyCandidates()}
      </div>
      <div>
        {renderTable()}
      </div>

      <EmployeeDrawer
        drawerData={employeeDrawerData}
        onClose={onCloseDrawer}
        externalButtons={[ drawerCandidateButton ]}
      />
    </div>
  );
};

export default CandidatesTable;
