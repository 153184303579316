import _ from 'lodash';
import * as Actions from '../constants/MainTable';
import Subtypes from '../constants/__common__/Subtypes';
import merge from '../utils/merge';

let InitialState = {
  info: '',
  error: true,
  isLoading: false,
  errorWhileChecking: false,
  errorWhileClosing: false,
  errorWhileAdding: false,
  deleteStarted: false,
  currentMonthIsSubmitted: true
};

function changingTimesheets(state, data) {
  let newState = _.cloneDeep(state);
  let changedActivity, activityExists;

  for(let i = 1; i < 32; i++) {
    for(let j = 0; j < 24; j++) {
      for(let k = 0; k < 2; k++) {
        activityExists = newState[i] && newState[i][j] && newState[i][j][k];
        changedActivity = data.find(e => activityExists && newState[i][j][k].activityId === e.activityId && newState[i][j][k].partnerId === e.partnerId); // eslint-disable-line no-loop-func
        if(changedActivity) {
          newState[i][j][k] = {
            added: true,
            frameIndex: k,
            partnerId: changedActivity.newPartner,
            activityId: changedActivity.newActivity,
            partnerColor: changedActivity.newPartnerColor,
            activityColor: changedActivity.newActivityColor,
            mask: changedActivity.mask,
            fullCell: newState[i][j][k].fullCell
          };
        }
      }
    }
  }
  return newState;
}

function changeAfterReplaceAndFillActivity(state, changedData, activityColors) {
  const { partnerColor, activityColor } = activityColors;
  const { activityId, partnerId } = changedData;
  let data = _.cloneDeep(state);
  let startDate = new Date(changedData.startTime);
  let endDate = new Date(changedData.endTime);
  let day = `${startDate.getDate()}-${startDate.getMonth()}`;
  let startTime = {
    hour: startDate.getHours(),
    minutes: startDate.getMinutes()
  };
  let endTime = {
    hour: endDate.getHours(),
    minutes: endDate.getMinutes()
  };
  let tsTemplate = (index, fullCell) => {
    return {
      frameIndex: index,
      partnerId: partnerId,
      activityId: activityId,
      partnerColor: partnerColor,
      mask: changedData.mask,
      activityColor: activityColor,
      added: true,
      fullCell
    }
  };

  let sameDay = startDate.getDate() === endDate.getDate();

  let cycleCondition = {
    start: startTime.minutes === 0 ? startTime.hour : startTime.hour + 1,
    end: (endTime.hour === 0 && startTime.hour === 0 && !sameDay) ||
    (endTime.hour === 0 && startTime.hour !== 0 ? 24 : endTime.hour)
  };
  for(let i = cycleCondition.start; i < cycleCondition.end; i++) {
    if(!data[day]) data[day] = {};
    if(!data[day][i]) data[day][i] = {};
    data[day][i][0] = tsTemplate(0, true, i);
    data[day][i][1] = tsTemplate(1, true, i);
  }
  if(startTime.minutes !== 0) {
    if(!data[day][startTime.hour]) data[day][startTime.hour] = {};
    data[day][startTime.hour][1] = tsTemplate(1, false, startTime.hour);
    if(data[day] && data[day][startTime.hour] && data[day][startTime.hour][0] && data[day][startTime.hour][0].fullCell) {
      data[day][startTime.hour][0].fullCell = false;
    }
  }

  if(endTime.minutes !== 0) {
    if(!data[day][cycleCondition.end]) data[day][cycleCondition.end] = {};
    data[day][cycleCondition.end][0] = tsTemplate(0, false, cycleCondition.end);
    if(data[day] && data[day][cycleCondition.end] && data[day][cycleCondition.end][1] && data[day][cycleCondition.end][1].fullCell) {
      data[day][cycleCondition.end][1].fullCell = false;
    }
  }
  return { ...merge(state, data) };
}

function changeAfterReplaceActivity(state, changedData, activityColors) {
  const { partnerColor, activityColor } = activityColors;

  let partnerId = changedData.partners[0].newPartner;
  let activityId = changedData.partners[0].newActivity;
  let mask = changedData.partners[0].mask;
  let data = _.cloneDeep(state);
  let startDate = new Date(changedData.start.split('-').join('/'));
  let endDate = new Date(changedData.end.split('-').join('/'));
  let day = `${startDate.getDate()}-${startDate.getMonth()}`;
  let startTime = {
    hour: startDate.getHours(),
    minutes: startDate.getMinutes()
  };
  let endTime = {
    hour: endDate.getHours(),
    minutes: endDate.getMinutes()
  };

  let tsTemplate = (index, hour, fullCell) => {
    return {
      frameIndex: index,
      partnerId: partnerId,
      activityId: activityId,
      partnerColor: partnerColor,
      activityColor: activityColor,
      added: true,
      mask,
      fullCell
    }
  };

  let sameDay = startDate.getDate() === endDate.getDate();

  let cycleCondition = {
    start: startTime.minutes === 0 ? startTime.hour : startTime.hour + 1,
    end: (endTime.hour === 0 && startTime.hour === 0 && !sameDay) ||
    (endTime.hour === 0 && startTime.hour !== 0 ? 24 : endTime.hour)
  };

  for(let i = cycleCondition.start; i < cycleCondition.end; i++) {
    if(data[day] && data[day][i] && data[day][i][0]) {
      data[day][i][0] = tsTemplate(0, i, data[day][i][0].fullCell);
    }
    if(data[day] && data[day][i] && data[day][i][1]) {
      data[day][i][1] = tsTemplate(1, i, data[day][i][1].fullCell);
    }
  }
  if(startTime.minutes !== 0) {
    if(data[day] && data[day][startTime.hour] && data[day][startTime.hour][1]) {
      data[day][startTime.hour][1] = tsTemplate(1, startTime.hour, false);
    }
    if(data[day] && data[day][startTime.hour] && data[day][startTime.hour][0] && data[day][startTime.hour][0].fullCell) {
      data[day][startTime.hour][0].fullCell = false;
    }
  }

  if(endTime.minutes !== 0) {
    if(data[day] && data[day][cycleCondition.end] && data[day][cycleCondition.end][0]) {
      data[day][endTime.hour][0] = tsTemplate(1, endTime.hour, false);
    }
    if(data[day] && data[day][cycleCondition.end] && data[day][cycleCondition.end][1] && data[day][cycleCondition.end][1].fullCell) {
      data[day][cycleCondition.end][1].fullCell = false;
    }
  }

  return { ...merge(state, data) };
}

function successWhilePosting(state, changedData) {
  let newState = _.cloneDeep(state);

  let date = Object.keys(changedData)[0];
  let firstHour = Object.keys(changedData[date])[0];
  let isSecondHalfFirst = parseInt(Object.keys(changedData[date][firstHour])[0]) === 1;
  if(isSecondHalfFirst && newState[date] && newState[date][firstHour] && newState[date][firstHour][0]) {
    newState[date][firstHour][0].fullCell = false;
  }

  Object.keys(changedData[date]).forEach(hour => {
    let cell = changedData[date][hour];
    if(cell[0]) {
      if(!newState[date]) newState[date] = {};
      if(!newState[date][hour]) newState[date][hour] = {};
      newState[date][hour][0] = { ...cell[0], added: true };
    }
    if(cell[1]) {
      if(!newState[date]) newState[date] = {};
      if(!newState[date][hour]) newState[date][hour] = {};
      newState[date][hour][1] = { ...cell[1], added: true };
    }
  });
  return newState;
}

function successWhileDeleting(state, changedData) {
  let data = _.cloneDeep(state);
  let day = Object.keys(changedData)[0];

  let firstHour = Object.keys(changedData[day])[0];
  let isSecondHalfFirst = parseInt(Object.keys(changedData[day][firstHour])[0]) === 1;
  if(isSecondHalfFirst && data[day] && data[day][firstHour] && data[day][firstHour][0]) {
    data[day][firstHour][0].fullCell = false;
  }

  Object.keys(changedData[day]).forEach(hour => {
    let cell = changedData[day][hour];
    if(cell[0] && cell[1]) {
      delete data[day][hour];
    } else {
      if(cell[0]) delete data[day][hour][0];
      if(cell[1]) delete data[day][hour][1];
    }
  });
  return { ...data, deleteStarted: false };
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CLEAR_TABLE:
      return {
        ...InitialState,
        currentMonthIsSubmitted: state.currentMonthIsSubmitted,
      };
    case Actions.LOAD_TIME_SHEET_REQUESTED:
      return { ...state, info: 'Loading timesheet: in progress', isLoading: true };
    case Actions.CHANGE_CALENDAR_TAB_MARK:
      return { ...state, currentMonthIsSubmitted: action.payload };
    case Actions.LOAD_TIME_SHEET_OK:
      return {
        ...merge(InitialState, action.payload.data),
        info: action.payload.message,
        error: state.error,
        errorWhileChecking: state.errorWhileChecking,
        errorWhileClosing: state.errorWhileClosing,
        currentMonthIsSubmitted: state.currentMonthIsSubmitted,
        isLoading: false,
        deleteStarted: false
      };
    case Actions.LOAD_TIME_SHEET_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case Actions.LOAD_TIME_SHEET:
      return loadTimeSheet(state, action);

    case Actions.REPLACE_TIMESHEETS:
      switch (action.subtype) {
        case Subtypes.Request.Success:
          return changeAfterReplaceActivity(state, action.payload.data, action.payload.activityColors);
        case Subtypes.Request.Loading:
        case Subtypes.Request.Error:
        default:
          return state;
      }
    case Actions.FAST_ADDING:
      return {
        ...merge(state, action.payload),
        info: state.message,
        error: state.error,
        errorWhileChecking: state.errorWhileChecking,
        errorWhileClosing: state.errorWhileClosing
      };
    case Actions.POST_TIME_SHEET_ERROR_FIXED:
      return { ...state, errorWhileAdding: false };
    case Actions.POST_TIME_SHEET:
      return postTimeSheet(state, action);
    case Actions.CHANGE_TIMESHEETS:
      return changingTimesheets(state, action.payload);
    case Actions.DELETE_TIME_SHEET:
      return deleteTimeSheet(state, action);
    case Actions.REPLACE_AND_FILL_TIMESHEETS:
      switch (action.subtype) {
        case Subtypes.Request.Success:
          return changeAfterReplaceAndFillActivity(state, action.payload.data, action.payload.activityColors);
        case Subtypes.Request.Error:
        default:
          return state;
      }
    case Actions.REPLACE_TIMESHEETS:
      switch (action.subtype) {
        case Subtypes.Request.Success:
          return changeAfterReplaceActivity(state, action.payload.data, action.payload.activityColors);
        case Subtypes.Request.Loading:
        case Subtypes.Request.Error:
        default:
          return state;
      }
    default:
      return state;
  }
}

function loadTimeSheet(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, info: 'Loading timesheet: in progress' };
    case Subtypes.Request.Success:
      return {
        ...merge(InitialState, action.payload.data),
        info: action.payload.message,
        error: state.error,
        errorWhileChecking: state.errorWhileChecking,
        errorWhileClosing: state.errorWhileClosing,
        currentMonthIsSubmitted: state.currentMonthIsSubmitted,
        deleteStarted: false
      };
    case Subtypes.Request.Error:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

function postTimeSheet(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, info: 'Posting timesheet: in progress' };
    case Subtypes.Request.Success:
      return successWhilePosting(state, action.payload)
    case Subtypes.Request.Error:
      return { ...state, errorWhileAdding: true };
    default:
      return state;
  }
}

function deleteTimeSheet(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...merge(state, action.payload), deleteStarted: true };
    case Subtypes.Request.Success:
      return successWhileDeleting(state, action.payload);
    case Subtypes.Request.Error:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
