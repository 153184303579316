import { Column } from 'components/_shared/Table/helpers/types';

export const HOUR_REPORT_COLUMNS: Column[] = [
  {
    title: 'Partner',
    dataIndex: 'partnerName',
    key: 'partnerName',
    width: 300,
    sorter: true,
  },
  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    width: 200,
  },
  {
    title: 'Hrs',
    dataIndex: 'partnerHours',
    key: 'partnerHours',
    width: 200,
    sorter: true,
  },
];

export const MOBILE_HOUR_REPORT_COLUMNS: Column[] = [
  {
    title: 'Partner / Activity',
    dataIndex: 'partnerName',
    key: 'partnerName',
    sorter: true,
  },
  {
    title: 'Payment / Hrs',
    dataIndex: 'statistic',
    key: 'statistic',
    align: 'right',
    width: 120,
  },
];
