/* eslint-disable import/prefer-default-export */
import { DispatchType } from 'helpers/types/_common';
import * as TablesActions from 'helpers/constants/_common/actions/Tables';
import Subtypes from 'V1/constants/__common__/Subtypes';
import * as partnerRequests from 'api/Partners/requests';
import { GetPartnersUsersBody } from 'components/PartnersUsers/types';

export const getPartnersUsersTable = (params: GetPartnersUsersBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return partnerRequests.getPartnersUsersTable(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};
