import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { CSSProperties } from 'hoist-non-react-statics/node_modules/@types/react';

import S from './helpers/styles.module.css';
import TrackerLogo from '../TrackerLogo/TrackerLogo';

interface AvatarProps {
  alt: string;
  src: string;
  size?: 'small' | 'medium';
  styles?: CSSProperties;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const { src, alt, styles, className = '', size = 'small' } = props;
  const [ withError, setError ] = useState(false);
  const sizeExists = Boolean(size) && S[`${size}`];
  const cssClasses = classNames(S.qsAvatarWrapper, className, { [S[`${size}`]]: sizeExists });

  const onError = () => {
    setError(true);
  };

  return (
    <div className={cssClasses} style={styles}>
      <TrackerLogo className={classNames(S.noAvatar, { [S.hidden]: !withError })} />
      <img src={src} alt={alt} onError={onError} className={classNames(S.avatarImage, { [S.hidden]: withError })} />
    </div>    
  );
};

export default Avatar;
