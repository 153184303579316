import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import selects from 'helpers/styles/components/selects.module.sass';

import s from './styles.module.sass';
import { PARTNER_IS_OVERTIMES_ALLOWED } from './helpers/constants';

interface IsOvertimesAllowedSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const IsOvertimesAllowedSelect: React.FC<IsOvertimesAllowedSelectProps> = ({ value, onChange }) => {
  const isOvertimesAllowedOptions = _.map(PARTNER_IS_OVERTIMES_ALLOWED, (o, key) => (
    <Select.Option key={key} value={o.value}>
      {o.text}
    </Select.Option>
  ));

  return (
    <Select
      className={classNames(selects.qsSelect, s.timeSelect, { [s.overdue]: true })}
      dropdownClassName={selects.selectDropdownWide}
      suffixIcon={<SelectIcon />}
      size="large"
      onChange={onChange}
      value={value}
      bordered={false}
      style={{ width: '180px' }}
      dropdownMatchSelectWidth={224}
      dropdownRender={menu => (
        <div>
          <span className={s.selectDescription}>Select overtimes allowance</span>
          {menu}
        </div>
      )}
    >
      {isOvertimesAllowedOptions}
    </Select>
  );
};

export default IsOvertimesAllowedSelect;
