import React from 'react';
import S from '../styles.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(S);

interface LayoutColComponentProps {
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  grow?: boolean;
  align?: string;
}

export default class Col extends React.Component<LayoutColComponentProps, {}> {
  render(): React.ReactNode {
    const { children, size, className, style, grow } = this.props;

    const colClass = cx(
      {
        'qs-layout__column': true,
        ['qs-layout__column--size-' + size]: size,
        'qs-layout__column--grow': grow
      },
      className
    );

    return (
      <div className={colClass} style={style}>
        {children}
      </div>
    );
  }
}
