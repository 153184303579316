import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import popover from 'helpers/styles/components/popover.module.sass';
import { grantCuratorStatus, revokeCuratorStatus, unassignUserFromPartner } from 'api/Partners/requests';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES, TAB_PARTNER_PARTICIPANTS } from 'helpers/constants/_common/MenuCases';
import { EDIT_PROFILE_TABS } from 'helpers/constants/_common/tabs/profile';

import { ParticipantsThreeDotsPopoverProps } from './helpers/types';
import s from './helpers/styles.module.sass';
import { UserRoles } from '../../helpers/types';

const ParticipantsThreeDotsPopover: React.FC<ParticipantsThreeDotsPopoverProps> = ({ partner, record, refreshTable }) => {
  const [ isPopoverVisible, setPopoverVisible ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);

  const { pathname } = useLocation();
  
  const isUserCurator = _.includes(record.roles, UserRoles.Curator);
  
  const handleCuratorStatusChange = () => {
    setLoading(true);

    const request = isUserCurator ? revokeCuratorStatus : grantCuratorStatus;

    request(partner.partnerId, record.user.userId)
      .then(refreshTable)
      .finally(() => setLoading(false));

    setPopoverVisible(false);
  };

  const handleUnassignFromPartner = () => {
    setLoading(true);
    setPopoverVisible(false);
    unassignUserFromPartner(partner.partnerId, record.user.userId)
      .then(refreshTable)
      .finally(() => setLoading(false));
  };

  const onVisibleChangeHandler = (visible: boolean) => {
    setLoading(false);
    setPopoverVisible(visible);
  };

  const renderPopoverContent = () => (
    <div className={popover.dotsPopoverItemWrapper}>
      <Link
        title="Show timesheet"
        to={{
          pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${record.user.userId}/${EDIT_PROFILE_TABS.timesheet.path}`,
          state: { from: TAB_PARTNER_PARTICIPANTS, pathname },
        }}
        className={classNames(popover.dotsPopoverItem, s.popoverButton)}
      >
        Show timesheet
      </Link>
      <Button className={classNames(popover.dotsPopoverItem, s.popoverButton)} onClick={handleCuratorStatusChange}>
        {`${isUserCurator ? 'Revoke' : 'Grant'} curator permission`}
      </Button>
      <Button className={classNames(popover.dotsPopoverItem, s.popoverButton)} onClick={handleUnassignFromPartner}>
        Unassign from partner
      </Button>
    </div>
  );

  return (
    <div className={classNames(popover.dotsPopover, s.threeDotsPopover)}>
      <Popover
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={onVisibleChangeHandler}
        content={renderPopoverContent()}
      >
        <div className="three-dots-button">
          <Button type="text" size="small" className={classNames(s.threeDotsButton, 'action-column-button')}>
            {
              isLoading
                ? <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin />
                : <FontAwesomeIcon className={s.skillTagRowIcon} icon="ellipsis-v" />
            }
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default ParticipantsThreeDotsPopover;
