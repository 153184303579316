import { Button, Popover } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import popover from 'helpers/styles/components/popover.module.sass';

import s from './helpers/style.module.sass';
import { ReactComponent as HamburgerIcon } from '../../../../../../helpers/icons/Buttons/hamburger.svg';

export type PopoverVisibleFunction = (visible: boolean) => void;

interface SidebarCollapseButtonProps {
  popoverContent: (visibleFunc: PopoverVisibleFunction) => React.ReactNode;
  setCollapsed?: (collapsed: boolean) => void;
  collapsed?: boolean;
  tabName?: string;
}

const SidebarCollapseMenu: React.FC<SidebarCollapseButtonProps> = ({ popoverContent, collapsed, tabName }) => {
  const [ popoverVisible, setPopoverVisible ] = useState(false);

  return (
    <Popover
      content={popoverContent(setPopoverVisible)}
      placement="rightTop"
      trigger="click"
      overlayClassName={popover.dotsPopoverOverlay}
      visible={popoverVisible}
      onVisibleChange={visible => setPopoverVisible(visible)}
    >
      <Button
        title="Menu"
        type="text"
        className={cx(s.sidebarHamburger, { [s.showHamburger]: collapsed })}
      >
        <span>
          <HamburgerIcon />
        </span>
        <span className={cx(s.sidebarMenuTitle, { [s.showHamburger]: collapsed })}>
          {tabName}
        </span>
      </Button>
    </Popover>
  );
};

export default SidebarCollapseMenu;
