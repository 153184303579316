import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import modal from 'antd/lib/modal';
import _ from 'lodash';

import { UsersBCDay, UsersBCMonth } from 'api/TimeSheet/types';
import { DEFAULT_DATE_FORMAT, FULL_MONTH_YEAR_FORMAT } from 'helpers/constants/_common/timeFormats';
import { DispatchType, Redux } from 'helpers/types/_common';
import modals from 'helpers/styles/components/modals.module.sass';
import { changeLockMonthStatus } from 'redux/TimeSheet/action';
import { ReactComponent as CloseIcon } from 'components/Mobile/MobileTimesheet/components/MobilePanel/helpers/closeIcon.svg';
import { ReactComponent as LockIcon } from 'helpers/icons/MobileLockIcon.svg';
import { ReactComponent as UnlockIcon } from 'helpers/icons/MobileUnlockIcon.svg';
import withRightPlural from 'helpers/utils/withRightPlural';

import { ReactComponent as PlusIcon } from './helpers/plusIcon.svg';
import S from './helpers/styles.module.sass';
import { MobileTimesheetPanel } from '../../helpers/types';

interface RegularPanelProps {
  changePanel: (panelName: MobileTimesheetPanel) => void;
  BCMonth: UsersBCMonth;
  selectedDateObj: Dayjs;
  selectedDayObj: UsersBCDay;
  isBCLoading: boolean;
}

const RegularPanel: React.FC<RegularPanelProps> = ({ changePanel, isBCLoading, selectedDayObj, selectedDateObj, BCMonth }) => {
  const dispatch: DispatchType = useDispatch();
  const { timeSheet, login } = useSelector((state: Redux) => state);
  const { isMonthClosed } = timeSheet.tableSettings;
  const trustedSelectedBCDay = _.find(BCMonth.days, dayObj => dayObj.day === selectedDayObj.day) || selectedDayObj;

  const isToday = trustedSelectedBCDay.day === dayjs().format(DEFAULT_DATE_FORMAT);
  const isAdmin = login.permissions.includes('ADMIN');

  const changeLockMonthStatusHandler = () => {
    if (!isAdmin && isMonthClosed) return; // user can't unlock locked month

    return dispatch(changeLockMonthStatus({
      userId: login.userId,
      month: BCMonth.month,
      year: BCMonth.year,
      isMonthClosed: !isMonthClosed,
    }));
  };

  const renderIcons = () => (
    <div className={classNames(S.iconsWrapper, { [S.locked]: isMonthClosed })}>
      <LockIcon className={S.lockIcon} />
      <UnlockIcon className={S.unlockIcon} />
    </div>
  );

  const closeMonthHandler = () => {
    modal.confirm({
      className: classNames(modals.mobileQsBasicAntdModal),
      title: 'Close month',
      content: `Are you sure you want to close ${selectedDateObj.format(FULL_MONTH_YEAR_FORMAT)}? Editing timesheet in closed month is not allowed.`,
      centered: true,
      closable: true,
      maskClosable: true,
      closeIcon: <CloseIcon stroke="#9BA3AC" />,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk: changeLockMonthStatusHandler,
      okText: 'Close Month',
    });
  };

  return (
    <div className={S.defaultPanel}>
      <div className={S.mainTextWrapper}>
        {isBCLoading ? (
          <LoadingOutlined className={S.spinner} spin />
        ) : (
          <>
            <span className={S.alreadyWorkedHours}>{`${trustedSelectedBCDay.hours} ${withRightPlural('hour', trustedSelectedBCDay.hours)}`}</span>
            <span className={classNames(S.dateMarker, { [S.today]: isToday })}>Today</span>
          </>
        )}
      </div>
      <div className={S.buttonsSection}>
        <Button
          className={classNames(S.secondButton, S.listButton)}
          onClick={() => changePanel(MobileTimesheetPanel.UserHoursList)}
          icon={<FontAwesomeIcon icon="list" />}
        />
        <Button
          className={classNames(S.addTimeButton, { [S.locked]: isMonthClosed })}
          disabled={isMonthClosed}
          onClick={() => changePanel(MobileTimesheetPanel.ChooseActivity)}
          icon={<PlusIcon />}
        />
        <div className={classNames(S.lockButtonWrapper, {
          [S.locked]: isMonthClosed,
        })}
        >
          <Button
            className={classNames(S.secondButton, S.lockButton)}
            disabled={isMonthClosed}
            onClick={closeMonthHandler}
            icon={renderIcons()}
          />
        </div>
      </div>
    </div>
  );
};

export default RegularPanel;
