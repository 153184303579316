import React from 'react';
import { Input, InputProps } from 'antd';
import _ from 'lodash';
import cx from 'classnames';

import inputs from 'helpers/styles/components/inputs.module.sass';

import s from './helpers/styles.module.sass';
import { inputErrorText } from './helpers/helpers';

interface InputWithErrorProps extends InputProps {
  error?: any;
  fieldName: string | string[];
}

const InputWithError: React.FC<InputWithErrorProps> = (props) => {
  const { error, fieldName } = props;

  const errorText = inputErrorText(fieldName, error);

  const renderErrorText = () => _.map(errorText, (e, i) => (
    <small className={s.errorMessage} key={i}>{e}</small>
  ));

  const customProps = _.omit(props, [ 'error', 'fieldName' ]);

  return (
    <>
      <Input
        {...customProps}
        className={cx(props.className, { [inputs.error]: !_.isEmpty(errorText) })}
      />

      { renderErrorText() }
    </>
  );
};

export default InputWithError;
