import React from 'react';

import { UserDetails } from 'api/User/types';
import toCamelCase from 'V1/utils/toCamelCase';

import s from './helpers/styles.module.sass';
import Avatar from '../Avatar';

interface ProfileViewFieldProps {
  user: UserDetails;
}

const ProfileViewField: React.FC<ProfileViewFieldProps> = ({ user }) => {
  const { forename, surname, avatarUrl, title } = user;

  const CCForename = toCamelCase(forename);
  const CCSurname = toCamelCase(surname);

  return (
    <div className={s.pvfWrapper}>
      <div className={s.pvfAvatarWrapper}>
        <Avatar src={avatarUrl} alt={`${CCForename} ${CCSurname}`} size="medium" />
      </div>
      <h4 className={s.pvfNameTitle} title={`${CCForename} ${CCSurname}`}>{`${CCForename} ${CCSurname}`}</h4>
      <span className={s.pvfCustomTitle}>{title || ''}</span>
    </div>
  );
};

export default ProfileViewField;
