import React, { useEffect } from 'react';
import _ from 'lodash';
import { PaginationProps, Table, TableProps } from 'antd';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { FilterValue, TableCurrentDataSource } from 'antd/lib/table/interface';

import { TableNames } from 'helpers/constants/Tables/tableList';

import { Column, RenderColumnsObject, TablePagination } from './helpers/types';
import { updateColumnsByFields } from './helpers/renderColumns';
import { convertSortForBackend, updateFieldsBySorter } from './helpers/sorter';
import S from './helpers/styles.module.sass';
import { clearSkillTagsTableData, setSkillTagsSorter } from '../../../redux/Tables/actions';
import { DispatchType, Redux, ValueOf } from '../../../helpers/types/_common';
import { convertPaginationForAntd, convertPaginationForBackend } from './helpers/pagination';
import { Meta, Sorter } from '../../../api/_request/types';
import { DEFAULT_PAGINATION_RAW } from '../../../helpers/constants/_common/Pagination';

const cx = classNames.bind(S);

export interface BasicTableProps extends Omit<TableProps<any>, 'pagination' | 'onChange' | 'columns'> {
  pagination: TablePagination | boolean;
  renderColumns?: RenderColumnsObject;
  tableName: ValueOf<TableNames>;
  getData?: (pagination: Meta, sorter?: Sorter) => any;
  onChange?: (pagination: Meta, filters: Record<string, FilterValue | null>, sorter: Sorter, extra: TableCurrentDataSource<any>) => void;
  initialRequest?: boolean;
  columns: Column[];
}

const BasicTable: React.FC<BasicTableProps> = (props) => {
  const dispatch: DispatchType = useDispatch();
  const { renderColumns, columns, rowSelection, onChange, tableName, getData, pagination, initialRequest = true, sortDirections } = props;

  const { sorter } = useSelector((state: Redux) => state.tables[tableName]);

  /* Initial request, if getData props it is. To prevent - initialRequest=false */
  useEffect(() => {
    if (getData && initialRequest) {
      getData(_.isBoolean(pagination) ? {} : pagination, sorter);
    }
  }, []);

  const defaultRowSelection = () => {
    if (!props.rowSelection) {
      return undefined;
    }

    return {
      columnWidth: 50,
      ...rowSelection,
    };
  };

  const onChangeTable = (pagination: PaginationProps, filter: any, tableSorter: any, extra: TableCurrentDataSource<Column>) => {
    const isSortAction = extra?.action === 'sort';
    const mappedPagination = _.isEmpty(pagination) ? {} : { limit: pagination.pageSize, offset: DEFAULT_PAGINATION_RAW.offset };
    const updatedPagination = convertPaginationForBackend(pagination);
    const updatedSort = convertSortForBackend(tableSorter, true);

    dispatch(setSkillTagsSorter(updatedSort, tableName));

    // reset pagination if sort changed
    getData?.(isSortAction ? mappedPagination : updatedPagination, updatedSort);
    onChange?.(updatedPagination, filter, updatedSort, extra);
  };

  /* Clear data in redux, when component destroyed */
  useEffect(() => () => dispatch(clearSkillTagsTableData(tableName)), [ dispatch ]);

  /*
    Update columns by renderColumns object (You can add any Column prop thru renderColumns)
    Also control sortOrder field of column
   */
  const completedColumns = renderColumns
    ? updateColumnsByFields(columns, renderColumns, sorter, sortDirections)
    : updateFieldsBySorter(columns, sorter, sortDirections);

  const clearedProps = _.omit(props,
    [
      'onChangeRowSelection',
      'selectedRowKeys',
      'renderColumns',
      'columns',
      'selectedRowKeys',
      'rowSelection',
      'tableName',
      'onChange',
      'getData',
      'initialRequest',
      'pagination',
    ]);

  const convertedPagination = () => {
    if (_.isBoolean(pagination)) {
      return false;
    }

    return {
      showSizeChanger: true,
      ..._.omit(pagination, [ 'limit', 'offset', 'totalCount', 'previous', 'next' ]),
      ...convertPaginationForAntd(pagination),
    };
  };

  return (
    <Table
      sortDirections={[ 'ascend', 'descend', 'ascend' ]}
      {...clearedProps}
      pagination={convertedPagination()}
      className={cx('basic-table', props.className)}
      columns={completedColumns}
      onChange={onChangeTable}
      rowSelection={defaultRowSelection()}
      showSorterTooltip={false}
      locale={{
        emptyText: 'No data',
        ...props.locale,
      }}
    />
  );
};

export default BasicTable;
