export const RECEIVE_PARTNERS_LIST_REQUEST = '[CURATOR HOUR REPORT] RECEIVE_PARTNERS_LIST_REQUEST ';
export const RECEIVE_PARTNERS_LIST_DATA = '[CURATOR HOUR REPORT] RECEIVE_PARTNERS_LIST_DATA ';
export const RECEIVE_PARTNERS_LIST_ERROR = '[CURATOR HOUR REPORT] RECEIVE_PARTNERS_LIST_ERROR ';
export const PARTNERS_LIST_INITIATE = '[CURATOR HOUR REPORT] PARTNERS_LIST_INITIATE ';
export const CURATOR_HOUR_REPORT_INITIATE = '[CURATOR HOUR REPORT] CURATOR_HOUR_REPORT_INITIATE ';
export const CHANGE_HOURLYRATE = '[CURATOR HOUR REPORT] CHANGE_HOURLYRATE';

export const RECEIVE_HOURREPORT_REQUEST = '[CURATOR HOUR REPORT] RECEIVE_HOURREPORT_REQUEST';
export const RECEIVE_HOURREPORT_DATA = '[CURATOR HOUR REPORT] RECEIVE_HOURREPORT_DATA';
export const RECEIVE_HOURREPORT_ERROR = '[CURATOR HOUR REPORT] RECEIVE_HOURREPORT_ERROR';

export const REMOVE_OBJECTS = '[CURATOR HOUR REPORT] REMOVE_OBJECTS';

export const CHANGE_STARTDATE = '[CURATOR HOUR REPORT] CHANGE_STARTDATE';
export const CHANGE_ENDDATE = '[CURATOR HOUR REPORT] CHANGE_ENDDATE';
export const CHANGE_PERIOD = '[CURATOR HOUR REPORT] CHANGE_PERIOD';

export const CURATORS_HOURS_PY_PROJECT_NAME_INITIATE = '[CURATOR HOUR REPORT] CURATORS_HOURS_PY_PROJECT_NAME_INITIATE';
export const RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_REQUEST = '[CURATOR HOUR REPORT] RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_REQUEST';
export const RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_DATA = '[CURATOR HOUR REPORT] RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_DATA';
export const RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_ERROR = '[CURATOR HOUR REPORT] RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_ERROR';

export const CHANGE_PARTNER_TO_FILTER = '[CURATOR HOUR REPORT] CHANGE_PARTNER_TO_FILTER';
export const CHANGE_USERNAME_TO_FILTER = '[CURATOR HOUR REPORT] CHANGE_USERNAME_TO_FILTER';
export const RESET_FILTERS = '[CURATOR HOUR REPORT] RESET_FILTERS';
