import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { ActivityCardOutput } from 'api/Partners/types';

import S from './styles.module.sass';
import { ReplacerData } from './types';

interface SelectorActivityToProps {
  isLoading: boolean;
  replaceData: ReplacerData;
  currentStep: number;
  activities: ActivityCardOutput[];
  stepToggle: (dir: 'next' | 'prev') => void;
  setReplaceData: (data: ReplacerData) => void;
}

const SelectorActivityTo: React.FC<SelectorActivityToProps> = ({ isLoading, replaceData, setReplaceData, currentStep, activities, stepToggle }) => {
  const onChange = (activityId: number) => {
    setReplaceData({
      ...replaceData,
      activityTo: _.find(activities, activity => activity.activityId === activityId) || null,
    });
    if (currentStep === 3) stepToggle('next');
  };
  
  return (
    <Select
      className={classNames(selects.qsSelect, S.reportSelect)}
      suffixIcon={isLoading ? <LoadingOutlined className={S.spinner} spin /> : <SelectIcon />}
      size="large"
      bordered={false}
      loading={isLoading}
      value={replaceData.activityTo?.activityId || null}
      disabled={_.isNull(replaceData.partnerTo)}
      onChange={onChange}
    >
      <Select.Option
        value={null}
        disabled
      >
        Pick an activity
      </Select.Option>
      {_.map(activities, activity => (
        <Select.Option
          key={activity.activityId + activity.name}
          value={activity.activityId}
        >
          {activity.name}
        </Select.Option>
      ))}
    </Select>
  );
};
export default SelectorActivityTo;
