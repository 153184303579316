import { ConfirmModalData } from './types';

const confirmModalInitData: ConfirmModalData = {
  isVisible: false,
  userId: 0,
  data: {
    day: '',
    startCellIndex: 1,
    endCellIndex: 1,
    activityId: 1,
  },
  onOk: () => {},
};

export default confirmModalInitData;
