import React from 'react';

import TableBody from './components/TableBody/TableBody';
import S from './helpers/styles.module.sass';
import { TimeSheetTableProps } from './helpers/types';

const TimeSheetTable: React.FC<TimeSheetTableProps> = (props) => {
  const {
    BCMonth,
    isBCLoading,
    userHoursTable,
    curatorOnlyView,
  } = props;

  return (
    <div className={S.table}>
      <TableBody
        BCMonth={BCMonth}
        userHoursTable={userHoursTable}
        isBCLoading={isBCLoading}
        curatorOnlyView={curatorOnlyView}
      />
    </div>
  );
};

export default TimeSheetTable;
