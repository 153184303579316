import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';

const cx = classNames.bind(S);

export default class TableHeader extends React.Component<{}, {}> {
  render(): React.ReactNode {
    return (
      <thead className={cx('qs-table__head')}>
        {this.props.children}
        <tr className={cx('qs-table__head-preloader')} />
      </thead>
    );
  }
}
