import React from 'react';
import { List } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';

import { UserPartnerOutput } from 'api/Partners/types';
import scrollbar from 'helpers/styles/components/scrollbar.module.sass';

import S from './helpers/styles.module.sass';
import ReplaceHourPartnerListItem from '../ReplaceHourPartnerListItem/ReplaceHourPartnerListItem';
import ReplaceHourPartnersListProps from './helpers/types';

const ReplaceHourPartnersList: React.FC<ReplaceHourPartnersListProps> = ({ handleSave, partners }) => {
  const renderItem = (partnerObject: UserPartnerOutput) => (
    <ReplaceHourPartnerListItem
      partnerObject={partnerObject}
      handleSave={handleSave}
    />
  );

  return (
    <div className={S.partnersList}>
      <h4 className={S.partnersListTitle}>Partners</h4>
      <Scrollbars
        className={classNames(scrollbar.customScrollbar, S.partnersCustomScrollbar)}
        renderThumbVertical={() => <div className={scrollbar.thumbVertical} />}
      >
        <List
          className={S.partnerList}
          itemLayout="horizontal"
          dataSource={partners}
          renderItem={renderItem}
        />
      </Scrollbars>
    </div>
  );
};

export default ReplaceHourPartnersList;
