import _ from 'lodash';

import { DispatchType, ValueOf } from '../../helpers/types/_common';
import * as TablesActions from '../../helpers/constants/_common/actions/Tables';
import configuredStore from '../_reducer/configureStore';
import { Sorter } from '../../api/_request/types';
import { TableNames } from '../../helpers/constants/Tables/tableList';

export const setSkillTagsSorter = (sorter: Sorter, tableName: ValueOf<TableNames>) => {
  // case:
  // you need to set default sorter inside redux,
  // so this function would be able to get it from redux, not from view
  const { sorter: reduxSorter } = configuredStore.getState().tables[tableName];

  if (!sorter && !_.isEmpty(reduxSorter)) return () => {};

  const sortObject = {
    orderBy: sorter.orderBy || reduxSorter.orderBy,
    orderDirection: sorter.orderDirection || reduxSorter.orderDirection,
  };

  return (dispatch: DispatchType) => {
    dispatch({ type: TablesActions.SET_TABLE_SORTER, sorter: sortObject, tableName });
  };
};

export const clearSkillTagsTableData = (tableName: string | boolean) => (dispatch: DispatchType) => (
  dispatch({
    type: TablesActions.CLEAR_TABLE_DATA,
    tableName,
  })
);
