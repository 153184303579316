import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { UsersBCMonth } from 'api/TimeSheet/types';
import { blankDaysGenerator } from 'components/TimeSheet/helpers/generators';
import * as BCRequests from 'api/TimeSheet/request';
import { UserDetails } from 'api/User/types';
import { UserRedux } from 'redux/User/types';
import { blankUsersBCGenerator } from 'components/Mobile/MobileTimesheet/helpers/helpers';

import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT } from '../constants/_common/timeFormats';

interface GetBusinessCalendarHookProps {
  selectedDate: string;
  user: UserDetails | UserRedux;
}

const useGetUsersBusinessCalendar = ({ selectedDate, user }: GetBusinessCalendarHookProps) => {
  const nowDayjs = dayjs();
  const selectedMonth = dayjs(selectedDate, [ MONTH_YEAR_FORMAT, DEFAULT_DATE_FORMAT ]).month() + 1;
  const selectedYear = dayjs(selectedDate, [ MONTH_YEAR_FORMAT, DEFAULT_DATE_FORMAT ]).year();

  const [ BCMonth, setBCMonth ] = useState<UsersBCMonth>(blankUsersBCGenerator(nowDayjs.format(DEFAULT_DATE_FORMAT))); // calendar month
  const [ BCIsLoading, setBCIsLoading ] = useState(false);

  const getCalendarByDate = (date: string) => {
    setBCIsLoading(true);

    return BCRequests.getUsersBusinessCalendarByDate({ userId: user.userId, date })
      .then(res => (res.days.length ? res : {
        ...res,
        days: blankDaysGenerator(dayjs(date).format(DEFAULT_DATE_FORMAT)),
      }))
      .catch(() => blankUsersBCGenerator(date));
  };

  const getCalendarByMonthAndYear = (coords: { year: number, month: number }) => {
    setBCIsLoading(true);

    const date = dayjs(`${coords.year}-${coords.month}-1`).format(DEFAULT_DATE_FORMAT);

    return BCRequests.getUsersBusinessCalendar({ ...coords, userId: user.userId })
      .then(res => (res.days.length ? res : {
        ...res,
        days: blankDaysGenerator(dayjs(date).format(DEFAULT_DATE_FORMAT)),
      }))
      .catch(() => blankUsersBCGenerator(date));
  };

  const refreshCalendar = () => {
    getCalendarByMonthAndYear({ year: selectedYear, month: selectedMonth })
      .then(async (month: any) => {
        setBCMonth(month);
        setBCIsLoading(false);
      });
  };

  useEffect(() => {
    getCalendarByDate(nowDayjs.format(DEFAULT_DATE_FORMAT))
      .then(async (month: any) => {
        setBCMonth(month);
        setBCIsLoading(false);
      });
  }, [ user.userId ]);

  useEffect(() => {
    if (BCMonth.year !== selectedYear || BCMonth.month !== selectedMonth) {
      refreshCalendar();
    }
  }, [ selectedDate, user.userId ]);

  return [
    BCMonth,
    BCIsLoading,
    setBCMonth,
    refreshCalendar,
  ] as [
    BCMonth: UsersBCMonth,
    BCIsLoading: boolean,
    setBCMonth: (BCMonth: UsersBCMonth) => void,
    refreshCalendar: () => void,
  ];
};

export default useGetUsersBusinessCalendar;
