import React, { useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import classNames from 'classnames/bind';

import { getSimpleTimeByCellIndex } from 'components/TimeSheet/helpers/helpers';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import MarkupEditor from 'components/_shared/MarkupEditor/MarkupEditor';

import S from './helpers/styles.module.sass';
import EditedUserHourCommentProps from './helpers/types';
import { doIntersectionsCheck, getEarliestStartCellIndex, getLatestEndCellIndex } from './helpers/helpers';
import { getMinMax } from '../../../Column/helpers/helpers';

const cx = classNames.bind(S);

const EditableComment: React.FC<EditedUserHourCommentProps> = (props) => {
  const {
    commentData,
    setEditedComment,
    groupCellsByCellIndex,
    rangeOfAvailableStartCells,
    rangeOfAvailableEndCells,
  } = props;
  const { comment, commentId, startCellIndex, endCellIndex } = commentData;
  const [ commentText, setCommentText ] = useState(comment);
  const [ startTimeCell, setStartTimeCell ] = useState(startCellIndex);
  const [ endTimeCell, setEndTimeCell ] = useState(endCellIndex);

  const isItNewComment = commentData.commentId === 'new';

  const changeStartAndEndTimeCell = (cellIndex: number) => {
    const { min, max } = getMinMax(cellIndex, endTimeCell);
    const { isIntersectionDetected } = doIntersectionsCheck(min, max, groupCellsByCellIndex, commentData.commentId);
    const isLessThenEnd = cellIndex < endTimeCell;

    if (isIntersectionDetected || !isLessThenEnd) {
      const { newEndTimeCell } = getLatestEndCellIndex(cellIndex, groupCellsByCellIndex);

      setStartTimeCell(cellIndex);
      setEndTimeCell(newEndTimeCell ? newEndTimeCell + 1 : cellIndex + 1);
      setEditedComment({
        ...commentData,
        startCellIndex: cellIndex,
        endCellIndex: newEndTimeCell ? newEndTimeCell + 1 : cellIndex + 1,
      });
    } else {
      setStartTimeCell(cellIndex);
      setEditedComment({
        ...commentData,
        startCellIndex: cellIndex,
      });
    }
  };

  const changeEndAndStartTimeCell = (cellIndex: number) => {
    const { min, max } = getMinMax(startTimeCell, cellIndex);
    const { isIntersectionDetected } = doIntersectionsCheck(min, max, groupCellsByCellIndex, commentData.commentId);
    const isMoreThenStart = cellIndex > startTimeCell;

    if (isIntersectionDetected || !isMoreThenStart) {
      const { newStartTimeCell } = getEarliestStartCellIndex(cellIndex, groupCellsByCellIndex);

      setEndTimeCell(cellIndex);
      setStartTimeCell(newStartTimeCell || cellIndex);
      setEditedComment({
        ...commentData,
        endCellIndex: cellIndex,
        startCellIndex: newStartTimeCell || cellIndex,
      });
    } else {
      setEndTimeCell(cellIndex);
      setEditedComment({
        ...commentData,
        endCellIndex: cellIndex,
      });
    }
  };

  const getRightContainerToRender = (triggerNode: any) => {
    const commentsContainer = document.getElementById('comments');

    return commentsContainer || triggerNode.parentElement;
  };

  // renders

  const onCommentTextChange = (value: string | undefined) => {
    const text = value || '';

    setCommentText(text);
    setEditedComment({
      ...commentData,
      comment: text,
    });
  };

  const renderSelectOptionsForStart = () => _.map(rangeOfAvailableStartCells, (commentId, key) => {
    const cellIndex = parseInt(key, 10);

    const isMatchToEndTimeCell = cellIndex === endTimeCell;
    const isCommentForThisTimeAlreadyExists = Boolean(commentId);
    const isThisTimeCellOfThisComment = commentId !== commentData.commentId;

    return (
      <Select.Option
        key={getSimpleTimeByCellIndex(cellIndex)}
        value={cellIndex}
        label={getSimpleTimeByCellIndex(cellIndex)}
        disabled={(isCommentForThisTimeAlreadyExists && isThisTimeCellOfThisComment) || isMatchToEndTimeCell}
      >
        {getSimpleTimeByCellIndex(cellIndex)}
      </Select.Option>
    );
  });

  const renderSelectOptionsForEnd = () => _.map(rangeOfAvailableEndCells, (commentId, key) => {
    const cellIndex = parseInt(key, 10);

    const isMatchToStartTimeCell = cellIndex === startTimeCell;
    const isCommentForThisTimeAlreadyExists = Boolean(commentId);
    const isThisTimeCellOfThisComment = commentId !== commentData.commentId;
    const time = getSimpleTimeByCellIndex(cellIndex);

    return (
      <Select.Option
        key={time}
        value={cellIndex}
        label={time}
        disabled={(isCommentForThisTimeAlreadyExists && isThisTimeCellOfThisComment) || isMatchToStartTimeCell}
      >
        {time}
      </Select.Option>
    );
  });

  const renderStartSelect = () => (
    <Select
      getPopupContainer={getRightContainerToRender}
      value={startTimeCell}
      onChange={changeStartAndEndTimeCell}
      optionLabelProp="label"
      className={S.select}
      dropdownClassName={selects.selectDropdown}
      suffixIcon={<ArrowIcon />}
      size="small"
      bordered={false}
    >
      {renderSelectOptionsForStart()}
    </Select>
  );

  const renderEndSelect = () => (
    <Select
      getPopupContainer={getRightContainerToRender}
      value={endTimeCell}
      onChange={changeEndAndStartTimeCell}
      optionLabelProp="label"
      className={S.select}
      dropdownClassName={selects.selectDropdown}
      suffixIcon={<ArrowIcon />}
      size="small"
      bordered={false}
    >
      {renderSelectOptionsForEnd()}
    </Select>
  );

  const renderEditableTimeRange = () => (
    <div className={S.commentInfo}>
      <span className={S.subLabel}>from</span>
      {renderStartSelect()}
      <span className={S.subLabel}>to</span>
      {renderEndSelect()}
    </div>
  );

  const renderRegularTimeRange = () => (
    <div className={S.regularCommentInfo}>
      <span className={S.timeSelector}>{getSimpleTimeByCellIndex(startCellIndex)}</span>
      <span style={{ margin: '0px 5px 0px' }}>-</span>
      <span className={S.timeSelector}>{getSimpleTimeByCellIndex(endCellIndex)}</span>
    </div>
  );

  const renderTimeRange = () => (isItNewComment ? renderEditableTimeRange() : renderRegularTimeRange());

  return (
    <div
      key={commentId}
      className={cx(S.comment, {
        [S.new]: isItNewComment,
      })}
    >
      {renderTimeRange()}
      <MarkupEditor
        value={commentText}
        onChange={onCommentTextChange}
      />
    </div>
  );
};

export default EditableComment;
