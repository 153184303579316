import _ from 'lodash';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';

import { SelectedHour } from 'components/TimeSheet/helpers/types';
import { BCDay, UsersHour, BCJointStatus } from 'api/TimeSheet/types';
import { getDateAndCellIndexByTime, getTimeByDateAndCellIndex } from 'components/TimeSheet/helpers/helpers';
import configureStore from 'redux/_reducer/configureStore';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { FlatUserHours } from './types';
import { getMinMax } from '../components/Column/helpers/helpers';

dayjs.extend(objectSupport);

interface SelectionRange {
  startCellIndex: number;
  endCellIndex: number;
  selectionDay: string;
}

/** Returns { startCellIndex: number, endCellIndex: number, startDay: string, endDay: string, hoursDelta: number, daysDelta: number } of selection box. */
export const getSelectionCoords = () => {
  const { selectedCells } = configureStore.getState().timeSheet.selection;

  const cellIds = _.keys(selectedCells);
  const rejectedData = {
    startCellIndex: 0,
    endCellIndex: 0,
    startDay: dayjs().format(DEFAULT_DATE_FORMAT),
    endDay: dayjs().format(DEFAULT_DATE_FORMAT),
    hoursDelta: 0,
    daysDelta: 0,
  };

  const { day: startDay, cellIndex: startCellIndex } = getDateAndCellIndexByTime(cellIds[0]);
  const { day: endDay, cellIndex: endCellIndex } = getDateAndCellIndexByTime(cellIds[cellIds.length - 1]);

  if (_.isNull(startDay) || _.isNull(endDay) || _.isNull(startCellIndex) || _.isNull(endCellIndex)) return rejectedData;

  return {
    startCellIndex,
    startDay,
    endCellIndex,
    endDay,
    hoursDelta: Math.abs(endCellIndex - startCellIndex) + 1,
    daysDelta: dayjs(endDay).diff(startDay, 'day') + 1,
  };
};

/** Returns boolean about is selected cells related to the same as selected activity, or not. */
export const isSelectedActivitySelectedWithCells = () => {
  const { selection, selectedActivity } = configureStore.getState().timeSheet;
  const { selectedCells } = selection;

  return _.every(selectedCells, [ 'activity.activityId', selectedActivity?.activity?.activityId ]);
};

/** Returns selected hours as { total: number, empty: number, filled: number }. */
export const getSelectionHoursStat = () => {
  const { selectedCells } = configureStore.getState().timeSheet.selection;

  if (_.isEmpty(selectedCells)) {
    return {
      total: 0,
      empty: 0,
      filled: 0,
    };
  }
  
  const stat = _.reduce(selectedCells, (result, cellObj) => {
    if (_.isUndefined(cellObj)) {
      return { ...result, total: result.total + 0.5, empty: result.empty + 0.5 };
    } else {
      return { ...result, total: result.total + 0.5, filled: result.filled + 0.5 };
    }
  }, { total: 0, empty: 0, filled: 0 });

  return stat;
};

/** Returns part of flat user hours table by selection Day and start|end cell ids. */
export const getFlatUserHoursPart = (selectionRange: { startCellIndex: number, endCellIndex: number, selectionDay: string }): FlatUserHours => {
  const { flatUserHoursTable } = configureStore.getState().timeSheet;

  const { endCellIndex, startCellIndex, selectionDay } = selectionRange;
  const cellIdsDelta = Math.abs(endCellIndex - startCellIndex) + 1;
  const listOfKeys = _.map(_.range(Math.abs(cellIdsDelta)), cellIndex => (
    getTimeByDateAndCellIndex(selectionDay, cellIndex + getMinMax(startCellIndex, endCellIndex).min)
  ));
  
  const tablePart = _.reduce(listOfKeys, (result, key) => ({
    ...result,
    [key]: flatUserHoursTable[key],
  }), {} as FlatUserHours);

  return tablePart;
};

/** Returns list of user hours from flatUserHoursTable where one cell size is 0.5 hour. */
export const getSelectedHours = (selectionRange?: { startCellIndex: number, endCellIndex: number, selectionDay: string }): SelectedHour[] => {
  const { selection, flatUserHoursTable } = configureStore.getState().timeSheet;
  const { startCellIndex, endCellIndex, selectionDay } = selectionRange || selection;

  if (!selectionDay || _.isNil(startCellIndex)) return [];

  const { min, max } = getMinMax(startCellIndex, endCellIndex);
  const range = _.range(max - min + 1).map(e => e + min);
  const selectedHours = _.reduce(range, (result, cellIndex) => {
    const key = getTimeByDateAndCellIndex(selectionDay, cellIndex);
    const userHour = flatUserHoursTable[key];

    if (userHour) {
      if (result.length) {
        const clonedResult = [ ...result ];

        const lastSelectedHour: SelectedHour = clonedResult[clonedResult.length - 1];
        const isSameActivity = lastSelectedHour.usersHourData.activity?.activityId === userHour.activity.activityId;
        const isSamePartner = lastSelectedHour.usersHourData.partner?.partnerId === userHour.partner.partnerId;

        if (isSameActivity && isSamePartner) {
          lastSelectedHour.endCellIndex = cellIndex;
          return clonedResult;
        } else {
          return [ ...result, {
            usersHourData: {
              activity: userHour.activity,
              partner: userHour.partner,
            },
            cutDay: true,
            comment: userHour.comment,
            startCellIndex: cellIndex,
            endCellIndex: cellIndex,
            timesheetId: userHour.timesheetId,
          } ];
        }
      } else {
        return [ {
          usersHourData: {
            activity: userHour.activity,
            partner: userHour.partner,
          },
          cutDay: true,
          comment: userHour.comment,
          startCellIndex: cellIndex,
          endCellIndex: cellIndex,
          timesheetId: userHour.timesheetId,
        } ];
      }
    }
    return result;
  }, [] as SelectedHour[]);

  return selectedHours;
};

/** Returns list of user hours where one cell size is 0.5 or 1 hour (as backend says) */
export const getSelectedHoursGroupedByTimesheetId = (selectionRange?: SelectionRange): { [key: number]: UsersHour } => {
  const { selection, flatUserHoursTable } = configureStore.getState().timeSheet;
  const { startCellIndex, endCellIndex, selectionDay } = selectionRange || selection;

  if (!selectionDay || _.isNil(startCellIndex)) return [];

  const { min, max } = getMinMax(startCellIndex, endCellIndex);
  const timeSheetRange = _.range(max - min + 1).map(e => e + min);

  const selectedHours = _.reduce(timeSheetRange, (result, cellIndex) => {
    const key = getTimeByDateAndCellIndex(selectionDay, cellIndex);
    const userHour = flatUserHoursTable[key];

    if (!userHour) return result;

    if (!result[userHour.timesheetId]) {
      return {
        ...result,
        [userHour.timesheetId]: userHour,
      };
    } else {
      return {
        ...result,
        [userHour.timesheetId]: {
          ...result[userHour.timesheetId],
          hours: 1,
          endTime: userHour.endTime,
        },
      };
    }
  }, {} as { [key: number]: UsersHour });

  return selectedHours;
};

/** Returns days grouped by cut or additional days. [[ cutDayObj, ... ], [ currentMonthDayObj, ...], [ additionalMonthDayObj, ...]] */
export const groupDaysByAdditionalOrCutDays = (days: BCDay[]): BCDay[][] => _.reduce(days, (groups, dayObj) => {
  const currentDayMonth = dayjs(dayObj.day).month();
  const isCurrentCutDay = dayObj.jointStatus === BCJointStatus.Cut;

  if (groups.length === 0) {
    return [ [ dayObj ] ];
  }

  const lastGroup = groups[groups.length - 1];
  const lastDay = lastGroup[lastGroup.length - 1];
  const lastDayMonth = dayjs(lastDay.day).month();
  const isLastDayForeign = lastDay.jointStatus === BCJointStatus.Cut;

  if (lastDayMonth === currentDayMonth && isCurrentCutDay === isLastDayForeign) {
    return [
      ...groups.slice(0, groups.length - 1),
      [ ...lastGroup, dayObj ],
    ];
  }

  return [
    ...groups,
    [ dayObj ],
  ];
}, [] as BCDay[][]);
