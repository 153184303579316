import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

import { CalendarDay, DayType } from '../../../helpers/types';

export const filterByMonth = (days: CalendarDay[], comparableMonthNumber: number, hardcodedDayType?: DayType) => _.reduce(
  days, 
  (result: CalendarDay[], dayObj: CalendarDay) => {
    if (dayjs(dayObj.day).get('month') + 1 === comparableMonthNumber) {
      return [ ...result, {
        ...dayObj,
        dayType: hardcodedDayType || dayObj?.dayType,
      } ];
    } else {
      return result;
    }
  }, [],
);

export const sortByMonth = (days: CalendarDay[], comparableMonthNumber: number, comparableYearNumber: number, hardcodedDayType?: DayType) => _.reduce(
  days,
  (
    result: {
      prevMonthDays: CalendarDay[],
      currentMonthDays: CalendarDay[],
      nextMonthDays: CalendarDay[],
    }, 
    dayObj: CalendarDay,
  ) => {
    const currentDayMonthNumber = dayjs(dayObj.day);
    const source = compareDayCoords(currentDayMonthNumber, { month: comparableMonthNumber, year: comparableYearNumber });

    return {
      ...result,
      [source]: [ ...result[source], {
        ...dayObj,
        dayType: hardcodedDayType || dayObj?.dayType,
      } ],
    };
  }, {
    prevMonthDays: [],
    currentMonthDays: [],
    nextMonthDays: [],
  },
);

export const compareDayCoords = (dayjsObj: Dayjs, trulyCoords: { year: number, month: number }) => {
  const month = dayjsObj.get('month') + 1;
  const year = dayjsObj.get('year');

  switch (true) {
    case (month > trulyCoords.month && year === trulyCoords.year) || (month < trulyCoords.month && year > trulyCoords.year):
      return 'nextMonthDays';
    case month === trulyCoords.month && year === trulyCoords.year:
      return 'currentMonthDays';
    case (month < trulyCoords.month && year === trulyCoords.year) || (month > trulyCoords.month && year < trulyCoords.year):
      return 'prevMonthDays';
    default: return 'currentMonthDays';
  }
};
