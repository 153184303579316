/* eslint-disable import/prefer-default-export */
import { NotificationUserPreferences } from 'redux/User/types';

import * as loginActions from '../../V1/constants/Login';
import { DispatchType } from '../../helpers/types/_common';
import userRequests from '../../api/PersonalInfo/requests';
import { PersonalInfoFields } from '../../api/PersonalInfo/types';
import convertConflictError from './helpers';

export function saveUserPreferencesAction(fields: NotificationUserPreferences, userId: number) {
  return (dispatch: DispatchType) => userRequests.saveUserPreferences(fields, userId)
    .then((response) => {
      dispatch({ type: loginActions.UPDATE_USER_DATA, payload: { preferences: fields } });

      return response;
    },
    (error) => {
      if (error?.response?.status === 409) {
        return Promise.reject(convertConflictError(error?.response?.data));
      }

      return Promise.reject(error?.response?.data);
    });
}

export function savePersonalInfoFields(fields: PersonalInfoFields, userId: number, editByAdmin?: boolean) {
  if (editByAdmin) {
    return () => userRequests.saveEmployeePersonalInfoFields(fields, userId)
      .catch((error) => {
        if (error?.response?.status === 409) {
          return Promise.reject(convertConflictError(error?.response?.data));
        }

        return Promise.reject(error?.response?.data);
      });
  }

  return (dispatch: DispatchType) => userRequests.savePersonalInfoFields(fields, userId)
    .then((response) => {
      dispatch({ type: loginActions.UPDATE_USER_DATA, payload: fields });

      return response;
    },
    (error) => {
      if (error?.response?.status === 409) {
        return Promise.reject(convertConflictError(error?.response?.data));
      }

      return Promise.reject(error?.response?.data);
    });
}
