import * as Actions from '../../constants/HotProjects';
import * as Type from './types';
import processCommonEvent from '../../utils/processCommonEvent';
// import Subtypes from '../../constants/__common__/Subtypes';
import Subtypes from '../../constants/__common__/Subtypes';
import { LIMIT } from '../../constants/__common__/PaginationConstants';
import moment from 'moment';
import _ from 'lodash';
import { HelpRequestOffer } from './types';
import { USER_TYPES } from '../../../helpers/constants/_common/constants';

const defaultHotProject = {
  contactId: 0,
  contactEmail: 'quantumsoft@email.ru',
  contactForename: 'Select contact person',
  contactSurname: '',
  contact: {
    active: true,
    description: '',
    emailAddress: '',
    curatorStatus: false,
    entityId: 0,
    entityName: '',
    forename: '',
    occupationId: 0,
    occupationName: '',
    role: 0,
    specialTags: [],
    surname: '',
    userId: 0,
    id: 0,
    username: '',
    usertype: USER_TYPES.USER,
  },
  partnerDescription: '',
  activityDescription: '',
  hotProjectId: 0,
  partnerId: 0,
  published: false,
  partnerName: 'Select partner',
  activityName: 'Select activity',
  color: '#1989e9',
  activityId: 0,
  rate: 2,
  mode: 1,
  status: 1,
  startDate: moment().format('YYYY-MM-DD') + ' 00:00',
  endDate:
    moment()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD') + ' 00:00',
  publicationDate: '',
  private: false,
  isViewed: false,
  helpOffersCount: 0,
  helpRequests: [],
  participantsCount: 0,
  selected: false
};
const InitialState: Type.StateType = {
  unreadCount: 0,
  allPartners: {
    isLoading: false,
    objects: []
  },
  hotProjectsTable: {
    isLoading: false,
    objects: [],
    noParticipants: false,
    matchMySkills: false,
    status: 0
  },
  helpRequestModal: {
    isLoading: false,
    errorMessage: '',
    show: false,
    selected: {
      helpRequestId: 0,
      name: '',
      description: '',
      occupationId: 1,
      occupationDescription: '',
      occupationName: 'Select occupation',
      occupation: {
        occupationId: 1,
        description: '',
        name: 'Select occupation'
      },
      status: 1,
      requestedSkills: [],
      helpOffers: [],
      author: {
        active: true,
        description: 'string',
        emailAddress: 'string',
        curatorStatus: false,
        entityId: 0,
        entityName: 'string',
        forename: 'string',
        occupationId: 0,
        occupationName: 'string',
        role: 0,
        specialTags: [],
        surname: 'string',
        userId: 0,
        id: 0,
        username: 'string',
        usertype: USER_TYPES.USER
      },
      workload: 0,
      color: '#1989e9'
    }
  },
  hotProjectConfirmationModal: {
    isLoading: false,
    errorMessage: '',
    show: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
    selected: defaultHotProject
  },
  hotProjectsModal: {
    isLoading: false,
    show: false,
    errorMessage: '',
    selected: defaultHotProject,
    isViewMode: false
  }
};

const getHotProjects = (action: any, state: Type.StateType) =>
  processCommonEvent.get('hotProjectsTable')(action, state);

const getAllPartners = (action: any, state: Type.StateType) =>
  processCommonEvent.get('allPartners')(action, state);

const hotProjectModal = (action: any, state: Type.StateType) =>
  processCommonEvent.modalToggle(
    'hotProjectsModal',
    InitialState.hotProjectsModal
  )(action, state);

const hotProjectConfirmationModal = (action: any, state: Type.StateType) =>
  processCommonEvent.modalToggle(
    'hotProjectConfirmationModal',
    InitialState.hotProjectConfirmationModal
  )(action, state);

const hotProjectModalRequest = (action: any, state: Type.StateType) =>
  processCommonEvent.request('hotProjectsModal')(action, state);

const getHotProjectById = (action: any, state: Type.StateType) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {
        ...state,
        hotProjectsModal: {
          ...state.hotProjectsModal,
          isLoading: true,
          errorMessage: ''
        }
      };
    case Subtypes.Request.Success:
      return {
        ...state,
        hotProjectsModal: {
          ...state.hotProjectsModal,
          isLoading: false,
          errorMessage: '',
          selected: action.payload
        }
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        hotProjectsModal: {
          ...state.hotProjectsModal,
          isLoading: false,
          errorMessage: action.payload
        }
      };
    default:
      return state;
  }
};

const markAllHotProjectsAsRead = (action: any, state: Type.StateType) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          isLoading: true
        }
      };
    case Subtypes.Request.Success:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          objects: state.hotProjectsTable.objects.map(object => ({
            ...object,
            isViewed: true
          })),
          isLoading: false
        }
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

const toggleHelpRequestStatus = (action: any, state: Type.StateType) => {
  if (action.subtype === Subtypes.Request.Success) {
    const clonedHelpRequests = _.cloneDeep(
      state.hotProjectsModal.selected.helpRequests
    );
    const clonedHelpRequest = _.cloneDeep(state.helpRequestModal.selected);
    const helpRequestToEdit = clonedHelpRequests.find(
      helpRequest => helpRequest.helpRequestId === action.payload.helpRequestId
    );
    if (helpRequestToEdit) {
      clonedHelpRequest.status = action.payload.status;
      helpRequestToEdit.status = action.payload.status;
    }

    return {
      ...state,
      helpRequestModal: {
        ...state.helpRequestModal,
        selected: clonedHelpRequest
      },
      hotProjectsModal: {
        ...state.hotProjectsModal,
        isLoading: false,
        selected: {
          ...state.hotProjectsModal.selected,
          helpRequest: clonedHelpRequests
        }
      }
    };
  } else if (action.subtype === Subtypes.Request.Loading) {
    return {
      ...state,
      hotProjectsModal: {
        ...state.hotProjectsModal,
        isLoading: true
      }
    };
  } else {
    return {
      ...state,
      hotProjectsModal: {
        ...state.hotProjectsModal,
        isLoading: false
      }
    };
  }
};

const makeHelpOffer = (action: any, state: Type.StateType) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {
        ...state,
        hotProjectsModal: {
          ...state.hotProjectsModal,
          isLoading: true
        }
      };
    case Subtypes.Request.Success: {
      const clonedHelpRequests = _.cloneDeep(
        state.hotProjectsModal.selected.helpRequests
      );
      const clonedHelpRequest = _.cloneDeep(state.helpRequestModal.selected);
      const helpRequestToEdit = clonedHelpRequests.find(
        helpRequest =>
          helpRequest.helpRequestId === action.payload.helpRequestId
      );
      const newHelpOffer: HelpRequestOffer = {
        user: action.payload.user,
        reviewer: null,
        reviewDate: '',
        status: 1,
        helpOfferId: action.payload.helpOfferId
      };
      if (helpRequestToEdit) {
        clonedHelpRequest.helpOffers = [
          ...clonedHelpRequest.helpOffers,
          newHelpOffer
        ];
        helpRequestToEdit.helpOffers = [
          ...helpRequestToEdit.helpOffers,
          newHelpOffer
        ];
      }
      return {
        ...state,
        helpRequestModal: {
          ...state.helpRequestModal,
          selected: clonedHelpRequest || state.helpRequestModal.selected
        },
        hotProjectsModal: {
          ...state.hotProjectsModal,
          selected: {
            ...state.hotProjectsModal.selected,
            helpRequests: clonedHelpRequests
          },
          isLoading: false
        }
      };
    }
    case Subtypes.Request.Error:
      return {
        ...state,
        helpRequestModal: {
          ...state.helpRequestModal,
          errorMessage: action.payload.data[0]
        },
        hotProjectsModal: {
          ...state.hotProjectsModal,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

function reducer(state = InitialState, action: any): any {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;

    case Actions.GET_HOT_PROJECTS:
      return getHotProjects(action, state);

    case Actions.GET_ALL_PARTNERS: {
      return getAllPartners(action, state);
    }

    case Actions.GET_HOT_PROJECT_BY_ID: {
      return getHotProjectById(action, state);
    }

    case Actions.ADD_HOT_PROJECT_MODAL_PROCESS:
      return hotProjectModalRequest(action, state);

    case Actions.HOT_PROJECT_MODAL_VIEW:
      return hotProjectModal(action, state);

    case Actions.HOT_PROJECT_CONFIRMATION_MODAL_VIEW:
      return hotProjectConfirmationModal(action, state);

    case Actions.MARK_ALL_HOT_PROJECTS_AS_READ:
      return markAllHotProjectsAsRead(action, state);

    case Actions.TOGGLE_HELP_REQUEST_STATUS:
      return toggleHelpRequestStatus(action, state);

    case Actions.MAKE_HELP_OFFER:
      return makeHelpOffer(action, state);

    case Actions.HANDLE_HOT_PROJECT_SELECTION: {
      const clonedList = _.cloneDeep(state.hotProjectsTable.objects);
      const hotProjectToHandle = clonedList.find(
        hotProject => hotProject.hotProjectId === action.payload.hotProjectId
      );

      if (hotProjectToHandle) {
        hotProjectToHandle.selected = action.payload.checked;
      }

      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          objects: clonedList
        }
      };
    }

    case Actions.UPDATE_HOT_PROJECTS_UNREAD_COUNT:
      return { ...state, unreadCount: action.payload };

    case Actions.UPDATE_HOT_PROJECT_AS_READ: {
      const clonedList = _.cloneDeep(state.hotProjectsTable.objects);
      const hotProjectToEdit = clonedList.find(
        hotProject => hotProject.hotProjectId === action.payload
      );
      if (hotProjectToEdit) {
        hotProjectToEdit.isViewed = true;
      } else {
        console.error(
          `Hot project with ID: ${action.payload} doesn't exist at list below:`,
          state.hotProjectsTable.objects
        );
      }
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          objects: clonedList
        }
      };
    }

    case Actions.UPDATE_SELECTED_HOT_PROJECTS_AS_READ: {
      const clonedList = _.cloneDeep(state.hotProjectsTable.objects).map(
        hotProject => ({
          ...hotProject,
          isViewed:
            hotProject.isViewed ||
            action.payload.includes(hotProject.hotProjectId)
        })
      );
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          objects: clonedList
        }
      };
    }

    case Actions.UPDATE_HOT_PROJECTS_LIST_WITH_NEW_ONE: {
      const oldList = state.hotProjectsTable.objects;

      if (oldList.length === LIMIT) {
        oldList.splice(-1, 1);
      }
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          objects: [action.payload, ...state.hotProjectsTable.objects]
        }
      };
    }

    case Actions.UPDATE_EXISTED_HOT_PROJECTS_LIST: {
      const clonedObjects = _.cloneDeep(state.hotProjectsTable.objects);
      let hotProjectToEdit = clonedObjects.find(
        hotProject => hotProject.hotProjectId === action.payload.hotProjectId
      );
      if (!hotProjectToEdit) return state;
      hotProjectToEdit = _.assign(hotProjectToEdit, action.payload);
      if (
        hotProjectToEdit &&
        hotProjectToEdit.published &&
        !hotProjectToEdit.publicationDate
      ) {
        hotProjectToEdit.publicationDate = moment().format('YYYY-MM-DD hh:mm');
      }

      return {
        ...state,
        hotProjectsTable: { ...state.hotProjectsTable, objects: clonedObjects }
      };
    }

    case Actions.CHANGE_MATCH_MY_SKILLS:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          matchMySkills: !state.hotProjectsTable.matchMySkills
        }
      };

    case Actions.CHANGE_NO_PARTICIPANTS:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          noParticipants: !state.hotProjectsTable.noParticipants
        }
      };

    case Actions.CHANGE_SELECTED_HELP_REQUEST:
      return {
        ...state,
        helpRequestModal: {
          ...state.helpRequestModal,
          errorMessage: '',
          selected: action.payload || InitialState.helpRequestModal.selected
        }
      };

    case Actions.CHANGE_STATUS:
      return {
        ...state,
        hotProjectsTable: {
          ...state.hotProjectsTable,
          status: action.payload
        }
      };

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer
};
