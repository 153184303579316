import WebFont from 'webfontloader';

export const webFontLoader = () => {
  WebFont.load({
    custom: {
      families: [ 'Roboto: n5', 'SF Pro Display:n9,n4', 'Gilroy:n4,n6' ],
      urls: [ './fonts.sass' ],
    },
  });
};
