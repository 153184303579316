import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Collapse, List } from 'antd';
import _ from 'lodash';

import popover from 'helpers/styles/components/popover.module.sass';
import PartnerMask from 'components/_shared/PartnerMask';
import { UserPartnerOutput } from 'api/Partners/types';

import ReplaceHourPartnerListItemProps from './helpers/types';
import S from './helpers/styles.module.sass';
import PartnerActivityListItem from '../PartnerActivityListItem/PartnerActivityListItem';

const cx = classNames.bind(S);

const ReplaceHourPartnerListItem: React.FC<ReplaceHourPartnerListItemProps> = ({ partnerObject, handleSave }) => {
  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const popoverContent = (partnerObject: UserPartnerOutput) => {
    const options = _.map(partnerObject.partner.activities, activity => (
      <PartnerActivityListItem
        key={activity.activityId}
        activity={activity}
        handleSave={handleSave}
        partnerObject={partnerObject}
        setPopoverVisible={setPopoverVisible}
      />
    ));

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        {_.isEmpty(options) ? <div className={S.emptyActivityOption}>No activities yet.</div> : options}
      </div>
    );
  };
  
  const listItem = (
    <List.Item
      key={partnerObject.partnerUserId}
      className={cx(S.partnerListItem, { [S.activePartnerListItem]: popoverVisible })}
      title={partnerObject.partner.name}
    >
      <List.Item.Meta
        className={S.partnerListItemMeta}
        description={(
          <div className={S.partnerListItemDescription}>
            <PartnerMask
              wrapperColor={partnerObject.partner.color}
              mask={partnerObject.partner.mask}
              wrapperClassName={S.partnerMaskWrapper}
              partnerId={partnerObject.partner.partnerId}
              iconColor={partnerObject.partner.activities[0]?.color}
              isVacation={partnerObject.partner.isVacation}
            />
            <div className={S.descriptionTextWrapper}>
              <span className={S.descriptionText}>{partnerObject.partner.name}</span>
            </div>
          </div>
        )}
      />
    </List.Item>
  );

  return (
    <Collapse bordered={false} expandIconPosition="right" className={S.partnerListItemWrapper}>
      <Collapse.Panel header={listItem} key={partnerObject.partnerUserId}>
        {popoverContent(partnerObject)}
      </Collapse.Panel>
    </Collapse>
  );
};

export default ReplaceHourPartnerListItem;
