import { Day, Month } from 'api/BusinessCalendar/types';

export interface CalendarDay extends Day {
  dayType?: DayType;
  disabled?: boolean;
}

export interface CalendarMonth extends Month {
  days: CalendarDay[];
}

export enum DayType {
  Cut = 'CUT',
  Original = 'ORIGINAL',
}

export type CalendarType = 'grid' | 'row' | 'new';

export interface BusinessCalendarDrawerDataProps {
  prevMonthDays: CalendarDay[];
  currentMonthPeriod: CalendarMonth;
  nextMonthDays: CalendarDay[];
}
