import React, { useMemo, useRef } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { EmptyCell } from 'components/TimeSheet/helpers/types';
import { Redux } from 'helpers/types/_common';
import { getSimpleTimeByCellIndex } from 'components/TimeSheet/helpers/helpers';
import { ContextMenuType } from 'redux/TimeSheet/types';
import { BCDay, BCJointStatus } from 'api/TimeSheet/types';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { ReactComponent as CutIcon } from './helpers/cutIcon.svg';
import Column from './components/Column/Column';
import { TimeSheetTableBodyProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import TABLE_CELLS from '../../helpers/constants';
import DeleteUserHoursModal from './components/DeleteModal/DeleteUserHoursModal';
import TimeSheetTimeline from '../Timeline/Timeline';
import { groupDaysByAdditionalOrCutDays } from './helpers/helpers';
import TableHighlight from './components/TableHighlight/TableHighlight';
import TableSelection from './components/TableSelection/TableSelection';
import { getMiddleDay } from './components/Column/helpers/helpers';

const TableBody: React.FC<TimeSheetTableBodyProps> = (props) => {
  const {
    userHoursTable,
    isBCLoading,
    BCMonth,
    curatorOnlyView,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const { tableSettings, contextMenus } = useSelector((state: Redux) => state.timeSheet);
  const { isMonthClosed } = tableSettings;

  const groupOfCellsContextMenu = contextMenus[ContextMenuType.Group];
  const daysToRender: BCDay[] = BCMonth.jointDays;
  const groupedDays = useMemo(() => groupDaysByAdditionalOrCutDays(daysToRender), [ BCMonth.jointDays ]);

  const renderGroupsOfColumns = () => _.map(groupedDays, (days, groupIndex) => {
    const someDayOfGroup = days[0];

    if (!someDayOfGroup) return null;

    const dayjsObj = dayjs(someDayOfGroup.day);

    const isStartOfTheMonth = dayjsObj.date() === 1 && dayjsObj.month() + 1 === BCMonth.month;
    const isPrevMonth = dayjsObj.isBefore(`${BCMonth.year}-${BCMonth.month}-1`);
    const isCut = someDayOfGroup.jointStatus === BCJointStatus.Cut;
    const isCurrentMonth = dayjsObj.month() + 1 === BCMonth.month;

    const getPrevDay = (i: number) => {
      if (days[i - 1]) return days[i - 1];

      const prevGroup = groupedDays[groupIndex - 1];

      if (prevGroup) return prevGroup[prevGroup.length - 1];
    };

    const renderMonthMarker = () => {
      if (getMiddleDay(BCMonth).day === dayjs().format(DEFAULT_DATE_FORMAT)) return;

      return isCut ? <CutIcon className={S.icon} /> : <span className={S.monthName}>{dayjsObj.format('MMM')}</span>;
    };

    return (
      <div
        key={days[0].day}
        style={{ flex: `${days.length} ${days.length} 0` }}
        className={cx(S.columnsGroup, {
          [S.cutGroup]: isCut,
          [S.stickToRight]: (isStartOfTheMonth && isCut) || isPrevMonth,
          [S.currentMonth]: isCurrentMonth && !isCut,
        })}
      >
        <div className={cx(S.groupMarker)}>
          <div className={S.markWrapper} style={{ flex: `0 0 calc(100% / ${days.length})` }}>
            {renderMonthMarker()}
          </div>
        </div>
        {(!isCurrentMonth || isCut) && <div className={S.gradient} style={{ width: `calc(100% / ${days.length})` }} />}
        {_.map(days, (objDay, i) => renderColumn(objDay, getPrevDay(i)))}
      </div>
    );
  });

  const renderColumn = (dayObj: BCDay, prevDayObj: BCDay | undefined) => (
    <Column
      key={`column-${dayObj.day}`}
      userHoursTable={userHoursTable}
      isMonthClosed={isMonthClosed}
      isBCLoading={isBCLoading}
      groupOfCellsContextMenu={groupOfCellsContextMenu}
      dayObj={dayObj}
      BCMonth={BCMonth}
      prevDayObj={prevDayObj}
      curatorOnlyView={curatorOnlyView}
    />
  );

  const renderSideColumn = () => {
    const veryFirstDay = groupedDays[0][0];
    const isCutMark = veryFirstDay.jointStatus === BCJointStatus.Cut;
    const isPrevMonthMark = dayjs(veryFirstDay.day).isBefore(`${BCMonth.year}-${BCMonth.month}-1`);

    return (
      <div className={S.sideColumn}>
        <div className={S.sideColumnHeader}>
          <div className={cx(S.sideColumnMarker, {
            [S.cut]: isCutMark,
            [S.prevMonth]: isPrevMonthMark,
          })}
          />
          <span className={S.sideColumnLabel}>hrs</span>
        </div>
        <div className={S.sideColumnBody}>
          {_.map(TABLE_CELLS, cellObj => (cellObj.cellIndex > 0 && renderSideCell(cellObj)))}
        </div>
      </div>
    );
  };

  const renderSideCell = (cellObj: EmptyCell) => (
    <div
      key={`${cellObj.cellIndex}-side-column`}
      className={cx(S.sideColumnHour)}
      style={{
        minHeight: `calc(100% / ${TABLE_CELLS.length})`,
        maxHeight: `calc(100% / ${TABLE_CELLS.length})`,
      }}
    >
      {renderNiceHourNumber(getSimpleTimeByCellIndex(cellObj.cellIndex))}
    </div>
  );

  const renderNiceHourNumber = (timeStr: string) => {
    const isHalfOfHour = timeStr.split(':')[1] !== '00';
    const niceNumber = timeStr.split(':')[0][0] === '0' ? timeStr.substr(1, timeStr.length - 1) : timeStr; // render 1:00 instead of 01:00

    if (isHalfOfHour) return null; // don't render half of hours numbers (10:30, 11:30, etc)

    return (
      <span className={cx(S.sideColumnHourTitle)}>
        {niceNumber}
      </span>
    );
  };

  return (
    <div id="new-timesheet-table-body" ref={containerRef} className={cx(S.tableBodyWrapper)}>
      <div className={S.sideColumnWrapper}>
        {useMemo(renderSideColumn, [ groupedDays ])}
      </div>
      <div className={cx(S.tableBody, {
        [S.closedMonth]: isMonthClosed,
      })}
      >
        {renderGroupsOfColumns()}
      </div>
      <div id="table-modal-inner" className={S.tableModalInner} />
      <DeleteUserHoursModal />
      <TimeSheetTimeline
        BCMonth={BCMonth}
        isLoading={isBCLoading}
      />
      <TableSelection
        sideColumnWidth={61}
        columnHeaderHeight={104}
        curatorOnlyView={curatorOnlyView}
        isDisabled={isBCLoading}
      />
      <TableHighlight
        sideColumnWidth={61}
        columnHeaderHeight={104}
        monthNameLabelHeight={26}
        isDisabled={isBCLoading}
      />
    </div>
  );
};

export default TableBody;
