import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { getPartnersTable } from 'redux/Partners/actions';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { setPartnerActiveStatusBulk } from 'api/Partners/requests';

import s from './styles.module.sass';
import PartnersSettings from './components/PartnersSettings';
import PartnersTable from './components/PartnersTable';
import { PartnersFiltersState, PartnerUserOutputChecked } from './helpers/types';
import { TABLE_NAMES } from '../../helpers/constants/Tables/tableList';
import { DispatchType, Redux } from '../../helpers/types/_common';
import TableSelectionList from '../TableComponents/TableSelectionList';
import { FoundPartnerOutput } from '../../api/Partners/types';
import { Meta, Sorter } from '../../api/_request/types';

const Partners = () => {
  const dispatch: DispatchType = useDispatch();

  const { meta, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.PARTNERS]);

  const [ filters, setFilters ] = useState<PartnersFiltersState>({ active: true });
  const [ selectedRows, setSelectedRows ] = useState<PartnerUserOutputChecked[]>([]);
  const [ isBulkActiveChangerLoading, setBulkActiveChangerLoading ] = useState(false);

  const refreshTable = () => {
    getPartnersData(meta, sorter);
  };

  const getPartnersData = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      ...filters,
      ...pagination,
      ...sorter,
    };

    return dispatch(getPartnersTable(body, TABLE_NAMES.PARTNERS));
  };

  const changePartnerActiveStatusBulk = () => {
    setBulkActiveChangerLoading(true);
    setPartnerActiveStatusBulk({
      partnerIds: _.map(selectedRows, row => row.partnerId),
      status: !selectedRows[0]?.active,
    }, {
      errorObject: {
        400: {
          hidden: false,
          description: (error: any) => `${_.keys(error.response.data)[0]}: ${_.values(error.response.data)[0]}`,
        },
        422: {
          hidden: false,
          description: (error: any) => `${error.response.data.error}: ${error.response.data.value}`,
        },
      },
    })
      .then(() => {
        refreshTable();
        setSelectedRows([]);
      })
      .finally(() => {
        setBulkActiveChangerLoading(false);
      });
  };

  const renderFilters = () => {
    const onChangeCheckbox = (event: CheckboxChangeEvent, targetRow: FoundPartnerOutput) => {
      const { partnerId } = targetRow;
      const checked = event?.target?.checked;

      const updatedSelectedRows = _.map(selectedRows, (s) => {
        if (s.partnerId === partnerId) {
          return { ...s, checked };
        }

        return s;
      });

      setSelectedRows(updatedSelectedRows);
    };

    if (!_.isEmpty(selectedRows)) {
      return (
        <div className={s.partnerSelectionPanelWrapper}>
          <Space>
            <span className={s.partnerSelectionPanelCount}>
              {`${selectedRows.length} selected`}
            </span>

            <TableSelectionList
              resetSelections={() => setSelectedRows([])}
              onChangeCheckbox={onChangeCheckbox}
              renderTitle={(row: FoundPartnerOutput) => `${row.name}`}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          </Space>

          <Button
            className={buttons.qsButtonPrimary}
            onClick={changePartnerActiveStatusBulk}
            type="primary"
            size="large"
            loading={isBulkActiveChangerLoading}
          >
            {selectedRows[0]?.active ? 'Deactivate' : 'Activate'}
          </Button>
        </div>
      );
    }

    return (
      <PartnersSettings
        filters={filters}
        setFilters={setFilters}
        refreshTable={refreshTable}
      />
    );
  };

  return (
    <div className={s.partners}>
      { renderFilters() }

      <PartnersTable
        getPartnersData={getPartnersData}
        filters={filters}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default Partners;
