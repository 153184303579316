import React, { useEffect } from 'react';

import useApi from 'api/hooks/useApi/useApi';
import { getActivitiesList, getPartnersList } from 'api/Partners/requests';
import { UserPartnerOutput, UserPinnedActivityOutput } from 'api/Partners/types';

import { ChosenActivityInfo, MobileTimesheetPanel } from '../../helpers/types';
import ActivitiesList from './components/ActivitiesList/ActivitiesList';
import PartnersList from './components/PartnersList/PartnersList';
import S from './helpers/styles.module.sass';

interface ChooseActivityPanelProps {
  changePanel: (panelName: MobileTimesheetPanel) => void;
  setUserHourActivity: (activity: ChosenActivityInfo) => void;
  userId: number;
}

const ChooseActivityPanel: React.FC<ChooseActivityPanelProps> = ({ changePanel, setUserHourActivity, userId }) => {
  const [
    getPartners,
    partners,
    loadingPartners,
  ] = useApi<typeof getPartnersList, UserPartnerOutput[]>(getPartnersList, { defaultData: [] });
  const [
    getActivities,
    activities,
    loadingActivities,
  ] = useApi<typeof getActivitiesList, UserPinnedActivityOutput[]>(getActivitiesList, { defaultData: [] });
  
  useEffect(() => {
    getPartners(userId);
    getActivities(userId);
  }, []);

  const chooseHandling = (activity: ChosenActivityInfo) => {
    setUserHourActivity(activity);
    changePanel(MobileTimesheetPanel.HourDetails);
  };

  return (
    <div className={S.panelWrapper}>
      <div className={S.lists}>
        <ActivitiesList
          activities={activities}
          isLoading={loadingActivities}
          handleSave={chooseHandling}
        />

        <PartnersList
          partners={partners}
          isLoading={loadingPartners}
          handleSave={chooseHandling}
        />
      </div>
    </div>
  );
};

export default ChooseActivityPanel;
