/**
  Using for carry submenus collapse state
 */
export const COLLAPSED_SIDEBAR = 'collapsedSidebar';

/**
  Using for carry authentication access token
 */
export const ACCESS_TOKEN = 'accessToken';

/**
  Using for carry authentication refresh token
 */
export const REFRESH_TOKEN = 'refresh';

/**
  Using for carry token expires datetime
 */
export const TOKEN_EXPIRES_AT = 'tokensExpiresAt';

/**
  Delete hour modal not showing
 */
export const DHM_AUTO_CLOSE = 'DHMAutoClose';

/**
  Add hour modal not showing
 */
export const AHM_AUTO_CLOSE = 'AHMAutoClose';

/**
 Dark/White theme constant
 */
export const MAIN_THEME = 'theme';
