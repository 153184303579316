import * as Actions from '../constants/Entities';
import Subtypes from '../constants/__common__/Subtypes';

const InitialState = {
  objects: [],
  gettingEntitiesIsLoading: false,
  disableEntitySelector: false
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;
    case Actions.GET_ALL_ENTITIES:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return {...state, gettingEntitiesIsLoading: true};
        case Subtypes.Request.Success:
          return {
            ...state,
            objects: action.payload,
            gettingEntitiesIsLoading: false,
            disableEntitySelector: action.payload.length <= 1
          };
        case Subtypes.Request.Error:
          return {...state, gettingEntitiesIsLoading: false};
        default:
          return state;
      }

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer
}
