import React from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';

import { SkillAttitudeProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import SKILL_ATTITUDES from '../../../../../../helpers/constants/Skills/Attitudes';

const cx = classNames.bind(S);

const SkillAttitude = (props: SkillAttitudeProps) => {
  if (_.isNil(props.iconName)) {
    return null;
  }

  const attitude = SKILL_ATTITUDES[props.iconName];

  if (!attitude) {
    return null;
  }

  return (
    <div className={cx('skill-attitude')}>
      <img src={attitude.icon} alt={attitude.title} title={attitude.title} style={{ ...props.style, width: 18 }} />
    </div>
  );
};

export default SkillAttitude;
