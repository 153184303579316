import { Button, Checkbox, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'antd/es/modal';

import modals from 'helpers/styles/components/modals.module.sass';
import withRightPlural from 'helpers/utils/withRightPlural';
import { replaceAndFillUserHourAction, replaceUserHourAction } from 'redux/TimeSheet/action';
import { DispatchType, Redux } from 'helpers/types/_common';
import { updateUserPreferences } from 'redux/User/actions';

import S from './styles.module.sass';
import { getTableModalInner } from '../CellGroup/helpers/helpers';
import { ConfirmModalData } from '../TableSelection/components/ActionMaker/helpers/types';
import { getSelectionHoursStat } from '../../helpers/helpers';

interface HoursConfirmModalProps extends ConfirmModalData {
  getContainer?: ModalProps['getContainer'];
  onOk: () => void;
  onClose: () => void;
}

const HoursConfirmModal: React.FC<HoursConfirmModalProps> = (props) => {
  const { isVisible, onClose, onOk, userId, data } = props;

  const { preferences } = useSelector((state: Redux) => state.login);
  
  const [ checkboxStatus, setCheckboxStatus ] = useState(preferences.askConfirmationOnOverwriteHours);
  const [ isLoading, setIsLoading ] = useState(false);

  const { filled, empty } = getSelectionHoursStat();
  const hours = (data.endCellIndex - data.startCellIndex + 1) / 2;
  
  const dispatch: DispatchType = useDispatch();

  if (!isVisible) return null;

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    dispatch(updateUserPreferences({ askConfirmationOnOverwriteHours: !e.target.checked }));
    setCheckboxStatus(e.target.checked);
  };

  const saveNewPreferences = () => {
    if (checkboxStatus === preferences.askConfirmationOnOverwriteHours) return;
    
    dispatch(updateUserPreferences({ askConfirmationOnOverwriteHours: checkboxStatus }));
  };

  const doReplaceAndFill = () => {
    setIsLoading(true);

    dispatch(replaceAndFillUserHourAction(userId, data))
      .then(() => {
        onOk();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderContent = () => (
    <>
      <span>Are you sure you want to:</span>
      <ul>
        <li>
          <span className={S.dot}>•</span>
          <span>
            Replace
            {' '}
            <b>{filled || hours}</b>
            {' '}
            selected
            {' '}
            {withRightPlural('hour', filled)}
          </span>
        </li>
        {empty !== 0 && (
          <li>
            <span className={S.dot}>•</span>
            <span>
              Add
              {' '}
              <b>{empty}</b> 
              {' '}
              {withRightPlural('hour', empty)}
              ?
            </span>
          </li>
        )}
      </ul>
    </>
  );

  return (
    <Modal
      visible={isVisible}
      title="Replace activity"
      onOk={doReplaceAndFill}
      centered
      onCancel={() => onClose()}
      getContainer={props.getContainer || getTableModalInner()}
      className={classNames(modals.qsBasicAntdModal, S.confirmModal)}
      footer={[
        <Checkbox
          key="checkbox"
          className={S.checkbox}
          onChange={onCheckboxChange}
        >
          Don’t ask again
        </Checkbox>,
        <Button
          key="back"
          type="text"
          className={modals.modalCancelBtn}
          style={{ marginRight: '0' }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          title="Confirm"
          loading={isLoading}
          className={modals.modalOkBtn}
          style={{ width: 'auto' }}
          onClick={doReplaceAndFill}
        >
          Confirm
        </Button>,
      ]}
    >
      <div className={S.content}>{renderContent()}</div>
    </Modal>
  );
};

export default HoursConfirmModal;
