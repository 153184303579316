import { Column } from 'components/_shared/Table/helpers/types';

const PARTNERS_USERS_COLUMNS: Column[] = [
  {
    title: 'Partner ID',
    dataIndex: 'partnerId',
    key: 'partnerId',
    sorter: true,
    width: 125,
  },
  {
    title: 'Partner name',
    dataIndex: 'partnerName',
    key: 'partnerName',
    width: 200,
    sorter: true,
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    sorter: true,
    width: 125,
  },
  {
    title: 'User fullname',
    dataIndex: 'userFullname',
    key: 'userFullname',
    width: 200,
    sorter: true,
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    width: 100,
  },
];

export default PARTNERS_USERS_COLUMNS;
