import React from 'react';
import _ from 'lodash';
import { Space, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross18.svg';
import { BaseUserOutput } from 'api/User/types';

import { FilteredItemsState } from '../../types';
import s from '../../styles.module.sass';
import { BaseEntityOutput } from '../../../../api/Entities/types';
import { PartnerCardOutput } from '../../../../api/Partners/types';

interface GeneralSearchSelectedEntitiesProps {
  filteredItems: FilteredItemsState;
  onClick: (entity: BaseEntityOutput | PartnerCardOutput | BaseUserOutput | null, searchItemId: number, fieldName: keyof FilteredItemsState) => void;
}

const GeneralSearchSelectedEntities: React.FC<GeneralSearchSelectedEntitiesProps> = ({ filteredItems, onClick }) => {
  if (_.isEmpty(filteredItems.entities)) {
    return null;
  }

  return (
    <Space className={s.filteredBlock} direction="vertical" size={0}>
      <span className={s.filteredBlockTitle}>Entities</span>
      <Space wrap size={4}>
        {
          _.map(filteredItems.entities, (entity, searchItemId) => (
            <Tag
              className={s.searchTagItem}
              key={entity.entityId}
              closable
              closeIcon={<CloseIcon />}
              onClick={() => onClick(entity, Number(searchItemId), 'entities')}
              onClose={() => onClick(entity, Number(searchItemId), 'entities')}
            >
              <Space>
                <FontAwesomeIcon icon="cubes" style={{ width: 24, height: 24 }} />
                <span>{entity.name}</span>
              </Space>
            </Tag>
          ))
        }
      </Space>
    </Space>
  );
};

export default GeneralSearchSelectedEntities;
