import React, { useState } from 'react';
import { Button, Popover, Select, Space, Spin } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import { ReactComponent as AddActivityIcon } from 'helpers/icons/plus.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';

import s from './helpers/styles.module.sass';
import selects from '../../../../../helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from '../../../../../helpers/icons/SelectArrow.svg';
import { getPartners, addPartner } from '../../../../../api/Partners/requests';
import { FoundPartnerPage, UserPartnerOutput } from '../../../../../api/Partners/types';
import { INCREASED_PAGINATION_RAW } from '../../../../../helpers/constants/_common/Pagination';

interface AddPartnerButtonProps {
  userId: number;
  isEdit: boolean;
  partners: UserPartnerOutput[];
  onAddPartnerCallback: () => void;
}

const AddPartnerButton: React.FC<AddPartnerButtonProps> = ({ userId, onAddPartnerCallback, partners, isEdit }) => {
  const [ partnersWithPagination, setPartnersWithPagination ] = useState<FoundPartnerPage>({});
  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ partnersLoading, setPartnersLoading ] = useState(false);
  const [ addPartnerLoading, setAddPartnerLoading ] = useState(false);
  const [ selectedPartner, setSelectedPartner ] = useState<number | undefined>();

  const onVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const getPartnersList = (searchValue?: string) => {
    setPartnersLoading(true);
    getPartners({
      orderDirection: 'ASC',
      orderBy: 'name',
      nameContains: searchValue,
      limit: INCREASED_PAGINATION_RAW.limit,
      offset: 0,
    })
      .then((partners: FoundPartnerPage) => setPartnersWithPagination(partners))
      .finally(() => setPartnersLoading(false));
  };

  const handleOnFocus = () => {
    if (!_.isEmpty(partnersWithPagination?.objects)) {
      return;
    }

    getPartnersList();
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin spinning={partnersLoading}>
      {menu}
    </Spin>
  );

  const onSearchPartners = _.debounce((searchValue: string) => {
    getPartnersList(searchValue);
  }, 500);

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (partnersWithPagination?.meta?.totalCount === partnersWithPagination?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(partnersWithPagination?.meta?.offset)
        ? (partnersWithPagination?.meta?.offset || 0) + INCREASED_PAGINATION_RAW.limit
        : 0;

      setPartnersLoading(true);
      getPartners({
        orderDirection: 'ASC',
        orderBy: 'name',
        limit: INCREASED_PAGINATION_RAW.limit,
        offset: increasedPageByOne,
      })
        .then((options: any) => {
          setPartnersWithPagination({
            meta: options.meta,
            objects: partnersWithPagination.objects ? [ ...partnersWithPagination.objects, ...options.objects ] : options.objects,
          });
        })
        .finally(() => setPartnersLoading(false));
    }
  };

  const onAddPartner = () => {
    if (!userId || !selectedPartner) {
      return;
    }

    setAddPartnerLoading(true);

    return addPartner({ userId, partnerId: selectedPartner })
      .then(() => {
        onAddPartnerCallback();
        setPartnersWithPagination({});
        setSelectedPartner(undefined);
        setPopoverVisible(false);
      })
      .finally(() => {
        setAddPartnerLoading(false);
      });
  };

  const popoverContent = () => {
    const partnersKeys = _.map(partners, p => p.partner.partnerId);
    const options = _.map(partnersWithPagination.objects, p => (
      <Select.Option
        key={p.partnerId}
        value={p.partnerId}
        disabled={p.assignedToMe || _.includes(partnersKeys, p.partnerId)}
      >
        {p.name}
      </Select.Option>
    ));

    return (
      <Space size={15} direction="vertical">
        <Select
          placeholder="Select or type Partner name"
          className={cx(selects.qsSelect, s.partnerSelect)}
          bordered={false}
          dropdownClassName={selects.selectDropdown}
          onFocus={handleOnFocus}
          onPopupScroll={onPopupScroll}
          dropdownRender={dropdownRender}
          onChange={(value) => {
            if (_.isUndefined(value)) {
              onSearchPartners('');
            }
            setSelectedPartner(value);
          }}
          size="large"
          value={selectedPartner}
          getPopupContainer={triggerNode => triggerNode.parentElement}
          onSearch={onSearchPartners}
          filterOption={() => true}
          showSearch
          allowClear
          suffixIcon={<SelectIcon />}
        >
          {options}
        </Select>
        <Button
          className={buttons.qsButtonPrimary}
          type="primary"
          size="large"
          loading={addPartnerLoading}
          onClick={onAddPartner}
          disabled={_.isNil(selectedPartner)}
        >
          Add partner
        </Button>
      </Space>
    );
  };

  return (
    <Popover
      align={{ offset: [ -1, 0 ] }}
      overlayClassName={s.addPopoverOverlay}
      placement="bottomLeft"
      content={popoverContent()}
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    >
      <Button
        type="text"
        className={cx(s.addButton, { [s.openedButton]: popoverVisible, [s.disabledButton]: isEdit })}
        title="Add partners"
      >
        <AddActivityIcon className={s.activityIcon} />
      </Button>
    </Popover>
  );
};

export default AddPartnerButton;
