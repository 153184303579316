import React, { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import classNames from 'classnames/bind';

import S from './helpers/styles.module.sass';
import SKILL_EXPERIENCES from '../../../../../../../../helpers/constants/Skills/Experience';
import { SkillCategoryEnum, SkillExperienceEnum } from '../../../../../../../../helpers/types/Skills';
import ElementStyles from '../SkillSetExperience/components/SkillElement/helpers/styles.module.sass';
import SkillAttitude from '../../../Attitude';

const cx = classNames.bind(S);
const cxElement = classNames.bind(ElementStyles);

function getItemStyles(initialOffset: any, currentOffset: any) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer: React.FC<{}> = () => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const renderItem = useMemo(() => {
    if (itemType && Object.values((SkillCategoryEnum)).includes(itemType as SkillCategoryEnum)) {
      return (
        <div
          className={cxElement('skill-element_users-skill', cx('custom-drag-element'))}
        >
          <div
            className={cxElement('skill-element_users-skill-inner')}
            style={{ backgroundColor: SKILL_EXPERIENCES[item.userSkill.experience as SkillExperienceEnum].color }}
          >
            <span>{item.userSkill.skill.name}</span>
            <div className={cxElement('skill-element_attitudes-icons')}>
              <SkillAttitude iconName={item.userSkill.attitude} />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [ itemType, item ]);

  if (!isDragging) {
    return null;
  }
  return (
    <div className={cx('custom-drag-layer')}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem}
      </div>
    </div>
  );
};

export default CustomDragLayer;
