import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';
import { TableComponentPreloader } from '../tableTypes';

const cx = classNames.bind(S);

interface TableBodyComponentProps {
  preloader?: TableComponentPreloader;
}

class TableBody extends React.Component<TableBodyComponentProps, {}> {
  renderPreloader(): React.ReactNode {
    let rows = 0,
      columns = 0;

    if (this.props.preloader) {
      rows = this.props.preloader.rows || 0;
      columns = this.props.preloader.columns || 0;
    }

    return (
      <>
        {Array(rows)
          .fill(0)
          .map((it, i) => (
            <tr key={i} className={cx('qs-table__row')}>
              {Array(columns)
                .fill(0)
                .map((it, i) => (
                  <td key={i} className={cx('qs-table__body-cell')}>
                    <div />
                  </td>
                ))}
            </tr>
          ))}
      </>
    );
  }

  render(): React.ReactNode {
    let isLoading = false;

    if (this.props.preloader) {
      isLoading = this.props.preloader.isLoading || false;
    }

    const childrenExists =
      this.props.children &&
      (this.props.children as [React.ReactElement]).length <= 1;

    return (
      <tbody className={cx('qs-table__body')}>
        {isLoading && childrenExists
          ? this.renderPreloader()
          : this.props.children}
      </tbody>
    );
  }
}

export default TableBody;
