import _ from 'lodash';

import { BaseUserOutput } from 'api/User/types';

type GenObject = {
  [key: string]: any;
};

type CaseType = 'snakeCase' | 'camelCase';

export const getCorrectRequesterForPartnerEditing = (requester: BaseUserOutput | null | undefined) => {
  switch (true) {
    case _.isNull(requester):
      return { requester_id: null };
    case _.isUndefined(requester):
      return {};
    case !_.isUndefined(requester?.userId):
      return { requester_id: requester?.userId };
    default:
      return {};
  }
};

export const convertObjectKeysToLanguageCase = <T extends GenObject>(object?: GenObject, caseType: CaseType = 'camelCase') => _.reduce(object, (acc, value, key): T => {
  const caseFunction = caseType === 'camelCase' ? _.camelCase : _.snakeCase;

  if (_.isArray(value)) {
    return {
      ...acc,
      [caseFunction(key)]: convertArrayObjectKeysToLanguageCase(value, caseType),
    };
  }

  if (_.isObject(value)) {
    return {
      ...acc,
      [caseFunction(key)]: convertObjectKeysToLanguageCase(value, caseType),
    };
  }

  if (_.isNil(value)) {
    return {
      ...acc,
      [caseFunction(key)]: value,
    };
  }

  return {
    ...acc,
    [caseFunction(key)]: value,
  };
}, {} as T);

export const convertArrayObjectKeysToLanguageCase = <T extends GenObject[]>(array: T, caseType: CaseType = 'camelCase') => _.map(array, (a) => {
  if (_.isObject(a)) {
    return convertObjectKeysToLanguageCase(a, caseType);
  }
  return a;
});
