export const LOGOUT = '[LOGIN] LOGOUT';
export const GET_USER_DATA = '[LOGIN] GET USER DATA';
export const UPDATE_USER_DATA = '[LOGIN] UPDATE USER DATA';
export const CHANGE_CONTENT = '[LOGIN] CHANGE CONTENT';
export const SHOW_LOGOUT_MODAL = '[LOGIN] SHOW LOGOUT MODAL';
export const CHANGE_PROFILES_OCCUPATION = '[LOGIN] CHANGE PROFILES OCCUPATION';

export const CHANGE_UNREAD_NOTIFICATIONS_COUNT = '[LOGIN] CHANGE UNREAD NOTIFICATIONS COUNT';

export const GET_UNREAD_COUNTS = '[LOGIN] GET UNREAD COUNTS';

export const GET_AUTH_PATH = '[LOGIN] GET AUTH PATH';

export const SET_USER_LAYOUT_TO_MOBILE = '[LOGIN] SET_USER_LAYOUT_TO_MOBILE';

export const SET_USER_PREFERENCES = '[LOGIN] SET_USER_PREFERENCES';
