import React from 'react';
import classNames from 'classnames/bind';
import { Radio, RadioChangeEvent } from 'antd';
import { useHistory, useParams } from 'react-router';

import S from './helpers/styles.module.sass';
import { SpecialistsTabsProps } from './helpers/types';
import { SPECIALISTS_TABS } from '../../helpers/constants';
import { PATH_FIND_SPECIALISTS } from '../../../../helpers/constants/_common/MenuCases';
import { SpecialistsURLParams } from '../../helpers/types';
import radio from '../../../../helpers/styles/components/radio.module.sass';

const cx = classNames.bind(S);

const SpecialistsTabs: React.FC<SpecialistsTabsProps> = ({ tab }) => {
  const history = useHistory();
  const { specialistRequestId } = useParams<SpecialistsURLParams>();

  const handleOnChangeRadio = (event: RadioChangeEvent) => {
    history.push(`${PATH_FIND_SPECIALISTS}/${event.target.value}`);
  };

  return (
    <div className={cx(radio.tableRadio, S.specialistsRadio)}>
      <Radio.Group
        value={tab}
        onChange={handleOnChangeRadio}
      >
        <Radio
          className={radio.radioItem}
          value={SPECIALISTS_TABS.find}
        >
          Find specialists
        </Radio>
        <Radio
          className={radio.radioItem}
          value={SPECIALISTS_TABS.requests}
          onClick={() => {
            if (specialistRequestId) {
              history.push(`${PATH_FIND_SPECIALISTS}/${SPECIALISTS_TABS.requests}`);
            }
          }}
        >
          Requests
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default SpecialistsTabs;
