import { Column } from '../../_shared/Table/helpers/types';

export const CURATOR_HOUR_REPORT_COLUMNS: Column[] = [
  {
    title: 'Partner',
    dataIndex: 'partnerName',
    key: 'partnerName',
    width: 300,
    sorter: true,
  },

  {
    title: 'Activity',
    dataIndex: 'activity',
    key: 'activity',
    width: 500,
  },

  {
    title: 'Hrs',
    dataIndex: 'hours',
    key: 'hours',
    className: 'borderless',
    sorter: true,
  },

];

export const MOBILE_CURATOR_REPORT_COLUMNS: Column[] = [
  {
    title: 'Partner / Activity / User',
    dataIndex: 'partnerName',
    key: 'partnerName',
    sorter: true,
  },
  {
    title: 'Hrs',
    dataIndex: 'hours',
    key: 'hours',
    align: 'right',
    width: 60,
    sorter: true,
  },
];
