import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Meta, Sorter } from 'api/_request/types';
import { getUserHourReportsData } from 'api/Reports/requests';
import useApi from 'api/hooks/useApi/useApi';
import { UserDetailedReports } from 'api/Reports/types';
import { Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';

import s from './styles.module.sass';
import { MONTH_YEAR_FORMAT } from '../../helpers/constants/_common/timeFormats';
import UserHourReportsSettings from './components/UserHourReportsSettings/UserHourReportsSettings';
import { BaseUserOutputChecked, NotificationsModalData, TimeRangeType, UserHourReportsSettingsState } from './types';
import UserHourReportsTable from './components/UserHourReportsTable/UserHourReportsTable';
import UserHourReportsModal from './components/UserHourReportsModal.tsx/UserHourReportModal';
import NotificationConfirmationModal from './components/NotificationConfirmationModal/NotificationConfirmationModal';

interface UserHourReportsProps {
}

const DEFAULT_SETTINGS_STATE: UserHourReportsSettingsState = {
  dates: {
    selectedDate: dayjs().format(MONTH_YEAR_FORMAT),
    modifiedMonth: false,
    startDateRange: undefined,
    endDateRange: undefined,
  },
  searchValue: undefined,
  entityId: [],
  partnerId: [],
  userId: [],
};

const NOTIFICATION_MODAL_INIT_DATA: NotificationsModalData = {
  isVisible: false,
  users: [],
  year: dayjs().year(),
  month: dayjs().month() + 1,
};

const UserHourReports: React.FC<UserHourReportsProps> = () => {
  const { sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.USER_HOUR_REPORTS]);
  const [ replaceToolModalVisible, setReplaceToolModalVisible ] = useState(false);
  const [ notificationModalData, setNotificationModalData ] = useState<NotificationsModalData>(NOTIFICATION_MODAL_INIT_DATA);

  const [ settingsValues, setSettingsValues ] = useState<UserHourReportsSettingsState>(DEFAULT_SETTINGS_STATE);
  const [ getUserHourData, userHourReportsData, loading ] = useApi<typeof getUserHourReportsData, UserDetailedReports>(getUserHourReportsData);
  const [ typeOfTimeRange, setTypeOfTimeRange ] = useState<TimeRangeType>('month');

  const usersToNotify: BaseUserOutputChecked[] = useMemo(() => (
    _.filter(userHourReportsData?.users, report => !report.periodIsClosed && report.user.active).map(report => ({ ...report.user, checked: true }))
  ), [ userHourReportsData ]);

  const getUserHourReports = (pagination: Meta | false, sorter?: Sorter) => {
    // if no dates provided - not call request (for the first time)
    if (!settingsValues.dates.startDateRange || !settingsValues.dates.endDateRange || !settingsValues.dates) {
      return;
    }

    getUserHourData(
      [ settingsValues.dates.startDateRange, settingsValues.dates.endDateRange ],
      {
        ...sorter,
        searchValue: settingsValues.searchValue,
        entityId: settingsValues.entityId,
        partnerId: settingsValues.partnerId,
        userId: settingsValues.userId,
        periodIsClosed: settingsValues.periodIsClosed,
      },
    );
  };

  useEffect(() => {
    getUserHourReports(false, sorter);
  }, [
    settingsValues.dates.startDateRange, settingsValues.dates.endDateRange,
    settingsValues.partnerId, settingsValues.entityId, settingsValues.userId,
    settingsValues.searchValue, settingsValues.periodIsClosed,
  ]);

  const openNotificationModal = (users: BaseUserOutputChecked[]) => {
    const dayjsObj = dayjs(settingsValues.dates.selectedDate);
    setNotificationModalData({
      isVisible: true,
      users,
      year: dayjsObj.year(),
      month: dayjsObj.month() + 1,
    });
  };

  return (
    <div className={s.userReportLayout}>
      <UserHourReportsSettings
        setSettingsValues={setSettingsValues}
        settingsValues={settingsValues}
        setTypeOfTimeRange={setTypeOfTimeRange}
        typeOfTimeRange={typeOfTimeRange}
        openReplaceTool={() => setReplaceToolModalVisible(true)}
        openNotificationModal={() => openNotificationModal(usersToNotify)}
      />

      <div className={s.userReportLayoutBody}>
        <UserHourReportsTable
          loading={loading}
          settingsValues={settingsValues}
          getUserHourReports={getUserHourReports}
          userHourReportsData={userHourReportsData}
          openNotificationModal={openNotificationModal}
        />
      </div>
      <UserHourReportsModal
        settings={settingsValues}
        typeOfTimeRange={typeOfTimeRange}
        refreshCalendar={() => getUserHourReports(false, sorter)}
        isVisible={replaceToolModalVisible}
        onClose={() => setReplaceToolModalVisible(false)}
      />
      <NotificationConfirmationModal
        {...notificationModalData}
        onClose={() => setNotificationModalData(NOTIFICATION_MODAL_INIT_DATA)}
      />
    </div>
  );
};

export default UserHourReports;
