import { ConstantType } from '../../helpers/types/_common';
import { SRStatuses } from './types';

export const SKILL_EXPERIENCE_RAW: ConstantType<string> = {
  notSet: 'experience_ng',
  petProjects: 'experience_gte_1g',
  commercialUse: 'experience_gte_2g',
  confident: 'experience_gte_3g',
  level80: 'experience_gte_4g',
  englishKnowledge: 'english_knowledge_gte',
};

export const SPECIALISTS_REQUESTS_STATUSES: ConstantType<string> = {
  [SRStatuses.Hiring]: 'Hiring',
  [SRStatuses.Staffed]: 'Staffed',
};

export const SKILLS_EXPERIENCE_MAPPER: ConstantType<string> = {
  notSet: 'ANY',
  petProjects: 'PET_PROJECTS',
  commercialUse: 'COMMERCIAL_USE',
  confident: 'CONFIDENT',
  level80: 'LEVEL_80',
};

export const SKILLS_EXPERIENCE_MAPPER_RAW: ConstantType<string> = {
  ANY: 'notSet',
  PET_PROJECTS: 'petProjects',
  COMMERCIAL_USE: 'commercialUse',
  CONFIDENT: 'confident',
  LEVEL_80: 'level80',
};
