import React from 'react';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';

import { Month as MonthProps } from 'api/BusinessCalendar/types';

import calendarCarouselConfig from '../../helpers/sliderConfig';
import Month from '../Month/Month';
import { CalendarMonthData } from '../Month/components/MonthInner/helpers/types';

interface SwiperCalendarProps {
  onOpenDrawer: (monthInnerData: CalendarMonthData) => void;
  calendar: MonthProps[];
  loading: boolean;
  setSwiper: React.Dispatch<React.SetStateAction<SwiperCore | undefined>>;
  selectedMonthIndex: number;
  changeSelectedMonthIndex: React.Dispatch<React.SetStateAction<number>>;
}

const SwiperCalendar: React.FC<SwiperCalendarProps> = (props) => {
  const {
    onOpenDrawer, calendar, loading,
    selectedMonthIndex, setSwiper, changeSelectedMonthIndex,
  } = props;

  const list = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

  const omittedProps = _.omit(calendarCarouselConfig, [ 'sliderPerView' ]);

  const renderSlide = (monthObj: MonthProps) => (
    <Month
      key={`${monthObj.year}-${monthObj.month}`}
      onDrawerOpen={onOpenDrawer}
      isActiveMonth={selectedMonthIndex === monthObj.month - 1}
      isLoading={loading}
      monthData={monthObj}
    />
  );

  return (
    <Swiper
      initialSlide={selectedMonthIndex}
      {...omittedProps}
      onSwiper={setSwiper}
      onSlideChange={newMonthIndex => changeSelectedMonthIndex(newMonthIndex.realIndex)}
    >
      {list.map((obj, i) => (
        <SwiperSlide key={obj}>{renderSlide(calendar[i])}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperCalendar;
