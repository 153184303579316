import { Select, Space } from 'antd';
import React, { ChangeEvent } from 'react';
import _ from 'lodash';
import classNames from 'classnames/bind';

import inputs from 'helpers/styles/components/inputs.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';

import SKILL_CATEGORIES from '../../../../../../helpers/constants/Skills/Categories';
import { ReactComponent as SelectIcon } from '../../../../../../helpers/icons/SelectArrow.svg';
import { MergeAndCreateNewTagProps } from './helpers/types';
import S from '../../helpers/styles.module.sass';
import InputWithError from '../../../../../_shared/InputWithError';

const cx = classNames.bind(S);

const MergeAndCreateNewTag: React.FC<MergeAndCreateNewTagProps> = (props) => {
  const {
    name,
    setName,
    category,
    handleOnChangeCategory,
    error,
    setError,
  } = props;

  const renderCategorySelector = () => {
    const options = _.map(SKILL_CATEGORIES, (s, key) => (
      <Select.Option key={s.categoryId} value={key}>{s.title}</Select.Option>
    ));

    return (
      <Select
        placeholder="Select category..."
        className={cx(selects.qsSelect, S.mergeSelect)}
        value={category}
        onChange={handleOnChangeCategory}
        suffixIcon={<SelectIcon />}
        size="large"
        allowClear
        bordered={false}
      >
        { options }
      </Select>
    );
  };

  const renderInput = () => (
    <InputWithError
      error={error}
      fieldName="name"
      placeholder="New tag name..."
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        if (error) {
          setError?.(null);
        }

        setName(event.target.value);
      }}
      value={name}
      size="large"
      maxLength={255}
      className={inputs.qsInput}
      allowClear
    />
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {renderInput()}
      {renderCategorySelector()}
    </Space>
  );
};

export default MergeAndCreateNewTag;
