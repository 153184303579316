import React from 'react';
import cx from 'classnames';

import s from './styles.module.sass';
import TrackerLogo from '../TrackerLogo/TrackerLogo';
import { BaseUserOutput } from '../../../api/User/types';

interface EmployeeListItemProps {
  employee: BaseUserOutput;
  className?: string;
  textClassName?: string;
}

const EmployeeListItem: React.FC<EmployeeListItemProps> = ({ employee, className, textClassName }) => (
  <div className={cx(s.userItemWrapper, className)}>
    {
      employee?.avatarUrl
        ? <img className={s.userAvatar} src={employee?.avatarUrl} alt="avatar" />
        : <TrackerLogo className={s.companyAvatar} />
    }
    <span
      className={cx(textClassName)}
      title={`${employee.forename} ${employee.surname}`}
    >
      {`${employee.forename} ${employee.surname}`}
    </span>

  </div>
);

export default EmployeeListItem;
