import Subtypes from '../constants/__common__/Subtypes';
import _ from 'lodash';

function getInvalideDataProvided(errors) {
  return Object.keys(errors.errorsMapping).map(key => `${key}: ${errors.errorsMapping[key]}`);
}

function getStringByErrorsGroup(errors) {
  const groups = {};
  const keys = Object.keys(errors);

  if (keys[0] === 'detail') return errors.detail;

  keys.forEach(errorField => {
    const errorMessage = _.isArray(errors[errorField]) ? errors[errorField][0] : errors[errorField];

    if (!groups[errorMessage]) {
      groups[errorMessage] = [errorField];
    } else {
      groups[errorMessage] = [...groups[errors[errorField]], errorField];
    }
  });

  const arrayOfErrors = Object.keys(groups).reduce((a,b) => [...a, `${groups[b].reduce((a, b) => `${a}, ${b}`)}: ${b}`], [])

  return _.join(arrayOfErrors, '; ');
}

function getReadableError(error) {
  if (!_.get(error, 'data')) {
    return 'Invalid data provided';
  }

  if (_.get(error, 'data.error') === 'Invalid data provided') {
    return getInvalideDataProvided(error.data);
  }

  switch (typeof error.data) {
    case 'string':
      if (error.data.length > 1000) {
        return error.statusText;
      } else {
        return error.data;
      }
    case 'object':
      if (Array.isArray(error.data)) {
        return error.data;
      } else {
        return getStringByErrorsGroup(error.data);
      }
    default:
      return error.statusText;
  }
}

const get = (storage) => (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, [storage]: {...state[storage], isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {...state, [storage]: {...state[storage], isLoading: false, errorMessage: '', objects: action.payload.objects}};
    case Subtypes.Request.Error:
      return {...state, [storage]: {...state[storage], isLoading: false, errorMessage: getReadableError(action.payload)}};
    default:
      return state;
  }
};

const request = (storage) => (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, [storage]: {...state[storage], isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {...state, [storage]: {...state[storage], isLoading: false, errorMessage: ''}};
    case Subtypes.Request.Error:
      return {...state, [storage]: {...state[storage], isLoading: false, errorMessage: getReadableError(action.payload)}};
    default:
      return state;
  }
};

const modalToggle = (storage, InitialState) => (action, state) => {
  switch (action.subtype) {
    case Subtypes.Modal.Show:
      return {...state, [storage]: {...state[storage], ...action.payload, show: true}};
    case Subtypes.Modal.Hide:
      return {...state, [storage]: {...state[storage], ...InitialState, show: false}};
    default:
      return state;
  }
};

const processCommonEvent = {
  get,
  request,
  modalToggle
};

export default processCommonEvent;
