import React from 'react';
import cx from 'classnames';
import { Button, Modal, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import _ from 'lodash';

import modals from 'helpers/styles/components/modals.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';

import TableSelectionList from '../../../TableComponents/TableSelectionList';
import s from './helpers/styles.module.sass';
import TableAvatar from '../../../TableComponents/TableAvatar';
import { UserStatisticOutputChecked } from '../../helpers/types';
import { massUserUpdateStatus } from '../../../../api/User/requests';

interface EmployeesSelectionPanelProps {
  setSelectedRows: (selectedRows: UserStatisticOutputChecked[]) => void;
  selectedRows: UserStatisticOutputChecked[];
  getEmployeesData: () => void;
}

const EmployeesSelectionPanel: React.FC<EmployeesSelectionPanelProps> = ({ setSelectedRows, selectedRows, getEmployeesData }) => {
  const onChangeCheckbox = (event: CheckboxChangeEvent, targetRow: UserStatisticOutputChecked) => {
    const { userId } = targetRow;
    const checked = event?.target?.checked;

    const updatedSelectedRows = _.map(selectedRows, (s) => {
      if (s.userId === userId) {
        return { ...s, checked };
      }

      return s;
    });

    setSelectedRows(updatedSelectedRows);
  };

  const resetSelections = () => {
    setSelectedRows([]);
  };

  const handleChangeStatus = (isAnyActiveUser: boolean) => {
    const selectedUsers = _.map(selectedRows, row => (
      <Space className={s.statusModalUser} align="center" key={row.userId}>
        <TableAvatar
          url={row.avatarUrl}
        />
        <span>{`${row.forename} ${row.surname}`}</span>
      </Space>
    ));

    const modalContent = (
      <Space size={18} direction="vertical" style={{ width: '100%' }}>
        <span>{`You are going to ${isAnyActiveUser ? 'deactivate' : 'activate'} following people:`}</span>
        <Space direction="vertical" split={<div className={cx('splitter')} />} className={s.statusModalList}>
          {selectedUsers}
        </Space>
      </Space>
    );

    const onOk = async () => {
      const userIds = _.map(selectedRows, r => r.userId);

      await massUserUpdateStatus({
        userIds,
        active: !isAnyActiveUser,
      });
      setSelectedRows([]);
      getEmployeesData();
    };

    Modal.confirm({
      title: `${isAnyActiveUser ? 'Deactivate' : 'Activate'} several employees`,
      className: modals.qsConfirmAntdModal,
      content: modalContent,
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk,
      okText: isAnyActiveUser ? 'Deactivate' : 'Activate',
    });
  };

  const changeStatusButton = () => {
    const isAnyActiveUser = _.some(selectedRows, [ 'active', true ]);

    return (
      <Button
        className={buttons.qsButtonPrimary}
        type="primary"
        size="large"
        onClick={() => handleChangeStatus(isAnyActiveUser)}
      >
        {isAnyActiveUser ? 'Deactivate' : 'Activate'}
      </Button>
    );
  };

  return (
    <div className={s.employeeSelectionPanel}>
      <Space>
        <Typography.Text className={s.employeeSelectionPanelCount}>
          {`${selectedRows.length} selected`}
        </Typography.Text>

        <TableSelectionList
          resetSelections={resetSelections}
          onChangeCheckbox={onChangeCheckbox}
          renderTitle={(row: UserStatisticOutputChecked) => `${row.forename} ${row.surname}`}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Space>
      <Space>
        <Button
          className={buttons.qsButton}
          size="large"
          disabled
        >
          Send Notification
        </Button>
        {changeStatusButton()}
      </Space>
    </div>
  );
};

export default EmployeesSelectionPanel;
