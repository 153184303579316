import * as Actions from '../../constants/VacancyPage/Vacancies.js';
import VacanciesRequests from '../../requests/Vacancies/Vacancies';
import Subtypes from '../../constants/__common__/Subtypes';
import {getObjectsWithPaginationData} from '../../constants/__common__/PaginationConstants';

export function getVacancies(data) {
  return dispatch => {
    dispatch({type: Actions.GET_VACANCIES, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.getVacancies(getObjectsWithPaginationData(data))
      .then(res => dispatch({type: Actions.GET_VACANCIES, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.GET_VACANCIES, subtype: Subtypes.Request.Error, payload: error.message}))
  }
}

export function getAllVacancies(data) {
  return dispatch => {
    dispatch({type: Actions.GET_ALL_VACANCIES, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.getAllVacancies()
      .then(res => dispatch({type: Actions.GET_ALL_VACANCIES, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.GET_ALL_VACANCIES, subtype: Subtypes.Request.Error, payload: error.message}))
  }
}

export function getUnreadVacancyCount() {
  return dispatch => {
    VacanciesRequests
      .getUnreadVacancyCount()
      .then(res => dispatch(updateUnreadVacanciesCount(res.unreadVacancies)))
  }
}

export function getVacancyById(vacancyId) {
  return dispatch => {
    dispatch({type: Actions.GET_VACANCY_BY_ID, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.getVacancyById(vacancyId)
      .then(res => dispatch({type: Actions.GET_VACANCY_BY_ID, subtype: Subtypes.Request.Success, payload: res}))
      .catch(error => dispatch({type: Actions.GET_VACANCY_BY_ID, subtype: Subtypes.Request.Error, payload: error.message}))
  }
}

export function getAffiliates() {
  return dispatch => {
    VacanciesRequests.getAffiliates()
      .then(res => dispatch({type: Actions.GET_AFFILIATES, payload: res}))
  }
}

export function addVacancy(vacancy) {
  return dispatch => {
    dispatch({type: Actions.ADD_VACANCY, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.addVacancy(vacancy)
      .then(res => {
        return dispatch({type: Actions.ADD_VACANCY, subtype: Subtypes.Request.Success, payload: res})
      })
      .catch(error => {
        return dispatch({type: Actions.ADD_VACANCY, subtype: Subtypes.Request.Error, payload: error.response})
      })
  }
}
export function removeVacancy(vacancy) {
  return dispatch => {
    dispatch({type: Actions.REMOVE_VACANCY, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.removeVacancy(vacancy)
      .then(res => {
        return dispatch({type: Actions.REMOVE_VACANCY, subtype: Subtypes.Request.Success, payload: res})
      })
      .catch(error => {
        return dispatch({type: Actions.REMOVE_VACANCY, subtype: Subtypes.Request.Error, payload: error.response})
      })
  }
}
export function editVacancy(vacancy) {
  return dispatch => {
    dispatch({type: Actions.EDIT_VACANCY, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.editVacancy(vacancy)
      .then(res => {
        return dispatch({type: Actions.EDIT_VACANCY, subtype: Subtypes.Request.Success, payload: res})
      })
      .catch(error => {
        return dispatch({type: Actions.EDIT_VACANCY, subtype: Subtypes.Request.Error, payload: error.response})
      })
  }
}

export function suspendVacancyToggle(vacancy) {
  return dispatch => {
    dispatch({type: Actions.SUSPEND_VACANCY, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.suspendVacancyToggle(vacancy)
      .then(res => {
        return dispatch({type: Actions.SUSPEND_VACANCY, subtype: Subtypes.Request.Success, payload: res})
      })
      .catch(error => {
        return dispatch({type: Actions.SUSPEND_VACANCY, subtype: Subtypes.Request.Error, payload: error.response})
      })
  }
}

export function markAllAsRead() {
  return dispatch => {
    dispatch({type: Actions.MARK_ALL_AS_READ, subtype: Subtypes.Request.Loading});

    return VacanciesRequests.markAllVacanciesAsRead()
      .then(() => dispatch({type: Actions.MARK_ALL_AS_READ, subtype: Subtypes.Request.Success}))
      .catch(error => dispatch({type: Actions.MARK_ALL_AS_READ, subtype: Subtypes.Request.Error, payload: error.response}))
  }
}

export function updateUnreadVacanciesCount(data) {
  return {type: Actions.UPDATE_UNREAD_VACANCIES_COUNT, payload: data}
}

export function openVacancyRemoveConfirmationModal(data) {
  return {type: Actions.VACANCY_REMOVE_CONFIRMATION_MODAL, subtype: Subtypes.Modal.Show, payload: data}
}
export function closeVacancyRemoveConfirmationModal() {
  return {type: Actions.VACANCY_REMOVE_CONFIRMATION_MODAL, subtype: Subtypes.Modal.Hide}
}

export function openVacancyModal(data) {
  return {type: Actions.VACANCY_MODAL, subtype: Subtypes.Modal.Show, payload: data}
}
export function closeVacancyModal() {
  return {type: Actions.VACANCY_MODAL, subtype: Subtypes.Modal.Hide}
}

export function openRecommendationProgramDescriptionModal(data) {
  return {type: Actions.RECOMMENDATION_PROGRAM_MODAL_DESCRIPTION, subtype: Subtypes.Modal.Show}
}
export function closeRecommendationProgramDescriptionModal() {
  return {type: Actions.RECOMMENDATION_PROGRAM_MODAL_DESCRIPTION, subtype: Subtypes.Modal.Hide}
}

export function openSuspendingConfirmationModal(data) {
  return {type: Actions.SUSPENDING_CONFIRMATION_MODAL, subtype: Subtypes.Modal.Show, payload: data}
}
export function closeSuspendingConfirmationModal() {
  return {type: Actions.SUSPENDING_CONFIRMATION_MODAL, subtype: Subtypes.Modal.Hide}
}

export function changeSelectedVacancy(vacancy) {
  return {type: Actions.CHANGE_SELECTED_VACANCY, payload: vacancy}
}
export function changeSelectedVacancyToSuspending(vacancy) {
  return {type: Actions.CHANGE_SELECTED_VACANCY_TO_SUSPENDING, payload: vacancy}
}
export function changeSelectedVacancyToRemove(vacancy) {
  return {type: Actions.CHANGE_SELECTED_VACANCY_TO_REMOVE, payload: vacancy}
}

export function updateVacanciesListWithNewOne(vacancy) {
  return dispatch => {
    dispatch({type: Actions.UPDATE_VACANCIES_LIST_WITH_NEW_ONE, payload: {...vacancy, isViewed: true}});
    dispatch(getUnreadVacancyCount());
  }
}
export function updateVacanciesWithSuspended(vacancyId) {
  return {type: Actions.UPDATE_VACANCIES_WITH_SUSPENDED, payload: vacancyId}
}
export function updateVacanciesWithRemoved(vacancyId) {
  return dispatch => {
    dispatch({type: Actions.UPDATE_VACANCIES_WITH_REMOVED, payload: vacancyId});
    dispatch(getUnreadVacancyCount());
  }
}
export function updateVacanciesListWithUpdated(vacancy) {
  return {type: Actions.UPDATE_VACANCIES_WITH_UPDATED, payload: vacancy}
}
