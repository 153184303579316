import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import { Spin, Pagination } from 'antd';
import _ from 'lodash';

import { Partner } from 'helpers/types/Partners';
import useApi from 'api/hooks/useApi/useApi';
import requestsApi from 'api/Specialists/requests';
import { DEFAULT_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';

import { ReactComponent as SelectAPartner } from './helpers/SelectAPartner.svg';
import RequestsPartnersList from './components/PartnersList';
import S from './helpers/styles.module.sass';
import RequestsTable from './components/RequestsTable';
import { ActivitySpecialistRequestsPage } from '../../../../api/Specialists/types';

const cx = className.bind(S);

const defaultState: ActivitySpecialistRequestsPage = {
  meta: {
    limit: 10,
    next: null,
    offset: 0,
    previous: null,
    totalCount: 10,
  },
  objects: [],
};

const RequestsTab: React.FC = () => {
  const [ activePartner, changeActivePartner ] = useState<Partner | null>(null);
  const [ currentPage, changeCurrentPage ] = useState(1);

  const [
    getSRByPartnerId,
    SRActivities,
    SRLoading,
  ] = useApi<typeof requestsApi.getSRByPartnerId, GetSR>(requestsApi.getSRByPartnerId, { defaultData: defaultState });

  useEffect(() => {
    if (activePartner) {
      getSRByPartnerId(activePartner.partnerId, {
        offset: (currentPage - 1) * (SRActivities?.meta?.limit || DEFAULT_PAGINATION_RAW.limit),
        limit: SRActivities.meta.limit,
        activities_active: true,
      });
    }
  }, [ activePartner ]);

  const afterCreatingNewRequest = () => {
    if (activePartner) {
      getSRByPartnerId(activePartner.partnerId, {
        offset: SRActivities.meta.offset,
        limit: SRActivities.meta.limit,
      });
    }
  };

  const onPageChange = (pageNumber: number) => {
    if (!activePartner) return;

    changeCurrentPage(pageNumber);
    getSRByPartnerId(activePartner.partnerId, {
      offset: (pageNumber - 1) * (SRActivities.meta.limit || DEFAULT_PAGINATION_RAW.limit),
      limit: SRActivities.meta.limit,
    });
  };

  const renderRequests = () => {
    if (!activePartner) {
      return (
        <div className={cx('request-tab-partner')}>
          <SelectAPartner />
          <p>Select a partner to start</p>
        </div>
      );
    } else {
      return (
        <div className={cx('request-tab-table-wrapper')}>
          {
            _.map(SRActivities.objects, SRActivity => (
              <RequestsTable
                key={SRActivity.activityId}
                activity={SRActivity}
                partner={activePartner}
                afterCreatingNewRequest={afterCreatingNewRequest}
              />
            ))
          }
          {SRActivities.meta.totalCount > SRActivities.meta.limit && (
            <Pagination
              onChange={onPageChange}
              defaultCurrent={1}
              total={SRActivities.meta.totalCount}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className={cx('requests-tab-wrapper')}>
      <div className={cx('requests-tab-partners_wrapper')}>
        <RequestsPartnersList
          onPartnerSelect={changeActivePartner}
          activePartner={activePartner}
          SRLoading={SRLoading}
        />
      </div>
      <div className={cx('requests-tab-requests_wrapper')}>
        {SRLoading && <Spin size="large" className={cx('requests-tab-requests_preloader')} />}
        {renderRequests()}
      </div>
    </div>
  );
};

export default RequestsTab;
