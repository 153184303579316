import React from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import cx from 'classnames';

import buttons from 'helpers/styles/components/buttons.module.sass';

import s from './styles.module.sass';

interface LayoutOverlayProps {
  type: 'desktop' | 'mobile';
}

const LayoutOverlay: React.FC<LayoutOverlayProps> = ({ type }) => {
  const description = (type === 'desktop')
    ? 'The page doesn\'t fit fully into the screen. Please, reload page to switch to a mobile mode'
    : 'The page fits into the screen. Please, reload page to switch to a desktop mode';

  return (
    <div className={cx(s.rootSizeOverlay, { [s[type]]: true })}>
      <div className={cx(s.rootSizeOverlayDesc, { [s[type]]: true })}>
        <span className={s.overlayDescription}>{description}</span>
        <Button
          type="primary"
          className={buttons.qsButtonPrimary}
          icon={<ReloadOutlined />}
          size="large"
          onClick={() => window.location.reload()}
        >
          {' Reload page'}
        </Button>
      </div>
    </div>
  );
};

export default LayoutOverlay;
