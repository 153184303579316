import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import useApi from 'api/hooks/useApi/useApi';
import { UserPartnerOutput, UserPinnedActivityOutput } from 'api/Partners/types';
import { getActivitiesList, getPartnersList } from 'api/Partners/requests';
import modals from 'helpers/styles/components/modals.module.sass';
import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import { replaceUserHourAction } from 'redux/TimeSheet/action';
import { DispatchType } from 'helpers/types/_common';
import { ReactComponent as LeftArrowIcon } from 'helpers/icons/leftArrowIcon.svg';
import withRightPlural from 'helpers/utils/withRightPlural';

import ActivitiesList from '../../../ChooseActivityPanel/components/ActivitiesList/ActivitiesList';
import PartnersList from '../../../ChooseActivityPanel/components/PartnersList/PartnersList';
import S from './styles.module.sass';
import { ModifiedUsersHour } from '../../../UserHoursList/helpers/groupUserHoursByActivity';

interface ReplaceActivityInnerPanelProps {
  hours: number;
  userId: number;
  userHour: ModifiedUsersHour | null;
  onClose: () => void;
  setInnerPanel: (panel: 'main' | 'replace') => void;
}

const ReplaceActivityInnerPanel: React.FC<ReplaceActivityInnerPanelProps> = ({ userId, hours, userHour, setInnerPanel, onClose }) => {
  const dispatch: DispatchType = useDispatch();
  
  const [
    getPartners,
    partners,
    loadingPartners,
  ] = useApi<typeof getPartnersList, UserPartnerOutput[]>(getPartnersList, { defaultData: [] });
  const [
    getActivities,
    activities,
    loadingActivities,
  ] = useApi<typeof getActivitiesList, UserPinnedActivityOutput[]>(getActivitiesList, { defaultData: [] });
  
  useEffect(() => {
    getPartners(userId);
    getActivities(userId);
  }, []);

  const renderConfirmationContent = () => (
    <div>
      {`Are you sure you want to replace ${hours} ${withRightPlural('hour', hours)} selected?`}
    </div>
  );
  
  const replaceHandle = (
    selectionDay: string,
    startCellIndex: number,
    endCellIndex: number,
    activityId: number,
  ) => dispatch(replaceUserHourAction(userId, { day: selectionDay, startCellIndex, endCellIndex, activityId }));

  const handleClose = () => {
    setInnerPanel('main');
  };

  const chooseHandling = (activity: any) => {
    if (!userHour) return;

    const start = getDateAndCellIndexByTime(userHour?.startTime);
    const end = getDateAndCellIndexByTime(userHour?.endTime);

    if (!start.day || !end.cellIndex) return;

    Modal.confirm({
      className: classNames(modals.mobileQsBasicAntdModal, S.skillTagModal),
      title: 'Replace activity',
      content: renderConfirmationContent(),
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      okText: 'Replace',
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      icon: null,
      width: 500,
      onOk: () => replaceHandle(start.day, start.cellIndex, end.cellIndex, activity.activityId).then(() => {
        handleClose();
        onClose();
      }),
    });
  };

  return (
    <div className={S.panelWrapper}>
      <Button onClick={() => setInnerPanel('main')} className={S.backButton}>
        <LeftArrowIcon />
        {' '}
        Back
      </Button>
      <div className={S.lists}>
        <ActivitiesList
          activities={activities}
          isLoading={loadingActivities}
          handleSave={chooseHandling}
        />

        <PartnersList
          partners={partners}
          isLoading={loadingPartners}
          handleSave={chooseHandling}
        />
      </div>
    </div>
  );
};

export default ReplaceActivityInnerPanel;
