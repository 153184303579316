import _ from 'lodash';

const convertConflictError = (error: any) => {
  if (error?.field) {
    return {
      [_.camelCase(error.field)]: error?.error,
    };
  }

  return error;
};

export default convertConflictError;
