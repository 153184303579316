import React, { forwardRef } from 'react';
import { List, Typography } from 'antd';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { useDispatch, useSelector } from 'react-redux';

import { UserPartnerOutput } from 'api/Partners/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import { UserRedux } from 'redux/User/types';
import { UserDetails } from 'api/User/types';
import { updateUserPartners } from 'redux/TimeSheet/action';

import AddPartnerButton from '../AddActivityButton';
import PartnerListItem from './components/PartnerListItem';
import s from './helpers/styles.module.sass';

interface PartnersListProps {
  ref: React.ForwardedRef<HTMLDivElement>;
  isEdit: boolean;
  onAddActivity: (activityId: number) => Promise<any> | undefined;
  loading: boolean;
  collapsed: boolean;
  onAddPartnerCallback: () => void;
  onRemovePartnerCallback: () => void;
  userData: UserRedux | UserDetails;
  curatorOnlyView?: boolean;
}

const PartnersList: React.FC<PartnersListProps> = forwardRef((props, ref) => {
  const {
    loading, collapsed,
    onAddActivity, isEdit, onAddPartnerCallback,
    onRemovePartnerCallback, userData, curatorOnlyView,
  } = props;

  const dispatch: DispatchType = useDispatch();

  const { partners } = useSelector((state: Redux) => state.timeSheet);

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination } = dropResult;

    if (!destination) {
      return null;
    }

    const dragCard = partners[source.index];

    const updatedActivities = update(partners, {
      $splice: [
        [ source.index, 1 ],
        [ destination.index, 0, dragCard ],
      ],
    });

    dispatch(updateUserPartners(updatedActivities));
  };

  const renderMenu = (partnerObject: UserPartnerOutput, index: number) => (
    <Draggable
      key={partnerObject.partner.name}
      draggableId={partnerObject.partner.name}
      index={index}
      isDragDisabled={!isEdit}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <PartnerListItem
            userId={userData.userId}
            collapsed={collapsed}
            isEdit={isEdit}
            partnerObject={partnerObject}
            onAddActivity={onAddActivity}
            onRemovePartnerCallback={onRemovePartnerCallback}
            curatorOnlyView={curatorOnlyView}
          />
        </div>
      )}
    </Draggable>
  );

  return (
    <div className={cx(s.partnerListWrapper, { [s.collapsedPartnerList]: collapsed })} ref={ref}>
      <div className={s.partnerListTitle}>
        <Typography.Text className={s.listTitle}>Partners</Typography.Text>
        {
          !curatorOnlyView && (
            <AddPartnerButton
              isEdit={isEdit}
              userId={userData.userId}
              partners={partners}
              onAddPartnerCallback={onAddPartnerCallback}
            />
          )
        }

      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="partners" isDropDisabled={!isEdit}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <List
                className={s.partnerList}
                itemLayout="horizontal"
                dataSource={partners}
                loading={{ spinning: loading, size: 'small' }}
                renderItem={renderMenu}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
});

export default PartnersList;
