import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { GetPartnerParticipantsBody, PartnerDetailsOutput } from 'api/Partners/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { getPartnerParticipantsAction } from 'redux/Partners/actions';

import ParticipantsTable from './components/ParticipantsTable/ParticipantsTable';
import { Meta, Sorter } from '../../../../api/_request/types';
import ParticipantsSettings from './components/ParticipantsSettings/ParticipantsSettings';
import { PartnerParticipantsOutputChecked } from './helpers/types';
import s from './styles.module.sass';

interface PartnerParticipantsTabProps {
  partner: PartnerDetailsOutput;
}

const DEFAULT_FILTERS_STATE = {
  active: true,
  orderBy: 'user_fullname',
  activeTab: 'all',
  activity: null,
  occupation: null,
  entity_id: [],
};

const PartnerParticipantsTab: React.FC<PartnerParticipantsTabProps> = (props) => {
  const { partner } = props;
  const dispatch: DispatchType = useDispatch();
  const { state } = useLocation<{ activeTab: string }>();

  const { meta, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.PARTNER_PARTICIPANTS]);

  const defaultFilters = {
    ...DEFAULT_FILTERS_STATE,
    activeTab: state?.activeTab || 'all',
  };

  const [ selectedRows, setSelectedRows ] = useState<PartnerParticipantsOutputChecked[]>([]);
  const [ filters, setFilters ] = useState<GetPartnerParticipantsBody>(defaultFilters);

  const getParticipantsData = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      ...pagination,
      ...sorter,
      ...filters,
    };

    return dispatch(getPartnerParticipantsAction(partner.partnerId, body, TABLE_NAMES.PARTNER_PARTICIPANTS));
  };

  return (
    <div className={s.partnersLayout}>
      <ParticipantsSettings
        partner={partner}
        filters={filters}
        selectedRows={selectedRows}
        setFilters={setFilters}
        setSelectedRows={setSelectedRows}
        getParticipants={() => getParticipantsData(meta, sorter)}
      />

      <ParticipantsTable
        partner={partner}
        filters={filters}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        getParticipantsData={getParticipantsData}
      />
    </div>
  );
};

export default PartnerParticipantsTab;
