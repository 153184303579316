import axios from 'axios';
import _ from 'lodash';

import apiErrorHandler from '../../redux/ErrorHandler/helpers';
import configuredStore from '../../redux/_reducer/configureStore';
import { externalRedirectToLogin } from '../../V1/actions/Login/LoginActions';
import TokenStore from './helpers/jwtTokenStore';
import { APIHeader, APIHeaders, APIRequestParams, CancelTokens } from './types';
import axiosDefaultResponseParser from './helpers/responseParser';

declare module 'axios' {
  interface Headers {
    Authorization: string
  }

  export interface AxiosRequestConfig {
    skipAuth?: boolean;
    cancelToken?: CancelToken;
  }

  export interface AxiosResponse {
    access_token: string,
    refresh_token: string,
    expires_in: string
  }
}

const { CancelToken } = axios;

const API_ROOT = '/api/v2';
export const DEFAULT_API_PATH = '/api/v3';

export const DEFAULT_HEADERS: APIHeaders = {};

function request(url: string, body?: any) {
  return apiClient.post(`${API_ROOT}${url}`, { ...body })
    .then(res => res.data);
}

function getRequest(url: string, body?: any) {
  return apiClient.get(`${API_ROOT}${url}`, { ...body })
    .then(res => res.data);
}

/*
  V3 API
  this.cancelTokens - holding { requestId: cancellationToken } pairs to be able cancel previous request, if new one was awake
 */

const apiClient = axios.create({
  timeout: 60000,
  transformResponse: axiosDefaultResponseParser,
});

export class API {
  apiBaseUrl: string;

  defaultHeaders: APIHeaders;

  cancelTokens: CancelTokens;

  constructor(apiBaseUrl: string, defaultHeaders: APIHeaders) {
    this.apiBaseUrl = apiBaseUrl;
    this.defaultHeaders = defaultHeaders;
    this.cancelTokens = {};

    apiClient.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    apiClient.interceptors.request.use(async (config: any) => {
      const isItV2Request = config.url.split('/').includes('v2');

      if (config.skipAuth) {
        return config;
      }

      const accessToken = await this.getValidAccessToken();

      if (!accessToken) {
        configuredStore.dispatch(externalRedirectToLogin());
      }

      return {
        ...config,
        paramsSerializer: config?.paramsSerializer,
        headers: {
          common: !config.skipAuth ? {
            Authorization: isItV2Request ? `${accessToken}` : `Bearer ${accessToken}`,
          } : {},
        },
      };
    });
  }

  getValidAccessToken = async () => {
    const accessToken = TokenStore.getAccessToken();
    const isRefreshTokenValid = TokenStore.checkRefreshTokenValidity();
    const isAccessTokenValid = TokenStore.checkAccessTokenValidity();

    if (!isRefreshTokenValid) {
      configuredStore.dispatch(externalRedirectToLogin());
    }

    if (!isAccessTokenValid) {
      return TokenStore.refreshAccessToken();
    } else {
      return accessToken;
    }
  };

  generateCancelToken = (requestId?: string) => {
    if (!requestId) return undefined;

    return new CancelToken((cancel) => {
      this.cancelTokens = {
        ...this.cancelTokens,
        [requestId]: cancel,
      };
    });
  };

  cancelRequest = (requestId?: string) => {
    if (!requestId) {
      return;
    }

    if (this.cancelTokens[requestId]) {
      this.cancelTokens[requestId]();
    }
  };

  deleteCancelTokenId = (requestId?: string) => {
    if (!requestId) {
      return;
    }

    this.cancelTokens = _.omit(this.cancelTokens, requestId);
  };

  get<T = any>(resourceUrl: string, params?: any, requestOptions?: APIRequestParams) {
    return apiClient.get<T>(`${this.apiBaseUrl}${resourceUrl}`, {
      params,
      skipAuth: requestOptions?.withoutAT,
      headers: this.getHeaders(requestOptions?.customHeaders),
      paramsSerializer: requestOptions?.paramsSerializer,
      // cancelToken: this.generateCancelToken(requestOptions?.requestId),
    })
      .then(res => res.data)
      .catch((error) => {
        apiErrorHandler(error, apiClient, requestOptions?.errorObject);

        throw error;
      });
  }

  post<T = any>(resourceUrl: string, body?: any, requestOptions?: APIRequestParams) {
    return apiClient.post<T>(`${this.apiBaseUrl}${resourceUrl}`, body, {
      skipAuth: requestOptions?.withoutAT,
      headers: this.getHeaders(requestOptions?.customHeaders),
      // cancelToken: this.generateCancelToken(requestOptions?.requestId),
    })
      .then(res => res.data)
      .catch((error) => {
        apiErrorHandler(error, apiClient, requestOptions?.errorObject);

        throw error;
      });
  }

  put<T = any>(resourceUrl: string, body?: any, requestOptions?: APIRequestParams) {
    return apiClient.put<T>(`${this.apiBaseUrl}${resourceUrl}`, body, {
      skipAuth: requestOptions?.withoutAT,
      headers: this.getHeaders(requestOptions?.customHeaders),
      // cancelToken: this.generateCancelToken(requestOptions?.requestId),
    })
      .then(res => res.data)
      .catch((error) => {
        apiErrorHandler(error, apiClient, requestOptions?.errorObject);

        throw error;
      });
  }

  patch<T = any>(resourceUrl: string, body?: any, requestOptions?: APIRequestParams) {
    return apiClient.patch<T>(`${this.apiBaseUrl}${resourceUrl}`, body, {
      skipAuth: requestOptions?.withoutAT,
      headers: this.getHeaders(requestOptions?.customHeaders),
      // cancelToken: this.generateCancelToken(requestOptions?.requestId),
    })
      .then(res => res?.data || res)
      .catch((error) => {
        apiErrorHandler(error, apiClient, requestOptions?.errorObject);

        throw error;
      });
  }

  delete(resourceUrl: string, body?: any, requestOptions?: APIRequestParams) {
    return apiClient({
      method: 'DELETE',
      url: `${this.apiBaseUrl}${resourceUrl}`,
      data: body,
      skipAuth: requestOptions?.withoutAT,
      headers: this.getHeaders(requestOptions?.customHeaders),
      // cancelToken: this.generateCancelToken(requestOptions?.requestId),
    })
      .then(res => res.data)
      .catch((error) => {
        apiErrorHandler(error, apiClient, requestOptions?.errorObject);

        throw error;
      });
  }

  getHeaders(customHeaders?: APIHeader) {
    return {
      ...this.defaultHeaders,
      ...customHeaders,
    };
  }
}

// its for understadnig that `request` is V1 api and class above is V3 api
export default {
  request,
  getRequest,
  apiClient,

  V2: { request },
};
