import React from 'react';
import DatePickerInner from './Picker';
import classNames from 'classnames/bind';
import S from './styles.module.css';
import moment from 'moment';
// import shmoment from '../../../utils/shmoment/shmoment';

const cx = classNames.bind(S);

interface DatePickerComponentProps {
  date: string;
  format?: string;
  className?: string;
  top?: boolean;
  onChange: (date: string) => void;
}

interface DatePickerComponentState {
  date: string;
  showDatePicker: boolean;
}

export default class DatePicker extends React.Component<
  DatePickerComponentProps,
  DatePickerComponentState
> {
  constructor(props: DatePickerComponentProps) {
    super(props);

    this.state = {
      date: this.props.date,
      showDatePicker: false
    };
  }

  componentDidUpdate(prevProps: Readonly<DatePickerComponentProps>): void {
    if (
      this.props.date !== this.state.date &&
      prevProps.date !== this.props.date
    ) {
      this.setState({ date: this.props.date });
    }
  }

  handleChange(date: string): void {
    const reg = /^\d{4}-\d{2}-\d{2}$/;

    if (reg.test(date)) {
      if (this.props.onChange) {
        this.props.onChange(date);
      }

      this.handlePreChange(date);
    }
  }

  handlePreChange(date: string): void {
    this.setState({ date });
  }

  openDatePicker(): void {
    this.setState({ showDatePicker: true });
  }

  handleClose(): void {
    this.setState({
      showDatePicker: false,
      date: this.props.date
    });
  }

  render(): React.ReactNode {
    const { date, showDatePicker } = this.state;
    const { format, top } = this.props;

    return date ? (
      <div className={cx('date-picker__wrapper')} onClick={this.openDatePicker.bind(this)}>
        <input
          type="text"
          disabled
          className={`${cx('date-picker__input')} ${this.props.className}`}
          value={moment(date).format(format || 'YYYY/MM/DD')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            this.handleChange((e.target as HTMLInputElement).value)
          }
        />
        <DatePickerInner
          date={date}
          show={showDatePicker}
          top={top}
          handlePreChange={this.handlePreChange.bind(this)}
          handleChange={this.handleChange.bind(this)}
          handleClose={this.handleClose.bind(this)}
        />
      </div>
    ) : (
      <span>invalid date</span>
    );
  }
}
