import { ConstantType } from '../../types/_common';
import { EmployeeOccupationEnum, OccupationConstant } from './types';

const OCCUPATIONS: ConstantType<OccupationConstant> = {
  [EmployeeOccupationEnum.FullstackEngineer]: { name: 'Fullstack Engineer' },
  [EmployeeOccupationEnum.BackendEngineer]: { name: 'Backend Engineer' },
  [EmployeeOccupationEnum.FrontendEngineer]: { name: 'Frontend Engineer' },
  [EmployeeOccupationEnum.MobileEngineer]: { name: 'Mobile Engineer' },
  [EmployeeOccupationEnum.QualityAssuranceEngineer]: { name: 'QA Engineer' },
  [EmployeeOccupationEnum.DevOpsEngineer]: { name: 'DevOps Engineer' },
  [EmployeeOccupationEnum.UXEngineer]: { name: 'UX Engineer' },
  [EmployeeOccupationEnum.InformationSecurityEngineer]: { name: 'Information Security Engineer' },
  [EmployeeOccupationEnum.OfficeManager]: { name: 'Office Manager' },
  [EmployeeOccupationEnum.SalesSpecialist]: { name: 'Sales Specialist' },
  [EmployeeOccupationEnum.MachineLearningEngineer]: { name: 'Machine Learning Engineer' },
  [EmployeeOccupationEnum.AutomationQualityAssuranceEngineer]: { name: 'Automation QA Engineer' },
  [EmployeeOccupationEnum.EmbeddedEngineer]: { name: 'Embedded Engineer' },
  [EmployeeOccupationEnum.BusinessAnalyst]: { name: 'Business Analyst' },
  [EmployeeOccupationEnum.SystemAdministrator]: { name: 'System Administrator' },
  [EmployeeOccupationEnum.NetworkEngineer]: { name: 'Network Engineer' },
};

export default OCCUPATIONS;
