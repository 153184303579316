import React from 'react';

import s from './styles.module.sass';
import EntitiesSettings from './EntitiesSettings/EntitiesSettings';
import EntitiesTable from './EntitiesTable/EntitiesTable';

interface EntitiesProps {
}

const Entities: React.FC<EntitiesProps> = () => {
  return (
    <div className={s.entitiesLayout}>
      <EntitiesSettings />
      <EntitiesTable />
    </div>
  );
};

export default Entities;
