import _ from 'lodash';

import { UserStatisticOutput } from '../../../../../../../api/User/types';

export const updateSelectedRowsByStatus = (selectedRows: UserStatisticOutput[], record: UserStatisticOutput) => _.reduce(selectedRows, (acc, value) => {
  if (value.userId === record.userId) {
    return acc;
  }

  return [
    ...acc,
    value,
  ];
}, [] as UserStatisticOutput[]);
