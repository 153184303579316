import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { getTimeByDateAndCellIndex } from 'components/TimeSheet/helpers/helpers';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { getMinMax } from 'components/TimeSheet/components/Table/components/TableBody/components/Column/helpers/helpers';

import {
  BCMonth,
  GetUserHoursProps,
  HoursDataToAdd,
  HoursDataToDelete,
  ReplaceTimesheetBody,
  UserHourComment,
  UserHours,
  UsersBCMonth,
  UsersHour,
  UsersHourResponse,
} from './types';
import { api } from '../../index';
import { getTimeRangeStringsByCellIndexes } from './helpers';

const USER_HOURS_LIMIT = 500; // limit for get user hours items limit

export const getCalendarByDate = (date: string) => api.get<BCMonth>('/calendar/months/joints/', {
  non_cut_day: date,
})
  .then(month => month)
  .catch((error: AxiosError) => {
    throw error;
  });

export const getCalendarByMonthAndYear = ({ month, year }: { month: number, year: number }) => {
  const errorObject = {
    404: {
      hidden: true,
    },
  };

  return api.get<BCMonth>(`/calendar/${year}/${month}/joints/`, {}, { errorObject })
    .then(month => month)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const getUserTimeSheetByDate = ({ userId, date }: { userId: number, date: string }) => api.get<UserHours>(`/users/${userId}/timesheets/`, {
  offset: 0,
  limit: 100,
  order_by: 'start_time',
  start_time_gte: `${date} 00:00:00`,
  end_time_lte: `${dayjs(date).add(1, 'day').format(DEFAULT_DATE_FORMAT)} 00:00:00`,
})
  .then(res => res.objects);

export const getUsersBusinessCalendar = ({ userId, month, year }: {
  userId: number,
  year: number,
  month: number,
}) => api.get<UsersBCMonth>(`/users/${userId}/calendar/${year}/${month}/`, {}, {
  errorObject: { 404: { description: () => 'Sorry, reported hours can\'t be displayed as business calendar is not available for chosen period.' } },
});

export const getUsersBusinessCalendarByDate = ({ userId, date }: { userId: number, date: string }) => api.get<UsersBCMonth>(`/users/${userId}/calendar/months/`, {
  day: date,
});

export const getUserHours = (requestProps: GetUserHoursProps, userId: number) => api.get<UserHours>(`/users/${userId}/timesheets/`, {
  offset: requestProps.offset,
  limit: requestProps.limit,
  start_time_gte: `${requestProps.startDate} 00:00:00`,
  end_time_lte: `${dayjs(requestProps.endDate).add(1, 'day').format(DEFAULT_DATE_FORMAT)} 00:00:00`,

  ...(requestProps.orderBy ? { ordered_by: requestProps.orderBy } : {}),
  ...(requestProps.orderDirection ? { order_direction: requestProps.orderDirection } : {}),
});

export const getCuratorUserHours = (requestProps: GetUserHoursProps, userId: number) => {
  const url = `/users/${userId}/timesheets/`;

  return api.get<UserHours>(url, {
    offset: requestProps.offset,
    limit: requestProps.limit,
    start_time_gte: `${requestProps.startDate} 00:00:00`,
    end_time_lte: `${dayjs(requestProps.endDate)
      .add(1, 'day')
      .format(DEFAULT_DATE_FORMAT)} 00:00:00`,

    ...(requestProps.orderBy ? { ordered_by: requestProps.orderBy } : {}),
    ...(requestProps.orderDirection ? { order_direction: requestProps.orderDirection } : {}),
  })
    .then(res => res)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const addUserHours = (userId: number, hoursData: HoursDataToAdd) => (
  api.post<UsersHourResponse[]>(`/users/${userId}/timesheets/`, {
    ...(hoursData.comment ? { comment: hoursData.comment } : {}),
    activity_id: hoursData.activityId,
    ...getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex, true),
  }, {
    errorObject: {
      409: {
        hidden: false,
        description: () => '409: Please, refresh Timetracker and try again',
      },
    },
  })
);

export const replaceAndFillUserHours = (userId: number, hoursData: HoursDataToAdd) => (
  api.put<UsersHourResponse[]>(`/users/${userId}/timesheets/`, {
    ...(hoursData.comment ? { comment: hoursData.comment } : {}),
    activity_id: hoursData.activityId,
    ...getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex, true),
  }, {
    errorObject: {
      409: {
        hidden: false,
        description: () => '409: Please, refresh Timetracker and try again',
      },
    },
  })
);

export const replaceUserHours = (userId: number, hoursData: HoursDataToAdd) => (
  api.patch<UsersHourResponse[]>(`/users/${userId}/timesheets/`, {
    activity_id: hoursData.activityId,
    ...getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex, true),
  })
);

export const deleteUserHours = (userId: number, hoursData: HoursDataToDelete) => (
  api.delete(`/users/${userId}/timesheets/`, getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex, true)) 
);

export const addUserHourComment = (userId: number, hoursData: HoursDataToDelete, comment: string) => (
  api.put<UserHourComment>(`/users/${userId}/timesheets/comments/`, {
    ...getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex),
    comment,
  })
);

export const deleteUserHourComment = (userId: number, hoursData: HoursDataToDelete) => (
  api.delete(`/users/${userId}/timesheets/comments/`, getTimeRangeStringsByCellIndexes(hoursData.day, hoursData.startCellIndex, hoursData.endCellIndex))
);

export const getAllUserHours: any = (userId: number, timeRange: { startDate: string, endDate: string }, offset = 0, alreadyLoadedItems: UsersHour[] = []) => {
  const requestProps: GetUserHoursProps = { ...timeRange, limit: USER_HOURS_LIMIT, offset, orderBy: 'start_time' };

  return getUserHours(requestProps, userId)
    .then(async (res) => {
      const isAllItemsLoaded = (res.meta.totalCount || 0) <= USER_HOURS_LIMIT + offset;
      const updatedItemList: UsersHour[] = [ ...alreadyLoadedItems, ...res.objects ];

      if (isAllItemsLoaded) {
        return updatedItemList;
      } else {
        return getAllUserHours(userId, timeRange, offset + USER_HOURS_LIMIT, updatedItemList);
      }
    });
};

export const getCuratorAllUserHours: any = (
  userId: number,
  timeRange: { startDate: string, endDate: string },
  offset = 0,
  alreadyLoadedItems: UsersHour[] = [],
) => {
  const requestProps: GetUserHoursProps = { ...timeRange, limit: USER_HOURS_LIMIT, offset };

  return getCuratorUserHours(requestProps, userId)
    .then(async (res) => {
      const isAllItemsLoaded = (res.meta.totalCount || 0) <= USER_HOURS_LIMIT + offset;
      const updatedItemList: UsersHour[] = [ ...alreadyLoadedItems, ...res.objects ];

      if (isAllItemsLoaded) {
        return updatedItemList;
      } else {
        return getCuratorAllUserHours(userId, timeRange, offset + USER_HOURS_LIMIT, updatedItemList);
      }
    });
};

/**
 Allows updating timesheets activity of a given user in scopes of a given month
 */
export const replaceTimesheetActivity = (body: ReplaceTimesheetBody) => {
  const { activityId, newActivityId, userId, selectedDateObj } = body;
  const year = selectedDateObj.year();
  const month = selectedDateObj.month() + 1;

  return api.patch(`/users/${userId}/timesheets/${year}/${month}/`, {
    activity_id: activityId,
    new_activity_id: newActivityId,
  });
};
