import _ from 'lodash';

import { TTLocalStorage } from './initiateLocalStorage';

export const deleteFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const setLocalStorage = (key: string, value: unknown): void => {
  if (!localStorage) {
    return;
  }

  localStorage.setItem(key, _.isString(value) ? value : JSON.stringify(value));
};

export const modifyLocalStorage = (key: string, value: any): void => {
  if (!localStorage) {
    return undefined;
  }

  const keyValue = localStorage[key] ? JSON.parse(localStorage[key]) : {};
  const newKeyValue = { ...keyValue, ...value };

  localStorage.setItem(key, JSON.stringify(newKeyValue));
};

export const getFromLocalStorage = (key: keyof TTLocalStorage, defaultValue?: any) => {
  if (!localStorage) {
    return {};
  }

  if (!localStorage[key]) {
    return defaultValue;
  }

  return JSON.parse(localStorage[key]);
};

export const getStringFromLocalStorage = (key: keyof TTLocalStorage, defaultValue?: any) => {
  if (!localStorage) {
    return {};
  }

  if (!localStorage[key]) {
    return defaultValue;
  }

  return localStorage[key];
};

export const getNumberFromLocalStorage = (key: string): number | undefined => {
  if (!localStorage || !localStorage[key]) {
    return undefined;
  }

  return Number(localStorage[key]);
};
