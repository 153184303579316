import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as ScheduleIcon } from './helpers/scheduleIcon.svg';
import { ReactComponent as SunIcon } from './helpers/sunIcon.svg';
import S from './helpers/styles.module.sass';
import { InfoPanelProps } from './helpers/types';

const InfoPanel: React.FC<InfoPanelProps> = (props) => {
  const holidaysHours = _.filter(props.days, day => day.status === 'HOLIDAY').length * 8;
  const workedHours = _.filter(props.days, day => day.status === 'REGULAR').length * 8;

  return (
    <div className={cx(S.infoPanel)}>
      <div className={cx(S.infoPanelPart, S.total)}>
        <ScheduleIcon className={cx(S.infoPanelIcon)} />
        <p className={cx(S.infoPanelLabel)}>
          Hours in current period: 
          {' '}
          <span className={cx(S.resultLabel)}>
            {props.isLoading ? (<LoadingOutlined className={S.spinner} spin />) : workedHours}
            h
          </span>
        </p>
      </div>
      <div className={cx(S.infoPanelPart, S.holidays)}>
        <SunIcon className={cx(S.infoPanelIcon)} />
        <p className={cx(S.infoPanelLabel)}>
          Holidays: 
          {' '}
          <span className={cx(S.resultLabel)}>
            {props.isLoading ? (<LoadingOutlined className={S.spinner} spin />) : holidaysHours}
            h
          </span>
        </p>
      </div>
    </div>
  );
};

export default InfoPanel;
