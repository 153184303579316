import { Button } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as CollapseIcon } from 'helpers/icons/Buttons/collapseIcon.svg';

import s from './helpers/style.module.sass';
import { setLocalStorage } from '../../../../../../helpers/utils/localStorageService';
import { COLLAPSED_SIDEBAR } from '../../../../../../helpers/constants/_common/localStorageConstants';

interface SidebarCollapseButtonProps {
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
}

const SidebarCollapseButton: React.FC<SidebarCollapseButtonProps> = ({ setCollapsed, collapsed }) => {
  const toggleCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
    setLocalStorage(COLLAPSED_SIDEBAR, collapsed);
  };

  return (
    <Button
      type="text"
      className={cx(s.sidebarButton, { [s.collapsed]: collapsed })}
      onClick={() => {
        toggleCollapse(!collapsed);
      }}
    >
      <CollapseIcon />
    </Button>
  );
};

export default SidebarCollapseButton;
