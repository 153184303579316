import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Button, Popover } from 'antd';

import PROFILE_TABS from 'helpers/constants/_common/tabs/profile';
import { getUnreadFields } from 'redux/Notification/actions';
import useInterval from 'api/hooks/useInterval/useInterval';
import { PATH_PROFILE, PATH_TRACKER } from 'helpers/constants/_common/MenuCases';

import { ReactComponent as InfoIcon } from './helpers/info-icon.svg';
import s from './helpers/style.module.sass';
import { DispatchType, Redux } from '../../helpers/types/_common';

interface UserNotificationProps {
  collapsed?: boolean;
  topPosition?: number;
  leftPosition?: number;
}

const UserNotification: React.FC<UserNotificationProps> = ({ collapsed, topPosition, leftPosition }) => {
  const dispatch: DispatchType = useDispatch();
  const { skillsetIsOutdated, loading, hasWeeklyOvertime } = useSelector((state: Redux) => state.notifications);
  const { userId } = useSelector((state: Redux) => state.login);
  const headerText: string = hasWeeklyOvertime ? 'Weekly overtime warning' : 'Please update your skillset';
  const notificationText: string = hasWeeklyOvertime ? 'Please note that monthly overtimes can be limited' : 'You haven’t updated your working experience&nbsp;for a long time';

  useEffect(() => {
    if (!userId) {
      return;
    }

    dispatch(getUnreadFields(userId));
  }, [ userId ]);

  useInterval(() => {
    if (!userId) {
      return;
    }

    dispatch(getUnreadFields(userId));
  }, 21600);

  const renderInformationContent = (collapsedMenu?: boolean) => (
    <div className={cx(s.userNotificationLayout, { [s.collapsed]: collapsedMenu })}>
      <Link to={hasWeeklyOvertime ? PATH_TRACKER : `${PATH_PROFILE}/${PROFILE_TABS.skillset.path}`} className={s.userNotificationLink}>
        <div className={s.userNotification}>
          <div className={s.userNotificationSide}>
            <InfoIcon className={s.userNotificationSideLogo} />
          </div>
          <div className={s.userNotificationWrapper}>
            <div className={s.linkDescriptionWrapper}>
              <h5 className={s.linkLabel}>{headerText}</h5>
              <p className={s.linkDescription}>{notificationText}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );

  const renderPopoverNotificationBar = () => {
    if (!skillsetIsOutdated || !hasWeeklyOvertime || loading) {
      return null;
    }

    return (
      <Popover
        content={renderInformationContent()}
        overlayClassName={s.notificationOverlay}
        trigger="click"
        placement="right"
      >
        <Button className={cx(s.infoButton, { [s.collapsedInfoButton]: !collapsed })} title="Notifications" style={{ top: topPosition, left: leftPosition }}>
          <InfoIcon className={s.collapsedInfoIcon} />
        </Button>
      </Popover>
    );
  };

  const renderNotificationBar = () => {
    if (!skillsetIsOutdated || loading) {
      return <div className={cx(s.userNotificationLayout, { [s.hidden]: !skillsetIsOutdated || loading })} />;
    }

    return renderInformationContent(collapsed);
  };

  return (
    <>
      {
        collapsed
          ? renderPopoverNotificationBar()
          : renderNotificationBar()
      }
    </>
  );
};

export default UserNotification;
