import * as Actions from '../constants/GlobalSearch';
import Subtypes from '../constants/__common__/Subtypes';

const InitialState = {
  findString: '',
  loading: false,
  data: {},
  message: ''
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CHANGE_FIND_STRING:
      return { ...state, findString: action.payload};
    case Actions.FIND_BY_ID:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, loading: true };
        case Subtypes.Request.Success:
          return { ...state, loading: false, data: action.payload };
        case Subtypes.Request.Error:
          return { ...state, loading: false, message: action.payload };
        default:
          return state;
      }
    case Actions.INITIATE:
      return InitialState;
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
