import React from 'react';
import _ from 'lodash';
import { Space, Tag } from 'antd';

import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross18.svg';
import { BaseUserOutput } from 'api/User/types';
import { BaseEntityOutput } from 'api/Entities/types';
import { PartnerCardOutput } from 'api/Partners/types';

import s from '../../styles.module.sass';
import EmployeeListItem from '../../../_shared/EmployeeListItem/EmployeeListItem';
import { FilteredItemsState } from '../../types';

interface GeneralSearchSelectedEmployeesProps {
  filteredItems: FilteredItemsState;
  onClick: (entity: BaseEntityOutput | PartnerCardOutput | BaseUserOutput | null, searchItemId: number, fieldName: keyof FilteredItemsState) => void;
}

const GeneralSearchSelectedEmployees: React.FC<GeneralSearchSelectedEmployeesProps> = ({ filteredItems, onClick }) => {
  if (_.isEmpty(filteredItems.employees)) {
    return null;
  }

  return (
    <Space className={s.filteredBlock} direction="vertical" size={0}>
      <span className={s.filteredBlockTitle}>Employees</span>
      <Space wrap size={4}>
        {
          _.map(filteredItems.employees, (employee, searchItemsId) => (
            <Tag
              className={s.searchTagItem}
              key={employee.userId}
              closable
              closeIcon={<CloseIcon />}
              onClick={() => onClick(employee, Number(searchItemsId), 'employees')}
              onClose={() => onClick(employee, Number(searchItemsId), 'employees')}
            >
              <EmployeeListItem
                className={s.employeeTagItemLayout}
                key={employee.userId}
                employee={employee}
              />
            </Tag>
          ))
        }
      </Space>
    </Space>
  );
};

export default GeneralSearchSelectedEmployees;
