import React from 'react';
import { Checkbox, Radio, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { CalendarType } from 'components/BusinessCalendar/helpers/types';
import radio from 'helpers/styles/components/radio.module.sass';
import { CALENDAR_TYPE } from 'components/BusinessCalendar/helpers/constants';
import { ReactComponent as ViewComfyIcon } from 'helpers/icons/view-comfy.svg';
import { ReactComponent as ViewCarouselIcon } from 'helpers/icons/view-carousel.svg';

import S from './helpers/styles.module.sass';
import YearSelector from './components/YearSelector/YearSelector';
import MonthList from './components/MonthList/MonthList';

export interface MonthsSelectorPanelProps {
  selectedMonthIndex: number;
  changingMode: 'amount' | 'status';
  bCIsLoading: boolean;
  calendarType: CalendarType;
  setCalendarType: React.Dispatch<React.SetStateAction<CalendarType>>;
  setChangingMode: (mode: 'amount' | 'status') => void;
  onChangeMonthIndex: (newMonthIndex: number) => void;
}

const MonthsSelectorPanel: React.FC<MonthsSelectorPanelProps> = (props) => {
  const {
    bCIsLoading, selectedMonthIndex, onChangeMonthIndex,
    calendarType, setCalendarType, changingMode,
    setChangingMode,
  } = props;

  const handleSetCalendarType = (e: RadioChangeEvent) => {
    setCalendarType(e.target.value);
  };

  const renderMonthSwitcher = () => {
    if (calendarType === CALENDAR_TYPE.grid) {
      return null;
    }

    if (calendarType === CALENDAR_TYPE.new) {
      return (
        <div className={S.modeSwitcher} style={{ marginLeft: 0 }}>
          <Checkbox checked={changingMode === 'amount'} onChange={(e: CheckboxChangeEvent) => setChangingMode(e.target.checked ? 'amount' : 'status')}>Amount change mode</Checkbox>
          <Checkbox checked={changingMode === 'status'} onChange={(e: CheckboxChangeEvent) => setChangingMode(e.target.checked ? 'status' : 'amount')}>Day status change mode</Checkbox>
        </div>
      );
    }

    return (
      <MonthList
        selectedMonthIndex={selectedMonthIndex}
        onChangeMonthIndex={onChangeMonthIndex}
      />
    );
  };

  return (
    <div className={classNames(S.monthsSelectorPanel, { [S.sticky]: calendarType === CALENDAR_TYPE.new })}>
      <div className={S.firstRow}>

        <Space align="center">
          <YearSelector bCIsLoading={bCIsLoading} />

          { renderMonthSwitcher() }
        </Space>

        <div className={S.modeWrapper}>
          <div className={S.modeIconBlock}>
            <Radio.Group
              name="calendar-type"
              className={radio.solidRadioGroup}
              value={calendarType}
              onChange={handleSetCalendarType}
            >
              {/* <Radio.Button
                value={CALENDAR_TYPE.new}
                className={classNames(radio.solidRadioItem, S.radio)}
              >
                <FontAwesomeIcon icon="calendar" className={classNames(S.radioIcon)} />
              </Radio.Button> */}
              <Radio.Button
                value={CALENDAR_TYPE.row}
                className={classNames(radio.solidRadioItem, S.radio)}
              >
                <ViewComfyIcon className={classNames(S.radioIcon, radio.solidRadioIcon)} />
              </Radio.Button>
              <Radio.Button
                value={CALENDAR_TYPE.grid}
                className={classNames(radio.solidRadioItem, S.radio)}
              >
                <ViewCarouselIcon className={classNames(S.radioIcon, radio.solidRadioIcon)} />
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthsSelectorPanel;
