import _ from 'lodash';

import {
  FindSpecialistsBody,
  AttitudesObjectColumn,
  SpecialistCandidateOutput, FoundSpecialist, BaseRequiredSkillOutput,
} from './types';
import { SKILL_EXPERIENCE_RAW, SKILLS_EXPERIENCE_MAPPER_RAW } from './constants';
import { UserSkill } from '../../helpers/types/Skills';

const MATCHED_BY_COLUMN = 'matchedBy';

const parseMatchedByField = (matchedByArray: UserSkill[]) => _.reduce(matchedByArray, (acc, s) => ({
  ...acc,
  [s.skill.skillId]: {
    attitude: s.attitude,
    experience: s.experience,
  },
}), {} as AttitudesObjectColumn);

export const convertObjectsByMatchedByKey = (objects: FoundSpecialist[]) => _.map(objects, o => _.reduce(o, (acc, value, key) => {
  if (key === MATCHED_BY_COLUMN && _.isArray(value)) {
    const expColumnData = parseMatchedByField(value);

    return {
      ...acc,
      [_.camelCase(key)]: value,
      ...expColumnData,
    };
  }

  return {
    ...acc,
    [_.camelCase(key)]: value,
  };
}, {}));

export const convertRequestDetailsBody = (body: object) => _.reduce(body, (acc, value, key) => {
  if (key === 'orderBy') {
    return {
      ...acc,
      ordering_skill_id: value,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});

export const convertFindSpecialistsBody = (body: FindSpecialistsBody) => _.reduce(body, (acc, value, key) => {
  if (_.isUndefined(value) || (_.isString(value) && _.isEmpty(value))) {
    return acc;
  }

  if (key === 'orderBy') {
    return {
      ...acc,
      ordering_skill_id: value,
    };
  }

  if (SKILL_EXPERIENCE_RAW[key]) {
    return {
      ...acc,
      [SKILL_EXPERIENCE_RAW[key]]: value,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});

export const convertCandidatesByMatchedByKey = (object: SpecialistCandidateOutput[]) => _.map(object, (row) => {
  const convertObject = (obj: object): any => _.reduce(obj, (acc, value, key) => {
    if (key === MATCHED_BY_COLUMN && _.isArray(value)) {
      const expColumnData = parseMatchedByField(value);

      return {
        ...acc,
        [key]: value,
        ...expColumnData,
      };
    }

    if (_.isObject(value)) {
      return {
        ...acc,
        ...convertObject(value),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});

  return convertObject(row);
});

export const parseRequestSkills = (skills: BaseRequiredSkillOutput[]) => _.reduce(skills, (acc, value) => ({
  ...acc,
  [value.skill.skillId]: {
    experience: SKILLS_EXPERIENCE_MAPPER_RAW[value.experience],
    name: value.skill.name,
  },
}), {});
