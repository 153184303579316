import React from 'react';
import { List } from 'antd';

import { UserPartnerOutput } from 'api/Partners/types';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';

import S from './helpers/styles.module.sass';
import PartnerListItem from '../PartnerListItem/PartnerListItem';
import { ChosenActivityInfo } from '../../../../helpers/types';

interface PartnersListProps {
  partners: UserPartnerOutput[];
  isLoading: boolean;
  handleSave: (activity: ChosenActivityInfo) => void;
}

const PartnersList: React.FC<PartnersListProps> = ({ handleSave, partners, isLoading }) => {
  const renderItem = (partnerObject: UserPartnerOutput) => (
    <PartnerListItem
      partnerObject={partnerObject}
      handleSave={handleSave}
    />
  );

  return (
    <div className={S.partnersList}>
      <h4 className={S.partnersListTitle}>Partners</h4>
      <div className={S.partnersListInner}>
        { isLoading && (<div className={S.loadingWrapper}><SpinnerNew /></div>)}
        <List
          itemLayout="horizontal"
          dataSource={partners}
          renderItem={renderItem}
          locale={{ emptyText: <div /> }}
        />
      </div>
    </div>
  );
};

export default PartnersList;
