import React from 'react';

import S from './styles.module.sass';

interface DeactivatedProps {
  isHidden: boolean;
}

const Deactivated: React.FC<DeactivatedProps> = ({ isHidden }) => (
  !isHidden ? (
    <div className={S.deactivatedLabel}>Deactivated</div>
  ) : null
);

export default Deactivated;
