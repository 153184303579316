import React, { useState } from 'react';
import { Button, Form, InputNumber, Popover, Space, Tooltip } from 'antd';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import popover from 'helpers/styles/components/popover.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import tooltips from 'helpers/styles/components/tooltips.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';

import s from './styles.module.sass';
import { SalaryObject } from './types';

interface SalaryFieldProps {
  salaryObject: SalaryObject;
  onChange: (salaryObject: SalaryObject) => void;
  className?: string;
  totalHours: number;
}

const SalaryField: React.FC<SalaryFieldProps> = ({ salaryObject, onChange, className, totalHours }) => {
  const [ form ] = Form.useForm();

  const [ isVisible, setIsVisible ] = useState(false);

  const handleOnApply = (values: SalaryObject) => {
    onChange(_.mapValues(values, value => value || 0));

    setIsVisible(false);
  };

  const popoverContent = () => (
    <Space
      className={s.salaryFieldPopoverLayout}
      direction="vertical"
      size={14}
    >
      <Form
        form={form}
        labelAlign="left"
        name="salaryPopover"
        layout="horizontal"
        colon={false}
        onFinish={handleOnApply}
      >
        <Space>
          <Form.Item
            className={s.salaryFieldFormItem}
            name="hourly"
            label="Hourly rate"
          >
            <InputNumber
              min={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              parser={value => value.replace(/\$\s?|( *)/g, '')}
              placeholder="0.00 ₽/h"
              className={cx(inputs.qsInput, s.salaryFieldInput)}
              size="large"
            />
          </Form.Item>

          <Form.Item
            className={s.salaryFieldFormItem}
            label="Fixed rate"
            name="fixed"
          >
            <InputNumber
              min={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              parser={value => value.replace(/\$\s?|( *)/g, '')}
              placeholder="0.00 ₽"
              className={cx(inputs.qsInput, s.salaryFieldInput)}
              size="large"
            />
          </Form.Item>
        </Space>

        <Form.Item
          className={s.salaryFieldFormItem}
          wrapperCol={{ offset: 16 }}
        >
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon="check-circle" />}
            className={cx(buttons.qsButtonPrimary, s.salaryFieldSubmit)}
            htmlType="submit"
          >
            Apply
          </Button>
        </Form.Item>
      </Form>

    </Space>
  );

  const calculatedSalary = () => {
    if (!_.sum([ salaryObject.hourly, salaryObject.fixed ])) {
      return '0.00 ₽';
    }

    const sum = _.round(_.sum([ salaryObject.hourly * totalHours, salaryObject.fixed ]), 2);

    return `${_.replace(String(sum), /\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;
  };

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      const formValues: SalaryObject = form.getFieldsValue();
      onChange(_.mapValues(formValues, value => value || 0));
    }

    setIsVisible(visible);
  };

  return (
    <Popover
      align={{ offset: [ 0, 0 ] }}
      content={popoverContent()}
      placement="topRight"
      trigger="click"
      overlayClassName={cx(popover.dotsPopoverOverlay, popover.popoverNoArrow, s.salaryFieldPopover)}
      visible={isVisible}
      onVisibleChange={onVisibleChange}
    >
      <Tooltip
        title="Set your rate"
        overlayClassName={tooltips.trackerTooltip}
        placement="right"
      >
        <div className={cx(s.salaryField, className, { [s.salaryFieldOpened]: isVisible })}>
          { calculatedSalary() }
        </div>
      </Tooltip>
    </Popover>
  );
};

export default SalaryField;
