import _ from 'lodash';

import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import { EntityPage, GetEntityBody, NewEntityBody, UpdateEntityBody } from './types';
import { api } from '../../index';

export const getEntities = async (body?: GetEntityBody) => {
  const response = await api.get<EntityPage>('/entities/', convertObjectKeysToLanguageCase(body, 'snakeCase'));

  return convertObjectKeysToLanguageCase(response);
};

export const createNewEntities = (body: NewEntityBody) => api.post<EntityPage>('/entities/', body);

export const updateEntity = (body: UpdateEntityBody) => api.patch<EntityPage>(`/entities/${body.entityId}/`, _.omit(body, 'entityId'));
