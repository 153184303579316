import { Input, List, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import inputs from 'helpers/styles/components/inputs.module.sass';
import { getAllUsers } from 'api/User/requests';
import { AllUsersRequest, UserCardOutput } from 'api/User/types';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES } from 'helpers/constants/_common/MenuCases';
import { DispatchType, Redux } from 'helpers/types/_common';
import { setTimeTrackerActivity } from 'redux/TimeSheet/action';

import s from './styles.module.sass';
import TrackerLogo from '../../../_shared/TrackerLogo/TrackerLogo';

interface EditProfileEmployeesListProps {
  visible?: boolean;
  alwaysVisible?: boolean;
  styleClasses?: { wrapper?: string, item?: string };
  setVisible?: (visible: boolean) => void;
}

const EditProfileEmployeesList: React.FC<EditProfileEmployeesListProps> = ({ visible, setVisible, alwaysVisible, styleClasses }) => {
  const inputRef = useRef<Input>(null);
  const { tab } = useParams<{ tab: string }>();

  const history = useHistory();
  const dispatch: DispatchType = useDispatch();

  const [ users, setUsers ] = useState<AllUsersRequest>();
  const [ loading, setLoading ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>();

  const { selectedActivity } = useSelector((state: Redux) => state.timeSheet);

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    if (visible) {
      inputRef?.current?.focus();
    }
  }, [ visible ]);

  const handleGetUsers = () => {
    if (users && (users?.objects?.length >= (users?.meta?.totalCount || 0))) {
      return;
    }

    setLoading(true);

    getAllUsers({
      orderBy: 'forename',
      active: true,
      fullnameContains: searchValue,
      usernameContains: searchValue,
      emailAddressContains: searchValue,
      limit: users?.meta?.limit || 40,
      offset: (users && ((users?.meta?.offset || 0) >= 0)) ? ((users?.meta?.offset || 0) + 40) : 0,
    })
      .then((res: AllUsersRequest) => {
        setUsers(prevUserState => ({
          meta: res.meta,
          objects: prevUserState ? [ ...prevUserState.objects, ...res.objects ] : [ ...res.objects ],
        }));
      })
      .finally(() => setLoading(false));
  };

  const handleUpdate = (values: positionValues) => {
    const { top } = values;
    if (top > 0.8) {
      if (!loading) {
        handleGetUsers();
      }
    }
  };

  const handleOnSearch = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    setSearchValue(event.target.value);

    getAllUsers({
      orderBy: 'forename',
      fullnameContains: event.target.value,
      usernameContains: event.target.value,
      emailAddressContains: event.target.value,
      active: true,
      limit: 40,
      offset: 0,
    })
      .then(res => setUsers(res))
      .finally(() => setLoading(false));
  }, 500);

  const onListItemClick = (user: UserCardOutput) => {
    history.push(`${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${user.userId}/timesheet`);

    if (selectedActivity) {
      dispatch(setTimeTrackerActivity(undefined));
    }

    setVisible?.(false);
  };

  const renderListItem = (user: UserCardOutput) => (
    <List.Item
      className={s.userListItem}
      key={user.userId}
      onClick={() => onListItemClick(user)}
    >
      {
        user?.avatarUrl
          ? <img className={s.userAvatar} src={user?.avatarUrl} alt="avatar" />
          : <TrackerLogo className={s.companyAvatar} />
      }

      <span
        className={cx(s.userListName, styleClasses?.item)}
        title={`${user.forename} ${user.surname}`}
      >
        {`${user.forename} ${user.surname}`}
      </span>
    </List.Item>
  );

  return (
    <div className={cx(s.profileListLayout, styleClasses?.wrapper, { [s.profileListLayoutVisible]: (visible || alwaysVisible) })}>
      <div className={s.searchEmployeeWrapper}>
        <Input
          ref={inputRef}
          className={cx(inputs.qsInput, s.searchEmployeeInput, { [s.filledInput]: !_.isEmpty(searchValue) })}
          prefix={<SearchIcon />}
          size="large"
          allowClear
          autoFocus
          onChange={e => handleOnSearch(e)}
          placeholder="Search employees..."
        />
      </div>

      <div className={s.profileListWrapper}>
        <Scrollbars onUpdate={handleUpdate} autoHide>
          <List
            className={s.userList}
            dataSource={users?.objects}
            renderItem={renderListItem}
          />
        </Scrollbars>
        {loading && <Spin className={s.employeeListSpinner} size="small" />}
      </div>
    </div>
  );
};

export default EditProfileEmployeesList;
