import * as Entities from 'api/Entities/requests';
import { DispatchType } from 'helpers/types/_common';
import * as TablesActions from 'helpers/constants/_common/actions/Tables';
import Subtypes from 'V1/constants/__common__/Subtypes';
import { GetEntityBody } from 'api/Entities/types';

export const getEntities = (body: GetEntityBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return Entities.getEntities(body)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};
