import React from 'react';

import { SkillCategoryEnum, SkillExperienceEnum, UserSkill } from 'helpers/types/Skills';
import { SkillSetReducerInterface } from 'redux/SkillSet/types';
import { UserRedux } from 'redux/User/types';
import { UserDetails } from 'api/User/types';

export interface SkillSetProps {
  skillset: SkillSetReducerInterface;
  skillSetActions: any;
  loginActions: any;
  user: UserDetails | UserRedux;
  setUserData?: React.Dispatch<UserDetails>
  editByAdmin?: boolean;
  curatorOnlyView?: boolean;
}

export interface SkillSetState {
  addedCategories: SkillCategoryEnum[];
  categoryToEdit: SkillCategoryEnum | null;
  userSkills: UserSkill[],
  reservedUsersSkills: UserSkill[],
}

export type SkillSetExperiences = {
  [key in SkillExperienceEnum]: {
    [showingType in SkillShowingEnum]: {
      columnId: string,
      ids: string[]
    }
  }
};

export enum SkillShowingEnum {
  inUse = 'inUse',
  notInUse = 'notInUse',
}
