import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Col, Popconfirm, Row, Space, message, Button, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import SKILL_CATEGORIES from 'helpers/constants/Skills/Categories';
import buttons from 'helpers/styles/components/buttons.module.sass';

import { SkillCategoryEnum, SkillExperienceEnum } from '../../../../../../helpers/types/Skills';
import { SkillShowingEnum } from '../../helpers/types';
import SKILL_EXPERIENCES from '../../../../../../helpers/constants/Skills/Experience';
import SkillSetExperience from './components/SkillSetExperience';
import * as skillSetActions from '../../../../../../redux/SkillSet/action';
import { SkillSetCategoryProps } from './helpers/types';
import S from '../../helpers/styles.module.sass';
import { DispatchType } from '../../../../../../helpers/types/_common';
import SkillDescription from '../SkillDescription/SkillDescription';

const cx = classNames.bind(S);

const SkillSetCategory: React.FC<SkillSetCategoryProps> = (props) => {
  const {
    skillCategory, userSkills, userSkillsAll, editedCategory, userSkillsIsChanged,
    restoreUserSkills, updateUserSkills, removeUserSkill, addUserSkill,
    changeEditedCategory, onCancelCallback, addedCategories, loading, user, isReadOnlyMode,
  } = props;

  const dispatch: DispatchType = useDispatch();

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isCategoryEditingNow, setIsCategoryEditingNow ] = useState(false);

  const addedSkillToCategory = _.filter(userSkillsAll, s => s.skill.category === skillCategory.key);

  useEffect(() => {
    if (editedCategory === skillCategory.key) {
      setIsCategoryEditingNow(true);
    } else {
      setIsCategoryEditingNow(false);
    }
  }, [ editedCategory ]);

  const filteredByCategorySkills = userSkills.filter(u => u.skill.category === skillCategory.key);

  const editCategoryState = () => {
    changeEditedCategory(skillCategory.key);
    setIsCategoryEditingNow(true);
    setErrorMessage('');
  };

  const cancelCategoryEditing = () => {
    if (loading) {
      return;
    }

    const onlyNewElementsAdded = _.filter(userSkillsAll, s => !s.userSkillId).length === addedSkillToCategory.length;

    if (_.isEmpty(addedSkillToCategory) || onlyNewElementsAdded) {
      onCancelCallback(skillCategory.key);
    }

    changeEditedCategory(null);
    restoreUserSkills();
    setIsCategoryEditingNow(false);
    setErrorMessage('');
  };

  const saveCategoryEditing = () => {
    if (loading) {
      return;
    }

    if (!userSkillsIsChanged) {
      if (_.isEmpty(addedSkillToCategory)) {
        onCancelCallback(skillCategory.key);
      }

      setIsCategoryEditingNow(false);
      changeEditedCategory(null);
    } else {
      dispatch(skillSetActions.updateUsersSkills(user.userId, userSkills))
        .then(() => {
          setIsCategoryEditingNow(false);
          changeEditedCategory(null);
        })
        .catch((errorObj: any) => {
          if (errorObj?.response?.data?.detail) {
            return;
          }

          const error = errorObj?.response?.data;
          const category = SKILL_CATEGORIES[error?.category as SkillCategoryEnum];
          const errorText = `${error?.error} in "${category?.title}"`;

          message.warning({
            content: (
              <span dangerouslySetInnerHTML={{ __html: errorText.replace(/"([^"]+)"/g, '<b>$1</b>') }} />
            ),
            duration: 5,
          });
        });
    }
  };

  const renderSkillsExperience = () => {
    const skillExperienceKeys = _.reverse([ ...Object.keys(SKILL_EXPERIENCES) as SkillExperienceEnum[] ]);

    return _.map(skillExperienceKeys, skill => (
      <SkillSetExperience
        key={`${skillCategory.key}-${skill}`}
        user={user}
        isCategoryEditingNow={isCategoryEditingNow}
        skillCategoryName={skillCategory.key}
        skillExperienceName={skill}
        skillShowingEnum={SkillShowingEnum.notInUse}
        userSkills={userSkills.filter(userSkill => userSkill.experience === skill)}
        userSkillsAll={userSkillsAll}
        removeUserSkill={removeUserSkill}
        restoreUserSkills={restoreUserSkills}
        updateUserSkills={updateUserSkills}
        addUserSkill={addUserSkill}
        addedCategories={addedCategories}
        isReadOnlyMode={isReadOnlyMode}
      />
    ));
  };

  const renderCancelButton = () => {
    if (!isCategoryEditingNow) {
      return;
    }

    if (!userSkillsIsChanged) {
      return (
        <Button
          className={buttons.qsButton}
          onClick={() => cancelCategoryEditing()}
        >
          Cancel
        </Button>
      );
    }

    return (
      <Popconfirm
        placement="left"
        okText="Discard changes"
        cancelText="Continue editing"
        cancelButtonProps={{
          type: 'text',
        }}
        title="You are about to discard your unsaved changes"
        onConfirm={() => cancelCategoryEditing()}
      >
        <Button
          className={buttons.qsButton}
        >
          Cancel
        </Button>
      </Popconfirm>
    );
  };

  const renderSaveButton = () => {
    if (!isCategoryEditingNow) {
      return null;
    }

    return (
      <Button
        type="primary"
        className={buttons.qsButtonPrimary}
        onClick={() => saveCategoryEditing()}
      >
        <div className={buttons.iconTextWrapper}>
          <FontAwesomeIcon icon="check-circle" />
          Save
        </div>
      </Button>
    );
  };

  const renderEditButton = () => {
    if (isCategoryEditingNow || isReadOnlyMode) {
      return null;
    }

    return (
      <Button
        type="text"
        className={cx('edit-button')}
        onClick={() => editCategoryState()}
      >
        <Space>
          <FontAwesomeIcon icon="cog" />
          Edit skills
        </Space>
      </Button>
    );
  };

  const renderCategory = () => {
    const { userSkills } = props;

    return (
      <Row
        className={cx('category-row', { edit: isCategoryEditingNow })}
        wrap={false}
      >
        <Col
          flex="110px"
          className={cx('category-left-col', { edit: isCategoryEditingNow })}
        >
          <div>
            <span className={cx('category-left-col_sum')}>{userSkills.length}</span>
            <span className={cx('category-left-col_desc')}>Skills you have experience in</span>
          </div>
          { renderEditButton() }
        </Col>
        <Col flex="auto">
          <Row className={cx('category-skills-row')}>
            { renderSkillsExperience() }
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div
      key={skillCategory.eventKey}
      className={cx('skillset_category', {
        'skillset_category--is-editing-now': isCategoryEditingNow,
        'skillset_category--is-isolated-now': editedCategory && editedCategory !== skillCategory.key,
        'skillset_category--is-empty': _.isEmpty(filteredByCategorySkills) && !isCategoryEditingNow,
      })}
    >
      <div className={cx('skillset_category-header')}>
        <Space>
          <h5 className={cx('skillset_category-title')}>{skillCategory.title}</h5>
          { skillCategory.description && <SkillDescription category={skillCategory.key} placement="topLeft" hidden={loading} /> }
        </Space>
        <div className={cx('skillset_category-editing-buttons')}>
          { renderCancelButton() }
          { renderSaveButton() }
        </div>
      </div>
      <div className={cx('skillset_category-body')}>
        { renderCategory() }
      </div>
      <div className={cx('skillset_category-footer')}>
        {Boolean(errorMessage) && isCategoryEditingNow && (
          <div className={cx('skillset_category-error')}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillSetCategory;
