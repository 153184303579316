import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import _ from 'lodash';
import { Result } from 'antd';

import { PATH_TRACKER, TAB_TRACKER_TIME } from '../../helpers/constants/_common/MenuCases';
import s from './helpers/styles.module.sass';

const PageDoesNotExist = () => {
  const location = useLocation();
  const locationPath = _.last(_.split(location?.pathname, '/'));

  if (locationPath === TAB_TRACKER_TIME) {
    return <Redirect to={PATH_TRACKER} />;
  }

  return (
    <div className={s.pageNotFoundWrapper}>
      <Result
        className={s.pageNotFound}
        status="404"
        title="404"
        subTitle="Oops, the page you visited does not exist."
        extra={<Link to={PATH_TRACKER}>Back Home</Link>}
      />
    </div>
  );
};

export default PageDoesNotExist;
