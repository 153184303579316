import React, { Key, useMemo, useState } from 'react';
import { Space } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import dayjs from 'dayjs';

import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { ReactComponent as ChevronRight } from 'helpers/icons/Buttons/chevronRight.svg';
import {
  ConvertedActivityReport,
  ConvertedPartnerDetailedReport,
  ConvertedUserDetailedReport,
  UserDetailedReports,
} from 'api/Reports/types';
import { ReactComponent as MonthLockedIcon } from 'helpers/icons/lockIcon.svg';
import { ReactComponent as MonthOpenIcon } from 'helpers/icons/lockOpenIcon.svg';
import PartnerMask from 'components/_shared/PartnerMask';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import BasicTable from 'components/_shared/Table';
import { renderTimesheetUpdatedDays } from 'components/_shared/OverdueBadge/helpers/helpers';
import { Meta, Sorter } from 'api/_request/types';
import Deactivated from 'components/_shared/Deactivated/Deactivated';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import USER_HOUR_REPORTS_COLUMNS from './helpers/constants';
import { BaseUserOutputChecked, UserHourReportsSettingsState } from '../../types';
import s from './styles.module.sass';
import EmployeeDrawer from '../../../Drawers/EmployeeDrawer';
import { EmployeeDrawerData } from '../../../Drawers/EmployeeDrawer/helpers/types';
import ThreeDotsMenu from './ThreeDotsMenu';

type Record = ConvertedActivityReport | ConvertedUserDetailedReport | ConvertedPartnerDetailedReport;

interface UserHourReportsTableProps {
  settingsValues: UserHourReportsSettingsState;
  loading: boolean;
  userHourReportsData: UserDetailedReports;
  getUserHourReports: (pagination: Meta | false, sorter?: Sorter) => void;
  openNotificationModal: (reports: BaseUserOutputChecked[]) => void;
}

const isUserRecord = (record: Record) => (
  ('user' in record && !('activity' in record) && !('partner' in record))
);

const UserHourReportsTable: React.FC<UserHourReportsTableProps> = (props) => {
  const { loading, settingsValues, getUserHourReports, userHourReportsData, openNotificationModal } = props;
  const [ expandedRowKeys, setExpandedRowKeys ] = useState<readonly Key[]>();
  const [ employeeDrawerData, setEmployeeDrawerData ] = useState<EmployeeDrawerData>({ userId: undefined, visible: false });

  const notCurrentPeriodViewing = useMemo(() => {
    const now = dayjs();
    const dayJsObj = dayjs(settingsValues.dates.selectedDate).date(now.date());

    return now.format(DEFAULT_DATE_FORMAT) !== dayJsObj.format(DEFAULT_DATE_FORMAT);
  }, [ settingsValues ]);

  const onCloseDrawer = () => {
    setEmployeeDrawerData({ userId: undefined, visible: false });
  };

  const renderColumns = {
    id: {
      render: (text: string, record: Record) => (
        isUserRecord(record) && record.user.userId
      ),
    },
    fullname: {
      render: (text: undefined, record: Record) => {
        if (isUserRecord(record)) {
          return (
            <Space
              size={0}
              className={s.expandedRowUser}
              onClick={(event) => {
                event.stopPropagation();
                setEmployeeDrawerData({
                  userId: record.user.userId,
                  visible: true,
                });
              }}
            >
              <UserAvatar avatarUrl={record.user.avatarUrl} />
              <span className={s.userTitle}>{`${record.user.forename} ${record.user.surname}`}</span>
            </Space>
          );
        }

        return null;
      },
    },

    partner: {
      render: (text: undefined, record: Record) => {
        if (('children' in record) && !('partner' in record)) {
          if (_.isEmpty(record.children)) {
            return 'No activities reported';
          }

          const partnersTitle = (record?.children?.length > 1) ? 'partners' : 'partner';

          return (
            <div className={s.flexRow}>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, String(record?.user?.userId)) })} />
              <span>{`${record?.children?.length} ${partnersTitle}`}</span>
              <Deactivated isHidden={record.user.active} />
            </div>
          );
        }

        if ('activity' in record) {
          return (
            <div className={cx(s.expandedRowActivity, s.flexRow)}>
              <PartnerMask
                wrapperColor={record?.partner?.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record?.activity?.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.activity.name}</span>
              <Deactivated isHidden={record.activity.active} />
            </div>
          );
        }

        if ('partner' in record) {
          return (
            <div className={cx(s.expandedRowPartner, s.flexRow)}>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, `${record?.user?.userId}-${record?.partner?.partnerId}`) })} />
              <PartnerMask
                wrapperColor={record?.partner?.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record.children[0].activity.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.partner.name}</span>
              <Deactivated isHidden={record.partner.active} />
            </div>
          );
        }
      },
    },

    hours: {
      render: (text: undefined, record: Record) => <span>{`${record?.hours}h`}</span>,
    },

    timesheetLastUpdate: {
      render: (text: string, record: Record) => {
        if (isUserRecord(record)) {
          return renderTimesheetUpdatedDays((record as ConvertedUserDetailedReport).timesheetLastUpdate);
        }

        return null;
      },
    },
    periodIsClosed: {
      render: (text: undefined, record: Record) => {
        if (isUserRecord(record)) {
          return (record as ConvertedUserDetailedReport).periodIsClosed
            ? <MonthLockedIcon className={s.lockedMonthIcon} />
            : <MonthOpenIcon className={s.openedMonthIcon} />;
        }

        return null;
      },
    },
  };

  const expandableSettings = {
    expandRowByClick: true,
    indentSize: 40,
    expandedRowKeys,
    onExpandedRowsChange: (expandedRowsKeys: readonly Key[]) => setExpandedRowKeys(expandedRowsKeys),
    expandIcon: () => null,
  };

  return (
    <div>
      <BasicTable
        className={s.reportHourTable}
        rowKey={(record: Record) => {
          if ('activity' in record) {
            return `${record.user.userId}-${record.activity.activityId}`;
          }

          if ('partner' in record) {
            return `${record.user.userId}-${record.partner.partnerId}`;
          }

          return `${record.user.userId}`;
        }}
        childrenColumnName="children"
        loading={loading}
        expandable={expandableSettings}
        getData={getUserHourReports}
        dataSource={userHourReportsData?.users}
        initialRequest={false}
        renderColumns={renderColumns}
        pagination={false}
        tableName={TABLE_NAMES.USER_HOUR_REPORTS}
        columns={USER_HOUR_REPORTS_COLUMNS}
        rowClassName={(record, index, indent) => {
          const isHoverUserRow: boolean = isUserRecord(record) && !(record as ConvertedUserDetailedReport).periodIsClosed;
          const isDeep = indent === 2;

          return cx({
            [s.hoverUserReportRow]: isHoverUserRow,
            [s.clickableRow]: !isDeep,
          });
        }}
        bordered
        locale={{
          emptyText: 'No hours reported in selected period',
        }}
      />

      <EmployeeDrawer
        drawerData={employeeDrawerData}
        onClose={onCloseDrawer}
      />
    </div>
  );
};

export default UserHourReportsTable;
