import dayjs from 'dayjs';
import _ from 'lodash';

import { BCDay, BCJointStatus, BCMonth, BCStatuses } from 'api/TimeSheet/types';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

/** Returns BCDay[] of calendar month, with correct BCStatus and with null ids. */
export const blankDaysGenerator = (date: string): BCDay[] => {
  const dayjsObj = dayjs(date);

  if (dayjsObj.format() === 'Invalid Date') return [] as BCDay[];

  const daysInMonth = dayjsObj.daysInMonth();

  return _.range(daysInMonth)
    .map((e, i) => {
      const dayjsObjOfCurrentDate = dayjsObj.date(1 + i);
      const currentDay = dayjsObjOfCurrentDate.day();
      const holidaysDays = [ 0, 6 ];

      return {
        businessDayId: null,
        day: dayjsObjOfCurrentDate.format(DEFAULT_DATE_FORMAT),
        status: holidaysDays.includes(currentDay) ? BCStatuses.Holiday : BCStatuses.Regular,
        jointStatus: BCJointStatus.Basic,
      };
    }) as BCDay[];
};

/** Returns BCMonth[] of calendar month, with null Ids, needed for cases when getBusinessCalendar returns 404. */
export const blankBusinessMonthGenerator = (date: string): BCMonth => {
  const fakeDateDayjs = dayjs(date);
  const fakeBCMonth: BCMonth = {
    businessMonthId: null,
    year: fakeDateDayjs.year(),
    month: fakeDateDayjs.month() + 1,
    jointDays: blankDaysGenerator(fakeDateDayjs.format(DEFAULT_DATE_FORMAT)),
    startDay: fakeDateDayjs.date(1).format(DEFAULT_DATE_FORMAT),
    endDay: fakeDateDayjs.date(fakeDateDayjs.daysInMonth()).format(DEFAULT_DATE_FORMAT),
    modified: false,
  };

  return fakeBCMonth;
};
