import React, { useEffect } from 'react';
import { List, Space, Spin } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { setPinnedActivity } from 'api/Partners/requests';
import { UserPinnedActivityOutput } from 'api/Partners/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import { updateUserActivities } from 'redux/TimeSheet/action';
import { UserRedux } from 'redux/User/types';
import { UserDetails } from 'api/User/types';

import s from './helpers/styles.module.sass';
import ActivityListItem from './component/ActivityListItem';

interface ActivitiesListProps {
  isEdit: boolean;
  loading: boolean;
  collapsed: boolean;
  curatorOnlyView?: boolean;
  userData: UserRedux | UserDetails;
  getActivities: () => void;
}

const ActivitiesList: React.FC<ActivitiesListProps> = ({ collapsed, isEdit, userData, getActivities, loading, curatorOnlyView }) => {
  const dispatch: DispatchType = useDispatch();

  const { activities } = useSelector((state: Redux) => state.timeSheet);

  const { userId } = useParams<{ userId?: string }>();

  useEffect(() => () => (dispatch(setPinnedActivity(undefined))), []);

  const handleSetPinnedActivity = (activityObject?: UserPinnedActivityOutput) => {
    if (curatorOnlyView) {
      return;
    }

    return dispatch(setPinnedActivity(activityObject));
  };

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination } = dropResult;

    if (!destination) {
      return null;
    }

    const dragCard = activities[source.index];

    const updatedActivities = update(activities, {
      $splice: [
        [ source.index, 1 ],
        [ destination.index, 0, dragCard ],
      ],
    });

    dispatch(updateUserActivities(updatedActivities));
  };

  const renderItem = (activityObject: UserPinnedActivityOutput, index: number) => (
    <Draggable
      key={activityObject.activity.activityId}
      draggableId={String(activityObject.activity.activityId)}
      index={index}
      isDragDisabled={!isEdit}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ActivityListItem
            userId={(userId && Number(userId)) || userData.userId}
            getActivities={getActivities}
            activityObject={activityObject}
            collapsed={collapsed}
            isEdit={isEdit}
            handleSetPinnedActivity={handleSetPinnedActivity}
            curatorOnlyView={curatorOnlyView}
          />
        </div>
      )}
    </Draggable>
  );

  const emptyActivitiesFooter = () => (
    <Space direction="vertical" className={s.emptyActivitiesFooter} size={0}>
      <span className={s.emptyActivitiesFooterTitle}>No current activities.</span>
      <span className={s.emptyActivitiesFooterDesc}>Select partner’s activity from the list below</span>
    </Space>
  );

  return (
    <Spin spinning={loading} size="small" className={s.activityListSpinner}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="activities" isDropDisabled={!isEdit}>
          { provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={cx(s.activityListWrapper, { [s.collapsedActivitiesList]: collapsed })}
            >
              <List
                className={s.activityList}
                itemLayout="horizontal"
                dataSource={activities}
                renderItem={renderItem}
                locale={{ emptyText: (_.isEmpty(activities) && loading) ? <div /> : emptyActivitiesFooter() }}
              />
              {provided.placeholder}
            </div>
          ) }
        </Droppable>
      </DragDropContext>
    </Spin>
  );
};

export default ActivitiesList;
