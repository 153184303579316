import qs from 'query-string';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginActions from '../../../../../../V1/actions/Login/LoginActions';
import * as logsActions from '../../../../../../V1/actions/LogsActions';
import TopMenu from './TopMenu';
import BottomMenu from './BottomMenu';
import LogsTable from './Table';
import history from '../../../../../../redux/_reducer/configureHistory';
import { TAB_LOGS } from '../../../../../../helpers/constants/_common/MenuCases';
import Layout from '../../../../_shared/Layout';

class Logs extends Component {
  componentDidMount() {
    const { tab } = qs.parse(history.location.search);

    if (tab === TAB_LOGS) {
      this.props.loginActions.changeContent();
    }
    const { startDate, endDate, amountOfRows, sortBy } = this.props.logs;

    this.props.logsActions.getLogs({
      startDate,
      endDate,
      limit: amountOfRows,
      page: 1,
      sortBy
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.logs.startDate !== this.props.logs.startDate ||
      prevProps.logs.endDate !== this.props.logs.endDate) {

      const {startDate, endDate, amountOfRows, sortBy} = this.props.logs;
      this.props.logsActions.getLogs({
        startDate,
        endDate,
        limit: amountOfRows,
        page: 1,
        sortBy
      });
    }
  }

  componentWillUnmount() {
    this.props.logsActions.initiate();
  }

  render() {
    return (
      <Layout.Container responsive>
        <TopMenu
          changeActivePage={this.props.logsActions.changeActivePage}
          changeStartDate={this.props.logsActions.changeStartDate}
          changeEndDate={this.props.logsActions.changeEndDate}
          changeFilter={this.props.logsActions.changeFilter}
          findLogs={this.props.logsActions.findLogs}
          changeAmountOfRows={this.props.logsActions.changeAmountOfRows}
          startDate={this.props.logs.startDate}
          endDate={this.props.logs.endDate}
          amountOfRows={this.props.logs.amountOfRows}
          activePage={this.props.logs.activePage}
          totalAmount={this.props.logs.totalAmount}
          filter={this.props.logs.filter}
          sortBy={this.props.logs.sortBy}
        />
        <Layout.Row>
          <Layout.Col>
            {<LogsTable
              isLoading={this.props.logs.loading}
              logs={this.props.logs}
              logsActions={this.props.logsActions}
            />
            }
          </Layout.Col>
        </Layout.Row>
        <BottomMenu
          changeActivePage={this.props.logsActions.changeActivePage}
          startDate={this.props.logs.startDate}
          endDate={this.props.logs.endDate}
          amountOfRows={this.props.logs.amountOfRows}
          activePage={this.props.logs.activePage}
          totalAmount={this.props.logs.totalAmount}
          sortBy={this.props.logs.sortBy}
          filter={this.props.logs.filter}
        />
      </Layout.Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logs: state.logs,
    user: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logsActions: bindActionCreators(logsActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs)
