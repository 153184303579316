import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import className from 'classnames/bind';
import { Input, List, Spin } from 'antd';
import _ from 'lodash';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';

import { Partner } from 'helpers/types/Partners';
import { GetPartners } from 'api/Specialists/types';
import specialistsApi from 'api/Specialists/requests';
import { deleteFromSessionStorage, getFromSessionStorage } from 'helpers/utils/sessionStorageService';
import history from 'redux/_reducer/configureHistory';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import { REQUEST_SELECTED_PARTNER } from 'helpers/constants/_common/sessionStorageConstants';

import { PartnerListProps, RequestsSettings } from './helpers/types';
import S from './helpers/styles.module.sass';

const cx = className.bind(S);

const RequestsPartnersList: React.FC<PartnerListProps> = ({ onPartnerSelect, activePartner, SRLoading }) => {
  const input = useRef<Input>(null);
  const rememberedPartner = getFromSessionStorage<string>(REQUEST_SELECTED_PARTNER);
  const defaultSettings = {
    pageSize: 20,
    offset: 0,
    nameContains: rememberedPartner || '',
    totalCount: 99,
  };

  const [ getPartnersIsLoading, changeGetPartnersIsLoading ] = useState(false);
  const [ settings, onChangeSettings ] = useState<RequestsSettings>(defaultSettings);
  const [ partners, changePartners ] = useState<Partner[]>([]);
  const [ partnerNameToSearch, changePartnerNameToSearch ] = useState('');

  history.listen((location) => {
    const pageName = location.pathname;
    if (!_.includes(pageName, 'specialists/request') && getFromSessionStorage(REQUEST_SELECTED_PARTNER)) {
      deleteFromSessionStorage(REQUEST_SELECTED_PARTNER);
    }
  });

  useEffect(() => {
    if (!getPartnersIsLoading) {
      fetchPartners(true);
    }
  }, [ settings.nameContains ]);

  useEffect(() => {
    if (rememberedPartner) {
      changePartnerNameToSearch(rememberedPartner);
    }
  }, []);

  const changeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (rememberedPartner) {
      deleteFromSessionStorage(REQUEST_SELECTED_PARTNER);
    }

    changePartnerNameToSearch(e.target.value);
    fetchBySearchedString(e);
  };

  const fetchBySearchedString = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChangeSettings({
      ...settings,
      nameContains: e.target.value,
      offset: 0,
      totalCount: 99, // its because of hasMore checking before fetching current totalCount
    });
  }, 1000);

  const fetchPartners = async (withCleaning = false) => {
    if (!withCleaning && settings.totalCount - partners.length === 0) {
      return;
    }

    changeGetPartnersIsLoading(true);

    specialistsApi.getAllPartners(settings, withCleaning)
      .then((response: GetPartners) => {
        onChangeSettings({
          ...settings,
          ...(withCleaning ? { offset: 0 } : { offset: settings.offset + settings.pageSize }),
          totalCount: response.meta.totalCount || 0, // need to store current total count
        });

        const updatedObjects = [
          ...(withCleaning ? [] : partners),
          ...response.objects,
        ];

        changePartners(updatedObjects as Partner[]);

        if (rememberedPartner && rememberedPartner === settings.nameContains) {
          const partner = _.find(response.objects, (partner: Partner) => partner.name === rememberedPartner);
          if (partner) onPartnerSelect(partner);
        }
      })
      .finally(() => {
        changeGetPartnersIsLoading(false);
      });
  };

  const onRememberedPartnerSelect = (partner: Partner) => {
    onPartnerSelect(partner);
  };

  const handleUpdate = (values: positionValues) => {
    const { top } = values;
    if (top > 0.8) {
      if (!getPartnersIsLoading) {
        fetchPartners();
      }
    }
  };

  return (
    <>
      <div className={cx('requests-tab-partners_title-wrapper')}>
        <Input
          ref={input}
          value={partnerNameToSearch}
          className={cx('requests-tab-partners_searcher')}
          allowClear
          size="large"
          placeholder="Search Partners..."
          onChange={changeSearchInput}
          bordered={false}
          prefix={<SearchIcon className={cx('requests-tab-partners_searcher', { icon: true })} />}
        />
      </div>
      <div
        className={cx('requests-tab-partners')}
      >
        <Scrollbars onUpdate={handleUpdate}>
          <List
            dataSource={partners}
            renderItem={(partner: Partner) => (
              <List.Item
                key={partner.partnerId}
                onClick={() => !SRLoading && onRememberedPartnerSelect(partner)}
                className={cx('requests-tab-partners_item', {
                  'requests-tab-partners_item--active': activePartner === partner,
                })}
              >
                <span>{partner.name}</span>
              </List.Item>
            )}
          />
        </Scrollbars>
        {getPartnersIsLoading && <div className={cx('requests-tab-partners_preloader')}><Spin /></div>}
      </div>
    </>
  );
};

export default RequestsPartnersList;
