import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';

import { PARTNER_MASKS, SPECIFIC_PARTNERS_IDS } from 'helpers/constants/_common/Partners';

import S from './styles.module.sass';
import { PartnerMaskType } from '../../../api/Partners/types';

const cx = classNames.bind(S);

interface PartnerMaskProps {
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  wrapperClassName?: string;
  iconClassName?: string;
  withoutWrapper?: boolean;
  wrapperColor?: string;
  iconColor?: string;
  mask?: PartnerMaskType;
  partnerId?: number;
  iconSize?: number;
  isVacation: boolean;
}

const PartnerMask: React.FC<PartnerMaskProps> = (props) => {
  const {
    style, iconStyle, mask, wrapperColor, partnerId,
    iconColor = '#fff', withoutWrapper,
    wrapperClassName, iconClassName, iconSize,
    isVacation = false,
  } = props;

  const PartnerIcon = (isVacation ? PARTNER_MASKS.VACATION.icon : partnerId && SPECIFIC_PARTNERS_IDS[partnerId]?.icon) || (mask && PARTNER_MASKS[mask]?.icon);

  const renderMaskSvg = () => {
    if (!PartnerIcon) {
      return null;
    }

    return (
      <PartnerIcon
        className={iconClassName}
        style={{
          width: iconSize || 16,
          height: iconSize || 16,
          fill: iconColor,
          ...iconStyle,
        }}
      />
    );
  };

  if (withoutWrapper) {
    return renderMaskSvg();
  }

  return (
    <div className={cx(S.partnerMaskWrapper, wrapperClassName)} style={{ backgroundColor: wrapperColor || '#AEB9C7', ...style }}>
      { renderMaskSvg() }
    </div>
  );
};

export default PartnerMask;
