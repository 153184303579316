import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useParams } from 'react-router';

import PartnerMask from 'components/_shared/PartnerMask';
import { useOnClickOutside } from 'helpers/hooks/useOnClickOutside';
import { DispatchType, Redux } from 'helpers/types/_common';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { addUserHourCommentAction, closeGroupContextMenu, deleteUserHourAction, dropTimeSelecting, initiateContextMenus, openDeleteUserHoursModal, setReplaceDrawer } from 'redux/TimeSheet/action';
import withRightPlural from 'helpers/utils/withRightPlural';
import KeyCodes from 'helpers/constants/_common/KeyCodes';
import getFromStore from 'helpers/utils/getFromStore';
import { DeleteUserHoursModalProps } from 'redux/TimeSheet/types';
import { EditableUserHourComment } from 'api/TimeSheet/types';
import { UserRedux } from 'redux/User/types';

import S from './helpers/styles.module.sass';
import { GroupOfCellsContextMenuProps, EditableUserHourCommentLists } from './helpers/types';
import { getSelectedHours, getSelectedHoursGroupedByTimesheetId } from '../../helpers/helpers';
import UserHourComments from '../UserHourComments/UserHourComments';
import { getEditedComment, getRightTitle } from './helpers/helpers';
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from './helpers/constants';

const cx = classNames.bind(S);

const GroupOfCellsContextMenu: React.FC<GroupOfCellsContextMenuProps> = (props) => {
  const { isCut, curatorOnlyView } = props;

  const [ isLoading, setIsLoading ] = useState(false);
  const [ commentLists, setCommentLists ] = useState<EditableUserHourCommentLists>({
    current: [],
    backup: [],
  });
  const groupPopoverRef = useRef<HTMLDivElement>(null);

  const { userId: editUserId } = useParams<{ userId: string }>();

  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);
  const { preferences } = useSelector((state: Redux) => state.login);
  const flatUserHoursTable = useSelector((state: Redux) => state.timeSheet.flatUserHoursTable);
  const dispatch: DispatchType = useDispatch();

  const isDeletingConfirmModalNeeded = preferences.askConfirmationOnDeleteHours;
  const halfHoursAmount = props.groupCellsLength;
  const hoursAmount = halfHoursAmount / 2;

  const activity = _.get(props, 'userHourData.activity');
  const partner = _.get(props, 'userHourData.partner');

  const startCellIndex = props.cellObj.groupCells[0].cellIndex || 0;
  const endCellIndex = props.cellObj.groupCells[props.cellObj.groupCells.length - 1].cellIndex || 1;

  const coords = { startCellIndex, endCellIndex, selectionDay: props.day };
  const pureSelectedHours = useMemo(() => getSelectedHoursGroupedByTimesheetId(coords), [ coords, flatUserHoursTable ]);
  const { editedCommentLength, isCommentEditingInProgress } = useMemo(() => {
    const editedComment = getEditedComment(commentLists.current);

    return {
      editedCommentLength: editedComment?.comment.length || 0,
      isCommentEditingInProgress: Boolean(editedComment),
    };
  }, [ commentLists ]);

  useEffect(() => {
    document.addEventListener('keyup', keyUpListener);

    groupPopoverRef.current?.focus();

    return () => { document.removeEventListener('keyup', keyUpListener); };
  }, []);

  useOnClickOutside(groupPopoverRef, () => {
    const { isVisible } = getFromStore<DeleteUserHoursModalProps>('timeSheet.deleteUserHoursModal');

    if (!isVisible) {
      dispatch(dropTimeSelecting());
      dispatch(initiateContextMenus());
    }
  });

  if (!partner || !activity || !props.day) return null;

  // handlers

  const keyUpListener = (e: KeyboardEvent) => {
    const focusedElement = document.activeElement;
    if (focusedElement?.nodeName === 'TEXTAREA') return;

    if (e.keyCode === KeyCodes.DELETE) {
      deleteUserHoursWithRightWay();
    }

    if (e.key === 'Escape') {
      dispatch(dropTimeSelecting());
    }
  };

  const openReplaceHoursDrawer = () => {
    const coords = { startCellIndex, endCellIndex, selectionDay: props.day };
    const selectedHours = getSelectedHours(coords);

    dispatch(setReplaceDrawer({ isVisible: true, data: { ...coords, selectedHours } }));
    dispatch(closeGroupContextMenu());
  };

  const deleteUserHours = () => {
    setIsLoading(true);

    dispatch(deleteUserHourAction(props.day, startCellIndex, endCellIndex, editUserId))
      .catch(() => {
        setIsLoading(false);
      });
  };

  const deleteUserHoursWithConfirmation = () => {
    dispatch(openDeleteUserHoursModal({
      selectionDay: props.day,
      startCellIndex,
      endCellIndex,
    }));
  };

  const setEditableCommentsList = (currentList: EditableUserHourComment[], backup?: EditableUserHourComment[]) => {
    setCommentLists({
      current: currentList,
      backup: backup || commentLists.backup,
    });
  };

  const cancelComment = () => {
    setEditableCommentsList(commentLists.backup);
  };

  const saveComment = () => {
    const editedComment = getEditedComment(commentLists.current);

    if (!editedComment) return;

    const { startCellIndex, endCellIndex, comment } = editedComment;
    const { userId } = getFromStore<UserRedux>('login');

    setIsLoading(true);
    dispatch(addUserHourCommentAction(+editUserId || userId, props.day, startCellIndex, endCellIndex, comment))
      .finally(() => {
        setIsLoading(false);
        cancelComment();
      });
  };

  const deleteUserHoursWithRightWay = isDeletingConfirmModalNeeded ? deleteUserHoursWithConfirmation : deleteUserHours;

  // renderers

  const renderFooter = () => {
    if (curatorOnlyView) {
      return;
    }

    if (!isMonthClosed && !isCut) {
      return (
        <div className={cx(S.groupPopoverFooter, { [S.withoutLabel]: isCommentEditingInProgress })}>
          {isCommentEditingInProgress ? renderCommentsEditingButtons() : renderRegularButtons()}
        </div>
      );
    }
  };

  const renderRegularButtons = () => (
    <>
      <span className={S.groupPopoverFooterLabel}>Actions</span>
      <div className={S.popoverActionButtons}>
        <Button
          className={cx(S.popoverButton, S.withBottomLine)}
          onClick={openReplaceHoursDrawer}
        >
          Replace activity
        </Button>
        <Button
          loading={isLoading}
          onClick={deleteUserHoursWithRightWay}
          className={S.popoverButton}
        >
          Delete
        </Button>
      </div>
    </>
  );

  const renderCommentsEditingButtons = () => {
    const isCommentTooSmall = editedCommentLength < MIN_COMMENT_LENGTH;
    const isCommentTooBig = editedCommentLength > MAX_COMMENT_LENGTH;

    const doneButtonTitle = getRightTitle(editedCommentLength);

    return (
      <div className={S.popoverActionButtons}>
        <div className={S.editingButtons}>
          <Button
            type="text"
            className={cx(buttons.qsButton, S.cancelButton)}
            onClick={cancelComment}
          >
            Cancel
          </Button>
          <Button
            className={buttons.qsButtonPrimary}
            type="primary"
            loading={isLoading}
            onClick={saveComment}
            title={doneButtonTitle}
            disabled={isCommentTooSmall || isCommentTooBig}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const renderComments = () => (
    <UserHourComments
      day={props.day}
      pureSelectedHours={pureSelectedHours}
      isMonthClosed={isMonthClosed}
      cellObj={props.cellObj}
      isCut={isCut}
      editableCommentsList={commentLists.current}
      setEditableCommentsList={setEditableCommentsList}
      isCommentEditingInProgress={isCommentEditingInProgress}
      curatorOnlyView={curatorOnlyView}
    />
  );

  return (
    <div
      ref={groupPopoverRef}
      className={S.groupPopover}
    >
      <div className={cx(S.groupPopoverHeader, S.withBottomLine)}>
        <div className={S.partnerMaskWrapper}>
          <div
            className={S.partnerMask}
            style={{
              borderColor: activity.color,
              backgroundColor: partner.color,
            }}
          >
            <PartnerMask
              withoutWrapper
              mask={partner.mask}
              iconColor={activity.color}
              partnerId={partner.partnerId}
            />
          </div>
        </div>
        <span className={S.partnerAndActivityName} title={`${partner.name}: ${activity.name}`}>{`${partner.name}: ${activity.name}`}</span>
      </div>
      <div className={cx(S.groupPopoverHoursSpent, { [S.nonInteractive]: isMonthClosed })}>
        <span className={S.hoursAmount}>{hoursAmount}</span>
        <span className={S.hoursLabel}>{`${withRightPlural('hour', hoursAmount)} reported`}</span>
      </div>
      <div
        className={cx(S.groupPopoverComments, {
          [S.nonInteractive]: isMonthClosed,
        })}
      >
        {renderComments()}
      </div>
      {renderFooter()}
    </div>
  );
};

export default GroupOfCellsContextMenu;
