import React, { Component } from 'react';
import Paginator from './Paginator';
import Layout from '../../../../_shared/Layout';

class BottomMenu extends Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.startDate !== nextProps.startDate ||
            this.props.endDate !== nextProps.endDate ||
            this.props.amountOfRows !== nextProps.amountOfRows ||
            this.props.activePage !== nextProps.activePage ||
            this.props.totalAmount !== nextProps.totalAmount ||
            this.props.sortBy !== nextProps.sortBy ||
            this.props.filter !== nextProps.filter

    );
  }

  render() {
    return (
      <Layout.Row>
        <Layout.Col>
          <Paginator
            changeActivePage={this.props.changeActivePage}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            amountOfRows={this.props.amountOfRows}
            activePage={this.props.activePage}
            totalAmount={this.props.totalAmount}
            sortBy={this.props.sortBy}
            filter={this.props.filter}
            style={{ marginBottom: '20px' }}
          />
        </Layout.Col>
      </Layout.Row>
    );
  }
}

export default BottomMenu;
