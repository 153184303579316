export default function(date) {
  return date
    .toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    .split('/')
    .join('-');
}

export var pythonDateToString = (date) => date.substring(0, 10).split('-').join('/') + ' ' + date.substring(11, 19);
