import React from 'react';

import { PartnerMaskType } from 'api/Partners/types';
import PartnerMaskPicker from 'components/_shared/PartnerMaskPicker/PartnerMaskPicker';
import ColorPicker from 'components/_shared/ColorPicker/ColorPicker';

import s from './styles.module.sass';

interface PartnerCustomizationProps {
  onChangeMask: (mask: PartnerMaskType) => void;
  onChangeColor: (color: string) => void;
  partnerMask: PartnerMaskType;
  partnerColor: string;
  maskColor: string;
  disabledColors: string[];
}

const PartnerCustomization: React.FC<PartnerCustomizationProps> = ({ onChangeMask, onChangeColor, partnerMask, partnerColor, disabledColors, maskColor }) => (
  <div className={s.customizationLayout}>
    <div className={s.blockWrapper}>
      <span className={s.blockTitle}>Mask</span>
      <PartnerMaskPicker
        partnerColor={partnerColor}
        maskColor={maskColor}
        onChange={onChangeMask}
        value={partnerMask}
      />
    </div>

    <div className={s.splitter} />

    <div className={s.blockWrapper}>
      <span className={s.blockTitle}>Color</span>
      <div className={s.colorPickerWrapper}>
        <ColorPicker
          color={partnerColor}
          disabledColors={disabledColors}
          onChange={onChangeColor}
        >
          <div
            className={s.colorPicker}
            style={{ backgroundColor: partnerColor }}
          />
        </ColorPicker>
      </div>

    </div>
  </div>
);

export default PartnerCustomization;
