import { Checkbox, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { ReactComponent as SelectIcon } from '../../helpers/icons/SelectArrow.svg';
import s from './styles.module.sass';

interface DropdownSelectSmartProps {
  placeholder?: string;
  valueKey: string;
  nameKey: string;  
  options: any[];
  value: number[];
  popoverText?: string;
  onChange: (selectedValues: number[]) => void;
  selectedText?: (selectedValues: number[]) => string;
}

const DropdownSelectSmart: React.FC<DropdownSelectSmartProps> = ({ placeholder, popoverText, options, valueKey, nameKey, value, onChange, selectedText }) => {
  const [ visible, setVisible ] = useState(false);
  const [ selectedValues, setSelectedValues ] = useState<number[]>([]);

  useEffect(() => {
    setSelectedValues(value || []);
  }, [ value ]);

  const handleCheckboxChange = (e: CheckboxChangeEvent, key: number) => {
    if (e.target.checked) {
      setSelectedValues(prev => ([ ...prev, key ]));
      return;
    }

    setSelectedValues(prev => _.filter(prev, v => v !== key));
  };

  const content = (
    <div className={s.dropdownSelectContent}>
      {popoverText && (<span className={s.dropdownSelectDescription}>{popoverText}</span>)}

      <Menu>
        {
          _.map(options, item => (
            <Menu.Item key={item[valueKey]} className={s.dropdownSelectMenuItem}>
              <Checkbox
                onChange={e => handleCheckboxChange(e, item[valueKey])}
                className={s.dropdownSelectCheckbox}
              >
                {item[nameKey]}
              </Checkbox>
            </Menu.Item>
          ))
        }
      </Menu>
    </div>

  );

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      if (!_.isEqual(value, selectedValues)) {
        onChange(selectedValues);
      }
    }

    setVisible(visible);
  };

  const dropdownText = _.isEmpty(selectedValues) ? (placeholder || '') : (selectedText?.(selectedValues) || '2 items selected');

  return (
    <Dropdown
      overlayClassName={s.dropdownSelectLayout}
      overlay={content}
      trigger={[ 'click' ]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <div className={cx(s.dropdownSelectButton, { [s.dropdownSelectOpen]: visible })}>
        { dropdownText }
        <SelectIcon className={s.selectDropdownIcon} />
      </div>
    </Dropdown>
  );
};

export default DropdownSelectSmart;
