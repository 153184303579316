import { Button, Popover, Typography } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import modal from 'antd/es/modal';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ReactComponent as SunIcon } from 'helpers/icons/sunIcon.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { BCDay, BCJointStatus, BCStatuses } from 'api/TimeSheet/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import { changeLockMonthStatus, getMonthStatus } from 'redux/TimeSheet/action';
import { USER_ROLES } from 'api/User/constants';
import popover from 'helpers/styles/components/popover.module.sass';
import modals from 'helpers/styles/components/modals.module.sass';
import { setPinnedActivity } from 'api/Partners/requests';
import { ReactComponent as ClosedMonthIcon } from 'helpers/icons/lockIcon.svg';
import { ReactComponent as OpenMonthIcon } from 'helpers/icons/lockOpenIcon.svg';
import { ReactComponent as ClockIcon } from 'helpers/icons/clockIcon.svg';
import { FULL_MONTH_YEAR_FORMAT } from 'helpers/constants/_common/timeFormats';

import S from './helpers/styles.module.sass';
import { MonthInfoProps } from './helpers/types';
import MonthInfoPopover from './components/MonthInfoPopover/MonthInfoPopover';

const cx = classNames.bind(S);
const REGULAR_WORK_DAY_HOURS = 8;

const MonthInfo: React.FC<MonthInfoProps> = (props) => {
  const { BCMonth, isBCLoading, userHoursTable, user, flatUserHoursTable, isDisabled, curatorOnlyView } = props;
  const dispatch: DispatchType = useDispatch();

  const { timeSheet, login } = useSelector((state: Redux) => state);
  const { isMonthClosed } = timeSheet.tableSettings;

  const [ monthInfoPopover, setMonthInfoPopover ] = useState(false);

  useEffect(() => {
    dispatch(getMonthStatus({
      userId: user.userId,
      month: BCMonth.month,
      year: BCMonth.year,
    }, curatorOnlyView));
  }, [ BCMonth.month, user ]);

  const isEditedByAdmin = _.includes(login.permissions, USER_ROLES.ADMIN);
  const closedMonthLabel = isEditedByAdmin ? 'Re-open month' : 'Month is closed';
  const onlyRegularDays = useMemo(() => _.filter(BCMonth.jointDays, jointDay => jointDay.jointStatus !== BCJointStatus.Cut), [ BCMonth.jointDays ]);

  const { totalWorkedHours, holidaysHours } = _.reduce(onlyRegularDays, (result: { totalWorkedHours: number, holidaysHours: number }, dayObj: BCDay) => {
    switch (dayObj.status) {
      case BCStatuses.Regular:
        return {
          ...result,
          totalWorkedHours: result.totalWorkedHours + REGULAR_WORK_DAY_HOURS,
        };
      case BCStatuses.Holiday:
        return {
          ...result,
          holidaysHours: result.holidaysHours + REGULAR_WORK_DAY_HOURS,
        };
      default: return result;
    }
  }, { totalWorkedHours: 0, holidaysHours: 0 });

  const alreadyWorkedHalfHours = _.reduce(userHoursTable, (result, columnObj) => {
    if (columnObj.jointStatus === BCJointStatus.Cut) return result;

    const dayHalfHoursSum = _.reduce(columnObj.cells, (result, cellObj) => result + (cellObj?.groupCells?.length || 0), 0);
    return result + dayHalfHoursSum;
  }, 0);

  const alreadyWorkedHours = alreadyWorkedHalfHours / 2;

  const handleOpenMonth = () => {
    const monthCloseText = () => {
      const userName = isEditedByAdmin ? ` of ${user.forename} ${user.surname}` : '';

      return `Are you sure you want to re-open ${dayjs(`${BCMonth.year}-${BCMonth.month}-1`).format(FULL_MONTH_YEAR_FORMAT)}${userName}? Editing timesheets in it will be allowed again.`;
    };

    modal.confirm({
      className: cx(modals.qsConfirmAntdModal),
      title: 'Open month',
      content: monthCloseText(),
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk: changeLockMonthStatusHandler,
      okText: 'Open Month',
    });
  };

  const changeLockMonthStatusHandler = () => {
    if (!isEditedByAdmin && isMonthClosed) return; // user can't unlock locked month

    return dispatch(changeLockMonthStatus({
      userId: user.userId,
      month: BCMonth.month,
      year: BCMonth.year,
      isMonthClosed: !isMonthClosed,
    }))
      .then(() => dispatch(setPinnedActivity(undefined)));
  };

  const monthCloseText = () => {
    const isEditedByAdmin = user.userId !== login.userId;

    const userName = isEditedByAdmin ? ` of ${user.forename} ${user.surname}` : '';

    const monthYearText = dayjs(`${BCMonth.year}-${BCMonth.month}-1`).format(FULL_MONTH_YEAR_FORMAT);

    return `Are you sure you want to close ${monthYearText}${userName}? Editing timesheet in closed month is not allowed.`;
  };

  const handleOnMonthButtonClick = () => {
    modal.confirm({
      className: cx(modals.qsConfirmAntdModal),
      title: 'Close month',
      content: monthCloseText(),
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk: changeLockMonthStatusHandler,
      okText: 'Close Month',
    });
  };

  const renderHoursWithPreloader = (text: string | number) => (isBCLoading ? <LoadingOutlined className={S.spinner} spin /> : (
    <b className={S.infoBoxHours}>
      {text}
      h
    </b>
  ));

  const renderMonthButton = () => {
    if (isBCLoading) {
      return (<div className={S.closeMonthPlaceholder} />);
    }

    if (curatorOnlyView) {
      return (
        <Button
          type="primary"
          disabled
          className={cx(buttons.qsButtonPrimary, S.closeMonthButton)}
        >
          <div className={buttons.iconTextWrapper}>
            { (isMonthClosed) ? <ClosedMonthIcon /> : <OpenMonthIcon /> }
            { (isMonthClosed) ? 'Month is closed' : 'Month is opened' }
          </div>
        </Button>
      );
    }

    return (
      <Button
        type="primary"
        disabled={isBCLoading || (!isEditedByAdmin && isMonthClosed) || curatorOnlyView}
        onClick={(isEditedByAdmin && isMonthClosed) ? handleOpenMonth : handleOnMonthButtonClick}
        className={cx(buttons.qsButtonPrimary, S.closeMonthButton)}
      >
        <div className={buttons.iconTextWrapper}>
          { isMonthClosed ? <OpenMonthIcon /> : <ClosedMonthIcon /> }
          { isMonthClosed ? closedMonthLabel : 'Close Month' }
        </div>
      </Button>
    );
  };

  return (
    <div className={cx(S.infoPanel, { [S.infoPanelDisabled]: isDisabled })}>
      <Popover
        onVisibleChange={setMonthInfoPopover}
        align={{ offset: [ 0, -8 ] }}
        trigger={[ 'click' ]}
        placement="bottom"
        overlayClassName={cx(popover.dotsPopoverOverlay, S.infoBoxOverlay)}
        content={(
          <MonthInfoPopover
            visible={monthInfoPopover}
            userId={user.userId}
            holidaysHours={holidaysHours}
            flatUserHoursTable={flatUserHoursTable}
            BCMonth={BCMonth}
            curatorOnlyView={curatorOnlyView}
          />
        )}
      >
        <div className={cx(S.infoBox, { [S.infoBoxOpened]: monthInfoPopover })}>
          <div className={S.infoBoxSection}>
            <ClockIcon />
            <Typography.Text className={S.infoBoxText}>Worked in current period:</Typography.Text>
            {renderHoursWithPreloader(`${alreadyWorkedHours}/${totalWorkedHours}`)}
          </div>

          <div className={S.infoBoxSection}>
            <SunIcon />
            <Typography.Text className={S.infoBoxText}>Holidays:</Typography.Text>
            {renderHoursWithPreloader(holidaysHours)}
          </div>
        </div>
      </Popover>

      { renderMonthButton() }

    </div>
  );
};

export default MonthInfo;
