import { Day } from 'api/BusinessCalendar/types';
import { CalendarDay } from 'components/BusinessCalendar/helpers/types';

export enum MonthInnerType {
  Regular = 'REGULAR',
  Drawer = 'DRAWER',
}

export interface CalendarMonthData {
  month: number;
  year: number;
  days: {
    prevMonth: CalendarDay[],
    currentMonth: CalendarDay[],
    nextMonth: CalendarDay[],
  }
}

export interface AddDaysStatuses {
  prevMonth: boolean;
  currentMonth: boolean;
  nextMonth: boolean;
}

export interface MonthInnerProps {
  monthInnerData: CalendarMonthData;
  monthInnerType: MonthInnerType;
  monthInnerDataEdited?: boolean;

  onDrawerOpen?: (monthInnerData: CalendarMonthData) => void;
  onRemoveAllDays?: (monthKey: keyof AddDaysStatuses, days?: CalendarDay[]) => void;
  onDayStatusChange?: (day: Day, monthKey: keyof AddDaysStatuses) => void;
  onDayAmountChange?: (day: Day, currentPeriodMonth: number, currentPeriodYear: number) => void;
}

export interface MonthTableProps {
  days: CalendarDay[];
  monthNumber: number;
  monthKey: keyof AddDaysStatuses;
  addDaysStatus?: boolean;
}

export interface MonthsTables {
  prevMonth: MonthTableProps,
  currentMonth: MonthTableProps,
  nextMonth: MonthTableProps,
}
