import React from 'react';
import { List } from 'antd';

import { UserPinnedActivityOutput } from 'api/Partners/types';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';

import S from './helpers/styles.module.sass';
import ActivityListItem from '../ActivityListItem/ActivityListItem';
import { ChosenActivityInfo } from '../../../../helpers/types';

interface ActivitiesListProps {
  activities: UserPinnedActivityOutput[];
  isLoading: boolean;
  handleSave: (activity: ChosenActivityInfo) => void;
}

const ActivitiesList: React.FC<ActivitiesListProps> = ({ activities, handleSave, isLoading }) => {
  const renderItem = (activityObject: UserPinnedActivityOutput) => (
    <ActivityListItem
      activityObject={activityObject}
      handleSave={handleSave}
    />
  );

  return (
    <div className={S.activitiesList}>
      <h4 className={S.activityListTitle}>Select activity</h4>
      <div className={S.activitiesListInner}>
        { isLoading && (<div className={S.loadingWrapper}><SpinnerNew /></div>)}
        <List
          itemLayout="horizontal"
          dataSource={activities}
          renderItem={renderItem}
          locale={{ emptyText: <div /> }}
        />
      </div>
    </div>
  );
};

export default ActivitiesList;
