import React from 'react';
import classNames from 'classnames/bind';
import S from '../../styles.module.css';

const cx = classNames.bind(S);

type FormInputGroupAddonComponentProps = {
  className?: string;
} & {
  [prop: string]: string;
};

export default class Addon extends React.Component<
  FormInputGroupAddonComponentProps
> {
  render(): React.ReactNode {
    return (
      <div className={cx('qs-form__input-group-addon')} {...this.props}>
        {this.props.children}
      </div>
    );
  }
}
