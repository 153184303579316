import dayjs from 'dayjs';
import _ from 'lodash';

import { DayStatuses } from 'api/BusinessCalendar/types';
import { isDayOff } from 'api/BusinessCalendar/helpers';

import { JointDay, JointMonth, JointStatus } from './types';

const defaultJointCalendarGenerator = () => {
  const now = dayjs();

  const generateDay = (day: string): JointDay => ({
    businessDayId: null,
    day,
    jointStatus: JointStatus.Regular,
    status: isDayOff(day) ? DayStatuses.DayOff : DayStatuses.Regular,
  });

  const generateMonth = (month: number): JointMonth => ({
    businessMonthId: null,
    month,
    year: now.get('year'),
    days: Array(now.month(month - 1).daysInMonth()).fill(null).map((e, i) => generateDay(dayjs(`${now.get('year')}-${month}-${i + 1}`).format('YYYY-MM-DD'))),
  });

  const generateYear = () => _.reduce(_.range(12), (r, i) => ({
    ...r,
    [(i + 1).toString()]: generateMonth(i + 1),
  }), {});

  return generateYear();
};

export default defaultJointCalendarGenerator;
