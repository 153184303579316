import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'helpers/constants/_common/timeFormats';
import { getBusinessMonthByDate, getCalendarMonthRange } from 'api/BusinessCalendar/requests';
import { BusinessMonthRangeOutput } from 'api/BusinessCalendar/types';
import { UserRedux } from 'redux/User/types';

import CuratorReportTable from './components/CuratorReportTable/CuratorReportTable';
import CuratorReportSettings from './components/CuratorReportSettings/CuratorReportSettings';
import { CuratorHourSettings } from './helpers/types';

interface CuratorReportProps {
  user: UserRedux;
}

const CuratorReport: React.FC<CuratorReportProps> = ({ user }) => {
  const [ isBCLoading, setIsBCLoading ] = useState(false);
  const [ settingsValues, setSettingsValues ] = useState<CuratorHourSettings>(() => ({
    dates: {
      selectedDate: dayjs().format(MONTH_YEAR_FORMAT),
      modifiedMonth: false,
      startDateRange: undefined,
      endDateRange: undefined,
    },
    searchValue: undefined,
    partnerId: null,
  }));

  useEffect(() => {
    setIsBCLoading(true);

    getBusinessMonthByDate(dayjs().format(DEFAULT_DATE_FORMAT))
      .then((r: BusinessMonthRangeOutput) => {
        setSettingsValues(prevValues => ({
          ...prevValues,
          dates: {
            ...prevValues.dates,
            selectedDate: `${r.year}-${r.month}`,
            startDateRange: r.startDay,
            endDateRange: r.endDay,
            modifiedMonth: r.modified,
          },
        }));
      })
      .finally(() => { setIsBCLoading(false); });
  }, []);

  const handleOnChangeMonth = (date: string) => {
    setIsBCLoading(true);

    return getCalendarMonthRange(user.userId, date)
      .then((response: BusinessMonthRangeOutput) => response)
      .catch(() => ({
        businessMonthId: undefined,
        startDay: dayjs(date).startOf('month').format(DEFAULT_DATE_FORMAT),
        endDay: dayjs(date).endOf('month').format(DEFAULT_DATE_FORMAT),
        year: dayjs(date).year(),
        month: dayjs(date).month() + 1,
        modified: false,
      }))
      .finally(() => setIsBCLoading(false));
  };

  return (
    <div>
      <CuratorReportSettings
        user={user}
        isBCLoading={isBCLoading}
        handleOnChangeMonth={handleOnChangeMonth}
        setSettingsValues={setSettingsValues}
        settingsValues={settingsValues}
      />

      <CuratorReportTable
        user={user}
        settingsValues={settingsValues}
        setSettingsValues={setSettingsValues}
      />
    </div>
  );
};

export default CuratorReport;
