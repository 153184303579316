import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SwiperCore from 'swiper';
import _ from 'lodash';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';

import useApi from 'api/hooks/useApi/useApi';
import Button from 'components/_shared/Button';
import CalendarDrawer from 'components/Drawers/CalendarDrawer/CalendarDrawer';
import { getCalendarByYear, updateMonth } from 'api/BusinessCalendar/requests';
import { Redux } from 'helpers/types/_common';
import { Day, Month as MonthProps } from 'api/BusinessCalendar/types';

import MonthsSelectorPanel from './components/MonthsSelectorPanel/MonthsSelectorPanel';
import { AddDaysStatuses, CalendarMonthData } from './components/Month/components/MonthInner/helpers/types';
import { BusinessCalendarDrawerDataProps, CalendarDay, CalendarType, DayType } from './helpers/types';
import 'swiper/swiper-bundle.css';
import S from './helpers/styles.module.sass';
import { getFilteredDaysByMonthNumber, getValidNewStatus } from './helpers/helpers';
import { compareDayCoords, sortByMonth } from './components/Month/helpers/helpers';
import defaultCalendarDrawerData from './helpers/defaultCalendarDrawerData';
import defaultCalendarGenerator from './helpers/defaultDaysGenerator';
import GridCalendar from './components/GridCalendar/GridCalendar';
import SwiperCalendar from './components/SwiperCalendar/SwiperCalendar';
import { CALENDAR_TYPE } from './helpers/constants';
import BusinessCalendarNew from './components/BusinessCalendar/BusinessCalendar';

const BusinessCalendar = () => {
  const { selectedYear } = useSelector((state: Redux) => state.businessCalendar2);

  const defaultCalendar = useMemo(() => defaultCalendarGenerator(), []);
  const [ getCalendar, calendar, bCIsLoading ] = useApi<typeof getCalendarByYear, MonthProps[]>(getCalendarByYear, { defaultData: defaultCalendar });

  const [ additionalMonth, changeAdditionalMonth ] = useState<MonthProps | null>(null);
  const [ swiper, setSwiper ] = useState<SwiperCore>();
  const [ calendarType, setCalendarType ] = useState<CalendarType>(CALENDAR_TYPE.row);
  const [ editedMonth, setEditedMonth ] = useState<number | null>(null);

  const defaultDrawerData: BusinessCalendarDrawerDataProps = useMemo(() => (defaultCalendarDrawerData), []);

  const [ drawerData, setDrawerData ] = useState<BusinessCalendarDrawerDataProps>(defaultDrawerData);
  const [ drawerVisible, setDrawerVisible ] = useState(false);
  const [ monthChangingIsLoading, changeMonthLoadingStatus ] = useState(false);
  const [ selectedMonthIndex, changeSelectedMonthIndex ] = useState(() => swiper?.realIndex || dayjs().get('month'));
  const [ changingMode, setChangingMode ] = useState<'amount' | 'status'>('amount');

  useEffect(() => {
    getCalendar(selectedYear);
  }, [ selectedYear ]);

  const onChangeMonthIndex = (newMonthIndex: number) => {
    if (!swiper) return;
    swiper?.slideTo(newMonthIndex);
  };

  const onDayStatusChange = (changedDay: CalendarDay, monthKey: keyof AddDaysStatuses) => {
    const days = {
      prevMonth: drawerData.prevMonthDays,
      currentMonth: drawerData.currentMonthPeriod.days,
      nextMonth: drawerData.nextMonthDays,
    };
    days[monthKey] = _.reduce(days[monthKey] as Day[], (array, day) => {
      if (day.businessDayId !== changedDay.businessDayId) {
        return [ ...array, day ];
      } else {
        return [
          ...array,
          {
            ...day,
            status: getValidNewStatus(changedDay.status, changedDay.day),
          },
        ];
      }
    }, [] as Day[]);

    setDrawerData({
      ...drawerData,
      prevMonthDays: days.prevMonth,
      currentMonthPeriod: {
        ...drawerData.currentMonthPeriod,
        days: days.currentMonth,
      },
      nextMonthDays: days.nextMonth,
    });
  };

  const onRemoveAllDays = (monthKey: keyof AddDaysStatuses, days?: CalendarDay[]) => {
    const { prevMonthDays, currentMonthPeriod, nextMonthDays } = drawerData;
    const daysStore = {
      prevMonth: [ ...prevMonthDays ],
      currentMonth: [ ...currentMonthPeriod.days ],
      nextMonth: [ ...nextMonthDays ],
    };

    if (days) {
      daysStore[monthKey] = [ ...days ];
    } else {
      daysStore[monthKey] = _.map(daysStore[monthKey], day => ({ ...day, dayType: DayType.Cut }));
    }

    setDrawerData({
      prevMonthDays: daysStore.prevMonth,
      currentMonthPeriod: {
        ...currentMonthPeriod,
        days: daysStore.currentMonth,
      },
      nextMonthDays: daysStore.nextMonth,
    });
  };

  const onDayAmountChange = (day: CalendarDay, currentPeriodMonth: number, currentPeriodYear: number) => {
    const monthMutateDirection = compareDayCoords(dayjs(day?.day), { month: currentPeriodMonth, year: currentPeriodYear });
    const dayIsChosenAlready = day.dayType === DayType.Original;

    let dataToStore = { ...drawerData };

    switch (monthMutateDirection) {
      case 'prevMonthDays': {
        const daysToMutate = [ ...drawerData.prevMonthDays, ...drawerData.currentMonthPeriod.days ];
        const chosenDayIndex = _.indexOf(daysToMutate, _.find(daysToMutate, { day: day.day }));
        const indexToMutate = dayIsChosenAlready ? chosenDayIndex + 1 : chosenDayIndex;
        const middleOfCurrentMonthIndex = drawerData.prevMonthDays.length + Math.floor(drawerData.currentMonthPeriod.days.length / 2);
        const mutatedDays: CalendarDay[] = _.map(daysToMutate, (dayObj, dayIndex) => {
          if (dayIndex >= indexToMutate && dayIndex <= middleOfCurrentMonthIndex) {
            // we need to change all days of prev month and of the start of current month, but not of end of month
            return { ...dayObj, dayType: DayType.Original };
          } else if (dayIndex < indexToMutate) {
            return { ...dayObj, dayType: DayType.Cut };
          } else {
            return dayObj;
          }
        });
        const sortedDays = sortByMonth(mutatedDays, drawerData.currentMonthPeriod.month, drawerData.currentMonthPeriod.year);

        dataToStore = {
          prevMonthDays: sortedDays.prevMonthDays,
          currentMonthPeriod: {
            ...drawerData.currentMonthPeriod,
            days: sortedDays.currentMonthDays,
          },
          nextMonthDays: drawerData.nextMonthDays,
        };

        break;
      }
      case 'nextMonthDays': {
        const daysToMutate = [ ...drawerData.currentMonthPeriod.days, ...drawerData.nextMonthDays ];
        const chosenDayIndex = _.indexOf(daysToMutate, _.find(daysToMutate, { day: day.day }));
        const indexToMutate = dayIsChosenAlready ? chosenDayIndex - 1 : chosenDayIndex;
        const mutatedDays: CalendarDay[] = _.map(daysToMutate, (dayObj, dayIndex) => {
          if (dayIndex <= indexToMutate && dayIndex >= 15) {
            // we need to change all days of next month and of the end of current month, but not of start of month
            return { ...dayObj, dayType: DayType.Original };
          } else if (dayIndex > indexToMutate) {
            return { ...dayObj, dayType: DayType.Cut };
          } else {
            return dayObj;
          }
        });
        const sortedDays = sortByMonth(mutatedDays, drawerData.currentMonthPeriod.month, drawerData.currentMonthPeriod.year);

        dataToStore = {
          prevMonthDays: drawerData.prevMonthDays,
          currentMonthPeriod: {
            ...drawerData.currentMonthPeriod,
            days: sortedDays.currentMonthDays,
          },
          nextMonthDays: sortedDays.nextMonthDays,
        };

        break;
      }
      default:
        break;
    }

    setDrawerData(dataToStore);
  };

  const onOpenDrawer = (monthInnerData: CalendarMonthData) => {
    const { month, year } = monthInnerData;

    changeMonthLoadingStatus(true);
    setDrawerVisible(true);
    getFilteredDaysByMonthNumber(year, month, calendar)
      .then((res) => {
        const { drawerData, additionalMonth } = res;

        setDrawerData(drawerData);
        changeAdditionalMonth(additionalMonth);
      })
      .finally(() => changeMonthLoadingStatus(false));
  };

  const onCloseDrawer = () => {
    setDrawerData(defaultDrawerData);
    setDrawerVisible(false);
  };

  const drawerSaveChangesButton = (drawerData?: BusinessCalendarDrawerDataProps) => {
    const onMonthChangeSave = () => {
      changeMonthLoadingStatus(true);

      const daysToUpdate = _.filter(
        [ ...drawerData?.prevMonthDays || [], ...drawerData?.currentMonthPeriod.days || [], ...drawerData?.nextMonthDays || [] ],
        dayObj => dayObj?.dayType === DayType.Original,
      );

      updateMonth(daysToUpdate, drawerData?.currentMonthPeriod?.businessMonthId || 0)
        .then(() => {
          getCalendar(selectedYear);
          onCloseDrawer();
        })
        .finally(() => {
          changeMonthLoadingStatus(false);
        });
    };

    return (
      <Button
        key="month-save-button"
        type="primary"
        isLoading={monthChangingIsLoading}
        loadingIcon={<LoadingOutlined className={S.spinner} spin />}
        onClick={onMonthChangeSave}
        className={S.monthDrawerButton}
      >
        Save changes
      </Button>
    );
  };

  const renderCalendarContent = () => {
    if (calendarType === 'new') {
      return (
        <BusinessCalendarNew
          changingMode={changingMode}
          setChangingMode={setChangingMode}
          editedMonth={editedMonth}
          setEditedMonth={setEditedMonth}
        />
      );
    }

    if (calendarType === 'grid') {
      return (
        <GridCalendar
          onOpenDrawer={onOpenDrawer}
          calendar={calendar}
          loading={bCIsLoading}
        />
      );
    }

    return (
      <SwiperCalendar
        calendar={calendar}
        loading={bCIsLoading}
        setSwiper={setSwiper}
        onOpenDrawer={onOpenDrawer}
        selectedMonthIndex={selectedMonthIndex}
        changeSelectedMonthIndex={changeSelectedMonthIndex}
      />
    );
  };

  return (
    <div className={S.businessCalendar}>
      <MonthsSelectorPanel
        calendarType={calendarType}
        setCalendarType={setCalendarType}
        selectedMonthIndex={selectedMonthIndex}
        onChangeMonthIndex={onChangeMonthIndex}
        bCIsLoading={bCIsLoading}
        changingMode={changingMode}
        setChangingMode={setChangingMode}
      />

      <div className={S.monthViewWrapper}>
        <div className={S.monthsView}>
          { renderCalendarContent() }
        </div>
        <CalendarDrawer
          isLoading={monthChangingIsLoading}
          drawerData={drawerData}
          visible={drawerVisible}
          onClose={onCloseDrawer}
          onRemoveAllDays={onRemoveAllDays}
          onDayStatusChange={onDayStatusChange}
          onDayAmountChange={onDayAmountChange}
          externalButtons={[ drawerSaveChangesButton ]}
        />
      </div>
    </div>
  );
};

export default BusinessCalendar;
