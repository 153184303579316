import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Button, Skeleton } from 'antd';

import { FULL_MONTH_FORMAT } from 'helpers/constants/_common/timeFormats';
import { DayStatuses } from 'api/BusinessCalendar/types';

import S from './styles.module.sass';
import DayComponent from '../DayComponent/DayComponent';
import { JointDay, JointMonth, JointStatus } from '../../helpers/types';
import { getDaysAmount, getDaysWithHighlighted } from '../../helpers/helpers';

interface MonthProps {
  BCMonth: JointMonth;
  changingMode: 'amount' | 'status';
  backupMonth: JointMonth;
  isEdited: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  startMonthEditing: (monthNumber: number) => void;
  restoreMonthData: () => void;
  saveChanges: () => void;
  changeDaysStatus: (BCDay: JointDay, monthNumber: number) => void;
  changeAmountOfDays: (BCMonth: JointMonth, daysToCut: string[]) => void;
}

const MonthComponent: React.FC<MonthProps> = (props) => {
  const {
    BCMonth, changeAmountOfDays, isEdited, isEditing, isDisabled, isLoading,
    startMonthEditing, restoreMonthData, changeDaysStatus,
    changingMode, backupMonth, saveChanges,
  } = props;
  const [ daysToCut, setDaysToCut ] = useState<string[]>([]);

  const monthName = dayjs(`${BCMonth.year}-${BCMonth.month}-1`).format(FULL_MONTH_FORMAT);
  const dayNames = _.range(7).reduce((result, dayIndex) => ({ ...result, [dayIndex + 1]: dayjs().day(dayIndex + 1).format('ddd') }), {});
  
  const { firstPeriodMonthDays, lastPeriodMonthDays } = useMemo(() => _.reduce(BCMonth.days, (result, dayObj) => getDaysAmount(result, dayObj, BCMonth), {
    firstPeriodMonthDays: 0,
    lastPeriodMonthDays: 0,
  } as {
    firstPeriodMonthDays: number,
    lastPeriodMonthDays: number,
  }), [ BCMonth ]);

  useEffect(() => {
    if (!isEditing) setDaysToCut([]);
  }, [ isEditing ]);

  const calcStat = (days: JointDay[]) => _.reduce(days, (result, dayObj) => {
    switch (true) {
      case dayObj.status === DayStatuses.Regular && dayObj.jointStatus === JointStatus.Regular:
        return {
          ...result,
          worked: result.worked + 8,
        };
      case dayObj.status === DayStatuses.Holiday && dayObj.jointStatus === JointStatus.Regular:
        return {
          ...result,
          holidays: result.holidays + 8,
        };
      default:
        return result;
    }
  }, { worked: 0, holidays: 0 } as { worked: number, holidays: number });

  const { worked, holidays } = useMemo(() => calcStat(BCMonth.days), [ BCMonth.days ]);

  const changeAmountOfDaysByArr = () => {
    setDaysToCut([]);
    changeAmountOfDays(BCMonth, daysToCut);
  };

  const changeDayStatus = (BCDay: JointDay) => {
    changeDaysStatus(BCDay, BCMonth.month);
  };

  const highlightDaysForCut = (targetDay: JointDay) => {
    const days = getDaysWithHighlighted(targetDay, BCMonth);

    setDaysToCut(days);
  };

  const dropHighlight = () => {
    if (!_.isEmpty(daysToCut)) {
      setDaysToCut([]);
    }
  };

  const renderAdditionalMonthLabels = () => (
    <>
      {Boolean(firstPeriodMonthDays) && (
        <div className={classNames(S.additionalMonthLabel, S.previousMonth)} style={{ height: `calc(${Math.ceil(firstPeriodMonthDays / 7)} * 12.5%)` }}>
          <span className={S.littleMonthName}>{dayjs(BCMonth.days[0].day).format('MMM')}</span>
        </div>
      )}
      {Boolean(lastPeriodMonthDays) && (
        <div className={classNames(S.additionalMonthLabel, S.nextMonth)} style={{ height: `calc(${Math.ceil(lastPeriodMonthDays / 7)} * 12.5%)` }}>
          <span className={S.littleMonthName}>{dayjs(BCMonth.days[BCMonth.days.length - 1].day).format('MMM')}</span>
        </div>
      )}
    </>
  );

  const renderButtons = () => (!isEditing ? (
    <Button type="primary" size="small" onClick={() => startMonthEditing(BCMonth.month)} className={S.editingButton}>Edit month</Button>
  ) : (
    <>
      <Button type="default" size="small" onClick={restoreMonthData} className={S.cancelButton}>Cancel</Button>
      <Button type="primary" size="small" disabled={isEdited} onClick={saveChanges} className={S.saveButton}>Save</Button>
    </>
  ));

  return (
    <div
      className={classNames(S.month, {
        [S.editing]: isEditing,
        [S.disabled]: isDisabled,
      })}
      onMouseLeave={dropHighlight}
    >
      <div className={S.monthHeader}>
        <div className={S.headerRow}>
          {isLoading ? (<Skeleton.Button size="large" active />) : (<span className={S.monthName}>{monthName}</span>)}
          <div className={S.stat}>
            {isLoading ? (<Skeleton.Button size="large" active />) : (
              <>
                <span className={S.leftPart}>{worked}</span>
                <span className={S.rightPart}>
                  worked
                  <br />
                  hours
                </span>
              </>
            )}
          </div>
        </div>
        <div className={S.headerRow}>
          {isLoading ? (<Skeleton.Button size="small" active />) : (<div className={S.headerButtons}>{renderButtons()}</div>)}
          <div className={S.stat}>
            {isLoading ? (<Skeleton.Button size="large" active />) : (
              <>
                <span className={S.leftPart}>{holidays}</span>
                <span className={S.rightPart}>
                  holidays
                  <br />
                  hours
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={S.daysLabels}>
        {_.map(dayNames, (dayName, dayIndex) => (
          <div key={dayIndex} className={classNames(S.dayName, { [S.holiday]: [ 6, 7 ].includes(parseInt(dayIndex, 10)) })}>{dayName}</div>
        ))}
      </div>
      <div className={classNames(S.days, { [S.nonInteractive]: !isEditing })}>
        {!isLoading && renderAdditionalMonthLabels()}
        {isLoading ? (<Skeleton active paragraph={{ rows: 5 }} />) : _.map(BCMonth.days, (dayObj, index) => (
          <React.Fragment key={dayObj.day}>
            <DayComponent
              BCDay={dayObj}
              changingMode={changingMode}
              currentMonthNumber={BCMonth.month}
              daysToCut={daysToCut}
              changeDayStatus={changeDayStatus}
              backupDay={backupMonth.days[index]}
              highlightDaysForCut={highlightDaysForCut}
              changeAmountOfDaysByArr={changeAmountOfDaysByArr}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MonthComponent;
