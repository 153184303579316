import React from 'react';
import { useSelector } from 'react-redux';

import TimeSheet from 'components/TimeSheet/TimeSheet';

import { Redux } from '../../helpers/types/_common';

const NewTrackerTabContent: React.FC = () => {
  const user = useSelector((state: Redux) => state.login);

  const renderTimeTrackerTab = () => <TimeSheet user={user} />;

  return (
    <div>
      {renderTimeTrackerTab()}
    </div>
  );
};

export default NewTrackerTabContent;
