import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router';

import SettingsPopover from 'components/SettingsPopover/SettingsPopover';

import s from './helpers/styles.module.sass';
import { Redux } from '../../../../helpers/types/_common';
import SidebarCollapseButton from './components/SidebarCollapseButton';

export interface PagesSidebarProps {
  children: React.ReactNode;
  setCollapsed?: (collapsed: boolean) => void;
  collapsed?: boolean;
}

const PagesSidebar: React.FC<PagesSidebarProps> = ({ children, collapsed, setCollapsed }) => {
  const location = useLocation();
  const { userId, userDataRequest, availableRoutes, logged } = useSelector((state: Redux) => state.login);

  if (!logged) {
    return null;
  }

  const currentRoutePath = _.split(location.pathname, '/')[1];
  const accessDenied = userId && !_.some(availableRoutes, r => _.includes(r, currentRoutePath));

  const renderCollapseButton = () => {
    if (_.isUndefined(collapsed) || !setCollapsed) {
      return null;
    }

    return (
      <SidebarCollapseButton
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    );
  };

  const renderSettingsButton = () => {
    if (collapsed) {
      return null;
    }

    return (
      <SettingsPopover />
    );
  };

  /*
    NOTE:
      pages-sidebar class targeted by getPopupContainer props of Tooltip/Popover, preventing scroll with popups.
   */
  return (
    <div id="pages-sidebar" className={cx(s.pagesSidebar, { [s.collapsedPagesSidebar]: collapsed, [s.hiddenPagesSidebar]: (userDataRequest.isLoading || accessDenied) })}>
      { renderSettingsButton() }

      { renderCollapseButton() }

      {children}
    </div>
  );
};

export default PagesSidebar;
