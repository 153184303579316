import { getMinMax } from 'components/TimeSheet/components/Table/components/TableBody/components/Column/helpers/helpers';
import { getTimeByDateAndCellIndex } from 'components/TimeSheet/helpers/helpers';

// eslint-disable-next-line import/prefer-default-export
export const getTimeRangeStringsByCellIndexes = (day: string, start: number, end: number, endInclusive?: boolean) => {
  const { min, max } = getMinMax(start, end);

  return {
    start_time: getTimeByDateAndCellIndex(day, min, 'YYYY-MM-DD HH:mm:ss'),
    end_time: getTimeByDateAndCellIndex(day, endInclusive ? max + 1 : max, 'YYYY-MM-DD HH:mm:ss'), // + 1 because we need top edge of the cell's time
  };
};
