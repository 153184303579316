import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Modal, Select } from 'antd';
import _ from 'lodash';
import { AxiosError } from 'axios';

import { EditSkillModalProps, DefaultSREditValues } from './helpers/types';
import { ReactComponent as SelectIcon } from '../../../../../../../../helpers/icons/SelectArrow.svg';
import { ValidationError } from '../../../../../../../../helpers/types/_common';
import S from './helpers/styles.module.sass';
import compareObjects from '../../../../../../../../helpers/utils/compareObjects';
import { SRStatuses } from '../../../../../../../../api/Specialists/types';
import OCCUPATIONS from '../../../../../../../../helpers/constants/Occupations/Occupations';
import { EmployeeOccupationEnum } from '../../../../../../../../helpers/constants/Occupations/types';
import { SPECIALISTS_REQUESTS_STATUSES } from '../../../../../../../../api/Specialists/constants';
import requestApi from '../../../../../../../../api/Specialists/requests';

const cx = classNames.bind(S);

const EditSRModal: React.FC<EditSkillModalProps> = (props) => {
  const { visible, onCancel, onSuccess, defaultValues, title } = props;

  const def = { occupation: defaultValues.occupation, status: defaultValues.status };
  const [ values, setValues ] = useState<DefaultSREditValues>(def);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState<ValidationError | null | undefined>(null);

  useEffect(() => {
    if (visible) setValues(def);
  }, [ visible ]);

  const renderOccupationSelect = () => {
    const options = _.map(OCCUPATIONS, (value, key) => <Select.Option key={key} value={key}>{value.name}</Select.Option>);

    const onChange = (occupation: EmployeeOccupationEnum) => {
      setValues({ ...values, occupation });
    };

    return (
      <Select
        value={values.occupation}
        placeholder="Select occupation..."
        className={cx('edit-modal_select')}
        suffixIcon={<SelectIcon />}
        size="large"
        bordered={false}
        onChange={onChange}
      >
        { options }
      </Select>
    );
  };

  const renderStatusesSelect = () => {
    const options = _.map(SPECIALISTS_REQUESTS_STATUSES, (value, key) => (
      <Select.Option key={key} value={key}>{value}</Select.Option>
    ));

    const onChange = (status: SRStatuses) => {
      setValues({ ...values, status });
    };

    return (
      <Select
        value={values.status}
        placeholder="Select status..."
        className={cx('edit-modal_select')}
        style={{ textTransform: 'capitalize' }}
        suffixIcon={<SelectIcon />}
        size="large"
        bordered={false}
        onChange={onChange}
      >
        { options }
      </Select>
    );
  };

  const renderModalContent = () => (
    <div className={cx('edit-modal_wrapper')}>
      { renderOccupationSelect() }
      { renderStatusesSelect() }
    </div>
  );

  const handleOnOk = () => {
    if (!defaultValues.specialistRequestId) {
      return;
    }

    const body = defaultValues ? compareObjects(values, defaultValues) : values;

    if (_.isEmpty(body)) {
      onCancel?.();
      return;
    }

    setLoading(true);
    requestApi.editSpecialRequest(body, defaultValues.specialistRequestId)
      .then(() => {
        onSuccess();
      })
      .catch(({ response }: AxiosError<ValidationError>) => setError(response?.data))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={title}
      className={cx('qs-basic-antd-modal')}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOnOk}
      confirmLoading={loading}
      centered
      cancelButtonProps={{ className: cx('qs-basic-antd-modal_cancel-btn'), type: 'text' }}
      okButtonProps={{
        className: cx('qs-basic-antd-modal_ok-btn'),
        size: 'large',
      }}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default EditSRModal;
