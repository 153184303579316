import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { Spin } from 'antd';

import S from './helpers/styles.module.sass';
import ENGLISH_KNOWLEDGE_STATES from '../../../../../../helpers/constants/Skills/EnglishKnowledgeStates';
import { DispatchType, EnglishLevelEnum } from '../../../../../../helpers/types/_common';
import { updateEnglishExperience } from '../../../../../../redux/SkillSet/action';
import { ReactComponent as EnglishIcon } from '../../../../../../helpers/icons/EnglishNew.svg';
import { UserDetails } from '../../../../../../api/User/types';
import { UserRedux } from '../../../../../../redux/User/types';

const cx = classNames.bind(S);

interface EnglishKnowledgeProps {
  user: UserDetails | UserRedux;
  loading: boolean;
  disabled: boolean;
  editByAdmin?: boolean;
  isReadOnlyMode?: boolean;
}

const EnglishKnowledge: React.FC<EnglishKnowledgeProps> = ({ loading, disabled, user, editByAdmin, isReadOnlyMode }) => {
  const dispatch: DispatchType = useDispatch();

  const [ spinning, setSpinning ] = useState(false);
  const [ error, setError ] = useState<unknown>();
  const [ englishKnowledge, setEnglishKnowledge ] = useState<EnglishLevelEnum>(() => user.englishKnowledge);

  const handleBlockClick = (knowledgeId: EnglishLevelEnum) => {
    if (spinning || knowledgeId === user.englishKnowledge || isReadOnlyMode) return;

    setSpinning(true);
    dispatch(updateEnglishExperience({ englishKnowledge: knowledgeId }, user.userId, editByAdmin))
      .then(() => setEnglishKnowledge(knowledgeId))
      .catch((err: unknown) => setError(err))
      .finally(() => setSpinning(false));
  };

  const renderError = () => {
    if (!error) return null;

    setTimeout(() => {
      setError(undefined);
    }, 5000);

    return (
      <span className={cx('skillset_category-title-error')}>Something go wrong, try again later!</span>
    );
  };

  const renderEnglishSelection = () => {
    const reversedEnglishKnowledge = _.reverse([ ...ENGLISH_KNOWLEDGE_STATES ]);

    return _.map(reversedEnglishKnowledge, (value, index) => {
      const isSelectedKnowledge = value.knowledgeId === englishKnowledge;

      return (
        <div
          key={value.knowledgeId}
          onClick={() => handleBlockClick(value.knowledgeId)}
          className={cx('skillset_experience', 'skillset_experience-english', {
            'skillset_experience-english--active': isSelectedKnowledge,
            'skillset_experience-left-column': index === 0,
            'skillset_experience-isReadOnlyMode': isReadOnlyMode,
          })}
        >
          <div className={cx('skillset_english-knowledge-icon', { loading })}>
            { isSelectedKnowledge ? <EnglishIcon /> : null }
          </div>
          <h5 className={cx('skillset_english-knowledge-title')}>{value.name}</h5>
          {
            value.descriptions
            && <p className={cx('skillset_english-knowledge-description')}>{value.descriptions}</p>
          }
        </div>
      );
    });
  };

  return (
    <div className={cx('skillset_language-section', { disabled })}>
      <div className={cx('skillset_skill-category')}>
        <Spin spinning={spinning}>
          <div className={cx('skillset_category-header')}>
            <h5 className={cx('skillset_category-title')}>
              English Knowledge
              { renderError() }
            </h5>
          </div>
          <div className={cx('skillset_category-body-row', { 'skillset_category--spinning': spinning })}>
            {renderEnglishSelection()}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default EnglishKnowledge;
