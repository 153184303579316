import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';

const cx = classNames.bind(S);

interface FormGroupComponentProps {
  className?: string;
  controlId?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export default class Group extends React.Component<FormGroupComponentProps> {
  render(): React.ReactNode {
    const { className, controlId, children, onClick, style } = this.props;

    return (
      <div
        id={controlId}
        className={cx('qs-form__group', className)}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    );
  }
}
