import React, { useState } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Popover, Tooltip } from 'antd';

import S from './helpers/styles.module.sass';
import { AttitudeSelectPopoverProps } from './helpers/type';
import { ReactComponent as Tongue } from './helpers/tongue.svg';
import SKILL_ATTITUDES from '../../../../../../helpers/constants/Skills/Attitudes';
import { SkillAttitudeProp } from '../../../../../../helpers/types/Skills';
import SkillAttitude from '../Attitude';
import useDidMountEffect from '../../../../../../helpers/hooks/useDidMountEffect';
import skillRequests from '../../../../../../api/SkillSet/requests';

const cx = classNames.bind(S);

const AttitudeSelectPopover: React.FC<AttitudeSelectPopoverProps> = (props) => {
  const { userSkill, children, updateUserSkills, onVisibleChange, visible, userId } = props;

  const [ loading, setLoading ] = useState(false);
  const [ isAttitudeChanged, setIsAttitudeChanged ] = useState(false);

  useDidMountEffect(() => {
    if (!isAttitudeChanged) {
      return;
    }

    setLoading(true);
    setIsAttitudeChanged(false);

    if (userSkill.skill.skillId) {
      skillRequests.updateAttitudes({
        skillId: userSkill.skill.skillId,
        attitude: userSkill.attitude,
        userId,
      })
        .then(() => {
          onVisibleChange(false);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [ userSkill.attitude ]);

  const onAttitudeClick = (attitude: SkillAttitudeProp) => {
    setIsAttitudeChanged(true);

    if (_.isEqual(userSkill.attitude, attitude.key)) {
      const updatedUserSkill = {
        ...userSkill,
        attitude: null,
      };

      updateUserSkills?.(updatedUserSkill);
      return;
    }

    const updatedUserSkill = {
      ...userSkill,
      attitude: attitude.key,
    };

    updateUserSkills?.(updatedUserSkill);
  };

  const renderContent = () => {
    const attitudes = _.map(SKILL_ATTITUDES, attitude => (
      <div className={cx('tooltip-attitude_inner')} key={attitude.key}>
        <Tooltip
          align={{ offset: [ -2, 0 ] }}
          title={(
            <div>
              <Tongue className={cx('tooltip-attitude_tongue')} />
              <div>{attitude.title}</div>
            </div>
          )}
          placement="right"
          overlayClassName={cx('tooltip-attitude_overlay')}
          destroyTooltipOnHide
        >
          <button
            type="button"
            onClick={() => onAttitudeClick(attitude)}
            className={cx('qs-attitude-selector', {
              'qs-attitude-selector--active': _.isEqual(userSkill.attitude, attitude.key),
              'qs-attitude-selector--is-loading': loading && _.isEqual(userSkill.attitude, attitude.key),
            })}
            title={attitude.title}
          >
            <SkillAttitude iconName={attitude.key} style={attitude.style} />
          </button>
        </Tooltip>
      </div>
    ));

    return (
      <div
        className={cx('attitude-popover_content')}
      >
        <div className={cx('attitude-popover_inner')}>
          { attitudes }
        </div>
        <Tongue className={S.attitudePopoverTongue} />
      </div>
    );
  };

  return (
    <Popover
      placement="right"
      visible={visible}
      overlayClassName={cx('attitude-popover')}
      content={renderContent()}
      trigger="click"
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    >
      {children}
    </Popover>

  );
};

export default AttitudeSelectPopover;
