import * as Actions from '../constants/HourReport';
import dayjs from 'dayjs';
import Subtypes from 'V1/constants/__common__/Subtypes';

const firstDay = dayjs().date(1).format('YYYY-MM-DD');
const lastDay = dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD');

const InitialState = {
  startDate: firstDay,
  endDate: lastDay,
  currentMonth: dayjs().month() + 1,
  currentYear: dayjs().get('year'),
  hourlyRate: 0,
  isLoading: false,
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CHANGE_STARTDATE:
      return { ...state, startDate: action.payload };
    case Actions.CHANGE_ENDDATE:
      return { ...state, endDate: action.payload };
    case Actions.CHANGE_PERIOD:
      const { startDate, endDate, currentMonth, currentYear } = action.payload;
      return { ...state, startDate, endDate, currentMonth, currentYear };
    case Actions.CHANGE_HOURLYRATE:
      return { ...state, hourlyRate: action.payload };
    case Actions.RECEIVE_HOURREPORT:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, isLoading: true };
        case Subtypes.Request.Success:
          return {
            ...state,
            objects: action.payload,
            error: false,
            isLoading: false
          };
          case Subtypes.Request.Error:
            return { ...state, error: true, isLoading: false, data: action.payload };
        default:
          return state;
      }
    case Actions.REMOVE_OBJECTS:
      return { ...state, objects: [] };
    case Actions.HOUR_REPORT_INITIATE:
      return InitialState;
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
