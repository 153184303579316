import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { DispatchType, Redux } from 'helpers/types/_common';
import { setFlatUserHoursList, changeSelectedDate, selectActivity, ctrlPressToggle } from 'redux/TimeSheet/action';
import { BCMonth as BCMonthProps, UsersHourResponse } from 'api/TimeSheet/types';
import * as BCRequests from 'api/TimeSheet/request';
import { UserDetails } from 'api/User/types';
import { UserRedux } from 'redux/User/types';
import MarkedHourDrawer from 'components/Drawers/MarkedHourDrawer/MarkedHourDrawer';
import configureStore from 'redux/_reducer/configureStore';
import { ContextMenuType } from 'redux/TimeSheet/types';

import TimeSheetTable from './components/Table/Table';
import S from './helpers/styles.module.sass';
import TimeSheetSettings from './components/Settings/TimeSheetSettings';
import { UserHoursTable } from './helpers/types';
import ClosedMonthPopup from './components/ClosedMonthPopup/ClosedMonthPopup';
import { DEFAULT_DATE_FORMAT } from '../../helpers/constants/_common/timeFormats';
import useGetBusinessCalendar from '../../helpers/hooks/useGetBusinessCalendar';
import { blankBusinessMonthGenerator } from './helpers/generators';
import { getFlatUserHoursList, getUserHoursTable } from './helpers/helpers';
import { UiDrawMode } from '../../helpers/constants/_common/constants';

interface TimeSheetProps {
  user: UserDetails | UserRedux;
  curatorOnlyView?: boolean;
}

const pressCtrl = (status: boolean) => {
  const { activities, contextMenus } = configureStore.getState().timeSheet;
  const isContextMenuOpened = contextMenus[ContextMenuType.Selection].isVisible;
  const currentlyInUseActivity = _.filter(activities, { currentlyInUse: true })[0];

  if (!currentlyInUseActivity || isContextMenuOpened) return;
  configureStore.dispatch(selectActivity(status ? 'currentlyInUse' : undefined));
  configureStore.dispatch(ctrlPressToggle(status));
};

const TimeSheet: React.FC<TimeSheetProps> = ({ user, curatorOnlyView }) => {
  const dispatch: DispatchType = useDispatch();

  const { preferences } = useSelector((state: Redux) => state.login);
  const { tableSettings, flatUserHoursTable, replaceDrawer } = useSelector((state: Redux) => state.timeSheet);
  const { selectedDateObj, isMonthClosed, isCtrlPressed } = tableSettings;

  const BCMonthDefault: BCMonthProps = useMemo(() => blankBusinessMonthGenerator(dayjs().format(DEFAULT_DATE_FORMAT)), []);
  const defaultTable = useMemo(() => getUserHoursTable(getFlatUserHoursList([], BCMonthDefault), BCMonthDefault), []);

  const [ userHoursTable, setUserHoursTable ] = useState<UserHoursTable>(defaultTable);

  const refreshUserHours = (month: BCMonthProps = BCMonth) => {
    const startDate = month.jointDays[0].day;
    const endDate = month.jointDays[month.jointDays.length - 1].day;
    const request = curatorOnlyView ? BCRequests.getCuratorAllUserHours : BCRequests.getAllUserHours;

    return request(user.userId, { startDate, endDate })
      .then((userHours: UsersHourResponse[]) => dispatch(setFlatUserHoursList(getFlatUserHoursList(userHours, month))));
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Control' && !isCtrlPressed && !curatorOnlyView) pressCtrl(true);
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Control' && !curatorOnlyView) pressCtrl(false);
    };

    if (preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL) {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [ preferences.webUiDrawingMode, isCtrlPressed ]);

  const [ BCMonth, isBCLoading ] = useGetBusinessCalendar({
    onBCUpdate: refreshUserHours,
    selectedDate: selectedDateObj.format(DEFAULT_DATE_FORMAT),
    userId: user?.userId,
  });

  useEffect(() => setUserHoursTable(getUserHoursTable(flatUserHoursTable, BCMonth)), [ flatUserHoursTable, user ]);
  useEffect(() => dispatch(changeSelectedDate(dayjs())), []);

  return (
    <div className={cx(S.timeSheet, { [S.isLoading]: isBCLoading })}>
      <TimeSheetSettings
        user={user}
        BCMonth={BCMonth}
        isBCLoading={isBCLoading}
        userHoursTable={userHoursTable}
        flatUserHoursTable={flatUserHoursTable}
        curatorOnlyView={curatorOnlyView}
      />

      <TimeSheetTable
        user={user}
        BCMonth={BCMonth}
        isBCLoading={isBCLoading}
        userHoursTable={userHoursTable}
        flatUserHoursTable={flatUserHoursTable}
        curatorOnlyView={curatorOnlyView}
      />

      <MarkedHourDrawer
        user={user}
        isLoading={replaceDrawer.isLoading}
        visible={replaceDrawer.isVisible}
        drawerData={replaceDrawer.data}
        mode={replaceDrawer.mode}
      />
      <ClosedMonthPopup
        selectedDateObj={selectedDateObj}
        isMonthClosed={isMonthClosed}
      />
    </div>
  );
};

export default TimeSheet;
