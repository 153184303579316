import React from 'react';
import classNames from 'classnames/bind';
import { useParams, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import { Redux } from 'helpers/types/_common';
import { AccessEnum } from 'components/Root/helpers/types';

import S from './helpers/styles.module.sass';
import SpecialistsTabs from './components/SpecialistsTabs';
import { SPECIALISTS_TABS } from './helpers/constants';
import FindSpecialistsTab from './components/FindSpecialists';
import RequestsTab from './components/Requests';
import RequestDetail from './components/Requests/components/RequestDetail';
import { PATH_FIND_SPECIALISTS } from '../../helpers/constants/_common/MenuCases';
import { SpecialistsURLParams } from './helpers/types';

const cx = classNames.bind(S);

const Specialists = () => {
  const { tab, specialistRequestId } = useParams<SpecialistsURLParams>();
  
  const user = useSelector((state: Redux) => state.login);

  const isProfilesViewerMode = user?.permissions.includes(AccessEnum.ProfilesViewer);

  const redirectToDefaultTab = () => {
    if (!tab) {
      return <Redirect to={`${PATH_FIND_SPECIALISTS}/${SPECIALISTS_TABS.find}`} />;
    }
  };

  const renderFindSpecialistsTab = () => {
    if (isProfilesViewerMode) {
      return <FindSpecialistsTab isProfilesViewerMode={isProfilesViewerMode} />;
    }
    
    if (specialistRequestId || !tab) {
      return null;
    }

    switch (tab) {
      case 'requests':
        return <RequestsTab />;

      case 'find':
      default:
        return <FindSpecialistsTab isProfilesViewerMode={isProfilesViewerMode} />;
    }
  };

  const renderRequestDetail = () => {
    if (!specialistRequestId || !tab) {
      return null;
    }

    return (
      <RequestDetail />
    );
  };

  const renderTabs = () => !isProfilesViewerMode && (
    <div className={cx('specialists_header')}>
      <SpecialistsTabs tab={tab} />
    </div>
  );

  return (
    <div className={cx('specialists')}>
      {redirectToDefaultTab()}
      {renderTabs()}
      {renderFindSpecialistsTab()}
      {renderRequestDetail()}
    </div>
  );
};

export default Specialists;
