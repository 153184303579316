import TokenStore from 'api/_request/helpers/jwtTokenStore';

import apiV2 from '../../api/_request';

const getPaginatedLogs = (data) => {
  const requestParams = {
    creation_date__gte: `${data.startDate}T00:00:00`,
    creation_date__lte: `${data.endDate}T23:59:59`,
    limit: data.limit,
    offset: (data.page - 1) * data.limit,
  };

  const { column, up } = data.sortBy;
  if (column === 'date') requestParams.order_by = up ? '-creation_date' : 'creation_date';
  if (column === 'subject') requestParams.order_by = up ? '-subject__username' : 'subject__username';
  if (column === 'doer') requestParams.order_by = up ? '-doer__username' : 'doer__username';
  if (column === 'action') requestParams.order_by = up ? '-type' : 'type';
  if (column === 'fromTo') requestParams.order_by = up ? '-from_to' : 'from_to';

  if (data.filter) {
    const { by, string } = data.filter;
    if (string !== '') {
      if (by === 'Doer') requestParams.doer__username__icontains = string;
      if (by === 'Subject') requestParams.subject__username__icontains = string;
      if (by === 'Action') requestParams.type__icontains = string;
    }
  }

  return apiV2.getRequest('/logs/', { params: requestParams, headers: { Authorization: TokenStore.getAccessToken() } })
    .then(response => ({
      meta: response.meta,
      objects: response.objects.map(log => ({
        doer: log.doerUsername,
        subject: log.subjectUsername,
        action: log.type,
        date: log.creationDate,
        previous: log.previous,
        current: log.current,
        fromTo: log.fromTo,
      })),
    }));
};

export default {
  getPaginatedLogs,
};
