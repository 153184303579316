export default () => ([
  {hex: '#ff371f'},
  {hex: '#c23861'},
  {hex: '#9924c6'},
  {hex: '#56347d'},
  {hex: '#2f5496'},
  {hex: '#1989e9'},
  {hex: '#ff6daa'},
  {hex: '#0097A7'},
  {hex: '#00685a'},
  {hex: '#1f7825'},
  {hex: '#00C853'},
  {hex: '#AFB42B'},
  {hex: '#E65100'},
  {hex: '#c57e00'},
  {hex: '#dd6c00'},
  {hex: '#388a73'},
  {hex: '#5D4037'},
  {hex: '#616161'},
  {hex: '#455A64'},
  {hex: '#827717'},
  {hex: '#33691E'}
])
