import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Select, Spin } from 'antd';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';
import selects from 'helpers/styles/components/selects.module.sass';

import { ReactComponent as SelectIcon } from '../../../../../../helpers/icons/SelectArrow.svg';
import { SkillTagSelectorProps } from './helpers/types';
import S from '../../helpers/styles.module.sass';
import { CategoryOptionsObject } from '../../../../../ProfileTabContent/components/SkillSet/components/SkillSetCategory/components/SkillSetExperience/components/SkillSearcher/helpers/types';
import { DispatchType } from '../../../../../../helpers/types/_common';
import { getSkillsByCategoryAndName } from '../../../../../../redux/SkillSet/action';
import useDebounce from '../../../../../../helpers/hooks/useDebounce';

const cx = classNames.bind(S);

const MergeIntoTargetTag: React.FC<SkillTagSelectorProps> = ({ onChange, value }) => {
  const [ optionsWithPagination, setOptionsWithPagination ] = useState<CategoryOptionsObject>(DEFAULT_SELECT_PAGINATION);
  const [ loading, setLoading ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>();
  const dispatch: DispatchType = useDispatch();
  const debounceSearchValue = useDebounce(searchValue, 500);

  const getSkills = (value?: string) => {
    setLoading(true);
    dispatch(getSkillsByCategoryAndName(value || ''))
      .then((options: CategoryOptionsObject) => setOptionsWithPagination(options))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      getSkills(debounceSearchValue);
    }
  }, [ debounceSearchValue ]);

  const options = _.map(optionsWithPagination.objects, skill => (
    <Select.Option
      className={cx('skill-tag-select_option')}
      key={skill.skillId}
      value={skill.skillId}
    >
      {skill.name}
    </Select.Option>
  ));

  const onFocus = () => {
    getSkills();
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (optionsWithPagination?.meta?.totalCount === optionsWithPagination?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(optionsWithPagination?.meta?.offset)
        ? optionsWithPagination.meta.offset + DEFAULT_SELECT_PAGINATION.meta.limit
        : 0;

      setLoading(true);
      dispatch(getSkillsByCategoryAndName(debounceSearchValue || '', undefined, increasedPageByOne))
        .then((options: CategoryOptionsObject) => {
          setOptionsWithPagination({
            meta: options.meta,
            objects: [ ...optionsWithPagination.objects, ...options.objects ],
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin
      spinning={loading}
    >
      {menu}
    </Spin>
  );

  return (
    <Select
      className={classNames(selects.qsSelect, S.mergeSelect)}
      placeholder="Select merge result..."
      onPopupScroll={onPopupScroll}
      onSearch={setSearchValue}
      onFocus={onFocus}
      searchValue={searchValue}
      dropdownRender={dropdownRender}
      filterOption={() => true}
      showSearch
      loading
      value={value}
      size="large"
      bordered={false}
      onSelect={onChange}
      suffixIcon={<SelectIcon />}
    >
      {options}
    </Select>
  );
};

export default MergeIntoTargetTag;
