import * as Actions from '../constants/ChangeTimesheet';
import _ from 'lodash';

const InitialState = {
  formatedPartners: [],
  partners: [],
  activities: [],
  openedFrom: false, //false - from timesheets, true - userhourreport
  start: new Date().toLocaleString('ja-JP'),
  end: new Date().toLocaleString('ja-JP'),
  reportDataChanged: false
};

function changeSinglePartner(state, payload) {
  const { partnerId, numInList } = payload;
  let newPartners = _.cloneDeep(state.formatedPartners);
  let newActivity = state.activities.find(activity => activity.partner === partnerId && activity.active);
  newPartners[numInList].newPartner = partnerId;
  newPartners[numInList].newActivity = newActivity.id;
  newPartners[numInList].newActivityColor = newActivity.color;
  newPartners[numInList].newPartnerColor = state.partners.find(e => e.id === partnerId).color;
  newPartners[numInList].changed = true;
  return { ...state, formatedPartners: newPartners};
}

function changeSingleActivity(state, payload) {
  const { activityId, numInList } = payload;
  let newPartners = _.cloneDeep(state.formatedPartners);
  newPartners[numInList].newActivity = activityId;
  newPartners[numInList].newActivityColor = state.activities.find(e => e.id === activityId).color;
  newPartners[numInList].newPartnerColor = newPartners[numInList].partnerColor;
  newPartners[numInList].changed = false;
  return { ...state, formatedPartners: newPartners};
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CTS_CHANGE_REPORT_TIMESHEETS:
      return { ...state, reportDataChanged: true };
    case Actions.CTS_END_DATA_CHANGING:
      return { ...state, reportDataChanged: false };
    case Actions.CTS_MODAL_OPENED_FROM:
     return { ...state, openedFrom: action.payload };
    case Actions.CTS_LOAD_ALL_PARTNERS:
      return { ...state, partners: action.payload };
    case Actions.CTS_LOAD_PARTNER_ACTIVITIES:
      return { ...state, activities: action.payload };
    case Actions.CTS_CHANGE_SINGLE_PARTNER:
      return changeSinglePartner(state, action.payload);
    case Actions.CTS_CHANGE_SINGLE_ACTIVITY:
      return changeSingleActivity(state, action.payload);
    case Actions.CTS_INIT_PARTNERS:
      return { ...state, formatedPartners: action.payload.formatedPartners };
    case Actions.CTS_CHANGE_TIME:
      return { ...state, start: action.payload.start, end: action.payload.end };
    default:
      return state;
  }
}

export default {
  InitialState,
  reducer
}
