import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Button } from 'antd';

import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import { EditableUserHourComment } from 'api/TimeSheet/types';

import S from './helpers/styles.module.sass';
import { UserHourCommentsProps, CommentEditingStates } from './helpers/types';
import PostedComment from './components/PostedComment/PostedComment';
import EditableComment from './components/EditableComment/EditableComment';
import { filterGroupCellsByCommentId, getAvailableTimeRangeToSelect, getGroupCellsByCellIndex, sortCommentsByTime } from './helpers/helpers';
import { getEditedComment } from '../GroupOfCellsContextMenu/helpers/helpers';

const cx = classNames.bind(S);

const UserHourComments: React.FC<UserHourCommentsProps> = (props) => {
  const {
    pureSelectedHours, isMonthClosed, cellObj, day, isCut,
    editableCommentsList, setEditableCommentsList, isCommentEditingInProgress,
    curatorOnlyView,
  } = props;

  const { rangeOfAvailableStartCells, rangeOfAvailableEndCells } = useMemo(() => getAvailableTimeRangeToSelect(pureSelectedHours), [ pureSelectedHours ]);
  const groupCellsByCellIndex = useMemo(() => getGroupCellsByCellIndex(cellObj.groupCells), [ cellObj.groupCells ]);
  const filteredByCommentId = useMemo(() => filterGroupCellsByCommentId(groupCellsByCellIndex), [ groupCellsByCellIndex ]);
  const sortedCommentsByTime = useMemo(() => sortCommentsByTime(filteredByCommentId), [ filteredByCommentId ]);
  const filteredByEditingOne = useMemo(() => {
    const editableComment = getEditedComment(editableCommentsList);

    if (editableComment) return [ editableComment ];
    return editableCommentsList;
  }, [ editableCommentsList ]);

  const isNotAllCommentsAreAdded = useMemo(() => Boolean(_.filter(groupCellsByCellIndex, cell => _.isNil(cell.comment)).length), [ groupCellsByCellIndex ]);
  const isAddCommentAvailable = isNotAllCommentsAreAdded && !isCommentEditingInProgress && !isMonthClosed && !isCut;

  useEffect(() => {
    const currentCommentList = sortedCommentsByTime.map(comment => ({ ...comment, state: CommentEditingStates.None }));

    setEditableCommentsList(currentCommentList, currentCommentList);
  }, [ sortedCommentsByTime ]);

  const addComment = () => {
    const isSomeCommentAlreadyExists = !_.isEmpty(_.pickBy(pureSelectedHours, usersHour => !_.isNil(usersHour.comment)));
    const { startCellIndex, endCellIndex } = _.reduce(pureSelectedHours, (result, usersHour) => {
      const cellStartIndex = getDateAndCellIndexByTime(usersHour.startTime).cellIndex as number;
      const cellEndIndex = getDateAndCellIndexByTime(usersHour.endTime).cellIndex as number;

      const isStartTimeRecorded = !_.isNil(result.startCellIndex);
      const isCommentExists = !_.isNil(usersHour.comment);

      if (isSomeCommentAlreadyExists) {
        const isRecordedStartTimeMoreThenNewOne = !_.isNil(result.startCellIndex) && result.startCellIndex > cellStartIndex;
        if ((isRecordedStartTimeMoreThenNewOne || !isStartTimeRecorded) && !isCommentExists) {
          return {
            ...result,
            startCellIndex: cellStartIndex,
            endCellIndex: cellEndIndex,
          };
        } else {
          return result;
        }
      } else {
        switch (true) {
          case !isCommentExists && !result.enough: {
            const correctEntCellIndex = cellEndIndex === 0 ? 48 : cellEndIndex;

            return {
              ...result,
              startCellIndex: !_.isNil(result.startCellIndex) && result.startCellIndex < cellStartIndex ? result.startCellIndex : cellStartIndex,
              endCellIndex: !_.isNil(result.endCellIndex) && result.endCellIndex > correctEntCellIndex ? result.endCellIndex : correctEntCellIndex,
            };
          }
          case isCommentExists:
            return { ...result, enough: true };
          default:
            return result;
        }
      }
    }, { startCellIndex: null, endCellIndex: null, enough: false } as { startCellIndex: number | null, endCellIndex: number | null, enough: boolean });

    const updatedComment = [
      ...editableCommentsList,
      {
        commentId: 'new',
        comment: '',
        startCellIndex: _.isNil(startCellIndex) ? 0 : startCellIndex,
        endCellIndex: _.isNil(endCellIndex) ? 1 : endCellIndex,
        state: CommentEditingStates.Active,
      },
    ];

    setEditableCommentsList(updatedComment, [ ...editableCommentsList ]);
  };

  const setEditedComment = (editedComment: EditableUserHourComment) => {
    const updatedCommentsList = _.reduce(editableCommentsList, (result, comment) => {
      switch (true) {
        case comment.commentId === editedComment.commentId:
          return [ ...result, {
            ...comment,
            ...editedComment,
          } ];
        default:
          return [ ...result, comment ];
      }
    }, [] as EditableUserHourComment[]);

    setEditableCommentsList(updatedCommentsList);
  };

  return (
    <div
      id="comments"
      className={cx(S.groupPopoverCommentsInner, {
        [S.noComments]: filteredByEditingOne.length === 0,
      })}
    >
      <div>
        {_.map(filteredByEditingOne, comment => (((comment.state === CommentEditingStates.None) || curatorOnlyView) ? (
          <PostedComment
            key={comment.commentId}
            day={day}
            isMonthClosed={isMonthClosed}
            isCut={isCut}
            commentData={comment}
            editableCommentsList={editableCommentsList}
            setEditableCommentsList={setEditableCommentsList}
            curatorOnlyView={curatorOnlyView}
          />
        ) : (
          <EditableComment
            key={comment.commentId}
            commentData={comment}
            setEditedComment={setEditedComment}
            groupCellsByCellIndex={groupCellsByCellIndex}
            rangeOfAvailableStartCells={rangeOfAvailableStartCells}
            rangeOfAvailableEndCells={rangeOfAvailableEndCells}
          />
        )))}

        {(isAddCommentAvailable && !curatorOnlyView) && (
          <Button
            size="small"
            className={S.addCommentButton}
            onClick={addComment}
          >
            Add comment
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserHourComments;
