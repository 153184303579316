import { AxiosError } from 'axios';

import * as Actions from 'helpers/constants/_common/actions/SkillSet';
import * as LoginActions from 'V1/constants/Login';
import Subtypes from 'V1/constants/__common__/Subtypes';
import { DispatchType } from 'helpers/types/_common';
import skillRequests, { getUserSkillsRecursively } from 'api/SkillSet/requests';
import { UserSkill, SkillCategoryEnum } from 'helpers/types/Skills';
import { CreatedSkills, UserSkillToUpdate } from 'api/SkillSet/types';
import userRequests from 'api/PersonalInfo/requests';
import { UserSkillOutput } from 'api/User/types';

import {
  CommonError,
  UpdateUsersSkillsResponseError,
  EnglishKnowledgeField,
} from './actionTypes';

export const getUserSkillsByUserId = (userId: number) => (dispatch: DispatchType) => {
  dispatch({ type: Actions.GET_USER_SKILLS_BY_USER_ID, subtype: Subtypes.Request.Loading });

  getUserSkillsRecursively(userId, { limit: 100 })
    .then((objects: UserSkillOutput[]) => {
      dispatch({
        type: Actions.GET_USER_SKILLS_BY_USER_ID,
        subtype: Subtypes.Request.Success,
        payload: {
          objects,
        },
      });
    })
    .catch((error: AxiosError<CommonError>) => {
      dispatch({
        type: Actions.GET_USER_SKILLS_BY_USER_ID,
        subtype: Subtypes.Request.Error,
        payload: error.response,
      });

      throw error.response;
    });
};

export function updateUsersSkills(userId: number, updatedUserSkills: UserSkill[]) {
  return (dispatch: DispatchType) => {
    dispatch({ type: Actions.UPDATE_USERS_SKILLS, subtype: Subtypes.Request.Loading });

    return skillRequests.updateUserSkills(userId, updatedUserSkills)
      .then((res: CreatedSkills) => {
        dispatch({
          type: Actions.UPDATE_USERS_SKILLS,
          subtype: Subtypes.Request.Success,
          payload: res,
        });
        dispatch(getUserSkillsByUserId(userId));

        return res;
      })
      .catch((error: AxiosError<UpdateUsersSkillsResponseError>) => {
        dispatch({
          type: Actions.UPDATE_USERS_SKILLS,
          subtype: Subtypes.Request.Error,
          payload: error?.response?.data,
        });

        throw error;
      });
  };
}

export function updateUserSkill(userId: number, userSkillToUpdate: UserSkillToUpdate) {
  return (dispatch: DispatchType) => {
    dispatch({ type: Actions.UPDATE_USERS_SKILLS, subtype: Subtypes.Request.Loading });

    return skillRequests.updateUserSkill(userId, userSkillToUpdate)
      .then((res: CreatedSkills) => {
        dispatch({
          type: Actions.UPDATE_USERS_SKILLS,
          subtype: Subtypes.Request.Success,
          payload: res,
        });

        return res;
      })
      .catch((error: AxiosError<UpdateUsersSkillsResponseError>) => {
        dispatch({
          type: Actions.UPDATE_USERS_SKILLS,
          subtype: Subtypes.Request.Error,
          payload: error.response,
        });

        throw error.response;
      });
  };
}

export const getSkillsByCategoryAndName = (changedValue: string, skillCategoryName?: SkillCategoryEnum, page?: number, limit?: number) => () => skillRequests.getSkillsByCategoryAndName(changedValue, skillCategoryName, page, limit);

export const updateEnglishExperience = (fields: EnglishKnowledgeField, userId: number, editByAdmin?: boolean) => (dispatch: DispatchType) => {
  if (editByAdmin) {
    return userRequests.saveEmployeePersonalInfoFields(fields, userId)
      .catch(error => Promise.reject(error?.response?.data));
  }

  return userRequests.savePersonalInfoFields(fields, userId)
    .then((response) => {
      dispatch({
        type: LoginActions.UPDATE_USER_DATA,
        payload: fields,
      });

      return response;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
