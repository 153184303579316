import _ from 'lodash';
import React from 'react';
import dayjs from 'dayjs';

import OverdueBadge from '..';
import { EmployeeFilterOverdueItem, EMPLOYEES_FILTER_OVERDUE, EMPLOYEES_TIMESHEET_FILTER_OVERDUE } from '../../../../helpers/constants/Employees/employeesConstants';
import { EMPLOYEE_OVERDUE_RANGES, EMPLOYEE_TIMESHEET_OVERDUE_RANGES } from '../../../../api/User/constants';

export const renderSkillUpdatedDays = (date?: string) => {
  if (_.isNull(date) || _.isUndefined(date)) {
    return (
      <span>
        <OverdueBadge color="#611B27" />
        <span>Never</span>
      </span>
    );
  }

  const days = dayjs().diff(date, 'd');

  if (days === 0) {
    return (
      <span>
        <OverdueBadge color="#60BF7A" />
        <span>{'<1d ago'}</span>
      </span>
    );
  }

  const renderCell = (badgeData: EmployeeFilterOverdueItem) => (
    <span>
      <OverdueBadge color={badgeData.badgeColor} ghosted={badgeData.ghosted} />
      <span>{`${days}d ago`}</span>
    </span>
  );

  if (_.inRange(days, 0, 30)) {
    const badgeData = EMPLOYEES_FILTER_OVERDUE[EMPLOYEE_OVERDUE_RANGES.lessThan30];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 30, 60)) {
    const badgeData = EMPLOYEES_FILTER_OVERDUE[EMPLOYEE_OVERDUE_RANGES.lessThan60];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 60, 90)) {
    const badgeData = EMPLOYEES_FILTER_OVERDUE[EMPLOYEE_OVERDUE_RANGES.lessThan90];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 90, 120)) {
    const badgeData = EMPLOYEES_FILTER_OVERDUE[EMPLOYEE_OVERDUE_RANGES.lessThan120];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 120, 99999)) {
    const badgeData = EMPLOYEES_FILTER_OVERDUE[EMPLOYEE_OVERDUE_RANGES.moreThan120];
    return renderCell(badgeData);
  }
};

export const renderTimesheetUpdatedDays = (date?: string) => {
  if (_.isNull(date) || _.isUndefined(date)) {
    return (
      <span>
        <OverdueBadge color="#611B27" />
        <span>Never</span>
      </span>
    );
  }

  const days = dayjs().diff(date, 'd');

  if (days === 0) {
    return (
      <span>
        <OverdueBadge color="#60BF7A" />
        <span>{'<1d ago'}</span>
      </span>
    );
  }

  const renderCell = (badgeData: EmployeeFilterOverdueItem) => (
    <span>
      <OverdueBadge color={badgeData.badgeColor} ghosted={badgeData.ghosted} />
      <span>{`${days}d ago`}</span>
    </span>
  );

  if (_.inRange(days, 0, 6)) {
    const badgeData = EMPLOYEES_TIMESHEET_FILTER_OVERDUE[EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan5];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 6, 11)) {
    const badgeData = EMPLOYEES_TIMESHEET_FILTER_OVERDUE[EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan10];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 11, 16)) {
    const badgeData = EMPLOYEES_TIMESHEET_FILTER_OVERDUE[EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan15];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 16, 21)) {
    const badgeData = EMPLOYEES_TIMESHEET_FILTER_OVERDUE[EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan20];
    return renderCell(badgeData);
  }

  if (_.inRange(days, 21, 99999)) {
    const badgeData = EMPLOYEES_TIMESHEET_FILTER_OVERDUE[EMPLOYEE_TIMESHEET_OVERDUE_RANGES.moreThan20];
    return renderCell(badgeData);
  }
};
