import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { CuratorPartnerDetailedReports } from 'api/Curators/types';

import S from './styles.module.sass';
import { ReplacerData } from './types';

interface SelectorPartnerFromProps {
  isLoading: boolean;
  replaceData: ReplacerData;
  currentStep: number;
  partnersReports: CuratorPartnerDetailedReports;
  stepToggle: (dir: 'next' | 'prev') => void;
  setReplaceData: (data: ReplacerData) => void;
}

const SelectorPartnerFrom: React.FC<SelectorPartnerFromProps> = ({ isLoading, replaceData, setReplaceData, currentStep, stepToggle, partnersReports }) => {
  const onChange = (partnerId: number) => {
    setReplaceData({
      ...replaceData,
      partnerFrom: _.find(partnersReports.partners, report => report.partner.partnerId === partnerId) || null,
      activityFrom: null,
      userReports: [],
    });
    if (currentStep === 0) stepToggle('next');
  };

  return (
    <Select
      className={classNames(selects.qsSelect, S.reportSelect)}
      suffixIcon={isLoading ? <LoadingOutlined className={S.spinner} spin /> : <SelectIcon />}
      size="large"
      bordered={false}
      loading={isLoading}
      value={replaceData.partnerFrom?.partner.partnerId || null}
      onChange={onChange}
    >
      <Select.Option
        value={null}
        disabled
      >
        Pick a partner
      </Select.Option>
      {_.map(_.sortBy(partnersReports.partners, report => report.partner.name), report => (
        <Select.Option
          key={report.partner.partnerId + report.partner.name}
          value={report.partner.partnerId}
          className={S.option}
        >
          <span>{report.partner.name}</span>
          {`${report.hours}h`}
        </Select.Option>
      ))}
    </Select>
  );
};
export default SelectorPartnerFrom;
