import { BusinessCalendarRedux } from 'redux/BusinessCalendar/types';

import configuredStore from '../../redux/_reducer/configureStore';
import { NotificationsRedux } from '../../api/Notifications/types';
import { ErrorHandlerError } from '../../components/ErrorHandler/helpers/types';
import {
  ManageSkillTagsTable,
  FindSpecialistsTable,
  RequestCandidatesTable,
  RequestSpecialistsTable,
  EmployeesTable,
  PartnersTable,
  PartnerParticipantsTable,
  VacationReportTable,
  HourReportTable,
  EntitiesTable,
  CuratorHourReportTable,
  UserHourReportsTable,
  PartnersUsersTable,
  BookkeepingItemsTable,
} from '../../redux/Tables/reducerTypes';
import { UserRedux } from '../../redux/User/types';
import { TimeSheetRedux } from '../../redux/TimeSheet/types';
import { API } from '../../api/_request';

export interface Occupation {
  occupationId: number;
  name: string;
  description: string;
}
export type ValueOf<T> = T[keyof T];

export type ConstantType<T> = { [ key: string ]: T };

export type ApiType = API;

export type ElementType<
  T extends ReadonlyArray<unknown>,
> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export interface ActionDataProps {
  payload: any;
  type: string;
}

export interface Modal {
  show: boolean;
  isLoading: boolean;
  errorMessage: string;
}

export interface ResponseMeta {
  totalCount: number;
  limit: number | null;
  offset: number | null;
  previous: string;
  next: string;
}

export interface Redux {
  login: UserRedux;
  timeSheet: TimeSheetRedux;
  timeTracker?: any;
  partners?: any;
  mainTable?: any;
  hourReport?: any;
  coratorHourReport?: any;
  usersHourReport?: any;
  users?: any;
  managePartners?: any;
  tableOverlay?: any;
  selection?: any;
  changeTimesheet?: any;
  logs?: any;
  etities?: any;
  globalSearch?: any;
  occupation?: any;
  skillsetV1?: any;
  skillset?: any;
  businessCalendar?: any;
  businessCalendar2: BusinessCalendarRedux;
  hotProject?: any;
  vacancies?: any;
  notifications: NotificationsRedux;
  tables: TablesRedux;
  errorHandler: ErrorHandlerError;
}

export type DispatchType = typeof configuredStore.dispatch;
export type GetState = () => Redux;

export interface TablesRedux {
  manageSkillTags: ManageSkillTagsTable;
  findSpecialists: FindSpecialistsTable;
  requestCandidates: RequestCandidatesTable;
  requestSpecialists: RequestSpecialistsTable;
  employees: EmployeesTable;
  bookkeepingItems: BookkeepingItemsTable;
  partners: PartnersTable;
  partnersUsers: PartnersUsersTable;
  entities: EntitiesTable;
  partnerParticipants: PartnerParticipantsTable;
  vacationReport: VacationReportTable;
  hourReport: HourReportTable;
  curatorHourReport: CuratorHourReportTable;
  userHourReports: UserHourReportsTable;
}

export type ValidationError = {
  field: string | null;
  error: string;
  value: string | null;
};

export type BadRequestError = {
  [fieldKey: string]: string | string[];
};

export type SubMenuSearchParams = {
  tab?: string;
  userId?: string;
};

export enum EnglishLevelEnum {
  Zero = 'ZERO',
  Googler = 'GOOGLER',
  WrittenOnly = 'WRITTEN_ONLY',
  Confident = 'CONFIDENT',
  Level80 = 'LEVEL_80',
}

export interface ValidationErrorResponse {
  [key: string]: string[];
}
