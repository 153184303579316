import { SearchItemBody, SearchItemPage } from './types';
import { api } from '../../index';
import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';

const COMMON_REQUESTS = 'COMMON_REQUESTS';

export const searchByAnyItem = async (body: SearchItemBody) => api.get<SearchItemPage>('/items/search/', {
  ...convertObjectKeysToLanguageCase(body, 'snakeCase'),
  active: true,
  order_by: 'name',
}, {
  requestId: COMMON_REQUESTS,
});
