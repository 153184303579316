import React from 'react';
import classNames from 'classnames/bind';

import S from '../styles.module.css';

const cx = classNames.bind(S);

interface LayoutContainerComponentProps {
  fluid?: boolean;
  responsive?: boolean;
  className?: string;
  style?: React.CSSProperties;
  withoutPadding?: boolean;
}

export default class Container extends React.Component<
LayoutContainerComponentProps,
{}
> {
  render(): React.ReactNode {
    const {
      children,
      fluid,
      responsive,
      className,
      style,
      withoutPadding,
    } = this.props;

    const containerClass = cx(
      {
        'qs-layout__container': true,
        'qs-layout__container--without-padding': withoutPadding,
        'qs-layout__container--responsive': responsive,
        'qs-layout__container--fluid': fluid,
      },
      className,
    );

    return (
      <div className={containerClass} style={style}>
        {children}
      </div>
    );
  }
}
