import _ from 'lodash';
import { Checkbox, Input, Popover, Select, Space, Divider, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import buttons from 'helpers/styles/components/buttons.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';

import SKILL_CATEGORIES from '../../../../../../helpers/constants/Skills/Categories';
import { ReactComponent as SelectIcon } from '../../../../../../helpers/icons/SelectArrow.svg';
import S from './helpers/styles.module.sass';
import { AddNewSkillTagProps } from './helpers/types';
import { DispatchType } from '../../../../../../helpers/types/_common';
import { createSkillTag } from '../../../../../../redux/SkillTags/actions';

const cx = classNames.bind(S);

const AddNewSkillTag: React.FC<AddNewSkillTagProps> = ({ onSaveCallback }) => {
  const dispatch: DispatchType = useDispatch();
  const inputRef = useRef<Input>(null);

  const [ name, setName ] = useState<string>();
  const [ category, setCategory ] = useState<string>();
  const [ approved, setApproved ] = useState(true);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);

  const options = _.map(SKILL_CATEGORIES, (s, key) => (
    <Select.Option key={s.categoryId} value={key}>{s.title}</Select.Option>
  ));

  const renderError = () => {
    if (!error) {
      return null;
    }

    return (
      <small className={cx('error-message')}>{error}</small>
    );
  };

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      setName(undefined);
      setCategory(undefined);
      setApproved(true);
      setError(null);
    }

    setPopoverVisible(visible);
  };

  const handleOnSave = () => {
    if (!category || !name) {
      return;
    }

    const body = {
      approved,
      category,
      name,
    };
    setSaveLoading(true);

    dispatch(createSkillTag(body))
      .then(() => {
        onSaveCallback();
        setName('');
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      })
      .catch((error: any) => {
        setError(error?.response?.data?.error);
      })
      .finally(() => setSaveLoading(false));
  };

  const content = (
    <Space
      direction="vertical"
      size={10}
    >

      <Input
        ref={inputRef}
        value={name}
        onChange={(e) => {
          setError(null);
          setName(e.currentTarget.value);
        }}
        onPressEnter={handleOnSave}
        className={inputs.qsInput}
        placeholder="Type tag name to add..."
        size="large"
        maxLength={31}
        allowClear
        autoFocus
      />
      <div>
        <Select
          value={category}
          onChange={value => setCategory(value)}
          size="large"
          placeholder="Select category"
          suffixIcon={<SelectIcon />}
          className={cx(selects.qsSelect, S.manageSkillsNewTag)}
          dropdownClassName={selects.selectDropdown}
          bordered={false}
          allowClear
        >
          {options}
        </Select>
        <Checkbox
          onChange={e => setApproved(e.target.checked)}
          checked={approved}
        >
          Approved
        </Checkbox>
      </div>
      {renderError()}

      <Divider />

      <div className={cx('button-wrapper')}>
        <p className={cx('button-annotation')}>
          You can also tap
          <b>Enter</b>
          to add an item
        </p>
        <Button
          type="primary"
          className={buttons.qsButtonPrimary}
          loading={saveLoading}
          onClick={handleOnSave}
          disabled={!name || !category}
        >
          Save
        </Button>
      </div>

    </Space>
  );

  return (
    <Popover
      align={{ offset: [ -1, 0 ] }}
      overlayClassName={cx('add-new-popover')}
      placement="bottomRight"
      content={content}
      trigger="click"
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    >
      <Button
        className={cx(buttons.qsButtonPrimary, { 'manage-tag-panel_button--popover-visible': popoverVisible })}
        type="primary"
        size="large"
      >
        <div className={buttons.iconTextWrapper}>
          <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
          <span>Add new</span>
        </div>
      </Button>
    </Popover>
  );
};

export default AddNewSkillTag;
