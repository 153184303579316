import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Spin } from 'antd';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';

import { DEFAULT_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import {
  getRequestCandidates,
  getRequestInfo,
  getRequestSkills,
  getRequestSpecialists,
  setCandidatesStatus,
  setSkillsExperienceParams,
  setRequestSpecialistsInfo,
  removeCandidatesStatus, resetSkillColumnSorter,
} from 'redux/Specialists/actions';
import { DispatchType, EnglishLevelEnum, Redux } from 'helpers/types/_common';
import { SpecialistRequestExtraDetails, SRStatuses } from 'api/Specialists/types';
import { Meta, Sorter } from 'api/_request/types';

import S from './helpers/styles.module.sass';
import { RequestSettingsChangeObject, RequestSettingsState } from './helpers/types';
import CandidatesTable from './components/CandidatesTable';
import RequestTable from './components/RequestTable';
import RequestSettings from './components/RequestSettings';
import { SpecialistsSkills, SpecialistsURLParams } from '../../../../helpers/types';
import { onClearPromises } from './components/RequestSettings/helpers/helpers';

const cx = classNames.bind(S);

const RequestDetail: React.FC = () => {
  const dispatch: DispatchType = useDispatch();
  const { specialistRequestId } = useParams<SpecialistsURLParams>();

  const { sorter: requestSorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.REQUEST_SPECIALISTS]);
  const { sorter: candidateSorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.REQUEST_CANDIDATES]);

  const [ loading, setLoading ] = useState(false);
  const [ skillsLoading, setSkillsLoading ] = useState(false);
  const [ settings, setSettings ] = useState<RequestSettingsState>({ skills: {}, skillsColumns: {}, userId: [], partnerId: [], entityId: [] });

  const isViewMode = settings?.requestData?.status === SRStatuses.Staffed;

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    if (!specialistRequestId) {
      return;
    }

    setLoading(true);

    Promise.all([
      getRequestInfo(specialistRequestId),
      getRequestSkills(specialistRequestId),
    ])
      .then((values: [SpecialistRequestExtraDetails, SpecialistsSkills]) => {
        const infoResponse = values[0];
        const skillsResponse = values[1];

        setSettings({
          requestData: infoResponse,
          skills: skillsResponse,
          skillsColumns: skillsResponse,
        });
      })
      .finally(() => setLoading(false));
  };

  const fetchRequestSpecialists = (pagination: Meta, sorter?: Sorter) => {
    if (!specialistRequestId) {
      return;
    }

    const body = {
      englishKnowledge: settings?.requestData?.englishKnowledge,
      fullnameContains: settings?.searchValue,
      usernameContains: settings?.searchValue,
      emailAddressContains: settings?.searchValue,
      userId: settings?.userId,
      partnerId: settings?.partnerId,
      entityId: settings?.entityId,
      specialistRequestId,
      active: true,
      ...pagination,
      ...sorter,
    };
    return dispatch(getRequestSpecialists(body, TABLE_NAMES.REQUEST_SPECIALISTS));
  };

  const fetchRequestCandidates = (pagination: Meta, sorter?: Sorter) => {
    if (!specialistRequestId) {
      return;
    }

    const body = {
      usersActive: true,
      specialistRequestId,
      ...pagination,
      ...sorter,
    };
    return dispatch(getRequestCandidates(body, TABLE_NAMES.REQUEST_CANDIDATES));
  };

  const handleAddToCandidate = (userId: number) => {
    if (!specialistRequestId) {
      return;
    }

    const body = {
      userId,
      specialistRequestId,
    };

    return dispatch(setCandidatesStatus(body))
      .then(() => {
        fetchRequestSpecialists(DEFAULT_PAGINATION_RAW);
        fetchRequestCandidates(DEFAULT_PAGINATION_RAW);
      });
  };

  const handleRemoveFromCandidate = (userId: number) => {
    if (!specialistRequestId) {
      return;
    }

    const body = {
      userId,
      specialistRequestId,
    };

    return dispatch(removeCandidatesStatus(body))
      .then(() => {
        fetchRequestSpecialists(DEFAULT_PAGINATION_RAW);
        fetchRequestCandidates(DEFAULT_PAGINATION_RAW);
      });
  };

  const onExperienceBlur = (updatedSkills: SpecialistsSkills) => {
    if (_.isEqual(settings.skillsColumns, updatedSkills) || !specialistRequestId) {
      return;
    }

    setSkillsLoading(true);

    setSkillsExperienceParams(updatedSkills, specialistRequestId)
      .then(() => {
        if (requestSorter?.orderBy && updatedSkills && !updatedSkills[Number(requestSorter.orderBy)]) {
          dispatch(resetSkillColumnSorter(TABLE_NAMES.REQUEST_SPECIALISTS));
        }

        if (candidateSorter?.orderBy && updatedSkills && !updatedSkills[Number(candidateSorter.orderBy)]) {
          dispatch(resetSkillColumnSorter(TABLE_NAMES.REQUEST_CANDIDATES));
        }

        setSettings({
          ...settings,
          skills: {
            ...updatedSkills,
          },
          skillsColumns: {
            ...updatedSkills,
          },
        });
      })
      .catch(() => {
        setSettings({
          ...settings,
          skills: {
            ...settings.skillsColumns,
          },
        });
      })
      .finally(() => setSkillsLoading(false));
  };

  const onChangeValue = (value: RequestSettingsChangeObject) => {
    setSettings({
      ...settings,
      ...value,
    });
  };

  const onChangeEnglishKnowledge = (value: string) => {
    if (!specialistRequestId) {
      return;
    }

    setLoading(true);

    setRequestSpecialistsInfo({ englishKnowledge: value }, specialistRequestId)
      .then(() => {
        if (settings.requestData) {
          setSettings({
            ...settings,
            requestData: {
              ...settings.requestData,
              englishKnowledge: value,
            },
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onChangeExperienceSettings = (value: SpecialistsSkills) => {
    setSettings({
      ...settings,
      skills: {
        ...value,
      },
    });
  };

  const onClear = () => {
    if (!specialistRequestId) {
      return;
    }

    const promiseAllValues = onClearPromises(specialistRequestId, settings?.requestData?.englishKnowledge, settings.skills);

    if (_.isEmpty(promiseAllValues)) {
      return;
    }

    dispatch(resetSkillColumnSorter(TABLE_NAMES.REQUEST_SPECIALISTS));
    dispatch(resetSkillColumnSorter(TABLE_NAMES.REQUEST_CANDIDATES));

    setLoading(true);
    Promise.all(_.map(promiseAllValues, promiseFunc => promiseFunc()))
      .then(() => {
        setSettings({
          requestData: {
            ...settings.requestData,
            englishKnowledge: EnglishLevelEnum.Zero,
          },
          searchValue: undefined,
          skills: {},
          skillsColumns: {},
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={cx('request-detail')}>
      <Spin spinning={loading}>
        <Spin spinning={skillsLoading}>
          <RequestSettings
            loading={loading}
            settings={settings}
            onChangeValue={onChangeValue}
            onChangeEnglishKnowledge={onChangeEnglishKnowledge}
            onChangeExperience={onChangeExperienceSettings}
            onExperienceBlur={onExperienceBlur}
            onClear={onClear}
            isViewMode={isViewMode}
          />
        </Spin>

        <CandidatesTable
          settings={settings}
          handleRemoveFromCandidate={handleRemoveFromCandidate}
          fetchRequestCandidates={fetchRequestCandidates}
          isViewMode={isViewMode}
        />

        <RequestTable
          settings={settings}
          handleAddToCandidate={handleAddToCandidate}
          fetchRequestSpecialists={fetchRequestSpecialists}
          isViewMode={isViewMode}
        />
      </Spin>
    </div>

  );
};

export default RequestDetail;
