import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { MAIN_THEME } from '../../helpers/constants/_common/localStorageConstants';
import { getStringFromLocalStorage, setLocalStorage } from '../../helpers/utils/localStorageService';
import { applyPrimaryColors } from './themes';
import { Themes } from '../../helpers/constants/_common/constants';
import { DispatchType, Redux } from '../../helpers/types/_common';
import { UITheme } from '../../redux/User/types';
import { updateUserPreferences } from '../../redux/User/actions';

export const ThemeContext = React.createContext({
  theme: Themes.LIGHT,
  setTheme: (theme: string) => {},
});

const AppThemeWrapper: React.FC = ({ children }) => {
  const dispatch: DispatchType = useDispatch();
  const { preferences } = useSelector((state: Redux) => state.login);

  const changeThemeRequest = useMemo(() => _.debounce((theme: UITheme) => {
    dispatch(updateUserPreferences({ uiTheme: _.toUpper(theme) as UITheme }));
  }, 1500), []);

  const onChangeTheme = (theme: UITheme) => {
    setLocalStorage(MAIN_THEME, theme);
    setTheme(prevTheme => ({ ...prevTheme, theme }));

    changeThemeRequest(theme);
  };

  useEffect(() => {
    if (preferences.uiTheme && (_.toLower(preferences.uiTheme) !== theme.theme)) {
      onChangeTheme(_.toLower(preferences.uiTheme) as UITheme);
    }
  }, [ preferences.uiTheme ]);

  const defaultTheme: UITheme = _.toLower(preferences.uiTheme) || getStringFromLocalStorage(MAIN_THEME, Themes.LIGHT);

  const initTheme = {
    theme: defaultTheme,
    setTheme: onChangeTheme,
  };

  const [ theme, setTheme ] = useState(initTheme);

  useEffect(() => {
    const html = document?.querySelector('html');

    if (theme.theme === Themes.DARK) {
      document.body.setAttribute('data-theme', theme.theme);
      html?.style.setProperty('color-scheme', theme.theme);
      html?.style.setProperty('background-color', '#1B2228');
    } else {
      document.body.setAttribute('data-theme', theme.theme);
      html?.style.setProperty('color-scheme', theme.theme);
      html?.style.setProperty('background-color', '#F2F4F6');
      html?.style.removeProperty('color-scheme');
    }

    applyPrimaryColors(theme.theme);
  }, [ theme ]);

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export default AppThemeWrapper;
