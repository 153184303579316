import React, { Component } from 'react';
import Table from '../../../../../_shared/Table';
import S from './styles.module.css';

class LogsTable extends Component {
  renderTableBody() {
    if (this.props.logs.data.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan='7' style={{ textAlign: 'center' }}>
            No data to display
          </Table.Cell>
        </Table.Row>
      );
    }

    return this.props.logs.data.map((log, i) => (
      <Table.Row key={i}>
        <Table.Cell className={S.tdStyle}>{log.doer}</Table.Cell>
        <Table.Cell className={S.tdStyle}>{log.subject}</Table.Cell>
        <Table.Cell className={S.tdStyle}>{log.action}</Table.Cell>
        <Table.Cell className={S.tdStyle} style={{minWidth: '160px'}}>
          <small style={{opacity: '.5'}}>{log.fromTo}</small>
        </Table.Cell>
        <Table.Cell className={S.tdStyle}>{log.previous}</Table.Cell>
        <Table.Cell className={S.tdStyle}>{log.current}</Table.Cell>
        <Table.Cell className={S.tdStyle}>
          <small style={{opacity: '.5'}}>{new Date(log.date + 'Z').toLocaleString('ja-JP', { timeZone: 'UTC' })}</small>
        </Table.Cell>
      </Table.Row>
    ));
  }

  changeSortBy(e, sortOrder) {
    const { startDate, endDate, amountOfRows, filter } = this.props.logs;

    let requestData = {
      startDate,
      endDate,
      limit: amountOfRows,
      page: 1,
      sortBy: {
        column: e.currentTarget.id,
        up: sortOrder === 'desc'
      },
      filter
    };

    this.props.logsActions.changeSortBy(requestData);
  }

  renderTableHead() {
    const { sortBy } = this.props.logs;

    let cols = [
      { id: 'doer', sort: true, defaultSort: 'asc', width: '17%', name: 'Doer' },
      { id: 'subject', sort: true, defaultSort: 'asc', width: '17%', name: 'Subject' },
      { id: 'action', sort: true, defaultSort: 'asc', width: '6%', name: 'Action' },
      { id: 'fromTo', sort: true, defaultSort: 'desc', width: '16%', name: 'Time period' },
      { id: 'prev', sort: false, width: '12%', name: 'Previous' },
      { id: 'current', sort: false, width: '20%', name: 'Current' },
      { id: 'date', sort: true, defaultSort: 'desc', width: '10%', name: 'Date' },
    ];

    return cols.map((col, i) => (
      <Table.HeaderCell
        key={i}
        id={col.id}
        type={'text'}
        sortable={col.sort}
        sortingMode={(col.sort && 'multi') || undefined}
        selected={col.sort && (sortBy.column === col.id)}
        sortingOrder={(col.sort && (sortBy.column === col.id) && (sortBy.up ? 'desc' : 'asc')) || undefined}
        defaultSortingOrder={(col.sort && col.defaultSort) || undefined}
        width={col.width}
        onClick={(col.sort && this.changeSortBy.bind(this)) || undefined}
      >
        {col.name}
      </Table.HeaderCell>
    ), this);
  }

  render() {
    return (
      <Table preloader={{isLoading: this.props.isLoading, rows: 7, columns: 7}}>
        <Table.Header>
          <Table.Row>
            {this.renderTableHead()}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderTableBody()}
        </Table.Body>
      </Table>
    );
  }
}

export default LogsTable;
