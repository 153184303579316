import _ from 'lodash';

import { GroupsOfCellsByCellIndex } from 'components/TimeSheet/helpers/types';

interface DoIntersectionsCheckResult {
  isIntersectionDetected: boolean;
}

interface GetLatestEndCellIndexResult {
  newEndTimeCell: number | null;
  enough: boolean;
}

interface GetEarliestStartCellIndexResult {
  newStartTimeCell: number | null;
  enough: boolean;
}

/** Checks if comments are already existed between start and end cell indexes. */
export const doIntersectionsCheck = (
  startCellIndex: number,
  endCellIndex: number,
  groupCellsByCellIndex: GroupsOfCellsByCellIndex,
  exceptionCommentId: number | string,
): DoIntersectionsCheckResult => _.reduce(groupCellsByCellIndex, (result, groupCell, key) => {
  const keyNumber = parseInt(key, 10);
  const isCommentExist = !_.isNil(groupCell.comment);

  switch (true) {
    case keyNumber < startCellIndex:
    case keyNumber > endCellIndex:
    case result.isIntersectionDetected:
    case groupCell.comment?.commentId === exceptionCommentId:
      return result;
    case isCommentExist:
      return { isIntersectionDetected: true };
    default:
      return result;
  }
}, { isIntersectionDetected: false } as { isIntersectionDetected: boolean });

/** Returns end time cell of largest available time span. */
export const getLatestEndCellIndex = (
  cellIndex: number,
  groupCellsByCellIndex: GroupsOfCellsByCellIndex,
): GetLatestEndCellIndexResult => _.reduce(groupCellsByCellIndex, (result, groupCell, key) => {
  const keyNumber = parseInt(key, 10);
  const isCommentExist = !_.isNil(groupCell.comment);

  switch (true) {
    case cellIndex > keyNumber:
      return result;
    case !isCommentExist && !result.enough:
      return { ...result, newEndTimeCell: keyNumber };
    case isCommentExist:
      return { ...result, enough: true };
    default:
      return result;
  }
}, { newEndTimeCell: null, enough: false } as { newEndTimeCell: number | null, enough: boolean });

export const getEarliestStartCellIndex = (
  cellIndex: number,
  groupCellsByCellIndex: GroupsOfCellsByCellIndex,
): GetEarliestStartCellIndexResult => _.reduceRight(groupCellsByCellIndex, (result, groupCell, key) => {
  const keyNumber = parseInt(key, 10);
  const isCommentExist = !_.isNil(groupCell.comment);

  switch (true) {
    case keyNumber >= cellIndex:
      return result;
    case !isCommentExist && !result.enough:
      return { ...result, newStartTimeCell: keyNumber };
    case isCommentExist:
      return { ...result, enough: true };
    default:
      return result;
  }
}, { newStartTimeCell: null, enough: false } as { newStartTimeCell: number | null, enough: boolean });
