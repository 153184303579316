import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import SpinnerNew from 'components/_shared/LoadingSpinnerNew';
import { UsersBCDay, UsersHourResponse } from 'api/TimeSheet/types';
import PartnerMask from 'components/_shared/PartnerMask';
import useApi from 'api/hooks/useApi/useApi';
import { getUserTimeSheetByDate } from 'api/TimeSheet/request';

import S from './helpers/styles.module.sass';
import { groupUserHoursByActivity, ModifiedUsersHour } from './helpers/groupUserHoursByActivity';
import UserHourModal from '../UserHourModal/UserHourModal';
import { MobileTimesheetPanel } from '../../helpers/types';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from '../RegularPanel/helpers/plusIcon.svg';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { Redux } from '../../../../../../../helpers/types/_common';

interface UserHoursListProps {
  refreshCalendar: () => void;
  changePanel: (panelName: MobileTimesheetPanel) => void;
  selectedDayObj: UsersBCDay;
  userId: number;
}

const UserHoursList: React.FC<UserHoursListProps> = ({ selectedDayObj, userId, refreshCalendar, changePanel }) => {
  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);

  const [ isModalVisible, setModalVisible ] = useState(false);
  const [ selectedUserHour, setUserHour ] = useState<ModifiedUsersHour | null>(null);
  const [
    getUserHours,
    userHours,
    isTSLoading,
  ] = useApi<typeof getUserTimeSheetByDate, UsersHourResponse[]>(getUserTimeSheetByDate, { defaultData: [] });

  const groupedUserHours = useMemo(() => groupUserHoursByActivity(userHours), [ userHours ]);

  const renderComment = (userHour: ModifiedUsersHour) => {
    const comment = _.values(userHour.comments)[0];
    const commentsAmount = _.values(userHour.comments).length;

    if (_.isEmpty(userHour.comments) || !comment) return null;

    return (
      <div className={S.comment}>
        {comment.comment}
        {commentsAmount > 1 && (
          <span className={S.moreCommentsLabel}>
            {` + ${commentsAmount - 1} more comments`}
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    getUserHours({ userId, date: selectedDayObj.day });
  }, [ selectedDayObj.day ]);

  useEffect(() => {
    if (!isModalVisible) {
      getUserHours({ userId, date: selectedDayObj.day });
    }
  }, [ isModalVisible ]);

  const openUserHourModal = (userHour: ModifiedUsersHour) => {
    setUserHour(userHour);
    setModalVisible(true);
  };

  const renderUserHour = (usersHour: ModifiedUsersHour) => {
    const startTime = dayjs(usersHour.startTime).format('HH:mm');
    const endTime = dayjs(usersHour.endTime).format('HH:mm');
    const fullName = `${usersHour.partner.name}: ${usersHour.activity.name}`;

    return (
      <button
        type="button"
        key={usersHour.timesheetId}
        className={S.timesheet}
        onClick={() => openUserHourModal(usersHour)}
      >
        <div className={S.timeRange}>
          <span>{startTime}</span>
          <span>–</span>
          <span>{endTime}</span>
        </div>
        <div className={S.activity}>
          <PartnerMask
            wrapperColor={usersHour.partner.color}
            mask={usersHour.partner.mask}
            wrapperClassName={S.activityIconWrapper}
            partnerId={usersHour.partner.partnerId}
            iconColor={usersHour.activity.color}
            isVacation={usersHour.partner.isVacation}
          />
          <div className={S.descriptionTextWrapper}>
            <span className={S.descriptionText}>{fullName}</span>
            <div className={S.commentsList}>
              {renderComment(usersHour)}
            </div>
          </div>
        </div>
        <span className={S.hours}>{`${usersHour.hours}h`}</span>
      </button>
    );
  };

  return (
    <div className={S.userHoursListPanel}>
      {isTSLoading && (<div className={S.preloaderWrapper}><SpinnerNew /></div>)}
      <div className={S.listWrapper} style={{ opacity: isTSLoading ? 0.6 : 1 }}>
        <Scrollbars autoHide>
          {_.map(groupedUserHours, renderUserHour)}
          {!_.isEmpty(groupedUserHours) && <div className={S.addTimeButtonPlaceholder} />}
          {_.isEmpty(groupedUserHours) && <span className={S.notItemsText}>No tracked activities yet.</span>}
        </Scrollbars>
      </div>

      <Button
        className={classNames(S.addTimeButton, { [S.locked]: isMonthClosed })}
        disabled={isMonthClosed}
        onClick={() => changePanel(MobileTimesheetPanel.ChooseActivity)}
        icon={<PlusIcon />}
      />

      <UserHourModal
        userHour={selectedUserHour}
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        refreshCalendar={refreshCalendar}
        changePanel={changePanel}
      />
    </div>
  );
};

export default UserHoursList;
