import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { UserRedux } from 'redux/User/types';
import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'helpers/constants/_common/timeFormats';
import { getBusinessMonthByDate, getCalendarMonthRange } from 'api/BusinessCalendar/requests';
import { BusinessMonthRangeOutput } from 'api/BusinessCalendar/types';

import { UserHourReportState } from './helpers/types';
import HourReportTable from './components/HourReportTable/HourReportTable';
import HourReportSettings from './components/HourReportSettings/HourReportSettings';

interface HourReportProps {
  user: UserRedux;
}

const HourReport: React.FC<HourReportProps> = ({ user }) => {
  const [ isBCLoading, setIsBCLoading ] = useState(false);
  const [ settingsValues, setSettingsValues ] = useState<UserHourReportState>(() => ({
    dates: {
      selectedDate: dayjs().format(MONTH_YEAR_FORMAT),
      modifiedMonth: false,
      startDateRange: undefined,
      endDateRange: undefined,
    },

    salary: { hourly: 0, fixed: 0 },
  }));

  useEffect(() => {
    setIsBCLoading(true);

    getBusinessMonthByDate(dayjs().format(DEFAULT_DATE_FORMAT))
      .then((r: BusinessMonthRangeOutput) => {
        setSettingsValues(prevValues => ({
          ...prevValues,
          dates: {
            ...prevValues.dates,
            selectedDate: `${r.year}-${r.month}`,
            startDateRange: r.startDay,
            endDateRange: r.endDay,
            modifiedMonth: r.modified,
          },
        }));
      })
      .finally(() => { setIsBCLoading(false); });
  }, []);

  const handleOnChangeMonth = (date: string) => {
    setIsBCLoading(true);

    return getCalendarMonthRange(user.userId, date)
      .then((response: BusinessMonthRangeOutput) => response)
      .catch(() => ({
        businessMonthId: undefined,
        startDay: dayjs(date).startOf('month').format(DEFAULT_DATE_FORMAT),
        endDay: dayjs(date).endOf('month').format(DEFAULT_DATE_FORMAT),
        year: dayjs(date).year(),
        month: dayjs(date).month() + 1,
        modified: false,
      }))
      .finally(() => setIsBCLoading(false));
  };

  return (
    <div>
      <HourReportSettings
        isBCLoading={isBCLoading}
        handleOnChangeMonth={handleOnChangeMonth}
        setSettingsValues={setSettingsValues}
        settingsValues={settingsValues}
      />

      <HourReportTable
        settingsValues={settingsValues}
        setSettingsValues={setSettingsValues}
      />
    </div>
  );
};

export default HourReport;
