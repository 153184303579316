import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

import ProfileViewField from 'components/_shared/ProfileViewField';
import UserNotification from 'components/UserNotification';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as MouseIcon } from 'helpers/icons/MouseIcon.svg';
import { ReactComponent as BrushIcon } from 'helpers/icons/BrushIcon.svg';
import { ReactComponent as NoteIcon } from 'helpers/icons/note.svg';
import { ReactComponent as PartnersButtonCircle } from 'helpers/icons/circle-arrow.svg';
import { DispatchType, Redux } from 'helpers/types/_common';
import { addActivityFromPartnersList, setActivitiesAndPartnersAfterDrag } from 'api/Partners/requests';
import { UIDrawingMode, UserRedux } from 'redux/User/types';
import { UserDetails } from 'api/User/types';

import ActivitiesList from './components/ActivitiesList';
import s from '../helpers/styles.module.sass';
import timesheetStyles from './styles.module.sass';
import { getActivitiesList, getPartnersList, selectActivity, updateUserActivities, updateUserPartners } from '../../../redux/TimeSheet/action';
import PartnersList from './components/PartnersList';
import useOnScreenObserver from '../../../helpers/hooks/useOnScreenObserver';
import { UiDrawMode } from '../../../helpers/constants/_common/constants';
import { updateUserPreferences } from '../../../redux/User/actions';

export interface PagesSidebarProps {
  collapsed: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  userData: UserRedux | UserDetails;
  noProfileHeader?: boolean;
  curatorOnlyView?: boolean;
}

const TrackerSidebar: React.FC<PagesSidebarProps> = ({ collapsed, userData, noProfileHeader, curatorOnlyView }) => {
  const dispatch: DispatchType = useDispatch();
  const partnerTitleRef = useRef<HTMLDivElement>(null);

  const { timeSheet, login } = useSelector((state: Redux) => state);
  const { activities, partners, tableSettings, selectedActivity } = timeSheet;

  const isVisibleRefItem = useOnScreenObserver(partnerTitleRef);

  const [ isEdit, setIsEdit ] = useState(false);
  const [ doneLoading, setDoneLoading ] = useState(false);
  const [ loadingActivities, setLoadingActivities ] = useState(false);
  const [ loadingPartners, setLoadingPartners ] = useState(false);

  const isMouseMode = login.preferences.webUiDrawingMode !== UiDrawMode.MOUSE_AND_CTRL;
  const isDrawMode = Boolean(selectedActivity);

  useEffect(() => () => {
    // Remove deprecated activities/partners if user moved from EditEmployeesPage or from Timesheet page.
    dispatch(updateUserActivities([]));
    dispatch(updateUserPartners([]));
  }, []);

  useEffect(() => {
    if (userData.userId) {
      getPartners();
      getActivities();
    }
  }, [ userData.userId ]);

  const getActivities = () => {
    setLoadingActivities(true);

    dispatch(getActivitiesList(userData.userId, curatorOnlyView))
      .finally(() => {
        setLoadingActivities(false);

        if (login.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL) {
          dispatch(selectActivity(undefined));
        } else {
          dispatch(selectActivity('currentlyInUse'));
        }
      });
  };

  const getPartners = () => {
    setLoadingPartners(true);

    dispatch(getPartnersList(userData.userId, curatorOnlyView))
      .finally(() => setLoadingPartners(false));
  };

  const onRemovePartnerCallback = () => {
    getActivities();
    getPartners();
  };

  const handleOnAddActivity = (activityId: number) => {
    if (!activityId || !userData.userId) {
      return;
    }

    return addActivityFromPartnersList({ userId: userData.userId, activityId })
      .then((response) => {
        getActivities();

        return response;
      });
  };

  const handleDoneButton = () => {
    setDoneLoading(true);

    setActivitiesAndPartnersAfterDrag({
      userId: userData.userId,
      partnerIds: _.map(partners, 'partner.partnerId'),
      activityIds: _.map(activities, 'activity.activityId'),
    })
      .then(() => {
        setIsEdit(prevEditValue => !prevEditValue);
      })
      .finally(() => setDoneLoading(false));
  };

  const doneEditButton = (
    <Button
      type="primary"
      className={buttons.qsButtonPrimary}
      onClick={handleDoneButton}
      loading={doneLoading}
    >
      <div className={buttons.iconTextWrapper}>
        <FontAwesomeIcon icon="check-circle" />
        Done
      </div>
    </Button>
  );

  const actionButton = (
    <Button
      title="Edit activities"
      type="text"
      className={s.editButton}
      disabled={isMouseMode && !_.isEmpty(selectedActivity)}
      onClick={() => setIsEdit(prevEditValue => !prevEditValue)}
    >
      <NoteIcon />
    </Button>
  );

  const handleScrollToPartnersTitle = () => {
    if (!partnerTitleRef?.current) {
      return;
    }

    partnerTitleRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const handleDrawModeToggle = (drawStatus: UIDrawingMode) => {
    if (curatorOnlyView) {
      return;
    }

    dispatch(updateUserPreferences({ webUiDrawingMode: drawStatus }));
    dispatch(selectActivity(login.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL ? 'currentlyInUse' : undefined));
  };

  const activitiesBlockTitle = (login.userId === userData.userId) ? 'My Activities' : `${userData.forename}'s Activities`;

  const renderDrawModeSwitcher = () => {
    const content = (
      <div className={timesheetStyles.drawModeSwitchedContent}>
        <span className={timesheetStyles.contentTitle}>Select preferred marking method</span>
        <Button
          className={cx(timesheetStyles.descriptionButton, { [timesheetStyles.active]: _.isNil(login.preferences.webUiDrawingMode) || (login.preferences.webUiDrawingMode === UiDrawMode.MOUSE) })}
          onClick={() => handleDrawModeToggle(UiDrawMode.MOUSE)}
        >
          <div className={timesheetStyles.iconWrapper}>
            <BrushIcon className={timesheetStyles.brushIcon} />
          </div>
          <div className={timesheetStyles.descriptionWrapper}>
            <span className={timesheetStyles.title}>Pick&Draw</span>
            <span className={timesheetStyles.text}>Pin particular activity for marking the hours worked for one.</span>
          </div>
        </Button>
        <Button
          className={cx(timesheetStyles.descriptionButton, { [timesheetStyles.active]: login.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL })}
          onClick={() => handleDrawModeToggle(UiDrawMode.MOUSE_AND_CTRL)}
        >
          <div className={timesheetStyles.iconWrapper}>
            <MouseIcon className={timesheetStyles.mouseIcon} />
          </div>
          <div className={timesheetStyles.descriptionWrapper}>
            <span className={timesheetStyles.title}>CTRL+Click</span>
            <span className={timesheetStyles.text}>Familiar way from V1 which allows you to pin activity from the list and mark with it using CTRL+LMB</span>
          </div>
        </Button>
      </div>
    );

    return (
      <div className={cx(timesheetStyles.drawModeSwitcherWrapper, { [timesheetStyles.collapsed]: collapsed })}>
        <Popover
          trigger="click"
          content={content}
          placement="right"
          overlayClassName={timesheetStyles.drawPopover}
        >
          <div className={timesheetStyles.drawModeButton}>
            {login.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL ? (
              <>
                <MouseIcon />
                CTRL+Click
              </>
            ) : (
              <>
                <BrushIcon />
                Pick&Draw
              </>
            )}
          </div>
        </Popover>
      </div>
    );
  };

  return (
    <div className={cx(s.sidebarLayout, { [s.isActivitySelected]: isMouseMode && isDrawMode })}>
      <div className={cx(s.sidebarContentWrapper, { [s.collapsed]: collapsed })}>

        {
          !noProfileHeader && (
            <>
              <div className={s.sidebarHeader}>
                <ProfileViewField user={userData} />
              </div>

              <UserNotification
                topPosition={200}
                collapsed={collapsed}
              />
            </>
          )
        }

        <div className={cx(s.activitiesWrapper, { [s.collapsedActivities]: collapsed })}>
          <span className={s.activitiesTitle}>{ isEdit ? 'Edit Activities' : activitiesBlockTitle }</span>
          <div className={s.buttons}>
            { isEdit ? doneEditButton : actionButton }
            { !isEdit && renderDrawModeSwitcher() }
          </div>
        </div>

        <Scrollbars
          autoHide
          className={s.customScrollbar}
          renderThumbVertical={() => <div className={s.thumbVertical} />}
        >
          <ActivitiesList
            userData={userData}
            isEdit={isEdit}
            getActivities={getActivities}
            loading={loadingActivities}
            collapsed={collapsed}
            curatorOnlyView={curatorOnlyView}
          />

          <PartnersList
            userData={userData}
            ref={partnerTitleRef}
            isEdit={isEdit}
            onAddActivity={handleOnAddActivity}
            onAddPartnerCallback={getPartners}
            onRemovePartnerCallback={onRemovePartnerCallback}
            loading={loadingPartners}
            collapsed={collapsed}
            curatorOnlyView={curatorOnlyView}
          />
        </Scrollbars>

        <Button
          className={cx(buttons.qsButton, s.partnersButton, {
            [s.hiddenPartnerButton]: _.isEmpty(partners) || isVisibleRefItem || collapsed,
          })}
          onClick={handleScrollToPartnersTitle}
        >
          Partners
          <PartnersButtonCircle />
        </Button>
      </div>
    </div>
  );
};

export default TrackerSidebar;
