import { Column } from '../../_shared/Table/helpers/types';

const EMPLOYEES_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    width: 100,
  },
  {
    title: 'Full Name',
    dataIndex: 'fullname',
    key: 'fullname',
    sorter: true,
  },
  {
    title: 'Position',
    dataIndex: 'occupation',
    key: 'occupation',
    width: 300,
  },
  {
    title: 'Entities',
    dataIndex: 'entities',
    key: 'entities',
    width: 175,
  },
  {
    title: 'Skill Tags',
    dataIndex: 'skillsCount',
    sorter: true,
    key: 'skillsCount',
    width: 100,
  },
  {
    title: 'HRS/Month',
    dataIndex: 'currentMonthHours',
    sorter: true,
    key: 'currentMonthHours',
    width: 100,
  },
  {
    title: 'Skills updated',
    dataIndex: 'skillsetLastUpdate',
    key: 'skillsetLastUpdate',
    sorter: true,
    width: 150,
  },
  {
    title: 'Timesheet updated',
    dataIndex: 'timesheetLastUpdate',
    key: 'timesheetLastUpdate',
    sorter: true,
    className: 'borderless',
    width: 200,
  },
  {
    title: 'Added by',
    dataIndex: 'authorAddedBy',
    key: 'authorAddedBy',
    width: 200,
  },
  {
    title: 'Added on',
    dataIndex: 'authorAddedOn',
    key: 'authorAddedOn',
    className: 'borderless',
    width: 200,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    width: 30,
    className: 'borderless action-column',
  },
];

export default EMPLOYEES_COLUMNS;
