import React, { useState } from 'react';
import _ from 'lodash';
import Popover from 'antd/lib/popover';
import classNames from 'classnames';

import popover from 'helpers/styles/components/popover.module.sass';
import { PartnerMaskType } from 'api/Partners/types';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { NEW_PARTNER_MASKS } from 'helpers/constants/_common/Partners';

import s from './styles.module.sass';

interface PartnerMaskPickerProps {
  partnerColor?: string;
  maskColor?: string;
  value: PartnerMaskType;
  onChange: (mask: PartnerMaskType) => void;
}

const PartnerMaskPicker: React.FC<PartnerMaskPickerProps> = ({ onChange, value, maskColor = 'red', partnerColor = 'white' }) => {
  const [ maskPopoverVisible, setMaskPopoverVisible ] = useState(false);

  const onChooseMask = (mask: any) => {
    onChange(mask.key);
    setMaskPopoverVisible(false);
  };

  const renderCurrentMask = () => {
    const Mask = value ? NEW_PARTNER_MASKS[value].icon : NEW_PARTNER_MASKS.DEFAULT.icon;

    return <Mask className={s.currentMask} style={{ fill: maskColor }} />;
  };

  const renderSelectionContent = () => (
    <div>
      <div className={s.partnerMasksLayout}>
        {
          _.map(NEW_PARTNER_MASKS, mask => (
            <button
              type="button"
              className={classNames(s.partnerMaskWrapper, { [s.activePartnerMask]: (value === mask.key) })}
              key={mask.key}
              onClick={() => onChooseMask(mask)}
            >
              <mask.icon className={s.partnerMask} />
            </button>
          ))
        }
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName={classNames(popover.dotsPopoverOverlay, s.customizationPopover, s.customizationPopoverRounded)}
      trigger="click"
      placement="bottom"
      content={renderSelectionContent()}
      visible={maskPopoverVisible}
      onVisibleChange={setMaskPopoverVisible}
    >
      <div className={s.partnerSelection} style={{ backgroundColor: partnerColor }}>
        { renderCurrentMask() }
        <SelectIcon />
      </div>
    </Popover>
  );
};

export default PartnerMaskPicker;
