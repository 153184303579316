import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Key } from 'antd/lib/table/interface';
import _ from 'lodash';
import { Space, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import TableAvatar from 'components/TableComponents/TableAvatar';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import BasicTable from 'components/_shared/Table';
import { PartnerDetailsOutput } from 'api/Partners/types';
import { Meta, Sorter } from 'api/_request/types';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES, TAB_PARTNER_PARTICIPANTS } from 'helpers/constants/_common/MenuCases';
import { EDIT_PROFILE_TABS } from 'helpers/constants/_common/tabs/profile';
import { ReactComponent as TimeIcon } from 'helpers/icons/timeIcon.svg';
import { EntityOutput } from 'api/User/types';

import { PARTICIPANTS_COLUMNS } from '../../helpers/constants';
import s from './styles.module.sass';
import { ParticipantsFiltersState, PartnerParticipantsOutputChecked } from '../../helpers/types';
import ParticipantsThreeDotsPopover from '../ParticipantsThreeDotsPopover/ParticipantsThreeDotsPopover';

interface ParticipantsTableProps {
  partner: PartnerDetailsOutput;
  selectedRows: PartnerParticipantsOutputChecked[];
  filters: ParticipantsFiltersState;
  setSelectedRows: (selectedRows: PartnerParticipantsOutputChecked[]) => void;
  getParticipantsData: (pagination: Meta, sorter?: Sorter) => void;
}

const ParticipantsTable: React.FC<ParticipantsTableProps> = (props) => {
  const { partner, filters, setSelectedRows, selectedRows, getParticipantsData } = props;

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.PARTNER_PARTICIPANTS]);
  const { pathname } = useLocation();

  useEffect(() => {
    getParticipantsData(meta, sorter);
  }, [ filters ]);

  const onChangeRowSelection = (selectedRowKeys: Key[], selectedRowsOnChange: PartnerParticipantsOutputChecked[]) => {
    const markedCheckedSelectedRows = _.map(selectedRowsOnChange, s => ({ ...s, checked: true }));

    setSelectedRows(markedCheckedSelectedRows);
  };

  const rowSelection = {
    onChange: onChangeRowSelection,
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(selectedRows, row => row.partnerUserId),
  };

  const renderEntitiesCell = (entities: EntityOutput[]) => {
    if (entities.length > 2) {
      return (
        <Tooltip
          title={_.map(entities, 'name').join(', ')}
        >
          <Space direction="vertical" size={2}>
            <span>{`${entities[0].name}, `}</span>
            <span>
              {entities[1].name}
              &nbsp;
              <span className={s.expandableCell}>
                {`+${entities.length - 2}`}
              </span>
            </span>
          </Space>
        </Tooltip>
      );
    }

    if (!_.isEmpty(entities)) {
      return _.map(entities, 'name').join(', ');
    }

    return '-';
  };

  const renderColumns = {
    userId: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => record.user.userId,
    },
    fullname: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => (
        <Space>
          <TableAvatar url={record.user.avatarUrl} />
          <span title={`${record.user.forename} ${record.user.surname}`} className={s.oneLineText}>{`${record.user.forename} ${record.user.surname}`}</span>
        </Space>
      ),
    },
    currentMonthHours: {
      render: (text: string) => `${text}h`,
    },
    occupation: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => {
        const mainSkill = record?.user?.primarySkill?.skill?.name || '';

        return (
          <div>
            {`${mainSkill} ${OCCUPATIONS[record.user.occupation]?.name}`}
          </div>
        );
      },
    },
    entity: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => renderEntitiesCell(record?.user?.entities),
    },
    activities: {
      render: () => (
        <div>
          -
        </div>
      ),
    },
    hours: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => (
        <div>
          {record.currentMonthHours}
          h
        </div>
      ),
    },
    actions: {
      render: (text: string, record: PartnerParticipantsOutputChecked) => (
        <div className={classNames(s.actions, 'three-dots-button')}>
          <Link
            title="Show timesheet"
            to={{
              pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${record.user.userId}/${EDIT_PROFILE_TABS.timesheet.path}`,
              state: { from: TAB_PARTNER_PARTICIPANTS, pathname },
            }}
            className={s.viewUserTimeSheet}
          >
            <TimeIcon />
          </Link>
          <ParticipantsThreeDotsPopover
            partner={partner}
            record={record}
            refreshTable={() => getParticipantsData(meta, sorter)}
          />
        </div>
      ),
    },
  };

  return (
    <div>
      <BasicTable
        rowKey="partnerUserId"
        className={s.participantsTable}
        tableName={TABLE_NAMES.PARTNER_PARTICIPANTS}
        getData={getParticipantsData}
        initialRequest={false}
        columns={PARTICIPANTS_COLUMNS}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        rowSelection={rowSelection}
        rowClassName={() => s.participantsRow}
        bordered
      />
    </div>
  );
};

export default ParticipantsTable;
