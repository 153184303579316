import { Column } from '../../../../_shared/Table/helpers/types';

const USER_HOUR_REPORTS_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    width: 100,
  },
  {
    title: 'Full name',
    dataIndex: 'fullname',
    key: 'fullname',
    width: 500,
    sorter: true,
  },

  {
    title: 'Partner',
    dataIndex: 'partner',
    key: 'partner',
  },

  {
    title: 'Hrs',
    dataIndex: 'hours',
    key: 'hours',
    width: 200,
    sorter: true,
  },

  {
    title: 'Timesheet last update',
    dataIndex: 'timesheetLastUpdate',
    key: 'timesheetLastUpdate',
    sorter: true,
    width: 200,
  },
  {
    title: '',
    dataIndex: 'periodIsClosed',
    key: 'periodIsClosed',
    className: 'borderless',
    width: 50,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    className: 'borderless',
    width: 50,
  },
];
export default USER_HOUR_REPORTS_COLUMNS;
