import _ from 'lodash';
import dayjs from 'dayjs';

import { UserHourComment, UsersHourResponse } from 'api/TimeSheet/types';

export interface ModifiedUsersHour extends Omit<UsersHourResponse, 'comment'> {
  comments: {
    [ key: number ]: UserHourComment | null;
  };
}

/** Returns UserHourResponse[] structure, grouped by activity id with right start, end time and hours. */
export const groupUserHoursByActivity = (userHours: UsersHourResponse[]) => {
  const grouped = _.reduce(userHours, (result, userHour) => {
    const startTimeKey = `${userHour.startTime}-${userHour.activity.activityId}`;
    const endTimeKey = `${userHour.endTime}-${userHour.activity.activityId}`;

    if (!result[startTimeKey]) {
      const comment = userHour.comment ? { [userHour.comment.commentId]: userHour.comment } : {};

      return {
        ...result,
        [endTimeKey]: {
          comments: { ...comment },
          ..._.omit(userHour, 'comment'),
        },
      };
    } else {
      const comment = userHour.comment ? { [userHour.comment.commentId]: userHour.comment } : {};

      return {
        ..._.omit(result, startTimeKey),
        [endTimeKey]: {
          ...result[startTimeKey],
          endTime: userHour.endTime,
          hours: result[startTimeKey].hours + userHour.hours,
          comments: {
            ...result[startTimeKey].comments,
            ...comment,
          },
        },
      };
    }
  }, {} as { [key: string]: ModifiedUsersHour });

  return _.sortBy(_.values(grouped), [ a => dayjs(a.startTime).unix() ]);
};
