import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Key } from 'antd/lib/table/interface';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import BasicTable from 'components/_shared/Table';
import { Redux } from 'helpers/types/_common';
import { PATH_PARTNER_INFO, TAB_PARTNER_INFO } from 'helpers/constants/_common/MenuCases';
import { FoundPartnerOutput } from 'api/Partners/types';
import { Meta, Sorter } from 'api/_request/types';
import PartnerMask from 'components/_shared/PartnerMask';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { PartnersFiltersState, PartnerUserOutputChecked } from '../../helpers/types';
import { PARTNERS_COLUMNS } from '../../helpers/constants';
import s from './styles.module.sass';
import PartnersThreeDotsPopover from '../PartnersThreeDotsPopover';

interface PartnersTableProps {
  getPartnersData: (pagination: Meta | boolean, sorter?: Sorter) => void;
  setSelectedRows: (selectedRows: PartnerUserOutputChecked[]) => void;
  filters: PartnersFiltersState;
  selectedRows: PartnerUserOutputChecked[];
}

const PartnersTable: React.FC<PartnersTableProps> = ({ getPartnersData, filters, selectedRows, setSelectedRows }) => {
  const history = useHistory();

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.PARTNERS]);

  useEffect(() => {
    refreshTable();
  }, [ filters ]);

  const refreshTable = () => {
    getPartnersData(meta, sorter);
  };

  const onChangeRowSelection = (selectedRowKeys: Key[], selectedRowsOnChange: any[]) => {
    const markedCheckedSelectedRows = _.map(selectedRowsOnChange, s => ({ ...s, checked: true }));

    setSelectedRows(markedCheckedSelectedRows);
  };

  const onCell = (record: FoundPartnerOutput) => ({
    onClick: () => {
      history.push(`${PATH_PARTNER_INFO}/${record.partnerId}/${TAB_PARTNER_INFO}`);
    },
  });

  const rowSelection = {
    onChange: onChangeRowSelection,
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(selectedRows, row => row.partnerId),
  };

  const renderColumns = {
    partnerId: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => record.partnerId,
    },
    name: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => (
        <div
          className={s.partner}
          key={record.partnerId}
          title={record.name}
        >
          <PartnerMask
            wrapperColor={record.color}
            mask={record.mask}
            wrapperClassName={s.maskWrapper}
            partnerId={record.partnerId}
            iconColor={record.maskColor || '#eee'}
            isVacation={record.isVacation}
          />
          <span className={s.name}>{record.name}</span>
        </div>
      ),
    },
    description: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => <span className={s.description} title={record.description}>{record.description || '-'}</span>,
    },
    requestedBy: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => (record.requester ? `${record.requester.forename} ${record.requester.surname}` : '-'),
    },
    createdBy: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => (record.author ? `${record.author.forename} ${record.author.surname}` : '-'),
    },
    currentMonthHours: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => `${record.currentMonthHours}h`,
    },
    creationDate: {
      onCell,
      render: (text: string, record: FoundPartnerOutput) => dayjs(record.creationDate).format(RU_DOT_DATE_FORMAT),
    },
    actions: {
      render: (text: string, record: FoundPartnerOutput) => (
        <PartnersThreeDotsPopover
          record={record}
          refreshTable={refreshTable}
        />
      ),
    },
  };

  return (
    <div>
      <BasicTable
        rowKey="partnerId"
        className={s.employeesTable}
        tableName={TABLE_NAMES.PARTNERS}
        getData={getPartnersData}
        initialRequest={false}
        columns={PARTNERS_COLUMNS}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        rowSelection={rowSelection}
        rowClassName={() => s.employeeRow}
        bordered
      />
    </div>
  );
};

export default PartnersTable;
