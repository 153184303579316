import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { SkillTagProps } from '../../../../../../../api/SkillTags/types';
import { Meta, Sorter } from '../../../../../../../api/_request/types';

export interface MergeSkillsModalProps {
  selectedRows: SkillTagProps[];
  visible: boolean;
  setVisible: (status: boolean) => void;
  fetchSkillTags: (pagination?: Meta, sorter?: Sorter) => void;
  resetSelections: () => void;
  handleOnChangeCheckbox: (event: CheckboxChangeEvent, targetRow: any) => void;
}

export enum MergingType {
  notSelected = 'NOT_SELECTED',
  intoExisted = 'INTO_EXISTED',
  withNewOne = 'WITH_NEW_ONE',
}
