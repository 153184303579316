import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getFirstCellRect } from '../TableSelection/components/SelectionBox/helpers/helpers';
import S from './styles.module.sass';

interface TableHighlightProps {
  sideColumnWidth: number,
  columnHeaderHeight: number,
  monthNameLabelHeight: number,
  isDisabled: boolean;
}

interface HighlightCoords {
  coords: { x: number, y: number },
  isVisible: boolean;
}

const TableHighlight: React.FC<TableHighlightProps> = ({ sideColumnWidth, columnHeaderHeight, monthNameLabelHeight, isDisabled }) => {
  const InitialDate: HighlightCoords = {
    coords: { x: 0, y: 0 },
    isVisible: false,
  };
  const [ data, setData ] = useState(InitialDate);

  const highlightRef = useRef<HTMLDivElement>(null);
  
  const onMouseMove = useCallback((e: MouseEvent) => {
    if (checkIfCursorIsInWrapper(e)) {
      setData({
        isVisible: true,
        coords: { x: e.clientX, y: e.clientY },
      });
    } else {
      setData({
        isVisible: false,
        coords: { x: 0, y: 0 },
      }); 
    }
  }, [ data ]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);

    return () => document.removeEventListener('mousemove', onMouseMove);
  }, [ onMouseMove ]);

  const checkIfCursorIsInWrapper = (e: MouseEvent): boolean => {
    const wrapper = highlightRef.current;

    if (_.isNull(wrapper)) return false;

    const rect = wrapper.getBoundingClientRect();

    const isXInRange = e.clientX >= (rect.left + sideColumnWidth) && e.clientX <= (rect.left + rect.width);
    const isYInRange = e.clientY >= (rect.top + columnHeaderHeight - monthNameLabelHeight) && e.clientY <= (rect.top + rect.height);

    return isXInRange && isYInRange;
  };

  const getHighlightStyles = () => {
    const firstCellReq = getFirstCellRect();

    if (_.isNull(firstCellReq)) {
      return {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      };
    }

    const { x, y } = data.coords;

    const roundedLeft = Math.floor((x - firstCellReq.left) / firstCellReq.width) * firstCellReq.width;
    const roundedTop = Math.floor((y - firstCellReq.top) / firstCellReq.height) * firstCellReq.height;

    return {
      width: firstCellReq.width,
      height: firstCellReq.height,
      left: roundedLeft + sideColumnWidth,
      top: roundedTop + columnHeaderHeight - monthNameLabelHeight,
    };
  };

  const renderHighlight = () => {
    if (isDisabled) return null;

    const { left, top, width, height } = getHighlightStyles();

    return (
      <>
        <div className={S.tableHighlightColumn} style={{ left: `${left}px`, width: `${width}px` }} />
        <div className={S.tableHighlightRow} style={{ top: `${top + 1}px`, height: `${height + 1}px` }} />
      </>
    );
  };

  return (
    <div
      style={{ height: `calc(100% - ${monthNameLabelHeight}px)` }}
      ref={highlightRef}
      className={S.tableHighlightWrapper}
    >
      {renderHighlight()}
    </div>
  );
};

export default TableHighlight;
