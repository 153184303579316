import React from 'react';

import S from './styles.module.sass';
import ActionMaker from './components/ActionMaker/ActionMaker';

interface TableSelectionProps {
  sideColumnWidth: number;
  columnHeaderHeight: number;
  curatorOnlyView: boolean | undefined;
  isDisabled: boolean;
}

const TableSelection: React.FC<TableSelectionProps> = ({ sideColumnWidth, columnHeaderHeight, curatorOnlyView, isDisabled }) => (
  <div
    id="table-selection-zone"
    className={S.tableSelectionWrapper}
    style={{
      height: `calc(100% - ${columnHeaderHeight + 1}px)`, // 1px is from border error
      width: `calc(100% - ${sideColumnWidth}px)`,
      left: sideColumnWidth,
      top: columnHeaderHeight + 1,
    }}
  >
    <ActionMaker curatorOnlyView={curatorOnlyView} isDisabled={isDisabled} />
  </div>
);

export default TableSelection;
