import VacationHourReport from 'components/VacationHourReport/VacationHourReport';
import Partners from 'components/Partners';
import UserHourReports from 'components/UserHourReports/UserHourReports';
import PartnersUsers from 'components/PartnersUsers/PartnersUsers';

import {
  TAB_EMPLOYEES, TAB_PARTNERS,
  TAB_PARTNER_INFO,
  TAB_VACATION_HOUR_REPORT,
  TAB_PARTNER_PARTICIPANTS,
  TAB_USERS_TIMESHEET, TAB_CURATORS_TIMESHEET, TAB_ADMIN_HOUR_REPORT, TAB_PARTNER_ACTICITIES, TAB_PARTNERS_USERS,
} from '../MenuCases';
import Employees from '../../../../components/Employees';
import { AccessEnum } from '../../../../components/Root/helpers/types';
import { SidebarTab } from './types';
import UserTimesheetAdminTab from '../../../../components/UserTimesheetAdminTab/UserTimesheetAdminTab';
import CuratorAdministrationTab from '../../../../components/CuratorAdministrationTab/CuratorAdministrationTab';

type AdministrationSidebarTab = Omit<SidebarTab, 'path'> & {
  path: (userId?: number) => string;
};

interface AdministrationSidebar {
  [key: string]: AdministrationSidebarTab
}

const ADMINISTRATION_TABS: AdministrationSidebar = {
  partners: {
    path: () => TAB_PARTNERS,
    name: 'Partners',
    component: Partners,
    accessType: AccessEnum.Admin,
  },

  employees: {
    path: () => TAB_EMPLOYEES,
    name: 'Employees',
    component: Employees,
    accessType: AccessEnum.Admin,
  },
  'user-hour-reports': {
    path: () => TAB_ADMIN_HOUR_REPORT,
    name: 'User hour reports',
    component: UserHourReports,
    accessType: AccessEnum.Admin,
  },

  'vacation-hour-reports': {
    path: () => TAB_VACATION_HOUR_REPORT,
    name: 'Vacation hour report',
    component: VacationHourReport,
    accessType: AccessEnum.Admin,
  },
  'users-timesheet': {
    path: () => TAB_USERS_TIMESHEET,
    name: 'View timesheet',
    component: UserTimesheetAdminTab,
    accessType: AccessEnum.Admin,
  },
  'partners-and-users': {
    path: () => TAB_PARTNERS_USERS,
    component: PartnersUsers,
    name: 'Partners & Users',
    accessType: AccessEnum.Admin,
  },
  'curated-employees': {
    path: () => TAB_CURATORS_TIMESHEET,
    component: CuratorAdministrationTab,
    name: 'Curated employees',
    accessType: AccessEnum.Curator,
  },
};

export const EDIT_PARTNER_TABS = {
  info: {
    path: TAB_PARTNER_INFO,
    name: 'General Info',
  },

  activities: {
    path: TAB_PARTNER_ACTICITIES,
    name: 'Activities',
  },
  
  participants: {
    path: TAB_PARTNER_PARTICIPANTS,
    name: 'Participants',
  },
};

export default ADMINISTRATION_TABS;
