import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames/bind';
import _ from 'lodash';
import { Col, Select, Skeleton, Space } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import FieldCard from 'components/_shared/FieldCard/FieldCard';
import { PartnerDetailsOutput } from 'api/Partners/types';
import InfinityScroll from 'components/_shared/InfinityScroll';
import { BaseUserOutput } from 'api/User/types';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import { getAllUsers } from 'api/User/requests';
import { addCuratorToPartner, getPartnerCurators, removeCuratorFromPartner } from 'api/Partners/requests';
import { PATH_PARTNER_INFO, TAB_PARTNER_PARTICIPANTS } from 'helpers/constants/_common/MenuCases';
import useApi from 'api/hooks/useApi/useApi';
import { MetaRequest } from 'api/_request/types';
import { PartnerParticipantsOutput } from 'components/PartnerInfo/components/PartnerParticipantsTab/helpers/types';

import partners from '../../styles.module.sass';
import s from './styles.module.sass';
import curatorCardStyles from '../CuratorCard/styles.module.sass';
import selects from '../../../../../../helpers/styles/components/selects.module.sass';
import CuratorCard from '../CuratorCard/CuratorCard';

interface ActivitiesListCardProps {
  partnerFields: PartnerDetailsOutput;  
}

interface GetPartnerCuratorsResponse {
  meta: MetaRequest,
  objects: PartnerParticipantsOutput[],
}

const MAX_CURATORS_DISPLAY = 10;

const CuratorsCard: React.FC<ActivitiesListCardProps> = ({ partnerFields }) => {
  const [ isAddingLoading, setAddingLoading ] = useState(false);
  const [ getCurators, curators, isGetterLoading, setCuratorsRequest ] = useApi<typeof getPartnerCurators, GetPartnerCuratorsResponse>(getPartnerCurators);

  const curatorIds = useMemo(() => _.map(curators?.objects || [], curator => curator.user.userId), [ curators?.objects ]);

  useEffect(() => {
    getCurators(partnerFields.partnerId, MAX_CURATORS_DISPLAY);
  }, [ partnerFields.partnerId ]);

  const handleCuratorDeleting = (curatorIdToDelete: number) => (
    removeCuratorFromPartner(partnerFields.partnerId, curatorIdToDelete)
      .then(() => {
        getCurators(partnerFields.partnerId, MAX_CURATORS_DISPLAY);
      })
  );

  const handleCuratorAdding = (userId: string | number | LabeledValue, list: BaseUserOutput[]) => {
    const curatorToAdd = _.find(list, user => user.userId === userId);
    const isAlreadyExists = _.some(curators.objects, { userId });
    
    if (curatorToAdd && !isAlreadyExists) {
      setAddingLoading(true);
      addCuratorToPartner(partnerFields.partnerId, +userId)
        .then((res) => {
          if ((curators.meta.totalCount || 0) >= MAX_CURATORS_DISPLAY) {
            setCuratorsRequest({ 
              ...curators,
              meta: {
                ...curators.meta,
                totalCount: (curators.meta.totalCount || curators.objects.length) + 1,
              },
            });
          } else {
            setCuratorsRequest({
              objects: [ ...curators.objects, res ],
              meta: {
                ...curators.meta,
                totalCount: (curators.meta.totalCount || curators.objects.length) + 1,
              },
            });
          }
        })
        .finally(() => setAddingLoading(false));
    }
  };

  const renderParticipantsLink = () => (
    <div className={s.linkRow}>
      <Link
        title="Show timesheet"
        to={{ pathname: `${PATH_PARTNER_INFO}/${partnerFields.partnerId}/${TAB_PARTNER_PARTICIPANTS}`, state: { activeTab: 'curators' } }}
        className={s.viewUserTimeSheet}
      >
        Show more
      </Link>
    </div>
  );
  
  const renderCuratorList = () => {
    if (_.isEmpty(curators?.objects)) return null;

    return (
      <div className={s.curatorsList}>
        <span className={partners.fieldTitle}>Assigned curators</span>
        <div className={s.list}>          
          {_.map(_.slice(curators.objects, 0, 10), (curator, i) => (
            isGetterLoading || isAddingLoading
              ? renderSkeletonCurator(curator)
              : (
                <CuratorCard
                  key={i}
                  curator={curator}
                  onDelete={() => handleCuratorDeleting(curator.user.userId)}
                />
              )
          ))}
          {(curators.meta.totalCount || 0) < 10 && isAddingLoading && renderSkeletonCurator()}
          {(curators.meta.totalCount || 0) > 10 && renderParticipantsLink()}
        </div>
      </div>
    );
  };

  const renderSkeletonCurator = (curator?: PartnerParticipantsOutput) => (
    <div className={curatorCardStyles.curatorCard} key={curator?.partnerUserId}>
      <Skeleton.Avatar active />
      <Skeleton.Button active size="small" className={s.curatorSceletonName} />
    </div>
  );

  const renderSelectPrefix = () => (
    isGetterLoading || isAddingLoading
      ? <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin />
      : <ArrowIcon style={{ position: 'relative', top: '5px' }} />
  );

  return (
    <div className={s.curatorsWrapper}>
      <>
        <Col span={12}>
          <FieldCard
            title="Curators"
            wrapperClassName={cx(partners.titledCardWrapper)}
          >
            <Space className={partners.fieldWrapper} direction="vertical">
              <span className={partners.fieldTitle}>Add curator</span>
              <InfinityScroll
                value={null}
                suffixIcon={renderSelectPrefix}
                loading={isGetterLoading}
                dropdownClassName={selects.selectDropdown}
                className={cx(selects.qsSelect, partners.partnerSelect)}
                onObjectSelect={handleCuratorAdding}
                size="large"
                placeholder="Type to find an employee..."
                filterOption={() => true}
                requestSettingsWithSearchValue={(searchValue: string) => ({
                  orderBy: 'fullname',
                  active: true,
                  fullnameContains: searchValue,
                  usernameContains: searchValue,
                  emailAddressContains: searchValue,
                })}
                getter={getAllUsers}
                renderItem={(user: BaseUserOutput) => (
                  <Select.Option
                    key={user.userId}
                    value={user.userId}
                    name={user.forename}
                    disabled={_.includes(curatorIds, user.userId)}
                  >
                    {`${user.forename} ${user.surname}`}
                  </Select.Option>
                )}
              />
            </Space>
          </FieldCard>
        </Col>
        <Col span={12}>
          {renderCuratorList()}
        </Col>
      </>
    </div>
  );
};

export default CuratorsCard;
