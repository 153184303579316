export const MARK_CELLS = 'MARK_CELLS';
export const CLEAR_TABLE = 'CLEAR_TABLE';

export const LOAD_TIME_SHEET = 'LOAD_TIME_SHEET';
export const LOAD_TIME_SHEET_REQUESTED = 'LOAD_TIME_SHEET_REQUESTED';
export const LOAD_TIME_SHEET_OK = 'LOAD_TIME_SHEET_OK';
export const LOAD_TIME_SHEET_ERROR = 'LOAD_TIME_SHEET_ERROR';

export const POST_TIME_SHEET = 'POST_TIME_SHEET';
export const POST_TIME_SHEET_REQUESTED = 'POST_TIME_SHEET_REQUESTED';
export const POST_TIME_SHEET_OK = 'POST_TIME_SHEET_OK';
export const POST_TIME_SHEET_ERROR = 'POST_TIME_SHEET_ERROR';
export const POST_TIME_SHEET_ERROR_FIXED = 'POST_TIME_SHEET_ERROR_FIXED';
export const FAST_ADDING = 'FAST_ADDING';
export const CHANGE_TIMESHEETS = 'CHANGE_TIMESHEETS';
export const GET_CALENDAR_FROM_SERVER = 'GET_CALENDAR_FROM_SERVER';

export const REPLACE_AND_FILL_TIMESHEETS = 'REPLACE_AND_FILL_TIMESHEETS';
export const REPLACE_AND_FILL_TIMESHEETS_OK = 'REPLACE_AND_FILL_TIMESHEETS_OK';
export const REPLACE_AND_FILL_TIMESHEETS_ERROR = 'REPLACE_AND_FILL_TIMESHEETS_ERROR';

export const REPLACE_TIMESHEETS = 'REPLACE_TIMESHEETS';
export const REPLACE_TIMESHEETS_LOADING = 'REPLACE_TIMESHEETS_LOADING';
export const REPLACE_TIMESHEETS_OK = 'REPLACE_TIMESHEETS_OK';
export const REPLACE_TIMESHEETS_ERROR = 'REPLACE_TIMESHEETS_ERROR';

export const DELETE_TIME_SHEET = 'DELETE_TIME_SHEET';
export const DELETE_TIME_SHEET_REQUESTED = 'DELETE_TIME_SHEET_REQUESTED';
export const DELETE_TIME_SHEET_OK = 'DELETE_TIME_SHEET_OK';
export const DELETE_TIME_SHEET_ERROR = 'DELETE_TIME_SHEET_ERROR';

export const CHANGE_CALENDAR_TAB_MARK = 'CHANGE_CALENDAR_TAB_MARK';
export const CACHED_HOLIDAYS_EXISTED = 'CACHED_HOLIDAYS_EXISTED';
