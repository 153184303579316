import React from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import { Space, Spin } from 'antd';
import _ from 'lodash';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SearchItemOutput } from 'api/Common/types';
import { BaseUserOutput } from 'api/User/types';
import { BaseEntityOutput } from 'api/Entities/types';
import { PartnerCardOutput } from 'api/Partners/types';

import EmployeeListItem from '../../../_shared/EmployeeListItem/EmployeeListItem';
import PartnerMask from '../../../_shared/PartnerMask';
import s from './styles.module.sass';
import { FilteredItemsState } from '../../types';

interface GeneralSearchSearchBlockProps {
  filteredItems: FilteredItemsState;
  loading: boolean;
  handleUpdate: (values: positionValues) => void;
  handleItemClick: (entity: BaseEntityOutput | PartnerCardOutput | BaseUserOutput | null, searchItemId: number, fieldName: keyof FilteredItemsState) => void;
  searchedItems?: SearchItemOutput[];
}

const GeneralSearchSearchBlock: React.FC<GeneralSearchSearchBlockProps> = (props) => {
  const { handleUpdate, filteredItems, loading, handleItemClick, searchedItems } = props;

  const renderSearchedItems = (item: SearchItemOutput) => {
    if (!_.isNil(item.user)) {
      const isActive = _.has(filteredItems.employees, item.searchItemId);

      return (
        <div
          key={item.searchItemId}
          onClick={() => handleItemClick(item.user, item.searchItemId, 'employees')}
          className={cx(s.searchListItem, { [s.activeSearchListItem]: isActive })}
        >
          <EmployeeListItem
            employee={item.user}
          />
        </div>
      );
    }

    if (!_.isNil(item.partner)) {
      const isActive = _.has(filteredItems.partners, item.searchItemId);

      return (
        <div
          key={item.searchItemId}
          onClick={() => handleItemClick(item.partner, item.searchItemId, 'partners')}
          className={cx(s.searchListItem, { [s.activeSearchListItem]: isActive })}
        >
          <Space>
            <PartnerMask
              wrapperColor={item.partner.color}
              mask={item.partner?.mask}
              partnerId={item.partner.partnerId}
              iconColor={item.partner.maskColor}
              isVacation={item.partner.isVacation}
            />
            <span>{item.partner.name}</span>
          </Space>
        </div>
      );
    }

    if (!_.isNil(item.entity)) {
      const isActive = _.has(filteredItems.entities, item.searchItemId);

      return (
        <div
          key={item.searchItemId}
          onClick={() => handleItemClick(item.entity, item.searchItemId, 'entities')}
          className={cx(s.searchListItem, { [s.activeSearchListItem]: isActive })}
        >
          <Space>
            <FontAwesomeIcon icon="cubes" style={{ width: 24, height: 24 }} />
            <span>{item.entity.name}</span>
          </Space>
        </div>
      );
    }
  };

  return (
    <div className={s.listBlock}>
      <div className={s.searchListWrapper}>
        <Scrollbars onUpdate={handleUpdate}>
          <Space direction="vertical" size={0} style={{ width: '100%' }}>
            {
              _.map(searchedItems, item => renderSearchedItems(item))
            }
          </Space>

        </Scrollbars>
        {loading && <Spin className={s.listSpinner} size="small" />}
      </div>
    </div>
  );
};

export default GeneralSearchSearchBlock;
