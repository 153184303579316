import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Spin } from 'antd';

import { getPartnerById } from 'api/Partners/requests';
import { EDIT_PARTNER_TABS } from 'helpers/constants/_common/tabs/administration';

import PartnerInfoSidebar from '../Sidebars/PartnerInfoSidebar/PartnerInfoSidebar';
import PagesSidebar from '../Root/components/PagesSidebar';
import useApi from '../../api/hooks/useApi/useApi';
import { PartnerDetailsOutput } from '../../api/Partners/types';
import s from './styles.module.sass';
import PartnerInfoTab from './components/PartnerInfoTab/PartnerInfoTab';
import { PATH_PARTNER_INFO, TAB_PARTNER_INFO } from '../../helpers/constants/_common/MenuCases';
import PartnerParticipantsTab from './components/PartnerParticipantsTab/PartnerParticipantsTab';
import PartnerActivitiesTab from './components/PartnerActivitiesTab/PartnerActivitiesTab';

const PartnerInfo = () => {
  const history = useHistory();
  const { partnerId, partnerTab } = useParams<{ partnerId: string; partnerTab: string }>();

  const [ getPartner, partner, loading, setPartner ] = useApi<typeof getPartnerById, PartnerDetailsOutput>(getPartnerById);

  useEffect(() => {
    if (_.isString(partnerId)) {
      getPartner(partnerId);
    }
  }, [ partnerId ]);

  useEffect(() => {
    if (!partnerTab || !_.includes(_.keys(EDIT_PARTNER_TABS), partnerTab)) {
      history.replace(`${PATH_PARTNER_INFO}/${partnerId}/${TAB_PARTNER_INFO}`);
    }
  }, [ partnerTab ]);

  const renderSidebar = () => {
    if (!partner || loading) {
      return null;
    }

    return (
      <PagesSidebar>
        <PartnerInfoSidebar
          partner={partner}
          tab={partnerTab}
        />
      </PagesSidebar>
    );
  };

  const renderContent = () => {
    if (!partner || loading) {
      return null;
    }

    switch (partnerTab) {
      case EDIT_PARTNER_TABS.info.path:
        return (
          <PartnerInfoTab
            partner={partner}
            setPartner={setPartner}
          />
        );

      case EDIT_PARTNER_TABS.activities.path:
        return (
          <PartnerActivitiesTab partner={partner} />
        );

      case EDIT_PARTNER_TABS.participants.path:
        return (
          <PartnerParticipantsTab partner={partner} />
        );
      default:
        return null;
    }
  };

  return (
    <Spin spinning={loading} wrapperClassName={s.partnerInfoWrapper}>
      { renderSidebar() }

      <div className={s.partnerIntoContent}>
        { renderContent() }
      </div>
    </Spin>
  );
};

export default PartnerInfo;
