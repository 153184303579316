import { useState, useEffect } from 'react';
/*
   This hook can be used for some interactive fields, where you need set state + debounce
   It can be used 'till useDeferredValue hooks would be released.
 */
const useDebounce = (value?: any, delay?: number) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },

    [ value ],
  );

  return debouncedValue;
};

export default useDebounce;
