import React, { useEffect } from 'react';
import _ from 'lodash';
import { Dayjs } from 'dayjs';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import cx from 'classnames';

import { BusinessMonthRangeOutput } from 'api/BusinessCalendar/types';
import BusinessMonthSelector from 'components/_shared/BusinessMonthSelector/BusinessMonthSelector';
import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT, RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import datepicker from 'helpers/styles/components/datepicker.module.sass';
import { ReactComponent as DateRangeIcon } from 'helpers/icons/dateRange.svg';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import useApi from 'api/hooks/useApi/useApi';
import { getPartnersList } from 'api/Partners/requests';
import { UserPartnerOutput } from 'api/Partners/types';
import { UserRedux } from 'redux/User/types';
import { USER_ROLES } from 'api/User/constants';

import { CuratorHourSettings } from '../../helpers/types';
import s from './styles.module.sass';

interface CuratorReportSettingsProps {
  user: UserRedux;
  setSettingsValues: React.Dispatch<React.SetStateAction<CuratorHourSettings>>;
  settingsValues: CuratorHourSettings;
  isBCLoading: boolean;
  handleOnChangeMonth: (date: string) => Promise<BusinessMonthRangeOutput>;
}

const CuratorReportSettings: React.FC<CuratorReportSettingsProps> = (props) => {
  const {
    isBCLoading, settingsValues,
    setSettingsValues, handleOnChangeMonth,
    user,
  } = props;

  const [ getPartners, partners, loadingPartners ] = useApi<typeof getPartnersList, UserPartnerOutput[]>(getPartnersList, { defaultData: [] });

  useEffect(() => {
    getPartners(user.userId, { role: USER_ROLES.CURATOR });
  }, []);

  const onChangeMonth = async (dayjsObj: Dayjs) => {
    const date = dayjsObj.format(MONTH_YEAR_FORMAT);

    setSettingsValues(({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: date,
      },
    }));

    const rangeDatesForMonth = await handleOnChangeMonth(date);

    setSettingsValues(prevSettingsValue => ({
      ...prevSettingsValue,
      dates: {
        ...prevSettingsValue.dates,
        selectedDate: date,
        startDateRange: rangeDatesForMonth?.startDay,
        endDateRange: rangeDatesForMonth?.endDay,
        modifiedMonth: rangeDatesForMonth?.modified,
      },
    }));
  };

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        startDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        endDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
  };

  const handleOnSearchEmployee = _.debounce((value) => {
    setSettingsValues({
      ...settingsValues,
      searchValue: value,
    });
  }, 1000);

  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.employeeReportSearch)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      size="large"
      onChange={e => handleOnSearchEmployee(e.currentTarget.value)}
      allowClear
      placeholder="Search employees..."
    />
  );

  const renderPartnerSelect = () => {
    const entitiesOptions = [
      <Select.Option value={null} key={null}>All partners</Select.Option>,
      ..._.map(partners, e => <Select.Option value={e.partner.partnerId} key={e.partner.partnerId}>{e.partner.name}</Select.Option>),
    ];

    return (
      <Select
        className={cx(selects.qsSelect, s.partnerSelect)}
        dropdownClassName={selects.selectDropdown}
        suffixIcon={<SelectIcon />}
        size="large"
        onChange={value => setSettingsValues({ ...settingsValues, partnerId: value })}
        value={settingsValues.partnerId}
        bordered={false}
        loading={loadingPartners}
      >
        {entitiesOptions}
      </Select>
    );
  };
  return (
    <div className={s.curatorReportSettingsLayout}>
      <BusinessMonthSelector
        className={s.reportMonthPicker}
        isModified={settingsValues.dates.modifiedMonth}
        onChangeMonth={onChangeMonth}
        selectedDate={settingsValues.dates.selectedDate}
        isLoading={isBCLoading}
        isCustomDate={_.isNull(settingsValues.dates.selectedDate)}
      />

      <div className={s.curatorReportFilter}>
        <DatePicker
          className={datepicker.singleDatepicker}
          onChange={handleStartDateChange}
          disabledDate={currentDate => currentDate > moment(settingsValues.dates.endDateRange)}
          value={moment(settingsValues.dates.startDateRange)}
          format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
          allowClear={false}
          size="large"
          suffixIcon={<DateRangeIcon />}
          disabled={isBCLoading}
        />

        <span className={cx(datepicker.datepickerSplitter, { [s.datepickerDividerLoading]: isBCLoading })}>—</span>

        <DatePicker
          disabled={isBCLoading}
          className={datepicker.singleDatepicker}
          onChange={handleEndDateChange}
          disabledDate={currentDate => currentDate < moment(settingsValues.dates.startDateRange)}
          value={moment(settingsValues.dates.endDateRange)}
          format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
          allowClear={false}
          size="large"
          suffixIcon={<DateRangeIcon />}
        />

        {renderPartnerSelect()}
        {renderInputSearch()}
      </div>

    </div>
  );
};

export default CuratorReportSettings;
