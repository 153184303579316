import { Column } from '../../_shared/Table/helpers/types';

export interface ISpecialistsTabs {
  find: 'find'
  requests: 'requests',
}

export const SPECIALISTS_TABS: ISpecialistsTabs = {
  find: 'find',
  requests: 'requests',
};

export const SPECIALISTS_READ_ONLY_COLUMNS: Column[] = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    key: 'fullName',
    width: 200,
  },
];

export const SPECIALISTS_COMMON_COLUMNS: Column[] = [
  ...SPECIALISTS_READ_ONLY_COLUMNS,
  {
    title: 'Position',
    dataIndex: 'occupation',
    key: 'occupation',
    width: 200,
  },
];

export const MAX_ELEMENTS_FOR_SCROLL = 6;
