import React from 'react';
import className from 'classnames/bind';
import S from './styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const cx = className.bind(S);

interface DropdownComponentProps {
  children: React.ReactElement;
  title?: string;
  partOfInputGroup?: boolean;
  className?: string;
  size?: number;
  style?: React.CSSProperties;
  nowrap?: boolean;
}

interface DropdownComponentState {
  opened: boolean;
}

export default class Dropdown extends React.Component<
  DropdownComponentProps,
  DropdownComponentState
> {
  private parent = React.createRef<HTMLDivElement>();

  constructor(props: DropdownComponentProps) {
    super(props);

    this.state = {
      opened: false
    };

    this.onClickOutside = this.onClickOutside.bind(this);
    this.openHandler = this.openHandler.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
  }

  openHandler(): void {
    this.setState({ opened: true });
    document.addEventListener('mousedown', this.onClickOutside);
  }

  closeHandler(): void {
    this.setState({ opened: false });
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onClickOutside(): void {
    setTimeout(() => {
      this.closeHandler();
    }, 250);
  }

  render(): React.ReactNode {
    const {
      children,
      title,
      partOfInputGroup,
      className,
      size,
      style
    } = this.props;
    const { opened } = this.state;
    const needToNoWrap = this.props.nowrap || false;
    const dropdownClass = cx({
      'qs-dropdown': true,
      'qs-dropdown--opened': opened
    });
    const triggerClass = cx(
      {
        'qs-dropdown__trigger': true,
        ['qs-dropdown__trigger--size-' + size]: size,
        'qs-dropdown__trigger--part-of-input-group': partOfInputGroup
      },
      className
    );

    return (
      <div className={dropdownClass}>
        <button
          className={triggerClass}
          onClick={this.openHandler}
          style={style}
        >
          {title} <FontAwesomeIcon icon={'caret-down'} />
        </button>
        <div
          className={cx('qs-dropdown__list', {
            'qs-dropdown__list--nowrap': needToNoWrap
          })}
          ref={this.parent}
        >
          {React.Children.map(children, (child: React.ReactElement) => {
            if (child) {
              const c = child && child.props && child.props.className;
              const className = `${c} ${cx('qs-dropdown__child-item')}`;

              return React.cloneElement(child, { className });
            } else {
              return child;
            }
          })}
        </div>
      </div>
    );
  }
}
