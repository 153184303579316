import dayjs from 'dayjs';

import { DayStatuses } from 'api/BusinessCalendar/types';
import { isDayOff } from 'api/BusinessCalendar/helpers';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

const defaultCalendarGenerator = () => {
  const now = dayjs();

  const generateDay = (day: string) => ({
    businessDayId: null,
    day,
    status: isDayOff(day) ? DayStatuses.DayOff : DayStatuses.Regular,
  });

  const generateMonth = (month: number) => ({
    businessMonthId: null,
    month,
    year: now.get('year'),
    days: Array(now.month(month - 1).daysInMonth()).fill(null).map((e, i) => generateDay(dayjs(`${now.get('year')}-${month}-${i + 1}`).format(DEFAULT_DATE_FORMAT))),
  });

  const generateYear = () => Array(12).fill(null).map((e, i) => generateMonth(i + 1));

  return generateYear();
};

export default defaultCalendarGenerator;
