import * as ErrorActions from '../../helpers/constants/_common/actions/ErrorHandler';
import { ErrorHandlerState } from './types';

const initialState: ErrorHandlerState = {};

function ErrorsReducer(state = initialState, action: any): any {
  switch (action.type) {
    case ErrorActions.SET_ERROR:
      return {
        ...action.payload,
      };

    case ErrorActions.CLEAR_ERROR:
      return initialState;

    default:
      return state;
  }
}

export default {
  initialState,
  ErrorsReducer,
};
