import { SidebarTabs } from './types';

export const EDIT_CURATOR_TABS: SidebarTabs = {
  general: {
    path: 'general',
    name: 'Personal Info',
  },
  skillset: {
    path: 'skillset',
    name: 'Skillset',
  },
  timesheet: {
    path: 'timesheet',
    name: 'Timesheet',
  },
};
