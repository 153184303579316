import React from 'react';
import { Button, Checkbox, Input, Select, Space } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import { ReactComponent as LeftArrow } from 'helpers/icons/Buttons/leftArrow.svg';
import { ReactComponent as RightArrow } from 'helpers/icons/Buttons/rightArrow.svg';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import selects from 'helpers/styles/components/selects.module.sass';

import { VacationReportFilters } from '../../helpers/types';
import s from './styles.module.sass';
import GeneralFiltersButton from '../../../GeneralFiltersButton/GeneralFiltersButton';
import { FilteredItemsChangeIds } from '../../../GeneralFiltersButton/types';

interface VacationReportSettingsProps {
  filters: VacationReportFilters;
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<VacationReportFilters>>;
}

const INITIAL_YEAR = 2011;

const VacationReportSettings: React.FC<VacationReportSettingsProps> = ({ filters, setFilters, setYear, year }) => {
  const debouncedSearchFunction = _.debounce(value => setFilters(prev => ({ ...prev, searchValue: value })), 500);

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearchFunction(e.currentTarget.value);
  };

  const renderYearsOptions = () => {
    const years = _.times((dayjs().year() - INITIAL_YEAR), i => INITIAL_YEAR + (i + 1));

    return (
      <Select
        className={s.vacationSelect}
        value={year}
        dropdownClassName={selects.selectDropdown}
        bordered={false}
        disabled={filters.showAll}
        onChange={value => setYear(value)}
        suffixIcon={null}
      >
        { _.map(years, year => <Select.Option key={year} value={year}>{year}</Select.Option>) }
      </Select>
    );
  };

  const handleStrongFilters = (ids: FilteredItemsChangeIds) => {
    setFilters(filters => ({ ...filters, ...ids }));
  };

  return (
    <div className={s.vacationReportSettingsWrapper}>
      <div className={s.settingsLeftCol}>
        <Space size={0}>

          <Button
            type="link"
            disabled={(year === INITIAL_YEAR) || filters.showAll}
            onClick={() => setYear(prevYear => (prevYear - 1))}
          >
            <LeftArrow className={cx({ [s.disabledYearButton]: (year === INITIAL_YEAR) || filters.showAll })} />
          </Button>

          { renderYearsOptions() }

          <Button
            type="link"
            disabled={(year === dayjs().year()) || filters.showAll}
            onClick={() => setYear(prevYear => (prevYear + 1))}
          >
            <RightArrow className={cx({ [s.disabledYearButton]: (year === dayjs().year()) || filters.showAll })} />
          </Button>
        </Space>

        <Checkbox
          onChange={e => setFilters(prev => ({ ...prev, showAll: e.target.checked }))}
          checked={filters.showAll}
        >
          Show all-time report
        </Checkbox>

        <Checkbox
          onChange={e => setFilters(prev => ({ ...prev, active: !e.target.checked }))}
          checked={!filters.active}
        >
          Show inactive
        </Checkbox>
      </div>

      <Space>
        <Input
          placeholder="Search..."
          onChange={handleOnSearchChange}
          className={cx(inputs.qsInput, s.vacationInput)}
          prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
          size="large"
          allowClear
        />

        <GeneralFiltersButton
          onSearch={handleStrongFilters}
        />
      </Space>

    </div>
  );
};

export default VacationReportSettings;
