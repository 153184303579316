import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import popover from 'helpers/styles/components/popover.module.sass';
import ADMINISTRATION_TABS from 'helpers/constants/_common/tabs/administration';
import ProfileViewField from 'components/_shared/ProfileViewField';
import { PagesSidebarProps } from 'components/Root/components/PagesSidebar';
import { Redux } from 'helpers/types/_common';
import { PATH_ADMINISTRATION } from 'helpers/constants/_common/MenuCases';

import s from '../helpers/styles.module.sass';
import SidebarCollapsedMenu, { PopoverVisibleFunction } from '../../Root/components/PagesSidebar/components/SidebarCollapsedMenu';
import { isAvailableByAccessType } from '../../../helpers/utils/checkRestrictions';

const cx = classNames.bind(s);

const AdministrationTabSidebar: React.FC<PagesSidebarProps> = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state: Redux) => state.login);

  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();

  useEffect(() => {
    const tabKeys = _.concat(_.keys(ADMINISTRATION_TABS), _.map(ADMINISTRATION_TABS, tab => tab.path()));

    if (!tab || !_.includes(tabKeys, tab)) {
      history.push(`${PATH_ADMINISTRATION}/${ADMINISTRATION_TABS.partners.path()}`);
    }
  }, [ tab, history ]);

  const popoverContent = (setPopoverVisible: PopoverVisibleFunction) => {
    const menuItems = _.map(ADMINISTRATION_TABS, tabMenu => (!isAvailableByAccessType(user.permissions, tabMenu.accessType) 
      ? null 
      : (
        <Link
          key={tabMenu.path(user.userId)}
          to={{
            pathname: `${PATH_ADMINISTRATION}/${tabMenu.path()}`,
            state: { from: PATH_ADMINISTRATION },
          }}
          className={cx(popover.dotsPopoverItem, { [popover.dotsPopoverItemActive]: tab === tabMenu.path(user.userId) })}
          onClick={() => setPopoverVisible(false)}
        >
          {tabMenu.name}
        </Link>
      )));

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        { menuItems }
      </div>
    );
  };

  const renderMenu = () => _.map(ADMINISTRATION_TABS, (menuTab) => {
    if (!isAvailableByAccessType(user.permissions, menuTab.accessType)) {
      return null;
    }

    return (
      <li
        key={menuTab.name}
        className={cx(s.sidebarListItem, { [s.activeSidebarList]: tab === menuTab.path(user.userId) })}
      >
        <Link
          to={{
            pathname: `${PATH_ADMINISTRATION}/${menuTab.path()}`,
            state: { from: PATH_ADMINISTRATION },
          }}
          className={s.sidebarListItemLink}
        >
          {menuTab.name}
        </Link>
      </li>
    );
  });

  return (
    <div className={s.sidebarLayout}>
      <SidebarCollapsedMenu
        popoverContent={popoverContent}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        tabName={tab && ADMINISTRATION_TABS[tab]?.name}
      />

      <div className={cx(s.sidebarContentWrapper, { [s.collapsed]: collapsed })}>
        <div className={s.sidebarHeader}>
          <ProfileViewField user={user} />
        </div>
        <ul className={s.sidebarList}>
          { renderMenu() }
        </ul>
      </div>
    </div>
  );
};

export default AdministrationTabSidebar;
