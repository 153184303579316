import React from 'react';
import classNames from 'classnames/bind';
import S from './styles.module.css';
import Group from './Group';
import InputGroup from './InputGroup';
import Label from './Label';
import Control from './Control';
import Select from './Select';
import Textarea from './Textarea';

const cx = classNames.bind(S);

interface FormComponentProps {
  children: React.ReactElement;
}

class Form extends React.Component<FormComponentProps> {
  public static InputGroup = InputGroup;
  public static Textarea = Textarea;
  public static Control = Control;
  public static Select = Select;
  public static Group = Group;
  public static Label = Label;

  render(): React.ReactNode {
    return (
      <form className={cx('qs-form')} {...this.props}>
        {this.props.children}
      </form>
    );
  }
}

export default Form;
