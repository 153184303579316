import * as Actions from '../../constants/VacancyPage/Vacancies';
import Subtypes from '../../constants/__common__/Subtypes';
import processCommonEvent from '../../utils/processCommonEvent';
import _ from 'lodash';
import {LIMIT} from '../../constants/__common__/PaginationConstants';

const InitialState = {
  vacanciesTable: {
    isLoading: false,
    errorMessage: '',
    objects: []
  },
  vacancyModal: {
    show: false,
    isLoading: false,
    errorMessage: '',
    selected: {
      position: '',
      author: {
        userId: 0,
        forename: '',
        surname: ''
      },
      description: {
        description: '',
        responsibilities: [''],
        requirements: [''],
        companyDescription: '',
        weOffer: [''],
        contact: ''
      },
      status: 1,
      affiliate: {affiliateId: 0, name: ''}
    }
  },
  recommendationProgramModalDescription: {
    show: false
  },
  vacancyRemoveConfirmationModal: {
    show: false,
    errorMessage: '',
    selected: {
      name: '',
      vacancyId: null,
      affiliate: {affiliateId: 0, name: ''}
    }
  },
  suspendingConfirmationModal: {
    show: false,
    errorMessage: '',
    selected: {
      name: '',
      vacancyId: null,
      affiliate: {affiliateId: 0, name: ''}
    }
  },
  allLocations: [],
  allVacancies: [],
  unreadVacanciesCount: 0
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.GET_VACANCIES:
      return getVacancies(action, state);

    case Actions.GET_ALL_VACANCIES:
      return getAllVacancies(action, state);

    case Actions.GET_AFFILIATES:
      return {...state, allLocations: action.payload};

    case Actions.UPDATE_UNREAD_VACANCIES_COUNT:
      return {...state, unreadVacanciesCount: action.payload};

    case Actions.GET_VACANCY_BY_ID:
      return getVacancyById(action, state);

    case Actions.MARK_ALL_AS_READ:
      return markAllAsRead(action, state);

    case Actions.ADD_VACANCY:
    case Actions.EDIT_VACANCY:
      return processVacancyModalEvents(action, state);

    case Actions.REMOVE_VACANCY:
      return removingVacancyEvent(action, state);

    case Actions.SUSPEND_VACANCY:
      return suspendingVacancyEvent(action, state);

    case Actions.VACANCY_REMOVE_CONFIRMATION_MODAL:
      return vacancyRemoveConfirmationModalToggle(action, state);
    case Actions.SUSPENDING_CONFIRMATION_MODAL:
      return suspendingConfirmationModalToggle(action, state);
    case Actions.VACANCY_MODAL:
      return vacancyModalToggle(action, state);
    case Actions.RECOMMENDATION_PROGRAM_MODAL_DESCRIPTION:
      return recommendationProgramModalDescription(action, state);

    case Actions.CHANGE_SELECTED_VACANCY:
      return {
        ...state,
        vacancyModal: {
          ...state.vacancyModal,
          selected: action.payload || InitialState.vacancyModal.selected
        }
      };
    case Actions.CHANGE_SELECTED_VACANCY_TO_SUSPENDING:
      return {
        ...state,
        suspendingConfirmationModal: {
          ...state.suspendingConfirmationModal,
          selected: action.payload || InitialState.suspendingConfirmationModal.selected
        }
      };
    case Actions.CHANGE_SELECTED_VACANCY_TO_REMOVE:
      return {
        ...state,
        vacancyRemoveConfirmationModal: {
          ...state.vacancyRemoveConfirmationModal,
          selected: action.payload || InitialState.vacancyRemoveConfirmationModal.selected
        }
      };

    case Actions.UPDATE_VACANCIES_LIST_WITH_NEW_ONE:
      const needToPickSliceWithoutLastOne = state.vacanciesTable.objects.length === LIMIT;
      const existedObjects = needToPickSliceWithoutLastOne ?
        state.vacanciesTable.objects.slice(0, -1) :
        state.vacanciesTable.objects;

      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, objects: [action.payload, ...existedObjects]}
      };
    case Actions.UPDATE_VACANCIES_WITH_SUSPENDED:
      const clonedObjectsToSuspend = _.cloneDeep(state.vacanciesTable.objects);
      const vacancyToSuspend = clonedObjectsToSuspend.find(vacancy => vacancy.vacancyId === action.payload);

      vacancyToSuspend.status = vacancyToSuspend.status === 1 ? 2 : 1;

      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, objects: clonedObjectsToSuspend}
      };
    case Actions.UPDATE_VACANCIES_WITH_REMOVED:
      const clonedObjectsToRemove = _.cloneDeep(state.vacanciesTable.objects);
      const vacancyToRemove = clonedObjectsToRemove.find(vacancy => vacancy.vacancyId === action.payload);

      vacancyToRemove.status = 3;

      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, objects: clonedObjectsToRemove}
      };
    case Actions.UPDATE_VACANCIES_WITH_UPDATED:
      const clonedObjectsToUpdate = _.cloneDeep(state.vacanciesTable.objects);
      const vacancyToUpdateIndex = clonedObjectsToUpdate.indexOf(clonedObjectsToUpdate.find(vacancy => vacancy.vacancyId === action.payload.vacancyId));

      clonedObjectsToUpdate[vacancyToUpdateIndex] = action.payload;

      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, objects: clonedObjectsToUpdate}
      };

    case Actions.VACANCIES_INITIATE:
      return InitialState;
    default:
      return state;
  }
}

const getVacancies = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: '', objects: action.payload.objects}
      };
    case Subtypes.Request.Error:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const getAllVacancies = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: ''},
        allVacancies: action.payload.objects
      };
    case Subtypes.Request.Error:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const getVacancyById = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, vacancyModal: {...state.vacancyModal, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        vacancyModal: {...state.vacancyModal, isLoading: false, errorMessage: '', objects: action.payload}
      };
    case Subtypes.Request.Error:
      return {...state, vacancyModal: {...state.vacancyModal, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const markAllAsRead = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: ''},
        unreadVacanciesCount: 0
      };
    case Subtypes.Request.Error:
      return {...state, vacanciesTable: {...state.vacanciesTable, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const processVacancyModalEvents = (action, state) => processCommonEvent.request('vacancyModal')(action, state);
const removingVacancyEvent = (action, state) => processCommonEvent.request('vacancyRemoveConfirmationModal')(action, state);
const suspendingVacancyEvent = (action, state) => processCommonEvent.request('suspendingConfirmationModal')(action, state);

const recommendationProgramModalDescription = (action, state) => processCommonEvent.modalToggle('recommendationProgramModalDescription', InitialState.recommendationProgramModalDescription)(action, state);
const vacancyModalToggle = (action, state) => processCommonEvent.modalToggle('vacancyModal', InitialState.vacancyModal)(action, state);
const suspendingConfirmationModalToggle = (action, state) => processCommonEvent.modalToggle('suspendingConfirmationModal', InitialState.suspendingConfirmationModal)(action, state);
const vacancyRemoveConfirmationModalToggle = (action, state) => processCommonEvent.modalToggle('vacancyRemoveConfirmationModal', InitialState.vacancyRemoveConfirmationModal)(action, state);

export default {
  InitialState,
  reducer
}
