import React, { useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getFromLocalStorage } from 'helpers/utils/localStorageService';
import { COLLAPSED_SIDEBAR } from 'helpers/constants/_common/localStorageConstants';

import s from './helpers/styles.module.sass';
import PagesSidebar from './components/PagesSidebar';
import { RouteItem } from './helpers/types';
import { Redux } from '../../helpers/types/_common';

interface RootProps {
  page: RouteItem;
}

const RootLayout: React.FC<RootProps> = ({ page }) => {
  const collapsedSidebar = getFromLocalStorage(COLLAPSED_SIDEBAR, false);

  const userData = useSelector((state: Redux) => state.login);

  const [ collapsed, setCollapsed ] = useState(() => collapsedSidebar);

  const Sidebar = page.sidebar;
  const Component = page.component;

  const renderSidebar = () => {
    if (!Sidebar) {
      return null;
    }

    return (
      <PagesSidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        <Sidebar
          userData={userData}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </PagesSidebar>
    );
  };

  return (
    <div className={s.rootWrapper}>

      { renderSidebar() }

      <div className={cx(s.contentWrapper, { [s.collapsed]: collapsed, [s.isSidebar]: Boolean(Sidebar) })}>
        <Component userData={userData} />
      </div>
    </div>
  );
};

export default RootLayout;
