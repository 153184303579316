import React, { useEffect } from 'react';
import { Button, Drawer, Space, Spin } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';

import { CalendarMonthData, MonthInnerType } from 'components/BusinessCalendar/components/Month/components/MonthInner/helpers/types';
import MonthInner from 'components/BusinessCalendar/components/Month/components/MonthInner/MonthInner';

import S from './helpers/styles.module.sass';
import { CalendarDrawerProps } from './helpers/types';
import buttons from '../../../helpers/styles/components/buttons.module.sass';

const cx = classNames.bind(S);

const CalendarDrawer: React.FC<CalendarDrawerProps> = (props) => {
  const { onClose, className, visible, drawerData, externalButtons, onRemoveAllDays, onDayStatusChange, onDayAmountChange, isLoading } = props;
  const { month, year, days } = drawerData?.currentMonthPeriod;
  const monthInnerData: CalendarMonthData = {
    month,
    year,
    days: {
      prevMonth: drawerData.prevMonthDays,
      currentMonth: days,
      nextMonth: drawerData.nextMonthDays,
    },
  };

  useEffect(() => {
  }, [ visible ]);

  const renderDrawerContent = () => (
    <div className={S.drawerContent}>
      <div className={S.monthWrapper}>
        <MonthInner
          monthInnerData={monthInnerData}
          monthInnerType={MonthInnerType.Drawer}
          onRemoveAllDays={onRemoveAllDays}
          onDayStatusChange={onDayStatusChange}
          onDayAmountChange={onDayAmountChange}
        />
      </div>
    </div>
  );

  const renderFooter = () => {
    const footerButtons = _.map(externalButtons, action => action(drawerData));

    return (
      <Space size={14}>
        <Button
          className={buttons.qsButton}
          onClick={() => onClose?.()}
        >
          Close
        </Button>
        {footerButtons}
      </Space>
    );
  };

  const omittedProps = _.omit(props, [ 'drawerData', 'externalButtons', 'onDayStatusChange', 'onDayAmountChange', 'onRemoveAllDays', 'isLoading' ]);

  return (
    <Drawer
      {...omittedProps}
      width={420}
      className={cx(S.drawer, className)}
      footer={renderFooter()}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        {renderDrawerContent()}
      </Spin>
    </Drawer>
  );
};

export default CalendarDrawer;
