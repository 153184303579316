import React, { useContext, useState } from 'react';
import { Button, Drawer, Menu, Space, Typography } from 'antd';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router';
import cx from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as HamburgerIcon } from 'helpers/icons/Buttons/hamburger.svg';
import { USER_ROLES } from 'api/User/constants';
import NavBarIcon from 'helpers/icons/NavBarMenu/NavBarIcon';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import { logout } from 'redux/User/actions';
import { Themes } from 'helpers/constants/_common/constants';
import { ReactComponent as SunIcon } from 'helpers/icons/sunIcon.svg';
import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross24.svg';

import s from './styles.module.sass';
import { RoutesList } from '../../Root/helpers/types';
import { MOBILE_MENU_FIELDS } from '../../Root/components/Menu/helpers/menuFields';
import { MenuField } from '../../Root/components/Menu/helpers/types';
import { ReactComponent as Logout } from '../../Root/components/Menu/helpers/icons/Logout.svg';
import TableAvatar from '../../TableComponents/TableAvatar';
import { ThemeContext } from '../../AppThemeWrapper/AppThemeWrapper';

interface MobileMenuProps {
  routes: RoutesList;
}

const MobileMenu: React.FC<MobileMenuProps> = () => {
  const dispatch: DispatchType = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { theme, setTheme } = useContext(ThemeContext);

  const { login } = useSelector((state: Redux) => state);

  const [ isDrawerOpen, setIsOpenDrawer ] = useState(false);
  const [ logoutLoading, setLogoutLoading ] = useState(false);
  const [ selectedMenuKeys, setSelectedMenuKeys ] = useState<string[]>(() => [ location.pathname ]);

  const commonFields = _.filter(MOBILE_MENU_FIELDS, m => (_.includes(m.type, USER_ROLES.USER)));

  const renderMainMenu = () => _.map(commonFields, (field) => {
    const navBarIcon = field.iconFileName ? <NavBarIcon iconFileName={field.iconFileName} className={s.mobileMenuIcon} /> : <FontAwesomeIcon icon="icons" />;

    if (field.submenu) {
      return renderSubmenu(field);
    }

    return (
      <Menu.Item key={field.path()}>
        <div className={s.linkContentWrapper}>
          {navBarIcon}
          {field.label}
        </div>
      </Menu.Item>
    );
  });

  const handleLogout = () => {
    if (logoutLoading) {
      return;
    }

    setLogoutLoading(true);

    dispatch(logout())
      .finally(() => setLogoutLoading(false));
  };

  const renderSubmenu = (field: MenuField) => {
    const navBarIcon = field.iconFileName ? <NavBarIcon iconFileName={field.iconFileName} className={s.mobileMenuIcon} /> : <FontAwesomeIcon icon="icons" />;
    const filteredMenus = _.filter(field.submenu, sub => !sub.hidden);

    return (
      <Menu.SubMenu
        icon={navBarIcon}
        title={(
          <div className={s.linkContentWrapper}>
            {`${field.label}`}
          </div>
        )}
        key={field.label}
      >
        {
          _.map(filteredMenus, submenu => (
            <Menu.Item key={submenu.path()} className={s.mobileMenuNestedItem}>
              {submenu.label}
            </Menu.Item>
          ))
        }
      </Menu.SubMenu>
    );
  };

  const menu = (
    <Menu
      defaultOpenKeys={[ 'Profile' ]}
      inlineIndent={24}
      mode="inline"
      selectedKeys={selectedMenuKeys}
      onSelect={selectedItem => setSelectedMenuKeys(selectedItem.selectedKeys)}
      onClick={(menu) => {
        history.push(menu.key);
        setIsOpenDrawer(false);
      }}
    >
      { renderMainMenu() }
    </Menu>
  );

  const footer = (
    <div className={s.mobileFooter}>
      {(theme === Themes.DARK)
        ? (
          <SunIcon
            className={s.changeThemeIcon}
            onClick={() => setTheme(Themes.LIGHT)}
          />
        )
        : (
          <FontAwesomeIcon
            size="lg"
            className={s.changeThemeIcon}
            icon="moon"
            onClick={() => setTheme(Themes.DARK)}
          />
        )}

      <Button
        type="text"
        className={cx(buttons.qsButton, s.mobileLogoutButton)}
        onClick={handleLogout}
      >
        { logoutLoading ? <LoadingOutlined /> : <Logout /> }
      </Button>
    </div>

  );

  return (
    <div className={s.mobileMenu}>
      <div />

      <Button
        type="text"
        shape="circle"
        onClick={() => setIsOpenDrawer(true)}
      >
        <HamburgerIcon className={s.hamburgerMobileIcon} />
      </Button>

      <Drawer
        className={s.mobileMenuDrawer}
        visible={isDrawerOpen}
        onClose={() => setIsOpenDrawer(false)}
        width={300}
        closable={false}
        footer={footer}
      >
        <div className={s.drawerBody}>
          <div className={s.drawerHeader}>
            <Space>
              <TableAvatar url={login.avatarUrl} />
              <Typography.Text title={`${login.forename} ${login.surname}`}>{`${login.forename} ${login.surname}`}</Typography.Text>
            </Space>

            <Button
              type="text"
              shape="circle"
              icon={<CloseIcon className={s.mobileMenuDrawerCloseIcon} />}
              onClick={() => setIsOpenDrawer(false)}
            />

          </div>

          {menu}
        </div>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
