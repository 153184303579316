import dayjs from 'dayjs';
import _ from 'lodash';

import { FlatUserHours } from 'components/TimeSheet/components/Table/components/TableBody/helpers/types';

import * as Actions from './constants';
import {
  updateTableAfterDeleteHelper,
  updateTableAfterAddOrReplaceHelper,
  updateTableAfterAddComment,
  updateTableAfterDeleteComment,
} from './helpers';
import { ContextMenuType, TimeSheetRedux, HoursDrawerMode } from './types';

const nowDayjs = dayjs();

const InitialState: TimeSheetRedux = {
  tableSettings: {
    isMonthClosed: false,
    isCtrlPressed: false,
    selectedDateObj: nowDayjs,
  },
  deleteUserHoursModal: {
    isVisible: false,
    startCellIndex: 0,
    endCellIndex: 1,
    selectionDay: null,
  },
  flatUserHoursTable: {},
  selection: {
    isShown: false,
    isLoading: false,
    selectedCells: {},
  },
  contextMenus: {
    [ContextMenuType.Selection]: {
      isVisible: false,
      selectedHours: [],
    },
    [ContextMenuType.Group]: {
      isVisible: false,
      cellIndex: 0,
      day: null,
    },
  },
  replaceDrawer: {
    isVisible: false,
    isLoading: false,
    mode: HoursDrawerMode.Replace,
    data: {
      selectedHours: [],
      startCellIndex: 0,
      endCellIndex: 1,
      selectionDay: null,
    },
  },
  selectedActivity: undefined,
  activities: [],
  partners: [],
};

function reducer(state = InitialState, action: any): any {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;

    case Actions.OPEN_DELETE_USER_HOURS_MODAL:
      return {
        ...state,
        deleteUserHoursModal: {
          ...state.deleteUserHoursModal,
          isVisible: true,
          ...action.payload,
        },
      };

    case Actions.CLOSE_DELETE_USER_HOURS_MODAL:
      return {
        ...state,
        deleteUserHoursModal: {
          ...state.deleteUserHoursModal,
          isVisible: false,
        },
      };

    case Actions.SET_REPLACE_DRAWER:
      return {
        ...state,
        replaceDrawer: {
          ...state.replaceDrawer,
          ...(action.payload || InitialState.replaceDrawer),
        },
      };

    case Actions.SET_FLAT_USER_HOURS_TABLE:
      return {
        ...state,
        flatUserHoursTable: action.payload,
      };

    case Actions.SET_SELECTION_BOX_LOADING:
      return {
        ...state,
        selection: {
          ...state.selection,
          isLoading: action.payload.isLoadingStatus,
        },
      };

    case Actions.DELETE_USER_HOUR: {
      const { day, cellIndexes } = action.payload;
      const updatedTable = updateTableAfterDeleteHelper(state, day, cellIndexes);
      return {
        ...state,
        flatUserHoursTable: updatedTable,
      };
    }

    case Actions.ADD_USER_HOUR_COMMENT: {
      const { day, cellIndexes, comment } = action.payload;
      const updatedTable = updateTableAfterAddComment(state, day, cellIndexes, comment);
      return {
        ...state,
        flatUserHoursTable: updatedTable,
      };
    }

    case Actions.DELETE_USER_HOUR_COMMENT: {
      const { day, cellIndexes } = action.payload;
      const updatedTable = updateTableAfterDeleteComment(state, day, cellIndexes);
      return {
        ...state,
        flatUserHoursTable: updatedTable,
      };
    }

    case Actions.ADD_USER_HOUR:
    case Actions.REPLACE_ACTIVITY:
    case Actions.REPLACE_AND_FILL_ACTIVITY:
    case Actions.REPLACE_USER_HOUR: {
      const { userHours } = action.payload;

      const updatedTable = updateTableAfterAddOrReplaceHelper(state, userHours);

      return {
        ...state,
        flatUserHoursTable: updatedTable,
      };
    }

    case Actions.SHOW_TIME_SELECTING:
      return {
        ...state,
        selection: {
          ...state.selection,
          isShown: true,
        },
      };

    case Actions.DROP_TIME_SELECTING:
      return {
        ...state,
        selection: InitialState.selection,
      };

    case Actions.SET_SELECTED_CELLS: {
      const selectedCells = _.reduce(action.payload.cellIds, (result, cellId) => ({
        ...result,
        [cellId]: state.flatUserHoursTable?.[cellId],
      }), {} as FlatUserHours);

      return {
        ...state,
        selection: {
          ...state.selection,
          selectedCells,
        },
      };
    }

    case Actions.CHANGE_DRAW_MODE:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          drawMode: action.payload,
        },
      };
    
    case Actions.OPEN_SELECTION_CONTEXT_MENU:
      return {
        ...state,
        contextMenus: {
          [ContextMenuType.Group]: InitialState.contextMenus[ContextMenuType.Group],
          [ContextMenuType.Selection]: {
            isVisible: true,
            selectedHours: action.payload.selectedHours,
          },
        },
      };

    case Actions.CLOSE_SELECTION_CONTEXT_MENU:
      return {
        ...state,
        contextMenus: {
          [ContextMenuType.Group]: InitialState.contextMenus[ContextMenuType.Group],
          [ContextMenuType.Selection]: {
            ...state.contextMenus[ContextMenuType.Selection],
            isVisible: false,
          },
        },
      };

    case Actions.INITIATE_CONTEXT_MENUS:
      return {
        ...state,
        contextMenus: InitialState.contextMenus,
      };

    case Actions.CTRL_PRESS_TOGGLE:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          isCtrlPressed: action.payload,
        },
      };

    case Actions.OPEN_GROUP_CONTEXT_MENU:
      return {
        ...state,
        contextMenus: {
          [ContextMenuType.Selection]: InitialState.contextMenus[ContextMenuType.Selection],
          [ContextMenuType.Group]: {
            isVisible: true,
            day: action.payload.day,
            cellIndex: action.payload.cellIndex,
          },
        },
      };

    case Actions.CLOSE_GROUP_CONTEXT_MENU:
      return {
        ...state,
        contextMenus: InitialState.contextMenus,
      };

    case Actions.CHANGE_SELECTED_DATE:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          selectedDateObj: action.payload,
        },
      };

    case Actions.CHANGE_LOCK_MONTH_STATUS:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          isMonthClosed: !state.tableSettings.isMonthClosed,
        },
      };

    case Actions.SET_LOCK_MONTH_STATUS:
      return {
        ...state,
        tableSettings: {
          ...state.tableSettings,
          isMonthClosed: action.isMonthClosed,
        },
      };

    case Actions.SET_ACTIVITY_ID:
      return {
        ...state,
        selectedActivity: action.selectedActivity,
      };

    case Actions.SET_CURRENTLY_IN_USE_ACTIVITY: {
      const updatedActivities = _.map(state.activities, activityObj => (
        activityObj.activity.activityId !== action.payload.activity.activityId
          ? {
            ...activityObj,
            currentlyInUse: false,
          }
          : {
            ...activityObj,
            currentlyInUse: !activityObj.currentlyInUse,
          }
      ));

      return {
        ...state,
        activities: updatedActivities,
      };
    }

    case Actions.SET_USER_PARTNERS:
    case Actions.UPDATE_USER_PARTNERS:
      return {
        ...state,
        partners: action.partners,
      };

    case Actions.SET_USER_ACTIVITIES:
    case Actions.UPDATE_USER_ACTIVITIES: {
      return {
        ...state,
        activities: action.activities,
      };
    }

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer,
};
