import React, { useEffect, useState } from 'react';
import { Col, Input, Modal, Row, Select, Space } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import common from 'helpers/styles/components/modals.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { createNewUser } from 'api/User/requests';
import InputWithError from 'components/_shared/InputWithError';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { UserStatisticOutput, UserStatisticStateFields } from 'api/User/types';
import { ValidationError } from 'helpers/types/_common';
import { EntityPage } from 'api/Entities/types';

import s from './helpers/styles.module.sass';
import { INCREASED_PAGINATION_RAW } from '../../../../helpers/constants/_common/Pagination';
import useApi from '../../../../api/hooks/useApi/useApi';
import { getEntities } from '../../../../api/Entities/requests';

interface EmployeeAddNewModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  getEmployeesData: () => void;
  employeeData?: UserStatisticOutput;
}

const DEFAULT_ENTITIES = {
  entities: [ {
    entityId: 1,
    name: 'QS Group LLC',
  } ],
};

const EmployeeAddNewModal: React.FC<EmployeeAddNewModalProps> = ({ visible, setVisible, employeeData, getEmployeesData }) => {
  const [ modalFields, setModalFields ] = useState<UserStatisticStateFields>(DEFAULT_ENTITIES);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ error, setError ] = useState<ValidationError | null>(null);

  const [ getEntitiesList, paginatedEntities, loading ] = useApi<typeof getEntities, EntityPage>(getEntities);

  useEffect(() => {
    if (visible && !paginatedEntities) {
      getEntitiesList(INCREASED_PAGINATION_RAW);
    }

    if (visible && employeeData) {
      const fields = _.pick(employeeData, [ 'forename', 'surname', 'occupation', 'emailAddress', 'entities', 'active', 'usertypeId' ]);

      setModalFields(fields);
    }

    if (!visible) {
      setModalFields(DEFAULT_ENTITIES);
      setSaveLoading(false);
      setError(null);
    }
  }, [ visible ]);

  const handleOnCreateNew = () => {
    const body = {
      ..._.omit(modalFields, 'entities'),
      entityIds: _.map(modalFields.entities, 'entityId'),
    };

    setSaveLoading(true);
    createNewUser(body)
      .then(() => {
        getEmployeesData();
        setVisible(false);
      })
      .catch((error) => {
        setError(error?.response?.data);
      })
      .finally(() => setSaveLoading(false));
  };

  const handleChangeEmail = (value: string) => {
    const splitByEmail = _.split(value, '@');
    const splitForName = _.split(splitByEmail[0], '.');
    setError(null);

    setModalFields({
      ...modalFields,
      emailAddress: value,
      forename: _.upperFirst(splitForName[0]),
      surname: _.upperFirst(splitForName[1]),
    });
  };

  const handleChangeField = (value: string | number | boolean, field: keyof UserStatisticStateFields) => {
    setError(null);
    setModalFields({
      ...modalFields,
      [field]: value,
    });
  };

  const handleEntitiesChange = (value: number[], options: any) => {
    setError(null);
    setModalFields({
      ...modalFields,
      entities: _.map(options, o => ({ entityId: o.value, name: o.children })),
    });
  };

  const renderFirstNameField = () => (
    <Space direction="vertical" size={4}>
      <span className={cx(s.employeeFieldTitle, { [s.required]: true })}>First Name</span>
      <Input
        placeholder="Enter first name"
        onChange={e => handleChangeField(e.target.value, 'forename')}
        value={modalFields.forename}
        size="large"
        minLength={2}
        maxLength={63}
        className={cx(inputs.qsInput, s.employeeInput)}
      />
    </Space>
  );

  const renderLastNameField = () => (
    <Space direction="vertical" size={4}>
      <span className={cx(s.employeeFieldTitle, { [s.required]: true })}>Last Name</span>
      <Input
        placeholder="Enter last name"
        onChange={e => handleChangeField(e.target.value, 'surname')}
        value={modalFields.surname}
        size="large"
        minLength={2}
        maxLength={63}
        className={cx(inputs.qsInput, s.employeeInput)}
      />
    </Space>
  );

  const renderEmailField = () => (
    <Space direction="vertical" size={4} style={{ width: '100%' }}>
      <span className={cx(s.employeeFieldTitle, { [s.required]: true })}>
        Email
      </span>

      <InputWithError
        error={error}
        fieldName={[ 'email_address', 'username' ]}
        placeholder="Enter email"
        onChange={e => handleChangeEmail(e.target.value)}
        value={modalFields.emailAddress}
        size="large"
        maxLength={255}
        className={cx(inputs.qsInput, s.employeeInput, { [s.wideInput]: true })}
      />
    </Space>
  );

  const renderOccupationSelect = () => (
    <Space direction="vertical">
      <span className={cx(s.employeeFieldTitle, { [s.required]: true })}>Position</span>
      <Select
        className={cx(selects.qsSelect, s.employeeSelect)}
        dropdownClassName={selects.selectDropdown}
        placeholder="Select position"
        suffixIcon={<SelectIcon />}
        size="large"
        bordered={false}
        onChange={value => handleChangeField(value, 'occupation')}
        value={modalFields.occupation}
      >
        {
          _.map(OCCUPATIONS, (o, key) => <Select.Option value={key} key={o.name}>{o.name}</Select.Option>)
        }
      </Select>
    </Space>
  );

  const renderEntitySelect = () => (
    <Space direction="vertical">
      <span className={cx(s.employeeFieldTitle, { [s.required]: true })}>Entities</span>
      <Select
        dropdownClassName={selects.selectDropdown}
        placeholder="Select entity"
        suffixIcon={<SelectIcon />}
        mode="multiple"
        size="large"
        bordered={false}
        onChange={handleEntitiesChange}
        value={loading ? undefined : _.map(modalFields.entities, 'entityId')}
        className={cx(selects.qsSelect, s.employeeSelect, s.entitySelect)}
      >
        {
          _.map(paginatedEntities?.objects, e => <Select.Option value={e.entityId} key={e.entityId}>{e.name}</Select.Option>)
        }
      </Select>
    </Space>
  );

  const disabledConfirmButton = _.isEmpty(modalFields.forename)
    || (modalFields.forename && modalFields.forename?.length < 2)
    || (modalFields.surname && modalFields.surname?.length < 2)
    || _.isEmpty(modalFields.surname) || _.isEmpty(modalFields.occupation)
    || _.isEmpty(modalFields.emailAddress) || _.isEmpty(modalFields.entities);

  return (
    <Modal
      title="Add new employee"
      className={common.qsBasicAntdModal}
      visible={visible}
      okText="Invite"
      width={500}
      centered
      confirmLoading={saveLoading}
      onOk={handleOnCreateNew}
      onCancel={() => setVisible(false)}
      okButtonProps={{
        className: common.modalOkBtn,
        size: 'large',
        disabled: disabledConfirmButton,
      }}
      cancelButtonProps={{
        className: common.modalCancelBtn,
        type: 'text',
      }}
    >
      <Row className={s.employeeModalRow} gutter={[ 11, 0 ]}>
        <Col span={24}>
          {renderEmailField()}
        </Col>
      </Row>

      <Row className={s.employeeModalRow} gutter={[ 11, 0 ]}>
        <Col span={12}>
          {renderFirstNameField()}
        </Col>
        <Col span={12}>
          {renderLastNameField()}
        </Col>
      </Row>

      <div className={s.splitter} />

      <Row className={s.employeeModalRow} gutter={[ 11, 0 ]}>
        <Col span={12}>
          {renderOccupationSelect()}
        </Col>
        <Col span={12}>
          {renderEntitySelect()}
        </Col>
      </Row>

    </Modal>
  );
};
export default EmployeeAddNewModal;
