import React from 'react';
import { useParams } from 'react-router-dom';

import ADMINISTRATION_TABS from 'helpers/constants/_common/tabs/administration';

const AdministrationTabContent = () => {
  const { tab } = useParams<{ tab: string }>();

  const renderTabContent = () => {
    if (!tab || !ADMINISTRATION_TABS[tab]?.component) {
      return null;
    }

    const TabComponent = ADMINISTRATION_TABS[tab].component;

    return TabComponent ? <TabComponent /> : null;
  };

  return (
    <div style={{ height: '100%' }}>
      {renderTabContent()}
    </div>
  );
};

export default AdministrationTabContent;
