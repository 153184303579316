import dayjs from 'dayjs';
import * as Actions from '../constants/BusinessCalendar';
import Subtypes from "../constants/__common__/Subtypes";
import generateEmptyMonthDays from '../utils/generateEmptyMonthDays';
import processCommonEvent from "../utils/processCommonEvent";

const InitialState = {
  monthsData: generateEmptyMonthDays({year: dayjs().year(), month: dayjs().month()}),
  lastReceivedMonth: 0,
  isLoading: false,
  errorMessage: '',
  monthSubmittingConfirmationModal: {
    show: false,
    isLoading: false,
    errorMessage: '',
    unapprovedWeekendsCount: 0,
    monthData: 0
  }
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;

    case Actions.STORE_MONTH_DATA:
      return {...state, monthsData: action.payload}

    case Actions.GET_CALENDAR:
      return getCalendar(action, state);

    case Actions.PERIOD_SUBMITTING_CONFIRMATION_MODAL:
      return monthSubmittingConfirmationModalToggle(action, state);

    case Actions.SUBMIT_MONTH:
    case Actions.UN_SUBMIT_MONTH:
      return submitAndUnSubmitMonth(action, state);

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer
}

const monthSubmittingConfirmationModalToggle = (action, state) => processCommonEvent.modalToggle('monthSubmittingConfirmationModal', InitialState.monthSubmittingConfirmationModal)(action, state);

const getCalendar = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, isLoading: true, errorMessage: ''};
    case Subtypes.Request.Success:
      return {...state, isLoading: false, errorMessage: '', monthsData: action.payload.monthsData, lastReceivedMonth: action.payload.lastReceivedMonth};
    case Subtypes.Request.Error:
      return {...state, isLoading: false, errorMessage: action.payload};
    default: return state;
  }
};

const submitAndUnSubmitMonth = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, isLoading: true, errorMessage: ''};
    case Subtypes.Request.Success:
      return {...state, isLoading: false, errorMessage: ''};
    case Subtypes.Request.Error:
      return {...state, isLoading: false, errorMessage: action.payload};
    default: return state;
  }
};
