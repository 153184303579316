import { Button, Input, Modal } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import modals from 'helpers/styles/components/modals.module.sass';
import { FoundPartnerOutput } from 'api/Partners/types';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { PARTNER_NAME_MIN_LENGTH } from 'helpers/constants/_common/Partners';
import useErrorForm from 'helpers/hooks/useErrorForm/useErrorForm';

import S from './styles.module.sass';

interface HoursConfirmModalProps {
  record: FoundPartnerOutput;
  isVisible: boolean;
  onClose: () => void;
  onOk: (newPartnerName: string) => Promise<any>;
}

interface RenamePartnerForm { name: string }

const PartnerRenameModal: React.FC<HoursConfirmModalProps> = ({ isVisible, onClose, onOk, record }) => {
  const [ newPartnerName, setNewPartnerName ] = useState(record.name);
  const [ isLoading, setLoading ] = useState(false);
  const [ errorHelper ] = useErrorForm<RenamePartnerForm>();

  useEffect(() => {
    errorHelper.clearErrorsState();
    
    setNewPartnerName(record.name);
  }, [ isVisible ]);
  
  if (!isVisible) return null;

  const okButtonHandle = () => {
    if (errorHelper.isWithErrors()) return;

    setLoading(true);
    onOk(newPartnerName)
      .then(onClose)
      .finally(() => setLoading(false));
  };

  const changeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
    errorHelper.doValidateByFieldChanging(name as keyof RenamePartnerForm, value, event);
    setNewPartnerName(value);
  };

  return (
    <Modal
      visible={isVisible}
      title="Rename Partner"
      onOk={okButtonHandle}
      centered
      closable={false}
      destroyOnClose
      onCancel={() => onClose()}
      className={classNames(modals.qsBasicAntdModal, S.confirmModal)}
      footer={[
        <Button
          key="back"
          type="text"
          className={classNames(modals.modalCancelBtn, S.modalButton)}
          style={{ marginRight: '0' }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          title="Confirm"
          loading={isLoading}
          disabled={errorHelper.isWithErrors()}
          className={classNames(modals.modalOkBtn, S.okButton, S.modalButton)}
          style={{ width: '130px' }}
          onClick={okButtonHandle}
        >
          Save
        </Button>,
      ]}
    >
      <div className={classNames(S.content, { [S.withoutTopPadding]: errorHelper.isErrorField('name') })}>
        {errorHelper.renderError('name')}
        <Input
          value={newPartnerName}
          className={classNames(inputs.qsInput, S.input, { [inputs.withError]: errorHelper.isErrorField('name') })}
          required
          name="name"
          min={PARTNER_NAME_MIN_LENGTH}
          onChange={changeField}
          placeholder="New partner name"
        />
      </div>
    </Modal>
  );
};

export default PartnerRenameModal;
