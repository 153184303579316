import React, { useState } from 'react';
import className from 'classnames/bind';
import { Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { REQUEST_SELECTED_PARTNER } from 'helpers/constants/_common/sessionStorageConstants';
import PartnerMask from 'components/_shared/PartnerMask';

import S from './helpers/styles.module.sass';
import { RequestsTableProps, DefaultSREditProps } from './helpers/types';
import columns from './helpers/columns';
import AddNewRequest from '../AddNewRequest';
import { SpecialistsRequest, SRStatuses } from '../../../../../../api/Specialists/types';
import RequestsTablePopover from './components/RequestsTablePopover';
import { EmployeeOccupationEnum } from '../../../../../../helpers/constants/Occupations/types';
import EditSRModal from './components/EditSRModal';
import { setSessionStorage } from '../../../../../../helpers/utils/sessionStorageService';

const cx = className.bind(S);

const RequestsTable: React.FC<RequestsTableProps> = ({ activity, partner, afterCreatingNewRequest }) => {
  const defaultValues = {
    status: SRStatuses.Hiring,
    occupation: EmployeeOccupationEnum.FullstackEngineer,
  };
  const [ editSRVisible, changeESRVisibility ] = useState(false);
  const [ defaultSRProps, changeDefaultSRProps ] = useState<DefaultSREditProps>(defaultValues);
  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();
  const noRequest = !activity?.specialistRequests?.length;

  const onRow = (record: SpecialistsRequest) => ({
    onClick: () => {
      if (tab && record?.specialistRequestId) {
        setSessionStorage(REQUEST_SELECTED_PARTNER, partner.name);
        history.push(`${tab}/${record.specialistRequestId}`);
      }
    },
  });

  const renderedColumns = columns.map((column) => {
    if (column.key === 'specialistRequestId') {
      return {
        ...column,
        render: (specialistRequestId: number, row: SpecialistsRequest) => (
          <RequestsTablePopover
            handleOnEdit={handleOnEdit}
            specialistRequest={row}
            refreshTable={afterCreatingNewRequest}
            partnerName={partner.name}
            activityName={activity.name}
            onRow={onRow}
          />
        ),
      };
    } else {
      return {
        ...column,
        onCell: onRow,
      };
    }
  });

  const handleOnEdit = (record: SpecialistsRequest) => {
    changeESRVisibility(true);
    changeDefaultSRProps({
      specialistRequestId: record.specialistRequestId,
      status: record.status,
      occupation: record.occupation,
    });
  };

  const onEditSuccess = () => {
    afterCreatingNewRequest();
    changeESRVisibility(false);
  };

  const onEditCancel = () => {
    changeESRVisibility(false);
  };

  const requestButton = () => (
    <AddNewRequest
      activity={activity}
      afterCreatingNewRequest={afterCreatingNewRequest}
    />
  );

  const renderBody = () => (
    <Table
      key={activity.activityId}
      rowKey="specialistRequestId"
      bordered={false}
      pagination={false}
      className={cx('requests-table_own')}
      dataSource={activity.specialistRequests}
      columns={renderedColumns}
    />
  );

  const renderNoRequestBody = () => (
    <div className={cx('requests-table_no-requests')}>
      <p className={cx('requests-table_no-requests-warning')}>No engineers was requested for this project yet</p>
      {requestButton()}
    </div>
  );

  return (
    <div className={cx('requests-table_wrapper')}>
      <div className={cx('requests-table_title-wrapper')}>
        <h4 className={cx('requests-table_title')}>
            <PartnerMask
              mask={partner.mask}
              partnerId={partner.partnerId}
              iconColor={activity.color}
              wrapperColor={partner.color}
              wrapperClassName={cx('requests-table_logo')}
              iconClassName={cx('requests-table_logo-svg')}
              isVacation={partner.isVacation}
            />
          {activity.name}
        </h4>
        {!noRequest && requestButton()}
      </div>
      {noRequest ? renderNoRequestBody() : renderBody()}
      <EditSRModal
        title="Edit specialist request"
        visible={editSRVisible}
        onSuccess={onEditSuccess}
        onCancel={onEditCancel}
        defaultValues={defaultSRProps}
      />
    </div>
  );
};

export default RequestsTable;
