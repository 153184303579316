import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Modal } from 'antd';
import _ from 'lodash';
import cx from 'classnames';
import TextArea from 'antd/es/input/TextArea';

import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import BasicTable from 'components/_shared/Table';
import { DispatchType, Redux } from 'helpers/types/_common';
import { Meta, Sorter } from 'api/_request/types';
import { getEntities } from 'redux/Entities/actions';
import { ReactComponent as EditIcon } from 'helpers/icons/Buttons/edit-icon.svg';
import { EntityBaseOutput, NewEntityBody, UpdateEntityBody } from 'api/Entities/types';
import inputs from 'helpers/styles/components/inputs.module.sass';
import modals from 'helpers/styles/components/modals.module.sass';
import { updateEntity } from 'api/Entities/requests';

import s from './styles.module.sass';
import { ENTITIES_COLUMNS } from './helpers/entititesColumns';

interface EntitiesTableProps {
}

const EntitiesTable: React.FC<EntitiesTableProps> = () => {
  const [ form ] = Form.useForm();

  const dispatch: DispatchType = useDispatch();
  const [ validateError, setValidateError ] = useState<object>({});

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.ENTITIES]);

  useEffect(() => {
    getEntitiesList({ limit: meta.limit, offset: meta.offset }, sorter);
  }, []);

  const getEntitiesList = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      ...pagination,
      ...sorter,
    };

    return dispatch(getEntities(body, TABLE_NAMES.ENTITIES));
  };

  const handleOnSave = (values: UpdateEntityBody) => updateEntity(values)
    .then(() => getEntitiesList(meta, sorter))
    .catch((error) => {
      setValidateError(error.response.data);
      return Promise.reject(error);
    });

  const handleOnEditClick = (record: EntityBaseOutput) => {
    Modal.confirm({
      icon: false,
      maskClosable: true,
      centered: true,
      title: 'Edit entity',
      className: modals.qsConfirmAntdModal,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      content: (
        <Form
          form={form}
          name={record.name}
          className={s.newEntityForm}
        >
          <Form.Item
            name="name"
            validateStatus={_.has(validateError, 'name') ? 'error' : undefined}
            help={_.has(validateError, 'name') ? validateError.name : undefined}
            rules={[ { required: true, message: 'Please input entity name' } ]}
          >
            <Input
              className={cx(inputs.qsInput, s.newEntityInput)}
              onChange={() => {
                if (_.has(validateError, 'name')) {
                  setValidateError(_.omit(validateError, 'name'));
                }
              }}
              placeholder="Type name of entity..."
              size="large"
              maxLength={255}
              allowClear
              autoFocus
            />

          </Form.Item>

          <Form.Item name="description">
            <TextArea
              placeholder="Description (optional)"
              className={inputs.trackerTextArea}
              maxLength={255}
              rows={5}
            />
          </Form.Item>
        </Form>
      ),
      okText: 'Edit',
      onOk: () => {
        const values: NewEntityBody = form.getFieldsValue();

        return handleOnSave({ entityId: record.entityId, ..._.omitBy(values, _.isNil) });
      },
    });

    form.setFieldsValue({ name: record.name, description: record.description });
  };

  const renderColumns = {
    id: {
      render: (text: undefined, record: EntityBaseOutput) => record.entityId,
    },

    actions: {
      render: (text: undefined, record: EntityBaseOutput) => (
        <Button
          type="text"
          className={s.editEntityIcon}
          onClick={() => handleOnEditClick(record)}
        >
          <EditIcon />
        </Button>
      ),
    },
  };

  return (
    <div>
      <BasicTable
        className={s.entitiesTable}
        bordered
        rowKey="entityId"
        initialRequest={false}
        getData={getEntitiesList}
        pagination={meta}
        renderColumns={renderColumns}
        tableName={TABLE_NAMES.ENTITIES}
        dataSource={objects}
        loading={loading}
        columns={ENTITIES_COLUMNS}
      />
    </div>
  );
};

export default EntitiesTable;
