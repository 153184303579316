import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';
import _ from 'lodash';

const cx = classNames.bind(S);

type FormControlComponentProps = {
  className?: string;
} & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  type?: string;
  max?: number;
  min?: number;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
};

export default class Control extends React.Component<
  FormControlComponentProps
> {
  render(): React.ReactNode {
    const props = _.assign(_.cloneDeep(this.props), {
      className: cx('qs-form__control', this.props.className)
    });

    return <input {...props} />;
  }
}
