import React, { useState } from 'react';
import { Radio, Space, Typography } from 'antd';
import _ from 'lodash';

import ECharts from 'components/_shared/ECharts/ECharts';

import s from './styles.module.sass';
import { BASE_PIE_OPTIONS } from '../_shared/ECharts/helpers/constants';
import radio from '../../helpers/styles/components/radio.module.sass';

interface VacationReportPageProps {
}

const VacationReportPage: React.FC<VacationReportPageProps> = () => {
  const [ yearType, setYearType ] = useState('current');

  const renderCurrentYearChart = () => {
    if (yearType !== 'current') {
      return null;
    }

    return (
      <div className={s.vacationYearChartsLayout}>
        <div style={{ width: 800 }}>
          <ECharts
            style={{ height: 800 }}
            option={BASE_PIE_OPTIONS}
          />
        </div>

        <Space direction="vertical">
          <Typography.Text>Ant Design (default)</Typography.Text>
          <Typography.Text type="secondary">Ant Design (secondary)</Typography.Text>
          <Typography.Text type="success">Ant Design (success)</Typography.Text>
          <Typography.Text type="warning">Ant Design (warning)</Typography.Text>
          <Typography.Text type="danger">Ant Design (danger)</Typography.Text>
          <Typography.Text disabled>Ant Design (disabled)</Typography.Text>
          <Typography.Text mark>Ant Design (mark)</Typography.Text>
          <Typography.Text code>Ant Design (code)</Typography.Text>
          <Typography.Text keyboard>Ant Design (keyboard)</Typography.Text>
          <Typography.Text underline>Ant Design (underline)</Typography.Text>
          <Typography.Text delete>Ant Design (delete)</Typography.Text>
          <Typography.Text strong>Ant Design (strong)</Typography.Text>
        </Space>
      </div>
    );
  };

  const renderAllTimeCharts = () => {
    if (yearType !== 'all') {
      return;
    }

    const randomYearsMap = _.times(_.random(1, 10), String);

    return (
      <div className={s.vacationAllTimeChartsLayout}>
        { _.map(randomYearsMap, i => (
          <div className={s.vacationYearChartsLayout} key={i}>
            <div style={{ width: 300 }}>
              <ECharts
                style={{ height: 300 }}
                option={BASE_PIE_OPTIONS}
              />
            </div>

            <Space direction="vertical">
              <Typography.Text>Ant Design (default)</Typography.Text>
              <Typography.Text type="secondary">Ant Design (secondary)</Typography.Text>
              <Typography.Text type="success">Ant Design (success)</Typography.Text>
              <Typography.Text type="warning">Ant Design (warning)</Typography.Text>
              <Typography.Text type="danger">Ant Design (danger)</Typography.Text>
              <Typography.Text disabled>Ant Design (disabled)</Typography.Text>
            </Space>
          </div>
        )) }
      </div>
    );
  };

  return (
    <div className={s.vacationPage}>
      <div>
        <Radio.Group
          name="calendar-type"
          className={radio.solidRadioGroup}
          value={yearType}
          onChange={e => setYearType(e.target.value)}
        >
          <Radio.Button
            value="current"
            className={radio.solidRadioItem}
          >
            Current year
          </Radio.Button>
          <Radio.Button
            className={radio.solidRadioItem}
            value="all"
          >
            All time
          </Radio.Button>
        </Radio.Group>
      </div>

      <div className={s.vacationChartLayout}>
        { renderCurrentYearChart() }
        { renderAllTimeCharts() }
      </div>

    </div>
  );
};

export default VacationReportPage;
