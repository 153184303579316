import React from 'react';
import S from '../styles.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(S);

interface LayoutCenteredComponentProps {
  children: React.ReactElement;
  vertical: boolean;
  horizontal: boolean;
  className: string;
  style: React.CSSProperties;
}

export default class Centered extends React.Component<
  LayoutCenteredComponentProps,
  {}
> {
  render(): React.ReactNode {
    const { children, vertical, horizontal, className, style } = this.props;

    const colClass = cx(
      {
        'qs-layout__centered': true,
        'qs-layout__centered--vertical': vertical,
        'qs-layout__centered--horizontal': horizontal
      },
      className
    );

    return (
      <div className={colClass} style={style}>
        {children}
      </div>
    );
  }
}
