import {
  GetSkillsByCategoryResponse,
  UserSkillToUpdate,
  RecursivelySkillBody, RecursivelyUserSkillBody, FoundSkillPage, FoundSkill, UserSkillPage, CreatedSkills,
} from './types';
import { AttitudesEnum, SkillCategoryEnum, UserSkill } from '../../helpers/types/Skills.js';
import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import { updatedSkillTags } from './helpers';
import { DEFAULT_SELECT_PAGINATION, DefaultPaginationRaw, INCREASED_PAGINATION_RAW } from '../../helpers/constants/_common/Pagination';
import { api } from '../../index';
import { UserSkillOutput } from '../User/types';

const resourceUrlUsers = '/users';
const resourceUrlSkills = '/skills';

const getSkillsByCategoryAndName = (name: string, categoryName?: SkillCategoryEnum, page?: number, limit?: number) => api.get<GetSkillsByCategoryResponse>(`${resourceUrlSkills}/`, {
  ...(categoryName ? { category: categoryName } : {}),
  name_contains: name,
  synonym_name_contains: name,
  order_by: 'name',
  offset: page || 0,
  limit: limit || DEFAULT_SELECT_PAGINATION.meta.limit,
});

const updateUserSkills = (userId: number, updatedUsersSkills: UserSkill[]) => {
  const body = updatedSkillTags(updatedUsersSkills);

  return api.patch<CreatedSkills>(`${resourceUrlUsers}/${userId}/skills/`, { operations: body })
    .catch((error) => { throw error; });
};

const updateUserSkill = (userId: number, skillToUpdate: UserSkillToUpdate) => {
  const data: UserSkillToUpdate = { attitude: skillToUpdate.attitude };

  if (skillToUpdate.experience) data.experience = skillToUpdate.experience;
  if (Object(skillToUpdate).hasOwnProperty('currentlyInUse')) data.currentlyInUse = skillToUpdate.currentlyInUse;

  return api.patch(`${resourceUrlUsers}/${userId}/skills/${skillToUpdate.skillId}/`, data);
};

const updateAttitudes = ({ attitude, skillId, userId }: { attitude: AttitudesEnum | null, skillId: number, userId: number }) => api.patch(`${resourceUrlUsers}/${userId}/skills/${skillId}/`, {
  attitude,
});

export const getSkillsRecursively = async (body: RecursivelySkillBody) => {
  const skills: FoundSkill[] = [];

  const recursivelyPartnersRequest = async (pagination: DefaultPaginationRaw) => {
    await api.get<FoundSkillPage>('/skills/',
      {
        order_by: 'name',
        ...convertObjectKeysToLanguageCase(body, 'snakeCase'),
        ...pagination,
      })
      .then((response: FoundSkillPage) => {
        skills.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > skills.length) {
          return recursivelyPartnersRequest({
            limit: response.meta.limit,
            offset: response.meta.offset + response.meta.limit,
          });
        }
      });
  };

  await recursivelyPartnersRequest({
    limit: INCREASED_PAGINATION_RAW.limit,
    offset: INCREASED_PAGINATION_RAW.offset,
  });

  return skills;
};

export const getUserSkillsRecursively = async (userId: number, body?: RecursivelyUserSkillBody) => {
  const skills: UserSkillOutput[] = [];

  const recursivelyUserSkillsRequest = async (pagination: DefaultPaginationRaw) => {
    await api.get<UserSkillPage>(`/users/${userId}/skills/`,
      {
        ...convertObjectKeysToLanguageCase(body, 'snakeCase'),
        ...pagination,
      })
      .then((response: UserSkillPage) => {
        skills.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > skills.length) {
          return recursivelyUserSkillsRequest({
            limit: response.meta.limit,
            offset: response.meta.offset + response.meta.limit,
          });
        }
      });
  };

  await recursivelyUserSkillsRequest({
    limit: body?.limit || INCREASED_PAGINATION_RAW.limit,
    offset: INCREASED_PAGINATION_RAW.offset,
  });

  return skills;
};

export default {
  getSkillsByCategoryAndName,
  updateUserSkills,
  updateUserSkill,
  updateAttitudes,
};
