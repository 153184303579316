import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import _ from 'lodash';

import { BCJointStatus, BCMonth, HoursType, UsersHourResponse } from 'api/TimeSheet/types';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import TABLE_CELLS from '../components/Table/helpers/constants';
import { FlatUserHours } from '../components/Table/components/TableBody/helpers/types';
import { CellType, EmptyCell, GroupOfCells, UserHoursTable } from './types';

dayjs.extend(objectSupport);
dayjs.extend(utc);

/** Returns HH:mm by cellIndex. */
export const getSimpleTimeByCellIndex = (i: number) => {
  const regularCase = Math.floor(i / 2) < 10 
    ? `0${Math.floor(i / 2)}`
    : `${Math.floor(i / 2)}`;
  const hour = Math.floor(i / 2) !== 24 // end of day not at 24:00 but at 00:00, keep it in mind
    ? regularCase
    : '00';
  const minutes = i % 2 === 0 ? '00' : '30';
  return `${hour}:${minutes}`;
};

/** Returns cellIndex by time. */
export const getHalfHoursByTime = (time: string) => {
  const dayjsObj = dayjs.utc(time);
  const h = dayjsObj.hour();
  const m = dayjsObj.minute();

  return h * 2 + (m ? 1 : 0); // double of hours + 1 half hour if it's exist
};

/** Returns formatted Time by Date (YYYY-MM-DD) and cellIndex (amount of half hour - 1: 0 is 00:00 and 47 is 23:30).
 *  You can specify format or skip it to get default timestamp format. */
export const getTimeByDateAndCellIndex = (date: string, cellIndex: number, format: string = '') => {
  const minutes = cellIndex * 30;

  return dayjs.utc(date).add(minutes, 'minute').format(format);
};

export const getDateAndCellIndexByTime = (time: string) => {
  const defaultResult = {
    day: null,
    cellIndex: null,
  };

  if (!time) return defaultResult;

  const dayjsObj = dayjs.utc(time);

  if (!dayjsObj.isValid()) return defaultResult;

  const date = dayjsObj.format(DEFAULT_DATE_FORMAT);
  const minutes = dayjsObj.get('minutes');
  const hours = dayjsObj.get('hours');
  const cellIndex = hours * 2 + (minutes === 30 ? 1 : 0);

  return {
    day: date,
    cellIndex,
  };
};

/** Returns days amount of some month, by month number and year. Returns 0 is some input is incorrect. */
export const getMonthDaysByMonthAndYear = (month: number, year: number) => {
  if (_.isNil(month) || _.isNil(year)) return 0;

  return dayjs(`${year}-${month}-1`).daysInMonth();  
};

/** Returns flat userHours structure by userHours[] and BCMonth. Cells without stored userHour fills with undefined. It stored in redux for future usage. */
export const getFlatUserHoursList = (userHours: UsersHourResponse[], month: BCMonth): FlatUserHours => {
  const { jointDays } = month;
  const allBCMonthDays = jointDays;
  const flatStructure = convertUserHoursToFlat(userHours);

  const flatStructureWithEmptyCells = _.reduce(allBCMonthDays, (result, dayObj) => {
    const dayData = _.reduce(TABLE_CELLS, (result, cellObj) => {
      const key = getTimeByDateAndCellIndex(dayObj.day, cellObj.cellIndex);
      const isCutDay = dayObj.jointStatus === BCJointStatus.Cut;

      // we should know about cut days on flat structure
      const flatDayObject = (isCutDay && flatStructure[key]) ? { 
        [key]: { ...flatStructure[key], cutDay: isCutDay },
      } : { 
        [key]: flatStructure[key],
      };

      return {
        ...result,
        ...flatDayObject,
      };
    }, {});

    return {
      ...result,
      ...dayData,
    };
  }, {});

  return flatStructureWithEmptyCells;
};

/** Returns flat userHours structure by userHours[]. */
export const convertUserHoursToFlat = (userHours: UsersHourResponse[]): FlatUserHours => _.reduce(userHours, (result, userHourObj) => {
  const startTimeDayjsObj = dayjs.utc(userHourObj.startTime);
  const originalRecordingTimeStamps = {
    startTime: userHourObj.startTime,
    endTime: userHourObj.endTime,
  };

  if (userHourObj.hours === HoursType.Hour) {
    const endTimeDayjsObj = startTimeDayjsObj.add(30, 'minutes');

    const firstHalfHour = {
      [startTimeDayjsObj.format()]: {
        ...userHourObj,
        hours: HoursType.HalfHour,
        endTime: endTimeDayjsObj.format(),
        originalRecordingTimeStamps,
      },
    };
    const secondHalfHour = {
      [endTimeDayjsObj.format()]: {
        ...userHourObj,
        hours: HoursType.HalfHour,
        startTime: endTimeDayjsObj.format(),
        endTime: endTimeDayjsObj.add(30, 'minutes').format(),
        originalRecordingTimeStamps,
      },
    };
    return {
      ...result,
      ...firstHalfHour,
      ...secondHalfHour,
    };
  } else {
    return {
      ...result,
      [startTimeDayjsObj.format()]: {
        ...userHourObj,
        originalRecordingTimeStamps,
      },
    };
  }
}, {});

/** Returns flat timesheet table to render by flat userHours structure and BCMonth. */
export const getUserHoursTable = (flatUserHours: FlatUserHours, month: BCMonth): UserHoursTable => {
  const { jointDays } = month;
  const allBCMonthDays = jointDays;

  return _.reduce(allBCMonthDays, (result, dayObj) => {
    const columnDays: (GroupOfCells | EmptyCell)[] = _.reduce(TABLE_CELLS, (result, cellObj) => {
      const clonedResult = [ ...result ];
      const emptyCell: EmptyCell = cellObj;
      const lastCell: EmptyCell | GroupOfCells = clonedResult[clonedResult.length - 1];
      const key = getTimeByDateAndCellIndex(dayObj.day, cellObj.cellIndex);
      const userHour = flatUserHours[key];

      if (!userHour) { // this cell is empty
        return [
          ...result,
          emptyCell,
        ];
      } else {
        const filledCell: GroupOfCells = {
          cellIndex: cellObj.cellIndex,
          timesheetId: userHour.timesheetId,
          type: CellType.Stored,
          userHourData: {
            activity: userHour.activity,
            partner: userHour.partner,
          },
          groupCells: [ {
            cellIndex: cellObj.cellIndex,
            type: CellType.Stored,
            comment: userHour.comment,
            timesheetId: userHour.timesheetId || -1,
          } ],
        };

        if (lastCell && lastCell.type === CellType.Stored) {
          const lastGroupCell = lastCell as GroupOfCells;
          const lastCellActivity = lastGroupCell.userHourData.activity;
          const lastCellPartner = lastGroupCell.userHourData.partner;

          const isLastCellRefersToSameActivity = lastCellActivity?.activityId === userHour.activity?.activityId && !_.isNil(userHour.activity?.activityId);
          const isLastCellRefersToSamePartner = lastCellPartner?.partnerId === userHour.partner?.partnerId && !_.isNil(userHour.partner?.partnerId);
          const isLastCellRefersToSameUserHour = isLastCellRefersToSameActivity && isLastCellRefersToSamePartner;

          if (isLastCellRefersToSameUserHour) { // last group cell refers to the same data as this cell
            lastGroupCell.groupCells = [ ...lastGroupCell.groupCells, {
              cellIndex: cellObj.cellIndex,
              type: CellType.Stored,
              comment: userHour.comment,
              timesheetId: userHour.timesheetId || -1,
            } ];

            return clonedResult;
          } else { // this cell is separated from last one
            return [
              ...result,
              filledCell,
            ];
          }
        } else {
          return [
            ...result,
            filledCell,
          ];
        }
      }
    }, [] as (GroupOfCells | EmptyCell)[]);

    return {
      ...result,
      [dayObj.day]: {
        ...dayObj,
        cells: columnDays,
      },
    };
  }, {} as UserHoursTable);
};
