import _ from 'lodash';

import { EditableUserHourComment } from 'api/TimeSheet/types';

import { CommentEditingStates } from '../../UserHourComments/helpers/types';
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from './constants';

export const getRightTitle = (length: number) => {
  const isCommentTooSmall = length < MIN_COMMENT_LENGTH;
  const isCommentTooBig = length > MAX_COMMENT_LENGTH;

  switch (true) {
    case isCommentTooSmall:
      return `Comment length can't be less then ${MIN_COMMENT_LENGTH}`;
    case isCommentTooBig:
      return `Comment length can't be more then ${MAX_COMMENT_LENGTH}`;
    default:
      return 'Save comment';
  }
};

export const getEditedComment = (editableCommentsList: EditableUserHourComment[]) => {
  const editedComment = _.find(editableCommentsList, comment => comment.state === CommentEditingStates.Active);
  
  return editedComment || null;
};
