import _ from 'lodash';

import { EnglishLevelEnum } from '../../../../../../../../../helpers/types/_common';
import { setRequestSpecialistsInfo, setSkillsExperienceParams } from '../../../../../../../../../redux/Specialists/actions';
import { SpecialistsSkills } from '../../../../../../../helpers/types';

export const onClearPromises = (specialistRequestId: string, englishKnowledge?: string, skills?: SpecialistsSkills) => {
  let promisesArray: Array<() => Promise<any>> = [];

  if (!_.isEqual(englishKnowledge, EnglishLevelEnum.Zero)) {
    promisesArray = [ ...promisesArray, () => setRequestSpecialistsInfo({ englishKnowledge: EnglishLevelEnum.Zero }, specialistRequestId) ];
  }

  if (!_.isEqual(skills, {})) {
    promisesArray = [ ...promisesArray, () => setSkillsExperienceParams({}, specialistRequestId) ];
  }

  return promisesArray;
};
