import React, { useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';

import s from './styles.module.sass';

export interface ErrorHelpers<T> {
  isErrorField: (name: keyof T) => boolean;
  isWithErrors: () => boolean;
  renderError: (fieldName: keyof T) => React.ReactNode | null,
  clearErrorsState: () => void;
  doValidateByFieldChanging: (key: keyof T, value: any, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isRequired?: boolean) => void;
}

type UseErrorFormResult<T> = [
  ErrorHelpers<T>,
  React.Dispatch<React.SetStateAction<T>>,
  T,
];

const useErrorForm = <T extends {}>(defaultErrorState?: T) => {
  const [ errors, setErrors ] = useState<T>({} as T);

  const renderError = (fieldName: keyof T) => {
    const isFieldError = isErrorField(fieldName);

    return isFieldError ? (
      <small
        className={cx({ [s.profileInputError]: isFieldError })}
      >
        {errors[fieldName as keyof T]}
      </small>
    ) : null;
  };

  const isErrorField = (name: keyof T) => !_.isUndefined(errors[name]);

  const isWithErrors = () => !_.isEmpty(errors);

  const clearErrorsState = () => setErrors(_.isUndefined(defaultErrorState) ? {} as T : defaultErrorState);

  const doValidateByFieldChanging = (key: keyof T, value: any, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isRequired?: boolean) => {
    const isFieldIsRequired = (!_.isUndefined(event) && event.target.required) || isRequired;

    const exceptionForRequiredField = isFieldIsRequired && (value.length === 0 || _.isNil(value));

    // for required fields cases
    if (exceptionForRequiredField) {
      return setErrors({ ...errors, [key]: [ 'Required' ] });
    }

    // for min/max input cases
    if (
      !_.isUndefined(event) 
      && (event.target instanceof HTMLInputElement) 
    ) {
      if (
        !_.isNil(event.target.min) 
        && typeof value === 'string'
        && value.length < parseInt(event.target.min, 10)
      ) {
        return setErrors({
          ...errors,
          [key]: [ `${_.upperFirst(key as string)} must not be less than ${event.target.min} symbols` ],
        });
      } else if (
        !_.isNil(event.target.max) 
        && typeof value === 'string'
        && value.length > parseInt(event.target.max, 10)
      ) {
        return setErrors({
          ...errors,
          [key]: [ `${_.upperFirst(key as string)} must not be more than ${event.target.min} symbols` ],
        });
      }
    }

    setErrors(_.omit(errors, key) as T);
  };

  const helpers: ErrorHelpers<T> = {
    isErrorField,
    isWithErrors,
    renderError,
    clearErrorsState,
    doValidateByFieldChanging,
  };

  return [
    helpers,
    setErrors,
    errors,
  ] as UseErrorFormResult<T>;
};

export default useErrorForm;
