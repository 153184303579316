import React, { CSSProperties, useContext } from 'react';

import { ReactComponent as QSGrayLogo } from 'helpers/icons/NavBarMenu/GrayLogo.svg';
import { ReactComponent as QSLogo } from 'helpers/icons/NavBarMenu/Logo.svg';

import { ThemeContext } from '../../AppThemeWrapper/AppThemeWrapper';
import { Themes } from '../../../helpers/constants/_common/constants';

interface TrackerLogoProps {
  className?: string;
  style?: CSSProperties;
}

const TrackerLogo: React.FC<TrackerLogoProps> = ({ className, style }) => {
  const { theme } = useContext(ThemeContext);

  return (theme === Themes.DARK) ? <QSGrayLogo className={className} style={style} /> : <QSLogo className={className} style={style} />;
};

export default TrackerLogo;
