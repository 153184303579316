/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import * as BookkeepingItems from 'api/BookkeepingItems/requests';
import { DispatchType } from 'helpers/types/_common';
import * as TablesActions from 'helpers/constants/_common/actions/Tables';
import Subtypes from 'V1/constants/__common__/Subtypes';
import { BookkeepingItemsRequestBody } from 'api/BookkeepingItems/types';
import { BookkeepingResponse } from 'redux/Tables/reducerTypes';

const convertToArrayWithoutNullElements = (object: BookkeepingResponse) => (
  _.filter(Object.entries(object), pair => !_.isNull(pair[1]))
    .map(pair => ({
      searchItemId: pair[0],
      [pair[0]]: pair[1],
    }))
);

export const getBookkeepingItems = (body: BookkeepingItemsRequestBody, tableName: string) => (dispatch: DispatchType) => {
  if (_.isEmpty(body.query) || _.isNil(body.query)) return;
  
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return BookkeepingItems.getBookkeepingItemsTable(body)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: { meta: { offset: 0, limit: 20 }, objects: convertToArrayWithoutNullElements(response) },
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};
