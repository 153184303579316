import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TOOLS_TABS from 'helpers/constants/_common/tabs/tools';
import ProfileViewField from 'components/_shared/ProfileViewField';

import s from '../helpers/styles.module.sass';
import SidebarCollapsedMenu, { PopoverVisibleFunction } from '../../Root/components/PagesSidebar/components/SidebarCollapsedMenu';
import { Redux } from '../../../helpers/types/_common';
import { PATH_TOOLS, TAB_SKILL_TAGS } from '../../../helpers/constants/_common/MenuCases';
import { PagesSidebarProps } from '../../Root/components/PagesSidebar/helpers/types';
import popover from '../../../helpers/styles/components/popover.module.sass';
import { Typography } from 'antd';

const cx = classNames.bind(s);

const ToolsTabSidebar: React.FC<PagesSidebarProps> = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state: Redux) => state.login);

  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();

  useEffect(() => {
    const tabKeys = _.keys(TOOLS_TABS);

    if (!tab || !_.includes(tabKeys, tab)) {
      history.push(`${PATH_TOOLS}/${TOOLS_TABS[TAB_SKILL_TAGS].path}`);
    }
  }, [ tab, history ]);

  const popoverContent = (setPopoverVisible: PopoverVisibleFunction) => {
    const menuItems = _.map(TOOLS_TABS, menuTab => (
      <Link
        key={menuTab.path}
        to={`${PATH_TOOLS}/${menuTab.path}`}
        className={cx(popover.dotsPopoverItem, { [popover.dotsPopoverItemActive]: tab === menuTab.path })}
        onClick={() => setPopoverVisible(false)}
      >
        {menuTab.name}
      </Link>
    ));

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        { menuItems}
      </div>
    );
  };

  const renderMenuItems = () => _.map(TOOLS_TABS, menuTab => (
    <li
      key={menuTab.path}
      className={cx(s.sidebarListItem, { [s.activeSidebarList]: tab === menuTab.path })}
    >
      <Link
        to={`${PATH_TOOLS}/${menuTab.path}`}
        className={s.sidebarListItemLink}
      >
        {menuTab.name}
      </Link>
    </li>
  ));

  return (
    <div className={s.sidebarLayout}>
      <SidebarCollapsedMenu
        popoverContent={popoverContent}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        tabName={tab && TOOLS_TABS[tab]?.name}
      />

      <div className={cx(s.sidebarContentWrapper, { [s.collapsed]: collapsed })}>
        <div className={s.sidebarHeader}>
          <ProfileViewField user={user} />
        </div>
        <ul className={s.sidebarList}>
          {renderMenuItems()}
        </ul>
      </div>
    </div>
  );
};

export default ToolsTabSidebar;
