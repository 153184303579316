import React, { useState } from 'react';
import { Button, Popover, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';

import popover from 'helpers/styles/components/popover.module.sass';
import NotificationModal from 'components/Employees/components/NotificationModal';
import { UserStatisticOutput } from 'api/User/types';
import { updateExistingUser } from 'api/User/requests';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES } from 'helpers/constants/_common/MenuCases';

import s from './helpers/styles.module.sass';
import { updateSelectedRowsByStatus } from './helpers/helpers';
import PROFILE_TABS, { EDIT_PROFILE_TABS } from '../../../../../../helpers/constants/_common/tabs/profile';
import { USER_TYPES } from '../../../../../../helpers/constants/_common/constants';

interface UserRowPopoverProps {
  employee: UserStatisticOutput;
  setSelectedRows: (selectedRows: UserStatisticOutput[]) => void;
  selectedRows: UserStatisticOutput[];
  getEmployeesData: () => void;
}

const UserRowPopover: React.FC<UserRowPopoverProps> = ({ employee, getEmployeesData, setSelectedRows, selectedRows }) => {
  const history = useHistory();

  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const [ notificationModalShow, setNotificationModalShow ] = useState(false);

  const handleChangeEmployeeStatus = async () => {
    setDeleteLoading(true);

    await updateExistingUser({ active: !employee.active }, employee.userId)
      .then(() => {
        const updatedSelectedRows = updateSelectedRowsByStatus(selectedRows, employee);

        setSelectedRows(updatedSelectedRows);
      })
      .finally(() => {
        setDeleteLoading(false);
        getEmployeesData();
      });
  };

  const handleOnEditEmployee = () => {
    history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employee.userId}/${PROFILE_TABS.general.path}` });
  };

  const popoverContent = (
    <div className={popover.dotsPopoverItemWrapper}>
      {
        (employee.usertype !== USER_TYPES.BOT) && (
          <Link
            to={`${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employee.userId}/${EDIT_PROFILE_TABS.timesheet.path}`}
            className={popover.dotsPopoverItem}
          >
            Show timesheet
          </Link>
        )
      }
      <span className={popover.dotsPopoverItem} onClick={() => setNotificationModalShow(true)}>Send notification</span>
      <span className={popover.dotsPopoverItem} onClick={handleOnEditEmployee}>View profile</span>
      <span className={popover.dotsPopoverItem} onClick={handleChangeEmployeeStatus}>
        <Space>
          {
            deleteLoading
              ? <LoadingOutlined className={s.spinner} spin />
              : null
          }
          <span>{employee.active ? 'Deactivate' : 'Activate'}</span>
        </Space>
      </span>
    </div>
  );
  return (
    <div className={popover.dotsPopover} title="More...">
      <Popover
        content={popoverContent}
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
      >
        <Button type="text" size="small" className="action-column-button">
          <FontAwesomeIcon className={s.employeeRowActionIcon} icon="ellipsis-v" />
        </Button>
      </Popover>

      <NotificationModal
        employeeData={employee}
        setVisible={setNotificationModalShow}
        visible={notificationModalShow}
      />
    </div>
  );
};

export default UserRowPopover;
