import _ from 'lodash';

import * as Actions from '../constants/ManagePartners';
// import {hslToHex} from '../utils/colorUtils';
import Subtypes from '../constants/__common__/Subtypes';
import processCommonEvent from '../utils/processCommonEvent';

const InitialState = {
  partners: [],
  partnerNameToFind: '',
  showClosedPartners: false,
  getPartnersIsLoading: false,
  getError: false,
  partnerModal: {
    show: false,
    selected: {
      name: '',
      description: '',
      color: '#267fd9',
      status: true,
      project_curators: [],
      active: true,
      activities: [],
      mask: 'DEFAULT',
      author: {
        userId: 0,
        forename: '',
        surname: '',
        emailAddress: '',
      },
      creationDate: '',
      curators: [],
      lastUpdate: '',
      partnerId: 0,
    },
    activities: [],
    errorMessage: '',
    errors: [],
    isLoading: false,
    selectedActivity: null,
    newActivityName: '',
    showActivityInput: false,
  },
  linksModal: {
    show: false,
    links: [],
    showInactiveUsers: false,
    showInactivePartners: false,
    sortBy: 0,
    errorMessage: '',
    isLoading: false,
  },
  errorModal: {
    show: false,
    extended: false,
    message: '',
    list: [],
  },
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    /* AddPartner modal actions */
    case Actions.PARTNER_MODAL:
      return partnerModalToggle(action, state);

    case Actions.GET_BY_PARTNER_ID:
      return getPartnerByIdEvent(action, state);

    case Actions.ADD_PARTNER:
    case Actions.EDIT_PARTNER:
      return addNewPartnerEvent(action, state);

    case Actions.REQUEST_PARTNER_ACTIVITIES:
      return {
        ...state, partnerModal: { ...state.partnerModal, isLoading: action.payload },
      };
    case Actions.CHANGE_PARTNER_MODAL_SELECTED_ACTIVITY:
      return {
        ...state, partnerModal: { ...state.partnerModal, selectedActivity: action.payload },
      };

    /* Activity editor actions */
    case Actions.CHANGE_ACTIVITY_INPUT_SHOW_STATUS:
      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          showActivityInput: action.payload,
          newActivityName: action.payload ? state.partnerModal.newActivityName : '',
        },
      };
    case Actions.CHANGE_NEW_ACTIVITY_NAME:
      return {
        ...state, partnerModal: { ...state.partnerModal, newActivityName: action.payload },
      };
    case Actions.PARTNER_MODAL_CHANGE_ACTIVITY_COLOR: {
      const { activityId, color } = action.payload;
      const activities = _.cloneDeep(state.partnerModal.selected.activities);
      const activityToChange = activities.find(activity => activity.activityId === activityId);

      if (activityToChange) {
        activityToChange.color = color;
        activityToChange.edited = true;
      }

      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          selected: {
            ...state.partnerModal.selected,
            activities,
          },
        },
      };
    }
    case Actions.PARTNER_MODAL_CHANGE_ACTIVITY_STATUS: {
      const { activityId } = action.payload;
      const activities = _.cloneDeep(state.partnerModal.selected.activities);
      const activityToChange = activities.find(activity => activity.activityId === activityId);

      if (activityToChange) {
        activityToChange.active = !activityToChange.active;
        activityToChange.edited = true;
      }

      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          selected: {
            ...state.partnerModal.selected,
            activities,
          },
        },
      };
    }
    case Actions.PARTNER_MODAL_DELETE_ACTIVITY: {
      const { activityId } = action.payload;
      const activities = _.cloneDeep(state.partnerModal.selected.activities);
      const activityToDelete = activities.find(activity => activity.activityId === activityId);

      if (activityToDelete) {
        activityToDelete.deleted = !activityToDelete.deleted;
        activityToDelete.edited = true;
      }

      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          selected: {
            ...state.partnerModal.selected,
            activities,
          },
        },
      };
    }
    case Actions.PARTNER_MODAL_ADD_ACTIVITY: {
      const newActivity = {
        name: action.payload.activityName.trim(),
        active: true,
        activityId: 0,
        color: action.payload.color || '#26d93e',
        description: '',
        hasHours: false,
        lastUpdate: '',
      };

      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          selected: {
            ...state.partnerModal.selected,
            activities: [ ...state.partnerModal.selected.activities, newActivity ],
          },
        },
      };
    }
    case Actions.PARTNER_MODAL_UPDATE_ACTIVITY: {
      const activities = _.cloneDeep(state.partnerModal.activities);
      activities.splice(activities.indexOf(activities.filter(activity => activity.id === action.payload.id)[0]), 1, action.payload);
      return { ...state, partnerModal: { ...state.partnerModal, editedActivity: {}, activityEditing: false, activities } };
    }
    case Actions.PARTNER_MODAL_EDIT_ACTIVITY: {
      const activities = _.cloneDeep(state.partnerModal.activities);
      const activity = activities.filter(a => a.id === action.activityIdToEdit)[0];

      activities.map((activity) => {
        if (activity.id === action.activityIdToEdit) {
          activity.editedNow = true;
        }

        return activity;
      });

      return {
        ...state,

        partnerModal: {
          ...state.partnerModal,

          activityEditing: true,
          editedActivity: activity,
          activities,
        },
      };
    }
    case Actions.PARTNER_MODAL_CLEAR_EDIT_ACTIVITY: {
      const activities = _.cloneDeep(state.partnerModal.activities);

      activities.map((activity) => {
        activity.editedNow = false;
        return activity;
      });

      return {
        ...state,

        partnerModal: {
          ...state.partnerModal,

          activityEditing: false,
          editedActivity: {},
          activities,
        },
      };
    }
    case Actions.ADD_CURATOR:
      return {
        ...state,
        partnerModal: {
          ...state.partnerModal,
          selected: {
            ...state.partnerModal.partner,
            projectCurators: [ ...state.partnerModal.selected.projectCurators,
              {
                ...action.payload,
                userId: action.payload.userId,
              },
            ],
          },
        },
      };
    case Actions.REMOVE_CURATOR:
      const projectCurators = state.partnerModal.selected.projectCurators.filter(curator => curator.userId !== action.payload);

      return {
        ...state,
        partnerModal: { ...state.partnerModal, selected: { ...state.partnerModal.selected, projectCurators } },
      };

    /* Error modal actions */
    case Actions.PARTNERS_ERROR_MODAL_SHOW:
      return { ...state, errorModal: { ...state.errorModal, show: action.payload } };
    case Actions.PARTNERS_ERROR_MODAL_CHANGE_CONTENT: {
      const { extended, message, list } = action.payload;
      return { ...state, errorModal: { ...state.errorModal, extended, message, list } };
    }

    /* Partners and top panel actions */
    case Actions.MANAGE_PARTNERS_INITIATE:
      return InitialState;
    case Actions.CHANGE_PARTNER_NAME_TO_FIND:
      return { ...state, partnerNameToFind: action.payload };
    case Actions.RECEIVE_ALL_PARTNERS_REQUEST:
      return { ...state, getPartnersIsLoading: true, getError: false };
    case Actions.RECEIVE_ALL_PARTNERS_SUCCESS:
      return { ...state, getPartnersIsLoading: false, partners: action.payload ? action.payload.objects : state.partners, getError: false };
    case Actions.RECEIVE_ALL_PARTNERS_ERROR:
      return { ...state, getPartnersIsLoading: false, error: action.payload, getError: true };
    case Actions.SHOW_CLOSED_PARTNERS:
      return { ...state, showClosedPartners: action.payload };
    case Actions.TOGGLE_PARTNER_STATUS: {
      const partners = _.cloneDeep(state.partners);
      const partner = partners.find(partner => partner.partnerId === action.payload.partnerId);
      partner.active = action.payload.active;
      return { ...state, partners };
    }

    /* Users partners links modal actions */
    case Actions.RECEIVE_ALL_PARTNER_USERS_LINKS:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, linksModal: { ...state.linksModal, isLoading: true } };

        case Subtypes.Request.Success:
          return { ...state, linksModal: { ...state.linksModal, isLoading: false, links: action.payload.objects } };

        case Subtypes.Request.Error:
          return { ...state, linksModal: { ...state.linksModal, isLoading: false, errorMessage: action.response } };

        default:
          return state;
      }
    case Actions.RELATED_LINKS_MODAL:
      return relatedLinksModalToggle(action, state);

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer,
};
const partnerModalToggle = (action, state) => processCommonEvent.modalToggle('partnerModal', InitialState.partnerModal)(action, state);

const relatedLinksModalToggle = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Modal.Show:
      return { ...state, linksModal: { ...state.linksModal, ...action.payload, show: true } };
    case Subtypes.Modal.Hide:
      return { ...state, linksModal: { ...state.linksModal, ...InitialState, links: state.linksModal.links, show: false } };
    default:
      return state;
  }
};

const addNewPartnerEvent = (action, state) => processCommonEvent.request('partnerModal')(action, state);

const getPartnerByIdEvent = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, partnerModal: { ...state.partnerModal, isLoading: true, errorMessage: '' } };
    case Subtypes.Request.Success:
      return { ...state, partnerModal: { ...state.partnerModal, isLoading: false, errorMessage: '', selected: action.payload } };
    case Subtypes.Request.Error:
      return { ...state, partnerModal: { ...state.partnerModal, isLoading: false, errorMessage: action.payload } };
    default:
      return state;
  }
};
