import React, { Key, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';
import { Space, Table, Typography } from 'antd';
import { RenderExpandIconProps } from 'rc-table/lib/interface';

import { ReactComponent as ChevronRight } from 'helpers/icons/Buttons/chevronRight.svg';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import useApi from 'api/hooks/useApi/useApi';
import { getHourReportData } from 'api/Reports/requests';
import { BaseActivityOutput } from 'api/Partners/types';
import { ActivityReport, AllItemsReport, PartnerDetailedReport } from 'api/Reports/types';
import { Redux } from 'helpers/types/_common';
import BasicTable from 'components/_shared/Table';
import PartnerMask from 'components/_shared/PartnerMask';
import { ReactComponent as SunIcon } from 'helpers/icons/sunIcon.svg';
import { Meta, Sorter } from 'api/_request/types';
import SalaryField from 'components/_shared/SalaryField/SalaryField';
import { SalaryObject } from 'components/_shared/SalaryField/types';
import Deactivated from 'components/_shared/Deactivated/Deactivated';

import { HOUR_REPORT_COLUMNS, MOBILE_HOUR_REPORT_COLUMNS } from './helpers/columns';
import s from './styles.module.sass';
import { UserHourReportState } from '../../helpers/types';

interface HourReportTableProps {
  settingsValues: UserHourReportState;
  setSettingsValues: React.Dispatch<React.SetStateAction<UserHourReportState>>;
}

const HourReportTable: React.FC<HourReportTableProps> = ({ settingsValues, setSettingsValues }) => {
  const { login } = useSelector((state: Redux) => state);

  const [ expandedRowKeys, setExpandedRowKeys ] = useState<readonly Key[]>();

  const { sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.HOUR_REPORT]);

  const [ getTableData, reportData, loading ] = useApi<typeof getHourReportData, AllItemsReport>(getHourReportData);

  const getHourReport = (pagination: Meta | false, sorter?: Sorter) => {
    // if no dates provided - not call request (for the first time)
    if (!settingsValues.dates.startDateRange || !settingsValues.dates.endDateRange || !settingsValues.dates) {
      return;
    }

    getTableData(login.userId, [ settingsValues.dates.startDateRange, settingsValues.dates.endDateRange ], sorter);
  };

  useEffect(() => {
    getHourReport(false, sorter);
    setExpandedRowKeys([]);
  }, [ settingsValues.dates.startDateRange, settingsValues.dates.endDateRange ]);

  const calculatedSalary = (hours?: number) => {
    if (_.isUndefined(hours)) {
      return 0;
    }

    const salary = settingsValues.salary.hourly ? _.round((settingsValues.salary.hourly), 2) : 0;

    return _.round((salary * hours), 2);
  };

  const calculatedHolidaySalary = (hours?: number) => {
    if (_.isUndefined(hours)) {
      return 0;
    }

    const salary = settingsValues.salary.hourly ? _.round((settingsValues.salary.hourly), 2) : 0;

    return _.round(salary * hours, 2);
  };

  const handleOnSalaryChange = (salaryObject: SalaryObject) => {
    setSettingsValues(prevValue => ({
      ...prevValue,
      salary: salaryObject,
    }));
  };

  const desktopRenderColumns = {
    partnerName: {
      render: (text: string, record: PartnerDetailedReport) => {
        if (!record?.partner?.name) {
          return null;
        }

        return (
          <div className={s.flexRow}>
            <PartnerMask
              wrapperColor={record.partner.color}
              mask={record.partner.mask}
              partnerId={record.partner.partnerId}
              iconColor={record.activities[0]?.activity?.color}
              isVacation={record.partner.isVacation}
            />
            <span>{record?.partner?.name}</span>
            <Deactivated isHidden={record?.partner?.active || true} />
          </div>
        );
      },
    },

    activity: {
      render: (text: BaseActivityOutput | undefined, record: PartnerDetailedReport | ActivityReport) => {
        if ('activities' in record) {
          const activitiesTitle = (record?.activities?.length > 1) ? 'activities' : 'activity';

          return (
            <Space>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, record?.partner?.partnerId) })} />
              <span>{`${record?.activities?.length} ${activitiesTitle}`}</span>
            </Space>
          );
        }

        return (
          <div className={cx(s.expandedRowActivity, s.flexRow)}>
            <PartnerMask
              wrapperColor={record?.partner?.partner?.color}
              mask={record?.partner?.partner?.mask}
              partnerId={record?.partner?.partner?.partnerId}
              iconColor={text?.color}
              isVacation={record?.partner?.partner?.isVacation || false}
            />
            <span>{text?.name}</span>
            <Deactivated isHidden={record?.partner?.partner?.active || true} />
          </div>
        );
      },
    },

    partnerHours: {
      render: (text: undefined, record: PartnerDetailedReport | ActivityReport) => <span>{`${record?.hours}h`}</span>,
    },

    payment: {
      render: (text: string, record: PartnerDetailedReport | ActivityReport) => (
        <span className={s.paymentField}>{`${calculatedSalary(record.hours)} ₽`}</span>
      ),
    },
  };

  const mobileRenderColumns = {
    partnerName: {
      render: (text: string, record: PartnerDetailedReport | ActivityReport) => {
        if ('activity' in record) {
          return (
            <Space>
              <PartnerMask
                wrapperColor={record?.partner?.partner?.color}
                mask={record?.partner?.partner?.mask}
                partnerId={record?.partner?.partner?.partnerId}
                iconColor={record?.activity?.color}
                isVacation={record?.partner?.partner?.isVacation || false}
              />
              <span>{record?.activity?.name}</span>
            </Space>
          );
        }

        if (!record?.partner?.name) {
          return null;
        }

        return (
          <Space>
            <PartnerMask
              wrapperColor={record.partner.color}
              mask={record.partner.mask}
              partnerId={record.partner.partnerId}
              iconColor={record.activities[0]?.activity?.color}
              isVacation={record?.partner?.isVacation || false}
            />
            <span>{record?.partner?.name}</span>
          </Space>
        );
      },
    },
    statistic: {
      render: (text: string, record: PartnerDetailedReport | ActivityReport) => (
        <Space size={4} direction="vertical">
          <span>{`${record?.hours}h`}</span>
          <span className={s.paymentField}>{`${calculatedSalary(record.hours)} ₽`}</span>
        </Space>
      ),
    },
  };

  const expandableSettings = {
    expandRowByClick: true,
    expandedRowKeys,
    indentSize: 40,
    expandIcon: (props: RenderExpandIconProps<PartnerDetailedReport | ActivityReport>) => {
      if (!props.expandable || !login.isMobileDevice) {
        return null;
      }

      return (<ChevronRight className={cx(s.expandedIcon, { [s.expanded]: props.expanded })} />);
    },
    onExpandedRowsChange: (expandedRowsKeys: readonly Key[]) => setExpandedRowKeys(expandedRowsKeys),
  };

  const mobileFooter = (
    <Table.Summary fixed="bottom">
      <Table.Summary.Row className={cx(s.summaryRow, s.summaryHoliday)}>
        <Table.Summary.Cell index={0} colSpan={1}>
          <div className={s.summaryHolidayTitle}>
            <SunIcon className={s.summaryHolidayIcon} />
            <span>Holidays</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1} className={s.mobileHolidayTotal}>
          <Space direction="vertical" align="end">
            <span>{`${reportData?.holidays?.hours}h`}</span>
            {`${calculatedHolidaySalary(reportData?.holidays?.hours)} ₽`}
          </Space>

        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row className={cx(s.summaryRow, s.totalRow)}>
        <Table.Summary.Cell index={0} colSpan={1}>Total for selected period</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1} className={s.mobileSummaryRow}>
          <Space direction="vertical" align="end">
            <span>{`${reportData?.hours}h`}</span>

            <SalaryField
              className={s.totalRowSalaryField}
              salaryObject={settingsValues.salary}
              onChange={handleOnSalaryChange}
              totalHours={reportData?.hours}
            />
          </Space>

        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );

  const desktopFooter = (
    <Table.Summary>
      <Table.Summary.Row className={cx(s.summaryRow, s.summaryHoliday)}>
        <Table.Summary.Cell index={0} colSpan={2}>
          <div className={s.summaryHolidayTitle}>
            <SunIcon className={s.summaryHolidayIcon} />
            <span>Holidays</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1}>
          {`${calculatedHolidaySalary(reportData?.holidays?.hours)} ₽`}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1}>{`${reportData?.holidays?.hours}h`}</Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row className={cx(s.summaryRow, s.totalRow)}>
        <Table.Summary.Cell index={0} colSpan={2}>Total for selected period</Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={1} className={s.salaryFieldWrapper}>
          <SalaryField
            className={s.totalRowSalaryField}
            salaryObject={settingsValues.salary}
            onChange={handleOnSalaryChange}
            totalHours={reportData?.hours}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1}>{`${reportData?.hours}h`}</Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );

  const renderSummaryContent = () => {
    if (!reportData?.hours) {
      return null;
    }

    return login.isMobileDevice ? mobileFooter : desktopFooter;
  };

  return (
    <div>
      <BasicTable
        className={s.reportHourTable}
        rowKey={record => record?.activity?.activityId || record?.partner?.partnerId}
        childrenColumnName="activities"
        loading={loading}
        getData={getHourReport}
        dataSource={reportData?.partners?.partners}
        initialRequest={false}
        renderColumns={login.isMobileDevice ? mobileRenderColumns : desktopRenderColumns}
        pagination={false}
        tableName={TABLE_NAMES.HOUR_REPORT}
        columns={login.isMobileDevice ? MOBILE_HOUR_REPORT_COLUMNS : HOUR_REPORT_COLUMNS}
        rowClassName={() => s.parentRow}
        bordered
        locale={{
          emptyText: <Typography.Text>No hours reported in selected period</Typography.Text>,
        }}
        expandable={expandableSettings}
        summary={renderSummaryContent}
      />
    </div>
  );
};

export default HourReportTable;
