import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDrop } from 'react-dnd';

import SKILL_EXPERIENCES from '../../../../../../../../helpers/constants/Skills/Experience';
import SkillElement from './components/SkillElement';
import { Skill, SkillCategoryEnum, SkillExperienceEnum, UserSkill, UserSkillActions } from '../../../../../../../../helpers/types/Skills';
import { SkillShowingEnum } from '../../../../helpers/types';
import skillsRequests from '../../../../../../../../api/SkillSet/requests';
import { SkillSetExperienceProps } from './helpers/types';
import SkillSearcher from './components/SkillSearcher';
import S from './helpers/styles.module.sass';

const cx = classNames.bind(S);

const SkillSetExperience: React.FC<SkillSetExperienceProps> = (props) => {
  const {
    skillCategoryName, skillExperienceName, skillShowingEnum,
    isCategoryEditingNow, userSkills, userSkillsAll, updateUserSkills,
    addedCategories, user, isReadOnlyMode,
  } = props;

  const currentExpCurrentlyInUse = skillShowingEnum === SkillShowingEnum.inUse;
  const [ exceptionIds, changeExceptionIds ] = useState<(number | null)[]>([]);
  const [ dndUserSkills, changeUserSKillList ] = useState<UserSkill[]>([ ...userSkills ]);

  const [ { hovered }, drop ] = useDrop({
    accept: skillCategoryName,
    drop: (listItem: any) => {
      const destExperience = listItem.userSkill.experience;
      const destCurrentlyInUse = listItem.userSkill.currentlyInUse;

      if (skillExperienceName === destExperience && currentExpCurrentlyInUse === destCurrentlyInUse) return;

      const droppedUserSkill = {
        ...listItem.userSkill,
        experience: skillExperienceName,
        currentlyInUse: listItem.disabled ? listItem.userSkill.currentlyInUse : false,
      };

      return onDrop(droppedUserSkill);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      highlighted: monitor.canDrop(),
      hovered: monitor.isOver(),
    }),
  });

  useEffect(() => {
    changeUserSKillList([ ...userSkills.filter(userSkill => userSkill.action !== UserSkillActions.delete) ]);
  }, [ userSkills ]);

  const onDrop = (droppedUserSkill: UserSkill) => {
    if (!droppedUserSkill.skill.skillId) return;

    props.updateUserSkills({ ...droppedUserSkill, isLoading: true });

    skillsRequests.updateUserSkill(user.userId, {
      skillId: droppedUserSkill.skill.skillId,
      experience: droppedUserSkill.experience,
      currentlyInUse: droppedUserSkill.currentlyInUse,
      attitude: droppedUserSkill.attitude,
    })
      .catch(() => props.restoreUserSkills())
      .finally(() => {
        props.updateUserSkills({ ...droppedUserSkill, isLoading: false }, true);
      });
  };

  const markSkillToDelete = (userSkillToUpdate: UserSkill): void => {
    changeExceptionIds(exceptionIds.filter(id => id !== userSkillToUpdate.skill.skillId));
    if (userSkillToUpdate.userSkillId) {
      props.updateUserSkills({ ...userSkillToUpdate, action: UserSkillActions.delete });
    } else {
      props.removeUserSkill(userSkillToUpdate);
    }
  };

  const markSkillToAdd = (skill: Skill, skillExperienceName: SkillExperienceEnum) => {
    changeExceptionIds([ ...exceptionIds, skill.skillId ]);

    props.addUserSkill({
      userSkillId: null,
      action: UserSkillActions.add,
      experience: skillExperienceName,
      currentlyInUse: false, // all new skills must to be not currently in use
      skill,
      attitude: null,
    });
  };

  const markSkillToCreate = (value: string, skillCategoryName: SkillCategoryEnum, skillExperienceName: SkillExperienceEnum) => {
    const newUserSkill = {
      userSkillId: null,
      action: UserSkillActions.create,
      experience: skillExperienceName,
      currentlyInUse: false, // all new skills must to be not currently in use
      skill: {
        skillId: null,
        name: value,
        category: skillCategoryName,
        approved: false,
      },
      attitude: null,
    };

    props.addUserSkill(newUserSkill);
  };

  const renderSkillElements = (userSkill: UserSkill): React.ReactNode => {
    const { skillExperienceName } = props;

    return (
      <SkillElement
        userId={user.userId}
        key={userSkill.skill.name}
        userSkill={userSkill}
        draggable={!isReadOnlyMode}
        isReadOnlyMode={isReadOnlyMode}
        updateUserSkills={updateUserSkills}
        skillCategoryName={skillCategoryName}
        color={SKILL_EXPERIENCES[skillExperienceName].color}
        addedCategories={addedCategories}
      />
    );
  };

  const searcherIsOpened = false;
  const skillExperience = SKILL_EXPERIENCES[skillExperienceName];

  /*
  all tags in edit mode rendered by SkillSearcher now
   */
  const renderSkillTags = () => {
    if (!isCategoryEditingNow) {
      return (
        <div
          className={cx('skillset_experience-body', { 'skillset_experience-body--hovered': hovered })}
        >
          {dndUserSkills.map(userSkill => renderSkillElements(userSkill))}
        </div>
      );
    }

    return (
      <SkillSearcher
        userSkillList={dndUserSkills}
        userSkillsAll={userSkillsAll}
        visible={isCategoryEditingNow}
        skillCategoryName={skillCategoryName}
        skillExperience={skillExperience}
        onAddExisted={skill => markSkillToAdd(skill, skillExperienceName)}
        onAddNew={value => markSkillToCreate(value, skillCategoryName, skillExperienceName)}
        onDeselect={markSkillToDelete}
      />
    );
  };

  return (
    <div
      ref={drop}
      className={cx('skillset_experience', {
        'skillset_experience--search-list-is-opened': searcherIsOpened,
        'skillset_experience--editing-in-process': isCategoryEditingNow,
      })}
    >
      <div className={cx('skillset_experience-header')}>
        <div className={cx('skillset_experience-header-row')}>
          <h5 className={cx('skillset_experience-title')}>{skillExperience.label}</h5>
        </div>
      </div>
      { renderSkillTags() }
    </div>
  );
};

export default SkillSetExperience;
