import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Popover, Spin } from 'antd';
import _ from 'lodash';
import cx from 'classnames';
import CheckableTag from 'antd/es/tag/CheckableTag';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { SkillCategoryEnum } from 'helpers/types/Skills';
import useApi from 'api/hooks/useApi/useApi';
import { getSkillsRecursively } from 'api/SkillSet/requests';
import { FoundSkill } from 'api/SkillSet/types';

import s from './styles.module.sass';

interface SkillDescriptionProps {
  category: SkillCategoryEnum;
  placement?: TooltipPlacement;
  hidden?: boolean;
}

const SkillDescription: React.FC<SkillDescriptionProps> = ({ category, placement, hidden }) => {
  const [ getSkills, skills, loading ] = useApi<typeof getSkillsRecursively, FoundSkill[]>(getSkillsRecursively);

  const [ selectedSkill, setSelectedSkill ] = useState<FoundSkill>();
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if (visible && !skills) {
      getSkills({ category, approved: true })
        .then((response: FoundSkill[]) => {
          setSelectedSkill(response[0]);
        });
    }
  }, [ visible ]);

  const renderContent = () => (
    <Spin spinning={loading} size="small">
      <div className={cx(s.descriptionContent, { [s.filledContent]: !_.isEmpty(skills) })}>
        <div className={s.descriptionTitles}>
          {
            _.map(skills, skill => (
              <CheckableTag
                key={skill.skillId}
                checked={_.isEqual(selectedSkill?.skillId, skill.skillId)}
                onChange={() => setSelectedSkill(skill)}
                className={cx(s.descriptionLink)}
              >
                {skill.name}
              </CheckableTag>
            ))
          }
        </div>
        <Divider />
        <div className={s.descriptionText}>
          { selectedSkill ? (selectedSkill?.description || 'No description provided') : null }
        </div>
      </div>
    </Spin>
  );

  if (hidden) {
    return null;
  }

  return (
    <Popover
      onVisibleChange={setVisible}
      placement={placement}
      content={renderContent()}
      overlayClassName={s.descriptionPopoverOverlay}
    >
      <FontAwesomeIcon icon="question-circle" size="lg" className={s.skillDescriptionIcon} />
    </Popover>
  );
};

export default SkillDescription;
