/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import { useParams } from 'react-router';
import _ from 'lodash';

import { ReactComponent as DeleteIcon } from 'helpers/icons/deleteIcon.svg';
import { getSimpleTimeByCellIndex } from 'components/TimeSheet/helpers/helpers';
import getFromStore from 'helpers/utils/getFromStore';
import { DispatchType } from 'helpers/types/_common';
import { deleteUserHourCommentAction } from 'redux/TimeSheet/action';
import { EditableUserHourComment } from 'api/TimeSheet/types';
import { UserRedux } from 'redux/User/types';
import MarkupRender from 'components/_shared/MarkupEditor/MarkupEditorView';

import PostedUserHourCommentProps from './helpers/types';
import S from './helpers/styles.module.sass';
import { CommentEditingStates } from '../../helpers/types';

const cx = classNames.bind(S);

const PostedComment: React.FC<PostedUserHourCommentProps> = (props) => {
  const {
    isMonthClosed, isCut, commentData, day,
    editableCommentsList, setEditableCommentsList,
    curatorOnlyView,
  } = props;
  const { commentId, comment, startCellIndex, endCellIndex } = commentData;
  const [ isLoading, setLoading ] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const { userId: editUserId } = useParams<{ userId: string }>();

  const deleteComment = () => {
    const { userId } = getFromStore<UserRedux>('login');

    setLoading(true);
    dispatch(deleteUserHourCommentAction(+editUserId || userId, day, startCellIndex, endCellIndex))
      .finally(() => {
        setLoading(false);
      });
  };

  const editComment = () => {
    if (isMonthClosed || isCut || curatorOnlyView) return;

    const updatedCommentsList = _.reduce(editableCommentsList, (result, comment) => {
      switch (true) {
        case comment.commentId === commentId:
          return [ ...result, {
            ...comment,
            state: CommentEditingStates.Active,
          } ];
        default:
          return [ ...result, comment ];
      }
    }, [] as EditableUserHourComment[]);

    setEditableCommentsList(updatedCommentsList);
  };

  return (
    <div
      key={commentId}
      className={cx(S.comment, {
        withoutButtonHide: isLoading,
        nonInteractive: isMonthClosed || isCut || curatorOnlyView,
      })}
    >
      <div className={S.commentInfo}>
        <div className={S.actions}>
          <Button
            title="Delete comment"
            className={S.actionButton}
            onClick={deleteComment}
            icon={isLoading ? (
              <LoadingOutlined
                className={S.loadingIcon}
                spin
              />
            ) : <DeleteIcon />}
          />
        </div>
        <span className={S.timeSelector}>{getSimpleTimeByCellIndex(startCellIndex)}</span>
        <span style={{ margin: '0px 5px 0px' }}>-</span>
        <span className={S.timeSelector}>{getSimpleTimeByCellIndex(endCellIndex)}</span>
      </div>
      <div className={S.commentTextWrapper} onDoubleClick={editComment}>
        <div className={S.commentText}>
          <MarkupRender value={comment} />
        </div>
      </div>
    </div>
  );
};

export default PostedComment;
