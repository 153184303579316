export default function(target, ...sources) {
  function clone(obj) {
    let copy;
    if (null === obj || 'object' !== typeof obj) return obj;
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = clone(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      // eslint-disable-next-line no-unused-vars
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
      }
      return copy;
    }
    throw new Error('Unable to copy obj! Its type isn`t supported.');
  }

  function _merge(target, source) {
    // eslint-disable-next-line no-unused-vars
    if (source instanceof Array) {
      // eslint-disable-next-line no-unused-vars
      for (let src of source) {
        _merge(target, src);
      }
    } else if (source instanceof Object) {
      // eslint-disable-next-line no-unused-vars
      for (let prop of Object.keys(source)) {
        target[prop] = target[prop]
          ? _merge(target[prop], source[prop])
          : source[prop];
      }
    } else {
      return source;
    }
    return target;
  }
  return _merge(clone(target), sources);
}
