import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Input, Select } from 'antd';
import _ from 'lodash';

import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { PartnerDetailsOutput } from 'api/Partners/types';
import DropdownSelectSmart from 'components/DropdownSelectSmart/DropdownSelectSmart';
import { EntityBaseOutput } from 'api/Entities/types';
import { getEntities } from 'api/Entities/requests';
import useDebounce from 'helpers/hooks/useDebounce';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';

import s from './styles.module.sass';
import { ParticipantsFiltersState, PartnerParticipantsOutputChecked } from '../../helpers/types';
import ParticipantsRadio from '../ParticipantsRadio';
import ParticipantsSelectionPanel from './SelectedRowsMenu';
import AddNewUserButton from './AddNewUserButton';

interface ParticipantsSettingsProps {
  partner: PartnerDetailsOutput;
  filters: ParticipantsFiltersState;
  selectedRows: PartnerParticipantsOutputChecked[];
  setSelectedRows: (selectedRows: PartnerParticipantsOutputChecked[]) => void;
  getParticipants: () => void;
  setFilters: (filters: ParticipantsFiltersState) => void;
}

const ParticipantsSettings: React.FC<ParticipantsSettingsProps> = ({ partner, getParticipants, selectedRows, setSelectedRows, filters, setFilters }) => {
  const [ entities, setEntities ] = useState<EntityBaseOutput[]>([]);
  const [ searchValue, setSearchValue ] = useState<string | undefined>();

  const debounceSearchValue = useDebounce(searchValue, 600);

  useEffect(() => {
    getEntities().then(res => setEntities(res.objects));
  }, []);

  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      setFilters({
        ...filters,
        usernameContains: searchValue,
      });
    }
  }, [ debounceSearchValue ]);
  
  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.participantsInput)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      size="large"
      value={searchValue}
      onChange={e => setSearchValue(e.currentTarget.value)}
      allowClear
      placeholder="Search participants..."
    />
  );

  const renderEntitySelect = () => (
    <DropdownSelectSmart
      placeholder="Any entity"
      popoverText="Select one or more entities"
      options={entities}
      nameKey="name"
      valueKey="entityId"
      value={filters.entity_id}
      onChange={(entity_id: number[]) => setFilters({ ...filters, entity_id })}
      selectedText={values => (values.length > 1 
        ? `${values.length} entities selected` 
        : _.find(entities, { entityId: values[0] })?.name || 'one entity selected'
      )}
    />
  );

  const renderPositionSelect = () => (
    <Select
      className={cx(selects.qsSelect, s.select)}
      dropdownClassName={selects.selectDropdown}
      suffixIcon={<SelectIcon />}
      size="large"
      onChange={(value: string) => setFilters({ ...filters, occupation: value })}
      value={filters.occupation}
      bordered={false}
    >
      <Select.Option value={null} key={null}>All positions</Select.Option>
      {_.map(OCCUPATIONS, (position, key) => <Select.Option value={key} key={key}>{position.name}</Select.Option>)}
    </Select>
  );

  const renderAddNewUsersButton = () => (
    <AddNewUserButton
      partner={partner}
      onSaveCallback={getParticipants}
    />
  );

  const renderRegularMenu = () => (
    <>
      <ParticipantsRadio
        filters={filters}
        onChangeFilters={setFilters}
      />
      <div className={s.filtersWrapper}>
        { renderInputSearch() }
        { renderEntitySelect() }
        { renderPositionSelect() }
        { renderAddNewUsersButton() }
      </div>
    </>
  );
  
  const renderSelectedRowsMenu = () => (
    <ParticipantsSelectionPanel
      partner={partner}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      getParticipants={getParticipants}
    />
  );

  return (
    <div className={s.participantsSettings}>
      {_.isEmpty(selectedRows) ? renderRegularMenu() : renderSelectedRowsMenu()}
    </div>
  );
};

export default ParticipantsSettings;
