import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button } from 'antd';
import { useHistory } from 'react-router';

import BasicTable from 'components/_shared/Table';
import { Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { SpecialistsRow } from 'api/Specialists/types';
import { EmployeeOccupationEnum } from 'helpers/constants/Occupations/types';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import EngKnowledgeLevel from 'components/_shared/EngKnowledgeLevel';
import EmployeeDrawer from 'components/Drawers/EmployeeDrawer';
import { Meta, Sorter } from 'api/_request/types';
import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { PATH_ADMINISTRATION, PATH_FIND_SPECIALISTS, TAB_EMPLOYEES } from 'helpers/constants/_common/MenuCases';
import PROFILE_TABS, { EDIT_PROFILE_TABS } from 'helpers/constants/_common/tabs/profile';

import { attitudesColumns, updateColumns } from '../../../../helpers/helpers';
import { MAX_ELEMENTS_FOR_SCROLL } from '../../../../helpers/constants';
import { FindSpecialistsSettings } from '../../helpers/types';
import S from './helpers/styles.module.sass';
import { DEFAULT_PAGINATION_RAW } from '../../../../../../helpers/constants/_common/Pagination';
import { EmployeeDrawerData } from '../../../../../Drawers/EmployeeDrawer/helpers/types';

const cx = classNames.bind(S);

export interface FindSpecialistsTableProps {
  settings: FindSpecialistsSettings;
  isProfilesViewerMode: boolean;
  fetchSpecialists: (pagination: Meta, sorter?: Sorter) => void;
}

const SpecialistsTable: React.FC<FindSpecialistsTableProps> = ({ settings, fetchSpecialists, isProfilesViewerMode }) => {
  const history = useHistory();

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.FIND_SPECIALISTS]);

  const [ employeeDrawerData, setEmployeeDrawerData ] = useState<EmployeeDrawerData>({ userId: undefined, visible: false });
  const routeState = { from: PATH_FIND_SPECIALISTS };

  useEffect(() => {
    fetchSpecialists(DEFAULT_PAGINATION_RAW, sorter);
  }, [
    settings.englishKnowledge, settings.occupation,
    settings.contains, settings.skillsColumns,
    settings.userId, settings.partnerId, settings.entityId,
  ]);

  const onRow = (record: SpecialistsRow) => ({
    onClick: () => {
      setEmployeeDrawerData({ userId: record.userId, visible: true });
    },
  });

  const renderColumns = {
    fullName: {
      render: (text: string, record: SpecialistsRow) => {
        const logo = record.avatarUrl
          ? <img src={record.avatarUrl} alt="avatar" className={cx('author-avatar')} />
          : <TrackerLogo className={cx('author-avatar', 'author-avatar--qs')} />;

        return (
          <div className={cx('name-cell')}>
            {logo}
            {`${record.forename} ${record.surname}`}
          </div>
        );
      },
    },
    skills: {
      render: (text: any, record: any) => '>',
    },
    occupation: {
      render: (text: EmployeeOccupationEnum) => OCCUPATIONS[text].name,
    },

    englishKnowledge: {
      render: (englishKnowledgeLevel: string) => <EngKnowledgeLevel englishKnowledgeLevel={englishKnowledgeLevel} />,
    },

    ...attitudesColumns(settings.skills),
  };

  const onCloseDrawer = () => {
    setEmployeeDrawerData({ userId: undefined, visible: false });
  };

  const renderViewProfileButton = () => (
    <Button
      type="primary"
      className={cx(buttons.qsButtonPrimary, 'cardButton')}
      key="edit button"
      onClick={() => {
        if (!employeeDrawerData.userId) {
          return;
        }

        history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${PROFILE_TABS.general.path}`, state: routeState });
      }}
    >
      View profile
    </Button>
  );

  const renderViewSkillsetButton = () => (
    <Button
      type="primary"
      className={cx(buttons.qsButtonPrimary, 'cardButton')}
      key="view skillset"
      onClick={() => {
        if (!employeeDrawerData.userId) {
          return;
        }

        history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${EDIT_PROFILE_TABS.skillset.path}`, state: routeState });
      }}
    >
      View skillset
    </Button>
  );

  const renderViewTimesheetButton = () => !isProfilesViewerMode && (
    <Button
      type="primary"
      className={cx(buttons.qsButtonPrimary, 'cardButton')}
      key="view timesheet"
      onClick={() => {
        if (!employeeDrawerData.userId) {
          return;
        }

        history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${EDIT_PROFILE_TABS.timesheet.path}`, state: routeState });
      }}
    >
      View timesheet
    </Button>
  );

  return (
    <div className={cx('find-specialists-table')}>
      <div className={cx('find-specialists-table_total')}>
        { meta?.totalCount ? `${meta.totalCount} Engineers Found` : ''}
      </div>
      <div>
        <BasicTable
          tableName={TABLE_NAMES.FIND_SPECIALISTS}
          initialRequest={false}
          getData={fetchSpecialists}
          rowKey="userId"
          columns={updateColumns(isProfilesViewerMode, settings.skills)}
          className={cx('find-specialists-table_table', { 'no-data': _.isEmpty(objects) })}
          pagination={meta}
          loading={loading}
          dataSource={objects}
          sortDirections={[ 'descend', 'ascend' ]}
          scroll={{ x: 1000, y: objects.length > MAX_ELEMENTS_FOR_SCROLL ? 'calc(100vh - 450px)' : undefined }}
          rowClassName={cx('bordered-row')}
          onRow={onRow}
          renderColumns={renderColumns}
        />

        <EmployeeDrawer
          drawerData={employeeDrawerData}
          onClose={onCloseDrawer}
          externalButtons={[ renderViewProfileButton, renderViewSkillsetButton, renderViewTimesheetButton ]}
        />
      </div>
    </div>
  );
};

export default SpecialistsTable;
