export const TIMEZONES_FULL = [
  {
    label: 'UTC+00:00 Africa/Abidjan',
    value: {
      offset: '+00:00',
      zone: 'Africa/Abidjan',
    },
  },
  {
    label: 'UTC+00:00 Africa/Accra',
    value: {
      offset: '+00:00',
      zone: 'Africa/Accra',
    },
  },
  {
    label: 'UTC+03:00 Africa/Addis Ababa',
    value: {
      offset: '+03:00',
      zone: 'Africa/Addis_Ababa',
    },
  },
  {
    label: 'UTC+01:00 Africa/Algiers',
    value: {
      offset: '+01:00',
      zone: 'Africa/Algiers',
    },
  },
  {
    label: 'UTC+03:00 Africa/Asmara',
    value: {
      offset: '+03:00',
      zone: 'Africa/Asmara',
    },
  },
  {
    label: 'UTC+03:00 Africa/Asmera',
    value: {
      offset: '+03:00',
      zone: 'Africa/Asmera',
    },
  },
  {
    label: 'UTC+00:00 Africa/Bamako',
    value: {
      offset: '+00:00',
      zone: 'Africa/Bamako',
    },
  },
  {
    label: 'UTC+01:00 Africa/Bangui',
    value: {
      offset: '+01:00',
      zone: 'Africa/Bangui',
    },
  },
  {
    label: 'UTC+00:00 Africa/Banjul',
    value: {
      offset: '+00:00',
      zone: 'Africa/Banjul',
    },
  },
  {
    label: 'UTC+00:00 Africa/Bissau',
    value: {
      offset: '+00:00',
      zone: 'Africa/Bissau',
    },
  },
  {
    label: 'UTC+02:00 Africa/Blantyre',
    value: {
      offset: '+02:00',
      zone: 'Africa/Blantyre',
    },
  },
  {
    label: 'UTC+01:00 Africa/Brazzaville',
    value: {
      offset: '+01:00',
      zone: 'Africa/Brazzaville',
    },
  },
  {
    label: 'UTC+02:00 Africa/Bujumbura',
    value: {
      offset: '+02:00',
      zone: 'Africa/Bujumbura',
    },
  },
  {
    label: 'UTC+02:00 Africa/Cairo',
    value: {
      offset: '+02:00',
      zone: 'Africa/Cairo',
    },
  },
  {
    label: 'UTC+01:00 Africa/Casablanca',
    value: {
      offset: '+01:00',
      zone: 'Africa/Casablanca',
    },
  },
  {
    label: 'UTC+02:00 Africa/Ceuta',
    value: {
      offset: '+02:00',
      zone: 'Africa/Ceuta',
    },
  },
  {
    label: 'UTC+00:00 Africa/Conakry',
    value: {
      offset: '+00:00',
      zone: 'Africa/Conakry',
    },
  },
  {
    label: 'UTC+00:00 Africa/Dakar',
    value: {
      offset: '+00:00',
      zone: 'Africa/Dakar',
    },
  },
  {
    label: 'UTC+03:00 Africa/Dar es Salaam',
    value: {
      offset: '+03:00',
      zone: 'Africa/Dar_es_Salaam',
    },
  },
  {
    label: 'UTC+03:00 Africa/Djibouti',
    value: {
      offset: '+03:00',
      zone: 'Africa/Djibouti',
    },
  },
  {
    label: 'UTC+01:00 Africa/Douala',
    value: {
      offset: '+01:00',
      zone: 'Africa/Douala',
    },
  },
  {
    label: 'UTC+01:00 Africa/El Aaiun',
    value: {
      offset: '+01:00',
      zone: 'Africa/El_Aaiun',
    },
  },
  {
    label: 'UTC+00:00 Africa/Freetown',
    value: {
      offset: '+00:00',
      zone: 'Africa/Freetown',
    },
  },
  {
    label: 'UTC+02:00 Africa/Gaborone',
    value: {
      offset: '+02:00',
      zone: 'Africa/Gaborone',
    },
  },
  {
    label: 'UTC+02:00 Africa/Harare',
    value: {
      offset: '+02:00',
      zone: 'Africa/Harare',
    },
  },
  {
    label: 'UTC+02:00 Africa/Johannesburg',
    value: {
      offset: '+02:00',
      zone: 'Africa/Johannesburg',
    },
  },
  {
    label: 'UTC+03:00 Africa/Juba',
    value: {
      offset: '+03:00',
      zone: 'Africa/Juba',
    },
  },
  {
    label: 'UTC+03:00 Africa/Kampala',
    value: {
      offset: '+03:00',
      zone: 'Africa/Kampala',
    },
  },
  {
    label: 'UTC+02:00 Africa/Khartoum',
    value: {
      offset: '+02:00',
      zone: 'Africa/Khartoum',
    },
  },
  {
    label: 'UTC+02:00 Africa/Kigali',
    value: {
      offset: '+02:00',
      zone: 'Africa/Kigali',
    },
  },
  {
    label: 'UTC+01:00 Africa/Kinshasa',
    value: {
      offset: '+01:00',
      zone: 'Africa/Kinshasa',
    },
  },
  {
    label: 'UTC+01:00 Africa/Lagos',
    value: {
      offset: '+01:00',
      zone: 'Africa/Lagos',
    },
  },
  {
    label: 'UTC+01:00 Africa/Libreville',
    value: {
      offset: '+01:00',
      zone: 'Africa/Libreville',
    },
  },
  {
    label: 'UTC+00:00 Africa/Lome',
    value: {
      offset: '+00:00',
      zone: 'Africa/Lome',
    },
  },
  {
    label: 'UTC+01:00 Africa/Luanda',
    value: {
      offset: '+01:00',
      zone: 'Africa/Luanda',
    },
  },
  {
    label: 'UTC+02:00 Africa/Lubumbashi',
    value: {
      offset: '+02:00',
      zone: 'Africa/Lubumbashi',
    },
  },
  {
    label: 'UTC+02:00 Africa/Lusaka',
    value: {
      offset: '+02:00',
      zone: 'Africa/Lusaka',
    },
  },
  {
    label: 'UTC+01:00 Africa/Malabo',
    value: {
      offset: '+01:00',
      zone: 'Africa/Malabo',
    },
  },
  {
    label: 'UTC+02:00 Africa/Maputo',
    value: {
      offset: '+02:00',
      zone: 'Africa/Maputo',
    },
  },
  {
    label: 'UTC+02:00 Africa/Maseru',
    value: {
      offset: '+02:00',
      zone: 'Africa/Maseru',
    },
  },
  {
    label: 'UTC+02:00 Africa/Mbabane',
    value: {
      offset: '+02:00',
      zone: 'Africa/Mbabane',
    },
  },
  {
    label: 'UTC+03:00 Africa/Mogadishu',
    value: {
      offset: '+03:00',
      zone: 'Africa/Mogadishu',
    },
  },
  {
    label: 'UTC+00:00 Africa/Monrovia',
    value: {
      offset: '+00:00',
      zone: 'Africa/Monrovia',
    },
  },
  {
    label: 'UTC+03:00 Africa/Nairobi',
    value: {
      offset: '+03:00',
      zone: 'Africa/Nairobi',
    },
  },
  {
    label: 'UTC+01:00 Africa/Ndjamena',
    value: {
      offset: '+01:00',
      zone: 'Africa/Ndjamena',
    },
  },
  {
    label: 'UTC+01:00 Africa/Niamey',
    value: {
      offset: '+01:00',
      zone: 'Africa/Niamey',
    },
  },
  {
    label: 'UTC+00:00 Africa/Nouakchott',
    value: {
      offset: '+00:00',
      zone: 'Africa/Nouakchott',
    },
  },
  {
    label: 'UTC+00:00 Africa/Ouagadougou',
    value: {
      offset: '+00:00',
      zone: 'Africa/Ouagadougou',
    },
  },
  {
    label: 'UTC+01:00 Africa/Porto-Novo',
    value: {
      offset: '+01:00',
      zone: 'Africa/Porto-Novo',
    },
  },
  {
    label: 'UTC+00:00 Africa/Sao Tome',
    value: {
      offset: '+00:00',
      zone: 'Africa/Sao_Tome',
    },
  },
  {
    label: 'UTC+00:00 Africa/Timbuktu',
    value: {
      offset: '+00:00',
      zone: 'Africa/Timbuktu',
    },
  },
  {
    label: 'UTC+02:00 Africa/Tripoli',
    value: {
      offset: '+02:00',
      zone: 'Africa/Tripoli',
    },
  },
  {
    label: 'UTC+01:00 Africa/Tunis',
    value: {
      offset: '+01:00',
      zone: 'Africa/Tunis',
    },
  },
  {
    label: 'UTC+02:00 Africa/Windhoek',
    value: {
      offset: '+02:00',
      zone: 'Africa/Windhoek',
    },
  },
  {
    label: 'UTC-09:00 America/Adak',
    value: {
      offset: '-09:00',
      zone: 'America/Adak',
    },
  },
  {
    label: 'UTC-08:00 America/Anchorage',
    value: {
      offset: '-08:00',
      zone: 'America/Anchorage',
    },
  },
  {
    label: 'UTC-04:00 America/Anguilla',
    value: {
      offset: '-04:00',
      zone: 'America/Anguilla',
    },
  },
  {
    label: 'UTC-04:00 America/Antigua',
    value: {
      offset: '-04:00',
      zone: 'America/Antigua',
    },
  },
  {
    label: 'UTC-03:00 America/Araguaina',
    value: {
      offset: '-03:00',
      zone: 'America/Araguaina',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Buenos Aires',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Buenos_Aires',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Catamarca',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Catamarca',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/ComodRivadavia',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/ComodRivadavia',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Cordoba',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Cordoba',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Jujuy',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Jujuy',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/La Rioja',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/La_Rioja',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Mendoza',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Mendoza',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Rio Gallegos',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Rio_Gallegos',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Salta',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Salta',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/San Juan',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/San_Juan',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/San Luis',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/San_Luis',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Tucuman',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Tucuman',
    },
  },
  {
    label: 'UTC-03:00 America/Argentina/Ushuaia',
    value: {
      offset: '-03:00',
      zone: 'America/Argentina/Ushuaia',
    },
  },
  {
    label: 'UTC-04:00 America/Aruba',
    value: {
      offset: '-04:00',
      zone: 'America/Aruba',
    },
  },
  {
    label: 'UTC-04:00 America/Asuncion',
    value: {
      offset: '-04:00',
      zone: 'America/Asuncion',
    },
  },
  {
    label: 'UTC-05:00 America/Atikokan',
    value: {
      offset: '-05:00',
      zone: 'America/Atikokan',
    },
  },
  {
    label: 'UTC-09:00 America/Atka',
    value: {
      offset: '-09:00',
      zone: 'America/Atka',
    },
  },
  {
    label: 'UTC-03:00 America/Bahia',
    value: {
      offset: '-03:00',
      zone: 'America/Bahia',
    },
  },
  {
    label: 'UTC-05:00 America/Bahia Banderas',
    value: {
      offset: '-05:00',
      zone: 'America/Bahia_Banderas',
    },
  },
  {
    label: 'UTC-04:00 America/Barbados',
    value: {
      offset: '-04:00',
      zone: 'America/Barbados',
    },
  },
  {
    label: 'UTC-03:00 America/Belem',
    value: {
      offset: '-03:00',
      zone: 'America/Belem',
    },
  },
  {
    label: 'UTC-06:00 America/Belize',
    value: {
      offset: '-06:00',
      zone: 'America/Belize',
    },
  },
  {
    label: 'UTC-04:00 America/Blanc-Sablon',
    value: {
      offset: '-04:00',
      zone: 'America/Blanc-Sablon',
    },
  },
  {
    label: 'UTC-04:00 America/Boa Vista',
    value: {
      offset: '-04:00',
      zone: 'America/Boa_Vista',
    },
  },
  {
    label: 'UTC-05:00 America/Bogota',
    value: {
      offset: '-05:00',
      zone: 'America/Bogota',
    },
  },
  {
    label: 'UTC-06:00 America/Boise',
    value: {
      offset: '-06:00',
      zone: 'America/Boise',
    },
  },
  {
    label: 'UTC-03:00 America/Buenos Aires',
    value: {
      offset: '-03:00',
      zone: 'America/Buenos_Aires',
    },
  },
  {
    label: 'UTC-06:00 America/Cambridge Bay',
    value: {
      offset: '-06:00',
      zone: 'America/Cambridge_Bay',
    },
  },
  {
    label: 'UTC-04:00 America/Campo Grande',
    value: {
      offset: '-04:00',
      zone: 'America/Campo_Grande',
    },
  },
  {
    label: 'UTC-05:00 America/Cancun',
    value: {
      offset: '-05:00',
      zone: 'America/Cancun',
    },
  },
  {
    label: 'UTC-04:00 America/Caracas',
    value: {
      offset: '-04:00',
      zone: 'America/Caracas',
    },
  },
  {
    label: 'UTC-03:00 America/Catamarca',
    value: {
      offset: '-03:00',
      zone: 'America/Catamarca',
    },
  },
  {
    label: 'UTC-03:00 America/Cayenne',
    value: {
      offset: '-03:00',
      zone: 'America/Cayenne',
    },
  },
  {
    label: 'UTC-05:00 America/Cayman',
    value: {
      offset: '-05:00',
      zone: 'America/Cayman',
    },
  },
  {
    label: 'UTC-05:00 America/Chicago',
    value: {
      offset: '-05:00',
      zone: 'America/Chicago',
    },
  },
  {
    label: 'UTC-06:00 America/Chihuahua',
    value: {
      offset: '-06:00',
      zone: 'America/Chihuahua',
    },
  },
  {
    label: 'UTC-05:00 America/Coral Harbour',
    value: {
      offset: '-05:00',
      zone: 'America/Coral_Harbour',
    },
  },
  {
    label: 'UTC-03:00 America/Cordoba',
    value: {
      offset: '-03:00',
      zone: 'America/Cordoba',
    },
  },
  {
    label: 'UTC-06:00 America/Costa Rica',
    value: {
      offset: '-06:00',
      zone: 'America/Costa_Rica',
    },
  },
  {
    label: 'UTC-07:00 America/Creston',
    value: {
      offset: '-07:00',
      zone: 'America/Creston',
    },
  },
  {
    label: 'UTC-04:00 America/Cuiaba',
    value: {
      offset: '-04:00',
      zone: 'America/Cuiaba',
    },
  },
  {
    label: 'UTC-04:00 America/Curacao',
    value: {
      offset: '-04:00',
      zone: 'America/Curacao',
    },
  },
  {
    label: 'UTC+00:00 America/Danmarkshavn',
    value: {
      offset: '+00:00',
      zone: 'America/Danmarkshavn',
    },
  },
  {
    label: 'UTC-07:00 America/Dawson',
    value: {
      offset: '-07:00',
      zone: 'America/Dawson',
    },
  },
  {
    label: 'UTC-07:00 America/Dawson Creek',
    value: {
      offset: '-07:00',
      zone: 'America/Dawson_Creek',
    },
  },
  {
    label: 'UTC-06:00 America/Denver',
    value: {
      offset: '-06:00',
      zone: 'America/Denver',
    },
  },
  {
    label: 'UTC-04:00 America/Detroit',
    value: {
      offset: '-04:00',
      zone: 'America/Detroit',
    },
  },
  {
    label: 'UTC-04:00 America/Dominica',
    value: {
      offset: '-04:00',
      zone: 'America/Dominica',
    },
  },
  {
    label: 'UTC-06:00 America/Edmonton',
    value: {
      offset: '-06:00',
      zone: 'America/Edmonton',
    },
  },
  {
    label: 'UTC-05:00 America/Eirunepe',
    value: {
      offset: '-05:00',
      zone: 'America/Eirunepe',
    },
  },
  {
    label: 'UTC-06:00 America/El Salvador',
    value: {
      offset: '-06:00',
      zone: 'America/El_Salvador',
    },
  },
  {
    label: 'UTC-07:00 America/Ensenada',
    value: {
      offset: '-07:00',
      zone: 'America/Ensenada',
    },
  },
  {
    label: 'UTC-07:00 America/Fort Nelson',
    value: {
      offset: '-07:00',
      zone: 'America/Fort_Nelson',
    },
  },
  {
    label: 'UTC-04:00 America/Fort Wayne',
    value: {
      offset: '-04:00',
      zone: 'America/Fort_Wayne',
    },
  },
  {
    label: 'UTC-03:00 America/Fortaleza',
    value: {
      offset: '-03:00',
      zone: 'America/Fortaleza',
    },
  },
  {
    label: 'UTC-03:00 America/Glace Bay',
    value: {
      offset: '-03:00',
      zone: 'America/Glace_Bay',
    },
  },
  {
    label: 'UTC-02:00 America/Godthab',
    value: {
      offset: '-02:00',
      zone: 'America/Godthab',
    },
  },
  {
    label: 'UTC-03:00 America/Goose Bay',
    value: {
      offset: '-03:00',
      zone: 'America/Goose_Bay',
    },
  },
  {
    label: 'UTC-04:00 America/Grand Turk',
    value: {
      offset: '-04:00',
      zone: 'America/Grand_Turk',
    },
  },
  {
    label: 'UTC-04:00 America/Grenada',
    value: {
      offset: '-04:00',
      zone: 'America/Grenada',
    },
  },
  {
    label: 'UTC-04:00 America/Guadeloupe',
    value: {
      offset: '-04:00',
      zone: 'America/Guadeloupe',
    },
  },
  {
    label: 'UTC-06:00 America/Guatemala',
    value: {
      offset: '-06:00',
      zone: 'America/Guatemala',
    },
  },
  {
    label: 'UTC-05:00 America/Guayaquil',
    value: {
      offset: '-05:00',
      zone: 'America/Guayaquil',
    },
  },
  {
    label: 'UTC-04:00 America/Guyana',
    value: {
      offset: '-04:00',
      zone: 'America/Guyana',
    },
  },
  {
    label: 'UTC-03:00 America/Halifax',
    value: {
      offset: '-03:00',
      zone: 'America/Halifax',
    },
  },
  {
    label: 'UTC-04:00 America/Havana',
    value: {
      offset: '-04:00',
      zone: 'America/Havana',
    },
  },
  {
    label: 'UTC-07:00 America/Hermosillo',
    value: {
      offset: '-07:00',
      zone: 'America/Hermosillo',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Indianapolis',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Indianapolis',
    },
  },
  {
    label: 'UTC-05:00 America/Indiana/Knox',
    value: {
      offset: '-05:00',
      zone: 'America/Indiana/Knox',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Marengo',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Marengo',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Petersburg',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Petersburg',
    },
  },
  {
    label: 'UTC-05:00 America/Indiana/Tell City',
    value: {
      offset: '-05:00',
      zone: 'America/Indiana/Tell_City',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Vevay',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Vevay',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Vincennes',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Vincennes',
    },
  },
  {
    label: 'UTC-04:00 America/Indiana/Winamac',
    value: {
      offset: '-04:00',
      zone: 'America/Indiana/Winamac',
    },
  },
  {
    label: 'UTC-04:00 America/Indianapolis',
    value: {
      offset: '-04:00',
      zone: 'America/Indianapolis',
    },
  },
  {
    label: 'UTC-06:00 America/Inuvik',
    value: {
      offset: '-06:00',
      zone: 'America/Inuvik',
    },
  },
  {
    label: 'UTC-04:00 America/Iqaluit',
    value: {
      offset: '-04:00',
      zone: 'America/Iqaluit',
    },
  },
  {
    label: 'UTC-05:00 America/Jamaica',
    value: {
      offset: '-05:00',
      zone: 'America/Jamaica',
    },
  },
  {
    label: 'UTC-03:00 America/Jujuy',
    value: {
      offset: '-03:00',
      zone: 'America/Jujuy',
    },
  },
  {
    label: 'UTC-08:00 America/Juneau',
    value: {
      offset: '-08:00',
      zone: 'America/Juneau',
    },
  },
  {
    label: 'UTC-04:00 America/Kentucky/Louisville',
    value: {
      offset: '-04:00',
      zone: 'America/Kentucky/Louisville',
    },
  },
  {
    label: 'UTC-04:00 America/Kentucky/Monticello',
    value: {
      offset: '-04:00',
      zone: 'America/Kentucky/Monticello',
    },
  },
  {
    label: 'UTC-05:00 America/Knox IN',
    value: {
      offset: '-05:00',
      zone: 'America/Knox_IN',
    },
  },
  {
    label: 'UTC-04:00 America/Kralendijk',
    value: {
      offset: '-04:00',
      zone: 'America/Kralendijk',
    },
  },
  {
    label: 'UTC-04:00 America/La Paz',
    value: {
      offset: '-04:00',
      zone: 'America/La_Paz',
    },
  },
  {
    label: 'UTC-05:00 America/Lima',
    value: {
      offset: '-05:00',
      zone: 'America/Lima',
    },
  },
  {
    label: 'UTC-07:00 America/Los Angeles',
    value: {
      offset: '-07:00',
      zone: 'America/Los_Angeles',
    },
  },
  {
    label: 'UTC-04:00 America/Louisville',
    value: {
      offset: '-04:00',
      zone: 'America/Louisville',
    },
  },
  {
    label: 'UTC-04:00 America/Lower Princes',
    value: {
      offset: '-04:00',
      zone: 'America/Lower_Princes',
    },
  },
  {
    label: 'UTC-03:00 America/Maceio',
    value: {
      offset: '-03:00',
      zone: 'America/Maceio',
    },
  },
  {
    label: 'UTC-06:00 America/Managua',
    value: {
      offset: '-06:00',
      zone: 'America/Managua',
    },
  },
  {
    label: 'UTC-04:00 America/Manaus',
    value: {
      offset: '-04:00',
      zone: 'America/Manaus',
    },
  },
  {
    label: 'UTC-04:00 America/Marigot',
    value: {
      offset: '-04:00',
      zone: 'America/Marigot',
    },
  },
  {
    label: 'UTC-04:00 America/Martinique',
    value: {
      offset: '-04:00',
      zone: 'America/Martinique',
    },
  },
  {
    label: 'UTC-05:00 America/Matamoros',
    value: {
      offset: '-05:00',
      zone: 'America/Matamoros',
    },
  },
  {
    label: 'UTC-06:00 America/Mazatlan',
    value: {
      offset: '-06:00',
      zone: 'America/Mazatlan',
    },
  },
  {
    label: 'UTC-03:00 America/Mendoza',
    value: {
      offset: '-03:00',
      zone: 'America/Mendoza',
    },
  },
  {
    label: 'UTC-05:00 America/Menominee',
    value: {
      offset: '-05:00',
      zone: 'America/Menominee',
    },
  },
  {
    label: 'UTC-05:00 America/Merida',
    value: {
      offset: '-05:00',
      zone: 'America/Merida',
    },
  },
  {
    label: 'UTC-08:00 America/Metlakatla',
    value: {
      offset: '-08:00',
      zone: 'America/Metlakatla',
    },
  },
  {
    label: 'UTC-05:00 America/Mexico City',
    value: {
      offset: '-05:00',
      zone: 'America/Mexico_City',
    },
  },
  {
    label: 'UTC-02:00 America/Miquelon',
    value: {
      offset: '-02:00',
      zone: 'America/Miquelon',
    },
  },
  {
    label: 'UTC-03:00 America/Moncton',
    value: {
      offset: '-03:00',
      zone: 'America/Moncton',
    },
  },
  {
    label: 'UTC-05:00 America/Monterrey',
    value: {
      offset: '-05:00',
      zone: 'America/Monterrey',
    },
  },
  {
    label: 'UTC-03:00 America/Montevideo',
    value: {
      offset: '-03:00',
      zone: 'America/Montevideo',
    },
  },
  {
    label: 'UTC-04:00 America/Montreal',
    value: {
      offset: '-04:00',
      zone: 'America/Montreal',
    },
  },
  {
    label: 'UTC-04:00 America/Montserrat',
    value: {
      offset: '-04:00',
      zone: 'America/Montserrat',
    },
  },
  {
    label: 'UTC-04:00 America/Nassau',
    value: {
      offset: '-04:00',
      zone: 'America/Nassau',
    },
  },
  {
    label: 'UTC-04:00 America/New York',
    value: {
      offset: '-04:00',
      zone: 'America/New_York',
    },
  },
  {
    label: 'UTC-04:00 America/Nipigon',
    value: {
      offset: '-04:00',
      zone: 'America/Nipigon',
    },
  },
  {
    label: 'UTC-08:00 America/Nome',
    value: {
      offset: '-08:00',
      zone: 'America/Nome',
    },
  },
  {
    label: 'UTC-02:00 America/Noronha',
    value: {
      offset: '-02:00',
      zone: 'America/Noronha',
    },
  },
  {
    label: 'UTC-05:00 America/North Dakota/Beulah',
    value: {
      offset: '-05:00',
      zone: 'America/North_Dakota/Beulah',
    },
  },
  {
    label: 'UTC-05:00 America/North Dakota/Center',
    value: {
      offset: '-05:00',
      zone: 'America/North_Dakota/Center',
    },
  },
  {
    label: 'UTC-05:00 America/North Dakota/New Salem',
    value: {
      offset: '-05:00',
      zone: 'America/North_Dakota/New_Salem',
    },
  },
  {
    label: 'UTC-02:00 America/Nuuk',
    value: {
      offset: '-02:00',
      zone: 'America/Nuuk',
    },
  },
  {
    label: 'UTC-06:00 America/Ojinaga',
    value: {
      offset: '-06:00',
      zone: 'America/Ojinaga',
    },
  },
  {
    label: 'UTC-05:00 America/Panama',
    value: {
      offset: '-05:00',
      zone: 'America/Panama',
    },
  },
  {
    label: 'UTC-04:00 America/Pangnirtung',
    value: {
      offset: '-04:00',
      zone: 'America/Pangnirtung',
    },
  },
  {
    label: 'UTC-03:00 America/Paramaribo',
    value: {
      offset: '-03:00',
      zone: 'America/Paramaribo',
    },
  },
  {
    label: 'UTC-07:00 America/Phoenix',
    value: {
      offset: '-07:00',
      zone: 'America/Phoenix',
    },
  },
  {
    label: 'UTC-04:00 America/Port-au-Prince',
    value: {
      offset: '-04:00',
      zone: 'America/Port-au-Prince',
    },
  },
  {
    label: 'UTC-04:00 America/Port of Spain',
    value: {
      offset: '-04:00',
      zone: 'America/Port_of_Spain',
    },
  },
  {
    label: 'UTC-05:00 America/Porto Acre',
    value: {
      offset: '-05:00',
      zone: 'America/Porto_Acre',
    },
  },
  {
    label: 'UTC-04:00 America/Porto Velho',
    value: {
      offset: '-04:00',
      zone: 'America/Porto_Velho',
    },
  },
  {
    label: 'UTC-04:00 America/Puerto Rico',
    value: {
      offset: '-04:00',
      zone: 'America/Puerto_Rico',
    },
  },
  {
    label: 'UTC-03:00 America/Punta Arenas',
    value: {
      offset: '-03:00',
      zone: 'America/Punta_Arenas',
    },
  },
  {
    label: 'UTC-05:00 America/Rainy River',
    value: {
      offset: '-05:00',
      zone: 'America/Rainy_River',
    },
  },
  {
    label: 'UTC-05:00 America/Rankin Inlet',
    value: {
      offset: '-05:00',
      zone: 'America/Rankin_Inlet',
    },
  },
  {
    label: 'UTC-03:00 America/Recife',
    value: {
      offset: '-03:00',
      zone: 'America/Recife',
    },
  },
  {
    label: 'UTC-06:00 America/Regina',
    value: {
      offset: '-06:00',
      zone: 'America/Regina',
    },
  },
  {
    label: 'UTC-05:00 America/Resolute',
    value: {
      offset: '-05:00',
      zone: 'America/Resolute',
    },
  },
  {
    label: 'UTC-05:00 America/Rio Branco',
    value: {
      offset: '-05:00',
      zone: 'America/Rio_Branco',
    },
  },
  {
    label: 'UTC-03:00 America/Rosario',
    value: {
      offset: '-03:00',
      zone: 'America/Rosario',
    },
  },
  {
    label: 'UTC-07:00 America/Santa Isabel',
    value: {
      offset: '-07:00',
      zone: 'America/Santa_Isabel',
    },
  },
  {
    label: 'UTC-03:00 America/Santarem',
    value: {
      offset: '-03:00',
      zone: 'America/Santarem',
    },
  },
  {
    label: 'UTC-04:00 America/Santiago',
    value: {
      offset: '-04:00',
      zone: 'America/Santiago',
    },
  },
  {
    label: 'UTC-04:00 America/Santo Domingo',
    value: {
      offset: '-04:00',
      zone: 'America/Santo_Domingo',
    },
  },
  {
    label: 'UTC-03:00 America/Sao Paulo',
    value: {
      offset: '-03:00',
      zone: 'America/Sao_Paulo',
    },
  },
  {
    label: 'UTC+00:00 America/Scoresbysund',
    value: {
      offset: '+00:00',
      zone: 'America/Scoresbysund',
    },
  },
  {
    label: 'UTC-06:00 America/Shiprock',
    value: {
      offset: '-06:00',
      zone: 'America/Shiprock',
    },
  },
  {
    label: 'UTC-08:00 America/Sitka',
    value: {
      offset: '-08:00',
      zone: 'America/Sitka',
    },
  },
  {
    label: 'UTC-04:00 America/St Barthelemy',
    value: {
      offset: '-04:00',
      zone: 'America/St_Barthelemy',
    },
  },
  {
    label: 'UTC-02:30 America/St Johns',
    value: {
      offset: '-02:30',
      zone: 'America/St_Johns',
    },
  },
  {
    label: 'UTC-04:00 America/St Kitts',
    value: {
      offset: '-04:00',
      zone: 'America/St_Kitts',
    },
  },
  {
    label: 'UTC-04:00 America/St Lucia',
    value: {
      offset: '-04:00',
      zone: 'America/St_Lucia',
    },
  },
  {
    label: 'UTC-04:00 America/St Thomas',
    value: {
      offset: '-04:00',
      zone: 'America/St_Thomas',
    },
  },
  {
    label: 'UTC-04:00 America/St Vincent',
    value: {
      offset: '-04:00',
      zone: 'America/St_Vincent',
    },
  },
  {
    label: 'UTC-06:00 America/Swift Current',
    value: {
      offset: '-06:00',
      zone: 'America/Swift_Current',
    },
  },
  {
    label: 'UTC-06:00 America/Tegucigalpa',
    value: {
      offset: '-06:00',
      zone: 'America/Tegucigalpa',
    },
  },
  {
    label: 'UTC-03:00 America/Thule',
    value: {
      offset: '-03:00',
      zone: 'America/Thule',
    },
  },
  {
    label: 'UTC-04:00 America/Thunder Bay',
    value: {
      offset: '-04:00',
      zone: 'America/Thunder_Bay',
    },
  },
  {
    label: 'UTC-07:00 America/Tijuana',
    value: {
      offset: '-07:00',
      zone: 'America/Tijuana',
    },
  },
  {
    label: 'UTC-04:00 America/Toronto',
    value: {
      offset: '-04:00',
      zone: 'America/Toronto',
    },
  },
  {
    label: 'UTC-04:00 America/Tortola',
    value: {
      offset: '-04:00',
      zone: 'America/Tortola',
    },
  },
  {
    label: 'UTC-07:00 America/Vancouver',
    value: {
      offset: '-07:00',
      zone: 'America/Vancouver',
    },
  },
  {
    label: 'UTC-04:00 America/Virgin',
    value: {
      offset: '-04:00',
      zone: 'America/Virgin',
    },
  },
  {
    label: 'UTC-07:00 America/Whitehorse',
    value: {
      offset: '-07:00',
      zone: 'America/Whitehorse',
    },
  },
  {
    label: 'UTC-05:00 America/Winnipeg',
    value: {
      offset: '-05:00',
      zone: 'America/Winnipeg',
    },
  },
  {
    label: 'UTC-08:00 America/Yakutat',
    value: {
      offset: '-08:00',
      zone: 'America/Yakutat',
    },
  },
  {
    label: 'UTC-06:00 America/Yellowknife',
    value: {
      offset: '-06:00',
      zone: 'America/Yellowknife',
    },
  },
  {
    label: 'UTC+08:00 Antarctica/Casey',
    value: {
      offset: '+08:00',
      zone: 'Antarctica/Casey',
    },
  },
  {
    label: 'UTC+07:00 Antarctica/Davis',
    value: {
      offset: '+07:00',
      zone: 'Antarctica/Davis',
    },
  },
  {
    label: 'UTC+10:00 Antarctica/DumontDUrville',
    value: {
      offset: '+10:00',
      zone: 'Antarctica/DumontDUrville',
    },
  },
  {
    label: 'UTC+11:00 Antarctica/Macquarie',
    value: {
      offset: '+11:00',
      zone: 'Antarctica/Macquarie',
    },
  },
  {
    label: 'UTC+05:00 Antarctica/Mawson',
    value: {
      offset: '+05:00',
      zone: 'Antarctica/Mawson',
    },
  },
  {
    label: 'UTC+12:00 Antarctica/McMurdo',
    value: {
      offset: '+12:00',
      zone: 'Antarctica/McMurdo',
    },
  },
  {
    label: 'UTC-03:00 Antarctica/Palmer',
    value: {
      offset: '-03:00',
      zone: 'Antarctica/Palmer',
    },
  },
  {
    label: 'UTC-03:00 Antarctica/Rothera',
    value: {
      offset: '-03:00',
      zone: 'Antarctica/Rothera',
    },
  },
  {
    label: 'UTC+12:00 Antarctica/South Pole',
    value: {
      offset: '+12:00',
      zone: 'Antarctica/South_Pole',
    },
  },
  {
    label: 'UTC+03:00 Antarctica/Syowa',
    value: {
      offset: '+03:00',
      zone: 'Antarctica/Syowa',
    },
  },
  {
    label: 'UTC+02:00 Antarctica/Troll',
    value: {
      offset: '+02:00',
      zone: 'Antarctica/Troll',
    },
  },
  {
    label: 'UTC+06:00 Antarctica/Vostok',
    value: {
      offset: '+06:00',
      zone: 'Antarctica/Vostok',
    },
  },
  {
    label: 'UTC+02:00 Arctic/Longyearbyen',
    value: {
      offset: '+02:00',
      zone: 'Arctic/Longyearbyen',
    },
  },
  {
    label: 'UTC+03:00 Asia/Aden',
    value: {
      offset: '+03:00',
      zone: 'Asia/Aden',
    },
  },
  {
    label: 'UTC+06:00 Asia/Almaty',
    value: {
      offset: '+06:00',
      zone: 'Asia/Almaty',
    },
  },
  {
    label: 'UTC+03:00 Asia/Amman',
    value: {
      offset: '+03:00',
      zone: 'Asia/Amman',
    },
  },
  {
    label: 'UTC+12:00 Asia/Anadyr',
    value: {
      offset: '+12:00',
      zone: 'Asia/Anadyr',
    },
  },
  {
    label: 'UTC+05:00 Asia/Aqtau',
    value: {
      offset: '+05:00',
      zone: 'Asia/Aqtau',
    },
  },
  {
    label: 'UTC+05:00 Asia/Aqtobe',
    value: {
      offset: '+05:00',
      zone: 'Asia/Aqtobe',
    },
  },
  {
    label: 'UTC+05:00 Asia/Ashgabat',
    value: {
      offset: '+05:00',
      zone: 'Asia/Ashgabat',
    },
  },
  {
    label: 'UTC+05:00 Asia/Ashkhabad',
    value: {
      offset: '+05:00',
      zone: 'Asia/Ashkhabad',
    },
  },
  {
    label: 'UTC+05:00 Asia/Atyrau',
    value: {
      offset: '+05:00',
      zone: 'Asia/Atyrau',
    },
  },
  {
    label: 'UTC+03:00 Asia/Baghdad',
    value: {
      offset: '+03:00',
      zone: 'Asia/Baghdad',
    },
  },
  {
    label: 'UTC+03:00 Asia/Bahrain',
    value: {
      offset: '+03:00',
      zone: 'Asia/Bahrain',
    },
  },
  {
    label: 'UTC+04:00 Asia/Baku',
    value: {
      offset: '+04:00',
      zone: 'Asia/Baku',
    },
  },
  {
    label: 'UTC+07:00 Asia/Bangkok',
    value: {
      offset: '+07:00',
      zone: 'Asia/Bangkok',
    },
  },
  {
    label: 'UTC+07:00 Asia/Barnaul',
    value: {
      offset: '+07:00',
      zone: 'Asia/Barnaul',
    },
  },
  {
    label: 'UTC+03:00 Asia/Beirut',
    value: {
      offset: '+03:00',
      zone: 'Asia/Beirut',
    },
  },
  {
    label: 'UTC+06:00 Asia/Bishkek',
    value: {
      offset: '+06:00',
      zone: 'Asia/Bishkek',
    },
  },
  {
    label: 'UTC+08:00 Asia/Brunei',
    value: {
      offset: '+08:00',
      zone: 'Asia/Brunei',
    },
  },
  {
    label: 'UTC+05:30 Asia/Calcutta',
    value: {
      offset: '+05:30',
      zone: 'Asia/Calcutta',
    },
  },
  {
    label: 'UTC+09:00 Asia/Chita',
    value: {
      offset: '+09:00',
      zone: 'Asia/Chita',
    },
  },
  {
    label: 'UTC+08:00 Asia/Choibalsan',
    value: {
      offset: '+08:00',
      zone: 'Asia/Choibalsan',
    },
  },
  {
    label: 'UTC+08:00 Asia/Chongqing',
    value: {
      offset: '+08:00',
      zone: 'Asia/Chongqing',
    },
  },
  {
    label: 'UTC+08:00 Asia/Chungking',
    value: {
      offset: '+08:00',
      zone: 'Asia/Chungking',
    },
  },
  {
    label: 'UTC+05:30 Asia/Colombo',
    value: {
      offset: '+05:30',
      zone: 'Asia/Colombo',
    },
  },
  {
    label: 'UTC+06:00 Asia/Dacca',
    value: {
      offset: '+06:00',
      zone: 'Asia/Dacca',
    },
  },
  {
    label: 'UTC+03:00 Asia/Damascus',
    value: {
      offset: '+03:00',
      zone: 'Asia/Damascus',
    },
  },
  {
    label: 'UTC+06:00 Asia/Dhaka',
    value: {
      offset: '+06:00',
      zone: 'Asia/Dhaka',
    },
  },
  {
    label: 'UTC+09:00 Asia/Dili',
    value: {
      offset: '+09:00',
      zone: 'Asia/Dili',
    },
  },
  {
    label: 'UTC+04:00 Asia/Dubai',
    value: {
      offset: '+04:00',
      zone: 'Asia/Dubai',
    },
  },
  {
    label: 'UTC+05:00 Asia/Dushanbe',
    value: {
      offset: '+05:00',
      zone: 'Asia/Dushanbe',
    },
  },
  {
    label: 'UTC+03:00 Asia/Famagusta',
    value: {
      offset: '+03:00',
      zone: 'Asia/Famagusta',
    },
  },
  {
    label: 'UTC+03:00 Asia/Gaza',
    value: {
      offset: '+03:00',
      zone: 'Asia/Gaza',
    },
  },
  {
    label: 'UTC+08:00 Asia/Harbin',
    value: {
      offset: '+08:00',
      zone: 'Asia/Harbin',
    },
  },
  {
    label: 'UTC+03:00 Asia/Hebron',
    value: {
      offset: '+03:00',
      zone: 'Asia/Hebron',
    },
  },
  {
    label: 'UTC+07:00 Asia/Ho Chi Minh',
    value: {
      offset: '+07:00',
      zone: 'Asia/Ho_Chi_Minh',
    },
  },
  {
    label: 'UTC+08:00 Asia/Hong Kong',
    value: {
      offset: '+08:00',
      zone: 'Asia/Hong_Kong',
    },
  },
  {
    label: 'UTC+07:00 Asia/Hovd',
    value: {
      offset: '+07:00',
      zone: 'Asia/Hovd',
    },
  },
  {
    label: 'UTC+08:00 Asia/Irkutsk',
    value: {
      offset: '+08:00',
      zone: 'Asia/Irkutsk',
    },
  },
  {
    label: 'UTC+03:00 Asia/Istanbul',
    value: {
      offset: '+03:00',
      zone: 'Asia/Istanbul',
    },
  },
  {
    label: 'UTC+07:00 Asia/Jakarta',
    value: {
      offset: '+07:00',
      zone: 'Asia/Jakarta',
    },
  },
  {
    label: 'UTC+09:00 Asia/Jayapura',
    value: {
      offset: '+09:00',
      zone: 'Asia/Jayapura',
    },
  },
  {
    label: 'UTC+03:00 Asia/Jerusalem',
    value: {
      offset: '+03:00',
      zone: 'Asia/Jerusalem',
    },
  },
  {
    label: 'UTC+04:30 Asia/Kabul',
    value: {
      offset: '+04:30',
      zone: 'Asia/Kabul',
    },
  },
  {
    label: 'UTC+12:00 Asia/Kamchatka',
    value: {
      offset: '+12:00',
      zone: 'Asia/Kamchatka',
    },
  },
  {
    label: 'UTC+05:00 Asia/Karachi',
    value: {
      offset: '+05:00',
      zone: 'Asia/Karachi',
    },
  },
  {
    label: 'UTC+06:00 Asia/Kashgar',
    value: {
      offset: '+06:00',
      zone: 'Asia/Kashgar',
    },
  },
  {
    label: 'UTC+05:45 Asia/Kathmandu',
    value: {
      offset: '+05:45',
      zone: 'Asia/Kathmandu',
    },
  },
  {
    label: 'UTC+05:45 Asia/Katmandu',
    value: {
      offset: '+05:45',
      zone: 'Asia/Katmandu',
    },
  },
  {
    label: 'UTC+09:00 Asia/Khandyga',
    value: {
      offset: '+09:00',
      zone: 'Asia/Khandyga',
    },
  },
  {
    label: 'UTC+05:30 Asia/Kolkata',
    value: {
      offset: '+05:30',
      zone: 'Asia/Kolkata',
    },
  },
  {
    label: 'UTC+07:00 Asia/Krasnoyarsk',
    value: {
      offset: '+07:00',
      zone: 'Asia/Krasnoyarsk',
    },
  },
  {
    label: 'UTC+08:00 Asia/Kuala Lumpur',
    value: {
      offset: '+08:00',
      zone: 'Asia/Kuala_Lumpur',
    },
  },
  {
    label: 'UTC+08:00 Asia/Kuching',
    value: {
      offset: '+08:00',
      zone: 'Asia/Kuching',
    },
  },
  {
    label: 'UTC+03:00 Asia/Kuwait',
    value: {
      offset: '+03:00',
      zone: 'Asia/Kuwait',
    },
  },
  {
    label: 'UTC+08:00 Asia/Macao',
    value: {
      offset: '+08:00',
      zone: 'Asia/Macao',
    },
  },
  {
    label: 'UTC+08:00 Asia/Macau',
    value: {
      offset: '+08:00',
      zone: 'Asia/Macau',
    },
  },
  {
    label: 'UTC+11:00 Asia/Magadan',
    value: {
      offset: '+11:00',
      zone: 'Asia/Magadan',
    },
  },
  {
    label: 'UTC+08:00 Asia/Makassar',
    value: {
      offset: '+08:00',
      zone: 'Asia/Makassar',
    },
  },
  {
    label: 'UTC+08:00 Asia/Manila',
    value: {
      offset: '+08:00',
      zone: 'Asia/Manila',
    },
  },
  {
    label: 'UTC+04:00 Asia/Muscat',
    value: {
      offset: '+04:00',
      zone: 'Asia/Muscat',
    },
  },
  {
    label: 'UTC+03:00 Asia/Nicosia',
    value: {
      offset: '+03:00',
      zone: 'Asia/Nicosia',
    },
  },
  {
    label: 'UTC+07:00 Asia/Novokuznetsk',
    value: {
      offset: '+07:00',
      zone: 'Asia/Novokuznetsk',
    },
  },
  {
    label: 'UTC+07:00 Asia/Novosibirsk',
    value: {
      offset: '+07:00',
      zone: 'Asia/Novosibirsk',
    },
  },
  {
    label: 'UTC+06:00 Asia/Omsk',
    value: {
      offset: '+06:00',
      zone: 'Asia/Omsk',
    },
  },
  {
    label: 'UTC+05:00 Asia/Oral',
    value: {
      offset: '+05:00',
      zone: 'Asia/Oral',
    },
  },
  {
    label: 'UTC+07:00 Asia/Phnom Penh',
    value: {
      offset: '+07:00',
      zone: 'Asia/Phnom_Penh',
    },
  },
  {
    label: 'UTC+07:00 Asia/Pontianak',
    value: {
      offset: '+07:00',
      zone: 'Asia/Pontianak',
    },
  },
  {
    label: 'UTC+09:00 Asia/Pyongyang',
    value: {
      offset: '+09:00',
      zone: 'Asia/Pyongyang',
    },
  },
  {
    label: 'UTC+03:00 Asia/Qatar',
    value: {
      offset: '+03:00',
      zone: 'Asia/Qatar',
    },
  },
  {
    label: 'UTC+06:00 Asia/Qostanay',
    value: {
      offset: '+06:00',
      zone: 'Asia/Qostanay',
    },
  },
  {
    label: 'UTC+05:00 Asia/Qyzylorda',
    value: {
      offset: '+05:00',
      zone: 'Asia/Qyzylorda',
    },
  },
  {
    label: 'UTC+06:30 Asia/Rangoon',
    value: {
      offset: '+06:30',
      zone: 'Asia/Rangoon',
    },
  },
  {
    label: 'UTC+03:00 Asia/Riyadh',
    value: {
      offset: '+03:00',
      zone: 'Asia/Riyadh',
    },
  },
  {
    label: 'UTC+07:00 Asia/Saigon',
    value: {
      offset: '+07:00',
      zone: 'Asia/Saigon',
    },
  },
  {
    label: 'UTC+11:00 Asia/Sakhalin',
    value: {
      offset: '+11:00',
      zone: 'Asia/Sakhalin',
    },
  },
  {
    label: 'UTC+05:00 Asia/Samarkand',
    value: {
      offset: '+05:00',
      zone: 'Asia/Samarkand',
    },
  },
  {
    label: 'UTC+09:00 Asia/Seoul',
    value: {
      offset: '+09:00',
      zone: 'Asia/Seoul',
    },
  },
  {
    label: 'UTC+08:00 Asia/Shanghai',
    value: {
      offset: '+08:00',
      zone: 'Asia/Shanghai',
    },
  },
  {
    label: 'UTC+08:00 Asia/Singapore',
    value: {
      offset: '+08:00',
      zone: 'Asia/Singapore',
    },
  },
  {
    label: 'UTC+11:00 Asia/Srednekolymsk',
    value: {
      offset: '+11:00',
      zone: 'Asia/Srednekolymsk',
    },
  },
  {
    label: 'UTC+08:00 Asia/Taipei',
    value: {
      offset: '+08:00',
      zone: 'Asia/Taipei',
    },
  },
  {
    label: 'UTC+05:00 Asia/Tashkent',
    value: {
      offset: '+05:00',
      zone: 'Asia/Tashkent',
    },
  },
  {
    label: 'UTC+04:00 Asia/Tbilisi',
    value: {
      offset: '+04:00',
      zone: 'Asia/Tbilisi',
    },
  },
  {
    label: 'UTC+04:30 Asia/Tehran',
    value: {
      offset: '+04:30',
      zone: 'Asia/Tehran',
    },
  },
  {
    label: 'UTC+03:00 Asia/Tel Aviv',
    value: {
      offset: '+03:00',
      zone: 'Asia/Tel_Aviv',
    },
  },
  {
    label: 'UTC+06:00 Asia/Thimbu',
    value: {
      offset: '+06:00',
      zone: 'Asia/Thimbu',
    },
  },
  {
    label: 'UTC+06:00 Asia/Thimphu',
    value: {
      offset: '+06:00',
      zone: 'Asia/Thimphu',
    },
  },
  {
    label: 'UTC+09:00 Asia/Tokyo',
    value: {
      offset: '+09:00',
      zone: 'Asia/Tokyo',
    },
  },
  {
    label: 'UTC+07:00 Asia/Tomsk',
    value: {
      offset: '+07:00',
      zone: 'Asia/Tomsk',
    },
  },
  {
    label: 'UTC+08:00 Asia/Ujung Pandang',
    value: {
      offset: '+08:00',
      zone: 'Asia/Ujung_Pandang',
    },
  },
  {
    label: 'UTC+08:00 Asia/Ulaanbaatar',
    value: {
      offset: '+08:00',
      zone: 'Asia/Ulaanbaatar',
    },
  },
  {
    label: 'UTC+08:00 Asia/Ulan Bator',
    value: {
      offset: '+08:00',
      zone: 'Asia/Ulan_Bator',
    },
  },
  {
    label: 'UTC+06:00 Asia/Urumqi',
    value: {
      offset: '+06:00',
      zone: 'Asia/Urumqi',
    },
  },
  {
    label: 'UTC+10:00 Asia/Ust-Nera',
    value: {
      offset: '+10:00',
      zone: 'Asia/Ust-Nera',
    },
  },
  {
    label: 'UTC+07:00 Asia/Vientiane',
    value: {
      offset: '+07:00',
      zone: 'Asia/Vientiane',
    },
  },
  {
    label: 'UTC+10:00 Asia/Vladivostok',
    value: {
      offset: '+10:00',
      zone: 'Asia/Vladivostok',
    },
  },
  {
    label: 'UTC+09:00 Asia/Yakutsk',
    value: {
      offset: '+09:00',
      zone: 'Asia/Yakutsk',
    },
  },
  {
    label: 'UTC+06:30 Asia/Yangon',
    value: {
      offset: '+06:30',
      zone: 'Asia/Yangon',
    },
  },
  {
    label: 'UTC+05:00 Asia/Yekaterinburg',
    value: {
      offset: '+05:00',
      zone: 'Asia/Yekaterinburg',
    },
  },
  {
    label: 'UTC+04:00 Asia/Yerevan',
    value: {
      offset: '+04:00',
      zone: 'Asia/Yerevan',
    },
  },
  {
    label: 'UTC+00:00 Atlantic/Azores',
    value: {
      offset: '+00:00',
      zone: 'Atlantic/Azores',
    },
  },
  {
    label: 'UTC-03:00 Atlantic/Bermuda',
    value: {
      offset: '-03:00',
      zone: 'Atlantic/Bermuda',
    },
  },
  {
    label: 'UTC+01:00 Atlantic/Canary',
    value: {
      offset: '+01:00',
      zone: 'Atlantic/Canary',
    },
  },
  {
    label: 'UTC-01:00 Atlantic/Cape Verde',
    value: {
      offset: '-01:00',
      zone: 'Atlantic/Cape_Verde',
    },
  },
  {
    label: 'UTC+01:00 Atlantic/Faeroe',
    value: {
      offset: '+01:00',
      zone: 'Atlantic/Faeroe',
    },
  },
  {
    label: 'UTC+01:00 Atlantic/Faroe',
    value: {
      offset: '+01:00',
      zone: 'Atlantic/Faroe',
    },
  },
  {
    label: 'UTC+02:00 Atlantic/Jan Mayen',
    value: {
      offset: '+02:00',
      zone: 'Atlantic/Jan_Mayen',
    },
  },
  {
    label: 'UTC+01:00 Atlantic/Madeira',
    value: {
      offset: '+01:00',
      zone: 'Atlantic/Madeira',
    },
  },
  {
    label: 'UTC+00:00 Atlantic/Reykjavik',
    value: {
      offset: '+00:00',
      zone: 'Atlantic/Reykjavik',
    },
  },
  {
    label: 'UTC-02:00 Atlantic/South Georgia',
    value: {
      offset: '-02:00',
      zone: 'Atlantic/South_Georgia',
    },
  },
  {
    label: 'UTC+00:00 Atlantic/St Helena',
    value: {
      offset: '+00:00',
      zone: 'Atlantic/St_Helena',
    },
  },
  {
    label: 'UTC-03:00 Atlantic/Stanley',
    value: {
      offset: '-03:00',
      zone: 'Atlantic/Stanley',
    },
  },
  {
    label: 'UTC+10:00 Australia/ACT',
    value: {
      offset: '+10:00',
      zone: 'Australia/ACT',
    },
  },
  {
    label: 'UTC+09:30 Australia/Adelaide',
    value: {
      offset: '+09:30',
      zone: 'Australia/Adelaide',
    },
  },
  {
    label: 'UTC+10:00 Australia/Brisbane',
    value: {
      offset: '+10:00',
      zone: 'Australia/Brisbane',
    },
  },
  {
    label: 'UTC+09:30 Australia/Broken Hill',
    value: {
      offset: '+09:30',
      zone: 'Australia/Broken_Hill',
    },
  },
  {
    label: 'UTC+10:00 Australia/Canberra',
    value: {
      offset: '+10:00',
      zone: 'Australia/Canberra',
    },
  },
  {
    label: 'UTC+10:00 Australia/Currie',
    value: {
      offset: '+10:00',
      zone: 'Australia/Currie',
    },
  },
  {
    label: 'UTC+09:30 Australia/Darwin',
    value: {
      offset: '+09:30',
      zone: 'Australia/Darwin',
    },
  },
  {
    label: 'UTC+08:45 Australia/Eucla',
    value: {
      offset: '+08:45',
      zone: 'Australia/Eucla',
    },
  },
  {
    label: 'UTC+10:00 Australia/Hobart',
    value: {
      offset: '+10:00',
      zone: 'Australia/Hobart',
    },
  },
  {
    label: 'UTC+10:30 Australia/LHI',
    value: {
      offset: '+10:30',
      zone: 'Australia/LHI',
    },
  },
  {
    label: 'UTC+10:00 Australia/Lindeman',
    value: {
      offset: '+10:00',
      zone: 'Australia/Lindeman',
    },
  },
  {
    label: 'UTC+10:30 Australia/Lord Howe',
    value: {
      offset: '+10:30',
      zone: 'Australia/Lord_Howe',
    },
  },
  {
    label: 'UTC+10:00 Australia/Melbourne',
    value: {
      offset: '+10:00',
      zone: 'Australia/Melbourne',
    },
  },
  {
    label: 'UTC+10:00 Australia/NSW',
    value: {
      offset: '+10:00',
      zone: 'Australia/NSW',
    },
  },
  {
    label: 'UTC+09:30 Australia/North',
    value: {
      offset: '+09:30',
      zone: 'Australia/North',
    },
  },
  {
    label: 'UTC+08:00 Australia/Perth',
    value: {
      offset: '+08:00',
      zone: 'Australia/Perth',
    },
  },
  {
    label: 'UTC+10:00 Australia/Queensland',
    value: {
      offset: '+10:00',
      zone: 'Australia/Queensland',
    },
  },
  {
    label: 'UTC+09:30 Australia/South',
    value: {
      offset: '+09:30',
      zone: 'Australia/South',
    },
  },
  {
    label: 'UTC+10:00 Australia/Sydney',
    value: {
      offset: '+10:00',
      zone: 'Australia/Sydney',
    },
  },
  {
    label: 'UTC+10:00 Australia/Tasmania',
    value: {
      offset: '+10:00',
      zone: 'Australia/Tasmania',
    },
  },
  {
    label: 'UTC+10:00 Australia/Victoria',
    value: {
      offset: '+10:00',
      zone: 'Australia/Victoria',
    },
  },
  {
    label: 'UTC+08:00 Australia/West',
    value: {
      offset: '+08:00',
      zone: 'Australia/West',
    },
  },
  {
    label: 'UTC+09:30 Australia/Yancowinna',
    value: {
      offset: '+09:30',
      zone: 'Australia/Yancowinna',
    },
  },
  {
    label: 'UTC-05:00 Brazil/Acre',
    value: {
      offset: '-05:00',
      zone: 'Brazil/Acre',
    },
  },
  {
    label: 'UTC-02:00 Brazil/DeNoronha',
    value: {
      offset: '-02:00',
      zone: 'Brazil/DeNoronha',
    },
  },
  {
    label: 'UTC-03:00 Brazil/East',
    value: {
      offset: '-03:00',
      zone: 'Brazil/East',
    },
  },
  {
    label: 'UTC-04:00 Brazil/West',
    value: {
      offset: '-04:00',
      zone: 'Brazil/West',
    },
  },
  {
    label: 'UTC+02:00 CET',
    value: {
      offset: '+02:00',
      zone: 'CET',
    },
  },
  {
    label: 'UTC-05:00 CST6CDT',
    value: {
      offset: '-05:00',
      zone: 'CST6CDT',
    },
  },
  {
    label: 'UTC-03:00 Canada/Atlantic',
    value: {
      offset: '-03:00',
      zone: 'Canada/Atlantic',
    },
  },
  {
    label: 'UTC-05:00 Canada/Central',
    value: {
      offset: '-05:00',
      zone: 'Canada/Central',
    },
  },
  {
    label: 'UTC-04:00 Canada/Eastern',
    value: {
      offset: '-04:00',
      zone: 'Canada/Eastern',
    },
  },
  {
    label: 'UTC-06:00 Canada/Mountain',
    value: {
      offset: '-06:00',
      zone: 'Canada/Mountain',
    },
  },
  {
    label: 'UTC-02:30 Canada/Newfoundland',
    value: {
      offset: '-02:30',
      zone: 'Canada/Newfoundland',
    },
  },
  {
    label: 'UTC-07:00 Canada/Pacific',
    value: {
      offset: '-07:00',
      zone: 'Canada/Pacific',
    },
  },
  {
    label: 'UTC-06:00 Canada/Saskatchewan',
    value: {
      offset: '-06:00',
      zone: 'Canada/Saskatchewan',
    },
  },
  {
    label: 'UTC-07:00 Canada/Yukon',
    value: {
      offset: '-07:00',
      zone: 'Canada/Yukon',
    },
  },
  {
    label: 'UTC-04:00 Chile/Continental',
    value: {
      offset: '-04:00',
      zone: 'Chile/Continental',
    },
  },
  {
    label: 'UTC-06:00 Chile/EasterIsland',
    value: {
      offset: '-06:00',
      zone: 'Chile/EasterIsland',
    },
  },
  {
    label: 'UTC-04:00 Cuba',
    value: {
      offset: '-04:00',
      zone: 'Cuba',
    },
  },
  {
    label: 'UTC+03:00 EET',
    value: {
      offset: '+03:00',
      zone: 'EET',
    },
  },
  {
    label: 'UTC-05:00 EST',
    value: {
      offset: '-05:00',
      zone: 'EST',
    },
  },
  {
    label: 'UTC-04:00 EST5EDT',
    value: {
      offset: '-04:00',
      zone: 'EST5EDT',
    },
  },
  {
    label: 'UTC+02:00 Egypt',
    value: {
      offset: '+02:00',
      zone: 'Egypt',
    },
  },
  {
    label: 'UTC+01:00 Eire',
    value: {
      offset: '+01:00',
      zone: 'Eire',
    },
  },
  {
    label: 'UTC+02:00 Europe/Amsterdam',
    value: {
      offset: '+02:00',
      zone: 'Europe/Amsterdam',
    },
  },
  {
    label: 'UTC+02:00 Europe/Andorra',
    value: {
      offset: '+02:00',
      zone: 'Europe/Andorra',
    },
  },
  {
    label: 'UTC+04:00 Europe/Astrakhan',
    value: {
      offset: '+04:00',
      zone: 'Europe/Astrakhan',
    },
  },
  {
    label: 'UTC+03:00 Europe/Athens',
    value: {
      offset: '+03:00',
      zone: 'Europe/Athens',
    },
  },
  {
    label: 'UTC+01:00 Europe/Belfast',
    value: {
      offset: '+01:00',
      zone: 'Europe/Belfast',
    },
  },
  {
    label: 'UTC+02:00 Europe/Belgrade',
    value: {
      offset: '+02:00',
      zone: 'Europe/Belgrade',
    },
  },
  {
    label: 'UTC+02:00 Europe/Berlin',
    value: {
      offset: '+02:00',
      zone: 'Europe/Berlin',
    },
  },
  {
    label: 'UTC+02:00 Europe/Bratislava',
    value: {
      offset: '+02:00',
      zone: 'Europe/Bratislava',
    },
  },
  {
    label: 'UTC+02:00 Europe/Brussels',
    value: {
      offset: '+02:00',
      zone: 'Europe/Brussels',
    },
  },
  {
    label: 'UTC+03:00 Europe/Bucharest',
    value: {
      offset: '+03:00',
      zone: 'Europe/Bucharest',
    },
  },
  {
    label: 'UTC+02:00 Europe/Budapest',
    value: {
      offset: '+02:00',
      zone: 'Europe/Budapest',
    },
  },
  {
    label: 'UTC+02:00 Europe/Busingen',
    value: {
      offset: '+02:00',
      zone: 'Europe/Busingen',
    },
  },
  {
    label: 'UTC+03:00 Europe/Chisinau',
    value: {
      offset: '+03:00',
      zone: 'Europe/Chisinau',
    },
  },
  {
    label: 'UTC+02:00 Europe/Copenhagen',
    value: {
      offset: '+02:00',
      zone: 'Europe/Copenhagen',
    },
  },
  {
    label: 'UTC+01:00 Europe/Dublin',
    value: {
      offset: '+01:00',
      zone: 'Europe/Dublin',
    },
  },
  {
    label: 'UTC+02:00 Europe/Gibraltar',
    value: {
      offset: '+02:00',
      zone: 'Europe/Gibraltar',
    },
  },
  {
    label: 'UTC+01:00 Europe/Guernsey',
    value: {
      offset: '+01:00',
      zone: 'Europe/Guernsey',
    },
  },
  {
    label: 'UTC+03:00 Europe/Helsinki',
    value: {
      offset: '+03:00',
      zone: 'Europe/Helsinki',
    },
  },
  {
    label: 'UTC+01:00 Europe/Isle of Man',
    value: {
      offset: '+01:00',
      zone: 'Europe/Isle_of_Man',
    },
  },
  {
    label: 'UTC+03:00 Europe/Istanbul',
    value: {
      offset: '+03:00',
      zone: 'Europe/Istanbul',
    },
  },
  {
    label: 'UTC+01:00 Europe/Jersey',
    value: {
      offset: '+01:00',
      zone: 'Europe/Jersey',
    },
  },
  {
    label: 'UTC+02:00 Europe/Kaliningrad',
    value: {
      offset: '+02:00',
      zone: 'Europe/Kaliningrad',
    },
  },
  {
    label: 'UTC+03:00 Europe/Kiev',
    value: {
      offset: '+03:00',
      zone: 'Europe/Kiev',
    },
  },
  {
    label: 'UTC+03:00 Europe/Kirov',
    value: {
      offset: '+03:00',
      zone: 'Europe/Kirov',
    },
  },
  {
    label: 'UTC+01:00 Europe/Lisbon',
    value: {
      offset: '+01:00',
      zone: 'Europe/Lisbon',
    },
  },
  {
    label: 'UTC+02:00 Europe/Ljubljana',
    value: {
      offset: '+02:00',
      zone: 'Europe/Ljubljana',
    },
  },
  {
    label: 'UTC+01:00 Europe/London',
    value: {
      offset: '+01:00',
      zone: 'Europe/London',
    },
  },
  {
    label: 'UTC+02:00 Europe/Luxembourg',
    value: {
      offset: '+02:00',
      zone: 'Europe/Luxembourg',
    },
  },
  {
    label: 'UTC+02:00 Europe/Madrid',
    value: {
      offset: '+02:00',
      zone: 'Europe/Madrid',
    },
  },
  {
    label: 'UTC+02:00 Europe/Malta',
    value: {
      offset: '+02:00',
      zone: 'Europe/Malta',
    },
  },
  {
    label: 'UTC+03:00 Europe/Mariehamn',
    value: {
      offset: '+03:00',
      zone: 'Europe/Mariehamn',
    },
  },
  {
    label: 'UTC+03:00 Europe/Minsk',
    value: {
      offset: '+03:00',
      zone: 'Europe/Minsk',
    },
  },
  {
    label: 'UTC+02:00 Europe/Monaco',
    value: {
      offset: '+02:00',
      zone: 'Europe/Monaco',
    },
  },
  {
    label: 'UTC+03:00 Europe/Moscow',
    value: {
      offset: '+03:00',
      zone: 'Europe/Moscow',
    },
  },
  {
    label: 'UTC+03:00 Europe/Nicosia',
    value: {
      offset: '+03:00',
      zone: 'Europe/Nicosia',
    },
  },
  {
    label: 'UTC+02:00 Europe/Oslo',
    value: {
      offset: '+02:00',
      zone: 'Europe/Oslo',
    },
  },
  {
    label: 'UTC+02:00 Europe/Paris',
    value: {
      offset: '+02:00',
      zone: 'Europe/Paris',
    },
  },
  {
    label: 'UTC+02:00 Europe/Podgorica',
    value: {
      offset: '+02:00',
      zone: 'Europe/Podgorica',
    },
  },
  {
    label: 'UTC+02:00 Europe/Prague',
    value: {
      offset: '+02:00',
      zone: 'Europe/Prague',
    },
  },
  {
    label: 'UTC+03:00 Europe/Riga',
    value: {
      offset: '+03:00',
      zone: 'Europe/Riga',
    },
  },
  {
    label: 'UTC+02:00 Europe/Rome',
    value: {
      offset: '+02:00',
      zone: 'Europe/Rome',
    },
  },
  {
    label: 'UTC+04:00 Europe/Samara',
    value: {
      offset: '+04:00',
      zone: 'Europe/Samara',
    },
  },
  {
    label: 'UTC+02:00 Europe/San Marino',
    value: {
      offset: '+02:00',
      zone: 'Europe/San_Marino',
    },
  },
  {
    label: 'UTC+02:00 Europe/Sarajevo',
    value: {
      offset: '+02:00',
      zone: 'Europe/Sarajevo',
    },
  },
  {
    label: 'UTC+04:00 Europe/Saratov',
    value: {
      offset: '+04:00',
      zone: 'Europe/Saratov',
    },
  },
  {
    label: 'UTC+03:00 Europe/Simferopol',
    value: {
      offset: '+03:00',
      zone: 'Europe/Simferopol',
    },
  },
  {
    label: 'UTC+02:00 Europe/Skopje',
    value: {
      offset: '+02:00',
      zone: 'Europe/Skopje',
    },
  },
  {
    label: 'UTC+03:00 Europe/Sofia',
    value: {
      offset: '+03:00',
      zone: 'Europe/Sofia',
    },
  },
  {
    label: 'UTC+02:00 Europe/Stockholm',
    value: {
      offset: '+02:00',
      zone: 'Europe/Stockholm',
    },
  },
  {
    label: 'UTC+03:00 Europe/Tallinn',
    value: {
      offset: '+03:00',
      zone: 'Europe/Tallinn',
    },
  },
  {
    label: 'UTC+02:00 Europe/Tirane',
    value: {
      offset: '+02:00',
      zone: 'Europe/Tirane',
    },
  },
  {
    label: 'UTC+03:00 Europe/Tiraspol',
    value: {
      offset: '+03:00',
      zone: 'Europe/Tiraspol',
    },
  },
  {
    label: 'UTC+04:00 Europe/Ulyanovsk',
    value: {
      offset: '+04:00',
      zone: 'Europe/Ulyanovsk',
    },
  },
  {
    label: 'UTC+03:00 Europe/Uzhgorod',
    value: {
      offset: '+03:00',
      zone: 'Europe/Uzhgorod',
    },
  },
  {
    label: 'UTC+02:00 Europe/Vaduz',
    value: {
      offset: '+02:00',
      zone: 'Europe/Vaduz',
    },
  },
  {
    label: 'UTC+02:00 Europe/Vatican',
    value: {
      offset: '+02:00',
      zone: 'Europe/Vatican',
    },
  },
  {
    label: 'UTC+02:00 Europe/Vienna',
    value: {
      offset: '+02:00',
      zone: 'Europe/Vienna',
    },
  },
  {
    label: 'UTC+03:00 Europe/Vilnius',
    value: {
      offset: '+03:00',
      zone: 'Europe/Vilnius',
    },
  },
  {
    label: 'UTC+04:00 Europe/Volgograd',
    value: {
      offset: '+04:00',
      zone: 'Europe/Volgograd',
    },
  },
  {
    label: 'UTC+02:00 Europe/Warsaw',
    value: {
      offset: '+02:00',
      zone: 'Europe/Warsaw',
    },
  },
  {
    label: 'UTC+02:00 Europe/Zagreb',
    value: {
      offset: '+02:00',
      zone: 'Europe/Zagreb',
    },
  },
  {
    label: 'UTC+03:00 Europe/Zaporozhye',
    value: {
      offset: '+03:00',
      zone: 'Europe/Zaporozhye',
    },
  },
  {
    label: 'UTC+02:00 Europe/Zurich',
    value: {
      offset: '+02:00',
      zone: 'Europe/Zurich',
    },
  },
  {
    label: 'UTC+01:00 GB',
    value: {
      offset: '+01:00',
      zone: 'GB',
    },
  },
  {
    label: 'UTC+01:00 GB-Eire',
    value: {
      offset: '+01:00',
      zone: 'GB-Eire',
    },
  },
  {
    label: 'UTC+00:00 Greenwich',
    value: {
      offset: '+00:00',
      zone: 'Greenwich',
    },
  },
  {
    label: 'UTC-10:00 HST',
    value: {
      offset: '-10:00',
      zone: 'HST',
    },
  },
  {
    label: 'UTC+08:00 Hongkong',
    value: {
      offset: '+08:00',
      zone: 'Hongkong',
    },
  },
  {
    label: 'UTC+00:00 Iceland',
    value: {
      offset: '+00:00',
      zone: 'Iceland',
    },
  },
  {
    label: 'UTC+03:00 Indian/Antananarivo',
    value: {
      offset: '+03:00',
      zone: 'Indian/Antananarivo',
    },
  },
  {
    label: 'UTC+06:00 Indian/Chagos',
    value: {
      offset: '+06:00',
      zone: 'Indian/Chagos',
    },
  },
  {
    label: 'UTC+07:00 Indian/Christmas',
    value: {
      offset: '+07:00',
      zone: 'Indian/Christmas',
    },
  },
  {
    label: 'UTC+06:30 Indian/Cocos',
    value: {
      offset: '+06:30',
      zone: 'Indian/Cocos',
    },
  },
  {
    label: 'UTC+03:00 Indian/Comoro',
    value: {
      offset: '+03:00',
      zone: 'Indian/Comoro',
    },
  },
  {
    label: 'UTC+05:00 Indian/Kerguelen',
    value: {
      offset: '+05:00',
      zone: 'Indian/Kerguelen',
    },
  },
  {
    label: 'UTC+04:00 Indian/Mahe',
    value: {
      offset: '+04:00',
      zone: 'Indian/Mahe',
    },
  },
  {
    label: 'UTC+05:00 Indian/Maldives',
    value: {
      offset: '+05:00',
      zone: 'Indian/Maldives',
    },
  },
  {
    label: 'UTC+04:00 Indian/Mauritius',
    value: {
      offset: '+04:00',
      zone: 'Indian/Mauritius',
    },
  },
  {
    label: 'UTC+03:00 Indian/Mayotte',
    value: {
      offset: '+03:00',
      zone: 'Indian/Mayotte',
    },
  },
  {
    label: 'UTC+04:00 Indian/Reunion',
    value: {
      offset: '+04:00',
      zone: 'Indian/Reunion',
    },
  },
  {
    label: 'UTC+04:30 Iran',
    value: {
      offset: '+04:30',
      zone: 'Iran',
    },
  },
  {
    label: 'UTC+03:00 Israel',
    value: {
      offset: '+03:00',
      zone: 'Israel',
    },
  },
  {
    label: 'UTC-05:00 Jamaica',
    value: {
      offset: '-05:00',
      zone: 'Jamaica',
    },
  },
  {
    label: 'UTC+09:00 Japan',
    value: {
      offset: '+09:00',
      zone: 'Japan',
    },
  },
  {
    label: 'UTC+12:00 Kwajalein',
    value: {
      offset: '+12:00',
      zone: 'Kwajalein',
    },
  },
  {
    label: 'UTC+02:00 Libya',
    value: {
      offset: '+02:00',
      zone: 'Libya',
    },
  },
  {
    label: 'UTC+02:00 MET',
    value: {
      offset: '+02:00',
      zone: 'MET',
    },
  },
  {
    label: 'UTC-07:00 MST',
    value: {
      offset: '-07:00',
      zone: 'MST',
    },
  },
  {
    label: 'UTC-06:00 MST7MDT',
    value: {
      offset: '-06:00',
      zone: 'MST7MDT',
    },
  },
  {
    label: 'UTC-07:00 Mexico/BajaNorte',
    value: {
      offset: '-07:00',
      zone: 'Mexico/BajaNorte',
    },
  },
  {
    label: 'UTC-06:00 Mexico/BajaSur',
    value: {
      offset: '-06:00',
      zone: 'Mexico/BajaSur',
    },
  },
  {
    label: 'UTC-05:00 Mexico/General',
    value: {
      offset: '-05:00',
      zone: 'Mexico/General',
    },
  },
  {
    label: 'UTC+12:00 NZ',
    value: {
      offset: '+12:00',
      zone: 'NZ',
    },
  },
  {
    label: 'UTC+12:45 NZ-CHAT',
    value: {
      offset: '+12:45',
      zone: 'NZ-CHAT',
    },
  },
  {
    label: 'UTC-06:00 Navajo',
    value: {
      offset: '-06:00',
      zone: 'Navajo',
    },
  },
  {
    label: 'UTC+08:00 PRC',
    value: {
      offset: '+08:00',
      zone: 'PRC',
    },
  },
  {
    label: 'UTC-07:00 PST8PDT',
    value: {
      offset: '-07:00',
      zone: 'PST8PDT',
    },
  },
  {
    label: 'UTC+13:00 Pacific/Apia',
    value: {
      offset: '+13:00',
      zone: 'Pacific/Apia',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Auckland',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Auckland',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Bougainville',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Bougainville',
    },
  },
  {
    label: 'UTC+12:45 Pacific/Chatham',
    value: {
      offset: '+12:45',
      zone: 'Pacific/Chatham',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Chuuk',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Chuuk',
    },
  },
  {
    label: 'UTC-06:00 Pacific/Easter',
    value: {
      offset: '-06:00',
      zone: 'Pacific/Easter',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Efate',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Efate',
    },
  },
  {
    label: 'UTC+13:00 Pacific/Enderbury',
    value: {
      offset: '+13:00',
      zone: 'Pacific/Enderbury',
    },
  },
  {
    label: 'UTC+13:00 Pacific/Fakaofo',
    value: {
      offset: '+13:00',
      zone: 'Pacific/Fakaofo',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Fiji',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Fiji',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Funafuti',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Funafuti',
    },
  },
  {
    label: 'UTC-06:00 Pacific/Galapagos',
    value: {
      offset: '-06:00',
      zone: 'Pacific/Galapagos',
    },
  },
  {
    label: 'UTC-09:00 Pacific/Gambier',
    value: {
      offset: '-09:00',
      zone: 'Pacific/Gambier',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Guadalcanal',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Guadalcanal',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Guam',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Guam',
    },
  },
  {
    label: 'UTC-10:00 Pacific/Honolulu',
    value: {
      offset: '-10:00',
      zone: 'Pacific/Honolulu',
    },
  },
  {
    label: 'UTC-10:00 Pacific/Johnston',
    value: {
      offset: '-10:00',
      zone: 'Pacific/Johnston',
    },
  },
  {
    label: 'UTC+14:00 Pacific/Kiritimati',
    value: {
      offset: '+14:00',
      zone: 'Pacific/Kiritimati',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Kosrae',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Kosrae',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Kwajalein',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Kwajalein',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Majuro',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Majuro',
    },
  },
  {
    label: 'UTC-09:30 Pacific/Marquesas',
    value: {
      offset: '-09:30',
      zone: 'Pacific/Marquesas',
    },
  },
  {
    label: 'UTC-11:00 Pacific/Midway',
    value: {
      offset: '-11:00',
      zone: 'Pacific/Midway',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Nauru',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Nauru',
    },
  },
  {
    label: 'UTC-11:00 Pacific/Niue',
    value: {
      offset: '-11:00',
      zone: 'Pacific/Niue',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Norfolk',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Norfolk',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Noumea',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Noumea',
    },
  },
  {
    label: 'UTC-11:00 Pacific/Pago Pago',
    value: {
      offset: '-11:00',
      zone: 'Pacific/Pago_Pago',
    },
  },
  {
    label: 'UTC+09:00 Pacific/Palau',
    value: {
      offset: '+09:00',
      zone: 'Pacific/Palau',
    },
  },
  {
    label: 'UTC-08:00 Pacific/Pitcairn',
    value: {
      offset: '-08:00',
      zone: 'Pacific/Pitcairn',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Pohnpei',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Pohnpei',
    },
  },
  {
    label: 'UTC+11:00 Pacific/Ponape',
    value: {
      offset: '+11:00',
      zone: 'Pacific/Ponape',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Port Moresby',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Port_Moresby',
    },
  },
  {
    label: 'UTC-10:00 Pacific/Rarotonga',
    value: {
      offset: '-10:00',
      zone: 'Pacific/Rarotonga',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Saipan',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Saipan',
    },
  },
  {
    label: 'UTC-11:00 Pacific/Samoa',
    value: {
      offset: '-11:00',
      zone: 'Pacific/Samoa',
    },
  },
  {
    label: 'UTC-10:00 Pacific/Tahiti',
    value: {
      offset: '-10:00',
      zone: 'Pacific/Tahiti',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Tarawa',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Tarawa',
    },
  },
  {
    label: 'UTC+13:00 Pacific/Tongatapu',
    value: {
      offset: '+13:00',
      zone: 'Pacific/Tongatapu',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Truk',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Truk',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Wake',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Wake',
    },
  },
  {
    label: 'UTC+12:00 Pacific/Wallis',
    value: {
      offset: '+12:00',
      zone: 'Pacific/Wallis',
    },
  },
  {
    label: 'UTC+10:00 Pacific/Yap',
    value: {
      offset: '+10:00',
      zone: 'Pacific/Yap',
    },
  },
  {
    label: 'UTC+02:00 Poland',
    value: {
      offset: '+02:00',
      zone: 'Poland',
    },
  },
  {
    label: 'UTC+01:00 Portugal',
    value: {
      offset: '+01:00',
      zone: 'Portugal',
    },
  },
  {
    label: 'UTC+08:00 ROC',
    value: {
      offset: '+08:00',
      zone: 'ROC',
    },
  },
  {
    label: 'UTC+09:00 ROK',
    value: {
      offset: '+09:00',
      zone: 'ROK',
    },
  },
  {
    label: 'UTC+08:00 Singapore',
    value: {
      offset: '+08:00',
      zone: 'Singapore',
    },
  },
  {
    label: 'UTC+03:00 Turkey',
    value: {
      offset: '+03:00',
      zone: 'Turkey',
    },
  },
  {
    label: 'UTC+00:00 UCT',
    value: {
      offset: '+00:00',
      zone: 'UCT',
    },
  },
  {
    label: 'UTC-08:00 US/Alaska',
    value: {
      offset: '-08:00',
      zone: 'US/Alaska',
    },
  },
  {
    label: 'UTC-09:00 US/Aleutian',
    value: {
      offset: '-09:00',
      zone: 'US/Aleutian',
    },
  },
  {
    label: 'UTC-07:00 US/Arizona',
    value: {
      offset: '-07:00',
      zone: 'US/Arizona',
    },
  },
  {
    label: 'UTC-05:00 US/Central',
    value: {
      offset: '-05:00',
      zone: 'US/Central',
    },
  },
  {
    label: 'UTC-04:00 US/East-Indiana',
    value: {
      offset: '-04:00',
      zone: 'US/East-Indiana',
    },
  },
  {
    label: 'UTC-04:00 US/Eastern',
    value: {
      offset: '-04:00',
      zone: 'US/Eastern',
    },
  },
  {
    label: 'UTC-10:00 US/Hawaii',
    value: {
      offset: '-10:00',
      zone: 'US/Hawaii',
    },
  },
  {
    label: 'UTC-05:00 US/Indiana-Starke',
    value: {
      offset: '-05:00',
      zone: 'US/Indiana-Starke',
    },
  },
  {
    label: 'UTC-04:00 US/Michigan',
    value: {
      offset: '-04:00',
      zone: 'US/Michigan',
    },
  },
  {
    label: 'UTC-06:00 US/Mountain',
    value: {
      offset: '-06:00',
      zone: 'US/Mountain',
    },
  },
  {
    label: 'UTC-07:00 US/Pacific',
    value: {
      offset: '-07:00',
      zone: 'US/Pacific',
    },
  },
  {
    label: 'UTC-11:00 US/Samoa',
    value: {
      offset: '-11:00',
      zone: 'US/Samoa',
    },
  },
  {
    label: 'UTC+00:00 UTC',
    value: {
      offset: '+00:00',
      zone: 'UTC',
    },
  },
  {
    label: 'UTC+00:00 Universal',
    value: {
      offset: '+00:00',
      zone: 'Universal',
    },
  },
  {
    label: 'UTC+03:00 W-SU',
    value: {
      offset: '+03:00',
      zone: 'W-SU',
    },
  },
  {
    label: 'UTC+01:00 WET',
    value: {
      offset: '+01:00',
      zone: 'WET',
    },
  },
  {
    label: 'UTC+00:00 Zulu',
    value: {
      offset: '+00:00',
      zone: 'Zulu',
    },
  },
];

export const TIMEZONES_LABEL = {
  moscow: 180,
  novosibirsk: 420,
};

export interface TimezonesList {
  [key: number]: {
    label: string;
    value: number;
  }
}

export const TIMEZONES_SHORT: TimezonesList = {
  0: {
    label: 'UTC +0',
    value: 0,
  },
  60: {
    label: 'UTC +1',
    value: 60,
  },
  120: {
    label: 'UTC +2',
    value: 120,
  },
  180: {
    label: 'UTC +3',
    value: 180,
  },
  240: {
    label: 'UTC +4',
    value: 240,
  },
  270: {
    label: 'UTC +4:30',
    value: 270,
  },
  300: {
    label: 'UTC +5',
    value: 300,
  },
  330: {
    label: 'UTC +5:30',
    value: 330,
  },
  345: {
    label: 'UTC +5:45',
    value: 345,
  },
  360: {
    label: 'UTC +6',
    value: 360,
  },
  390: {
    label: 'UTC +6:30',
    value: 390,
  },
  420: {
    label: 'UTC +7',
    value: 420,
  },
  480: {
    label: 'UTC +8',
    value: 480,
  },
  525: {
    label: 'UTC +8:45',
    value: 525,
  },
  540: {
    label: 'UTC +9',
    value: 540,
  },
  570: {
    label: 'UTC +9:30',
    value: 570,
  },
  600: {
    label: 'UTC +10',
    value: 600,
  },
  630: {
    label: 'UTC +10:30',
    value: 630,
  },
  660: {
    label: 'UTC +11',
    value: 660,
  },
  720: {
    label: 'UTC +12',
    value: 720,
  },
  765: {
    label: 'UTC +12:45',
    value: 765,
  },
  780: {
    label: 'UTC +13',
    value: 780,
  },
  840: {
    label: 'UTC +14',
    value: 840,
  },
  '-60': {
    label: 'UTC -1',
    value: -60,
  },
  '-120': {
    label: 'UTC -2',
    value: -120,
  },
  '-150': {
    label: 'UTC -2:30',
    value: -150,
  },
  '-180': {
    label: 'UTC -3',
    value: -180,
  },
  '-240': {
    label: 'UTC -4',
    value: -240,
  },
  '-300': {
    label: 'UTC -5',
    value: -300,
  },
  '-360': {
    label: 'UTC -6',
    value: -360,
  },
  '-420': {
    label: 'UTC -7',
    value: -420,
  },
  '-480': {
    label: 'UTC -8',
    value: -480,
  },
  '-540': {
    label: 'UTC -9',
    value: -540,
  },
  '-570': {
    label: 'UTC -9:30',
    value: -570,
  },
  '-600': {
    label: 'UTC -10',
    value: -600,
  },
  '-660': {
    label: 'UTC -11',
    value: -660,
  },
  '-720': {
    label: 'UTC -12',
    value: -720,
  },
};
