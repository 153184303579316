import React from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import ROUTES from '../../helpers/constants/_common/routes';
import RootLayout from '../Root';
import PageDoesNotExist from '../PageDoesNotExist';
import { Redux } from '../../helpers/types/_common';
import { filterPagesByAccessType, checkRouteAccess } from './helpers/helpers';
import Menu from '../Root/components/Menu';
import LayoutOverlay from '../LayoutOverlay/LayoutOverlay';

const AppRoutesLayout = () => {
  const { permissions } = useSelector((state: Redux) => state.login);

  const routes = filterPagesByAccessType(permissions);

  return (
    <div>
      <LayoutOverlay type="desktop" />

      <Menu />

      <Switch>
        { checkRouteAccess(ROUTES.sandbox, permissions) }

        {
          _.map(routes, (page, pageKey) => (
            <Route
              key={pageKey}
              path={page.path}
              render={() => <RootLayout page={page} />}
              exact={_.isBoolean(page.exact) ? page.exact : true}
            />
          ))
        }
        <Route path="*" component={PageDoesNotExist} />
      </Switch>
    </div>
  );
};

export default AppRoutesLayout;
