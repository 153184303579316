/* eslint-disable import/prefer-default-export */
import { FULL_MONTH_FORMAT } from 'helpers/constants/_common/timeFormats';
import getFromStore from 'helpers/utils/getFromStore';

/** Returns month name by month number. */
export const getMonthNameByNumber = (month: number, format: string = FULL_MONTH_FORMAT) => {
  const { selectedDateObj } = getFromStore('timeSheet.tableSettings');

  return selectedDateObj.month(month - 1).format(format);
};
