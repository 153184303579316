import React, { useContext } from 'react';
import MDEditor, { commands, EditorContext, ICommand } from '@uiw/react-md-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rehypeSanitize from 'rehype-sanitize';

import S from './styles.module.sass';

interface MarkupEditorProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const Button = () => {
  const { preview, dispatch } = useContext(EditorContext);
  const click = () => {
    dispatch!({ preview: preview === 'edit' ? 'preview' : 'edit' });
  };

  return (
    <button type="button" onClick={click} data-name="link" aria-label="Preview" title="Preview">
      <FontAwesomeIcon icon="eye" />
    </button>
  );
};

const codePreview: ICommand = {
  name: 'preview',
  keyCommand: 'preview',
  value: 'preview',
  icon: <Button />,
};

const MarkupEditor: React.FC<MarkupEditorProps> = ({ value, onChange }) => (
  <MDEditor
    value={value}
    onChange={onChange}
    preview="edit"
    className={S.MDEditor}
    previewOptions={{
      rehypePlugins: [ [ rehypeSanitize ] ],
    }}
    extraCommands={[ codePreview ]}
    commands={[ commands.bold, commands.italic, commands.strikethrough, commands.link ]}
  />
);

export default MarkupEditor;
