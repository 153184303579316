import React, { useState } from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import UserAvatar from 'components/UserAvatar/UserAvatar';
import { ReactComponent as Xmark } from 'helpers/icons/xmark.svg';
import { PartnerParticipantsOutput } from 'components/PartnerInfo/components/PartnerParticipantsTab/helpers/types';

import s from './styles.module.sass';

interface CuratorCardProps {
  curator: PartnerParticipantsOutput;
  onDelete: () => Promise<any>;
}

const CuratorCard: React.FC<CuratorCardProps> = (props) => {
  const [ isDeleteLoading, setDeleteLoading ] = useState(false);
  const { onDelete, curator } = props;
  const { forename, surname, avatarUrl } = curator.user;

  const handleCuratorDeleting = () => {
    setDeleteLoading(true);
    onDelete().finally(() => setDeleteLoading(false));
  };

  return (
    <div className={s.curatorCard}>
      <div className={s.curatorAvatar}>
        <UserAvatar avatarUrl={avatarUrl} size={32} />
      </div>
      <span className={s.curatorName}>{`${forename} ${surname}`}</span>
      <div className={classNames(s.buttons, { [s.showed]: isDeleteLoading })}>
        <Button onClick={handleCuratorDeleting} className={s.curatorButton}>
          {
            isDeleteLoading
              ? <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin />
              : <Xmark />
          }
        </Button>
      </div>
    </div>
  );
};

export default CuratorCard;
