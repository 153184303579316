export interface PartnerIsOvertimesAllowed {
    [key: string]: PartnerIsOvertimesAllowedItem
  }
  
  export interface PartnerIsOvertimesAllowedItem {
    text: string;
    value: string;
  }

  
  export const PARTNER_IS_OVERTIMES_ALLOWED: PartnerIsOvertimesAllowed = {
    ALL: {
      text: 'Overtimes: All',
      value: 'ALL',
    },
    NOT_ALLOWED: {
      text: 'Overtimes: Not allowed',
      value: 'NOT_ALLOWED',
    },
    ALLOWED: {
      text: 'Overtimes: Allowed',
      value: 'ALLOWED',
    },
  };
  