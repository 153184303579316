import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';

const cx = classNames.bind(S);

interface SelectComponentProps {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  id?: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  wrapperClassName?: string;
}

interface SelectComponentState {
  selected: string | number;
  value: string | number;
}

export default class Select extends React.Component<
  SelectComponentProps,
  SelectComponentState
> {
  constructor(props: SelectComponentProps) {
    super(props);

    this.state = {
      value: this.props.value,
      selected: this.props.value
    };
  }

  componentDidUpdate(prevProps: Readonly<SelectComponentProps>): void {
    if (
      this.props.value &&
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.selected
    ) {
      this.setState({ selected: this.props.value });
    }
  }

  onChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    const newValue = e.target.value;
    this.props.onChange(e);
    this.setState({ selected: newValue });
  }

  render(): React.ReactNode {
    const { selected } = this.state;
    const { id, style, className } = this.props;

    return (
      <select
        className={cx('qs-form__select', className)}
        value={selected}
        onChange={this.onChange.bind(this)}
        id={id}
        style={style}
      >
        {this.props.children}
      </select>
    );
  }
}
