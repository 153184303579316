export const EMPLOYEE_OVERDUE_RANGES = {
  lessThan30: '0to29',
  lessThan60: '30to59',
  lessThan90: '60to89',
  lessThan120: '90to119',
  moreThan120: '120to',
};

export const EMPLOYEE_TIMESHEET_OVERDUE_RANGES = {
  lessThan5: '0to5',
  lessThan10: '6to10',
  lessThan15: '11to15',
  lessThan20: '16to20',
  moreThan20: '21to',
};

export const EMPLOYEE_TABS = {
  ACTIVE: {
    radioValue: 'active',
  },
  INACTIVE: {
    radioValue: 'inactive',
  },
  INVITED: {
    radioValue: 'invited',
  },  
  ADMINS: {
    radioValue: 'admins',
  },  
  BOT: {
    radioValue: 'BOT',
  },
};

export const PARTICIPANTS_TABS = {
  ALL_MEMBERS: {
    radioValue: 'all',
  },
  CURATORS: {
    radioValue: 'curators',
  },
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  CURATOR: 'CURATOR',
};
