import * as Actions from '../constants/Logs';
import Subtypes from '../constants/__common__/Subtypes';
import logsRequests from '../requests/Logs';

export function initiate() {
  return {
    type: Actions.INITIATE,
  };
}

export function changeStartDate(date) {
  return {
    type: Actions.CHANGE_START_DATE,
    payload: date,
  };
}

export function changeEndDate(date) {
  return {
    type: Actions.CHANGE_END_DATE,
    payload: date,
  };
}

export function changeFilter(string, by) {
  return {
    type: Actions.CHANGE_FILTER,
    payload: { string, by },
  };
}

export function findLogs(data) {
  return (dispatch) => {
    dispatch(requestLogsStart());
    logsRequests.getPaginatedLogs(data)
      .then((response) => {
        dispatch(requestLogsSuccess(response.objects, response.meta.totalCount));
      })
      .catch(error => dispatch(requestLogsError()));
  };
}

export function changeSortBy(data) {
  return (dispatch) => {
    dispatch(requestLogsStart());
    logsRequests.getPaginatedLogs(data)
      .then((response) => {
        dispatch(requestLogsSuccess(response.objects, response.meta.totalCount));
        dispatch({
          type: Actions.CHAGNE_SORT_BY,
          payload: {
            up: data.sortBy.up,
            column: data.sortBy.column,
          },
        });
        dispatch(changeActiveTSPage(data.page));
      })
      .catch(error => dispatch(requestLogsError()));
  };
}

function changeActiveTSPage(int) {
  return {
    type: Actions.CHANGE_ACTIVE_TS_PAGE,
    payload: int,
  };
}

export function changeActivePage(data) {
  return (dispatch) => {
    dispatch(requestLogsStart());
    logsRequests.getPaginatedLogs(data)
      .then((response) => {
        dispatch(requestLogsSuccess(response.objects, response.meta.totalCount));
        dispatch(changeActiveTSPage(data.page));
      })
      .catch(error => dispatch(requestLogsError()));
  };
}

export function changeAmountOfRows(data) {
  return (dispatch) => {
    dispatch(requestLogsStart());
    logsRequests.getPaginatedLogs(data)
      .then((response) => {
        dispatch(requestLogsSuccess(response.objects, response.meta.totalCount));
        dispatch({
          type: Actions.CHANGE_MAX_ROWS_COUNT,
          payload: data.limit,
        });
        dispatch(changeActiveTSPage(1));
      })
      .catch(error => dispatch(requestLogsError()));
  };
}

const requestLogsStart = () => ({ type: Actions.GET_LOGS, subtype: Subtypes.Request.Loading });
const requestLogsError = () => ({ type: Actions.GET_LOGS, subtype: Subtypes.Request.Error });
const requestLogsSuccess = (objects, total) => ({
  type: Actions.GET_LOGS,
  subtype: Subtypes.Request.Success,
  payload: objects,
  meta: total,
});

export function getLogs(data) {
  return (dispatch) => {
    dispatch(requestLogsStart());
    logsRequests.getPaginatedLogs(data)
      .then((response) => {
        dispatch(requestLogsSuccess(response.objects, response.meta.totalCount));
        dispatch(changeActiveTSPage(1));
      })
      .catch(error => dispatch(requestLogsError()));
  };
}
