export type TableNames = {
  MANAGE_SKILL_TAGS: 'manageSkillTags',
  FIND_SPECIALISTS: 'findSpecialists',
  REQUEST_CANDIDATES: 'requestCandidates',
  REQUEST_SPECIALISTS: 'requestSpecialists',
  BOOKKEEPING_ITEMS: 'bookkeepingItems',
  EMPLOYEES: 'employees',
  PARTNERS: 'partners',
  PARTNERS_USERS: 'partnersUsers',
  ENTITIES: 'entities',
  PARTNER_PARTICIPANTS: 'partnerParticipants',
  VACATION_REPORT: 'vacationReport',
  HOUR_REPORT: 'hourReport',
  CURATOR_HOUR_REPORT: 'curatorHourReport',
  USER_HOUR_REPORTS: 'userHourReports',
};

export const TABLE_NAMES: TableNames = {
  MANAGE_SKILL_TAGS: 'manageSkillTags',
  FIND_SPECIALISTS: 'findSpecialists',
  REQUEST_CANDIDATES: 'requestCandidates',
  REQUEST_SPECIALISTS: 'requestSpecialists',
  BOOKKEEPING_ITEMS: 'bookkeepingItems',
  EMPLOYEES: 'employees',
  PARTNERS: 'partners',
  PARTNERS_USERS: 'partnersUsers',
  ENTITIES: 'entities',
  PARTNER_PARTICIPANTS: 'partnerParticipants',
  VACATION_REPORT: 'vacationReport',
  HOUR_REPORT: 'hourReport',
  CURATOR_HOUR_REPORT: 'curatorHourReport',
  USER_HOUR_REPORTS: 'userHourReports',
};
