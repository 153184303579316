import { combineReducers } from 'redux';

import login from '../../V1/reducers/login';
import timeTracker from '../../V1/reducers/timetracker';
import partners from '../../V1/reducers/partners';
import mainTable from '../../V1/reducers/mainTable';
import hourReport from '../../V1/reducers/hourReport';
import curatorHourReport from '../../V1/reducers/curatorHourReport';
import usersHourReport from '../../V1/reducers/usersHourReport';
import businessCalendar from '../../V1/reducers/businessCalendar';
import businessCalendar2 from '../BusinessCalendar/reducer';
import hotProjects from '../../V1/reducers/HotProjects/hotProjects';
import tableOverlay from '../../V1/reducers/tableOverlay';
import users from '../../V1/reducers/users';
import managePartners from '../../V1/reducers/managePartners';
import selection from '../../V1/reducers/selection';
import changeTimesheet from '../../V1/reducers/changeTimesheet';
import logs from '../../V1/reducers/logs';
import globalSearch from '../../V1/reducers/globalSearch';
import entities from '../../V1/reducers/entities';
import occupations from '../../V1/reducers/occupations';
import skillsetV1 from '../../V1/reducers/skillset';
import vacancies from '../../V1/reducers/VacancyPage/vacancies';
import recommendations from '../../V1/reducers/VacancyPage/recommendations';
import notifications from '../../api/Notifications/reducer';
import tables from '../Tables/reducer';
import errorHandler from '../ErrorHandler/reducer';
import timeSheet from '../TimeSheet/reducer';

// V2

import skillset from '../SkillSet/reducer';

//! ! index.tsx in "reducers" usually exports just object with reducers, without combining

export default combineReducers({
  login: login.reducer,
  timeTracker: timeTracker.reducer,
  partners: partners.reducer,
  mainTable: mainTable.reducer,
  hourReport: hourReport.reducer,
  curatorHourReport: curatorHourReport.reducer,
  usersHourReport: usersHourReport.reducer,
  users: users.reducer,
  managePartners: managePartners.reducer,
  tableOverlay: tableOverlay.reducer,
  selection: selection.reducer,
  changeTimesheet: changeTimesheet.reducer,
  logs: logs.reducer,
  entities: entities.reducer,
  globalSearch: globalSearch.reducer,
  occupations: occupations.reducer,
  skillsetV1: skillsetV1.reducer,
  skillset: skillset.reducer,
  businessCalendar: businessCalendar.reducer,
  businessCalendar2: businessCalendar2.reducer,
  hotProjects: hotProjects.reducer,
  timeSheet: timeSheet.reducer,
  vacancies: combineReducers({
    vacanciesSection: vacancies.reducer,
    recommendationsSection: recommendations.reducer,
  }),
  notifications: notifications.NotificationReducer,
  tables: tables.TablesReducer,
  errorHandler: errorHandler.ErrorsReducer,
});
