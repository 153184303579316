import { CellType } from 'components/TimeSheet/helpers/types';

const TABLE_CELLS = Array(48) // half hours
  .fill(null)
  .map((e, i) => ({
    cellIndex: i,
    type: CellType.Empty,
  }));

export default TABLE_CELLS;
