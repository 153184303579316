import React from 'react';
import { Col, Input, Row, Select, Space } from 'antd';
import cx from 'classnames/bind';
import dayjs from 'dayjs';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';

import inputs from 'helpers/styles/components/inputs.module.sass';
import FieldCard from 'components/_shared/FieldCard/FieldCard';
import { PartnerDetailsOutput } from 'api/Partners/types';
import InfinityScroll from 'components/_shared/InfinityScroll';
import selects from 'helpers/styles/components/selects.module.sass';
import { BaseUserOutput } from 'api/User/types';
import { getAllUsers } from 'api/User/requests';

import partners from '../../styles.module.sass';

interface CreatedByCardProps {
  partnerFields: PartnerDetailsOutput;
  isEdit: boolean;
  changeField: (key: keyof PartnerDetailsOutput, value: any) => void;
}

const CreatedByCard: React.FC<CreatedByCardProps> = ({ partnerFields, changeField, isEdit }) => {
  const onRequesterChange = (userId: number | string | LabeledValue, list: BaseUserOutput[]) => {
    const user = _.find(list, user => user.userId === userId);
    
    changeField('requester', user);
  };

  return (
    <FieldCard className={partners.primaryCard} fullHeight>
      <Row>
        <Col span={12}>
          <Space className={partners.fieldWrapper} direction="vertical">
            <span className={partners.fieldTitle}>Created by</span>
            <Input
              className={cx(inputs.qsInput, partners.partnerInput)}
              value={`${partnerFields.author.forename} ${partnerFields.author.surname}`}
              disabled
            />
          </Space>
        </Col>
        <Col span={12}>
          <Space className={partners.fieldWrapper} direction="vertical">
            <span className={partners.fieldTitle}>Created on</span>
            <Input
              className={cx(inputs.qsInput, partners.partnerInput)}
              value={dayjs(partnerFields.creationDate).format('DD.MM.YYYY [at] hh:mm')}
              disabled
            />
          </Space>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Space className={partners.fieldWrapper} direction="vertical">
            <span className={partners.fieldTitle}>Requested by</span>
            <InfinityScroll
              value={!_.isNull(partnerFields.requester) ? `${partnerFields.requester?.forename} ${partnerFields.requester?.surname}` : '-'}
              dropdownClassName={selects.selectDropdown}
              className={cx(selects.qsSelect, partners.partnerSelect, partners.requesterSelect, { [partners.requesterSelectDisabled]: !isEdit })}
              onObjectSelect={onRequesterChange}
              size="large"
              allowClear
              onClear={() => changeField('requester', null)}
              placeholder="Type to find an employee..."
              filterOption={() => true}
              disabled={!isEdit}
              requestSettingsWithSearchValue={(searchValue: string) => ({
                orderBy: 'fullname',
                active: true,
                fullnameContains: searchValue,
                usernameContains: searchValue,
                emailAddressContains: searchValue,
              })}
              getter={getAllUsers}
              renderItem={(user: BaseUserOutput) => (
                <Select.Option
                  key={user.userId}
                  value={user.userId}
                  name={user.forename}
                >
                  {`${user.forename} ${user.surname}`}
                </Select.Option>
              )}
            />
          </Space>
        </Col>
      </Row>

    </FieldCard>
  );
};

export default CreatedByCard;
