import { Column } from 'components/_shared/Table/helpers/types';

export const ENTITIES_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    sorter: true,
    width: 100,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    className: 'borderless',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    width: 30,
    className: 'action-column',
  },
];
