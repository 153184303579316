import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Radio } from 'antd';

import { FULL_MONTH_FORMAT } from 'helpers/constants/_common/timeFormats';

import { MonthListProps } from './helpers/type';
import S from './helpers/styles.module.sass';

const MonthList: React.FC<MonthListProps> = (props) => {
  const { selectedMonthIndex, onChangeMonthIndex } = props;

  const renderMonthsButtons = () => (
    <Radio.Group
      defaultValue={selectedMonthIndex}
      value={selectedMonthIndex}
      className={S.monthListInner}
      onChange={month => onChangeMonthIndex(month.target.value)}
    >
      {
        Array(12).fill(null).map((e, i) => {
          const month = dayjs().month(i).format(FULL_MONTH_FORMAT);
      
          return (
            <Radio
              key={`${month}`}
              className={cx(S.monthButton, {
                [S.active]: selectedMonthIndex === i,
              })}
              value={i}
            >
              <span>{month}</span>
            </Radio>
          );
        })
      }
    </Radio.Group>
  );
  return (
    <div className={cx(S.monthList)}>
      {renderMonthsButtons()}
    </div>
  );
};

export default MonthList;
