import React from 'react';
import _ from 'lodash';
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import { Button, Col, Row, Skeleton, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import buttons from 'helpers/styles/components/buttons.module.sass';

import { SkillSetProps, SkillSetState } from './helpers/types';
import {
  SkillCategoryEnum,
  UserSkill,
  UserSkillActions,
} from '../../../../helpers/types/Skills';
import SKILL_CATEGORIES, { DEFAULT_SKILL_CATEGORIES_ORDER } from '../../../../helpers/constants/Skills/Categories';
import EnglishKnowledge from './components/EnglishKnowledge';
import S from './helpers/styles.module.sass';
import SkillSetCategory from './components/SkillSetCategory';
import CustomDragLayer from './components/SkillSetCategory/components/CustomDragLayer';
import * as loginActions from '../../../../V1/actions/Login/LoginActions';
import { DispatchType, Redux } from '../../../../helpers/types/_common';
import * as skillSetActions from '../../../../redux/SkillSet/action';
import ConfirmSkillButton from './components/ConfirmSkillButton/ConfirmSkillButton';
import SkillDescription from './components/SkillDescription/SkillDescription';
import { AccessEnum } from 'components/Root/helpers/types';
import configureStore from 'redux/_reducer/configureStore';

const cx = classNames.bind(S);

class SkillSet extends React.Component<SkillSetProps, SkillSetState> {
  constructor(props: SkillSetProps) {
    super(props);

    this.state = {
      categoryToEdit: null,
      userSkills: this.props.skillset.usersSkillsTable.objects,
      reservedUsersSkills: this.props.skillset.usersSkillsTable.objects,
      addedCategories: [],
    };

    this.addUserSkill = this.addUserSkill.bind(this);
    this.changeEditedCategory = this.changeEditedCategory.bind(this);
    this.updateUserSkills = this.updateUserSkills.bind(this);
    this.removeUserSkill = this.removeUserSkill.bind(this);
  }

  componentDidMount(): void {
    if (this.props.user.userId) {
      this.initiate();
    }
  }

  componentDidUpdate(prevProps: SkillSetProps): void {
    if (!_.isEqual(prevProps.user.userId, this.props.user.userId)) {
      this.initiate();
    }

    if (
      this.props.skillset.usersSkillsTable.objects !== prevProps.skillset.usersSkillsTable.objects
      && this.props.skillset.usersSkillsTable.objects !== this.state.userSkills
    ) {
      const listOfAddedCategories = _.map(_.uniqBy(this.props.skillset.usersSkillsTable.objects, 'skill.category'), v => v.skill.category);
      const orderedCategories = _.intersection(DEFAULT_SKILL_CATEGORIES_ORDER, listOfAddedCategories);

      this.setState({
        userSkills: this.props.skillset.usersSkillsTable.objects,
        reservedUsersSkills: this.props.skillset.usersSkillsTable.objects,
        addedCategories: orderedCategories,
      });
    }
  }

  initiate = () => {
    this.props.skillSetActions.getUserSkillsByUserId(this.props.user.userId);
  };

  changeEditedCategory(newCategoryName: SkillCategoryEnum | null): void {
    this.setState({ categoryToEdit: newCategoryName });
  }

  restoreUserSkills = () => this.setState({ userSkills: this.state.reservedUsersSkills });

  removeUserSkill = (removedUserSkill: UserSkill, storeToReservedState?: boolean) => {
    const { userSkills, reservedUsersSkills } = this.state;
    const updatedUserSkills = update(userSkills, { $set: userSkills.filter(userSkill => userSkill !== removedUserSkill) });

    this.setState({ userSkills: updatedUserSkills, reservedUsersSkills: storeToReservedState ? updatedUserSkills : reservedUsersSkills });
  };

  addUserSkill = (newUserSkill: UserSkill, storeToReservedState?: boolean) => {
    const { userSkills, reservedUsersSkills } = this.state;

    const updatedUserSkills = _.map(userSkills, (value) => {
      if ((_.toLower(value.skill.name) === _.toLower(newUserSkill.skill.name))) {
        const isActionCreate = value.action === UserSkillActions.create;
        const assignedSkillAction = value.userSkillId ? UserSkillActions.update : newUserSkill.action;

        return {
          ...value,
          action: isActionCreate ? value.action : assignedSkillAction,
          experience: newUserSkill.experience,
        };
      }

      return value;
    });

    const isNewSkillExisted = _.some(userSkills, u => _.toLower(u.skill.name) === _.toLower(newUserSkill.skill.name));
    const completeUserSkills = isNewSkillExisted ? updatedUserSkills : [ ...userSkills, newUserSkill ];

    this.setState({ userSkills: completeUserSkills, reservedUsersSkills: storeToReservedState ? completeUserSkills : reservedUsersSkills });
  };

  updateUserSkills = (updatedUserSkill: UserSkill, storeToReservedState?: boolean) => {
    const { userSkills, reservedUsersSkills } = this.state;
    const skillToUpdate = userSkills.find(userSkill => userSkill.skill.name === updatedUserSkill.skill.name);

    if (!skillToUpdate) return;

    const index = userSkills.indexOf(skillToUpdate);
    const updatedUserSkills = update(userSkills, {
      [index]: { $set: updatedUserSkill },
    });

    this.setState({ userSkills: updatedUserSkills, reservedUsersSkills: storeToReservedState ? updatedUserSkills : reservedUsersSkills });
  };

  skillCategoryActionCallback = (category: SkillCategoryEnum) => {
    const { addedCategories } = this.state;

    this.setState({
      addedCategories: _.filter(addedCategories, c => c !== category),
    });
  };

  onAddNewCategory = (categoryKey: SkillCategoryEnum) => {
    this.setState(prevState => ({
      addedCategories: [ ...prevState.addedCategories, categoryKey ],
      categoryToEdit: categoryKey,
    }));
  };

  render(): React.ReactNode {
    const { skillset, user, setUserData } = this.props;
    const { userSkills, reservedUsersSkills, categoryToEdit, addedCategories } = this.state;

    const userSkillsIsChanged = !_.isEqual(userSkills, reservedUsersSkills);
    const categoriesWithoutAdded = _.omitBy(SKILL_CATEGORIES, (value, key) => _.includes(addedCategories, key));
    const isReadOnlyMode = this.props.curatorOnlyView || (this.props.editByAdmin && !_.includes(configureStore.getState().login.permissions, AccessEnum.Admin));

    return (
      <DndProvider backend={HTML5Backend}>
        <div className={cx('skillset_wrapper', { 'skillset_wrapper--isLoading': skillset.usersSkillsTable.isLoading })}>

          <ConfirmSkillButton
            isEdit={Boolean(categoryToEdit)}
            userSkills={userSkills}
            user={user}
            setUserData={setUserData}
            isReadOnlyMode={isReadOnlyMode}
            loading={skillset.usersSkillsTable.isLoading}
          />

          <CustomDragLayer />
          <div className={cx('skillset_skills-section')}>
            {
              _.map(addedCategories, skillCategoryName => (
                <SkillSetCategory
                  key={skillCategoryName}
                  user={this.props.user}
                  userSkills={userSkills.filter(userSkill => userSkill.skill.category === skillCategoryName)}
                  userSkillsAll={userSkills}
                  skillCategory={SKILL_CATEGORIES[skillCategoryName]}
                  editedCategory={categoryToEdit}
                  userSkillsIsChanged={userSkillsIsChanged}
                  removeUserSkill={this.removeUserSkill}
                  updateUserSkills={this.updateUserSkills}
                  addUserSkill={this.addUserSkill}
                  restoreUserSkills={this.restoreUserSkills}
                  changeEditedCategory={this.changeEditedCategory}
                  onCancelCallback={this.skillCategoryActionCallback}
                  addedCategories={addedCategories}
                  isReadOnlyMode={isReadOnlyMode}
                  loading={skillset.usersSkillsTable.isLoading}
                />
              ))
            }
          </div>
          <Skeleton loading={skillset.usersSkillsTable.isLoading} className={cx('skillset-categories_preloader')}>
            {
              addedCategories.length === _.keys(SKILL_CATEGORIES).length || isReadOnlyMode
                ? null
                : (
                  <Row className={cx('skillset-categories', { edited: Boolean(categoryToEdit) })}>
                    <Col className={cx('skillset-categories_title')} flex={106}>
                      <FontAwesomeIcon icon="layer-group" size="2x" />
                      <div className={cx('skillset-categories_text')}>More skill categories to&nbsp;add</div>
                    </Col>

                    <Col flex="auto" className={cx('skillset-categories_layout')}>
                      {
                        _.map(categoriesWithoutAdded, (category, categoryKey: SkillCategoryEnum) => (
                          <div key={category.categoryId} className={cx('skillset-categories_item')}>
                            <Space className={cx('skillset-categories_item-wrapper')}>
                              <span className={cx('skillset-categories_item-title')}>{category.title}</span>
                              { category.description && <SkillDescription category={category.key} /> }
                            </Space>

                            <Button
                              className={cx(buttons.qsButton, 'skillset-categories_add-button')}
                              onClick={() => this.onAddNewCategory(categoryKey)}
                            >
                              <Space size={6}>
                                <FontAwesomeIcon icon="plus-circle" />
                                Add skills
                              </Space>
                            </Button>
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                )
            }
          </Skeleton>

          <EnglishKnowledge
            user={this.props.user}
            loading={skillset.usersSkillsTable.isLoading}
            disabled={Boolean(categoryToEdit)}
            editByAdmin={this.props.editByAdmin}
            isReadOnlyMode={isReadOnlyMode}
          />
        </div>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state: Redux) => ({
  skillset: state.skillset,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  personalInfoActions: bindActionCreators(loginActions, dispatch),
  loginActions: bindActionCreators(loginActions, dispatch),
  skillSetActions: bindActionCreators(skillSetActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillSet);
