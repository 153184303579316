import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import selects from 'helpers/styles/components/selects.module.sass';
import OverdueBadge from 'components/_shared/OverdueBadge';

import s from './styles.module.sass';
import { PARTNERS_CREATED_SINCE } from './helpers/constants';

interface CreatedTimeSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const CreatedTimeSelect: React.FC<CreatedTimeSelectProps> = ({ value, onChange }) => {
  const overdueOptions = _.map(PARTNERS_CREATED_SINCE, (o, key) => (
    <Select.Option key={key} value={o.value}>
      <OverdueBadge color={o.badgeColor} ghosted={o.ghosted} />
      {o.text}
    </Select.Option>
  ));

  return (
    <Select
      className={classNames(selects.qsSelect, s.timeSelect, { [s.overdue]: true })}
      dropdownClassName={selects.selectDropdownWide}
      suffixIcon={<SelectIcon />}
      size="large"
      onChange={onChange}
      value={value}
      bordered={false}
      style={{ width: '180px' }}
      dropdownMatchSelectWidth={224}
      dropdownRender={menu => (
        <div>
          <span className={s.selectDescription}>Days since creation</span>
          {menu}
        </div>
      )}
    >
      {overdueOptions}
    </Select>
  );
};

export default CreatedTimeSelect;
