import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SkillElementProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import SkillAttitude from '../../../../../Attitude';
import { ReactComponent as AttitudePlaceholder } from '../../../../../../../../../../helpers/icons/Attitudes/AttitudePlaceholder.svg';
import AttitudeSelectPopover from '../../../../../AttitudesSelectPopover';

const cx = classNames.bind(S);

const SkillElement: React.FC<SkillElementProps> = (props) => {
  const {
    userSkill, color, skillCategoryName, disabled, userId,
    updateUserSkills, draggable = true, onClose, addedCategories, isReadOnlyMode,
  } = props;

  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const [ { isDragging }, drag, preview ] = useDrag({
    item: {
      type: skillCategoryName,
      userSkill,
      disabled,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const renderAttitudeWrapper = (element: React.ReactElement | null) => (isReadOnlyMode 
    ? element 
    : (
      <AttitudeSelectPopover
        userId={userId}
        visible={popoverVisible}
        userSkill={userSkill}
        updateUserSkills={updateUserSkills}
        onVisibleChange={setPopoverVisible}
      >
        {element}
      </AttitudeSelectPopover>
    ));

  const renderEmptyAttitude = () => (!isReadOnlyMode 
    ? (
      <FontAwesomeIcon icon="question-circle" className={S.attitudesPlaceholder} />
    ) 
    : null);

  const renderAttitude = () => (_.isEmpty(userSkill.attitude) 
    ? renderEmptyAttitude() 
    : (
      <div className={cx('skill-element_attitudes-icons')}>
        <SkillAttitude iconName={userSkill.attitude} />
      </div>
    ));

  return (
    <div
      ref={draggable ? drag : undefined}
      className={cx('skill-element_users-skill', {
        'skill-element_users-skill--is-loading': userSkill.isLoading,
        'skill-element_users-skill--is-draggable': draggable,
        'skill-element_users-skill--popover-visible': popoverVisible,
        'skill-element_users-skill--currently-in-use': userSkill.isLoading || userSkill.currentlyInUse,
        'skill-element_users-skill--errored': userSkill.errored,
        'skill-element_users-skill--is-dragging': isDragging,
        'skill-element_users-skill--disabled': disabled,
        'skill-element_users-skill--isReadOnlyMode': isReadOnlyMode,
        'skill-element_users-skill--empty': _.isEmpty(userSkill.attitude),
      })}
    >
      <div
        className={cx('skill-element_users-skill-inner', 'preloaded-gray-box')}
        style={{ backgroundColor: color }}
      >
        <span title={userSkill.skill.name} className={cx('skill-element_label')}>{userSkill.skill.name}</span>

        {renderAttitudeWrapper(renderAttitude())}

        {
          onClose
            ? <FontAwesomeIcon className={cx('skill_delete-icon')} icon="times" onClick={onClose} />
            : null
        }
      </div>
    </div>
  );
};

export default SkillElement;
