import { List } from 'antd';
import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import PartnerMask from 'components/_shared/PartnerMask';
import { Redux } from 'helpers/types/_common';
import { BaseActivityOutput, UserPartnerOutput } from 'api/Partners/types';

import S from './helpers/styles.module.sass';
import { ChosenActivityInfo } from '../../../../helpers/types';

const cx = classNames.bind(S);

interface ActivityListItemProps {
  activity: BaseActivityOutput;
  partnerObject: UserPartnerOutput;
  setPopoverVisible: (status: boolean) => void;
  handleSave: (activity: ChosenActivityInfo) => void;
}

const PartnersActivityListItem: React.FC<ActivityListItemProps> = ({ activity, handleSave, partnerObject, setPopoverVisible }) => {
  const { tableSettings } = useSelector((state: Redux) => state.timeSheet);

  const onClickHandler = () => {
    setPopoverVisible(false);
    handleSave({
      activityId: activity.activityId,
      partnerId: partnerObject.partner.partnerId,
      partnerName: partnerObject.partner.name,
      activityName: activity.name,
      mask: partnerObject.partner.mask,
      partnerColor: partnerObject.partner.color,
      activityColor: activity.color,
    });
  };

  return (
    <List.Item
      key={activity.activityId}
      className={cx(S.activityListItem, S.withPadding, {
        [S.monthClosedActivityItem]: tableSettings.isMonthClosed,
      })}
      title={`${partnerObject.partner.name}: ${activity.name}`}
      onClick={onClickHandler}
    >
      <List.Item.Meta
        className={S.activityListItemMeta}
        description={(
          <div className={S.activityListItemDescription}>
            <PartnerMask
              wrapperColor={partnerObject.partner.color}
              mask={partnerObject.partner.mask}
              wrapperClassName={S.activityIconWrapper}
              partnerId={partnerObject.partner.partnerId}
              iconColor={activity.color}
              isVacation={partnerObject.partner.isVacation}
            />
            <p className={S.descriptionTextWrapper}>
              <span className={S.descriptionText}>{`${partnerObject.partner.name}: ${activity.name}`}</span>
            </p>
          </div>
        )}
      />
    </List.Item>
  );
};

export default PartnersActivityListItem;
