import React, { Component } from 'react';
import DatePicker from '../../../../../_shared/DatePicker';
import Dropdown from '../../../../../_shared/Dropdown';
import Paginator from '../Paginator';
import S from './styles.module.css';
import Search from '../Search';
import Layout from '../../../../../_shared/Layout';
import Form from '../../../../../_shared/Form';

export default class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
  }

  changeStartDate(value) {
    this.props.changeStartDate(value);
  }

  changeEndDate(value) {
    this.props.changeEndDate(value);
  }

  changeFilter(e) {
    this.props.changeFilter(e.currentTarget.value);
  }

  rowCountSelect(eventKey) {
    const { startDate, endDate, sortBy, filter } = this.props;
    this.props.changeAmountOfRows({
      startDate,
      endDate,
      page: 1,
      limit: eventKey,
      sortBy,
      filter
    });
  }

  render() {
    const { startDate, endDate, sortBy, amountOfRows } = this.props;
    let requestData = { startDate, endDate, amountOfRows, sortBy };
    let amountOfRowsArray = [20, 50, 100];
    return (
      <>
        <Layout.Row>
          <Layout.Col>
            <Form.InputGroup>
              <Form.InputGroup.Addon>From</Form.InputGroup.Addon>
              <DatePicker
                date={this.props.startDate}
                onChange={this.changeStartDate}
              />
            </Form.InputGroup>
          </Layout.Col>
          <Layout.Col>
            <Form.InputGroup>
              <Form.InputGroup.Addon>To</Form.InputGroup.Addon>
              <DatePicker
                date={this.props.endDate}
                onChange={this.changeEndDate}
              />
            </Form.InputGroup>
          </Layout.Col>
          <Layout.Col>
            <Search
              data={requestData}
              filter={this.props.filter}
              changeFilter={this.props.changeFilter}
              findLogs={this.props.findLogs}
            />
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
          <Layout.Col>
            <div className={S.buttonGroup}>
              <div>
                <Paginator
                  changeActivePage={this.props.changeActivePage}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  amountOfRows={this.props.amountOfRows}
                  activePage={this.props.activePage}
                  totalAmount={this.props.totalAmount}
                  sortBy={this.props.sortBy}
                  filter={this.props.filter}
                  style={{ marginTop: '30px', marginRight: '10px' }}
                />
              </div>
              <div>
                <Dropdown
                  title={this.props.amountOfRows}
                  className={S.tableSizeSelector}
                >
                  {amountOfRowsArray.map(num =>
                    <div
                      key={num}
                      onClick={this.rowCountSelect.bind(this, num)}
                    >
                      {num}
                    </div>
                  )}
                </Dropdown>
              </div>
            </div>
          </Layout.Col>
        </Layout.Row>
      </>
    );
  }
}
