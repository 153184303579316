import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Modal, Select, Input, Checkbox } from 'antd';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import TextArea from 'antd/es/input/TextArea';

import modals from 'helpers/styles/components/modals.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import SKILL_CATEGORIES from 'helpers/constants/Skills/Categories';
import { ValidationError } from 'helpers/types/_common';
import { editSkillTag } from 'redux/SkillTags/actions';
import compareObjects from 'helpers/utils/compareObjects';

import S from './helpers/styles.module.sass';
import { EditSkillModalProps, DefaultModalValues } from './helpers/types';

const cx = classNames.bind(S);

const EditSkillModal: React.FC<EditSkillModalProps> = (props) => {
  const { visible, onCancel, onSuccess, defaultValues, title } = props;

  const [ values, setValues ] = useState<DefaultModalValues>({ approved: true });
  const [ skillId, setSkillId ] = useState<number>();
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState<ValidationError | null | undefined>(null);

  useEffect(() => {
    if (visible && !_.isEmpty(error)) {
      setError(null);
    }

    setValues({ approved: true });
    setSkillId(undefined);
  }, [ visible ]);

  useEffect(() => {
    if (defaultValues) {
      setValues({ ..._.omit(defaultValues, 'skillId') });
      setSkillId(defaultValues.skillId);
    }
  }, [ defaultValues ]);

  const renderSelect = () => {
    const options = _.map(SKILL_CATEGORIES, (s, key) => (
      <Select.Option key={s.categoryId} value={key}>{s.title}</Select.Option>
    ));

    const onChange = (category?: string) => {
      setValues({ ...values, category });
    };

    return (
      <Select
        value={values.category}
        placeholder="Select category..."
        suffixIcon={<SelectIcon />}
        className={cx(selects.qsSelect, 'add-new-modal_select')}
        bordered={false}
        size="large"
        dropdownClassName={selects.selectDropdown}
        onChange={onChange}
        allowClear
      >
        { options }
      </Select>
    );
  };

  const renderInputName = () => {
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.value;
      setValues({ ...values, name });
      setError(null);
    };

    const renderError = () => {
      if (!error) {
        return null;
      }

      return (
        <small className={cx('error-message')}>{error?.error || error}</small>
      );
    };

    return (
      <>
        <Input
          className={cx(inputs.qsInput, 'add-new-modal_input', { [inputs.error]: Boolean(error) })}
          size="large"
          placeholder="Enter name..."
          value={values.name}
          onChange={onChangeInput}
          autoFocus
          maxLength={31}
          allowClear
        />
        {renderError()}
      </>
    );
  };

  const renderDescriptionInput = () => {
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      setValues({ ...values, description: e.currentTarget.value });
      setError(null);
    };

    return (
      <TextArea
        className={cx(inputs.qsInput, 'add-new-modal_input')}
        size="large"
        placeholder="Enter description..."
        value={values.description}
        onChange={onChangeInput}
        maxLength={255}
        rows={3}
        allowClear
      />
    );
  };

  const renderCheckbox = () => {
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
      const approved = e.target.checked;
      setValues({ ...values, approved });
    };

    return (
      <Checkbox
        className={cx('add-new-modal_checkbox')}
        checked={values.approved}
        onChange={onChangeCheckbox}
      >
        Approved
      </Checkbox>
    );
  };

  const renderModalContent = () => (
    <div className={cx('add-new-modal_wrapper')}>
      { renderInputName() }
      { renderSelect() }
      { renderDescriptionInput() }
      { renderCheckbox() }
    </div>
  );

  const handleOnOk = () => {
    if (!values.name || !values.category) {
      return;
    }

    const body = defaultValues ? compareObjects(values, defaultValues) : values;

    if (!skillId) {
      return;
    }

    if (_.isEmpty(body)) {
      onCancel?.();
      return;
    }

    setLoading(true);
    editSkillTag(body, skillId)
      .then(() => {
        onSuccess();
      })
      .catch((errorObj) => {
        const { error } = errorObj?.response?.data || {};

        setError(error || errorObj?.response?.error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={title}
      className={modals.qsBasicAntdModal}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOnOk}
      confirmLoading={loading}
      centered
      cancelButtonProps={{ className: modals.modalCancelBtn, type: 'text' }}
      okButtonProps={{
        className: modals.modalOkBtn,
        size: 'large',
        disabled: !values.name || !values.category,
      }}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default EditSkillModal;
