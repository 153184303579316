import React, { Component } from 'react';
import className from 'classnames/bind';
import S from './styles.module.css';

const cx = className.bind(S);

class Paginator extends Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.startDate !== nextProps.startDate ||
            this.props.endDate !== nextProps.endDate ||
            this.props.amountOfRows !== nextProps.amountOfRows ||
            this.props.activePage !== nextProps.activePage ||
            this.props.totalAmount !== nextProps.totalAmount ||
            this.props.sortBy.up !== nextProps.sortBy.up ||
            this.props.sortBy.column !== nextProps.sortBy.column ||
            this.props.filter.by !== nextProps.filter.by ||
            this.props.filter.string !== nextProps.filter.string
    );
  }

  pageSelect(eventKey) {
    const { startDate, endDate, amountOfRows, sortBy, filter } = this.props;
    this.props.changeActivePage({
      startDate,
      endDate,
      page: eventKey,
      limit: amountOfRows,
      sortBy,
      filter
    });
  }

  render() {
    const { totalAmount, amountOfRows, activePage, style } = this.props;
    let pagesCount = Math.ceil(totalAmount / amountOfRows);
    const pagesArray = Array(pagesCount).fill(0).map((e, i) => i + 1);
    let items = [];

    if (pagesArray.length <= 9) {
      items = pagesArray.map(page => (
        <li
          className={cx({'pagination__item': true, 'pagination__item--active': activePage === page})}
          key={page}
        >
          <button
            className={cx({'pagination__link': true })}
            onClick={activePage === page ? () => {} : this.pageSelect.bind(this, page)}
          >{page}</button>
        </li>
      ));
    } else {
      items = pagesArray.map(page => {
        if ([1, activePage, activePage - 1, activePage + 1, pagesArray.length].includes(page)) {
          return (
            <li
              className={cx({'pagination__item': true, 'pagination__item--active': activePage === page})}
              key={page}
            >
              <button
                className={cx({'pagination__link': true })}
                onClick={activePage === page ? () => {} : this.pageSelect.bind(this, page)}
              >{page}</button>
            </li>
          )
        } else {
          if (page - 1 && [1, activePage, activePage - 1, activePage + 1, pagesArray.length].includes(page - 1)) {
            return (
              <li
                className={cx({'pagination__item': true, 'pagination__item--active': activePage === page})}
                key={page}
              >
                <button
                  disabled={true}
                  className={cx({'pagination__link': true })}
                  onClick={activePage === page ? () => {} : this.pageSelect.bind(this, page)}
                >...</button>
              </li>
            )
          } else {
            return null;
          }
        }
      });
    }

    if (pagesCount > 1) {
      return (
        <ul className={cx('pagination')}
          style={style}
        >
          {items}
        </ul>
      );
    } else {
      return null;
    }
  }
}

export default Paginator;
