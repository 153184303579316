import { Select, SelectProps, Spin } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { LabeledValue } from 'antd/lib/select';
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';
import useDebounce from 'helpers/hooks/useDebounce';

import s from './styles.module.sass';

interface InfinityScrollProps extends SelectProps {
  depsToCleaning?: any[];
  limit?: number;
  width?: number;
  onObjectSelect?: (value: string | number | LabeledValue, list: any[]) => void;
  defaultElementRender?: () => React.ReactNode;
  requestSettingsWithSearchValue: (searchValue: string) => { [key: string]: any };
  renderItem: (item: any) => React.ReactNode;
  getter: (settings: any) => Promise<any>;
}

interface GetterData {
  meta: {
    limit: number;
    offset: number;
    next?: string;
    previous?: string;
    totalCount?: number;
  },
  objects: any[],
}

const InfinityScroll: React.FC<InfinityScrollProps> = (props) => {
  const {
    getter,
    defaultElementRender,
    renderItem,
    requestSettingsWithSearchValue,
    depsToCleaning,
    limit,
    width,
    onSelect,
    onObjectSelect,
    suffixIcon,
    ...rest
  } = props;

  const defaultGetterData = {
    meta: {
      ...DEFAULT_SELECT_PAGINATION.meta,
      limit: limit || DEFAULT_SELECT_PAGINATION.meta.limit,
    },
    objects: [],
  };
  const [ getterData, setGetterData ] = useState<GetterData>(defaultGetterData);
  const [ searchValue, setSearchValue ] = useState<string>();
  const [ isLoading, setLoading ] = useState(false);
  
  const debounceSearchValue = useDebounce(searchValue, 600);
  
  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      getData(0);
    }
  }, [ debounceSearchValue ]);

  useEffect(() => {
    if (rest.value && getterData.objects.length === 0) {
      getData();
    }
  }, [ getterData, rest.value ]);

  useEffect(() => {
    if (_.isEmpty(depsToCleaning)) return;

    setGetterData(defaultGetterData);
    setSearchValue(undefined);
    setLoading(false);
  }, depsToCleaning);

  const getData = (offset?: number) => {
    const correctOffset = _.isNumber(offset) ? offset : getterData.meta.offset || 0;

    setLoading(true);
    getter({
      ...requestSettingsWithSearchValue(searchValue || ''),
      limit: getterData?.meta?.limit || DEFAULT_SELECT_PAGINATION.meta.limit,
      offset: correctOffset,
    })
      .then((options: GetterData) => setGetterData({
        meta: options.meta,
        objects: correctOffset ? [ 
          ...getterData.objects,
          ...options.objects,
        ] : options.objects,
      }))
      .finally(() => setLoading(false));
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin
      spinning={isLoading}
    >
      {menu}
    </Spin>
  );

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (getterData?.meta?.totalCount === getterData?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(getterData?.meta?.offset) ? getterData.meta.offset + DEFAULT_SELECT_PAGINATION.meta.limit : 0;

      getData(increasedPageByOne);
    }
  };

  const onSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const onFocus = () => {
    if (!_.isEmpty(getterData?.objects) || !_.isEmpty(searchValue)) {
      return;
    }

    getData(0);
  };

  const onItemSelect = (value: string | number | LabeledValue, option: any) => {
    if (onSelect) onSelect(value, option);

    if (onObjectSelect) onObjectSelect(value, getterData.objects);
  };

  const renderOptions = () => (
    _.map(getterData.objects, renderItem)
  );
  
  const componentProps = {
    suffixIcon: isLoading ? <LoadingOutlined className={s.spinner} spin /> : suffixIcon || <ArrowIcon />,
    dropdownAlign: { offset: [ 0, 7 ] },
    bordered: false,
    onFocus,
    dropdownRender,
    onPopupScroll,
    onSearch,
    searchValue,
    onSelect: onItemSelect,
    style: { width: `${width}px` },
    showSearch: true,
    showArrow: true,
    ...rest,
  };
  
  return (
    <Select {...componentProps} className={classNames(props.className, s.select)}>
      {defaultElementRender && defaultElementRender()}
      {renderOptions()}
    </Select>
  );
};

export default InfinityScroll;
