import { DispatchType } from '../../helpers/types/_common';
import * as TablesActions from '../../helpers/constants/_common/actions/Tables';
import Subtypes from '../../V1/constants/__common__/Subtypes';
import * as userRequests from '../../api/User/requests';
import { UserStatisticBody } from '../../api/User/types';

// eslint-disable-next-line import/prefer-default-export
export const getAllEmployees = (params: UserStatisticBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return userRequests.getUsersStatistics(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};
