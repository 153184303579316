import React from 'react';
import classNames from 'classnames/bind';

import S from './helpers/styles.module.sass';

const cx = classNames.bind(S);

interface OverdueBadgeProps {
  color?: string,
  ghosted?: boolean
}

const OverdueBadge: React.FC<OverdueBadgeProps> = ({ color, ghosted }) => {
  const badgeColor = ghosted ? '#fff' : (color || 'transparent');
  const badgeBorder = (ghosted && color) ? `1px solid ${color}` : 'none';

  return (
    <span
      className={cx('overdue-badge')}
      style={{ backgroundColor: badgeColor, border: badgeBorder }}
    />
  );
};

export default OverdueBadge;
