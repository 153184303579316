import _ from 'lodash';
import dayjs from 'dayjs';

import { UserStatisticBody } from './types';
import { EMPLOYEE_TABS } from './constants';
import { DEFAULT_DATE_FORMAT } from '../../helpers/constants/_common/timeFormats';

const ONLY_REAL_VALUES_LIST = [ 'hasPrimarySkill', 'hasSpecialistProfilesReadAccess' ]; // if this value is false -- it won't be sent to server

// eslint-disable-next-line import/prefer-default-export
export const convertUserStatisticsBody = (body: UserStatisticBody) => _.reduce(body, (acc, value, key) => {
  if (key === 'active') {
    // tabs changing cases over here
    switch (value) {
      case EMPLOYEE_TABS.ACTIVE.radioValue:
        return {
          active: true,
          exclude_usertype: EMPLOYEE_TABS.BOT.radioValue,
        };
      case EMPLOYEE_TABS.INACTIVE.radioValue:
        return {
          active: false,
          exclude_usertype: EMPLOYEE_TABS.BOT.radioValue,
        };
      case EMPLOYEE_TABS.INVITED.radioValue:
        return {
          active: true,
          filled_profile: false,
          exclude_usertype: EMPLOYEE_TABS.BOT.radioValue,
        };
      case EMPLOYEE_TABS.BOT.radioValue:
        return {
          filled_profile: false,
          usertype: EMPLOYEE_TABS.BOT.radioValue,
        };
      case EMPLOYEE_TABS.ADMINS.radioValue:
        return {
          active: true,
          usertype: 'ADMIN',
          exclude_usertype: EMPLOYEE_TABS.BOT.radioValue,
        };
    }
  }

  if (key === 'skillsetLastUpdate') {
    if (!value) {
      return acc;
    }

    const splitByTo = _.isString(value) ? _.split(value, 'to') : [];

    return {
      ...acc,
      skillset_last_update_lte: splitByTo[0] ? dayjs().subtract(Number(splitByTo[0]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
      skillset_last_update_gte: splitByTo[1] ? dayjs().subtract(Number(splitByTo[1]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
    };
  }

  if (key === 'timesheetLastUpdate') {
    if (!value) {
      return acc;
    }

    const splitByTo = _.isString(value) ? _.split(value, 'to') : [];

    return {
      ...acc,
      timesheet_last_update_lte: splitByTo[0] ? dayjs().subtract(Number(splitByTo[0]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
      timesheet_last_update_gte: splitByTo[1] ? dayjs().subtract(Number(splitByTo[1]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
    };
  }

  if ((_.isString(value) || _.isArray(value)) && _.isEmpty(value)) {
    return acc;
  }
  
  if (ONLY_REAL_VALUES_LIST.includes(key)) {
    return {
      ...acc,
      [_.snakeCase(key)]: value || null,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});
