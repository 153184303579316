import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { Redux } from 'helpers/types/_common';
import { PATH_PROFILE } from 'helpers/constants/_common/MenuCases';
import PROFILE_TABS from 'helpers/constants/_common/tabs/profile';
import ProfileViewField from 'components/_shared/ProfileViewField';

import popover from '../../../helpers/styles/components/popover.module.sass';
import s from '../helpers/styles.module.sass';
import SidebarCollapsedMenu, { PopoverVisibleFunction } from '../../Root/components/PagesSidebar/components/SidebarCollapsedMenu';
import { PagesSidebarProps } from '../../Root/components/PagesSidebar';
import { Typography } from 'antd';

const ProfileTabSidebar: React.FC<PagesSidebarProps> = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state: Redux) => state.login);

  const history = useHistory();

  const { tab: currentTab } = useParams<{ tab: string }>();

  useEffect(() => {
    const tabKeys = _.keys(PROFILE_TABS);

    if (!currentTab || !_.includes(tabKeys, currentTab)) {
      history.push(`${PATH_PROFILE}/${PROFILE_TABS.general.path}`);
    }
  }, [ currentTab, history ]);

  const popoverContent = (setPopoverVisible: PopoverVisibleFunction) => {
    const menuItems = _.map(PROFILE_TABS, tab => (
      <Link
        key={tab.name}
        to={`${PATH_PROFILE}/${tab.path}`}
        className={cx(popover.dotsPopoverItem, { [popover.dotsPopoverItemActive]: currentTab === tab.path })}
        onClick={() => setPopoverVisible(false)}
      >
        {tab.name}
      </Link>
    ));

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        { menuItems }
      </div>
    );
  };

  const renderMenuItems = () => _.map(PROFILE_TABS, tab => (
    <li
      key={tab.path}
      className={cx(s.sidebarListItem, { [s.activeSidebarList]: currentTab === tab.path })}
    >
      <Link
        to={`${PATH_PROFILE}/${tab.path}`}
        className={s.sidebarListItemLink}
      >
        {/*<Typography.Text>{tab.name}</Typography.Text>*/}
        {tab.name}
      </Link>
    </li>
  ));

  return (
    <div className={s.sidebarLayout}>
      <SidebarCollapsedMenu
        popoverContent={popoverContent}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        tabName={currentTab && PROFILE_TABS[currentTab]?.name}
      />

      <div className={cx(s.sidebarContentWrapper, { [s.collapsed]: collapsed })}>
        <div className={s.sidebarHeader}>
          <ProfileViewField user={user} />
        </div>
        <ul className={s.sidebarList}>
          {renderMenuItems()}
        </ul>
      </div>
    </div>
  );
};

export default ProfileTabSidebar;
