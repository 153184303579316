import React, { useState } from 'react';
import { Button, Modal, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { LoadingOutlined } from '@ant-design/icons';

import requestApi from 'api/Specialists/requests';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { SRStatuses } from 'api/Specialists/types';
import modals from 'helpers/styles/components/modals.module.sass';

import S from './helpers/styles.module.sass';
import { RequestsTable } from './helpers/types';

const cx = classNames.bind(S);

const RequestsTablePopover: React.FC<RequestsTable> = (props) => {
  const { specialistRequest, refreshTable, partnerName, activityName, handleOnEdit, onRow } = props;
  const [ editLoading, setEditLoading ] = useState(false);

  const renderPopoverContent = () => {
    const deleteContent = (
      <div>
        You are going to delete specialist request
        {' '}
        <b>{OCCUPATIONS[specialistRequest.occupation].name}</b>
        {' '}
        for
        {' '}
        <b>{partnerName}</b>
        :
        {' '}
        <b>{activityName}</b>
      </div>
    );

    const handleOnDeleteClick = () => {
      Modal.confirm({
        className: cx(modals.qsConfirmAntdModal, S.requestTableConfirmModal),
        title: 'Delete specialist request',
        content: deleteContent,
        cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
        okButtonProps: { className: modals.modalOkBtn, size: 'large' },
        okText: 'Delete',
        centered: true,
        autoFocusButton: null,
        maskClosable: true,
        icon: null,
        width: 500,
        onOk: () => {
          requestApi.deleteSpecialRequest(specialistRequest.specialistRequestId)
            .then(() => {
              refreshTable();
            });
        },
      });
    };

    const handleOnEditClick = () => {
      handleOnEdit(specialistRequest);
    };

    const toggleStatus = () => {
      const newStatus = specialistRequest.status === SRStatuses.Hiring ? SRStatuses.Staffed : SRStatuses.Hiring;
      requestApi.editSpecialRequest({ status: newStatus }, specialistRequest.specialistRequestId)
        .then(() => {
          refreshTable();
        })
        .finally(() => {
          setEditLoading(false);
        });
    };

    return (
      <div className={cx('requests-table-popover_popover-menu-content')}>
        <Button
          type="text"
          className={cx('requests-table-popover_popover-menu-content_item')}
          onClick={(e) => {
            e.stopPropagation();
            setEditLoading(true);
            toggleStatus();
          }}
        >
          {
            editLoading
              ? <LoadingOutlined className={cx('requests-table-popover_popover-menu-content_loading')} spin />
              : null
          }
          { specialistRequest.status === SRStatuses.Hiring ? 'Close' : 'Reopen' }
        </Button>

        <Button
          type="text"
          disabled={specialistRequest.status === SRStatuses.Staffed}
          className={cx('requests-table-popover_popover-menu-content_item')}
          onClick={(e) => {
            e.stopPropagation();
            handleOnEditClick();
          }}
        >
          Edit
        </Button>

        <Button
          type="text"
          className={cx('requests-table-popover_popover-menu-content_item')}
          onClick={() => onRow(specialistRequest).onClick()}
        >
          View details
        </Button>

        <Button
          type="text"
          className={cx('requests-table-popover_popover-menu-content_item')}
          onClick={(e) => {
            e.stopPropagation();
            handleOnDeleteClick();
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className={cx('requests-table-popover_emp-row')}>
        <Popover
          overlayClassName={cx('requests-table-popover_popover-menu')}
          placement="left"
          trigger="hover"
          content={renderPopoverContent()}
        >
          <Button type="text" size="small">
            <FontAwesomeIcon className={cx('emp-row_icon')} icon="ellipsis-v" />
          </Button>
        </Popover>
      </div>
    </>
  );
};

export default RequestsTablePopover;
