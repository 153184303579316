import React from 'react';
import { Button } from 'antd';

import { BaseActivityOutput } from 'api/Partners/types';
import PartnerMask from 'components/_shared/PartnerMask';

import S from './helpers/styles.module.sass';
import PartnerActivityListItemProps from './helpers/types';

const PartnerActivityListItem: React.FC<PartnerActivityListItemProps> = ({ partnerObject, handleSave, setPopoverVisible, activity }) => {
  const handleOnAddActivity = (activity: BaseActivityOutput) => {
    handleSave(activity.activityId);
    setPopoverVisible(false);
  };

  return (
    <Button
      onClick={() => handleOnAddActivity(activity)}
      className={S.partnerActivityListItem}
      key={activity.activityId}
    >
      <PartnerMask
        wrapperColor={partnerObject.partner.color}
        iconColor={activity.color}
        partnerId={partnerObject.partner.partnerId}
        wrapperClassName={S.partnerMaskWrapper}
        mask={partnerObject.partner.mask}
        isVacation={partnerObject.partner.isVacation}
      />
      <div className={S.partnerActivityDescription}>
        <span>{activity.name}</span>
      </div>
    </Button>
  );
};

export default PartnerActivityListItem;
