import { Checkbox, Popover, Select, Space, Divider, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';

import buttons from 'helpers/styles/components/buttons.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import { getAllUsers } from 'api/User/requests';
import { BaseUserOutput } from 'api/User/types';
import InfinityScroll from 'components/_shared/InfinityScroll';
import { addUsersToPartner } from 'api/Partners/requests';
import { PartnerDetailsOutput } from 'api/Partners/types';

import S from './styles.module.sass';

const cx = classNames.bind(S);

interface AddNewUsersButtonProps {
  partner: PartnerDetailsOutput;
  onSaveCallback: () => void;
}

const AddNewUserButton: React.FC<AddNewUsersButtonProps> = ({ partner, onSaveCallback }) => {
  const [ userId, setUserId ] = useState<number | undefined>();
  const [ isCurator, setCuratorStatus ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);

  const ref = React.useRef<any>(null);

  const renderError = () => {
    if (!error) {
      return null;
    }

    return (
      <small className={cx('error-message')}>{error}</small>
    );
  };

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      setUserId(undefined);
      setCuratorStatus(false);
      setError(null);
    }

    setPopoverVisible(visible);
  };

  const handleAddUser = (userId: string | number | LabeledValue) => {
    if (typeof userId !== 'number') return;

    setSaveLoading(true);
    addUsersToPartner(partner.partnerId, userId, { ...(isCurator ? { isCurator } : {}) })
      .then(onSaveCallback)
      .finally(() => setSaveLoading(false));
  };

  const handleSave = () => {
    if (_.isUndefined(userId)) return;

    handleAddUser(userId);
  };

  const content = (
    <Space
      direction="vertical"
      size={0}
    >
      <div className={S.popoverWrapper}>
        <InfinityScroll
          value={userId}
          style={{ flexGrow: 2 }}
          dropdownClassName={selects.selectDropdown}
          className={cx(selects.qsSelect)}
          onObjectSelect={(userId) => {
            setUserId(userId as number);
          }}
          size="large"
          placeholder="Type to find an employee..."
          filterOption={() => true}
          requestSettingsWithSearchValue={(searchValue: string) => ({
            orderBy: 'fullname',
            active: true,
            excludeUsertype: 'BOT',
            fullnameContains: searchValue,
            usernameContains: searchValue,
            emailAddressContains: searchValue,
          })}
          getter={getAllUsers}
          renderItem={(user: BaseUserOutput) => (
            <Select.Option
              key={user.userId}
              value={user.userId}
              name={user.forename}
            >
              {`${user.forename} ${user.surname}`}
            </Select.Option>
          )}
        />
        <div className={S.flexToEnd}>
          <Checkbox
            onChange={e => setCuratorStatus(e.target.checked)}
            checked={isCurator}
            className={S.curatorCheckbox}
          >
            Is curator
          </Checkbox>
        </div>
        {renderError()}
      </div>
      <Space
        direction="vertical"
        size={10}
        style={{ width: '100%' }}
      >
        <Divider />
        <div className={S.buttonWrapper}>
          <Button
            type="primary"
            id="new-participants-save-button"
            className={buttons.qsButtonPrimary}
            loading={saveLoading}
            onClick={handleSave}
            disabled={_.isUndefined(userId)}
          >
            Save
          </Button>
        </div>
      </Space>
    </Space>
  );

  return (
    <Popover
      ref={ref}
      align={{ offset: [ -1, 0 ] }}
      overlayClassName={S.addNewPopover}
      placement="bottomRight"
      content={content}
      trigger="click"
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    >
      <Button
        className={cx(buttons.qsButtonPrimary, { 'manage-tag-panel_button--popover-visible': popoverVisible })}
        type="primary"
        size="large"
      >
        <div className={buttons.iconTextWrapper}>
          <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
          <span>Add new</span>
        </div>
      </Button>
    </Popover>
  );
};

export default AddNewUserButton;
