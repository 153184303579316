import React, {Component} from 'react';
import Button from '../../../../_shared/Button';
import Dropdown from '../../../../_shared/Dropdown';
import Layout from '../../../../_shared/Layout';
import Form from '../../../../_shared/Form';
import classNames from 'classnames/bind';
import S from './styles.module.css';

const cx = classNames.bind(S);

class Search extends Component {
  constructor(props) {
    super(props);

    this.changeFilterBy = this.changeFilterBy.bind(this);
    this.changeFilterString = this.changeFilterString.bind(this);
    this.findLogs = this.findLogs.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.filter.by !== nextProps.filter.by ||
      this.props.filter.string !== nextProps.filter.string ||
      this.props.data !== nextProps.data
    );
  }

  changeFilterString(e) {
    const {filter, changeFilter} = this.props;
    changeFilter(e.currentTarget.value, filter.by);
  }

  changeFilterBy(eventKey) {
    const {filter, changeFilter} = this.props;
    if (eventKey !== 'Action') return;
    changeFilter(filter.string, eventKey);
  }

  findLogs(e) {
    e.preventDefault();

    const {findLogs, filter} = this.props;
    const {startDate, endDate, amountOfRows, sortBy} = this.props.data;

    let requestData = {
      startDate,
      endDate,
      limit: amountOfRows,
      page: 1,
      sortBy,
      filter
    };

    if (filter !== '') findLogs(requestData);
  }

  render() {
    const {filter} = this.props;
    let filterByParams = ['Doer', 'Subject', 'Action'];
    return (
      <Layout.Row>
        <Layout.Col grow>
          <Form.InputGroup>
            <Dropdown
              title={filter.by}
              partOfInputGroup
            >
              {filterByParams.map((name, i) =>
                <div
                  onClick={this.changeFilterBy.bind(this, name)}
                  className={cx({ 'qs-search_element--disabled': 'Action' !== name })}
                  title={'Action' !== name ? 'Temporarily unavailable' : ''}
                  key={i}
                >
                  {name}
                </div>
              )}
            </Dropdown>
            <Form.Control
              value={this.props.filter.string}
              onChange={this.changeFilterString}
              type='text'
              placeholder='Find...'
            />
          </Form.InputGroup>
        </Layout.Col>
        <Layout.Col>
          <Button
            style={{float: 'right'}}
            onClick={this.findLogs}
          >
            Find
          </Button>
        </Layout.Col>
      </Layout.Row>
    );
  }
}

export default Search;
