import { Modal } from 'antd';
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import modals from 'helpers/styles/components/modals.module.sass';
import SkillTag from 'components/_shared/SkillTag';

import s from './styles.module.sass';
import { SkillTagProps, SkillTagStatistic } from '../../../../../api/SkillTags/types';

export const convertSelectedRows = (selectedRows: SkillTagProps[]) => _.reduce(selectedRows, (acc, value) => ({
  ...acc,
  [value.skillId]: {
    name: value.name,
    skillId: value.skillId,
    checked: true,
  },
}), {});

export const SkillDeleteConfirmModal = (onOk: () => void, data: SkillTagProps[] | SkillTagProps | SkillTagStatistic) => {
  let skillTag;
  let totalPeopleUsing;
  let title = 'Delete skill tag';

  if (_.isArray(data)) {
    const selectedKeys = _.map(data, row => row.skillId);

    if (selectedKeys.length > 1) {     
      const skill = _.head(data);
      
      skillTag = <span className={classNames(s.drawerSkillTag, s.multiple)}>{`${data.length} skill tags`}</span>;
      totalPeopleUsing = skill?.employeesKnow || 0;
      title = 'Delete multiple skill tags';
    } else {
      const skill = _.head(data);
      
      skillTag = <SkillTag title={skill?.name} className={s.drawerSkillTag} />;
      totalPeopleUsing = _.reduce(data, (acc, value) => acc + value.employeesKnow, 0);
    }
  } else {
    skillTag = <SkillTag title={data.name} className={s.drawerSkillTag} />;
    totalPeopleUsing = data.employeesKnow;
  }

  const content = (
    <div>
      You are going to delete skill tag
      {skillTag}
      which is used by&nbsp;
      {totalPeopleUsing}
        &nbsp;employee(-s)
    </div>
  );
  
  Modal.confirm({
    className: classNames(modals.qsConfirmAntdModal, s.skillTagModal),
    title,
    content,
    cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
    okButtonProps: { className: modals.modalOkBtn, size: 'large' },
    okText: 'Delete anyway',
    centered: true,
    closable: true,
    autoFocusButton: null,
    maskClosable: true,
    icon: null,
    width: 500,
    onOk,
  });
};
