import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Redirect, useHistory } from 'react-router';
import dayjs from 'dayjs';

import { UserDetails } from 'api/User/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import useApi from 'api/hooks/useApi/useApi';
import { getReverseGeocoding } from 'api/DaData/requests';
import { DaDataGeolocation } from 'api/DaData/types';
import { PATH_TRACKER } from 'helpers/constants/_common/MenuCases';
import { testUserDataForFilling } from 'V1/actions/Login/LoginActions';
import { PersonalInfoFields, PersonalInfoResponseError, WelcomePageResponseError } from 'api/PersonalInfo/types';
import { savePersonalInfoFields } from 'redux/PersonalInfo/action';
import compareObjects from 'helpers/utils/compareObjects';

import WelcomeLocationPage from './components/WelcomeLocationPage';
import s from './helpers/styles.module.sass';
import { hasLocationPermissions } from './helpers/helpers';
import WelcomeProfileFillingPage from './components/WelcomeProfileFillingPage';

export type WelcomePageFields = Pick<UserDetails, 'forename' | 'occupation' | 'phone' | 'skypeId' | 'slackMemberId' | 'surname' | 'location' | 'timezoneUtcOffset'>;
export type Steps = 'locationConfirm' | 'profileFilling';

const WelcomeTrackerPage = () => {
  const userData = useSelector((state: Redux) => state.login);

  const [ getGeocodingLocation, geolocation ] = useApi<typeof getReverseGeocoding, DaDataGeolocation>(getReverseGeocoding);

  const [ errors, setErrors ] = useState<WelcomePageResponseError>({});
  const [ loading, setLoading ] = useState(false);
  const [ fields, setFields ] = useState<WelcomePageFields | undefined>();
  const [ currentPrefStep, setPrefStep ] = useState<Steps | null>(null);
  const [ browserGeolocationStatus, setBrowserGeolocationStatus ] = useState(false);

  const dispatch: DispatchType = useDispatch();
  const history = useHistory();

  const isNotificationFilled = useMemo(() => !_.values({
    notifyViaSlack: userData.preferences.notifyViaSlack,
    notifyViaEmail: userData.preferences.notifyViaEmail,
    notifyToCloseMonth: userData.preferences.notifyToCloseMonth,
    notifyToReportHours: userData.preferences.notifyToReportHours,
  }).includes(null), [ userData ]);
  
  useEffect(() => {
    const welcomeFields = _.pick(userData, [ 'forename', 'occupation', 'phone', 'skypeId', 'slackMemberId', 'surname', 'location', 'timezoneUtcOffset' ]);

    setFields({
      ...welcomeFields,
      timezoneUtcOffset: _.isNil(welcomeFields.timezoneUtcOffset) ? dayjs().utcOffset() : welcomeFields.timezoneUtcOffset,
    });

    /*
    Turn of LocationPage if location is not filled and access is granted or can be granted.
   */
    hasLocationPermissions()
      .then((isGeolocationTurnedOn) => {
        setBrowserGeolocationStatus(true);

        const { isUserDataEmpty } = testUserDataForFilling(userData);
        
        if (isGeolocationTurnedOn && _.isEmpty(welcomeFields.location)) {
          setPrefStep('locationConfirm');
        } else if (!isUserDataEmpty) {
          history.push(`${PATH_TRACKER}`);
        } else {
          setPrefStep('profileFilling');
        }
      });
  }, []);

  /*
    Settings geolocation and timezone
   */
  useEffect(() => {
    if (!geolocation || !fields) {
      return;
    }

    if (_.isEmpty(fields.location)) {
      const location = `${geolocation.country}, ${geolocation.city}`;

      setFields({
        ...fields,
        location,
      });
    }
  }, [ geolocation ]);

  const onChangeField = (value: string | number, field: keyof WelcomePageFields) => {
    if (errors[field]) {
      setErrors({ ..._.omit(errors, field) });
    }

    if (fields) {
      setFields({
        ...fields,
        [field]: value,
      });
    }
  };

  const onConfirm = (updatedFields: PersonalInfoFields) => {
    setLoading(true);

    dispatch(savePersonalInfoFields({ ...updatedFields }, userData.userId))
      .then(() => {
        history.push(`${PATH_TRACKER}`);
      })
      .catch((errorFields: PersonalInfoResponseError) => {
        setErrors(errorFields);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onLocationAccessGranted = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setPrefStep('profileFilling');

      getGeocodingLocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
    }, (err) => {
      setPrefStep('profileFilling');

      console.warn(`ERROR(${err.code}): ${err.message}`);
    });
  };

  if (userData.isFilledProfile && isNotificationFilled) {
    return <Redirect to={PATH_TRACKER} />;
  }

  const renderCurrentStep = () => {
    if (!browserGeolocationStatus && (!userData.isFilledProfile || !isNotificationFilled)) {
      return (
        <div className={s.welcomePage}>
          <div className={s.welcomePageWrapper} />
        </div>
      );
    }

    switch (true) {
      case currentPrefStep === 'locationConfirm':
        return (
          <div className={s.welcomePage}>
            <WelcomeLocationPage
              setPrefStep={setPrefStep}
              onLocationAccessGranted={onLocationAccessGranted}
            />
          </div>
        );
      case currentPrefStep === 'profileFilling':
        return (
          <div className={s.welcomePage}>
            <WelcomeProfileFillingPage
              loading={loading}
              onChangeField={onChangeField}
              fields={fields}
              errors={errors}
              onConfirm={() => onConfirm(compareObjects(fields, userData))}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return renderCurrentStep();
};

export default WelcomeTrackerPage;
