import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import classNames from 'classnames';

import { DispatchType, Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { getBookkeepingItems } from 'redux/BookkeepingItems/actions';
import useDebounce from 'helpers/hooks/useDebounce';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import inputs from 'helpers/styles/components/inputs.module.sass';

import s from './styles.module.sass';

const BookkeepingItemsSettings: React.FC<{}> = () => {
  const [ queryStr, setQueryStr ] = useState<string | undefined>();

  const dispatch: DispatchType = useDispatch();

  const { meta } = useSelector((state: Redux) => state.tables[TABLE_NAMES.BOOKKEEPING_ITEMS]);
  const debounceSearchValue = useDebounce(queryStr, 600);

  useEffect(() => {
    getGlobalSearchList();
  }, [ debounceSearchValue ]);

  const getGlobalSearchList = () => (
    dispatch(getBookkeepingItems({ ...meta, query: debounceSearchValue }, TABLE_NAMES.BOOKKEEPING_ITEMS))
  );
  
  const renderInputSearch = () => (
    <Input
      className={classNames(inputs.qsInput, s.bookkeepingItemsInput)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      size="large"
      type="number"
      value={queryStr}
      onChange={e => setQueryStr(e.currentTarget.value)}
      allowClear
      placeholder="Type identifier..."
    />
  );

  return (
    <div className={s.bookkeepingItemsSettings}>
      {renderInputSearch()}
    </div>
  );
};

export default BookkeepingItemsSettings;
