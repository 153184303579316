/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { Divider, message, Select, Spin } from 'antd';
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getSkillsByCategoryAndName } from 'redux/SkillSet/action';
import { DispatchType } from 'helpers/types/_common';
import SKILL_CATEGORIES from 'helpers/constants/Skills/Categories';
import useDebounce from 'helpers/hooks/useDebounce';
import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';
import SkillTag from 'components/_shared/SkillTag';

import S from './helpers/styles.module.sass';
import { CategoryOptionsObject, SearcherProps, SelectOption, SelectValue } from './helpers/types';

const cx = classNames.bind(S);

const SkillSearcher: React.FC<SearcherProps> = (props) => {
  const { visible, skillCategoryName, skillExperience, onAddExisted, userSkillList, onDeselect, onAddNew, userSkillsAll } = props;

  const dispatch: DispatchType = useDispatch();

  const [ loading, setLoading ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>();

  const [ optionsWithPagination, setOptionsWithPagination ] = useState<CategoryOptionsObject>(DEFAULT_SELECT_PAGINATION);

  const debounceSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      getCategory(debounceSearchValue);
    }
  }, [ debounceSearchValue ]);

  if (!visible) {
    return null;
  }

  const getCategory = (value?: string) => {
    setLoading(true);
    dispatch(getSkillsByCategoryAndName(value || '', skillCategoryName))
      .then((options: CategoryOptionsObject) => setOptionsWithPagination(options))
      .finally(() => setLoading(false));
  };

  const handleOnFocus = () => {
    if (!_.isEmpty(optionsWithPagination?.objects)) {
      return;
    }

    getCategory();
  };

  const options = _.map(optionsWithPagination.objects, (skill) => {
    const userSkill = _.find(userSkillList, userSkill => userSkill.skill.skillId === skill.skillId);

    return (
      <Select.Option
        key={skill.skillId || skill.name}
        value={skill.name}
        skill={skill}
        tag={userSkill}
      >
        {skill.name}
      </Select.Option>
    );
  });

  const dropdownRender = (menu: React.ReactElement) => {
    const renderAddNewItem = () => {
      const isNewItemInOptions = _.some(optionsWithPagination.objects, o => o.name === searchValue);
      const divider = _.isEmpty(optionsWithPagination.objects) ? null : <Divider style={{ margin: '2px 0' }} />;

      if (!searchValue || isNewItemInOptions) {
        return null;
      }

      return (
        <>
          <div
            onMouseDown={() => handleOnSelect({ value: searchValue, label: searchValue }, {})}
            className={cx('add-new-skill')}
          >
            <span>{searchValue}</span>
            <FontAwesomeIcon icon="plus" className={cx('add-new-skill_icon')} />
          </div>
          {divider}
        </>
      );
    };

    return (
      <Spin
        spinning={loading}
        size="small"
      >
        {renderAddNewItem()}
        {menu}
      </Spin>
    );
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (optionsWithPagination?.meta?.totalCount === optionsWithPagination?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(optionsWithPagination?.meta?.offset)
        ? optionsWithPagination.meta.offset + DEFAULT_SELECT_PAGINATION.meta.limit
        : 0;

      setLoading(true);
      dispatch(getSkillsByCategoryAndName('', skillCategoryName, increasedPageByOne))
        .then((options: CategoryOptionsObject) => {
          setOptionsWithPagination({
            meta: options.meta,
            objects: [ ...optionsWithPagination.objects, ...options.objects ],
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleOnSelect = (value: SelectValue, option: SelectOption) => {
    const existedSkill = _.find(userSkillsAll, v => _.toLower(v.skill.name) === _.toLower(value.label));

    if (existedSkill && existedSkill.skill?.category !== skillCategoryName) {
      message.warning({
        content: (
          <span>
            <b>{existedSkill.skill.name}</b>
            {' already exists in '}
            <b>{SKILL_CATEGORIES[existedSkill.skill?.category].title}</b>
          </span>
        ),
        duration: 5,
      });
      return;
    }

    if (_.isEmpty(option)) {
      onAddNew(value.value);

      setSearchValue(undefined);
      getCategory();
      return;
    }

    if (option?.tag?.userSkillId || option?.skill) {
      onAddExisted(option.skill);
    }
  };

  const addNewSkill = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (_.isUndefined(searchValue)) return;

    if (e.keyCode === 13) {
      onAddNew(searchValue);
    
      setSearchValue(undefined);
      getCategory();
    }
  };

  const handleOnDeselect = (value: SelectValue) => {
    const userSkill = _.find(userSkillList, userSkill => userSkill.skill.name === value.value);

    if (userSkill) {
      onDeselect(userSkill);
    }
  };

  const tagRender = (props: any) => (
    <SkillTag
      className={cx('skillset_skill-tag')}
      title={props.value}
      color={skillExperience.color}
      onClose={props.onClose}
    />
  );

  const selectValue = _.map(userSkillList, u => ({ value: u.skill.name, label: u.skill.name }));

  return (
    <Select
      dropdownAlign={{ offset: [ 0, 13 ] }}
      mode="tags"
      placeholder="Add skill tag..."
      className={cx('skillset_skill-list')}
      labelInValue
      onFocus={handleOnFocus}
      bordered={false}
      value={selectValue}
      onSearch={(value: string) => {
        if (value?.length >= 31) {
          return;
        }

        setSearchValue(value);
      }}
      onKeyUp={addNewSkill}
      searchValue={searchValue}
      dropdownClassName={cx('skill-searcher_dropdown')}
      onSelect={handleOnSelect}
      onDeselect={handleOnDeselect}
      loading={loading}
      onPopupScroll={onPopupScroll}
      showArrow
      maxLength={31}
      suffixIcon={_.isEmpty(selectValue) ? undefined : <FontAwesomeIcon icon="plus" />}
      filterOption={() => true}
      tagRender={tagRender}
      dropdownRender={dropdownRender}
    >
      {options}
    </Select>
  );
};

export default SkillSearcher;
