import _ from 'lodash';
import React from 'react';

import { AttitudesTextField } from '../../../api/Specialists/types';
import { Column } from '../../_shared/Table/helpers/types';
import AttitudesCell from '../components/AttitudesCell';
import { SPECIALISTS_COMMON_COLUMNS, SPECIALISTS_READ_ONLY_COLUMNS } from './constants';
import { SpecialistsSkills } from './types';

export const parseSkillsByType = (skills?: SpecialistsSkills) => _.reduce(skills, (acc, value, key) => ({
  ...acc,
  [value.experience]: acc[value.experience] ? [ ...acc[value.experience], key ] : [ key ],
}), {} as any);

export const updateColumns = (isProfilesViewerMode: boolean, skills?: SpecialistsSkills) => {
  const correctColumnsSource = isProfilesViewerMode ? SPECIALISTS_READ_ONLY_COLUMNS : SPECIALISTS_COMMON_COLUMNS;
  const updateColumnsToFixed: Column[] = _.isEmpty(skills) ? [ ...correctColumnsSource ] : _.map(correctColumnsSource, (c) => {
    if (_.isEmpty(skills)) {
      return c;
    }

    return ({ ...c, fixed: 'left' });
  });

  const columns = _.reduce(skills, (acc, v, key): Column[] => [
    ...acc,
    {
      title: v.name,
      dataIndex: key,
      key,
      sorter: true,
      width: 140,
      className: 'attitudes-column',
    },
  ], [ ...updateColumnsToFixed ]);

  // englishKnowledge column should be at the end of the rest
  return [
    ...columns,
    {
      title: 'English',
      dataIndex: 'englishKnowledge',
      key: 'englishKnowledge',
      width: 140,
      className: _.isEmpty(skills) ? 'attitudes-column' : '',
    },
  ];
};

export const attitudesColumns = (skills: SpecialistsSkills, onCell?: any) => {
  const skillsKeys = _.keys(skills);

  return _.reduce(skillsKeys, (acc, value) => ({
    ...acc,
    [value]: {
      render: (text: AttitudesTextField) => <AttitudesCell text={text} />,
      onCell: onCell || _.noop,
    },
  }), {});
};
