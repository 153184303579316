import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import classNames from 'classnames/bind';
import S from './styles.module.css';

const cx = classNames.bind(S);

interface DatePickerInnerComponentProps {
  date: string;
  show: boolean;
  handleChange: (formattedDate: string) => void;
  handlePreChange: (formattedDate: string) => void;
  handleClose: () => void;
  top?: boolean;
}

interface DatePickerInnerComponentState {
  currentMoment: Moment;
  leftOffset: number;
}

interface DatePickerCellButtonProps {
  previous?: boolean;
  next?: boolean;
  onClick: () => void;
  children?: React.ReactChildren | React.ReactText;
}

const DatePickerCellButton: React.FunctionComponent<DatePickerCellButtonProps> = (
  props: DatePickerCellButtonProps
) => {
  const isControlButton = props.previous || props.next;
  const controlButtonIcon = props.previous
    ? 'chevron-left'
    : props.next
      ? 'chevron-right'
      : 'times';

  return (
    <button
      className={cx('button', {
        'button--control-button': isControlButton
      })}
      onClick={props.onClick}
    >
      <span className={cx('button__main-element')}>
        {isControlButton ? (
          <FontAwesomeIcon icon={controlButtonIcon} />
        ) : (
          props.children
        )}
      </span>
    </button>
  );
};

export default class DatePickerInner extends React.Component<
  DatePickerInnerComponentProps,
  DatePickerInnerComponentState
  > {
  private parent = React.createRef<HTMLDivElement>();

  constructor(props: DatePickerInnerComponentProps) {
    super(props);

    this.state = {
      currentMoment: moment(this.props.date),
      leftOffset: 0
    };

    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<DatePickerInnerComponentProps>): void {
    if (prevProps.show !== this.props.show && this.props.show) {
      document.addEventListener('mousedown', this.onClickOutside);
      this.calcPosition();
    }

    if (prevProps.date !== this.props.date) {
      this.setState({ currentMoment: moment(this.props.date) });
    }
  }

  calcPosition(): void {
    const parent = this.parent.current;

    if (!parent) return;

    const { left, width } = parent.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    this.setState({
      leftOffset:
        left < 0
          ? -left
          : left + width > windowWidth
          ? windowWidth - (width + left)
          : 0
    });
  }

  onDayClick(date: number): void {
    this.setState({ currentMoment: this.state.currentMoment.date(date) });
    this.props.handleChange(this.state.currentMoment.format('YYYY-MM-DD'));
    this.props.handleClose();
  }

  onClickOutside(e: Event): void {
    const parent = this.parent.current;

    if (parent && !parent.contains(e.target as HTMLElement)) {
      this.handleClose();
    }
  }

  handleClose(): void {
    this.setState({ currentMoment: moment(this.props.date) });
    document.removeEventListener('mousedown', this.onClickOutside);
    this.props.handleClose();
  }

  previousMonth(): void {
    this.setState({
      currentMoment: this.state.currentMoment.subtract(1, 'month')
    });
    this.props.handlePreChange(this.state.currentMoment.format('YYYY-MM-DD'));
  }

  nextMonth(): void {
    this.setState({
      currentMoment: this.state.currentMoment.add(1, 'month')
    });
    this.props.handlePreChange(this.state.currentMoment.format('YYYY-MM-DD'));
  }

  renderMonth(): React.ReactNode {
    const { currentMoment } = this.state;
    const daysBeforeMonth = this.getDayOfMonthByDate(1) - 1;
    const daysInMonth = currentMoment.daysInMonth();
    const daysArray = [
      ..._.range(daysBeforeMonth * -1 + 1, 1),
      ..._.range(1, daysInMonth + 1),
      ..._.range(
        daysInMonth + 1,
        daysInMonth + 8 - this.getDayOfMonthByDate(daysInMonth)
      )
    ];
    const weekDays: Array<string> = _.range(1, 8).map((day: number) =>
      moment()
        .day(day)
        .format('ddd')
    );

    return (
      <div className={cx('date-picker__month')}>
        <div className={cx('date-picker__month-head')}>
          {weekDays.map((day: string, i: number) => (
            <div key={i} className={cx('date-picker__month-day')}>
              {day}
            </div>
          ))}
        </div>
        <div className={cx('date-picker__month-body')}>
          {daysArray.map(day => this.renderDay(day))}
        </div>
      </div>
    );
  }

  renderDay(date: number): React.ReactNode {
    const { currentMoment } = this.state;
    const today = moment(currentMoment)
      .date(date)
      .isSame(moment(), 'day');
    const chosen = parseInt(currentMoment.format('D')) === date;
    const weekend =
      parseInt(
        moment(currentMoment)
          .date(date)
          .format('E')
      ) >= 6;
    const classes = cx('date-picker__month-day', {
      'date-picker__month-day--today': today,
      'date-picker__month-day--chosen': chosen,
      'date-picker__month-day--week-end': weekend,
      'date-picker__month-day--prev-month': date <= 0,
      'date-picker__month-day--next-month': date > currentMoment.daysInMonth()
    });

    return (
      <div className={classes} key={date}>
        <DatePickerCellButton onClick={this.onDayClick.bind(this, date)}>
          {moment(this.props.date)
            .date(date)
            .format('D')}
        </DatePickerCellButton>
      </div>
    );
  }

  renderSettings(): React.ReactNode {
    const { currentMoment } = this.state;
    const year = currentMoment.format('YYYY');
    const month = currentMoment.format('MMMM');

    return (
      <div className={cx('date-picker__settings')}>
        <DatePickerCellButton
          previous={true}
          onClick={this.previousMonth.bind(this)}
        />
        <h4 className={cx('date-picker__settings--title')}>
          {month} {year}
        </h4>
        <DatePickerCellButton next={true} onClick={this.nextMonth.bind(this)} />
      </div>
    );
  }

  getDayOfMonthByDate(date: number): number {
    return parseInt(
      moment(this.state.currentMoment)
        .date(date)
        .format('E')
    );
  }

  render(): React.ReactNode {
    const classes = cx('date-picker', {
      'date-picker--showed': this.props.show,
      'date-picker--top': this.props.top
    });
    const { leftOffset } = this.state;

    return (
      <div
        className={classes}
        style={{ left: `calc(50% + ${leftOffset}px)` }}
        ref={this.parent}
      >
        {this.renderSettings()}
        {this.renderMonth()}
      </div>
    );
  }
}
