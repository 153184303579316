import { Dayjs } from 'dayjs';

import { FlatUserHours } from 'components/TimeSheet/components/Table/components/TableBody/helpers/types';
import { SelectedHour } from 'components/TimeSheet/helpers/types';

import { UserPartnerOutput, UserPinnedActivityOutput } from '../../api/Partners/types';

export interface TimeSheetRedux {
  tableSettings: TimeSheetTableSettingsProps;
  deleteUserHoursModal: DeleteUserHoursModalProps;
  flatUserHoursTable: FlatUserHours;
  selection: TimeSheetSelectionProps;
  contextMenus: TimeSheetContextMenusProps;
  replaceDrawer: HoursDrawerProps;
  selectedActivity?: UserPinnedActivityOutput;
  activities: UserPinnedActivityOutput[];
  partners: UserPartnerOutput[];
}

export interface DeleteUserHoursModalData {
  startCellIndex: number;
  endCellIndex: number;
  selectionDay: string | null;
}

export interface DeleteUserHoursModalProps extends DeleteUserHoursModalData {
  isVisible: boolean;
}

export interface SetHoursDrawerProps {
  isVisible?: boolean;
  isLoading?: boolean;
  mode?: HoursDrawerMode;
  data?: ReplaceDrawerData;
}

export interface HoursDrawerProps {
  isVisible: boolean;
  isLoading: boolean;
  mode: HoursDrawerMode;
  data: ReplaceDrawerData;
}

export enum HoursDrawerMode {
  Replace = 'REPLACE',
  ReplaceAndFill = 'REPLACE_AND_FILL',
  Add = 'ADD',
}

export interface ReplaceDrawerData {
  selectedHours: SelectedHour[],
  startCellIndex: number,
  endCellIndex: number,
  selectionDay: string | null,
}

export enum SelectionActionsEnum {
  Start = 'START',
  Continue = 'CONTINUE',
  End = 'END',
}

export interface TimeSheetSelectionProps {
  isShown: boolean;
  isLoading: boolean;
  selectedCells: FlatUserHours;
}

export interface TimeSheetContextMenusProps {
  [ContextMenuType.Group]: GroupContextMenuProps,
  [ContextMenuType.Selection]: SelectionContextMenuProps
}

export interface GroupContextMenuProps {
  isVisible: false,
  cellIndex: number,
  day: string | null,
}

export interface SelectionContextMenuProps {
  isVisible: false,
  selectedHours: SelectedHour[];
}

export interface TimeSheetTableSettingsProps {
  isMonthClosed: boolean;
  isCtrlPressed: boolean;
  selectedDateObj: Dayjs;
}

export enum ContextMenuType {
  Group = 'GROUP',
  Selection = 'SELECTION',
}
