import { Radio } from 'antd';
import React from 'react';
import cx from 'classnames';

import radio from 'helpers/styles/components/radio.module.sass';
import { PARTICIPANTS_TABS } from 'api/User/constants';

import s from './helpers/styles.module.sass';
import { ParticipantsFiltersState } from '../../helpers/types';

interface ParticipantsRadioProps {
  filters: ParticipantsFiltersState;
  onChangeFilters: (value: ParticipantsFiltersState) => void;
}

const ParticipantsRadio: React.FC<ParticipantsRadioProps> = ({ filters, onChangeFilters }) => {
  const handleOnChange = (value: string) => {
    onChangeFilters({
      ...filters,
      activeTab: value,
    });
  };

  return (
    <div className={cx(radio.tableRadio, s.employeesRadio)}>
      <Radio.Group
        value={filters.activeTab}
        className={s.radioWrapper}
        onChange={e => handleOnChange(e.target.value)}
      >
        <Radio
          className={radio.radioItem}
          value={PARTICIPANTS_TABS.ALL_MEMBERS.radioValue}
        >
          All members
        </Radio>
        <Radio
          className={radio.radioItem}
          value={PARTICIPANTS_TABS.CURATORS.radioValue}
        >
          Curators
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default ParticipantsRadio;
