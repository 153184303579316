/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import React from 'react';
import { SortOrder } from 'antd/lib/table/interface';

import TableSortIcon from '../components/TableSortIcon/TableSortIcon';
import { RenderColumnsObject, Column } from './types';
import { Sorter } from '../../../../api/_request/types';
import { SORT_DIRECTIONS } from './sorter';

export const updateColumnsByFields = (columns: Column[], renderColumns: RenderColumnsObject, sorter: Sorter, sortDirections?: SortOrder[]) => (
  _.map(columns, (c) => {
    const renamedOrderDirection = sorter.orderDirection === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.ascend : SORT_DIRECTIONS.descend;
    const sortValue = (c.dataIndex === _.camelCase(sorter?.orderBy)) ? { sortOrder: sorter.orderDirection ? renamedOrderDirection : undefined } : {};
    const sortObject = c.sorter ? sortValue : {};
    
    if (renderColumns[c.dataIndex]) {
      if (_.isObject(renderColumns[c.dataIndex])) {
        return {
          ...c,
          ...sortObject,
          title: () => <TableSortIcon sortOrder={sortObject.sortOrder} column={c} sortDirections={sortDirections} />,
          ...renderColumns[c.dataIndex],
        };
      }
  
      return {
        ...c,
        ...sortObject,
        title: () => <TableSortIcon sortOrder={sortObject.sortOrder} column={c} sortDirections={sortDirections} />,
        render: renderColumns[c.dataIndex],
      };
    }
  
    if (renderColumns.default) {
      return {
        ...c,
        ...sortObject,
        title: () => <TableSortIcon sortOrder={sortObject.sortOrder} column={c} sortDirections={sortDirections} />,
        render: renderColumns.default,
      };
    }
  
    return {
      ...c,
      ...sortObject,
    };
  })
);
