import React, { Key, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import BasicTable from 'components/_shared/Table';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { Redux } from 'helpers/types/_common';
import useApi from 'api/hooks/useApi/useApi';
import { Meta, Sorter } from 'api/_request/types';
import { UserRedux } from 'redux/User/types';
import { getCuratorReportData } from 'api/Curators/requests';
import { CuratorPartnerDetailedReports, FrontActivityUserReports, FrontCuratorPartnerDetailedReport, FrontUserReport } from 'api/Curators/types';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import PartnerMask from 'components/_shared/PartnerMask';
import { ReactComponent as ChevronRight } from 'helpers/icons/Buttons/chevronRight.svg';
import Deactivated from 'components/_shared/Deactivated/Deactivated';

import { CURATOR_HOUR_REPORT_COLUMNS, MOBILE_CURATOR_REPORT_COLUMNS } from '../../helpers/constants';
import { CuratorHourSettings } from '../../helpers/types';
import s from './styles.module.sass';
import { PATH_ADMINISTRATION, PATH_REPORTS, TAB_CURATORS_TIMESHEET } from '../../../../helpers/constants/_common/MenuCases';
import { EDIT_CURATOR_TABS } from '../../../../helpers/constants/_common/tabs/curator';

interface CuratorReportTableProps {
  user: UserRedux;
  setSettingsValues: React.Dispatch<React.SetStateAction<CuratorHourSettings>>;
  settingsValues: CuratorHourSettings;
}

const CuratorReportTable: React.FC<CuratorReportTableProps> = ({ user, settingsValues }) => {
  const { login } = useSelector((state: Redux) => state);
  const { sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.CURATOR_HOUR_REPORT]);

  const [ expandedRowKeys, setExpandedRowKeys ] = useState<readonly Key[]>();

  const [ getCuratorData, curatorData, loading ] = useApi<typeof getCuratorReportData, CuratorPartnerDetailedReports>(getCuratorReportData);

  const getCuratorReport = (pagination: Meta | false, sorter?: Sorter) => {
    // if no dates provided - not call request (for the first time)
    if (!settingsValues.dates.startDateRange || !settingsValues.dates.endDateRange || !settingsValues.dates) {
      return;
    }

    const correctSorter = sorter?.orderBy === 'hours' ? { ...sorter, orderBy: 'partner_hours' } : sorter;

    getCuratorData(
      user.userId,
      [ settingsValues.dates.startDateRange, settingsValues.dates.endDateRange ],
      { ...correctSorter, partnerId: settingsValues.partnerId, searchValue: settingsValues.searchValue },
    );
  };

  useEffect(() => {
    getCuratorReport(false, sorter);
  }, [ settingsValues.dates.startDateRange, settingsValues.dates.endDateRange, settingsValues.partnerId, settingsValues.searchValue ]);

  const desktopRenderColumns = {
    partnerName: {
      render: (text: undefined, record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => {
        if ('activity' in record) {
          return null;
        }

        if ('partner' in record) {
          return (
            <Space>
              <PartnerMask
                wrapperColor={record.partner.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record.children[0].activity.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.partner.name}</span>
            </Space>
          );
        }

        return null;
      },
    },

    activity: {
      render: (text: undefined, record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => {
        if (('children' in record) && !('activity' in record)) {
          const activitiesTitle = (record?.children?.length > 1) ? 'activities' : 'activity';

          return (
            <Space>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, record?.partner?.partnerId) })} />
              <span>{`${record?.children?.length} ${activitiesTitle}`}</span>
            </Space>
          );
        }

        if ('user' in record) {
          return (
            <Space size={0} className={s.expandedRowUser}>
              <UserAvatar avatarUrl={record.user.avatarUrl} />
              <Link
                title="Show timesheet"
                to={{ pathname: `${PATH_ADMINISTRATION}/${TAB_CURATORS_TIMESHEET}/${record.user.userId}/${EDIT_CURATOR_TABS.timesheet.path}`, state: { from: PATH_REPORTS } }}
                className={s.viewUserTimeSheet}
              >
                <Space size={36}>
                  <span>{`${record.user.forename} ${record.user.surname}`}</span>
                  { !record.user.active && <div className={s.deactivatedTag}>Deactivated</div> }
                </Space>
              </Link>

            </Space>
          );
        }

        if ('activity' in record) {
          return (
            <Space className={s.expandedRowActivity}>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, record?.partner?.partnerId) })} />
              <PartnerMask
                wrapperColor={record?.partner?.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record?.activity?.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.activity.name}</span>
            </Space>
          );
        }
      },
    },

    hours: {
      render: (text: undefined, record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => <span>{`${record?.hours}h`}</span>,
    },
  };

  const mobileRenderColumns = {
    partnerName: {
      render: (text: string, record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => {
        if (('children' in record) && !('activity' in record)) {
          return (
            <div className={s.flexRow}>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, record?.partner?.partnerId) })} />
              <PartnerMask
                wrapperColor={record.partner.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record.children[0].activity.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.partner.name}</span>
              <Deactivated isHidden={record.partner.active} />
            </div>
          );
        }

        if ('user' in record) {
          return (
            <div className={cx(s.mobileExpandedRowUser, s.flexRow)}>
              <UserAvatar avatarUrl={record.user.avatarUrl} />
              <Link
                title="Show timesheet"
                to={{ pathname: `${PATH_ADMINISTRATION}/${TAB_CURATORS_TIMESHEET}/${record.user.userId}/${EDIT_CURATOR_TABS.timesheet.path}`, state: { from: PATH_REPORTS } }}
                className={s.viewUserTimeSheet}
              >
                <Space size={36}>
                  <span>{`${record.user.forename} ${record.user.surname}`}</span>
                  { !record.user.active && <div className={s.deactivatedTag}>Deactivated</div> }
                </Space>
              </Link>
              <Deactivated isHidden={record.user.active} />
            </div>
          );
        }

        if ('activity' in record) {
          return (
            <div className={cx(s.mobileExpandedRowActivity, s.flexRow)}>
              <ChevronRight className={cx(s.expandedIcon, { [s.expanded]: _.includes(expandedRowKeys, record?.partner?.partnerId) })} />
              <PartnerMask
                wrapperColor={record?.partner?.color}
                mask={record.partner?.mask}
                partnerId={record.partner.partnerId}
                iconColor={record?.activity?.color}
                isVacation={record.partner.isVacation}
              />
              <span>{record.activity.name}</span>
              <Deactivated isHidden={record.activity.active} />
            </div>
          );
        }
      },
    },
    hours: {
      render: (text: string, record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => (
        <span>{`${record?.hours}h`}</span>
      ),
    },
  };

  const expandableSettings = {
    expandRowByClick: true,
    indentSize: 40,
    expandedRowKeys,
    onExpandedRowsChange: (expandedRowsKeys: readonly Key[]) => setExpandedRowKeys(expandedRowsKeys),
    expandIcon: () => null,
  };

  return (
    <div>
      <BasicTable
        className={cx(s.reportHourTable, { [s.mobileReportHourTable]: login.isMobileDevice })}
        rowKey={(record: FrontActivityUserReports | FrontUserReport | FrontCuratorPartnerDetailedReport) => {
          if ('user' in record) {
            return `${record.activity.activityId}-${record.user.userId}`;
          }
          if ('activity' in record) {
            return record.activity.activityId;
          }

          return record.partner.partnerId;
        }}
        childrenColumnName="children"
        loading={loading}
        expandable={expandableSettings}
        getData={getCuratorReport}
        dataSource={curatorData?.partners}
        initialRequest={false}
        renderColumns={login.isMobileDevice ? mobileRenderColumns : desktopRenderColumns}
        pagination={false}
        tableName={TABLE_NAMES.CURATOR_HOUR_REPORT}
        columns={login.isMobileDevice ? MOBILE_CURATOR_REPORT_COLUMNS : CURATOR_HOUR_REPORT_COLUMNS}
        rowClassName={(record, index, indent) => {
          if (indent === 2) {
            return '';
          }

          return s.clickableRow;
        }}
        bordered
        locale={{
          emptyText: 'No hours reported in selected period',
        }}
      />
    </div>
  );
};

export default CuratorReportTable;
