import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import modal from 'antd/es/modal';
import _ from 'lodash';
import dayjs from 'dayjs';

import { savePersonalInfoFields } from 'redux/PersonalInfo/action';
import { DispatchType, Redux } from 'helpers/types/_common';
import modals from 'helpers/styles/components/modals.module.sass';
import { UserSkill } from 'helpers/types/Skills';
import { ReactComponent as CircleErrorIcon } from 'helpers/icons/Buttons/error-circle.svg';
import { ReactComponent as CheckCircleIcon } from 'helpers/icons/Buttons/check-circle-outline.svg';
import { ReactComponent as ExclamationErrorIcon } from 'helpers/icons/Buttons/error-exclamation.svg';
import { UserDetails } from 'api/User/types';
import { UserRedux } from 'redux/User/types';
import useScrollPosition from 'helpers/hooks/useWindowScroll';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import s from './styles.module.sass';

interface ConfirmSkillButtonProps {
  isEdit: boolean;
  loading: boolean;
  isReadOnlyMode?: boolean;
  user: UserDetails | UserRedux;
  setUserData?: React.Dispatch<React.SetStateAction<UserDetails | UserRedux>>
  userSkills: UserSkill[];
}

const SCROLL_VALUE_TO_COLLAPSE = 53;

const ConfirmSkillButton: React.FC<ConfirmSkillButtonProps> = ({ isEdit, userSkills, user, loading, setUserData, isReadOnlyMode }) => {
  const dispatch: DispatchType = useDispatch();
  const { userId: currentUserId } = useSelector((state: Redux) => state.login);

  const scrollY = useScrollPosition();
  const isEditedByAdmin = user.userId !== currentUserId;

  if (isEdit || _.isEmpty(userSkills)) {
    return null;
  }

  const isSkillsetNotFilled = _.isNull(user.skillsetActuality?.markedAsActual);
  const isSkillsetWarning = !_.isNull(user.skillsetActuality?.markedAsActual) && user.skillsetActuality?.isExpired;
  const isSkillsetOkay = !_.isNull(user.skillsetActuality?.markedAsActual) && !user.skillsetActuality?.isExpired;

  const handleOnClick = () => {
    const content = isSkillsetWarning ? `Skillset was confirmed on ${_.isNull(user.skillsetActuality?.markedAsActual)
      ? '-' : dayjs(user.skillsetActuality?.markedAsActual).format(RU_DOT_DATE_FORMAT)}. Is it still actual in your opinion?`
      : `In my opinion, ${isEditedByAdmin ? 'user\'s' : 'my'} skillset is actual`;

    const handleOnOk = () => dispatch(savePersonalInfoFields({ markedSkillsetAsActual: true }, user.userId, isEditedByAdmin))
      .then(() => {
        if (isEditedByAdmin) {
          setUserData?.({ ...user, skillsetActuality: { markedAsActual: dayjs().format(), isExpired: false } });
        }
      });

    modal.confirm({
      title: isEditedByAdmin ? 'Confirm user\'s skillset' : 'Confirm my skillset',
      className: cx(modals.qsConfirmAntdModal, s.confirmModal),
      content,
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleOnOk,
    });
  };

  const renderOkayButton = () => (
    <div className={s.confirmOkayButtonWrapper}>
      <CheckCircleIcon />
      { isEditedByAdmin ? 'User’s skillset is up to date' : 'Your skillset is up to date' }
    </div>
  );

  const renderNotificationWarning = () => (
    <div className={s.confirmNotificationStickyWrapper}>
      <div
        className={cx(s.confirmNotificationWrapper,
          {
            [s.warningWrapper]: isSkillsetWarning,
            [s.errorWrapper]: isSkillsetNotFilled,
            [s.collapsedNotificationWrapper]: scrollY > SCROLL_VALUE_TO_COLLAPSE,
            [s.isReadOnlyMode]: isReadOnlyMode,
          })}
      >
        <div className={s.confirmNotificationTitleWrapper}>
          { isSkillsetWarning ? <CircleErrorIcon /> : <ExclamationErrorIcon /> }
          <span className={s.confirmNotificationTitle}>{isEditedByAdmin ? 'Confirm user\'s skillset' : 'Please, confirm your skillset'}</span>
        </div>

        <Button
          size="small"
          type="default"
          onClick={handleOnClick}
          className={cx(s.confirmNotificationButton, {
            [s.warningButton]: isSkillsetWarning,
            [s.errorButton]: isSkillsetNotFilled,
          })}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  if (loading || isReadOnlyMode) {
    return null;
  }

  return (
    isSkillsetOkay
      ? renderOkayButton()
      : renderNotificationWarning()
  );
};

export default ConfirmSkillButton;
