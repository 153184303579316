import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';
import Button from '../../Button';
import { LIMIT } from '../../../../constants/__common__/PaginationConstants';
import { PaginationComponentProps } from '../tableTypes';
import { ActionDataProps } from '../../../../../helpers/types/_common';
import _ from 'lodash';

const cx = classNames.bind(S);

interface TablePaginationComponentProps {
  onPage?: number;
  additionalData?: AdditionalDataToSendForGettingRequest;
  getter: (
    paginationProps: PaginationComponentProps
  ) => Promise<ActionDataProps>;
}

interface TablePaginationComponentState {
  onPage: number;
  showPagination: boolean;
  pageCount: number;
  page: number;
  objectsCount: number;
}

type AdditionalDataToSendForGettingRequest = {
  [key: string]: string | number | boolean;
};

export default class TablePagination extends React.Component<
  TablePaginationComponentProps,
  TablePaginationComponentState
> {
  constructor(props: TablePaginationComponentProps) {
    super(props);

    this.state = {
      onPage: this.props.onPage || LIMIT,
      showPagination: false,
      pageCount: 0,
      page: 0,
      objectsCount: 0
    };

    this.changePage = this.changePage.bind(this);
  }

  shouldComponentUpdate(
    nextProps: Readonly<TablePaginationComponentProps>,
    nextState: Readonly<TablePaginationComponentState>
  ): boolean {
    return (
      this.props.additionalData !== nextProps.additionalData ||
      this.state.showPagination !== nextState.showPagination ||
      this.state.objectsCount !== nextState.objectsCount ||
      this.state.page !== nextState.page
    );
  }

  componentDidUpdate(prevProps: Readonly<TablePaginationComponentProps>): void {
    if (
      this.props.additionalData &&
      prevProps.additionalData !== this.props.additionalData
    ) {
      const keys = Object.keys(this.props.additionalData);

      if (
        prevProps.additionalData &&
        keys.length !== Object.keys(prevProps.additionalData).length
      ) {
        this.getDataByGetter();
      } else {
        let needToReloadTable = false;

        keys.forEach((key, i, arr) => {
          needToReloadTable =
            (prevProps.additionalData &&
              this.props.additionalData &&
              (prevProps.additionalData[key] !==
                this.props.additionalData[key] ||
                Object.keys(prevProps.additionalData).length !== arr.length)) ||
            needToReloadTable;
        });

        if (needToReloadTable) this.getDataByGetter();
      }
    }
  }

  getDataByGetter(page = 0): void {
    const {
      getter,
      additionalData,
    } = this.props;
    const { onPage } = this.state;

    if (!getter) return;
    getter({
      ...additionalData,
      limit: onPage,
      offset: page * onPage,
    })
      .then(res => {
        if (!res || !res.payload)
          throw Error('looks like getter doesn\'t return payload to pagination');

      const objectsCount =
        res.payload.meta && res.payload.meta.totalCount
          ? res.payload.meta.totalCount
          : 0;

      this.setState({
        showPagination: Math.ceil(objectsCount / onPage) > 1,
        page,
        objectsCount
      });
    });
  }

  componentDidMount(): void {
    this.getDataByGetter();
  }

  changePage(page: number): void {
    this.setState({ page });

    this.getDataByGetter(page);
  }

  renderPagination(): React.ReactNode {
    const { onPage, objectsCount } = this.state;
    const currentPage = this.state.page;
    const pages = Array.from(Array(Math.ceil(objectsCount / onPage)).keys());

    // array of expressions for view of pagination buttons -> 1 ... 3,(4),5 ... 7
    const buttonsToRender = [
      0,
      currentPage,
      currentPage - 1,
      currentPage + 1,
      pages.length - 1
    ];

    if (pages.length <= 9) {
      return pages.map(page => (
        <Button
          key={page}
          type={currentPage === page ? 'primary' : undefined}
          onClick={this.changePage.bind(this, page)}
        >
          {page + 1}
        </Button>
      ));
    } else {
      return pages.map(page => {
        if (buttonsToRender.includes(page)) {
          return (
            <Button
              key={page}
              type={currentPage === page ? 'primary' : undefined}
              onClick={this.changePage.bind(this, page)}
            >
              {page + 1}
            </Button>
          );
        } else {
          return (
            <Button
              key={page}
              className={cx('qs-table__pagination-thumb')}
              disabled={true}
            >
              ...
            </Button>
          );
        }
      });
    }
  }

  render(): React.ReactNode {
    const { showPagination } = this.state;

    return showPagination ? (
      <div className={cx('qs-table__pagination')}>
        {this.renderPagination()}
      </div>
    ) : null;
  }
}
