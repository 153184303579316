import _ from 'lodash';
import { Dayjs } from 'dayjs';

import { UserPartnerOutput, UserPinnedActivityOutput } from 'api/Partners/types';
import * as userActions from 'api/User/requests';
import { ChangeMonthBody, MonthButtonBody, UserMonthOutput } from 'api/User/types';
import { SelectedHour } from 'components/TimeSheet/helpers/types';
import { FlatUserHours } from 'components/TimeSheet/components/Table/components/TableBody/helpers/types';
import * as TimeSheetRequests from 'api/TimeSheet/request';
import * as PartnerRequests from 'api/Partners/requests';
import * as CuratorRequests from 'api/Curators/requests';
import { getMinMax } from 'components/TimeSheet/components/Table/components/TableBody/components/Column/helpers/helpers';
import configureStore from 'redux/_reducer/configureStore';
import { HoursDataToAdd, UsersHourResponse } from 'api/TimeSheet/types';
import { DispatchType } from 'helpers/types/_common';
import { getSelectionCoords } from 'components/TimeSheet/components/Table/components/TableBody/helpers/helpers';

import * as Actions from './constants';
import { DeleteUserHoursModalData, SetHoursDrawerProps } from './types';

export const openDeleteUserHoursModal = (data: DeleteUserHoursModalData) => ({ type: Actions.OPEN_DELETE_USER_HOURS_MODAL, payload: data });
export const closeDeleteUserHoursModal = () => ({ type: Actions.CLOSE_DELETE_USER_HOURS_MODAL });

export const startTimeSelecting = () => ({ type: Actions.SHOW_TIME_SELECTING });
export const dropTimeSelecting = () => ({ type: Actions.DROP_TIME_SELECTING });

export const setSelectedCells = (cellIds: string[]) => (
  (dispatch: DispatchType) => {
    const p = new Promise((resolve) => {
      dispatch({ type: Actions.SET_SELECTED_CELLS, payload: { cellIds } });
      resolve(true);
    });

    return p;
  }
);

export const changeDrawMode = (newStatus: string) => ({ type: Actions.CHANGE_DRAW_MODE, payload: newStatus });

export const deleteUserHourAction = (day: string, startCellIndex: number, endCellIndex: number, editUserId?: string) => {
  const { userId } = configureStore.getState().login;

  return (dispatch: DispatchType) => TimeSheetRequests.deleteUserHours(editUserId || userId, {
    startCellIndex,
    endCellIndex,
    day,
  })
    .then(() => {
      const { min, max } = getMinMax(startCellIndex, endCellIndex);
      const cellIndexes = _.range((max + 1) - min).map(c => c + min);

      dispatch({ type: Actions.DELETE_USER_HOUR, payload: { day, cellIndexes } });
      dispatch(dropTimeSelecting());
      dispatch(closeSelectionContextMenu());
    });
};

export const addUserHourCommentAction = (
  userId: number,
  day: string,
  startCellIndex: number,
  endCellIndex: number,
  comment: string,
) => (dispatch: DispatchType) => TimeSheetRequests.addUserHourComment(userId, {
  startCellIndex,
  endCellIndex,
  day,
}, comment)
  .then((res) => {
    const { min, max } = getMinMax(startCellIndex, endCellIndex);
    const cellIndexes = _.range(max - min).map(c => c + min);

    dispatch({ type: Actions.ADD_USER_HOUR_COMMENT,
      payload: {
        day,
        cellIndexes,
        comment: {
          commentId: res.commentId,
          comment,
        },
      } });
  });

export const deleteUserHourCommentAction = (
  userId: number,
  day: string,
  startCellIndex: number,
  endCellIndex: number,
) => (dispatch: DispatchType) => TimeSheetRequests.deleteUserHourComment(userId, {
  startCellIndex,
  endCellIndex,
  day,
})
  .then(() => {
    const { min, max } = getMinMax(startCellIndex, endCellIndex);
    const cellIndexes = _.range(max - min).map(c => c + min);

    dispatch({ type: Actions.ADD_USER_HOUR_COMMENT, payload: { day, cellIndexes } });
  });

  export const markActivityAsCurrentlyInUse = (activity: UserPinnedActivityOutput, userId: number) => (dispatch: DispatchType) => {
    dispatch({ type: Actions.SET_CURRENTLY_IN_USE_ACTIVITY, payload: activity });
  
    PartnerRequests.markActivityCurrentlyInUse({ userId, activity });
  };

export const ctrlPressToggle = (status: boolean) => ({ type: Actions.CTRL_PRESS_TOGGLE, payload: status });

export const selectActivity = (activityObject: undefined | 'currentlyInUse' | UserPinnedActivityOutput) => {
  const { activities } = configureStore.getState().timeSheet;
  const currentlyInUseActivity = _.filter(activities, { currentlyInUse: true })[0];

  if (_.isUndefined(activityObject)) return setTimeTrackerActivity();

  if (activityObject === 'currentlyInUse') {
    if (!_.isUndefined(currentlyInUseActivity)) {
      return setTimeTrackerActivity(currentlyInUseActivity);
    } else {
      return setTimeTrackerActivity();
    }
  }

  return setTimeTrackerActivity(activityObject as UserPinnedActivityOutput);
};

export const addUserHourAction = (editUserId?: string, data?: HoursDataToAdd) => {
  const { userId } = configureStore.getState().login;
  const { selectedActivity } = configureStore.getState().timeSheet;
  const selectionCoords = getSelectionCoords();
  const { startCellIndex, endCellIndex } = data || selectionCoords;

  const selectionDay = data ? data.day : selectionCoords.startDay;
  const activityId = data ? data.activityId : selectedActivity.activity.activityId;

  return (dispatch: DispatchType) => {
    const trustedActivityId = activityId || _.get(selectedActivity, 'activity.activityId');
    if (!selectionDay || !trustedActivityId) {
      dispatch({ type: Actions.ADD_USER_HOUR, payload: { userHours: [] } });

      if (!selectionDay && trustedActivityId) return new Promise((resolve, reject) => reject(new Error(`Selected day is unavailable: ${selectionDay}.`)));
      if (selectionDay && !trustedActivityId) return new Promise((resolve, reject) => reject(new Error(`Selected activity id is unavailable: ${trustedActivityId}.`)));
      if (!selectionDay && !trustedActivityId) return new Promise((resolve, reject) => reject(new Error(`Selected day (${selectionDay}) and activity id (${trustedActivityId}) is unavailable.`)));
    } else {
      return TimeSheetRequests.addUserHours(editUserId || userId, {
        startCellIndex,
        endCellIndex,
        activityId: activityId || _.get(selectedActivity, 'activity.activityId'),
        day: selectionDay,
      })
        .then(userHours => dispatch({ type: Actions.ADD_USER_HOUR, payload: { userHours } }));
    }
  };
};

export const replaceUserHourAction = (editUserId: string | number, data: HoursDataToAdd) => {
  const { userId } = configureStore.getState().login;

  return (dispatch: DispatchType) => (
    TimeSheetRequests.replaceUserHours(editUserId || userId, data)
      .then(userHours => dispatch({ type: Actions.REPLACE_USER_HOUR, payload: { userHours } }))
  );
};

export const replaceAndFillUserHourAction = (editUserId: string | number, data: HoursDataToAdd) => {
  const { userId } = configureStore.getState().login;

  return (dispatch: DispatchType) => (
    TimeSheetRequests.replaceAndFillUserHours(editUserId || userId, data)
      .then(userHours => dispatch({ type: Actions.REPLACE_USER_HOUR, payload: { userHours } }))
  );
};

export const setSelectionBoxLoading = (isLoadingStatus: boolean) => ({ type: Actions.SET_SELECTION_BOX_LOADING, payload: { isLoadingStatus } });

export const setFlatUserHoursList = (table: FlatUserHours) => ({ type: Actions.SET_FLAT_USER_HOURS_TABLE, payload: table });
export const setReplaceDrawer = (replaceDrawer?: SetHoursDrawerProps) => ({ type: Actions.SET_REPLACE_DRAWER, payload: replaceDrawer });

export const openSelectionContextMenu = (selectedHours: SelectedHour[]) => ({ type: Actions.OPEN_SELECTION_CONTEXT_MENU, payload: { selectedHours } });
export const closeSelectionContextMenu = () => ({ type: Actions.CLOSE_SELECTION_CONTEXT_MENU });
export const initiateContextMenus = () => ({ type: Actions.INITIATE_CONTEXT_MENUS });

export const openGroupContextMenu = (day: string, cellIndex: number) => ({ type: Actions.OPEN_GROUP_CONTEXT_MENU, payload: { day, cellIndex } });
export const closeGroupContextMenu = () => ({ type: Actions.CLOSE_GROUP_CONTEXT_MENU });

export const changeSelectedDate = (dayjsObj: Dayjs) => ({ type: Actions.CHANGE_SELECTED_DATE, payload: dayjsObj });

export const changeLockMonthStatus = (body: ChangeMonthBody) => (dispatch: DispatchType) => userActions.changeMonthStatus(body)
  .then(() => {
    dispatch({ type: Actions.CHANGE_LOCK_MONTH_STATUS });
  });

export const getMonthStatus = (body: MonthButtonBody, curatorOnlyView?: boolean) => (dispatch: DispatchType) => {
  if (curatorOnlyView) {
    return CuratorRequests.getCuratorMonthStatus(body)
      .then((res: UserMonthOutput) => {
        dispatch({
          type: Actions.SET_LOCK_MONTH_STATUS,
          isMonthClosed: res.closed,
        });
      })
      .catch(() => {
        dispatch({
          type: Actions.SET_LOCK_MONTH_STATUS,
          isMonthClosed: true,
        });
      });
  }

  return userActions.getMonthStatus(body)
    .then((res: UserMonthOutput) => {
      dispatch({
        type: Actions.SET_LOCK_MONTH_STATUS,
        isMonthClosed: res.closed,
      });
    })
    .catch(() => {
      dispatch({
        type: Actions.SET_LOCK_MONTH_STATUS,
        isMonthClosed: true,
      });
    });
};

export const setTimeTrackerActivity = (selectedActivity?: UserPinnedActivityOutput) => ({
  type: Actions.SET_ACTIVITY_ID,
  selectedActivity,
});

export const replaceTimesheetActivityWithNew = (userHours: UsersHourResponse[]) => (dispatch: DispatchType) => {
  if (!_.isArray(userHours)) {
    return;
  }

  dispatch({
    type: Actions.REPLACE_ACTIVITY,
    payload: { userHours },
  });
};

export const getActivitiesList = (userId: number, curatorOnlyView?: boolean) => (dispatch: DispatchType) => {
  if (curatorOnlyView) {
    return CuratorRequests.getCuratorActivitiesList(userId)
      .then((activities) => {
        dispatch({
          type: Actions.SET_USER_ACTIVITIES,
          activities,
        });
      });
  }

  return PartnerRequests.getActivitiesList(userId)
    .then((activities) => {
      dispatch({
        type: Actions.SET_USER_ACTIVITIES,
        activities,
      });
    });
};
export const updateUserActivities = (activities: UserPinnedActivityOutput[]) => ({
  type: Actions.UPDATE_USER_ACTIVITIES,
  activities,
});

export const getPartnersList = (userId: number, curatorOnlyView?: boolean) => (dispatch: DispatchType) => {
  if (curatorOnlyView) {
    return CuratorRequests.getCuratorPartnersList(userId).then((partners) => {
      dispatch({
        type: Actions.SET_USER_PARTNERS,
        partners,
      });
    });
  }

  return PartnerRequests.getPartnersList(userId)
    .then((partners) => {
      dispatch({
        type: Actions.SET_USER_PARTNERS,
        partners,
      });
    });
};
export const updateUserPartners = (partners: UserPartnerOutput[]) => ({
  type: Actions.UPDATE_USER_PARTNERS,
  partners,
});
