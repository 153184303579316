import React, { useState } from 'react';

import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';

import s from './styles.module.sass';

interface UserAvatarProps {
  avatarUrl?: string;
  size?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ avatarUrl, size }) => {
  const [ withError, setErrorStatus ] = useState(false);

  return (
    avatarUrl && !withError
      ? <img src={avatarUrl} alt="avatar" onError={() => setErrorStatus(true)} className={s.userAvatar} style={size ? { height: size, width: size } : {}} />
      : <TrackerLogo className={s.companyAvatar} style={size ? { height: size, width: size } : {}} />
  );
};

export default UserAvatar;
