import React from 'react';
import S from '../styles.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(S);

interface LayoutRowComponentProps {
  className?: string;
  style?: React.CSSProperties;
  justify?: string;
  align?: string;
}

export default class Row extends React.Component<LayoutRowComponentProps, {}> {
  render(): React.ReactNode {
    const { children, className, style, justify, align } = this.props;
    const justifyIsExist = Boolean(S[`qs-layout__row--justify-${justify}`]);
    const alignIsExist = Boolean(S[`qs-layout__row--align-${align}`]);

    return (
      <div
        className={cx(
          {
            'qs-layout__row': true,
            ['qs-layout__row--justify-' + justify]: justifyIsExist,
            ['qs-layout__row--align-' + align]: alignIsExist
          },
          className
        )}
        style={style}
      >
        {children}
      </div>
    );
  }
}
