import React, { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as TimeTracker } from './TimeTracker.svg';
import { ReactComponent as Employees } from './Employees.svg';
import { ReactComponent as EmployeesReports } from './EmployeesReports.svg';
import { ReactComponent as Entities } from './Entities.svg';
import { ReactComponent as Partners } from './Partners.svg';
import { ReactComponent as WorkedHours } from './WorkedHours.svg';
import { ReactComponent as Report } from './Report.svg';
import { ReactComponent as Administration } from './Settings.svg';
import { ReactComponent as FindSpecialists } from './searchUsers.svg';

interface NavBarIconProps {
  iconFileName: string;
  className?: string;
}

interface IconsType {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const NavBarIcon: React.FC<NavBarIconProps> = (props) => {
  const icons: IconsType = {
    TimeTracker,
    Employees,
    EmployeesReports,
    Entities,
    Partners,
    WorkedHours,
    Report,
    Administration,
    FindSpecialists,
  };

  const IconName = icons[props.iconFileName];

  return IconName ? <IconName className={props.className} /> : null;
};

export default NavBarIcon;
