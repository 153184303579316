import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames/bind';

import S from './helpers/styles.module.sass';
import { AttitudesTextField } from '../../../../api/Specialists/types';
import { SkillExperiencesConstant } from '../../../../helpers/types/Skills';
import SKILL_EXPERIENCES from '../../../../helpers/constants/Skills/Experience';
import SkillAttitude from '../../../ProfileTabContent/components/SkillSet/components/Attitude';

const cx = classNames.bind(S);

type AttitudesCellProps = {
  text: AttitudesTextField;
};

const AttitudesCell: React.FC<AttitudesCellProps> = ({ text }) => {
  if (!text && !_.isObject(text)) {
    return <div>&mdash;</div>;
  }

  return (
    <div className={cx('attitudes-cell')}>
      <div className={cx('attitudes-cell_exp')}>{SKILL_EXPERIENCES[text.experience as keyof SkillExperiencesConstant]?.label}</div>
      <div className={cx('attitudes-cell_attitudes')}>
        <SkillAttitude iconName={text.attitude} />
      </div>
    </div>
  );
};

export default AttitudesCell;
