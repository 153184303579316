import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Checkbox, Input, Radio } from 'antd';
import _ from 'lodash';

import radio from 'helpers/styles/components/radio.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import useDebounce from 'helpers/hooks/useDebounce';
import GeneralFiltersButton from 'components/GeneralFiltersButton/GeneralFiltersButton';

import s from './styles.module.sass';
import { PartnersUsersFiltersState } from '../types';

interface PartnersUsersTableProps {
  filters: PartnersUsersFiltersState;
  setFilters: (filters: PartnersUsersFiltersState) => void;
}

const PartnersUsersSettings: React.FC<PartnersUsersTableProps> = ({ filters, setFilters }) => {
  const [ searchValue, setSearchValue ] = useState<string | undefined>();
  
  const debounceSearchValue = useDebounce(searchValue, 600);
  
  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      setFilters({
        ...filters,
        nameContains: searchValue,
      });
    }
  }, [ debounceSearchValue ]);

  const handleOnChange = (value: 'ALL' | 'CURATOR') => {
    setFilters({
      ...filters,
      active: value,
    });
  };

  const renderRadio = () => (
    <div className={cx(radio.tableRadio, s.partnersUsersRadio)}>
      <Radio.Group
        value={filters.active}
        className={s.radioWrapper}
        onChange={e => handleOnChange(e.target.value)}
      >
        <Radio
          className={radio.radioItem}
          value="ALL"
        >
          All
        </Radio>
        <Radio
          className={radio.radioItem}
          value="CURATOR"
        >
          Curators
        </Radio>
      </Radio.Group>
    </div>
  );

  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.partnersInput)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      value={searchValue}
      size="large"
      onChange={e => setSearchValue(e.currentTarget.value)}
      allowClear
      placeholder="Search partners..."
    />
  );

  return (
    <div className={s.partnersSettings}>
      <div className={s.filtersWrapper}>
        { renderRadio() }

        <Checkbox
          onChange={e => setFilters({ ...filters, userActive: !e.target.checked })}
          checked={!filters.userActive}
          className={s.curatorCheckbox}
        >
          Show inactive users
        </Checkbox>
        <Checkbox
          onChange={e => setFilters({ ...filters, partnerActive: !e.target.checked })}
          checked={!filters.partnerActive}
          className={s.curatorCheckbox}
        >
          Show inactive partners
        </Checkbox>
        
        { renderInputSearch() }

        <GeneralFiltersButton
          isProfilesViewerMode={false}
          onSearch={(ids) => {
            const { entityId, userId, partnerId } = ids;

            setFilters({
              ...filters,
              entityId,
              userId,
              partnerId,
            });
          }}
        />
      </div>
    </div>
  );
};

export default PartnersUsersSettings;
