export const deleteFromSessionStorage = (key: string): void => {
  sessionStorage.removeItem(key);
};

export const setSessionStorage = (key: string, value: unknown): void => {
  if (!sessionStorage || !value) {
    deleteFromSessionStorage(key);
  }

  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = <T>(key: string): T | undefined => {
  if (!sessionStorage || !sessionStorage[key]) {
    return undefined;
  }

  return JSON.parse(sessionStorage[key]);
};

export const getStringFromSessionStorage = (key: string): string | undefined => {
  if (!sessionStorage || !sessionStorage[key]) {
    return undefined;
  }

  return sessionStorage[key];
};

export const getNumberFromSessionStorage = (key: string): number | undefined => {
  if (!sessionStorage || !sessionStorage[key]) {
    return undefined;
  }

  return Number(sessionStorage[key]);
};
