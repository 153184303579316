import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Checkbox, Modal } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';

import modals from 'helpers/styles/components/modals.module.sass';
import { ReactComponent as CloseIcon } from 'helpers/icons/closeIcon.svg';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { BaseUserOutputChecked, NotificationsModalData } from 'components/UserHourReports/types';
import { sendCloseMonthNotification, sendNotifications } from 'api/Notifications/requests';
import { NotificationTypes } from 'api/Notifications/types';

import S from './styles.module.sass';

interface NotificationConfirmationModalProps extends NotificationsModalData {
  onClose: () => void;
}

const SCROLL_SAFE_ZONE = 50; // px

const NotificationConfirmationModal: React.FC<NotificationConfirmationModalProps> = ({ isVisible, onClose, users, year, month }) => {
  const [ isLoading, setLoading ] = useState(false);
  const [ isDisabled, setDisabled ] = useState(users.length > 1);
  const [ userReportsToNotify, setUserReportsToNotify ] = useState<BaseUserOutputChecked[]>([]);

  const isMultipleType = useMemo(() => users.length > 1, [ users ]);
  const userReportsListRef = useRef<HTMLUListElement>(null);
  const checkedUsers = useMemo(() => _.filter(userReportsToNotify, userReport => userReport.checked), [ userReportsToNotify ]);
  const beautyMonthName = useMemo(() => dayjs(`${year}-${month}-1`).format('MMMM'), [ year, month ]);

  useEffect(() => {
    if (isVisible) {
      setUserReportsToNotify(users);
      setDisabled(users.length > 1);
    } else {
      setLoading(false);
      setDisabled(true);
      setUserReportsToNotify([]);

      if (userReportsListRef?.current) userReportsListRef.current.scrollTop = 0;
    }
  }, [ isVisible ]);

  const onOk = () => {
    const userIds = _.map(checkedUsers, user => user.userId);
    
    if (!isMultipleType) {
      setLoading(true);
      sendCloseMonthNotification(NotificationTypes.closeMonth, userIds[0])
        .finally(() => {
          setLoading(false);
          onClose();
        });
    } else {
      setLoading(true);
      sendNotifications(NotificationTypes.closeMonth, userIds)
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  const onScrollDown = () => {
    if (!userReportsListRef.current) {
      if (isDisabled) setDisabled(false);
      return;
    }

    const list = userReportsListRef.current;

    if (list.scrollTop + list.clientHeight + SCROLL_SAFE_ZONE >= list.scrollHeight && isDisabled) setDisabled(false);
  };

  const userSelectCheckboxToggle = (index: number) => {
    const updatedUsersList = _.reduce(userReportsToNotify, (result, userReport, i) => (
      i === index 
        ? [ ...result, ({ ...userReport, checked: !userReport.checked }) ]
        : [ ...result, userReport ]
    ), [] as BaseUserOutputChecked[]);
    setUserReportsToNotify(updatedUsersList);
  };

  const renderUser = (user: BaseUserOutputChecked) => (
    <div className={S.userCard}>
      <UserAvatar avatarUrl={user.avatarUrl} />
      <span>{`${user.forename} ${user.surname}`}</span>
    </div>
  );

  const renderOption = (user: BaseUserOutputChecked, index: number) => (
    <li key={user.userId} className={S.userReport}>
      <Checkbox
        checked={user.checked}
        onChange={() => userSelectCheckboxToggle(index)}
        className={S.checkbox}
      >
        {renderUser(user)}
      </Checkbox>
    </li>
  );

  const memoizedReportsList = useMemo(() => (
    <ul
      ref={userReportsListRef}
      className={S.userReportsList}
      onScroll={onScrollDown}
    >
      {_.map(userReportsToNotify, renderOption)}
    </ul>
  ), [ userReportsToNotify ]);

  const renderText = () => {
    if (_.isEmpty(users)) return null;

    if (isMultipleType) {
      return (
        <>
          You are going to send notifications to
          <span className={S.redText}>{checkedUsers.length}</span>
          users
        </>
      );
    } else {
      return (
        <>
          You are going to notify
          <span className={S.redText}>{`${users[0].forename} ${users[0].surname}`}</span>
          to close
          <span className={S.redText}>{`${beautyMonthName} of ${year}`}</span>
        </>
      );
    }
  };

  const renderUsers = () => {
    if (_.isEmpty(users) || !isMultipleType) return null;

    return memoizedReportsList;
  };

  return (
    <Modal
      title={`${isMultipleType ? 'Send notifications' : 'Send notification'}`}
      visible={isVisible}
      onCancel={onClose}
      width={600}
      closeIcon={<CloseIcon stroke="#9BA3AC" />}
      className={classNames(S.userHourReportModal, modals.qsBasicAntdModal)}
      footer={[
        <Button
          key="back"
          type="text"
          className={classNames(modals.modalCancelBtn, S.modalButton)}
          style={{ marginRight: '0' }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          title="Confirm"
          loading={isLoading}
          disabled={isDisabled}
          className={classNames(modals.modalOkBtn, S.okButton, S.modalButton)}
          style={{ width: '130px' }}
          onClick={onOk}
        >
          Send
        </Button> ]}
    >
      <div className={S.content}>
        {renderText()}
        {renderUsers()}
      </div>
    </Modal>
  );
};

export default NotificationConfirmationModal;
