import React from 'react';

import s from './styles.module.sass';
import EditProfileEmployeesList from '../Sidebars/EditProfileTabSidebar/components/EditProfileEmployeesList';

interface UserTimesheetAdminTabProps {
}

const UserTimesheetAdminTab: React.FC<UserTimesheetAdminTabProps> = () => (
  <div className={s.employeesListWrapper}>
    <div className={s.employeesListTitle}>Please, select user to view his timesheet</div>
    <EditProfileEmployeesList
      styleClasses={{ wrapper: s.employeesList, item: s.employeesItem }}
      alwaysVisible
    />
  </div>
);

export default UserTimesheetAdminTab;
