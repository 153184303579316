import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
/*
  Import charts, all with Chart suffix
  ALL AVAILABLE CHARTS COMPONENT
  https://github.com/hustcc/echarts-for-react
 */
import {
  PieChart,
} from 'echarts/charts';
/* import components, all suffixed with Component */
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
/* Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step */
import {
  CanvasRenderer,
} from 'echarts/renderers';
import { EChartsReactProps } from 'echarts-for-react/lib/types';

// import s from './styles.module.sass';

// Register the required components
echarts.use(
  [ TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer, LegendComponent ],
);

interface EChartsProps extends EChartsReactProps {
}

/*
    options - https://echarts.apache.org/en/option.html#title
 */

const ECharts: React.FC<EChartsProps> = props => (
  <ReactEChartsCore
    {...props}
    echarts={echarts}
    notMerge
    lazyUpdate
  />
);

export default ECharts;
