import _ from 'lodash';

import configureStore from 'redux/_reducer/configureStore';
import { USER_ROLES } from 'api/User/constants';
import { UserRedux } from 'redux/User/types';

import { AccessEnum } from '../../components/Root/helpers/types';

export const isCuratorRole = (userInfo?: UserRedux) => {
  const { permissions } = configureStore.getState().login;

  return _.includes(permissions, USER_ROLES.CURATOR) || _.includes(userInfo?.permissions, USER_ROLES.CURATOR);
};

export const isAvailableByAccessType = (permissions: string[], accessType?: AccessEnum | AccessEnum[]) => {
  if (!accessType) {
    return false;
  }

  if (!_.isArray(accessType)) {
    return _.includes(permissions, accessType);
  }

  return !_.isEmpty(_.intersection(permissions, accessType));
};
