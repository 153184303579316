import React, { useState } from 'react';
import cx from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { SkillTagProps } from './helpers/types';
import s from './helpers/styles.module.sass';

const SkillTag: React.FC<SkillTagProps> = ({ title, onDelete, color, className, id, tooltip, onClose, ...props }) => {
  const [ deleteLoading, setDeleteLoading ] = useState(false);

  const handleOnDelete = async (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (onClose) {
      onClose();
      return;
    }

    if (!id) {
      return;
    }

    if (onDelete) {
      setDeleteLoading(true);
      await onDelete(event, id);
      setDeleteLoading(false);
    }
  };

  const deleteIcon = (onDelete || onClose) ? <FontAwesomeIcon className="delete-icon" icon="times" onClick={handleOnDelete} /> : null;
  const deleteLoadingIcon = onDelete ? <LoadingOutlined className={s.deleteLoading} spin /> : null;
  const styleColor = color ? { backgroundColor: color } : {};

  if (!title) {
    return null;
  }

  return (
    <Tooltip
      overlayClassName={s.skillTagTooltip}
      title={tooltip}
    >
      <span
        {...props}
        className={cx(s.skillTag, className)}
        style={styleColor}
      >
        <span className={cx(s.skillTagSpan)}>{title}</span>
        {deleteLoading ? deleteLoadingIcon : deleteIcon}
      </span>
    </Tooltip>
  );
};

export default SkillTag;
