import React from 'react';
import cx from 'classnames';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';

import selects from 'helpers/styles/components/selects.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import { changeSelectedYear } from 'redux/BusinessCalendar/actions';

import S from './helpers/styles.module.sass';
import { YearSelectorProps } from './helpers/type';
import { ReactComponent as ArrowIcon } from './helpers/arrowIcon.svg';

const YearSelector: React.FC<YearSelectorProps> = ({ bCIsLoading }) => {
  const dispatch: DispatchType = useDispatch();
  const { selectedYear } = useSelector((state: Redux) => state.businessCalendar2);
  const yearsLeftBorder = 2020;

  const changeYear = (year: number) => {
    dispatch(changeSelectedYear(year));
  };

  const renderYearOptions = () => Array(dayjs().get('year') - yearsLeftBorder + 3).fill(null).map((e, i) => (
    <Select.Option
      // eslint-disable-next-line react/no-array-index-key
      key={yearsLeftBorder + i}
      value={yearsLeftBorder + i}
    >
      {yearsLeftBorder + i}
    </Select.Option>
  ));

  return (
    <div className={cx(S.yearSelector)}>
      <Select
        defaultValue={selectedYear}
        onChange={changeYear}
        className={S.yearSelect}
        dropdownClassName={selects.selectDropdown}
        suffixIcon={
          bCIsLoading
            ? <LoadingOutlined className={S.spinner} spin />
            : <ArrowIcon style={{ position: 'relative', top: '-2px' }} />
        }
        size="large"
        bordered={false}
        disabled={bCIsLoading}
      >
        {renderYearOptions()}
      </Select>
    </div>
  );
};

export default YearSelector;
