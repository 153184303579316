import { QsLinkElementProps } from './types';
import React from 'react';
import classNames from 'classnames/bind';
import S from '../../helpers/styles.module.sass';

const cx = classNames.bind(S);

export const LinkElement = (props: QsLinkElementProps) => {
  const { cantBeActive, active, onClick, children, activeClassName } = props;

  return children
    ? React.cloneElement(children, {
        className: cx([children.props.className], {
          [activeClassName || 'active']: active && !cantBeActive
        }),
        onClick: onClick
      })
    : null;
};
