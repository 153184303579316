import React from 'react';
import { useParams } from 'react-router-dom';

import TOOLS_TABS from 'helpers/constants/_common/tabs/tools';

import S from './helpers/styles.module.sass';

const ToolsTabContent = () => {
  const { tab } = useParams<{ tab: string }>();

  const renderTabContent = () => {
    if (!tab || !TOOLS_TABS[tab]) {
      return null;
    }

    const TabComponent = TOOLS_TABS[tab].component;

    return TabComponent ? <TabComponent /> : null;
  };

  return (
    <div className={S.administrationTabContent}>
      {renderTabContent()}
    </div>
  );
};

export default ToolsTabContent;
