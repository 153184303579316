import { DEFAULT_PAGINATION_RAW } from '../../helpers/constants/_common/Pagination';
import {
  ManageSkillTagsTable,
  FindSpecialistsTable,
  RequestCandidatesTable,
  RequestSpecialistsTable,
  EmployeesTable,
  PartnersTable,
  PartnerParticipantsTable,
  VacationReportTable,
  EntitiesTable,
  HourReportTable,
  CuratorHourReportTable,
  UserHourReportsTable,
  PartnersUsersTable,
  BookkeepingItemsTable,
} from './reducerTypes';
import { SORT_DIRECTIONS } from '../../components/_shared/Table/helpers/sorter';

export const manageSkillTagsInitialState: ManageSkillTagsTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'name' },
};

export const findSpecialistsInitialState: FindSpecialistsTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: {},
};

export const requestCandidatesInitialState: RequestCandidatesTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: {},
};

export const requestSpecialistsInitialState: RequestSpecialistsTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: {},
};

export const employeesInitialState: EmployeesTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'fullname' },
};

export const partnersInitialState: PartnersTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'name' },
};

export const partnersUsersInitialState: PartnersUsersTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'partnerId' },
};

export const entitiesInitialState: EntitiesTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'id' },
};

export const partnerParticipantsInitialState: PartnerParticipantsTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'fullname' },
};

export const BookkeepingItemsState: BookkeepingItemsTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'id' },
};

export const vacationReportInitialState: VacationReportTable = {
  loading: false,
  meta: DEFAULT_PAGINATION_RAW,
  objects: [],
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'fullname' },
};

export const hourReportInitialState: HourReportTable = {
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'partner_name' },
};

export const curatorHourReportInitialState: CuratorHourReportTable = {
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'partner_name' },
};

export const userHourReportSInitialState: UserHourReportsTable = {
  sorter: { orderDirection: SORT_DIRECTIONS.ASC, orderBy: 'fullname' },
};
