import { ExperienceFilterNames } from '../../../../../helpers/types/Skills';
import { getFromSessionStorage, deleteFromSessionStorage } from '../../../../../helpers/utils/sessionStorageService';
import { SkillTagStatistic } from '../../../../../api/SkillTags/types';
import { DEFAULT_SPECIALISTS_SKILLS } from '../../../../../helpers/constants/_common/sessionStorageConstants';

interface SkillTag extends SkillTagStatistic {
  skillId: number;
  name: string;
}

export const defaultSkillsState = () => {
  const defaultSkills = getFromSessionStorage<SkillTag>(DEFAULT_SPECIALISTS_SKILLS);

  if (defaultSkills) {
    const skills = {
      [defaultSkills.skillId]: {
        name: defaultSkills.name,
        experience: ExperienceFilterNames.notSet,
      },
    };

    deleteFromSessionStorage(DEFAULT_SPECIALISTS_SKILLS);
    return {
      skills,
      englishKnowledge: 'ZERO',
      skillsColumns: {},
      entityId: [],
      userId: [],
      partnerId: [],
    };
  }

  return { skills: {}, englishKnowledge: 'ZERO', skillsColumns: {}, entityId: [], userId: [], partnerId: [] };
};
