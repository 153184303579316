import React from 'react';
import _ from 'lodash';
import { Space, Tag } from 'antd';

import s from '../../styles.module.sass';
import { ReactComponent as CloseIcon } from '../../../../helpers/icons/CloseCross18.svg';
import PartnerMask from '../../../_shared/PartnerMask';
import { FilteredItemsState } from '../../types';
import { BaseUserOutput } from '../../../../api/User/types';
import { BaseEntityOutput } from '../../../../api/Entities/types';
import { PartnerCardOutput } from '../../../../api/Partners/types';

interface GeneralSearchSelectedPartnersProps {
  filteredItems: FilteredItemsState;
  onClick: (entity: BaseEntityOutput | PartnerCardOutput | BaseUserOutput | null, searchItemId: number, fieldName: keyof FilteredItemsState) => void;
}

const GeneralSearchSelectedPartners: React.FC<GeneralSearchSelectedPartnersProps> = ({ filteredItems, onClick }) => {
  if (_.isEmpty(filteredItems.partners)) {
    return null;
  }

  return (
    <Space className={s.filteredBlock} direction="vertical" size={0}>
      <span className={s.filteredBlockTitle}>Partners</span>
      <Space wrap size={4}>
        {
          _.map(filteredItems.partners, (partner, searchItemId) => (
            <Tag
              className={s.searchTagItem}
              key={partner.partnerId}
              closable
              closeIcon={<CloseIcon />}
              onClick={() => onClick(partner, Number(searchItemId), 'partners')}
              onClose={() => onClick(partner, Number(searchItemId), 'partners')}
            >
              <Space>
                <PartnerMask
                  wrapperColor={partner.color}
                  mask={partner?.mask}
                  partnerId={partner.partnerId}
                  iconColor={partner.maskColor}
                  isVacation={partner.isVacation}
                />
                <span>{partner.name}</span>
              </Space>
            </Tag>
          ))
        }
      </Space>
    </Space>
  );
};

export default GeneralSearchSelectedPartners;
