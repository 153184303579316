import React, { ChangeEvent } from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';

const cx = classNames.bind(S);

interface FormTextareaComponentProps {
  className?: string;
  rows?: number;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
}

export default class Textarea extends React.Component<
  FormTextareaComponentProps
> {
  render(): React.ReactNode {
    return (
      <textarea
        className={cx('qs-form__textarea', this.props.className)}
        onChange={this.props.onChange}
        rows={this.props.rows || 3}
        value={this.props.value || ''}
        id={this.props.id}
      >
        {this.props.value}
      </textarea>
    );
  }
}
