import React from 'react';

export enum AccessEnum {
  Admin = 'ADMIN',
  User = 'USER',
  Curator = 'CURATOR',
  ProfilesViewer = 'PROFILES_VIEWER',
}

export interface RouteItem {
  path: string;
  component: React.ComponentType<any>;
  mobileComponent?: React.ComponentType<any>;
  sidebar?: React.FC<any>;
  componentProps?: any;
  accessType: AccessEnum | AccessEnum[];
  exact?: boolean;
}

export interface RoutesList {
  [key: string]: RouteItem;
}
