import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Key } from 'antd/lib/table/interface';
import _ from 'lodash';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DEFAULT_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import BasicTable from '../_shared/Table';
import { MANAGE_SKILL_TAGS_COLUMNS, DEFAULT_MANAGE_PANEL_FIELDS } from './helpers/constants';
import ManageSkillTagPanel from './components/ManageSkillTagPanel';
import {
  getSkillTags,
} from '../../redux/SkillTags/actions';
import { Redux, DispatchType } from '../../helpers/types/_common';
import SKILL_CATEGORIES from '../../helpers/constants/Skills/Categories';
import { SkillCategoriesConstant } from '../../helpers/types/Skills';
import { ManagePanelValues } from './helpers/types';
import { RenderColumnsObject } from '../_shared/Table/helpers/types';
import SelectionSkillTagPanel from './components/SelectionSkillTagPanel';
import { SkillTagProps, DefaultEditValues } from '../../api/SkillTags/types';
import SkillTagsPopover from './components/SkillTagsPopover';
import { TABLE_NAMES } from '../../helpers/constants/Tables/tableList';
import {
  deleteSelectedRowsBySkillId,
  updateSelectedRowsByApprove,
} from './helpers/helpers';
import EditSkillModal from './components/ManageSkillTagPanel/components/EditSkillModal';
import SkillTagDrawer from './components/SkillTagDrawer';
import S from './helpers/styles.module.sass';
import { Meta, Sorter } from '../../api/_request/types';

const cx = classNames.bind(S);

const SkillTags: React.FC = () => {
  const dispatch: DispatchType = useDispatch();

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.MANAGE_SKILL_TAGS]);

  const [ selectedRows, setSelectedRows ] = useState<SkillTagProps[]>([]);
  const [ managePanelValues, setManagePanelValues ] = useState<ManagePanelValues>(DEFAULT_MANAGE_PANEL_FIELDS);
  const [ defaultEditValues, setDefaultEditValues ] = useState<DefaultEditValues>();
  const [ editModalVisible, setEditModalVisible ] = useState(false);
  const [ infoVisible, setInfoVisible ] = useState(false);
  const [ infoSkillId, setInfoSkillId ] = useState<number>();

  const fetchSkillTags = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      approved: managePanelValues.approved,
      category: managePanelValues.category,
      nameContains: managePanelValues.searchValue,
      synonymNameContains: managePanelValues.searchBySynonyms ? managePanelValues.searchValue : undefined,
      ...pagination,
      ...sorter,
    };

    return dispatch(getSkillTags(body, TABLE_NAMES.MANAGE_SKILL_TAGS));
  };

  useEffect(() => {
    fetchSkillTags({ limit: meta.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter);
  }, [ managePanelValues.approved, managePanelValues.category, managePanelValues.searchBySynonyms ]);

  useEffect(() => {
    if (!_.isString(managePanelValues.searchValue) && _.isEmpty(managePanelValues.searchValue)) {
      return;
    }

    fetchSkillTags({ limit: meta.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter);
  }, [ managePanelValues.searchValue ]);

  const onChangeManagePanel = (values: ManagePanelValues) => {
    setManagePanelValues(values);
  };

  const onChangeRowSelection = (selectedRowKeys: Key[], selectedRowsOnChange: SkillTagProps[]) => {
    const markedCheckedSelectedRows = _.map(selectedRowsOnChange, s => ({ ...s, checked: true }));

    setSelectedRows(markedCheckedSelectedRows);
  };

  const resetSelections = () => {
    setSelectedRows([]);
  };

  const handleOnEdit = (record: SkillTagProps) => {
    const defaultValues = {
      approved: record.approved,
      name: record.name,
      category: record.category,
      skillId: record.skillId,
      description: record.description,
    };

    setEditModalVisible(true);
    setDefaultEditValues(defaultValues);
  };

  const handleOnCancelModal = () => {
    setEditModalVisible(false);
    setDefaultEditValues(undefined);
  };

  const handleOnSuccessModal = () => {
    setEditModalVisible(false);
    setDefaultEditValues(undefined);
    fetchSkillTags(meta, sorter);
  };

  const renderManageSkillTagPanel = () => (
    <ManageSkillTagPanel
      hidden={!_.isEmpty(selectedRows)}
      managePanelValues={managePanelValues}
      onChange={onChangeManagePanel}
      onAddNewCallback={() => fetchSkillTags({ limit: meta.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter)}
    />
  );

  const renderSelectionPanel = () => {
    if (_.isEmpty(selectedRows)) {
      return null;
    }

    const onMassApproveCallback = () => {
      setSelectedRows([]);

      fetchSkillTags(meta, sorter);
    };

    return (
      <SelectionSkillTagPanel
        fetchSkillTags={() => fetchSkillTags({ limit: meta.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter)}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        resetSelections={resetSelections}
        onMassApproveCallback={onMassApproveCallback}
      />
    );
  };

  const onDeleteCallback = (skillId: number) => {
    const updatedSelectedRows = deleteSelectedRowsBySkillId(selectedRows, skillId);
    setSelectedRows(updatedSelectedRows);

    fetchSkillTags({ limit: meta.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter);
  };

  const onApproveCallback = (record: SkillTagProps) => {
    const updatedSelectedRows = updateSelectedRowsByApprove(selectedRows, record);
    setSelectedRows(updatedSelectedRows);

    fetchSkillTags(meta, sorter);
  };

  const handleOpenInfo = (record: SkillTagProps) => {
    setInfoSkillId(record.skillId);
    setInfoVisible(true);
  };

  const onCell = (record: SkillTagProps) => ({
    onClick: () => handleOpenInfo(record),
  });

  const renderColumns: RenderColumnsObject = {
    approved: {
      render: (text: boolean) => (text ? <FontAwesomeIcon title="Approved" icon="check-circle" className={cx('status-icon')} /> : null),
      onCell,
    },
    category: {
      render: (text: string) => SKILL_CATEGORIES[text as keyof SkillCategoriesConstant].title,
      onCell,
    },
    creationDate: {
      render: (text: string) => dayjs(text).format(RU_DOT_DATE_FORMAT),
      onCell,
    },
    actions: {
      render: (text: string, record: SkillTagProps) => (
        <SkillTagsPopover
          record={record}
          onEdit={handleOnEdit}
          onViewDetail={handleOpenInfo}
          onDeleteCallback={onDeleteCallback}
          onApproveCallback={onApproveCallback}
        />
      ),
    },
    name: {
      onCell,
      render: (text: string) => <span title={text} className={cx('manage-skill-tags_one-line-text')}>{text}</span>,
    },
    employeesKnow: {
      onCell,
    },
  };

  const rowSelection = {
    onChange: onChangeRowSelection,
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(selectedRows, row => row.skillId),
  };

  return (
    <div className={cx('manage-skill-tags')}>
      <div className={cx('manage-skill-tags_panel-wrapper')}>
        {renderManageSkillTagPanel()}
        {renderSelectionPanel()}
      </div>

      <BasicTable
        rowKey="skillId"
        tableName={TABLE_NAMES.MANAGE_SKILL_TAGS}
        getData={fetchSkillTags}
        initialRequest={false}
        columns={MANAGE_SKILL_TAGS_COLUMNS}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        rowSelection={rowSelection}
        rowClassName={() => 'clickable-row'}
        bordered
      />

      <EditSkillModal
        title="Edit skill tag"
        visible={editModalVisible}
        defaultValues={defaultEditValues}
        onCancel={handleOnCancelModal}
        onSuccess={handleOnSuccessModal}
      />

      <SkillTagDrawer
        skillId={infoSkillId}
        visible={infoVisible}
        onDeleteCallback={(skillId) => {
          onDeleteCallback(skillId);
        }}
        onClose={() => {
          setInfoVisible(false);
          setInfoSkillId(undefined);
        }}
        width={715}
      />
    </div>
  );
};

export default SkillTags;
