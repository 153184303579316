/* eslint-disable import/no-cycle */
import { Button, Col, Divider, Input, Popover, Row, Select, Space } from 'antd';
import React, { useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import buttons from 'helpers/styles/components/buttons.module.sass';
import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';
import { WelcomePageResponseError } from 'api/PersonalInfo/types';
import inputs from 'helpers/styles/components/inputs.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import useApi from 'api/hooks/useApi/useApi';
import { getDaDataLocation } from 'api/DaData/requests';
import { DaDataAddressResponse } from 'api/DaData/types';
import { TimezonesList, TIMEZONES_LABEL, TIMEZONES_SHORT } from 'api/DaData/timezones';
import { WelcomePageFields } from 'components/WelcomeTrackerPage';

import s from './helpers/styles.module.sass';

interface WelcomeProfileFillingPageProps {
  fields: WelcomePageFields | undefined;
  errors: WelcomePageResponseError;
  loading: boolean;
  onConfirm: () => void;
  onChangeField: (value: string | number, field: keyof WelcomePageFields) => void;
}

const WelcomeProfileFillingPage: React.FC<WelcomeProfileFillingPageProps> = ({ fields, loading, onChangeField, errors, onConfirm }) => {
  const [ searchValue, setSearchValue ] = useState('');

  const [ getLocationBySearch, locationList ] = useApi<typeof getDaDataLocation, DaDataAddressResponse[]>(getDaDataLocation);

  const onSearchCity = _.debounce((address: string) => {
    if (!address && fields?.location) {
      getLocationBySearch(fields?.location);
      setSearchValue(address);
      return;
    }

    setSearchValue(address);
    getLocationBySearch(address);
  }, 500);

  const renderCurrentLocation = () => {
    const cityOptions = _.map(locationList, (l) => {
      const value = `${l?.data?.country}, ${l?.data?.city}`;
      return (
        <Select.Option
          value={value}
          key={l?.data?.geonameId}
        >
          {value}
        </Select.Option>
      );
    });

    const handleOnFocus = () => {
      if (fields?.location) {
        getLocationBySearch(fields?.location);
      }
    };

    const notFoundContent = (
      <div>
        <span>No results</span>
        {
          searchValue && (
            <>
              <Divider style={{ margin: '2px 0' }} />
              <Button
                type="text"
                className={s.addNewLocationButton}
                onClick={() => {
                  onChangeField(searchValue, 'location');
                  setSearchValue('');
                }}
              >
                <span>
                  {searchValue}
                  <FontAwesomeIcon icon="plus" />
                </span>
              </Button>
            </>
          )
        }
      </div>
    );

    return (
      <Select
        placeholder="Enter your current location"
        className={cx(selects.qsSelect, s.welcomePageSelect)}
        dropdownClassName={selects.selectDropdown}
        bordered={false}
        size="large"
        onChange={value => onChangeField(value, 'location')}
        onSearch={onSearchCity}
        showSearch
        onFocus={handleOnFocus}
        filterOption={() => true}
        value={fields?.location}
        showArrow={false}
        notFoundContent={notFoundContent}
      >
        {cityOptions}
      </Select>
    );
  };

  const renderError = (field: keyof WelcomePageFields) => {
    const errorMessage = errors[field];

    return (
      <div className={s.errorWrapper}>
        <small>{errorMessage ? errorMessage[0] : null}</small>
      </div>
    );
  };

  const renderTimezone = () => {
    const timezoneOptions = (timezoneList: TimezonesList) => _.map(timezoneList, t => (
      <Select.Option
        value={t.value}
        key={t.label}
        title={t.label}
      >
        {t.label}
      </Select.Option>
    ));

    return (
      <Select
        placeholder="Choose timezone"
        size="large"
        className={cx(selects.qsSelect, s.welcomePageSelect)}
        value={fields?.timezoneUtcOffset}
        onChange={value => onChangeField(value, 'timezoneUtcOffset')}
        dropdownClassName={selects.selectDropdown}
        suffixIcon={<SelectIcon />}
        bordered={false}
      >
        <Select.OptGroup label="Popular">
          {timezoneOptions(_.pick(TIMEZONES_SHORT, [ TIMEZONES_LABEL.moscow, TIMEZONES_LABEL.novosibirsk ]))}
        </Select.OptGroup>
        <Select.OptGroup label="Others">
          {timezoneOptions(_.omit(TIMEZONES_SHORT, [ TIMEZONES_LABEL.moscow, TIMEZONES_LABEL.novosibirsk ]))}
        </Select.OptGroup>
      </Select>
    );
  };

  return (
    <div className={s.welcomePageWrapper}>
      <div>
        <TrackerLogo className={s.trackerLogo} />
      </div>
      <Space className={s.welcomePageBody} direction="vertical" split={<div className={s.splitter} />} size={0}>
        <Space direction="vertical" className={s.welcomePageHeader} size={18}>
          <h1>Welcome to Time Tracker</h1>
          <p>Please fillout your profile to continue</p>
        </Space>
        <div>
          <Row gutter={[ 11, 20 ]}>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>First Name</span>
                <Input
                  onChange={e => onChangeField(e.currentTarget.value, 'forename')}
                  style={{ textOverflow: 'ellipsis' }}
                  placeholder="Enter first name"
                  size="large"
                  value={fields?.forename}
                  className={cx(inputs.qsInput, s.welcomePageInput, { [s.error]: errors.forename })}
                />
                {renderError('forename')}
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Last Name</span>
                <Input
                  onChange={e => onChangeField(e.currentTarget.value, 'surname')}
                  placeholder="Enter last name"
                  size="large"
                  value={fields?.surname}
                  className={cx(inputs.qsInput, s.welcomePageInput, { [s.error]: errors.surname })}
                />
                {renderError('surname')}
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Position</span>
                <Select
                  placeholder="Choose your position"
                  size="large"
                  onChange={value => onChangeField(value, 'occupation')}
                  className={cx(selects.qsSelect, s.welcomePageSelect)}
                  value={fields?.occupation ? OCCUPATIONS[fields?.occupation]?.name : undefined}
                  dropdownClassName={selects.selectDropdown}
                  suffixIcon={<SelectIcon />}
                  bordered={false}
                >
                  { _.map(OCCUPATIONS, (o, k) => <Select.Option value={k} key={o.name}>{o.name}</Select.Option>) }
                </Select>
              </Space>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[ 11, 20 ]}>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <Space>
                  <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Skype</span>
                  <Popover
                    content="Skype profile > Skype Name"
                  >
                    <FontAwesomeIcon icon="question-circle" className={s.questionMarkInfo} />
                  </Popover>
                </Space>

                <Input
                  placeholder="Enter skype name"
                  size="large"
                  value={fields?.skypeId}
                  onChange={e => onChangeField(e.currentTarget.value, 'skypeId')}
                  className={cx(inputs.qsInput, s.welcomePageInput, { [s.error]: errors.skypeId })}
                />
                {renderError('skypeId')}
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Mobile</span>
                <Input
                  type="tel"
                  placeholder="Enter mobile number"
                  size="large"
                  value={fields?.phone}
                  onChange={e => onChangeField(e.currentTarget.value, 'phone')}
                  className={cx(inputs.qsInput, s.welcomePageInput)}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[ 11, 20 ]}>
            <Col span={24} xl={16}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Current location</span>
                { renderCurrentLocation() }
              </Space>
            </Col>
            <Col span={24} xl={8}>
              <Space direction="vertical" size={4} className={s.welcomePageFieldWrapper}>
                <span className={cx(s.welcomePageFieldTitle, { [s.required]: true })}>Timezone</span>
                { renderTimezone() }
              </Space>
            </Col>
          </Row>
        </div>
        <div>
          <Button
            size="large"
            type="primary"
            className={cx(buttons.qsButtonPrimary, s.welcomePageConfirmButton)}
            onClick={onConfirm}
            loading={loading}
            disabled={
              _.isEmpty(fields?.forename)
              || _.isEmpty(fields?.occupation)
              || _.isEmpty(fields?.phone)
              || _.isEmpty(fields?.skypeId)
              || _.isEmpty(fields?.surname)
              || _.isEmpty(fields?.location)
              || !_.isNumber(fields?.timezoneUtcOffset)
            }
          >
            Confirm
          </Button>
        </div>
      </Space>
    </div>
  );
};

export default WelcomeProfileFillingPage;
