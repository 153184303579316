import React from 'react';
import { Dayjs } from 'dayjs';
import { DatePicker, Space, Typography } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import cx from 'classnames';

import datepicker from 'helpers/styles/components/datepicker.module.sass';
import { ReactComponent as DateRangeIcon } from 'helpers/icons/dateRange.svg';
import BusinessMonthSelector from 'components/_shared/BusinessMonthSelector/BusinessMonthSelector';
import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT, RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { BusinessMonthRangeOutput } from 'api/BusinessCalendar/types';

import s from './styles.module.sass';
import { UserHourReportState } from '../../helpers/types';

interface HourReportSettingsProps {
  setSettingsValues: React.Dispatch<React.SetStateAction<UserHourReportState>>;
  settingsValues: UserHourReportState;
  isBCLoading: boolean;
  handleOnChangeMonth: (date: string) => Promise<BusinessMonthRangeOutput>;
}

const HourReportSettings: React.FC<HourReportSettingsProps> = (props) => {
  const {
    isBCLoading, settingsValues,
    setSettingsValues, handleOnChangeMonth,
  } = props;

  const onChangeMonth = async (dayjsObj: Dayjs) => {
    const date = dayjsObj.format(MONTH_YEAR_FORMAT);

    setSettingsValues(({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: date,
      },
    }));

    const rangeDatesForMonth = await handleOnChangeMonth(date);

    setSettingsValues(prevSettingsValue => ({
      ...prevSettingsValue,
      dates: {
        ...prevSettingsValue.dates,
        selectedDate: date,
        startDateRange: rangeDatesForMonth?.startDay,
        endDateRange: rangeDatesForMonth?.endDay,
        modifiedMonth: rangeDatesForMonth?.modified,
      },
    }));
  };

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        startDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        endDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
  };

  return (
    <div className={s.reportSettingsLayout}>
      <BusinessMonthSelector
        className={s.reportMonthPicker}
        isModified={settingsValues.dates.modifiedMonth}
        onChangeMonth={onChangeMonth}
        selectedDate={settingsValues.dates.selectedDate}
        isLoading={isBCLoading}
        isCustomDate={_.isNull(settingsValues.dates.selectedDate)}
      />

      <Space>
        <DatePicker
          className={datepicker.singleDatepicker}
          onChange={handleStartDateChange}
          disabledDate={currentDate => currentDate > moment(settingsValues.dates.endDateRange)}
          value={moment(settingsValues.dates.startDateRange)}
          format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
          allowClear={false}
          size="large"
          suffixIcon={<DateRangeIcon />}
          disabled={isBCLoading}
        />

        <Typography.Text className={cx({ [s.datepickerDividerLoading]: isBCLoading })}>—</Typography.Text>

        <DatePicker
          disabled={isBCLoading}
          className={datepicker.singleDatepicker}
          onChange={handleEndDateChange}
          disabledDate={currentDate => currentDate < moment(settingsValues.dates.startDateRange)}
          value={moment(settingsValues.dates.endDateRange)}
          format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
          allowClear={false}
          size="large"
          suffixIcon={<DateRangeIcon />}
        />
      </Space>
    </div>
  );
};

export default HourReportSettings;
