import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';
import Addon from './Addon';

const cx = classNames.bind(S);

interface FormInputGroupComponentProps {
  alignment?: string;
  children: React.ReactElement;
  className?: string;
}

class InputGroup extends React.Component<FormInputGroupComponentProps> {
  public static Addon = Addon;

  render(): React.ReactNode {
    const { alignment, children, className } = this.props;
    const isAlignmentExists = Boolean(
      S['qs-form__input-group--alignment-' + alignment]
    );

    const props = {
      ...this.props,
      className: cx(
        {
          'qs-form__input-group': true,
          ['qs-form__input-group--alignment-' + alignment]: isAlignmentExists
        },
        className
      )
    };

    return <div {...props}>{children}</div>;
  }
}

export default InputGroup;
