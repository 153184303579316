import _ from 'lodash';

import { UsersHourResponse } from 'api/TimeSheet/types';

import { FlatUserHours } from '../../../../../../Table/components/TableBody/helpers/types';

export interface TimesheetActivitiesHours {
  [partnerId: number]: UsersHourResponse;
}

export const selectedActivitiesByHours = (flatUserHoursTable: FlatUserHours) => _.reduce(flatUserHoursTable, (acc, v) => {
  if (!v || v?.cutDay) {
    return acc;
  }

  return {
    ...acc,
    [v.activity.activityId]: acc[v.activity.activityId]
      ? { ...acc[v.activity.activityId], hours: acc[v.activity.activityId].hours + v.hours }
      : { ...v, hours: v.hours },
  };
}, {} as TimesheetActivitiesHours);
