export const CHANGE_MONTH = '[DASHBOARD] CHANGE MONTH';
export const CHANGE_YEAR = '[DASHBOARD] CHANGE YEAR';
export const CHANGE_MONTH_YEAR = '[DASHBOARD] CHANGE MONTH YEAR';
export const CHANGE_PARTNER = '[DASHBOARD] CHANGE PARTNER';
export const TIME_TRACKER_INITIATE = '[DASHBOARD] TIME TRACKER INITIATE';
export const PREVIOUS_MONTH = '[DASHBOARD] PREVIOUS MONTH';
export const NEXT_MONTH = '[DASHBOARD] NEXT MONTH';
export const CHANGE_PANEL_YEAR = '[DASHBOARD] CHANGE PANEL YEAR';
export const DROP_PARTNER_SELECTION = '[DASHBOARD] DROP PARTNER SELECTION';
export const CHANGE_FAVOURITE_ACTIVITY = '[DASHBOARD] CHANGE FAVOURITE ACTIVITY';
export const LOAD_FAVOURITES_TO_PANEL = '[DASHBOARD] LOAD FAVOURITES TO PANEL';
export const DROP_LOCAL_FAVOURITES = '[DASHBOARD] DROP LOCAL FAVOURITES';
export const SHOW_MONTH_LIST = '[DASHBOARD] SHOW MONTH LIST';

export const CHECK_CURRENT_MONTH = '[DASHBOARD] CHECK CURRENT MONTH';

export const CLOSE_CURRENT_MONTH = '[DASHBOARD] CLOSE CURRENT MONTH';
