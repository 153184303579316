/*
  Using for default skills, provided from
  Administration -> Skill Tags -> {onRowClick} -> Drawer -> Show all
 */
export const DEFAULT_SPECIALISTS_SKILLS = 'defaultSpecialistsSkills';

/*
  Using for carry url before login to be able redirect on the same route after login
 */
export const REDIRECT_URL = 'redirectUrl';

/*
  Using for carry selected partner in Specialists/request page for partner list.
 */
export const REQUEST_SELECTED_PARTNER = 'rememberedPartner';
