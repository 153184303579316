import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useDidMountEffect = (func: EffectCallback, deps: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();

      return;
    }

    didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
