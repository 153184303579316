import React, { useState } from 'react';

import s from './styles.module.sass';
import TrackerLogo from '../../_shared/TrackerLogo/TrackerLogo';

interface TableAvatarProps {
  url?: string;
  size?: number;
  alt?: string
}

const TableAvatar: React.FC<TableAvatarProps> = ({ url, size = 32, alt = 'avatar' }) => {
  const [ isLoadedWithError, setLoadedWithError ] = useState(false);

  return (
    url && !isLoadedWithError
      ? (
        <img
          src={url}
          alt={alt}
          onError={() => setLoadedWithError(true)}
          className={s.authorAvatar}
          width={size}
          height={size}
        />
      )
      : <TrackerLogo style={size ? { height: size || 26, width: size || 26 } : {}} />
  );
};

export default TableAvatar;
