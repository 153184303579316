import _ from 'lodash';

import { SkillTagProps } from '../../api/SkillTags/types';

export const getIdsFromSelectedRows = (skillRows: SkillTagProps[]) => _.reduce(skillRows, (acc, value) => {
  if (!value.checked) {
    return acc;
  }

  return [
    ...acc,
    value.skillId,
  ];
}, [] as number[]);
