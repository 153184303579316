import { NotificationsRedux } from './types';
import * as Actions from '../../V1/constants/Notification';

const InitialState: NotificationsRedux = {
  multipliers: undefined,
  notifications: undefined,
  skillsetIsOutdated: undefined,
  vacancies: undefined,
  loading: true,
};

const NotificationReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case Actions.GET_UNREAD_FIELDS:
      return {
        ...state,
        ...action.payload,
        loading: action.loading || false,
      };

    default:
      return state;
  }
};

export default {
  InitialState,
  NotificationReducer,
};
