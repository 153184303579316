import dayjs from 'dayjs';

import * as Actions from './constants';
import { BusinessCalendarRedux } from './types';

const now = dayjs();

const InitialState: BusinessCalendarRedux = {
  selectedYear: now.get('year') || new Date().getFullYear(),
};

const BusinessCalendarReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;

    case Actions.CHANGE_SELECTED_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };

    default:
      return state;
  }
};

export default {
  InitialState,
  reducer: BusinessCalendarReducer,
};
