import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Checkbox, DatePicker, Input, Space } from 'antd';
import moment from 'moment';
import cx from 'classnames';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT, RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { getBusinessMonthByDate, getCalendarMonthRange } from 'api/BusinessCalendar/requests';
import { BusinessMonthRangeOutput } from 'api/BusinessCalendar/types';
import { Redux } from 'helpers/types/_common';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as ReplaceToolIcon } from 'helpers/icons/Buttons/flip_camera__24dp.svg';
import datepicker from 'helpers/styles/components/datepicker.module.sass';
import { ReactComponent as DateRangeIcon } from 'helpers/icons/dateRange.svg';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import BusinessMonthSelector from 'components/_shared/BusinessMonthSelector/BusinessMonthSelector';

import { TimeRangeType, UserHourReportsSettingsState } from '../../types';
import s from './styles.module.sass';
import GeneralFiltersButton from '../../../GeneralFiltersButton/GeneralFiltersButton';

interface UserHourReportsSettingsProps {
  setSettingsValues: React.Dispatch<React.SetStateAction<UserHourReportsSettingsState>>;
  settingsValues: UserHourReportsSettingsState;
  typeOfTimeRange: TimeRangeType;
  setTypeOfTimeRange: (type: TimeRangeType) => void;
  openNotificationModal: () => void;
  openReplaceTool: () => void;
}

const UserHourReportsSettings: React.FC<UserHourReportsSettingsProps> = (props) => {
  const { setSettingsValues, settingsValues, openReplaceTool, setTypeOfTimeRange } = props;
  const [ bcLoading, setBcLoading ] = useState(false);

  const { userId } = useSelector((state: Redux) => state.login);

  useEffect(() => {
    setBcLoading(true);

    getBusinessMonthByDate(dayjs().format(DEFAULT_DATE_FORMAT))
      .then((r: BusinessMonthRangeOutput) => {
        setSettingsValues(prevValues => ({
          ...prevValues,
          dates: {
            ...prevValues.dates,
            selectedDate: `${r.year}-${r.month}`,
            startDateRange: r.startDay,
            endDateRange: r.endDay,
            modifiedMonth: r.modified,
          },
        }));
      })
      .finally(() => { setBcLoading(false); });
  }, []);

  const handleOnChangeMonth = (date: string) => {
    setBcLoading(true);
    setTypeOfTimeRange('month');

    return getCalendarMonthRange(userId, date)
      .then((response: BusinessMonthRangeOutput) => response)
      .catch(() => ({
        businessMonthId: undefined,
        startDay: dayjs(date).startOf('month').format(DEFAULT_DATE_FORMAT),
        endDay: dayjs(date).endOf('month').format(DEFAULT_DATE_FORMAT),
        year: dayjs(date).year(),
        month: dayjs(date).month() + 1,
        modified: false,
      }))
      .finally(() => setBcLoading(false));
  };

  const onChangeMonth = async (dayjsObj: Dayjs) => {
    const date = dayjsObj.format(MONTH_YEAR_FORMAT);

    setSettingsValues(({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: date,
      },
    }));

    const rangeDatesForMonth = await handleOnChangeMonth(date);

    setSettingsValues(prevSettingsValue => ({
      ...prevSettingsValue,
      dates: {
        ...prevSettingsValue.dates,
        selectedDate: date,
        startDateRange: rangeDatesForMonth?.startDay,
        endDateRange: rangeDatesForMonth?.endDay,
        modifiedMonth: rangeDatesForMonth?.modified,
      },
    }));
  };

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        startDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
    setTypeOfTimeRange('custom');
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setSettingsValues({
      ...settingsValues,
      dates: {
        ...settingsValues.dates,
        selectedDate: null,
        endDateRange: date.format(DEFAULT_DATE_FORMAT),
      },
    });
    setTypeOfTimeRange('custom');
  };

  const handleOnSearchEmployee = _.debounce((value) => {
    setSettingsValues({
      ...settingsValues,
      searchValue: value,
    });
  }, 1000);

  const handleStatusCheckbox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    setSettingsValues({
      ...settingsValues,
      periodIsClosed: checked ? false : undefined,
    });
  };

  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.employeeHourReportSearch)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      size="large"
      onChange={e => handleOnSearchEmployee(e.currentTarget.value)}
      allowClear
      placeholder="Search employees..."
    />
  );

  return (
    <div className={s.userReportLayoutSettings}>
      <div className={s.userReportLayoutSettingsTop}>
        <Space size={32}>
          <BusinessMonthSelector
            className={s.reportMonthPicker}
            isModified={settingsValues.dates.modifiedMonth}
            onChangeMonth={onChangeMonth}
            selectedDate={settingsValues.dates.selectedDate}
            isLoading={bcLoading}
            isCustomDate={_.isNull(settingsValues.dates.selectedDate)}
          />
          <Checkbox
            onChange={handleStatusCheckbox}
            disabled={bcLoading}
          >
            Show employees with not closed period
          </Checkbox>
        </Space>

        <div className={s.customDatesWrapper}>
          <DatePicker
            className={datepicker.singleDatepicker}
            onChange={handleStartDateChange}
            disabledDate={currentDate => currentDate > moment(settingsValues.dates.endDateRange)}
            value={moment(settingsValues.dates.startDateRange)}
            format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
            allowClear={false}
            size="large"
            suffixIcon={<DateRangeIcon />}
            disabled={bcLoading}
          />

          <span className={cx(datepicker.datepickerSplitter, { [s.datepickerDividerLoading]: bcLoading })}>—</span>

          <DatePicker
            disabled={bcLoading}
            className={datepicker.singleDatepicker}
            onChange={handleEndDateChange}
            disabledDate={currentDate => currentDate < moment(settingsValues.dates.startDateRange)}
            value={moment(settingsValues.dates.endDateRange)}
            format={[ RU_DOT_DATE_FORMAT, DEFAULT_DATE_FORMAT ]}
            allowClear={false}
            size="large"
            suffixIcon={<DateRangeIcon />}
          />

          {renderInputSearch()}

          <GeneralFiltersButton
            onSearch={(ids) => {
              setSettingsValues({ ...settingsValues, ...ids });
            }}
          />

          <Button
            className={cx(buttons.qsButton, s.replaceButton)}
            size="large"
            onClick={openReplaceTool}
          >
            <ReplaceToolIcon />
            Replace tool
          </Button>
        </div>

      </div>
    </div>
  );
};

export default UserHourReportsSettings;
