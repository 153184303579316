import { SKILLS_EXPERIENCE_MAPPER, SKILLS_EXPERIENCE_MAPPER_RAW } from '../../../api/Specialists/constants';
import { SkillExperiencesConstant } from '../../types/Skills';

const SKILL_EXPERIENCES: SkillExperiencesConstant = {
  PET_PROJECTS: {
    experienceId: 1,
    label: 'Pet Project',
    name: 'Less than 1 year',
    secondName: 'Learning',
    shortName: '<1Y',
    color: '#79986A',
  },
  COMMERCIAL_USE: {
    experienceId: 2,
    label: 'Commercial Use',
    name: '1+ year',
    shortName: '1Y+',
    color: '#6280AC',
  },
  CONFIDENT: {
    experienceId: 3,
    label: 'Confident',
    name: '3+ years',
    shortName: '3Y+',
    color: '#8D6E9B',
  },
  LEVEL_80: {
    experienceId: 4,
    label: '80 lvl',
    name: '5+ years',
    shortName: '5Y+',
    color: '#9A8A61',

  },
};

export const SKILL_EXPERIENCES_COLOR = {
  [SKILLS_EXPERIENCE_MAPPER_RAW.ANY]: '#263341',
  [SKILLS_EXPERIENCE_MAPPER_RAW.PET_PROJECTS]: '#79986A',
  [SKILLS_EXPERIENCE_MAPPER_RAW.COMMERCIAL_USE]: '#6280AC',
  [SKILLS_EXPERIENCE_MAPPER_RAW.CONFIDENT]: '#8D6E9B',
  [SKILLS_EXPERIENCE_MAPPER_RAW.LEVEL_80]: '#9A8A61',
};

export const SKILL_EXPERIENCES_COLOR_RAW = {
  [SKILLS_EXPERIENCE_MAPPER.petProjects]: '#79986A',
  [SKILLS_EXPERIENCE_MAPPER.commercialUse]: '#6280AC',
  [SKILLS_EXPERIENCE_MAPPER.confident]: '#8D6E9B',
  [SKILLS_EXPERIENCE_MAPPER.level80]: '#9A8A61',
};

export default SKILL_EXPERIENCES;
