/* eslint-disable import/no-cycle */
import { Button, Space } from 'antd';
import React from 'react';
import cx from 'classnames';

import buttons from 'helpers/styles/components/buttons.module.sass';
import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';
import { Steps } from 'components/WelcomeTrackerPage';

import s from './helpers/styles.module.sass';

interface WelcomeLocationPageProps {
  setPrefStep: (newStep: Steps) => void;
  onLocationAccessGranted: () => void;
}

const WelcomeLocationPage: React.FC<WelcomeLocationPageProps> = ({ setPrefStep, onLocationAccessGranted }) => (
  <div className={s.locationPageWrapper}>
    <TrackerLogo className={s.trackerLogo} />
    <Space direction="vertical" className={s.bodyWrapper}>
      <Space direction="vertical" className={s.textWrapper}>
        <h1 className={s.locationTitle}>Time Tracker needs an access to your location</h1>
        <p className={s.primaryText}>
          We need this to automatically set your timezone and current city.
          Time Tracker is not logging or storing your exact location or your movement history
        </p>
      </Space>

      <div className={s.splitter} />

      <div className={s.buttonGroupWrapper}>
        <Button
          type="text"
          className={s.cancelButton}
          size="large"
          onClick={() => setPrefStep('profileFilling')}
        >
          Skip for later
        </Button>
        <Button
          type="primary"
          size="large"
          className={cx(buttons.qsButtonPrimary, s.allowButton)}
          onClick={onLocationAccessGranted}
        >
          Allow access to location
        </Button>
      </div>
    </Space>
  </div>
);

export default WelcomeLocationPage;
