import dayjs from 'dayjs';
import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

import { BCJointStatus, BCStatuses } from 'api/TimeSheet/types';
import { GroupOfCells } from 'components/TimeSheet/helpers/types';
import { ReactComponent as SunIconSmall } from 'helpers/icons/sunIconSmall.svg';

import S from './helpers/styles.module.sass';
import { TimeSheetTableHeaderColumnProps } from './helpers/types';

const ColumnHeader: React.FC<TimeSheetTableHeaderColumnProps> = ({
  day,
  prevDayObj,
  status,
  jointStatus,
  isMonthClosed,
  userHoursColumn,
  isBCLoading,
  isToday,
}) => {
  const isWeekend = status !== BCStatuses.Regular;
  const isAfterWeekend = prevDayObj && prevDayObj.status !== BCStatuses.Regular;
  const isHoliday = status === BCStatuses.Holiday;
  const isCut = jointStatus === BCJointStatus.Cut;
  const isAfterCut = prevDayObj && prevDayObj.jointStatus === BCJointStatus.Cut;

  const renderHours = () => {
    const alreadyWorkedHalfHours = _.reduce(userHoursColumn?.cells, (result, groupObj) => result + ((groupObj as GroupOfCells)?.groupCells?.length || 0), 0);
    const alreadyWorkedHours = userHoursColumn ? alreadyWorkedHalfHours / 2 : 0;

    return Boolean(alreadyWorkedHalfHours) && (
      <div className={cx(S.workedHoursWrapper, 'workedHoursWrapper')}>
        <span className={cx(S.workedHours, 'workedHours')}>{alreadyWorkedHours}</span>
      </div>
    );
  };

  return (
    <div className={cx(S.columnHeader, {
      [S.weekend]: isWeekend && !isCut,
      [S.afterWeekend]: isAfterWeekend && !isAfterCut,
      [S.closedMonth]: isMonthClosed,
      [S.today]: isToday,
      [S.cutDay]: isCut,
      [S.afterCut]: isAfterCut,
    })}
    >
      { isHoliday && <SunIconSmall className={S.holidayIcon} /> }
      <span className={S.dayNumber}>{dayjs(day).format('D')}</span>
      <span className={S.dayName}>{dayjs(day).format('ddd')}</span>
      {!isBCLoading && (
        <div className={S.additionalInfo}>
          {renderHours()}
        </div>
      )}
    </div>
  );
};

export default ColumnHeader;
