import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import BasicTable from 'components/_shared/Table';
import { DispatchType, Redux } from 'helpers/types/_common';
import { getBookkeepingItems } from 'redux/BookkeepingItems/actions';
import { BookkeepingItem } from 'redux/Tables/reducerTypes';
import { BookkeepingItemsRequestBody } from 'api/BookkeepingItems/types';

import COLUMNS from './columns';
import s from './styles.module.sass';
import { getBookkeepingItemType, renderActiveStatus, renderDescription, renderName, renderTime } from './helpers';

const BookkeepingItemsTable: React.FC<{}> = () => {
  const dispatch: DispatchType = useDispatch();
  const { objects, loading } = useSelector((state: Redux) => state.tables[TABLE_NAMES.BOOKKEEPING_ITEMS]);

  const getBookkeepingItemsSearch = (data: BookkeepingItemsRequestBody) => {
    if (data.query) return dispatch(getBookkeepingItems(data, TABLE_NAMES.BOOKKEEPING_ITEMS));
  };

  const renderColumns = {
    type: {
      render: (text: undefined, record: BookkeepingItem) => <span className={s.searchItemType}>{_.upperFirst(getBookkeepingItemType(record) || '-')}</span>,
    },
    name: {
      render: (text: undefined, record: BookkeepingItem) => renderName(record),
    },
    description: {
      render: (text: undefined, record: BookkeepingItem) => renderDescription(record),
    },
    active: {
      render: (text: undefined, record: BookkeepingItem) => renderActiveStatus(record),
    },
    creationDate: {
      render: (text: undefined, record: BookkeepingItem) => <span className={s.limited}>{renderTime(record, 'creationDate')}</span>,
    },
    lastUpdate: {
      render: (text: undefined, record: BookkeepingItem) => <span className={s.limited}>{renderTime(record, 'lastUpdate')}</span>,
    },
  };

  return (
    <div className={s.bookkeepingItemsTable}>
      <BasicTable
        bordered
        rowKey="searchItemId"
        initialRequest={false}
        getData={getBookkeepingItemsSearch}
        pagination={false}
        renderColumns={renderColumns}
        tableName={TABLE_NAMES.BOOKKEEPING_ITEMS}
        dataSource={objects}
        loading={loading}
        columns={COLUMNS}
      />
    </div>
  );
};

export default BookkeepingItemsTable;
