import { Column } from 'components/_shared/Table/helpers/types';

export const PARTNERS_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'partnerId',
    sorter: true,
    key: 'partnerId',
    width: 125,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Note',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Requested by',
    dataIndex: 'requestedBy',
    key: 'requestedBy',
    width: 200,
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 200,
  },
  {
    title: 'Hrs/Month',
    dataIndex: 'currentMonthHours',
    key: 'currentMonthHours',
    sorter: true,
    width: 50,
  },
  {
    title: 'Created on',
    dataIndex: 'creationDate',
    key: 'creationDate',
    className: 'borderless',
    sorter: true,
    width: 150,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    width: 30,
    className: 'borderless action-column',
  },
];
