import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import popover from 'helpers/styles/components/popover.module.sass';
import { updatePartner } from 'api/Partners/requests';
import { DispatchType } from 'helpers/types/_common';
import { setError } from 'redux/ErrorHandler/actions';

import { SkillTagsPopoverProps } from './helpers/types';
import s from './helpers/styles.module.sass';
import PartnerRenameModal from './components/PartnerRenameModal/PartnerRenameModal';

const PartnersThreeDotsPopover: React.FC<SkillTagsPopoverProps> = ({ record, refreshTable }) => {
  const [ isPopoverVisible, setPopoverVisible ] = useState(false);
  const [ isRenameModalVisible, setRenameModalVisible ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);

  const dispatch: DispatchType = useDispatch();

  const handleOnRenameClick = () => {
    setRenameModalVisible(true);
    setPopoverVisible(false);
  };
  
  const handleOnStatusChangeClick = () => {
    setLoading(true);

    updatePartner({
      partnerId: record.partnerId,
      data: { active: !record.active },
      requestParams: {
        errorObject: {
          400: {
            hidden: false,
            description: (error: any) => error.response.data.errorsMapping.active,
          },
        },
      },
    })
      .then(() => {
        refreshTable();
      })
      .finally(() => {
        setLoading(false);
      });

    setPopoverVisible(false);
  };

  const onVisibleChangeHandler = (visible: boolean) => {
    setLoading(false);
    setPopoverVisible(visible);
  };

  const savePartnerRenaming = (newPartnerName: string) => {
    if (newPartnerName.length < 2) {
      dispatch(setError({ error: 'Partner name is required field' }));

      return new Promise((resolve, reject) => reject());
    }

    setLoading(true);
    
    return updatePartner({
      partnerId: record.partnerId,
      data: { name: newPartnerName },
    })
      .then(() => {
        refreshTable();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderPopoverContent = () => (
    <div className={popover.dotsPopoverItemWrapper}>
      <Button className={classNames(popover.dotsPopoverItem, s.popoverButton)} onClick={handleOnRenameClick}>
        Rename
      </Button>
      <Button className={classNames(popover.dotsPopoverItem, s.popoverButton)} onClick={handleOnStatusChangeClick}>
        {record.active ? 'Deactivate' : 'Activate'}
      </Button>
    </div>
  );

  return (
    <div className={classNames(popover.dotsPopover, s.threeDotsPopover)}>
      <Popover
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={onVisibleChangeHandler}
        content={renderPopoverContent()}
      >
        <div className="three-dots-button">
          <Button type="text" size="small" className="action-column-button">
            {
              isLoading
                ? <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin />
                : <FontAwesomeIcon className={s.skillTagRowIcon} icon="ellipsis-v" />
            }
          </Button>
        </div>
      </Popover>
      <PartnerRenameModal
        record={record}
        isVisible={isRenameModalVisible}
        onClose={() => setRenameModalVisible(false)}
        onOk={savePartnerRenaming}
      />
    </div>
  );
};

export default PartnersThreeDotsPopover;
