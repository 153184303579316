import React from 'react';
import classNames from 'classnames/bind';
import S from './helpers/styles.module.css';

const cx = classNames.bind(S);

interface Props {
  onClick?: (event?: any) => void;
  className?: string;
  style?: object;
  type?: string;
  buttonType?: 'submit' | 'reset' | 'button';
  size?: string;
  disabled?: boolean;
  href?: string;
  title?: string;
  isLoading?: boolean;
  icon?: React.ReactElement;
  loadingIcon?: React.ReactElement;
}

export default class Button extends React.Component<Props> {
  render(): React.ReactNode {
    const {
      children,
      className,
      onClick,
      style,
      type,
      icon,
      loadingIcon,
      buttonType,
      size,
      disabled,
      href,
      title,
      isLoading
    } = this.props;
    const sizeExists = Boolean(size && S[`qs-button--size-${size}`]);
    const typeExists = Boolean(type && S[`qs-button--${type}`]);

    const buttonClass = cx(className, {
      'qs-button': true,
      'qs-button--default': !type,
      'qs-button--loading': isLoading,
      'qs-button--disabled': disabled,
      [`qs-button--${type}`]: typeExists,
      [`qs-button--size-${size}`]: sizeExists
    });

    if (href) {
      return (
        <a href={href} onClick={onClick} style={style} className={buttonClass}>
          {children}
        </a>
      );
    } else {
      return (
        <button
          type={buttonType}
          onClick={onClick}
          style={style}
          title={title}
          className={buttonClass}
          disabled={disabled || isLoading}
        >
          {isLoading ? (loadingIcon || icon) : icon}
          <span>
             {children}
          </span>
        </button>
      );
    }
  }
}
