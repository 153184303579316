export interface DefaultPaginationRaw {
  limit: number;
  offset: number;
}

export const DEFAULT_PAGINATION_RAW = {
  limit: 20,
  offset: 0,
  totalCount: undefined,
};

export const INCREASED_PAGINATION_RAW = {
  limit: 40,
  offset: 0,
};

export const DEFAULT_SELECT_PAGINATION = {
  meta: {
    limit: 40,
    offset: 0,
  },
  objects: [],
};
