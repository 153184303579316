import React from 'react';
import className from 'classnames/bind';
import _ from 'lodash';
import S from '../../styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const cx = className.bind(S);

interface ContextMenuElement {
  label: string;
  onClick?: (value?: any) => void;
  hideOn?: boolean;
  iconName?: IconName;
  disableOn?: boolean;
}

interface ContextMenu {
  [key: string]: ContextMenuElement;
}

interface TableBodyCellComponentProps {
  type?: string;
  className?: string;
  title?: string;
  colSpan?: number;
  disabled?: boolean;
  showButtonOnHover?: boolean;
  onClick?: () => void;
  contextMenu?: ContextMenu;
  S?: React.CSSProperties;
  hideContextOn?: boolean;
}

interface TableBodyCellComponentState {
  contextMenuIsShowed: boolean;
}

export default class TableBodyCell extends React.Component<
  TableBodyCellComponentProps,
  TableBodyCellComponentState
> {
  private parent = React.createRef<HTMLDivElement>();

  constructor(props: TableBodyCellComponentProps) {
    super(props);

    this.state = {
      contextMenuIsShowed: false
    };

    this.showContextMenu = this.showContextMenu.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  showContextMenu(): void {
    this.setState({ contextMenuIsShowed: true });
    document.addEventListener('mousedown', this.clickOutside);
  }

  hideContextMenu(): void {
    this.setState({ contextMenuIsShowed: false });
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(e: Event): void {
    const parent = this.parent.current;

    if (parent && !parent.contains(e.target as HTMLElement)) {
      this.hideContextMenu();
    }
  }

  handleClick(e: React.MouseEvent): void {
    if (this.props.onClick) {
      _.invoke(this.props, 'onClick', e);
    }
  }

  renderContextMenu(): Array<React.ReactNode> {
    let array: Array<string> = [];
    let resultArray: Array<React.ReactNode> = [];
    if (this.props.contextMenu) {
      array = Object.keys(this.props.contextMenu);
    }
    if (array.length) {
      resultArray = array.map(
        (menuElementKey: string, i: number): React.ReactNode => {
          if (this.props.contextMenu) {
            const menuElement = this.props.contextMenu[menuElementKey];
            if (menuElement) {
              return (
                <div
                  key={i}
                  className={cx('table-cell-context-menu__element', {
                    'table-cell-context-menu__element--disabled':
                      menuElement.disableOn
                  })}
                  onClick={(): void => {
                    if (menuElement.disableOn) return;
                    if (menuElement.onClick) menuElement.onClick();
                    this.hideContextMenu();
                  }}
                >
                  {menuElement.label}
                  {menuElement.iconName ? (
                    <FontAwesomeIcon
                      icon={menuElement.iconName}
                      style={{ marginLeft: '1rem' }}
                    />
                  ) : null}
                </div>
              );
            }
          }

          return <div key={i} />;
        }
      );
    }

    return resultArray;
  }

  handleOnClick(e: React.MouseEvent<HTMLTableDataCellElement>): void | boolean {
    const parent = this.parent.current;

    if (this.props.onClick) {
      if (parent) {
        if (!parent.contains(e.target as HTMLElement)) {
          this.handleClick(e);
        }
      } else {
        this.handleClick(e);
      }
    }
  }

  render(): React.ReactNode {
    const classNames = cx('qs-table__body-cell', {
      'qs-table__body-cell--with-context-menu': this.props.contextMenu,
      'qs-table__body-cell-text': this.props.type === 'text',
      'qs-table__body-cell-numbers': this.props.type === 'numbers',
      'qs-table__body-cell--disabled': this.props.disabled,
      'qs-table__body-cell--show-button-on-hover': this.props.showButtonOnHover
    });
    const fullClassName = className(classNames, this.props.className);

    return (
      <td
        colSpan={this.props.colSpan}
        style={this.props.S}
        className={fullClassName}
        title={this.props.title}
        onClick={this.handleOnClick}
      >
        {this.props.children}
        {this.props.contextMenu && !this.props.hideContextOn && (
          <div
            ref={this.parent}
            className={cx('table-cell-context-menu__wrapper', {
              'table-cell-context-menu__wrapper--context-menu-is-showed': this
                .state.contextMenuIsShowed
            })}
          >
            <button
              onClick={this.showContextMenu}
              className={cx('table-cell-context-menu__trigger')}
            >
              <FontAwesomeIcon icon={'ellipsis-v'} />
            </button>
            <div className={cx('table-cell-context-menu')}>
              {this.renderContextMenu()}
            </div>
          </div>
        )}
      </td>
    );
  }
}
