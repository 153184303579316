import { api } from '../../index';
import { NotificationTypes } from './types';

const usersResourceUrl = '/users';

export const getUnreadFields = (userId: number) => api.get(`${usersResourceUrl}/${userId}/unreads/`);

export const sendCloseMonthNotification = (type: NotificationTypes, userId: number) => api.post(`/notifications/users/${userId}/`, { type });

export const sendNotifications = (type: NotificationTypes, userIds: number[]) => (
  api.post('/notifications/users/', { type, user_ids: userIds })
);
