import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Column } from '../../_shared/Table/helpers/types';

export const MANAGE_SKILL_TAGS_COLUMNS: Column[] = [
  {
    title: 'Skill Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: 400,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: <FontAwesomeIcon icon="check-circle" size="lg" />,
    dataIndex: 'approved',
    key: 'approved',
    align: 'center',
    width: 60,
  },
  {
    title: 'Created on',
    dataIndex: 'creationDate',
    key: 'creationDate',
    sorter: true,
  },
  {
    title: 'Emp.Know',
    dataIndex: 'employeesKnow',
    key: 'employeesKnow',
    width: 100,
    className: 'borderless',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 30,
    className: 'borderless action-column',
  },
];

export const DEFAULT_MANAGE_PANEL_FIELDS = {
  approved: undefined,
  searchValue: undefined,
  category: undefined,
  searchBySynonyms: true,
};
