/* eslint-disable import/prefer-default-export */
import qs from 'query-string';
import _ from 'lodash';

import { CuratorPartnerDetailedReports } from 'api/Curators/types';
import { BookkeepingResponse } from 'redux/Tables/reducerTypes';

import { api } from '../../index';
import { ActivityReplaceByDateRangeProps, ActivityReplaceByMonthAndYearProps, BookkeepingItemsRequestBody } from './types';

export const getBookkeepingItemsTable = (params: BookkeepingItemsRequestBody) => (
  api.get<BookkeepingResponse>(`/bookkeeping/items/${params.query}/`, {}, {
    paramsSerializer: (params: object) => qs.stringify(params, { skipNull: true }),
    requestId: 'getBookkeepingItemsTable',
    errorObject: { 404: { hidden: true } },
  })
);

export const getBookkeepingPartnersReportsByYearAndMonth = (year: number, month: number) => (
  api.get<CuratorPartnerDetailedReports>(`/bookkeeping/reports/partners/${year}/${month}/`)
);

export const getBookkeepingPartnersReportsByDateRange = (startDate: string, endDate: string) => (
  api.get<CuratorPartnerDetailedReports>('/bookkeeping/reports/partners/', {
    start_date_gte: startDate,
    end_date_lte: endDate,
  })
);

export const activityReplaceByMonthAndYear = (year: number, month: number, activityId: number, data: ActivityReplaceByMonthAndYearProps) => (
  api.patch(`/bookkeeping/timesheets/${year}/${month}/activities/`, {
    activity_id: activityId,
    new_activity_id: data.newActivityId,
    ...(!_.isUndefined(data.userIds) ? { user_ids: data.userIds } : {}),
  })
);

export const activityReplaceByDateRange = (activityId: number, data: ActivityReplaceByDateRangeProps) => (
  api.patch('/bookkeeping/timesheets/activities/', {
    start_date: data.startDate,
    end_date: data.endDate,
    activity_id: activityId,
    new_activity_id: data.newActivityId,
    ...(!_.isUndefined(data.userIds) ? { user_ids: data.userIds } : {}),
  })
);
