import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { getVacationReport } from 'redux/User/actions';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import Deactivated from 'components/_shared/Deactivated/Deactivated';

import s from './styles.module.sass';
import { TABLE_NAMES } from '../../helpers/constants/Tables/tableList';
import BasicTable from '../_shared/Table';
import { DispatchType, Redux } from '../../helpers/types/_common';
import { VACATION_REPORT_COLUMNS } from './helpers/constants';
import { VacationReportFilters } from './helpers/types';
import { Meta, Sorter } from '../../api/_request/types';
import { VacationReport } from '../../api/User/types';
import VacationReportSettings from './components/VacationReportSettings/VacationReportSettings';
import { DEFAULT_PAGINATION_RAW } from '../../helpers/constants/_common/Pagination';
import useDebounce from '../../helpers/hooks/useDebounce';

const DEFAULT_FILTERS = {
  active: true,
  searchValue: undefined,
  showAll: false,
  entityId: [],
  partnerId: [],
  userId: [],
};

const VacationHourReport = () => {
  const dispatch: DispatchType = useDispatch();

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.VACATION_REPORT]);
  const [ filters, setFilters ] = useState<VacationReportFilters>(DEFAULT_FILTERS);
  const [ year, setYear ] = useState(() => dayjs().year());

  const getVacationData = (meta: Meta, sorter?: Sorter) => {
    dispatch(getVacationReport({
      ...meta,
      ...sorter,
      ...filters,
      year,
    }, TABLE_NAMES.VACATION_REPORT));
  };

  const debouncedYear = useDebounce(year, 500);

  useEffect(() => {
    getVacationData({ limit: DEFAULT_PAGINATION_RAW.limit, offset: DEFAULT_PAGINATION_RAW.offset }, sorter);
  }, [ filters, debouncedYear ]);

  const renderName = (record: VacationReport) => (
    <div className={s.nameWrapper}>
      <span className={s.name}>{`${record.user.forename} ${record.user.surname}`}</span>
      <Deactivated isHidden={record.user.active} />
    </div>
  );

  const renderColumns = {
    id: {
      render: (text: string, record: VacationReport) => record.user.userId,
    },
    fullname: {
      render: (text: string, record: VacationReport) => renderName(record),
    },
    creationDate: {
      render: (text: string, record: VacationReport) => dayjs(record.user.creationDate).format(RU_DOT_DATE_FORMAT),
    },
    prescribed: {
      render: (text: string, record: VacationReport) => `${record.prescribed.hours}h. (${record.prescribed.days}d.)`,
    },
    spent: {
      render: (text: string, record: VacationReport) => `${record.spent.hours}h. (${record.spent.days}d.)`,
    },
    remains: {
      render: (text: string, record: VacationReport) => `${record.remains.hours}h. (${record.remains.days}d.)`,
    },
  };

  return (
    <div className={s.vacationReportLayout}>
      <VacationReportSettings
        filters={filters}
        setFilters={setFilters}
        year={year}
        setYear={setYear}
      />

      <BasicTable
        rowKey={(record: VacationReport) => record.user.userId}
        tableName={TABLE_NAMES.VACATION_REPORT}
        getData={getVacationData}
        initialRequest={false}
        columns={VACATION_REPORT_COLUMNS}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        bordered
      />
    </div>

  );
};

export default VacationHourReport;
