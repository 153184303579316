import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import _ from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import classNames from 'classnames';

import PartnerMask from 'components/_shared/PartnerMask';
import { getSimpleTimeByCellIndex } from 'components/TimeSheet/helpers/helpers';
import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from 'components/TimeSheet/components/Table/components/TableBody/components/GroupOfCellsContextMenu/helpers/constants';
import { addUserHours, getUserTimeSheetByDate, replaceAndFillUserHours } from 'api/TimeSheet/request';
import { UsersBCDay, UsersHourResponse } from 'api/TimeSheet/types';
import modals from 'helpers/styles/components/modals.module.sass';
import useApi from 'api/hooks/useApi/useApi';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';
import { MobilePicker, PickerItemValue } from 'components/_shared/MobilePicker/MobilePicker';
import withRightPlural from 'helpers/utils/withRightPlural';

import S from './helpers/styles.module.sass';
import { MobileTimesheetPanel, UserHourData } from '../../helpers/types';
import { groupUserHoursByActivity } from '../UserHoursList/helpers/groupUserHoursByActivity';

dayjs.extend(isBetween);

interface HourDetailsPanelProps {
  addWorkedHoursToDate: (date: string, hours: number) => void;
  changePanel: (panelName: MobileTimesheetPanel) => void;
  userHourData: UserHourData;
  selectedDayObj: UsersBCDay;
  userId: number;
}

const MARKS = _.reduce(_.range(49), (r, i) => ({
  ...r,
  [i]: getSimpleTimeByCellIndex(i),
}), {});

const HourDetailsPanel: React.FC<HourDetailsPanelProps> = ({ userHourData, userId, selectedDayObj, changePanel, addWorkedHoursToDate }) => {
  const fullName = `${userHourData.activity.partnerName}: ${userHourData.activity.activityName}`;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ startTime, setStartTime ] = useState<number>(20);
  const [ endTime, setEndTime ] = useState<number>(36);
  const [ comment, setComment ] = useState('');
  const [
    getUserHours,
    userHours,
    isTSLoading,
  ] = useApi<typeof getUserTimeSheetByDate, UsersHourResponse[]>(getUserTimeSheetByDate, { defaultData: [] });
  const groupedUserHours = useMemo(() => groupUserHoursByActivity(userHours), [ userHours ]);

  useEffect(() => {
    getUserHours({ userId, date: selectedDayObj.day });
  }, []);

  const onStartTimeChange = (value: PickerItemValue) => {
    setStartTime(Number(value));
  };

  const onEndTimeChange = (value: PickerItemValue) => {
    setEndTime(Number(value));
  };

  const onCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;

    setComment(text);
  };

  const onAddingConfirm = () => {
    if (_.isNull(startTime) || _.isNull(endTime)) return;

    const selectedStartTime = dayjs.utc(selectedDayObj.day).hour(Math.floor(startTime / 2)).minute(startTime % 2 && 30);
    const selectedEndTime = dayjs.utc(selectedDayObj.day).hour(Math.floor(endTime / 2)).minute(endTime % 2 && 30);

    const overlaps = _.reduce(groupedUserHours, (result, group) => {
      const groupStart = dayjs.utc(group.startTime);
      const groupEnd = dayjs.utc(group.endTime);

      const isSelectedStartInRange = selectedStartTime.isBetween(groupStart, groupEnd, null, '[)');
      const isSelectedEndInRange = selectedEndTime.isBetween(groupStart, groupEnd, null, '(]');

      const isGroupStartInRange = groupStart.isBetween(selectedStartTime, selectedEndTime, null, '[)');
      const isGroupEndInRange = groupEnd.isBetween(selectedStartTime, selectedEndTime, null, '(]');

      const getOverlappingStartAndEnd = () => {
        switch (true) {
          case isSelectedStartInRange && !isSelectedEndInRange:
            return { startTime: selectedStartTime, endTime: groupEnd };
          case !isSelectedStartInRange && isSelectedEndInRange:
            return { startTime: groupStart, endTime: selectedEndTime };
          case isSelectedStartInRange && isSelectedEndInRange:
            return { startTime: selectedStartTime, endTime: selectedEndTime };
          default:
            return { startTime: groupStart, endTime: groupEnd };
        }
      };

      switch (true) {
        case isSelectedStartInRange || isSelectedEndInRange:
        case isGroupStartInRange || isGroupEndInRange: {
          const { startTime, endTime } = getOverlappingStartAndEnd();
          const hours = (endTime.unix() - startTime.unix()) / (60 * 60);

          return [
            ...result,
            {
              hours,
              activity: group.activity,
              partner: group.partner,
            },
          ];
        }
        default:
          return result;
      }
    }, [] as any);

    if (overlaps.length) {
      const hours = _.reduce(overlaps, (result, overlap) => result + overlap.hours, 0);
      const addedHours = ((endTime - startTime) / 2) - hours;

      const modalContent = (
        <div className={S.confirmContentWrapper}>
          <span>Are you sure you want to:</span>
          <ul>
            <li>
              <span className={S.dot}>•</span>
              <span>
                Replace
                {' '}
                <b>{hours}</b>
                {' '}
                selected
                {' '}
                {withRightPlural('hour', hours)}
              </span>
            </li>
            {addedHours !== 0 && (
              <li>
                <span className={S.dot}>•</span>
                <span>
                  Add
                  {' '}
                  <b>{addedHours}</b>
                  {' '}
                  {withRightPlural('hour', addedHours)}
                  ?
                </span>
              </li>
            )}
          </ul>
        </div>
      );

      Modal.confirm({
        title: 'Replace confirmation',
        className: modals.qsConfirmAntdModal,
        content: modalContent,
        centered: true,
        closable: true,
        maskClosable: true,
        cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
        okButtonProps: { className: modals.modalOkBtn, size: 'large' },
        autoFocusButton: null,
        icon: null,
        width: 500,
        onOk: () => replaceHours(addedHours || 0),
        okText: 'Confirm',
      });
    } else {
      addHours(startTime, endTime);
    }
  };

  const replaceHours = (addedHours: number) => {
    setIsLoading(true);

    replaceAndFillUserHours(userId, {
      startCellIndex: startTime,
      endCellIndex: endTime - 1, // -1 because of route's correction of cases when we select only one cell to add usersHour
      ...(comment ? { comment } : {}),
      activityId: userHourData.activity.activityId,
      day: selectedDayObj.day,
    })
      .then(() => {
        addWorkedHoursToDate(selectedDayObj.day, addedHours);
        changePanel(MobileTimesheetPanel.UserHoursList);
      })
      .finally(() => setIsLoading(false));
  };

  const addHours = (startTime: number, endTime: number) => {
    setIsLoading(true);
    addUserHours(userId, {
      startCellIndex: startTime,
      endCellIndex: endTime - 1, // -1 because of route's correction of cases when we select only one cell to add usersHour
      ...(comment ? { comment } : {}),
      activityId: userHourData.activity.activityId,
      day: selectedDayObj.day,
    })
      .then(() => {
        addWorkedHoursToDate(selectedDayObj.day, (endTime - startTime) / 2);
        changePanel(MobileTimesheetPanel.UserHoursList);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={S.wrapper}>
      <div className={S.activity}>
        <PartnerMask
          wrapperColor={userHourData.activity.partnerColor}
          mask={userHourData.activity.mask}
          wrapperClassName={S.activityIconWrapper}
          partnerId={userHourData.activity.partnerId}
          iconColor={userHourData.activity.activityColor}
          isVacation={false} // TODO: Fix with real data
        />
        <p className={S.descriptionTextWrapper}>
          <span className={S.descriptionText}>{fullName}</span>
        </p>
      </div>
      <div className={S.formWrapper}>
        {isTSLoading && (<div className={S.preloaderWrapper}><SpinnerNew /></div>)}
        {!isTSLoading && (
          <>
            <div className={S.selectors}>
              <div className={S.selector}>
                <MobilePicker
                  itemWidth={100}
                  activeItemClassName={S.activeItem}
                  defaultValue={startTime}
                  onSelect={onStartTimeChange}
                >
                  {_.map(MARKS, (v, k) => (
                    <MobilePicker.Item
                      key={k}
                      value={k}
                      disabled={parseInt(k, 10) >= (endTime || 49)}
                    >
                      <div className={classNames(S.selectedHours, { [S.disabled]: parseInt(k, 10) >= (endTime || 49) })}>{v}</div>
                    </MobilePicker.Item>
                  ))}
                </MobilePicker>
                <div className={S.marker}>Start time</div>
              </div>
              <div className={S.selector} style={{ marginTop: '15px' }}>
                <MobilePicker
                  itemWidth={100}
                  activeItemClassName={S.activeItem}
                  defaultValue={endTime}
                  onSelect={onEndTimeChange}
                >
                  {_.map(MARKS, (v, k) => (
                    <MobilePicker.Item
                      key={k}
                      value={k}
                      disabled={parseInt(k, 10) <= (startTime || 0)}
                    >
                      <div className={classNames(S.selectedHours, { [S.disabled]: parseInt(k, 10) <= (startTime || 0) })}>{v}</div>
                    </MobilePicker.Item>
                  ))}
                </MobilePicker>
                <div className={S.marker}>End time</div>
              </div>
            </div>
            <TextArea
              rows={4}
              value={comment}
              onChange={onCommentChange}
              className={S.comment}
              minLength={MIN_COMMENT_LENGTH}
              maxLength={MAX_COMMENT_LENGTH}
              placeholder="Add an optional comment"
            />
          </>
        )}
      </div>
      <div className={S.buttonsWrapper}>
        <Button
          onClick={onAddingConfirm}
          className={S.saveButton}
          loading={isLoading}
          disabled={isTSLoading || (_.isNull(startTime) || _.isNull(endTime)) || (startTime >= endTime)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default HourDetailsPanel;
