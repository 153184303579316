import * as TableActions from '../../helpers/constants/_common/actions/Tables';
import { TablesRedux } from '../../helpers/types/_common';
import { handleTableDataAction } from './helpers';
import {
  manageSkillTagsInitialState,
  findSpecialistsInitialState,
  requestCandidatesInitialState,
  requestSpecialistsInitialState,
  employeesInitialState,
  partnersInitialState,
  partnersUsersInitialState,
  entitiesInitialState,
  partnerParticipantsInitialState,
  vacationReportInitialState,
  hourReportInitialState,
  curatorHourReportInitialState,
  userHourReportSInitialState,
  BookkeepingItemsState,
} from './constants';

const initialState: TablesRedux = {
  manageSkillTags: manageSkillTagsInitialState,
  findSpecialists: findSpecialistsInitialState,
  requestCandidates: requestCandidatesInitialState,
  requestSpecialists: requestSpecialistsInitialState,
  employees: employeesInitialState,
  bookkeepingItems: BookkeepingItemsState,
  partners: partnersInitialState,
  partnersUsers: partnersUsersInitialState,
  entities: entitiesInitialState,
  partnerParticipants: partnerParticipantsInitialState,
  vacationReport: vacationReportInitialState,
  hourReport: hourReportInitialState,
  curatorHourReport: curatorHourReportInitialState,
  userHourReports: userHourReportSInitialState,
};

const TablesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TableActions.SET_TABLE_SORTER:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName as keyof TablesRedux],
          sorter: action.sorter,
        },
      };

    case TableActions.GET_TABLE_DATA:
      return handleTableDataAction(state, action);

    case TableActions.CLEAR_TABLE_DATA:
      return {
        ...state,
        [action.tableName]: initialState[action.tableName as keyof TablesRedux],
      };

    default:
      return state;
  }
};

export default {
  initialState,
  TablesReducer,
};
