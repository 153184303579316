import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { EDIT_PROFILE_TABS } from 'helpers/constants/_common/tabs/profile';
import { Redux } from 'helpers/types/_common';

import s from './styles.module.sass';
import PagesSidebar from '../Root/components/PagesSidebar';
import EditProfileTabSidebar from '../Sidebars/EditProfileTabSidebar';
import useApi from '../../api/hooks/useApi/useApi';
import { getUserById } from '../../api/User/requests';
import { UserDetails } from '../../api/User/types';
import PersonalInfo from '../ProfileTabContent/components/PersonalInfo';
import SkillSet from '../ProfileTabContent/components/SkillSet';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES } from '../../helpers/constants/_common/MenuCases';
import useTabRedirect from '../../helpers/hooks/useTabRedirect';
import TimeSheet from '../TimeSheet/TimeSheet';
import { USER_TYPES } from '../../helpers/constants/_common/constants';

const EditProfile = () => {
  const { login } = useSelector((state: Redux) => state);

  const { userId, tab } = useParams<{ userId: string, tab: string }>();

  const [ getUserData, userData, loading, setUserData ] = useApi<typeof getUserById, UserDetails>(getUserById);

  useTabRedirect({
    redirectUrl: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${userId}/${EDIT_PROFILE_TABS.general.path}`,
    tab,
    tabKeys: _.keys(EDIT_PROFILE_TABS),
  });

  useEffect(() => {
    getUserData(Number(userId) || login.userId);
  }, [ userId ]);

  const renderPersonalInfoContent = () => {
    if ((tab !== EDIT_PROFILE_TABS.general.path) || !userData) {
      return null;
    }

    return <PersonalInfo user={userData} setUserData={setUserData} editByAdmin />;
  };

  const renderSkillSetContent = () => {
    if ((tab !== EDIT_PROFILE_TABS.skillset.path) || !userData) {
      return null;
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <SkillSet
          user={userData}
          setUserData={setUserData}
          editByAdmin
        />
      </DndProvider>
    );
  };

  const renderTimesheetContent = () => {
    if ((tab !== EDIT_PROFILE_TABS.timesheet.path) || !userData || (userData.usertype === USER_TYPES.BOT)) {
      return null;
    }

    return (
      <TimeSheet user={userData} />
    );
  };

  return (
    <div className={s.editProfileLayout}>
      <PagesSidebar>
        <EditProfileTabSidebar
          userData={userData}
          loading={loading}
        />
      </PagesSidebar>

      <Spin
        wrapperClassName={s.editProfileSpinner}
        spinning={loading}
      >
        <div className={s.editProfileContent}>
          { renderPersonalInfoContent() }
          { renderSkillSetContent() }
          { renderTimesheetContent() }
        </div>
      </Spin>
    </div>
  );
};

export default EditProfile;
