import { UserStatisticOutput } from 'api/User/types';

export interface ParticipantsFiltersState {
  activeTab: string;
  active: boolean;
  entity_id: number[];
  activity: number | null;
  occupation: string | null;
  usernameContains?: string;
}

export enum UserRoles {
  User = 'USER',
  Curator = 'CURATOR',
}

export interface PartnerParticipantsOutputChecked extends PartnerParticipantsOutput {
  checked?: boolean;
}

export interface PartnerParticipantsOutput {
  user: UserStatisticOutput;
  roles: UserRoles[],
  currentMonthHours: number;
  partnerUserId: number;  
}
