import {
  COLLAPSED_SIDEBAR,
  MAIN_THEME,
} from '../constants/_common/localStorageConstants';
import { Themes } from '../constants/_common/constants';

export interface TTLocalStorage {
  [key: string]: any;
  hiddenSubmenus: LSHiddenSubmenus;
}

export interface LSHiddenSubmenus {
  administration: boolean;
  profile: boolean;
  partners: boolean;
}

export const initiateLocalStorage = () => {
  if (!localStorage) {
    return;
  }

  const isDarkTheme = window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)')?.matches;

  if (!localStorage[COLLAPSED_SIDEBAR]) localStorage.setItem(COLLAPSED_SIDEBAR, JSON.stringify(false));
  if (!localStorage.getItem(MAIN_THEME)) localStorage.setItem(MAIN_THEME, isDarkTheme ? Themes.DARK : Themes.LIGHT);
};
