export const VACANCIES_INITIATE = 'VACANCIES_INITIATE';

export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_VACANCIES = 'GET_VACANCIES';
export const GET_ALL_VACANCIES = 'GET_ALL_VACANCIES';
export const GET_VACANCY_BY_ID = 'GET_VACANCY_BY_ID';
export const ADD_VACANCY = 'ADD_VACANCY';
export const REMOVE_VACANCY = 'REMOVE_VACANCY';
export const SUSPEND_VACANCY = 'SUSPEND_VACANCY';
export const EDIT_VACANCY = 'EDIT_VACANCY';
export const GET_UNREAD_VACANCY_COUNT = 'GET_UNREAD_VACANCY_COUNT';

export const VACANCY_MODAL = 'VACANCY_MODAL';
export const SUSPENDING_CONFIRMATION_MODAL = 'SUSPENDING_CONFIRMATION_MODAL';
export const VACANCY_REMOVE_CONFIRMATION_MODAL = 'VACANCY_REMOVE_CONFIRMATION_MODAL';
export const RECOMMENDATION_PROGRAM_MODAL_DESCRIPTION = 'RECOMMENDATION_PROGRAM_MODAL_DESCRIPTION';

export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';

export const UPDATE_VACANCIES_LIST_WITH_NEW_ONE = 'UPDATE_VACANCIES_LIST_WITH_NEW_ONE';
export const UPDATE_VACANCIES_WITH_SUSPENDED = 'UPDATE_VACANCIES_WITH_SUSPENDED';
export const UPDATE_VACANCIES_WITH_REMOVED = 'UPDATE_VACANCIES_WITH_REMOVED';
export const UPDATE_VACANCIES_WITH_UPDATED = 'UPDATE_VACANCIES_WITH_UPDATED';
export const UPDATE_UNREAD_VACANCIES_COUNT = 'UPDATE_UNREAD_VACANCIES_COUNT';

export const CHANGE_SELECTED_VACANCY = 'CHANGE_SELECTED_VACANCY';
export const CHANGE_SELECTED_VACANCY_TO_SUSPENDING = 'CHANGE_SELECTED_VACANCY_TO_SUSPENDING';
export const CHANGE_SELECTED_VACANCY_TO_REMOVE = 'CHANGE_SELECTED_VACANCY_TO_REMOVE';
