import { BCJointStatus, BCMonth } from 'api/TimeSheet/types';

/** Returns { min: number, max: number } where min always <= than max. 
 * Even if we selected from bottom to top -- we need startCell to be less then endCell. */
export const getMinMax = (one: number, two: number) => (
  (one - two) <= 0 
    ? { min: one, max: two }
    : { min: two, max: one }
);

export const getMiddleDay = (BCMonth: BCMonth) => {
  const daysOfCurrentMonth = BCMonth.jointDays.filter(dayObj => dayObj.jointStatus === BCJointStatus.Basic);

  return daysOfCurrentMonth[Math.floor(daysOfCurrentMonth.length / 2)];
};
