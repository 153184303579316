import _ from 'lodash';

import TokenStore from 'api/_request/helpers/jwtTokenStore';

import availableRouteKeys from '../../../helpers/utils/accessUtils';
import history from '../../../redux/_reducer/configureHistory';
import { REDIRECT_URL } from '../../../helpers/constants/_common/sessionStorageConstants';
import { getStringFromSessionStorage } from '../../../helpers/utils/sessionStorageService';
import * as Actions from '../../constants/Login';
import {DEFAULT_PATH, PATH_ADMINISTRATION, PATH_TRACKER, TAB_EMPLOYEES, WELCOME_PAGE} from '../../../helpers/constants/_common/MenuCases';
import userRequests from '../../requests/User';
import authRequests, { applyDefaultSettings } from '../../requests/Auth';
import Subtypes from '../../constants/__common__/Subtypes';
import * as vacanciesActions from '../VacancyPage/VacanciesActions';
import * as hotProjectsActions from '../HotProjectsActions';
import { USER_TYPES } from '../../../helpers/constants/_common/constants';

let LoggedUserId = 0;

export function testUserDataForFilling(userData) {
  const isUserDataEmpty = _.isEmpty(userData?.forename)
    || _.isEmpty(userData?.surname)
    || _.isEmpty(userData?.occupation)
    || _.isEmpty(userData?.skypeId)
    || _.isEmpty(userData?.phone)
    || _.isEmpty(userData?.location)
    || !_.isNumber(userData?.timezoneUtcOffset);

  const isNotificationDataEmpty = [
    userData.preferences.notifyViaSlack,
    userData.preferences.notifyViaEmail,
    userData.preferences.notifyToCloseMonth,
    userData.preferences.notifyToReportHours,
  ].includes(null);

  return { isUserDataEmpty, isNotificationDataEmpty: false };
}

export function getUnreadCounts(userId) {
  if (userId) LoggedUserId = userId;
  return (dispatch) => {
    dispatch({ type: Actions.GET_UNREAD_COUNTS, subtype: Subtypes.Request.Loading });

    return userRequests.getUnreadCounts(LoggedUserId)
      .then((res) => {
        dispatch(updateUnreadNotificationsCount(res.notifications));
        dispatch(vacanciesActions.updateUnreadVacanciesCount(res.vacancies));
        dispatch(hotProjectsActions.updateUnreadHotProjectsCount(res.multipliers));
        return dispatch({ type: Actions.GET_UNREAD_COUNTS, subtype: Subtypes.Request.Success, payload: res });
      })
      .catch(() => dispatch({ type: Actions.GET_UNREAD_COUNTS, subtype: Subtypes.Request.Error }));
  };
}

export function updateUnreadNotificationsCount(count) {
  return {
    type: Actions.CHANGE_UNREAD_NOTIFICATIONS_COUNT,
    payload: count,
  };
}

export function changeContent() {
  return {
    type: Actions.CHANGE_CONTENT,
  };
}

export function externalRedirectToLogin() {
  return (dispatch) => {
    TokenStore.clearJWTData();

    history.replace(DEFAULT_PATH);

    dispatch({ type: Actions.LOGOUT });
    dispatch({
      type: Actions.GET_USER_DATA,
      subtype: Subtypes.Request.Error,
      payload: '',
    });
  };
}

export function authByAccessToken() {
  return (dispatch) => {
    dispatch({ type: Actions.GET_USER_DATA, subtype: Subtypes.Request.Loading });

    return authRequests.getUserInfo()
      .then((res) => {
        if (res.active) {
          const { isNotificationDataEmpty, isUserDataEmpty } = testUserDataForFilling(res);
          const isWelcomePageNeeded = isNotificationDataEmpty || isUserDataEmpty;

          dispatch({
            type: Actions.GET_USER_DATA,
            subtype: Subtypes.Request.Success,
            payload: res,
            isFilledProfile: !isWelcomePageNeeded,
            availableRoutes: availableRouteKeys(res.permissions, res),
          });

          if (isWelcomePageNeeded) {
            history.replace(WELCOME_PAGE);
          }
        } else {
          dispatch({
            type: Actions.GET_USER_DATA,
            subtype: Subtypes.Request.Error,
            payload: 'User is disabled',
          });
        }

        return res;
      })
      .catch((error) => {
        document.cookie = 'hash=';
        history.replace(DEFAULT_PATH);
        dispatch({
          type: Actions.GET_USER_DATA,
          subtype: Subtypes.Request.Error,
          payload: '',
        });

        throw error;
      });
  };
}

export function googleAuth(code, state, provider, redirectUri) {
  return (dispatch) => {
    dispatch({ type: Actions.GET_USER_DATA, subtype: Subtypes.Request.Loading });

    return authRequests.googleAuth(code, state, provider, redirectUri)
      .then((res) => {
        TokenStore.setJWTData({
          refreshToken: res.refreshToken,
          accessToken: res.accessToken,
          expiresIn: res.expiresIn,
        });

        if (res.user && res.user.active) {
          const { isNotificationDataEmpty, isUserDataEmpty } = testUserDataForFilling(res.user);
          const isWelcomePageNeeded = isNotificationDataEmpty || isUserDataEmpty;

          if (!_.get(res, 'user.preferences.confirmed')) {
            const defaultSettings = applyDefaultSettings(res.user.userId);

            const user = {
              ...res.user,
              preferences: defaultSettings,
            };

            dispatch({
              type: Actions.GET_USER_DATA,
              subtype: Subtypes.Request.Success,
              payload: user,
              isFilledProfile: !isWelcomePageNeeded,
              availableRoutes: availableRouteKeys(res.user.permissions, res.user),
            });
          } else {
            dispatch({
              type: Actions.GET_USER_DATA,
              subtype: Subtypes.Request.Success,
              payload: res.user,
              isFilledProfile: !isWelcomePageNeeded,
              availableRoutes: availableRouteKeys(res.user.permissions, res.user),
            });
          }

          const redirectUrl = getStringFromSessionStorage(REDIRECT_URL);

          if (isWelcomePageNeeded) {
            history.replace(WELCOME_PAGE);
            return;
          }
          if (res.user.usertype === USER_TYPES.ADMIN) {
            history.replace(redirectUrl || `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${res.user.userId}/timesheet`);
          } else {
            history.replace(redirectUrl || PATH_TRACKER);
          }
        } else {
          history.replace(DEFAULT_PATH);

          dispatch({
            type: Actions.GET_USER_DATA,
            subtype: Subtypes.Request.Error,
            payload: 'User is disabled',
          });
        }
      })
      .catch((error) => {
        const errorStatus = error && error.response && error.response.status;

        dispatch({
          type: Actions.GET_USER_DATA,
          subtype: Subtypes.Request.Error,
          payload: (errorStatus === 404) ? 'There is no account for this email address. Please, contact administrators to create one' : (_.get(error, 'response.data.error') || error.message),
        });
        history.replace(DEFAULT_PATH);

        throw error;
      });
  };
}

export function globalLogout(userId) {
  return (dispatch) => {
    userRequests.globalLogout(userId).then(response => dispatch(externalRedirectToLogin()));
  };
}

export function showLogoutModal() {
  return {
    type: Actions.SHOW_LOGOUT_MODAL,
  };
}

export function changeProfilesOccupation(occupationId) {
  return { type: Actions.CHANGE_PROFILES_OCCUPATION, payload: occupationId };
}
