import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { changeSelectedDate } from 'redux/TimeSheet/action';
import { BCMonth as BCMonthProps } from 'api/TimeSheet/types';
import { blankBusinessMonthGenerator, blankDaysGenerator } from 'components/TimeSheet/helpers/generators';
import * as BCRequests from 'api/TimeSheet/request';

import { DispatchType } from '../types/_common';
import { DEFAULT_DATE_FORMAT, MONTH_YEAR_FORMAT } from '../constants/_common/timeFormats';

interface GetBusinessCalendarHookProps {
  selectedDate: string;
  onBCUpdate?: (month: BCMonthProps) => Promise<any>;
  userId?: number;
}

const useGetBusinessCalendar = ({ selectedDate, onBCUpdate, userId }: GetBusinessCalendarHookProps) => {
  const dispatch: DispatchType = useDispatch();

  const nowDayjs = dayjs();
  const defaultDays = blankDaysGenerator(nowDayjs.format(DEFAULT_DATE_FORMAT));
  const selectedMonth = dayjs(selectedDate, [ MONTH_YEAR_FORMAT, DEFAULT_DATE_FORMAT ]).month() + 1;
  const selectedYear = dayjs(selectedDate, [ MONTH_YEAR_FORMAT, DEFAULT_DATE_FORMAT ]).year();

  const BCMonthDefault: BCMonthProps = {
    businessMonthId: null,
    year: nowDayjs.get('year'),
    month: nowDayjs.get('month') + 1,
    jointDays: defaultDays,
    startDay: nowDayjs.date(1).format(DEFAULT_DATE_FORMAT),
    endDay: nowDayjs.date(nowDayjs.daysInMonth()).format(DEFAULT_DATE_FORMAT),
    modified: false,
  };

  const [ BCMonth, setBCMonth ] = useState<BCMonthProps>(() => BCMonthDefault); // calendar month
  const [ BCIsLoading, changeBCIsLoading ] = useState(false);

  const getCalendarByDate = (date: string) => {
    changeBCIsLoading(true);

    return BCRequests.getCalendarByDate(date);
  };

  const getCalendarByMonthAndYear = (coords: { year: number, month: number }) => {
    changeBCIsLoading(true);

    const date = dayjs(`${coords.year}-${coords.month}-1`).format(DEFAULT_DATE_FORMAT);

    return BCRequests.getCalendarByMonthAndYear(coords)
      .then(res => (res.jointDays.length ? res : {
        ...res,
        jointDays: blankDaysGenerator(dayjs(date).format(DEFAULT_DATE_FORMAT)),
      }))
      .catch(() => blankBusinessMonthGenerator(date));
  };

  useEffect(() => {
    getCalendarByDate(nowDayjs.format(DEFAULT_DATE_FORMAT))
      .then(async (month: any) => {
        if (month.month !== selectedMonth || month.year !== selectedYear) {
          const newDayjsObj = dayjs(`${month.year}-${month.month}-1`);

          dispatch(changeSelectedDate(newDayjsObj));
        }

        setBCMonth(month);
        await onBCUpdate?.(month).finally(() => changeBCIsLoading(false));

        changeBCIsLoading(false);
      });
  }, [ userId ]);

  useEffect(() => {
    if (BCMonth.year !== selectedYear || BCMonth.month !== selectedMonth) {
      getCalendarByMonthAndYear({ year: selectedYear, month: selectedMonth })
        .then(async (month: any) => {
          setBCMonth(month);
          await onBCUpdate?.(month).finally(() => changeBCIsLoading(false));

          changeBCIsLoading(false);
        });
    }
  }, [ selectedDate, userId ]);

  return [ BCMonth, BCIsLoading ] as [BCMonth: BCMonthProps, BCIsLoading: boolean];
};

export default useGetBusinessCalendar;
