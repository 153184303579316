import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router';

import { DispatchType, Redux } from 'helpers/types/_common';
import {
  dropTimeSelecting,
  openSelectionContextMenu,
  openGroupContextMenu,
  addUserHourAction,
  setSelectionBoxLoading,
  replaceAndFillUserHourAction,
} from 'redux/TimeSheet/action';
import getFromStore from 'helpers/utils/getFromStore';
import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import { SelectedHour } from 'components/TimeSheet/helpers/types';
import { UiDrawMode } from 'helpers/constants/_common/constants';
import { HoursDataToAdd } from 'api/TimeSheet/types';

import SelectionBox from '../SelectionBox/SelectionBox';
import { SelectionData } from '../SelectionBox/helpers/types';
import { getSelectionHoursStat, isSelectedActivitySelectedWithCells } from '../../../../helpers/helpers';
import { ConfirmModalData } from './helpers/types';
import confirmModalInitData from './helpers/constants';
import HoursConfirmModal from '../../../HoursConfirmModal/HoursConfirmModal';

interface ActionMakerProps {
  curatorOnlyView: boolean | undefined;
  isDisabled: boolean;
}

const ActionMaker: React.FC<ActionMakerProps> = ({ curatorOnlyView, isDisabled }) => {
  const [ confirmModalData, setConfirmModalData ] = useState<ConfirmModalData>(confirmModalInitData);

  const { preferences } = useSelector((state: Redux) => state.login);
  const { selectedActivity, tableSettings, flatUserHoursTable } = useSelector((state: Redux) => state.timeSheet);

  const { userId: editUserId } = useParams<{ userId: string }>();
  
  const dispatch: DispatchType = useDispatch();

  const onMouseUpCallback = (selectionData: SelectionData) => {
    switch (selectionData.userAction) {
      case 'click':
        return doOpenGroupContextMenu(selectionData.clickedCell);
      case 'select':
        return doOpenSelectionContextMenu();
      case 'draw':
        return doDrawComplete(selectionData);
      default:
        return dispatch(dropTimeSelecting());
    }
  };

  const doOpenSelectionContextMenu = () => {
    if (tableSettings.isMonthClosed || curatorOnlyView) return dispatch(dropTimeSelecting());

    const selectedHours: SelectedHour[] = [];

    dispatch(openSelectionContextMenu(selectedHours));
  };

  const doOpenGroupContextMenu = (clickedElement: Element | null) => {
    if (!clickedElement) return dispatch(dropTimeSelecting());

    const { dataset } = clickedElement as HTMLDivElement;
    const { day, groupStartIndex, cellId } = dataset;

    if (_.isUndefined(day) || !groupStartIndex || !cellId || !flatUserHoursTable[cellId]) return dispatch(dropTimeSelecting());
    
    dispatch(openGroupContextMenu(day, parseInt(groupStartIndex, 10)));
  };

  const doDrawComplete = (selectionData: SelectionData) => {
    if (tableSettings.isMonthClosed || curatorOnlyView) return dispatch(dropTimeSelecting());
    
    const { total, filled, empty } = getSelectionHoursStat();
    const isSameActivity = isSelectedActivitySelectedWithCells();
    
    const isOnlyEmptyCells = total === empty;
    const isOnlyFilledCells = total === filled;

    switch (true) {
      case isOnlyEmptyCells:
        return doAddHours(selectionData);
      case isOnlyFilledCells && isSameActivity:
        return doOpenSelectionContextMenu();
      default:
        return doReplaceAndFill(selectionData);
    }
  };

  const doAddHours = (selectionData: SelectionData) => {
    const requestData = getAddHourRequestData();
    const isCutDay = checkIsCutDay(selectionData);
    
    if (_.isNull(requestData) || isCutDay) return dispatch(dropTimeSelecting());

    dispatch(setSelectionBoxLoading(true));
    dispatch(addUserHourAction(editUserId, requestData))
      .finally(() => {
        dispatch(setSelectionBoxLoading(false));
        dispatch(dropTimeSelecting());
      });
  };

  const doReplaceAndFill = (selectionData: SelectionData) => {
    const isCutDay = checkIsCutDay(selectionData);
    const isCtrlMouseMode = preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL;
    const { askConfirmationOnOverwriteHours } = preferences;

    if (isCutDay) dispatch(dropTimeSelecting());
    const requestData = getAddHourRequestData();
    
    if (_.isNull(requestData)) return dispatch(dropTimeSelecting());

    if (!askConfirmationOnOverwriteHours || isCtrlMouseMode) {
      dispatch(setSelectionBoxLoading(true));
      dispatch(replaceAndFillUserHourAction(editUserId, requestData))
        .finally(() => {
          dispatch(setSelectionBoxLoading(false));
          dispatch(dropTimeSelecting());
        });
    } else {
      setConfirmModalData({
        isVisible: true,
        userId: parseInt(editUserId, 10),
        data: requestData,
        onOk: () => {
          setConfirmModalData(confirmModalInitData);
          dispatch(setSelectionBoxLoading(false));
          dispatch(dropTimeSelecting());
        },
      });
    }
  };

  const getAddHourRequestData = (): HoursDataToAdd | null => {
    const { selectedCells } = getFromStore('timeSheet.selection');
    const cellIds = _.keys(selectedCells);
    const { day, cellIndex: startCellIndex } = getDateAndCellIndexByTime(cellIds[0]);
    const endCellIndex = getDateAndCellIndexByTime(cellIds[cellIds.length - 1]).cellIndex;

    if (_.isNull(day) || _.isNull(startCellIndex) || _.isNull(endCellIndex)) return null;

    return { day, startCellIndex, endCellIndex, activityId: selectedActivity?.activity.activityId };
  };

  const checkIsCutDay = (selectionData: SelectionData) => {
    if (_.isNull(selectionData.clickedCell)) return false;

    const { isCut } = selectionData.clickedCell.dataset;
    
    if (_.isUndefined(isCut)) return false;
    
    return isCut === 'true';
  };

  return (
    <>
      <SelectionBox
        curatorOnlyView={curatorOnlyView}
        onMouseUpCallback={onMouseUpCallback}
        isDisabled={isDisabled}
      />
      <HoursConfirmModal
        {...confirmModalData}
        onClose={() => {
          dispatch(dropTimeSelecting());
          setConfirmModalData(confirmModalInitData);
        }}
      />
    </>
  );
};

export default ActionMaker;
