import React from 'react';
import cx from 'classnames/bind';
import _ from 'lodash';

import s from './styles.module.sass';

interface FieldCardProps {
  title?: string;
  className?: string;
  wrapperClassName?: string;
  fullHeight?: boolean;
}

const FieldCard: React.FC<FieldCardProps> = ({ title, className, wrapperClassName, fullHeight, children }) => (
  <div className={cx(s.fieldCard, wrapperClassName, { [s.fullHeight]: fullHeight })}>
    { !_.isUndefined(title) ? <div className={s.fieldCardTitle}>{title}</div> : null}

    <div className={cx(s.fieldCardBody, className, { [s.fullHeight]: fullHeight })}>
      {children}
    </div>
  </div>
);

export default FieldCard;
