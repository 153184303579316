import _ from 'lodash';

import { convertUserHoursToFlat, getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import { UserHourComment, UsersHourResponse } from 'api/TimeSheet/types';

import { TimeSheetRedux } from './types';
import { ActivityWithPartnerOutput } from '../../api/Partners/types';

export const updateTableAfterAddComment = (state: TimeSheetRedux, selectedDay: string, cellIndexes: number[], comment: UserHourComment) => {
  const { flatUserHoursTable } = state;

  return _.reduce(flatUserHoursTable, (newFlatTable, userHour, key) => {
    const { cellIndex, day } = getDateAndCellIndexByTime(key);

    if (!day || _.isNil(cellIndex)) return newFlatTable;

    if (day === selectedDay && cellIndexes.includes(cellIndex)) {
      return {
        ...newFlatTable,
        [key]: {
          ...userHour,
          comment,
        },
      };
    } else {
      return {
        ...newFlatTable,
        [key]: userHour,
      };
    }
  }, {});
};

export const updateTableAfterDeleteComment = (state: TimeSheetRedux, selectedDay: string, cellIndexes: number[]) => {
  const { flatUserHoursTable } = state;

  return _.reduce(flatUserHoursTable, (newFlatTable, userHour, key) => {
    const { cellIndex, day } = getDateAndCellIndexByTime(key);

    if (!day || _.isNil(cellIndex)) return newFlatTable;

    if (day === selectedDay && cellIndexes.includes(cellIndex)) {
      return {
        ...newFlatTable,
        [key]: {
          ...userHour,
          comment: undefined,
        },
      };
    } else {
      return {
        ...newFlatTable,
        [key]: userHour,
      };
    }
  }, {});
};

export const updateTableAfterDeleteHelper = (state: TimeSheetRedux, selectedDay: string, cellIndexes: number[]) => {
  const { flatUserHoursTable } = state;

  return _.reduce(flatUserHoursTable, (newFlatTable, userHour, key) => {
    const { cellIndex, day } = getDateAndCellIndexByTime(key);

    if (!day || _.isNil(cellIndex)) return newFlatTable;

    if (day === selectedDay && cellIndexes.includes(cellIndex)) {
      return {
        ...newFlatTable,
        [key]: undefined,
      };
    } else {
      return {
        ...newFlatTable,
        [key]: userHour,
      };
    }
  }, {});
};

export const updateTableAfterAddOrReplaceHelper = (state: TimeSheetRedux, userHours: UsersHourResponse[]) => {
  const { flatUserHoursTable } = state;

  if (userHours.length === 0) return state.flatUserHoursTable; // nothing to update

  return {
    ...flatUserHoursTable,
    ...convertUserHoursToFlat(userHours),
  };
};
