import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Space, Tooltip } from 'antd';
import { Key } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import cx from 'classnames';

import { EntityOutput, UserDetails, UserStatisticOutput } from 'api/User/types';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { PATH_ADMINISTRATION, TAB_EMPLOYEES } from 'helpers/constants/_common/MenuCases';
import { ReactComponent as EditIcon } from 'helpers/icons/Buttons/edit-icon.svg';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { Redux } from 'helpers/types/_common';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import EmployeeDrawer from 'components/Drawers/EmployeeDrawer';
import TableAvatar from 'components/TableComponents/TableAvatar';
import { renderSkillUpdatedDays, renderTimesheetUpdatedDays } from 'components/_shared/OverdueBadge/helpers/helpers';
import { Meta, Sorter } from 'api/_request/types';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import UserRowPopover from './components/UserRowPopover';
import EMPLOYEES_COLUMNS from '../../helpers/constant';
import BasicTable from '../../../_shared/Table';
import { EmployeesFiltersState, UserStatisticOutputChecked } from '../../helpers/types';
import s from './helpers/styles.module.sass';
import { ReactComponent as TimeIcon } from './helpers/timeIcon.svg';
import PROFILE_TABS, { EDIT_PROFILE_TABS } from '../../../../helpers/constants/_common/tabs/profile';
import { USER_TYPES } from '../../../../helpers/constants/_common/constants';
import { DEFAULT_PAGINATION_RAW } from '../../../../helpers/constants/_common/Pagination';
import { EmployeeDrawerData } from '../../../Drawers/EmployeeDrawer/helpers/types';

interface EmployeesTableProps {
  filters: EmployeesFiltersState;
  setSelectedRows: (selectedRows: UserStatisticOutput[]) => void;
  selectedRows: UserStatisticOutputChecked[];
  getEmployeesData: (pagination: Meta, sorter?: Sorter) => void;
}

const EmployeesTable: React.FC<EmployeesTableProps> = ({ selectedRows, setSelectedRows, filters, getEmployeesData }) => {
  const history = useHistory();

  const [ employeeDrawerData, setEmployeeDrawerData ] = useState<EmployeeDrawerData>({ userId: undefined, visible: false });

  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.EMPLOYEES]);
  const routeState = { from: TAB_EMPLOYEES };

  useEffect(() => {
    getEmployeesData(DEFAULT_PAGINATION_RAW, sorter);
  }, [ filters ]);

  const onChangeRowSelection = (selectedRowKeys: Key[], selectedRowsOnChange: UserStatisticOutputChecked[]) => {
    const markedCheckedSelectedRows = _.map(selectedRowsOnChange, s => ({ ...s, checked: true }));

    setSelectedRows(markedCheckedSelectedRows);
  };

  const rowSelection = {
    onChange: onChangeRowSelection,
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(selectedRows, row => row.userId),
  };

  const onCell = (record: UserStatisticOutputChecked) => ({
    onClick: () => {
      setEmployeeDrawerData({ userId: record.userId, visible: true });
    },
  });

  const onCloseDrawer = () => {
    setEmployeeDrawerData({ userId: undefined, visible: false });
  };

  const renderEntitiesCell = (entities: EntityOutput[]) => {
    if (entities.length > 2) {
      return (
        <Tooltip
          title={_.map(entities, 'name').join(', ')}
        >
          <Space direction="vertical" size={2}>
            <span>{`${entities[0].name}, `}</span>
            <span>
              {entities[1].name}
              &nbsp;
              <span className={s.expandableCell}>
                {`+${entities.length - 2}`}
              </span>
            </span>
          </Space>
        </Tooltip>
      );
    }

    return (
      <Space direction="vertical" size={2}>
        { _.map(entities, 'name').join(', ') }
      </Space>
    );
  };

  const renderColumns = {
    id: {
      onCell,
      render: (text: string, record: UserStatisticOutputChecked) => record.userId,
    },
    fullname: {
      onCell,
      render: (text: string, record: UserStatisticOutputChecked) => (
        <Space>
          <TableAvatar url={record.avatarUrl} />
          <span title={`${record.forename} ${record.surname}`} className={s.oneLineText}>{`${record.forename} ${record.surname}`}</span>
        </Space>
      ),
    },
    entities: {
      onCell,
      render: (entities: EntityOutput[]) => renderEntitiesCell(entities),
    },
    currentMonthHours: {
      onCell,
      render: (text: string) => `${text}h`,
    },
    occupation: {
      onCell,
      render: (text: string, record: UserStatisticOutputChecked) => {
        const mainSkill = record?.primarySkill?.skill?.name || '';

        return (
          <div>
            {`${mainSkill} ${OCCUPATIONS[record.occupation].name}`}
          </div>
        );
      },
    },
    skillsCount: { onCell },
    skillsetLastUpdate: {
      onCell,
      render: (date: string) => renderSkillUpdatedDays(date),
    },
    timesheetLastUpdate: {
      onCell,
      render: (date: string) => renderTimesheetUpdatedDays(date),
    },
    authorAddedBy: {
      onCell,
      render: (text: string, record: UserStatisticOutputChecked) => {
        if (!record?.author?.forename && !record?.author?.surname) {
          return null;
        }

        return `${_.upperFirst(record.author.forename)} ${_.upperFirst(record.author.surname)}`;
      },
    },
    authorAddedOn: {
      onCell,
      render: (text: string, record: UserStatisticOutputChecked) => {
        const isValidDate = dayjs(record.creationDate).isValid();
        if (!isValidDate) {
          return null;
        }

        return dayjs(record.creationDate).format(RU_DOT_DATE_FORMAT);
      },
    },
    actions: {
      render: (text: string, record: UserStatisticOutputChecked) => (
        <div className={s.buttonsRow}>
          {
            (record.usertype !== USER_TYPES.BOT) && (
              <Link
                title="Show timesheet"
                to={{ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${record.userId}/${EDIT_PROFILE_TABS.timesheet.path}`, state: { from: TAB_EMPLOYEES } }}
                className={s.viewUserTimeSheet}
              >
                <TimeIcon />
              </Link>
            )
          }

          <Link
            title="Edit profile"
            to={{ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${record.userId}/${EDIT_PROFILE_TABS.general.path}`, state: { from: TAB_EMPLOYEES } }}
            className={s.viewUserTimeSheet}
          >
            <EditIcon />
          </Link>
          <UserRowPopover
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            getEmployeesData={() => getEmployeesData(meta, sorter)}
            employee={record}
          />
        </div>
      ),
    },
  };

  const filteredColumns = () => {
    switch (filters.active) {
      case 'invited':
        return _.filter(EMPLOYEES_COLUMNS, column => !_.includes([ 'primarySkill', 'currentMonthHours', 'skillsCount', 'skillsetLastUpdate', 'timesheetLastUpdate' ], column.dataIndex));
      default:
        return _.filter(EMPLOYEES_COLUMNS, column => !_.includes([ 'authorAddedBy', 'authorAddedOn' ], column.dataIndex));
    }
  };

  const renderViewProfileButton = () => (
    <Button
      type="primary"
      className={cx(buttons.qsButtonPrimary, s.cardButton)}
      key="edit button"
      onClick={() => {
        if (!employeeDrawerData.userId) {
          return;
        }

        history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${PROFILE_TABS.general.path}`, state: routeState });
      }}
    >
      View profile
    </Button>
  );

  const renderViewSkillsetButton = (userData: UserDetails) => {
    if (userData?.usertype === USER_TYPES.BOT) {
      return null;
    }

    return (
      <Button
        type="primary"
        className={cx(buttons.qsButtonPrimary, s.cardButton)}
        key="view skillset"
        onClick={() => {
          if (!employeeDrawerData.userId) {
            return;
          }

          history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${EDIT_PROFILE_TABS.skillset.path}`, state: routeState });
        }}
      >
        View skillset
      </Button>
    );
  };

  const renderViewTimesheetButton = (userData: UserDetails) => {
    if (userData?.usertype === USER_TYPES.BOT) {
      return null;
    }

    return (
      <Button
        type="primary"
        className={cx(buttons.qsButtonPrimary, s.cardButton)}
        key="view timesheet"
        onClick={() => {
          if (!employeeDrawerData.userId) {
            return;
          }

          history.push({ pathname: `${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${employeeDrawerData.userId}/${EDIT_PROFILE_TABS.timesheet.path}`, state: routeState });
        }}
      >
        View timesheet
      </Button>
    );
  };

  return (
    <>
      <BasicTable
        rowKey="userId"
        className={s.employeesTable}
        tableName={TABLE_NAMES.EMPLOYEES}
        getData={getEmployeesData}
        initialRequest={false}
        columns={filteredColumns()}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        rowSelection={rowSelection}
        rowClassName={() => s.employeeRow}
        bordered
      />

      <EmployeeDrawer
        drawerData={employeeDrawerData}
        onClose={onCloseDrawer}
        externalButtons={[ renderViewProfileButton, renderViewSkillsetButton, renderViewTimesheetButton ]}
      />
    </>
  );
};

export default EmployeesTable;
