import * as Actions from '../constants/Logs';
import _ from 'lodash';
import moment from 'moment';
import Subtypes from '../constants/__common__/Subtypes';

const firstDay = moment().date(1).format('YYYY-MM-DD');
const lastDay = moment().date(moment().daysInMonth()).format('YYYY-MM-DD');


const InitialState = {
  data: [],
  startDate: firstDay,
  endDate: lastDay,
  filter: {
    string: '',
    by: 'Action'
  },
  sortBy: {
    column: 'date',
    up: true
  },
  activePage: 1,
  amountOfRows: 20,
  totalAmount: 0
};

function sortUp(name, a, b) {
  if (!a[name]) return 1;
  if (!b[name]) return -1;
  return a[name].toLowerCase() < b[name].toLowerCase() ? 1 : -1;
}

function sortDown(name, a, b) {
  if (!a[name]) return 1;
  if (!b[name]) return -1;
  return a[name].toLowerCase() > b[name].toLowerCase() ? 1 : -1;
}

function changeSort(state, sortBy) {
  const { column, up } = sortBy;
  if(state.sortBy.column !== sortBy.column || state.sortBy.up !== sortBy.up) {
    let newState = _.cloneDeep(state);
    newState.data.sort(
      up
        ? sortUp.bind(this, column)
        : sortDown.bind(this, column)
    );
    return { ...newState, sortBy: { column, up } };
  } else {
    return state;
  }
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;
    case Actions.CHANGE_START_DATE:
      return { ...state, startDate: action.payload };
    case Actions.CHANGE_END_DATE:
      return { ...state, endDate: action.payload };
    case Actions.CHANGE_FILTER:
      return { ...state, filter: { string: action.payload.string, by: action.payload.by }};
    case Actions.CHAGNE_SORT_BY:
      return changeSort(state, action.payload);
    case Actions.GET_LOGS:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, loading: true };
        case Subtypes.Request.Success:
          return { ...state, data: action.payload, totalAmount: action.meta, loading: false };
        case Subtypes.Request.Error:
          return { ...state, loading: false };
        default:
          return state;
      }
    case Actions.CHANGE_ACTIVE_TS_PAGE:
      return { ...state, activePage: action.payload };
    case Actions.CHANGE_MAX_ROWS_COUNT:
      return { ...state, amountOfRows: action.payload };
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
