import { Column } from 'components/_shared/Table/helpers/types';

export const VACATION_REPORT_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'fullname',
    key: 'fullname',
    sorter: true,
  },
  {
    title: 'Creation date',
    dataIndex: 'creationDate',
    key: 'creationDate',
  },
  {
    title: 'Prescribed',
    dataIndex: 'prescribed',
    key: 'prescribed',
  },
  {
    title: 'Spent',
    dataIndex: 'spent',
    key: 'spent',
  },
  {
    title: 'Remains',
    dataIndex: 'remains',
    key: 'remains',
  },
];
