import _ from 'lodash';

import {
  PATH_ADMINISTRATION,
  PATH_FIND_SPECIALISTS, PATH_REPORTS,
  PATH_TRACKER,
  PATH_PROFILE,
  PATH_TOOLS,
  TAB_PARTNERS,
  TAB_SKILL_TAGS,
  TAB_HOUR_REPORT,
  TAB_CURATORS_TIMESHEET,
} from '../../../../../helpers/constants/_common/MenuCases';
import { AccessEnum } from '../../../helpers/types';
import { MenuField } from './types';
import PROFILE_TABS from '../../../../../helpers/constants/_common/tabs/profile';
import { USER_ROLES } from '../../../../../api/User/constants';

export const MENU_FIELDS: MenuField[] = [
  {
    path: () => PATH_TRACKER,
    label: 'Timesheet',
    type: AccessEnum.User,
    iconFileName: 'WorkedHours',
  },
  {
    path: () => `${PATH_PROFILE}/${PROFILE_TABS.general.path}`,
    label: 'Profile',
    type: AccessEnum.User,
    iconFileName: 'Employees',
  },
  {
    path: () => `${PATH_REPORTS}/${TAB_HOUR_REPORT}`,
    label: 'Reports',
    type: AccessEnum.User,
    iconFileName: 'Report',
  },
  {
    path: (params) => {
      if (params?.permissions && (_.includes(params?.permissions, USER_ROLES.CURATOR) && !_.includes(params?.permissions, USER_ROLES.ADMIN))) {
        return `${PATH_ADMINISTRATION}/${TAB_CURATORS_TIMESHEET}`;
      }

      return `${PATH_ADMINISTRATION}/${TAB_PARTNERS}`;
    },
    label: 'Administration',
    type: [ AccessEnum.Admin, AccessEnum.Curator ],
    iconFileName: 'Partners',
  },
  {
    path: () => `${PATH_FIND_SPECIALISTS}/find`,
    label: 'Find specialists',
    type: [ AccessEnum.Admin, AccessEnum.ProfilesViewer ],
    iconFileName: 'FindSpecialists',
  },
  {
    path: () => `${PATH_TOOLS}/${TAB_SKILL_TAGS}`,
    label: 'Tools',
    type: AccessEnum.Admin,
    iconFileName: 'Administration',
  },
];

export const MOBILE_MENU_FIELDS: MenuField[] = [
  {
    path: () => PATH_TRACKER,
    label: 'Tracker',
    type: AccessEnum.User,
    iconFileName: 'WorkedHours',
  },
  {
    path: () => `${PATH_PROFILE}/${PROFILE_TABS.general.path}`,
    label: 'Profile',
    type: AccessEnum.User,
    iconFileName: 'Employees',
  },
  {
    path: () => `${PATH_REPORTS}/${TAB_HOUR_REPORT}`,
    label: 'Reports',
    type: AccessEnum.User,
    iconFileName: 'Report',
  },
];
