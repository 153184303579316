import React, { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { UseApiOptions, UseApiError } from './types';

type UseApiRequestFunction = (...rest: any[]) => Promise<any>;
type UseApiResult<T, D> = [ T, D, boolean, React.Dispatch<D>, UseApiError, React.Dispatch<any> ];

const useApi = <T extends UseApiRequestFunction, D>(request: T, options?: UseApiOptions<D>) => {
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState<D | undefined>(() => options?.defaultData);
  const [ error, setError ] = useState<UseApiError>({});

  const execute = useCallback(async (...args: any) => {
    setLoading(true);

    return request(...args)
      .then((response: AxiosResponse<D>) => {
        setData(options?.afterRequest ? options.afterRequest(response) : response);

        return response;
      })
      .catch((error: AxiosError) => {
        const errorData = options?.catchHandler ? options.catchHandler(error) : error?.response?.data;

        if (errorData) {
          setError(errorData);
        }

        return Promise.reject(error);
      })
      .finally(() => setLoading(false));
  }, [ request ]);

  return [
    execute,
    data,
    loading,
    setData,
    error,
    setError,
  ] as UseApiResult<T, D>;
};

export default useApi;
