import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import modals from 'helpers/styles/components/modals.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';

import { ReactComponent as SelectIcon } from '../../../../helpers/icons/SelectArrow.svg';
import s from './helpers/styles.module.sass';
import { NOTIFICATION_MODAL_OPTIONS } from './helpers/constants';
import { sendNotificationToUser } from '../../../../api/User/requests';
import { NotificationCreate, NotificationTypes } from '../../../../api/Notifications/types';
import { UserStatisticOutput } from '../../../../api/User/types';

interface NotificationModalProps {
  employeeData: UserStatisticOutput;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

type NotificationModalFields = Omit<NotificationCreate, 'userId'>;

const NotificationModal: React.FC<NotificationModalProps> = ({ visible, setVisible, employeeData }) => {
  const [ modalFields, setModalFields ] = useState<NotificationModalFields>({ type: NOTIFICATION_MODAL_OPTIONS.update.value });
  const [ saveLoading, setSaveLoading ] = useState(false);

  const onSendNotification = () => {
    setSaveLoading(true);

    sendNotificationToUser({
      userId: employeeData.userId,
      ...modalFields,
    })
      .then(() => {
        setVisible(false);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const notificationOptions = _.map(NOTIFICATION_MODAL_OPTIONS, o => <Select.Option value={o.value} key={o.value}>{o.label}</Select.Option>);

  return (
    <Modal
      title="Send notification"
      className={modals.qsBasicAntdModal}
      visible={visible}
      okText="Send"
      width={500}
      centered
      confirmLoading={saveLoading}
      onOk={onSendNotification}
      onCancel={() => {
        setModalFields({ type: NOTIFICATION_MODAL_OPTIONS.update.value });
        setSaveLoading(false);
        setVisible(false);
      }}
      okButtonProps={{
        className: modals.modalOkBtn,
        size: 'large',
      }}
      cancelButtonProps={{
        className: modals.modalCancelBtn,
        type: 'text',
      }}
    >
      <Select
        dropdownClassName={selects.selectDropdown}
        className={cx(selects.qsSelect, s.notificationModalSelect)}
        suffixIcon={<SelectIcon />}
        size="large"
        onChange={(value: NotificationTypes) => setModalFields({ ...modalFields, type: value })}
        value={modalFields?.type}
        bordered={false}
      >
        { notificationOptions }
      </Select>
    </Modal>
  );
};
export default NotificationModal;
