import * as Actions from '../constants/UsersHourReport';
import { TABLE_TYPES } from '../constants/UsersHourReport';
import * as Dispatching from '../constants/__common__/EmailDispatch';
import dayjs from 'dayjs';
import Subtypes from 'V1/constants/__common__/Subtypes';

const firstDay = dayjs().date(1).format('YYYY-MM-DD');
const lastDay = dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD');

let InitialState = {
  startDate: firstDay,
  endDate: lastDay,
  currentMonth: dayjs().month() + 1,
  currentYear: dayjs().get('year'),
  tableType: TABLE_TYPES.REPORT,
  usersFilter: '',
  usersGroup: [],
  monthsStatus: [],
  showQuickTip: true,
  sortBy: 0,
  sortOrder: null,
  groupRows: false,
  isLoading: true,
  dispatchStatus: Dispatching.INITIAL,
  showRemindersModal: false,
  individualReminder: null,
  userForReminding: null
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CHANGE_STARTDATE:
      return { ...state, startDate: action.payload };
    case Actions.CHANGE_ENDDATE:
      return { ...state, endDate: action.payload };
    case Actions.CHANGE_PAYMENT: {
      let reportIndex = state.objects.findIndex(report =>
        report.userId === action.payload.user &&
        report.partnerId === action.payload.partner &&
        report.activityId === action.payload.activity);
      return { ...state, objects: state.objects.map((item, i) => {
        if(i !== reportIndex) return item;
        return { ...item, payment: action.payload.payment }
      }) };
    }
    case Actions.RECEIVE_HOURREPORT:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, isLoading: action.payload };
        case Subtypes.Request.Success:
          return {
            ...state,
            objects: action.payload,
            error: false,
            isLoading: false
          };
        case Subtypes.Request.Error:
          return { ...state, error: true, isLoading: false, data: action.payload };
        default:
          return state;
      }
    case Actions.REMOVE_OBJECTS:
      return { ...state, objects: [] };
    case Actions.CHANGE_TABLE_TYPE:
      return { ...state, tableType: action.payload };
    case Actions.CHANGE_USERS_FILTER:
      return { ...state, usersFilter: action.payload };
    case Actions.CHANGE_USERS_GROUP:
      return { ...state, usersGroup: action.payload };
    case Actions.CHANGE_MONTHS_STATUS:
      return { ...state, monthsStatus: action.payload };
    case Actions.SHOW_QUICK_TIP:
      return { ...state, showQuickTip: action.payload };
    case Actions.SORT_BY:
      return { ...state, sortBy: action.payload.sortBy, sortOrder: action.payload.sortOrder };
    case Actions.HOUR_REPORT_INITIATE:
      return InitialState;
    case Actions.GROUP_ROWS:
      return { ...state, groupRows: !state.groupRows };
    case Actions.CHANGE_TIME_PERIOD:
      const { startDate, endDate, currentMonth, currentYear } = action.payload;
      return { ...state, startDate, endDate, currentMonth, currentYear };
    case Actions.CHANGE_DISPATCH_STATUS:
      return { ...state, dispatchStatus: action.payload };
    case Actions.SHOW_REMINDERS_MODAL:
      return { ...state,
        showRemindersModal: action.payload.bool,
        individualReminder: action.payload.individual
      };
    case Actions.UHR_SELECT_USER:
      return { ...state, userForReminding: action.payload};
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
