import { DispatchType } from '../../helpers/types/_common';
import * as ErrorActions from '../../helpers/constants/_common/actions/ErrorHandler';

export const setError = (error: object) => {
  return (dispatch: DispatchType) => {
    dispatch({ type: ErrorActions.SET_ERROR, payload: { ...error, errorId: Math.random() } });
  };
};

export const clearError = () => {
  return (dispatch: DispatchType) => {
    dispatch({ type: ErrorActions.CLEAR_ERROR });
  };
};
