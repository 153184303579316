export interface OccupationConstant {
  name: string;
}

export enum EmployeeOccupationEnum {
  FullstackEngineer = 'FULLSTACK_ENGINEER',
  QualityAssuranceEngineer = 'QA_ENGINEER',
  DevOpsEngineer = 'DEVOPS_ENGINEER',
  MobileEngineer = 'MOBILE_ENGINEER',
  UXEngineer = 'UX_ENGINEER',
  InformationSecurityEngineer = 'INFORMATION_SECURITY_ENGINEER',
  BackendEngineer = 'BACKEND_ENGINEER',
  OfficeManager = 'OFFICE_MANAGER',
  SalesSpecialist = 'SALES_SPECIALIST',
  FrontendEngineer = 'FRONTEND_ENGINEER',
  MachineLearningEngineer = 'MACHINE_LEARNING_ENGINEER',
  AutomationQualityAssuranceEngineer = 'AUTOMATION_QA_ENGINEER',
  EmbeddedEngineer = 'EMBEDDED_ENGINEER',
  BusinessAnalyst = 'BUSINESS_ANALYST',
  SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
  NetworkEngineer = 'NETWORK_ENGINEER',
}

export enum EmployeeOccupationEnumRaw {
  FULLSTACK_ENGINEER = 'FullstackEngineer',
  QA_ENGINEER = 'QualityAssuranceEngineer',
  DEVOPS_ENGINEER = 'DevOpsEngineer',
  MOBILE_ENGINEER = 'MobileEngineer',
  UX_ENGINEER = 'UXEngineer',
  INFORMATION_SECURITY_ENGINEER = 'InformationSecurityEngineer',
  BACKEND_ENGINEER = 'BackendEngineer',
  OFFICE_MANAGER = 'OfficeManager',
  SALES_SPECIALIST = 'SalesSpecialist',
  FRONTEND_ENGINEER = 'FrontendEngineer',
  MACHINE_LEARNING_ENGINEER = 'MachineLearningEngineer',
  AUTOMATION_QA_ENGINEER = 'AutomationQualityAssuranceEngineer',
  EMBEDDED_ENGINEER = 'EmbeddedEngineer',
  BUSINESS_ANALYST = 'BusinessAnalyst',
  SYSTEM_ADMINISTRATOR = 'SystemAdministrator',
  NETWORK_ENGINEER = 'NetworkEngineer',
}

export enum EmployeeOccupationNames {
  FULLSTACK_ENGINEER = 'Fullstack Engineer',
  QA_ENGINEER = 'Quality Assurance Engineer',
  DEVOPS_ENGINEER = 'DevOps Engineer',
  MOBILE_ENGINEER = 'Mobile Engineer',
  UX_ENGINEER = 'UX Engineer',
  INFORMATION_SECURITY_ENGINEER = 'Information Security Engineer',
  BACKEND_ENGINEER = 'Backend Engineer',
  OFFICE_MANAGER = 'Office Manager',
  SALES_SPECIALIST = 'Sales Specialist',
  FRONTEND_ENGINEER = 'Frontend Engineer',
  MACHINE_LEARNING_ENGINEER = 'Machine Learning Engineer',
  AUTOMATION_QA_ENGINEER = 'Automation Quality Assurance Engineer',
  EMBEDDED_ENGINEER = 'Embedded Engineer',
  BUSINESS_ANALYST = 'Business Analyst',
  SYSTEM_ADMINISTRATOR = 'System Administrator',
  NETWORK_ENGINEER = 'Network Engineer',
}
