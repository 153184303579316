import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import buttons from 'helpers/styles/components/buttons.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { getEntities } from 'redux/Entities/actions';
import { NewEntityBody } from 'api/Entities/types';
import { createNewEntities } from 'api/Entities/requests';

import s from './styles.module.sass';

interface EntitiesSettingsProps {
}

const EntitiesSettings: React.FC<EntitiesSettingsProps> = () => {
  const dispatch: DispatchType = useDispatch();

  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ validateError, setValidateError ] = useState<object>({});

  useEffect(() => {
    if (!popoverVisible) {
      setValidateError({});
    }
  }, [ popoverVisible ]);

  const { meta, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.ENTITIES]);

  const getEntitiesList = () => {
    const body = {
      ...meta,
      ...sorter,
    };

    return dispatch(getEntities(body, TABLE_NAMES.ENTITIES));
  };

  const handleOnSave = (values: NewEntityBody) => {
    setSaveLoading(true);

    return createNewEntities(values)
      .then(() => {
        setPopoverVisible(false);
        return getEntitiesList();
      })
      .catch((error) => {
        setValidateError(error.response.data);

        return Promise.reject();
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const content = (
    <Form
      className={s.newEntityForm}
      onFinish={handleOnSave}
    >

      <Form.Item
        name="name"
        validateStatus={_.has(validateError, 'name') ? 'error' : undefined}
        help={_.has(validateError, 'name') ? validateError.name : undefined}
        rules={[ { required: true, message: 'Please input entity name' } ]}
      >
        <Input
          className={cx(inputs.qsInput, s.newEntityInput)}
          onChange={() => {
            if (_.has(validateError, 'name')) {
              setValidateError(_.omit(validateError, 'name'));
            }
          }}
          placeholder="Type name of entity..."
          size="large"
          maxLength={255}
          allowClear
          autoFocus
        />

      </Form.Item>

      <Form.Item name="description">
        <TextArea
          placeholder="Description (optional)"
          className={inputs.trackerTextArea}
          maxLength={255}
          rows={5}
        />
      </Form.Item>

      <Divider />

      <div className={s.newEntityFormItem}>
        <Button
          htmlType="submit"
          type="primary"
          className={buttons.qsButtonPrimary}
          loading={saveLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  );

  return (
    <div className={s.entitiesSettings}>
      <Popover
        align={{ offset: [ -1, 0 ] }}
        overlayClassName={cx(s.addEntitiesPopover)}
        placement="bottomRight"
        content={content}
        trigger="click"
        onVisibleChange={setPopoverVisible}
        visible={popoverVisible}
        destroyTooltipOnHide
      >
        <Button
          className={cx(buttons.qsButtonPrimary, { [s.openedNewEntityButton]: popoverVisible })}
          type="primary"
          size="large"
        >
          <div className={buttons.iconTextWrapper}>
            <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
            <span>Add new</span>
          </div>
        </Button>
      </Popover>
    </div>
  );
};

export default EntitiesSettings;
