import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from 'antd';

import { UsersBCDay, UsersBCMonth } from 'api/TimeSheet/types';
import { DEFAULT_FULL_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { ReactComponent as CloseIcon } from 'helpers/icons/CloseCross24.svg';

import S from './helpers/styles.module.sass';
import { ChosenActivityInfo, MobileTimesheetPanel, PanelsDataProps, UserHourData } from './helpers/types';
import RegularPanel from './components/RegularPanel/RegularPanel';
import ChooseActivityPanel from './components/ChooseActivityPanel/ChooseActivityPanel';
import HourDetailsPanel from './components/HourDetailsPanel/HourDetailsPanel';
import UserHoursList from './components/UserHoursList/UserHoursList';
import { ReactComponent as ArrowIcon } from './helpers/arrowIcon.svg';

const cx = classNames.bind(S);

interface MobilePanelProps {
  addWorkedHoursToDate: (date: string, hours: number) => void;
  setPickerDisabled: (status: boolean) => void;
  refreshCalendar: () => void;
  BCMonth: UsersBCMonth;
  selectedDayObj: UsersBCDay;
  selectedDateObj: Dayjs;
  isBCLoading: boolean;
  userId: number;
}

const PANELS_SWITCHING_TIMEOUT = 250;

const MobilePanel: React.FC<MobilePanelProps> = (props) => {
  const { selectedDayObj, isBCLoading, userId, addWorkedHoursToDate, setPickerDisabled, selectedDateObj, BCMonth, refreshCalendar } = props;
  const defaultData: PanelsDataProps = {
    activePanel: MobileTimesheetPanel.Regular,
    isInProgress: false,
  };

  const defaultUserHour: UserHourData = {
    startTime: dayjs().format(DEFAULT_FULL_DATE_FORMAT),
    endTime: dayjs().format(DEFAULT_FULL_DATE_FORMAT),
    activity: {
      activityId: 0,
      partnerId: 0,
      mask: 'DEFAULT',
      partnerName: '',
      activityName: '',
      partnerColor: '',
      activityColor: '',
    },
    comment: '',
  };

  const [ panelsData, setPanelsData ] = useState<PanelsDataProps>(defaultData);
  const [ userHourData, setUserHourData ] = useState<UserHourData>(defaultUserHour);

  useEffect(() => {
    setPickerDisabled(![ MobileTimesheetPanel.Regular, MobileTimesheetPanel.UserHoursList ].includes(panelsData.activePanel));
  }, [ panelsData ]);

  const changePanel = (panelName: MobileTimesheetPanel) => {
    setPanelsData({
      ...panelsData,
      isInProgress: true,
    });

    setTimeout(() => {
      setPanelsData({
        activePanel: panelName,
        isInProgress: false,
      });
    }, PANELS_SWITCHING_TIMEOUT);
  };

  const setUserHourActivity = (activity: ChosenActivityInfo) => {
    setUserHourData({
      ...defaultUserHour,
      activity,
    });
  };

  const renderPanel = () => {
    switch (panelsData.activePanel) {
      default:
      case MobileTimesheetPanel.Regular:
        return (
          <RegularPanel
            isBCLoading={isBCLoading}
            BCMonth={BCMonth}
            selectedDateObj={selectedDateObj}
            selectedDayObj={selectedDayObj}
            changePanel={changePanel}
          />
        );
      case MobileTimesheetPanel.ChooseActivity:
        return (
          <ChooseActivityPanel
            changePanel={changePanel}
            setUserHourActivity={setUserHourActivity}
            userId={userId}
          />
        );
      case MobileTimesheetPanel.HourDetails:
        return (
          <HourDetailsPanel
            addWorkedHoursToDate={addWorkedHoursToDate}
            userHourData={userHourData}
            selectedDayObj={selectedDayObj}
            changePanel={changePanel}
            userId={userId}
          />
        );
      case MobileTimesheetPanel.UserHoursList:
        return (
          <UserHoursList
            changePanel={changePanel}
            selectedDayObj={selectedDayObj}
            refreshCalendar={refreshCalendar}
            userId={userId}
          />
        );
    }
  };

  return (
    <>
      {panelsData.activePanel !== MobileTimesheetPanel.Regular && (
        <Button
          type="primary"
          className={S.backButton}
          onClick={() => changePanel(MobileTimesheetPanel.Regular)}
        >
          <CloseIcon />
        </Button>
      )}
      <div className={cx(S.panel, {
        [S.regularPanel]: panelsData.activePanel === MobileTimesheetPanel.Regular,
        [S.chooseActivityPanel]: panelsData.activePanel === MobileTimesheetPanel.ChooseActivity,
        [S.hourDetailsPanel]: panelsData.activePanel === MobileTimesheetPanel.HourDetails,
        [S.userHoursListPanel]: panelsData.activePanel === MobileTimesheetPanel.UserHoursList,
      })}
      >
        <div className={S.marker}>
          <ArrowIcon className={S.panelArrow} />
        </div>
        <div className={S.panelInner}>
          {renderPanel()}
        </div>
      </div>
    </>
  );
};

export default MobilePanel;
