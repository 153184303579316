import React from 'react';
import className from 'classnames/bind';

import S from './styles.module.css';

const cx = className.bind(S);

interface SpinnerComponentProps {
  style?: React.CSSProperties;
  hidden?: boolean;
  className?: string;
  small?: boolean
}

const SpinnerNew: React.FC<SpinnerComponentProps> = ({
  hidden,
  style,
  className,
  small,
}) => (
  <div
    className={cx('spinner-bar-container', {
      'spinner-bar-container--hidden': hidden,
    })}
    style={style}
  >
    <div style={style} className={cx('spinner', className)}>
      <div className={cx('spinner-ripple-wrapper', { small })}>
        <div className={cx('spinner-ripple', { small })}>
          <div />
          <div />
        </div>
      </div>
    </div>
  </div>
);

export default SpinnerNew;
