export const HOUR_REPORT_INITIATE = '[USERS HOUR REPORT] HOUR_REPORT_INITIATE';

export const CHANGE_STARTDATE = '[USERS HOUR REPORT] CHANGE_STARTDATE';
export const CHANGE_ENDDATE = '[USERS HOUR REPORT] CHANGE_ENDDATE';
export const CHANGE_TIME_PERIOD = '[USERS HOUR REPORT] CHANGE_TIME_PERIOD';

export const RECEIVE_HOURREPORT = '[USERS HOUR REPORT] RECEIVE_HOURREPORT';
export const RECEIVE_HOURREPORT_REQUEST = '[USERS HOUR REPORT] RECEIVE_HOURREPORT_REQUEST';
export const RECEIVE_HOURREPORT_DATA = '[USERS HOUR REPORT] RECEIVE_HOURREPORT_DATA';
export const RECEIVE_HOURREPORT_ERROR = '[USERS HOUR REPORT] RECEIVE_HOURREPORT_ERROR';

export const CHANGE_TABLE_TYPE = '[USERS HOUR REPORT] CHANGE_TABLE_TYPE';
export const GROUP_ROWS = '[USERS HOUR REPORT] GROUP_ROWS';
export const CHANGE_USERS_FILTER = '[USERS HOUR REPORT] CHANGE_USERS_FILTER';
export const SORT_BY = '[USERS HOUR REPORT] SORT_BY';
export const SHOW_QUICK_TIP = '[USERS HOUR REPORT] SHOW_QUICK_TIP';

export const CHANGE_USERS_GROUP = '[USERS HOUR REPORT] CHANGE_USERS_GROUP';
export const CHANGE_MONTHS_STATUS = '[USERS HOUR REPORT] CHANGE_MONTHS_STATUS';
export const REMOVE_OBJECTS = '[USERS HOUR REPORT] REMOVE_OBJECTS';
export const CHANGE_PAYMENT = '[USERS HOUR REPORT] CHANGE_PAYMENT';

export const CHANGE_DISPATCH_STATUS = '[USERS HOUR REPORT] CHANGE_DISPATCH_STATUS';
export const SHOW_REMINDERS_MODAL = '[USERS HOUR REPORT] SHOW_REMINDERS_MODAL';
export const UHR_SELECT_USER = '[USERS HOUR REPORT] UHR_SELECT_USER';

export const TABLE_TYPES = { PAYMENT: 'payment', REPORT: 'report'};
