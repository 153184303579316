import dayjs from 'dayjs';
import _ from 'lodash';

// eslint-disable-next-line import/no-cycle
import authRequests from 'V1/requests/Auth';
import configureStore from 'redux/_reducer/configureStore';
import { externalRedirectToLogin } from 'V1/actions/Login/LoginActions';

import { JWTData } from './type';

let refreshTokenRequest: Promise<any> | null = null;

const TIME_OFFSET_MULTIPLIER = 0.9;

const getAccessToken = () => localStorage.getItem('accessToken');

const getRefreshToken = () => localStorage.getItem('refresh');

const getAccessTokenExpiringTime = () => {
  const storedTime = localStorage.getItem('tokensExpiresAt');
  
  if (!storedTime || dayjs(parseInt(storedTime, 10)).format() === 'Invalid Date') return dayjs();

  return dayjs(parseInt(storedTime, 10));
};

const setJWTData = (data: JWTData) => {
  const { refreshToken, accessToken, expiresIn } = data;  
  const accessTokenExpiredAt = dayjs().valueOf() + parseInt(expiresIn, 10) * 1000 * TIME_OFFSET_MULTIPLIER;
  const timeToRefreshToken = accessTokenExpiredAt;

  if (!refreshToken || !accessToken || !expiresIn) return;

  if (dayjs(accessTokenExpiredAt).isBefore(dayjs())) {
    localStorage.setItem('tokensExpiresAt', dayjs().valueOf().toString());
  } else {
    localStorage.setItem('tokensExpiresAt', timeToRefreshToken.toString());
  }

  localStorage.setItem('refresh', refreshToken);  
  localStorage.setItem('accessToken', accessToken);
};

const clearJWTData = () => {
  localStorage.removeItem('refresh');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('tokensExpiresAt');
};

const checkAccessTokenValidity = () => {
  const expiringTime = getAccessTokenExpiringTime();

  const isNotExpiredYet = dayjs().isBefore(expiringTime);
  const accessToken = getAccessToken();

  return !_.isNil(accessToken) && isNotExpiredYet;
};

const checkRefreshTokenValidity = () => {
  const refreshToken = getRefreshToken();

  return !_.isNil(refreshToken);
};

const refreshAccessToken = async () => {
  let res = {
    accessToken: null,
    refreshToken: null,
    expiresIn: '300',
  };

  if (refreshTokenRequest === null) {
    refreshTokenRequest = authRequests.updateTokens();
  }

  res = await refreshTokenRequest;

  refreshTokenRequest = null;

  if (_.isNil(res.accessToken) || _.isNil(res.refreshToken) || _.isNil(res.expiresIn)) {
    configureStore.dispatch(externalRedirectToLogin());
    
    return null;
  } else {
    setJWTData({
      refreshToken: res.refreshToken,
      accessToken: res.accessToken,
      expiresIn: res.expiresIn,
    });

    return res.accessToken;
  }
};

const TokenStore = {
  getAccessToken,
  getRefreshToken,
  getAccessTokenExpiringTime,
  setJWTData,
  clearJWTData,
  checkAccessTokenValidity,
  checkRefreshTokenValidity,
  refreshAccessToken,
};

export default TokenStore;
