import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PROFILE_TABS from 'helpers/constants/_common/tabs/profile';

import SkillSet from './components/SkillSet';
import PersonalInfo from './components/PersonalInfo';
import { Redux } from '../../helpers/types/_common';

const ProfileTabContent = () => {
  const user = useSelector((state: Redux) => state.login);

  const { tab: currentTab } = useParams<{ tab: string }>();

  const renderPersonalInfoContent = () => {
    if (currentTab !== PROFILE_TABS.general.path) {
      return null;
    }

    return <PersonalInfo user={user} />;
  };

  const renderSkillSetContent = () => {
    if (currentTab !== PROFILE_TABS.skillset.path) {
      return null;
    }

    return <SkillSet user={user} />;
  };

  return (
    <div>
      {renderPersonalInfoContent()}
      {renderSkillSetContent()}
    </div>
  );
};

export default ProfileTabContent;
