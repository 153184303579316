import dayjs from 'dayjs';
import * as Actions from '../constants/Selection';

let InitialState = {
  day: 1,
  date: dayjs().format('YYYY-MM-DD'),
  columnIndex: 0,
  start: false,
  end: false,
  ctrl: false,
  highlight: {
    day: -1,
    frame: -1
  }
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.START_SELECTING: {
      const { day, date, frame, columnIndex } = action.payload;
      return { ...state, day, date, columnIndex, start: frame, end: frame };
    }
    case Actions.STILL_SELECTING:
      return { ...state, end: action.payload };
    case Actions.SELECTION_CTRL_PRESSED:
      return { ...state, ctrl: action.payload };
    case Actions.SELECTION_DROP_HIGHLIGHT:
      return { ...state, highlight: InitialState.highlight };
    case Actions.SELECTION_HIGHLIGHT: {
      const { day, frame } = action.payload;
      return { ...state, highlight: { day, frame }};
    }
    case Actions.SELECTION_INITIATE:
      return InitialState;
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
