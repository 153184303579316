import React from 'react';
import _ from 'lodash';
import { Space, Tooltip } from 'antd';
import className from 'classnames/bind';

import S from './helpers/styles.module.sass';
import ENGLISH_KNOWLEDGE_STATES from '../../../helpers/constants/Skills/EnglishKnowledgeStates';
import { ReactComponent as English } from '../../../helpers/icons/EnglishNew.svg';
import { ENGLISH_VALUE_BY_TYPE } from './helpers/constants';
import { EnglishLevelEnum } from '../../../helpers/types/_common';
import tooltips from '../../../helpers/styles/components/tooltips.module.sass';

const cx = className.bind(S);

type EngKnowledgeLevelProps = {
  size?: number;
  engViewBox?: string;
  zeroEnglishPlaceholder?: React.ReactNode | string | null;
  englishKnowledgeLevel?: EnglishLevelEnum | string;
};

const EngKnowledgeLevel: React.FC<EngKnowledgeLevelProps> = ({ englishKnowledgeLevel, size, zeroEnglishPlaceholder, engViewBox }) => {
  if (!englishKnowledgeLevel) {
    return null;
  }

  const englishTitle = _.find(ENGLISH_KNOWLEDGE_STATES, [ 'knowledgeId', englishKnowledgeLevel ]);
  const noEnglishPlaceholder = _.isUndefined(zeroEnglishPlaceholder) ? '—' : zeroEnglishPlaceholder;

  const englishOptions = _.reduce(_.drop(ENGLISH_KNOWLEDGE_STATES), (acc, e) => {
    const englishValue = ENGLISH_VALUE_BY_TYPE[englishKnowledgeLevel];
    const engSize = size ? { height: size, width: size } : undefined;

    if (englishValue >= e.value) {
      return [
        ...acc,
        <English key={e.knowledgeId} style={engSize} viewBox={engViewBox} />,
      ];
    }
    return acc;
  }, [] as React.ReactNode[]);

  return (
    <Tooltip title={englishTitle?.name} overlayClassName={tooltips.trackerTooltip}>
      <Space
        className={cx('eng-wrapper')}
        size={4}
        align="center"
      >
        {_.isEmpty(englishOptions) ? noEnglishPlaceholder : englishOptions}
      </Space>
    </Tooltip>
  );
};

export default EngKnowledgeLevel;
