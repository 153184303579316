import _ from 'lodash';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import React from 'react';

import { Sorter } from 'api/_request/types';

import { Column } from './types';
import TableSortIcon from '../components/TableSortIcon/TableSortIcon';

interface SortDirections {
  DESC: string;
  ASC: string;
  ascend: string;
  descend: string;
}
export const SORT_DIRECTIONS: SortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
  ascend: 'ascend',
  descend: 'descend',
};

export const convertSortForBackend = (sorter?: SorterResult<Column>, valueToSnakeCase?: boolean): Sorter => {
  if (!sorter?.order) {
    return {};
  }

  const renamedOrderDirection = (sorter.order === SORT_DIRECTIONS.ascend) ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;

  return {
    orderBy: valueToSnakeCase && _.isString(sorter.field) ? _.snakeCase(sorter.field) : sorter.field,
    orderDirection: renamedOrderDirection,
  };
};

export const updateFieldsBySorter = (columns: any, sorter: any, sortDirection?: SortOrder[]) => _.map(columns, (c) => {
  const renamedOrderDirection = sorter.orderDirection === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.ascend : SORT_DIRECTIONS.descend;
  const sortValue = (c.dataIndex === _.camelCase(sorter?.orderBy)) ? { sortOrder: sorter.orderDirection ? renamedOrderDirection : undefined } : {};
  const sortObject = c.sorter ? sortValue : {};

  if (c.dataIndex === sorter?.field) {
    return {
      ...c,
      ...sortObject,
      title: () => <TableSortIcon sortOrder={sortObject.sortOrder} column={c} sortDirections={sortDirection} />,
    };
  }

  return {
    ...c,
    ...sortObject,
    title: () => <TableSortIcon sortOrder={sortObject.sortOrder} column={c} sortDirections={sortDirection} />,
  };
});
