import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useSelector } from 'react-redux';

import useApi from 'api/hooks/useApi/useApi';
import { getCalendarByMonth, getCalendarByYear } from 'api/BusinessCalendar/requests';
import defaultCalendarGenerator from 'components/BusinessCalendar/helpers/defaultDaysGenerator';
import { DayObj, DayStatuses, Month as MonthObject } from 'api/BusinessCalendar/types';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { isDayOff } from 'api/BusinessCalendar/helpers';
import { Redux } from 'helpers/types/_common';

import S from './styles.module.sass';
import MonthComponent from './components/Month/MonthComponent';
import { JointStatus, JointDay, JointMonth, MonthsList } from './helpers/types';
import defaultJointCalendarGenerator from './helpers/defaultDaysGenerator';

dayjs.extend(objectSupport);

interface BusinessCalendarProps {
  editedMonth: null | number;
  changingMode: 'amount' | 'status';
  setEditedMonth: (month: null | number) => void;
  setChangingMode: (mode: 'amount' | 'status') => void;
}

const BusinessCalendar: React.FC<BusinessCalendarProps> = ({ changingMode, editedMonth, setEditedMonth, setChangingMode }) => {
  const defaultCalendar = useMemo(() => defaultCalendarGenerator(), []);
  const defaultJointCalendar = useMemo(() => defaultJointCalendarGenerator(), []);
  const { selectedYear } = useSelector((state: Redux) => state.businessCalendar2);

  const [ selectedDateObj ] = useState<Dayjs>(dayjs());
  const [ monthsList, setMonthsList ] = useState<MonthsList>(defaultJointCalendar);
  const [ backupMonthsList, setBackupMonthsList ] = useState<MonthsList>(defaultJointCalendar);
  const [ isEdited, setIsEditedStatus ] = useState(true);
  
  const [ getCalendar,, isLoading ] = useApi<typeof getCalendarByYear, MonthObject[]>(getCalendarByYear, { defaultData: defaultCalendar });

  useEffect(() => {
    const ctrlCatcher = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        setChangingMode((changingMode: 'amount' | 'status') => (changingMode === 'amount' ? 'status' : 'amount'));
      }

      if (event.key === 'Escape') {
        setEditedMonth(null);
      }
    };

    document.addEventListener('keydown', ctrlCatcher);
    return () => document.removeEventListener('keydown', ctrlCatcher);
  }, []);

  useEffect(() => {
    Promise.all([
      getCalendarByMonth(selectedDateObj.year() - 1, 12),
      getCalendar(selectedYear),
      getCalendarByMonth(selectedDateObj.year() + 1, 1),
    ]).then((results) => {
      const [ december, calendar, january ] = results;
      const monthsList = convertToJointsMonths(calendar, { january, december });
      
      setMonthsList(monthsList);
      setBackupMonthsList(monthsList);
      setIsEditedStatus(true);
    });
  }, [ selectedYear ]);

  useEffect(() => {
    const isMonthChanged = !_.isEqual(monthsList, backupMonthsList);

    if (!isMonthChanged !== isEdited) setIsEditedStatus(!isMonthChanged);
  }, [ monthsList ]);

  const convertToJointsMonths = (monthsList: MonthObject[], additionalMonths: { january: MonthObject, december: MonthObject }): MonthsList => {
    const jointMonths = _.reduce(monthsList, (result, month, index) => {
      const prevMonth = monthsList[index - 1] || additionalMonths.december;
      const nextMonth = monthsList[index + 1] || additionalMonths.january;

      const generateMonthDays = (direction: 'prev' | 'current' | 'next') => {
        let dayjsObj = dayjs(`${month.year}-${month.month}-1`);

        switch (direction) {
          case 'prev':
            dayjsObj = dayjsObj.subtract(1, 'month');
            break;
          case 'next':
            dayjsObj = dayjsObj.add(1, 'month');
            break;
          default:
            break;
        }

        return _.range(dayjsObj.daysInMonth()).map(date => dayjsObj.date(date + 1).format(DEFAULT_DATE_FORMAT));
      };

      const allDaysFake = [
        ...generateMonthDays('prev'),
        ...generateMonthDays('current'),
        ...generateMonthDays('next'),
      ];

      if (dayjs(allDaysFake[0]).day() !== 1) {
        const day = dayjs(allDaysFake[0]).day() || 7;
        allDaysFake.splice(0, 7 - day + 1);
      }

      if (dayjs(allDaysFake[allDaysFake.length - 1]).day() !== 0) {
        const day = dayjs(allDaysFake[allDaysFake.length - 1]).day();
        allDaysFake.splice(allDaysFake.length - day, day);
      }

      allDaysFake.splice(0, 14);
      allDaysFake.splice(56);

      const allDays: JointDay[] = [
        ...prevMonth.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Cut })),
        ...month.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Regular })),
        ...nextMonth.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Cut })),
      ];
      const allDaysOriginal: JointDay[] = _.filter(allDays, dayObj => allDaysFake.includes(dayObj.day));
      const prevBusinessMonthId = monthsList[index - 1] ? monthsList[index - 1].businessMonthId : additionalMonths.december.businessMonthId;
      const nextBusinessMonthId = monthsList[index + 1] ? monthsList[index + 1].businessMonthId : additionalMonths.january.businessMonthId;

      return {
        ...result,
        [month.month]: {
          ...month,
          prevBusinessMonthId,
          nextBusinessMonthId,
          days: allDaysOriginal,
        },
      };
    }, {} as MonthsList);

    return jointMonths;
  };

  const changeAmountOfDays = (BCMonth: JointMonth, daysToCut: string[]) => {
    const newMonthsList = _.reduce(monthsList, (result, monthObj) => {
      switch (true) {
        case monthObj.month === BCMonth.month: // current month
        case monthObj.month === BCMonth.month + 1 || monthObj.month === BCMonth.month - 1: { // month from which clicked day
          const updatedDays = _.map(monthObj.days, (dayObj) => {
            const newJointStatus = dayObj.jointStatus === JointStatus.Regular ? JointStatus.Cut : JointStatus.Regular;
            return {
              ...dayObj,
              jointStatus: daysToCut.includes(dayObj.day) ? newJointStatus : dayObj.jointStatus,
            };
          });
          return {
            ...result,
            [monthObj.month]: {
              ...monthObj,
              days: updatedDays,
            },
          };
        }
        default:
          return {
            ...result,
            [monthObj.month]: monthObj,
          };
      }
    }, {} as MonthsList);

    setMonthsList(newMonthsList);
  };

  const changeDaysStatus = (BCDay: JointDay, monthNumber: number) => {
    const updatedMonthsList = _.reduce(monthsList, (result, monthObj, key) => {
      if (monthObj.month !== monthNumber) {
        return {
          ...result,
          [key]: monthObj,
        };
      } else {
        const updatedMonthDays = _.reduce(monthObj.days, (result, dayObj) => {
          if (dayObj.day !== BCDay.day) {
            return [ ...result, dayObj ];
          } else {
            const getUpdatedStatus = () => {
              switch (dayObj.status) {
                case DayStatuses.Regular:
                  if (isDayOff(dayObj.day)) {
                    return DayStatuses.DayOff;
                  } else {
                    return DayStatuses.Holiday;
                  }
                case DayStatuses.Holiday:
                case DayStatuses.DayOff:
                case DayStatuses.SuggestedHoliday:
                  return DayStatuses.Regular;
              }
            };
            return [ ...result, {
              ...dayObj,
              status: getUpdatedStatus(),
            } ];
          }
        }, [] as JointDay[]);
        return {
          ...result,
          [key]: {
            ...monthObj,
            days: updatedMonthDays,
          },
        };
      }
    }, {} as MonthsList);

    setMonthsList(updatedMonthsList);
  };

  const restoreMonthData = () => {
    setMonthsList(backupMonthsList);
    setEditedMonth(null);
  };

  const saveChanges = () => {
    if (_.isNull(editedMonth)) return;

    const editedMonthObj = monthsList[editedMonth];

    if (_.isUndefined(editedMonth) || _.isNull(editedMonthObj.businessMonthId)) return;

    const monthsChangedDays = _.reduce(editedMonthObj.days, (result, dayObj, index) => {
      if (!backupMonthsList[editedMonth]?.days[index]) return result;

      const isDayStatusChanged = backupMonthsList[editedMonth].days[index].status !== dayObj.status;
      const isJointStatusChanged = backupMonthsList[editedMonth].days[index].jointStatus !== dayObj.jointStatus;

      if (isDayStatusChanged || isJointStatusChanged) {
        return [
          ...result,
          dayObj,
        ];
      } else {
        return result;
      }
    }, [] as JointDay[]);

    const { prevMonthDays, thisMonthDays, nextMonthsDays } = _.reduce(monthsChangedDays, (result, dayObj) => {
      if (dayObj.jointStatus === JointStatus.Regular || dayjs(dayObj.day).month() + 1 !== editedMonthObj.month) {
        return {
          ...result,
          thisMonthDays: [ ...result.thisMonthDays, { businessDayId: dayObj.businessDayId, status: dayObj.status } ],
        };
      } else if (dayjs(`${editedMonthObj.year}-${editedMonthObj.month}-15`).isAfter(dayObj.day)) {
        return {
          ...result,
          prevMonthDays: [ ...result.prevMonthDays, { businessDayId: dayObj.businessDayId, status: dayObj.status } ],
        };
      } else {
        return {
          ...result,
          nextMonthsDays: [ ...result.prevMonthDays, { businessDayId: dayObj.businessDayId, status: dayObj.status } ],
        };
      }
    }, { prevMonthDays: [], thisMonthDays: [], nextMonthsDays: [] } as { prevMonthDays: DayObj[], thisMonthDays: DayObj[], nextMonthsDays: DayObj[] });
  };

  return (
    <div className={S.businessCalendarWrapper}>
      <div className={S.monthList}>
        {_.map(monthsList, (monthObject, key) => (
          <React.Fragment key={monthObject.month}>
            <MonthComponent
              backupMonth={backupMonthsList[key]}
              changingMode={changingMode}
              isEdited={isEdited}
              isEditing={editedMonth === monthObject.month}
              isDisabled={editedMonth !== monthObject.month && Boolean(editedMonth)}
              isLoading={isLoading}
              startMonthEditing={(monthNumber: number) => setEditedMonth(monthNumber)}
              restoreMonthData={restoreMonthData}
              saveChanges={saveChanges}
              BCMonth={monthObject}
              changeDaysStatus={changeDaysStatus}
              changeAmountOfDays={changeAmountOfDays}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BusinessCalendar;
