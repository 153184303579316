import { Button, Checkbox } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import S from './styles.module.sass';
import { ReplacerData } from './types';

interface UserListToRemoveProps {
  replaceData: ReplacerData;
  onNextStepBtnClick: () => void;
  onUserCheckboxChange: (i: number) => void;
}

const UserListToRemove: React.FC<UserListToRemoveProps> = ({ replaceData, onNextStepBtnClick, onUserCheckboxChange }) => (
  <div className={classNames(S.userList, S.withBlack)}>
    {_.map(replaceData.userReports, (userReport, i) => (
      <Checkbox
        key={userReport.user.userId}
        checked={userReport.checked}
        className={S.userHourReportCheckbox}
        onChange={() => onUserCheckboxChange(i)}
      >
        <span className={S.userWrapper}>
          <span className={S.name}>{`${userReport.user.forename} ${userReport.user.surname}`}</span>
          <span>
            {userReport.hours}
            h
          </span>
        </span>
      </Checkbox>
    ))}
    <div className={S.buttons}>
      <Button size="small" className={S.nextStepBtn} type="primary" onClick={onNextStepBtnClick}>Next step</Button>
    </div>
  </div>
);

export default UserListToRemove;
