import _ from 'lodash';
import { Route } from 'react-router-dom';
import React from 'react';

import ROUTES from '../../../helpers/constants/_common/routes';
import { RouteItem, RoutesList } from '../../Root/helpers/types';
import { isAvailableByAccessType } from '../../../helpers/utils/checkRestrictions';

/* Making available routes by filtering them by permissions and page's accessType */
export const filterPagesByAccessType = (permissions: string[]) => {
  if (_.isEmpty(permissions)) {
    return {};
  }

  return _.reduce(ROUTES, (acc, page, key) => {
    if (!isAvailableByAccessType(permissions, page.accessType)) {
      return acc;
    }

    return {
      ...acc,
      [key]: page,
    };
  }, {} as RoutesList);
};

export const checkRouteAccess = (page: RouteItem, permissions: string[]) => (
  isAvailableByAccessType(permissions, page.accessType)
    ? <Route path={page.path} component={page.component} />
    : null);
