import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import MobileMenu from 'components/Mobile/MobileMenu/MobileMenu';
import ROUTES from 'helpers/constants/_common/routes';
import { setUserLayoutToMobile } from 'redux/User/actions';

import RootLayout from '../Root';
import PageDoesNotExist from '../PageDoesNotExist';
import { DispatchType, Redux } from '../../helpers/types/_common';
import { filterPagesByAccessType, checkRouteAccess } from './helpers/helpers';
import s from './styles.module.sass';
import LayoutOverlay from '../LayoutOverlay/LayoutOverlay';

const AppMobileRoutesLayout = () => {
  const dispatch: DispatchType = useDispatch();

  const { permissions } = useSelector((state: Redux) => state.login);

  useEffect(() => {
    dispatch(setUserLayoutToMobile());
  }, []);

  const routes = filterPagesByAccessType(permissions);

  return (
    <div className={s.mobileRootLayout}>
      <LayoutOverlay type="mobile" />

      <MobileMenu routes={routes} />

      <Switch>
        { checkRouteAccess(ROUTES.sandbox, permissions) }

        {
          _.map(routes, (page, pageKey) => (
            <Route
              key={pageKey}
              path={page.path}
              render={() => (page.mobileComponent ? <page.mobileComponent /> : <RootLayout page={page} />)}
              exact={_.isBoolean(page.exact) ? page.exact : true}
            />
          ))
        }
        <Route path="*" component={PageDoesNotExist} />
      </Switch>
    </div>
  );
};

export default AppMobileRoutesLayout;
