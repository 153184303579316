export const INITIATE = '[NEW TIME SHEET] INITIATE';

export const START_TIME_SELECTING = '[NEW TIME SHEET] START_TIME_SELECTING';
export const CONTINUE_TIME_SELECTING = '[NEW TIME SHEET] CONTINUE_TIME_SELECTING';
export const END_TIME_SELECTING = '[NEW TIME SHEET] END_TIME_SELECTING';

export const OPEN_SELECTION_CONTEXT_MENU = '[NEW TIME SHEET] OPEN_SELECTION_CONTEXT_MENU';
export const CLOSE_SELECTION_CONTEXT_MENU = '[NEW TIME SHEET] CLOSE_SELECTION_CONTEXT_MENU';

export const OPEN_DELETE_USER_HOURS_MODAL = '[NEW TIME SHEET] OPEN_DELETE_USER_HOURS_MODAL';
export const CLOSE_DELETE_USER_HOURS_MODAL = '[NEW TIME SHEET] CLOSE_DELETE_USER_HOURS_MODAL';

export const INITIATE_CONTEXT_MENUS = '[NEW TIME SHEET] INITIATE_CONTEXT_MENUS';

export const OPEN_GROUP_CONTEXT_MENU = '[NEW TIME SHEET] OPEN_GROUP_CONTEXT_MENU';
export const CLOSE_GROUP_CONTEXT_MENU = '[NEW TIME SHEET] CLOSE_GROUP_CONTEXT_MENU';

export const DELETE_USER_HOUR = '[NEW TIME SHEET] DELETE_USER_HOUR';
export const ADD_USER_HOUR = '[NEW TIME SHEET] ADD_USER_HOUR';
export const REPLACE_USER_HOUR = '[NEW TIME SHEET] REPLACE_USER_HOUR';
export const REPLACE_AND_FILL_ACTIVITY = '[NEW TIME SHEET] REPLACE_AND_FILL_ACTIVITY';
export const REPLACE_ACTIVITY = '[NEW TIME SHEET] REPLACE_ACTIVITY';

export const UPDATE_USER_ACTIVITIES = '[NEW TIME SHEET] UPDATE_USER_ACTIVITIES';
export const UPDATE_USER_PARTNERS = '[NEW TIME SHEET] UPDATE_USER_PARTNERS';
export const SET_USER_ACTIVITIES = '[NEW TIME SHEET] SET_USER_ACTIVITIES';
export const SET_USER_PARTNERS = '[NEW TIME SHEET] SET_USER_PARTNERS';

export const ADD_USER_HOUR_COMMENT = '[NEW TIME SHEET] ADD_USER_HOUR_COMMENT';
export const DELETE_USER_HOUR_COMMENT = '[NEW TIME SHEET] DELETE_USER_HOUR_COMMENT';

export const SET_SELECTION_BOX_LOADING = '[NEW TIME SHEET] SET_SELECTION_BOX_LOADING';

export const SET_REPLACE_DRAWER = '[NEW TIME SHEET] SET_REPLACE_DRAWER';
export const SET_FLAT_USER_HOURS_TABLE = '[NEW TIME SHEET] SET_FLAT_USER_HOURS_TABLE';
export const UPDATE_FLAT_USER_HOURS_TABLE = '[NEW TIME SHEET] UPDATE_FLAT_USER_HOURS_TABLE';

export const SHOW_TIME_SELECTING = '[NEW TIME SHEET] SHOW_TIME_SELECTING';
export const DROP_TIME_SELECTING = '[NEW TIME SHEET] DROP_TIME_SELECTING';

export const SET_SELECTED_CELLS = '[NEW TIME SHEET] SET_SELECTED_CELLS';

export const CHANGE_DRAW_MODE = '[NEW TIME SHEET] CHANGE_DRAW_MODE';

export const CTRL_PRESS_TOGGLE = '[NEW TIME SHEET] CTRL_PRESS_TOGGLE';

export const CHANGE_SELECTED_DATE = '[NEW TIME SHEET] CHANGE_SELECTED_DATE';

export const CHANGE_LOCK_MONTH_STATUS = '[NEW TIME SHEET] CHANGE_LOCK_MONTH_STATUS';
export const SET_LOCK_MONTH_STATUS = '[NEW TIME SHEET] SET_LOCK_MONTH_STATUS';

export const SET_ACTIVITY_ID = '[NEW TIME SHEET] SET_ACTIVITY_ID';

export const SET_CURRENTLY_IN_USE_ACTIVITY = '[NEW TIME SHEET] SET_CURRENTLY_IN_USE_ACTIVITY';

export const SET_FLAT_TABLE_DATA = '[NEW TIME SHEET] SET_FLAT_TABLE_DATA';
