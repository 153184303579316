import React from 'react';
import classNames from 'classnames/bind';
import S from '../styles.module.css';

const cx = classNames.bind(S);

interface FormLabelComponentProps {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  message?: string;
  showOn?: boolean;
}

export default class Label extends React.Component<FormLabelComponentProps> {
  render(): React.ReactNode {
    const { id, style, className, showOn, message } = this.props;

    return (
      <label className={cx('qs-form__label', className)} id={id} style={style}>
        {this.props.children}
        {showOn && <span className={cx('qs-form__label-span')}>{message}</span>}
      </label>
    );
  }
}
