import { ColumnsType } from 'antd/lib/table';
import Avatar from 'components/_shared/Avatar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React from 'react';

import { SPECIALISTS_REQUESTS_STATUSES } from '../../../../../../../api/Specialists/constants';
import { Candidate, SRStatuses } from '../../../../../../../api/Specialists/types';
import OCCUPATIONS from '../../../../../../../helpers/constants/Occupations/Occupations';
import { EmployeeOccupationEnum } from '../../../../../../../helpers/constants/Occupations/types';
import { TrustedUser } from '../../../../../../../helpers/types/User';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const columns = [
  {
    title: 'Position',
    dataIndex: 'occupation',
    key: 'occupation',
    width: 300,
    render: (occupation: EmployeeOccupationEnum) => OCCUPATIONS[occupation].name,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: SRStatuses) => SPECIALISTS_REQUESTS_STATUSES[status],
  },
  {
    title: 'Candidates',
    dataIndex: 'candidates',
    key: 'candidates',
    render: (candidates: Candidate[]) => {
      const candidatesNames = candidates.slice(0, 2).reduce((r, c, i) => {
        if (i === 0) {
          return `${c.user.forename} ${c.user.surname[0]}.`;
        } else {
          return `${r}, ${c.user.forename} ${c.user.surname[0]}.`;
        }
      }, '');
      const candidatesMore = candidates.length > 2 ? ` + ${candidates.length - 2} more` : '';

      return (
        <div className="requests-table_candidates">
          {candidates.slice(0, 3).map((candidate) => {
            const { user } = candidate;
            const forename = user.forename[0].toUpperCase() + user.forename.slice(1, user.forename.length);
            const surname = user.surname[0].toUpperCase() + user.surname.slice(1, user.surname.length);
            const title = `${forename} ${surname}`;
  
            return (
              <div key={candidate.user.userId} className="requests-table_avatar-wrapper" title={title}>
                <Avatar
                  key={candidate.specialistCandidateId}
                  src={candidate.user.avatarUrl}
                  alt={`${candidate.user.forename} ${candidate.user.surname}`}
                  className="requests-table_avatar"
                />
              </div>
            );
          })}
          <span className="requests-table_names">
            {candidates.length ? (candidatesNames + candidatesMore) : '-'}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Created by',
    dataIndex: 'author',
    key: 'author',
    width: 200,
    render: (author: TrustedUser) => <span style={{ textTransform: 'capitalize' }}>{`${author.forename} ${author.surname}`}</span>,
  },
  {
    title: 'Last used',
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    width: 200,
    render: (date: string) => {
      if (date) {
        const toNow = dayjs.utc(date).toNow(true);
        return `${toNow[0].toLocaleUpperCase() + toNow.slice(1, toNow.length)} ago`;
      } else {
        return '-';
      }
    },
  },
  {
    title: '',
    dataIndex: 'specialistRequestId',
    key: 'specialistRequestId',
    width: 30,
    className: 'borderless action-column',
  },
];

export default columns;
