import { Button, Modal } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useParams } from 'react-router';

import withRightPlural from 'helpers/utils/withRightPlural';
import modals from 'helpers/styles/components/modals.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import { closeDeleteUserHoursModal, deleteUserHourAction, dropTimeSelecting } from 'redux/TimeSheet/action';

import S from './helpers/styles.module.sass';
import { getTableModalInner } from '../CellGroup/helpers/helpers';
import { updateUserPreferences } from '../../../../../../../../redux/User/actions';

const cx = classNames.bind(S);

interface DeleteUserHoursModalProps {}

const DeleteUserHoursModal: React.FC<DeleteUserHoursModalProps> = () => {
  const dispatch: DispatchType = useDispatch();
  const { userId: editUserId } = useParams<{ userId: string }>();

  const { isVisible, selectionDay, startCellIndex, endCellIndex } = useSelector((state: Redux) => state.timeSheet.deleteUserHoursModal);
  const { preferences } = useSelector((state: Redux) => state.login);

  const [ isLoading, setIsLoading ] = useState(false);

  const hoursAmount = Math.abs(endCellIndex - startCellIndex) + 1;

  const handleOk = () => {
    if (!selectionDay) return handleCancel();

    setIsLoading(true);

    dispatch(deleteUserHourAction(selectionDay, startCellIndex, endCellIndex, editUserId))
      .then(() => {
        dispatch(closeDeleteUserHoursModal());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    dispatch(dropTimeSelecting());
    dispatch(closeDeleteUserHoursModal());
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    const status = !e.target.checked;
    dispatch(updateUserPreferences({ askConfirmationOnDeleteHours: status }));
  };

  return (
    <Modal
      visible={isVisible}
      title="Delete activity"
      onOk={handleOk}
      onCancel={handleCancel}
      getContainer={getTableModalInner()}
      width={500}
      className={cx(modals.qsBasicAntdModal, S.deleteModal)}
      centered
      footer={[
        <Checkbox
          key="remember"
          className={S.checkbox}
          onChange={onCheckboxChange}
          checked={!preferences.askConfirmationOnDeleteHours}
        >
          Don’t ask again
        </Checkbox>,
        <Button
          key="back"
          type="text"
          className={cx(modals.modalCancelBtn, S.cancelButton)}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={cx(modals.modalOkBtn, S.okButton)}
          loading={isLoading}
          onClick={handleOk}
        >
          Delete
        </Button>,
      ]}
    >
      <div className={S.content}>
        <span>Are you sure you want to:</span>
        <ul>
          <li>
            <span className={S.dot}>•</span>
            <span>
              Delete
              {' '}
              <b>{hoursAmount / 2}</b>
              {' '}
              selected
              {' '}
              {withRightPlural('hour', hoursAmount / 2)}
            </span>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default DeleteUserHoursModal;
