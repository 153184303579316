/* eslint-disable no-useless-escape */
import { Button, Input, Modal, Space, Checkbox } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

import modals from 'helpers/styles/components/modals.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as RandomizeIcon } from 'helpers/icons/RandomizeIcon.svg';
import { ActivityCardOutput, NewActivity, PartnerDetailsOutput } from 'api/Partners/types';
import COLORS from 'helpers/constants/_common/colors';
import ColorPicker from 'components/_shared/ColorPicker/ColorPicker';
import { addActivity, updateActivity } from 'api/Partners/requests';

import s from './styles.module.sass';
import PartnerMask from '../../../_shared/PartnerMask';

interface ActivityModalProps {
  visible: boolean;
  partner: PartnerDetailsOutput;
  editedActivity?: ActivityCardOutput;
  updateActivities: (activities: ActivityCardOutput) => void;
  onVisibleChange: (visible: boolean, withRefresh?: boolean) => void;
}

const DEFAULT_VALUES: NewActivity = {
  name: '',
  description: '',
  color: '#000E85',
  isOvertimesAllowed: false
};

const ActivityModal: React.FC<ActivityModalProps> = ({ visible, editedActivity, partner, updateActivities, onVisibleChange }) => {
  const isEditMode = !_.isUndefined(editedActivity);

  const [ activity, setActivity ] = useState<NewActivity | ActivityCardOutput>(isEditMode ? editedActivity : DEFAULT_VALUES);
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    if (visible) {
      if (editedActivity) {
        setActivity(editedActivity);
      } else {
        setActivity({
          ...DEFAULT_VALUES,
          color: getRandomColor(),
        });
      }
    }
  }, [ visible, editedActivity ]);

  // handlers

  const setActivityInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setActivity({
      ...activity,
      [name]: value,
    });
  };

  const getRandomColor = (): string => _.sample(COLORS) || COLORS.Blue;

  const setRandomColor = () => {
    const color = getRandomColor();

    setActivity({
      ...activity,
      color,
    });
  };

  const handleOvertimesAllowanceCheckbox = (e: CheckboxChangeEvent) => {
    const isOvertimesAllowed = e.target.checked;

    setActivity({
      ...activity,
      isOvertimesAllowed,
    });
  };

  const saveActivity = () => {
    if (_.isEmpty(activity.name)) return;
    const request = (isEditMode
      ? () => {
        const editedBody = _.reduce(activity, (result, value, key) => {
          if (editedActivity[key as keyof ActivityCardOutput] !== value) {
            return {
              ...result,
              [key]: value,
            };
          } else {
            return result;
          }
        }, {} as Partial<ActivityCardOutput>);

        return updateActivity(partner.partnerId, editedActivity.activityId, editedBody);
      }
      : () => addActivity(partner.partnerId, activity as NewActivity));

    setLoading(true);
    request()
      .then((res: ActivityCardOutput) => {
        updateActivities(res);
        onVisibleChange(false, true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // renderers

  const renderPrefixMask = () => (
    <PartnerMask
      wrapperColor={partner.color}
      mask={partner.mask}
      iconColor={activity.color}
      wrapperClassName={s.activityNamePartnerMask}
      partnerId={partner.partnerId}
      isVacation={partner.isVacation}
    />
  );

  const renderNameField = () => (
    <div className={s.nameFieldWrapper}>
      <Space direction="vertical" className={s.nameField}>
        <div className={s.titleWrapper}>
          <span className={s.fieldName}>Name</span>
        </div>
        <div className={s.bodyWrapper}>
          <Input
            prefix={renderPrefixMask()}
            className={cx(inputs.qsInput, s.addPartnerInput)}
            placeholder="Enter activity name"
            name="name"
            value={activity.name}
            onChange={setActivityInputValue}
          />
        </div>
      </Space>
      <Space direction="vertical" className={s.colorField}>
        <div className={s.titleWrapper}>
          <span className={s.fieldName}>Color</span>
        </div>
        <div className={s.bodyWrapper}>
          <ColorPicker
            color={activity.color}
            onChange={(hexColor: string) => setActivity({ ...activity, color: hexColor })}
          >
            <div className={s.colorPickerTrigger} style={{ backgroundColor: activity.color }} />
          </ColorPicker>
          <Button
            type="text"
            className={s.randomizeButton}
            onClick={setRandomColor}
          >
            <span>Randomize</span>
            <RandomizeIcon />
          </Button>
        </div>
      </Space>
    </div>
  );

  const renderDescriptionField = () => (
    <Space direction="vertical">
      <span className={s.fieldName}>Description</span>
      <Input
        className={cx(inputs.qsInput, s.addPartnerInput)}
        placeholder="Enter description text..."
        name="description"
        value={activity.description}
        onChange={setActivityInputValue}
      />
    </Space>
  );

  const renderExtraOptions = () => {
    if (isEditMode) {
      return <Space direction="vertical">
        <span className={s.fieldName}>Options</span>
        <Checkbox
          checked={activity.isOvertimesAllowed}
          onChange={handleOvertimesAllowanceCheckbox}
        >
          Allow overtimes
        </Checkbox>
      </Space>
    } else {
      return;
    };


  };

  return (
    <Modal
      className={modals.qsBasicAntdModal}
      visible={visible}
      onCancel={() => onVisibleChange(false)}
      title={isEditMode ? 'Edit Activity' : 'Add new Activity'}
      onOk={saveActivity}
      confirmLoading={isLoading}
      okButtonProps={{
        disabled: _.isEmpty(activity.name),
        size: 'large',
        className: buttons.qsButtonPrimary,
      }}
      cancelButtonProps={{
        size: 'large',
        className: buttons.qsButton,
      }}
      okText={isEditMode ? 'Save' : 'Add'}
      centered
      closable={false}
    >
      <div className={s.addActivityModalInner}>
        { renderNameField() }
        { renderDescriptionField() }
        { renderExtraOptions() }
      </div>
    </Modal>
  );
};

export default ActivityModal;
