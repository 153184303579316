import { Modal } from 'antd';

import TokenStore from 'api/_request/helpers/jwtTokenStore';
import * as userRequests from 'api/User/requests';

import { DispatchType } from '../../helpers/types/_common';
import * as TablesActions from '../../helpers/constants/_common/actions/Tables';
import Subtypes from '../../V1/constants/__common__/Subtypes';
import { api } from '../../index';
import * as Actions from '../../V1/constants/Login';
import history from '../_reducer/configureHistory';
import { DEFAULT_PATH } from '../../helpers/constants/_common/MenuCases';
import configuredStore from '../_reducer/configureStore';
import { PreferencesBody } from './types';

export const getVacationReport = (params: any, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return userRequests.getVacationReport(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const setUserLayoutToMobile = () => ({
  type: Actions.SET_USER_LAYOUT_TO_MOBILE,
});

const deleteToken = (code: string) => api.delete('/auth/token/', {
  refresh_token: code,
});

export const logout = (params?: { isGlobalLogout: boolean }) => (dispatch: DispatchType) => {
  const refreshToken = TokenStore.getRefreshToken();

  if (!refreshToken) {
    TokenStore.clearJWTData();
    history.replace(DEFAULT_PATH);

    dispatch({ type: Actions.LOGOUT });
    return;
  }

  // global logout
  if (params?.isGlobalLogout) {
    const { userId } = configuredStore.getState().login;

    return userRequests.globalLogout(userId)
      .finally(() => {
        Modal.destroyAll();

        TokenStore.clearJWTData();
        history.replace(DEFAULT_PATH);

        dispatch({ type: Actions.LOGOUT });
      });
  }

  // user logout
  return deleteToken(refreshToken)
    .finally(() => {
      TokenStore.clearJWTData();
      history.replace(DEFAULT_PATH);

      dispatch({ type: Actions.LOGOUT });
    });
};

export const updateUserPreferences = (preferences: PreferencesBody) => (dispatch: DispatchType) => {
  const { userId } = configuredStore.getState().login;

  dispatch({
    type: Actions.SET_USER_PREFERENCES,
    preferences,
  });

  return userRequests.updateUserPreferences(userId, preferences);
};
