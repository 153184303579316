import _ from 'lodash';

/**
 * Default response parser for all data. Convert snake_case into camelCase.
 * @param response has string type.
 */
import { convertArrayObjectKeysToLanguageCase, convertObjectKeysToLanguageCase } from '../../../helpers/utils/convertObject';

const axiosDefaultResponseParser = (response: string) => {
  if (!response) {
    return response;
  }

  try {
    const parsedResponse = JSON.parse(response);

    if (_.isString(parsedResponse) || _.isBoolean(parsedResponse)) {
      return parsedResponse;
    }

    return _.isArray(parsedResponse) ? convertArrayObjectKeysToLanguageCase(parsedResponse) : convertObjectKeysToLanguageCase(parsedResponse);
  } catch (e) {
    return undefined;
  }
};

export default axiosDefaultResponseParser;
