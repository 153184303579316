import React, { useEffect, useState } from 'react';
import { Button, Popover, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames/bind';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { ReactComponent as DateRangeIcon } from 'helpers/icons/dateRange.svg';
import { FULL_MONTH_FORMAT } from 'helpers/constants/_common/timeFormats';

import { ReactComponent as LeftArrow } from './helpers/leftArrow.svg';
import { ReactComponent as RightArrow } from './helpers/rightArrow.svg';
import { ReactComponent as ArrowIcon } from './helpers/arrowIcon.svg';
import S from './styles.module.sass';
import { getMonthNameByNumber } from './helpers/helpers';

const cx = classNames.bind(S);

export interface TimeSheetSelectorProps {
  selectedDate: string | null; // MONTH_YEAR_FORMAT format
  onChangeMonth: (dayjsObj: Dayjs) => void;
  className?: string;
  isDisabled?: boolean;
  isModified: boolean;
  isCustomDate?: boolean;
  isLoading: boolean;
}

const BusinessMonthSelector: React.FC<TimeSheetSelectorProps> = (props) => {
  const {
    isLoading, selectedDate, isDisabled,
    onChangeMonth, isModified, className, isCustomDate,
  } = props;

  const selectedDateObj = _.isNull(selectedDate) ? dayjs() : dayjs(selectedDate);

  const [ visible, changeVisible ] = useState(false);
  const [ popoverYear, setPopoverYear ] = useState(selectedDateObj.year() || dayjs().year());

  useEffect(() => {
    setPopoverYear(selectedDateObj.year());
  }, [ selectedDate ]);

  useEffect(() => {
    if (visible) {
      setPopoverYear(selectedDateObj.year());
    }
  }, [ visible ]);

  const changeMonth = (newMonthNumber: number, isPopupMonthChange?: boolean) => {
    if ((isCustomDate && !isPopupMonthChange) || isLoading) {
      return;
    }

    const oldMonthNumber = selectedDateObj.month();
    const newSelectedDayObj = selectedDateObj.year(popoverYear).add(newMonthNumber - oldMonthNumber - 1, 'month');

    return onChangeMonth(newSelectedDayObj);
  };

  const changeYear = (value: number) => {
    setPopoverYear(value);
  };

  const renderMonthList = () => _.range(12).map((e, i) => {
    const month = dayjs().month(i).format(FULL_MONTH_FORMAT);

    return (
      <Button
        key={`${month}`}
        onClick={() => {
          changeMonth(i + 1, true);

          changeVisible(false);
        }}
        className={cx(S.monthButton, {
          [S.active]: selectedDateObj.month() === i && popoverYear === selectedDateObj.year(),
        })}
      >
        <span>{month}</span>
      </Button>
    );
  });

  const renderPopoverContent = () => (
    <div className={S.popoverContent}>
      <div className={S.yearSelector}>
        <Button
          type="link"
          className={cx(S.arrowButton, S.popoverButton)}
          onClick={() => changeYear(popoverYear - 1)}
        >
          <LeftArrow />
        </Button>
        <span className={S.selectedYearLabel}>{popoverYear}</span>
        <Button
          type="link"
          className={cx(S.arrowButton, S.popoverButton)}
          onClick={() => changeYear(popoverYear + 1)}
        >
          <RightArrow />
        </Button>
      </div>
      <div className={S.monthList}>
        <Space direction="vertical" className={S.monthListInner}>
          {renderMonthList()}
        </Space>
      </div>
    </div>
  );

  const renderCustomMonthTitle = () => (
    <div className={S.customDateWrapper}>
      <span className={S.selectedMonthLabel}>Custom period</span>
      <DateRangeIcon className={S.isCustomDateIcon} />
    </div>
  );

  const renderBusinessMonthTitle = () => (
    <div className={S.selected}>
      <Typography.Text className={S.selectedMonthLabel} data-testid="month-year-display">
        {getMonthNameByNumber(selectedDateObj.month() + 1)}
        {' '}
        {selectedDateObj.year()}
      </Typography.Text>
      {isModified && <Typography.Text data-testid="modified-mode" className={S.modified}>Modified</Typography.Text>}
      <div className={S.popoverIcon}>
        {isLoading ? <LoadingOutlined className={S.spinner} spin /> : <ArrowIcon className={S.arrowIcon} />}
      </div>
    </div>
  );

  return (
    <div className={cx(S.selector, className, { [S.disabledSelector]: isDisabled })}>
      <Button
        type="link"
        data-testid="arrow-left"
        className={cx(S.arrowButton, { [S.disabledArrowButton]: isCustomDate })}
        onClick={() => changeMonth(selectedDateObj.month())} // dayjs.month() returns monthIndex (current month - 1), but we need current month - 1
        disabled={isCustomDate}
      >
        <LeftArrow />
      </Button>
      <div className={S.dropDownSelector}>
        <Popover
          content={renderPopoverContent()}
          trigger="click"
          visible={visible}
          onVisibleChange={changeVisible}
          placement="bottom"
          overlayClassName={S.popover}
        >
          { isCustomDate ? renderCustomMonthTitle() : renderBusinessMonthTitle() }
        </Popover>
      </div>
      <Button
        type="link"
        data-testid="arrow-right"
        className={cx(S.arrowButton, { [S.disabledArrowButton]: isCustomDate })}
        onClick={() => changeMonth(selectedDateObj.month() + 2)} // dayjs.month() returns monthIndex (current month - 1), but we need current month + 1
        disabled={isCustomDate}
      >
        <RightArrow />
      </Button>
    </div>
  );
};

export default BusinessMonthSelector;
