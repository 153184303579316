/*jshint esversion: 6*/
import { USER_TYPES } from '../../helpers/constants/_common/constants';
import * as Actions from '../constants/Users';
import _ from 'lodash';
import Subtypes from '../constants/__common__/Subtypes';

let InitialState = {
  objects: [],
  objectsV3: [],
  showInactive: false,
  entityId: undefined,
  selectedUser: {
    userId: 0,
    username: '',
    forename: '',
    surname: '',
    emailAddress: '',
    role: 0,
    status: true,
    usertype: USER_TYPES.USER,
    usertypeId: 0,
    entityId: 1,
    occupation: 'FULLSTACK_ENGINEER',
    description: '',
    administrator: false,
    active: true,
    curator: false,
    usersAdminNotes: [],
  },
  selectedUsersSkills: [],
  findUsers: '',
  errorMessage: '',
  tableErrorMessage: '',
  skillsErrorMessage: '',
  occupations: [],
  showUserDataModal: false,
  gettingUsersIsLoading: true,
  userModalIsLoading: false,
  gettingOccupationsIsInProgress: false,
  gettingUserSkillsIsInProgress: false,
  savingUserSkillsIsInProgress: false,
  viewUser: {
    username: '',
    id: 0,
    surname: '',
    forename: '',
    entityId: 1,
    occupationId: 1
  }
};

function changeUser(state, next) {
  const {objects, viewUser} = state;
  let userNumberInArray, newUserId = 0, newUsername = '', newSurname = '', newForename = '';

  if (objects > []) {
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].userId === viewUser.id) {
        userNumberInArray = i;
        break;
      }
    }
    if (next === true) {
      for (let i = userNumberInArray - 1; i > 0; i--) {
        if (objects[i].status) {
          newUserId = objects[i].userId;
          newUsername = objects[i].username;
          newForename = objects[i].forename;
          newSurname = objects[i].surname;
          break;
        }
      }
    } else {
      for (let i = userNumberInArray + 1; i < objects.length; i++) {
        if (objects[i].status) {
          newUserId = objects[i].userId;
          newUsername = objects[i].username;
          newForename = objects[i].forename;
          newSurname = objects[i].surname;
          break;
        }
      }
    }
    if (newUserId !== 0) {
      return {...state, viewUser: {id: newUserId, username: newUsername, forename: newForename, surname: newSurname}};
    } else {
      return state;
    }
  } else {
    return state;
  }
}

function changeUserById(state, id) {
  let newUsername = '', newSurname = '', newForename = '';
  for (let i = 0; i < state.objects.length; i++) {
    if (id === state.objects[i].userId) {
      newUsername = state.objects[i].username;
      newForename = state.objects[i].forename;
      newSurname = state.objects[i].surname;
      break;
    }
  }
  return {...state, viewUser: {id: id, userId: id, username: newUsername, forename: newForename, surname: newSurname}};
}

function changeViewUser(state, user) {
  return {
    ...state,
    viewUser: {id: user.userId, username: user.username, forename: user.forename, surname: user.surname}
  };
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;
    case Actions.CLEAR_STORE:
      return {...InitialState, objects: state.objects};
    case Actions.USER_MODAL_IS_LOADING:
      return {...state, userModalIsLoading: true};

    case Actions.GET_USERS_REQUEST_V3:
      return {...state, gettingUsersIsLoading: true, tableErrorMessage: false};
    case Actions.GET_USERS_SUCCESS_V3:
      return {...state, gettingUsersIsLoading: false, objectsV3: action.payload ? action.payload.objects : state.partners, tableErrorMessage: false};
    case Actions.GET_USERS_ERROR_V3:
      return {...state, gettingUsersIsLoading: false, error: action.payload, getError: true};

    case Actions.GET_USERS_REQUEST:
      return {...state, gettingUsersIsLoading: true, tableErrorMessage: ''};
    case Actions.GET_USERS_SUCCESS:
      return {...state, gettingUsersIsLoading: false, tableErrorMessage: '', objects: action.payload};
    case Actions.GET_USERS_ERROR:
      return {...state, gettingUsersIsLoading: false, tableErrorMessage: action.payload};
    case Actions.SHOW_USER_DATA_MODAL:
      return {...state, showUserDataModal: action.payload};
    case Actions.CHANGE_ERROR_MESSAGE:
      return {...state, userModalIsLoading: false, errorMessage: action.payload};
    case Actions.SHOW_FOUND_USERS:
      return {...state, findUsers: action.payload};
    case Actions.CHANGE_ENTITY_ID:
      return {...state, entityId: action.payload};
    case Actions.CHANGE_VIEW_USER:
      return changeViewUser(state, action.payload);
    case Actions.UPDATE_SKILL_SET:
      return {
        ...state,
        selectedUsersSkills: [
          ...state.selectedUsersSkills
            .filter(skill => !action.payload.deleted.includes(skill.skillId))
            .map(skill => {
              if (action.payload.pureSkills.map(s => s.skillId).includes(skill.skillId)) {
                const attitudes = action.payload.pureSkills.find(s => s.skillId === skill.skillId).attitudes;

                return {
                  ...skill,
                  attitudes: attitudes.length ? attitudes : [null],
                  userSkillLinkId: true,
                  experienceRateId: action.payload.experience
                }
              } else {
                return skill;
              }
            }),
          ...action.payload.pureSkills
            .filter(skill => !state.selectedUsersSkills.map(s => s.skillId).includes(skill.skillId))
            .map(skill => ({
              ...skill,
              userSkillLinkId: skill.userSkillLinkId || true,
              experienceRateId: action.payload.experience
            }))
        ]
      };
    case Actions.CHANGE_FORENAME:
      return {...state, selectedUser: {...state.selectedUser, forename: action.payload}};
    case Actions.CHANGE_SURNAME:
      return {...state, selectedUser: {...state.selectedUser, surname: action.payload}};
    case Actions.CHANGE_USERNAME:
      return {...state, selectedUser: {...state.selectedUser, username: action.payload}};
    case Actions.CHANGE_EMAIL:
      return {...state, selectedUser: {...state.selectedUser, email: action.payload}};
    case Actions.CHANGE_DESCRIPTION:
      return {...state, selectedUser: {...state.selectedUser, description: action.payload}};
    case Actions.CHANGE_ACTIVE:
      return {...state, selectedUser: {...state.selectedUser, status: action.payload}};
    case Actions.CHANGE_ADMINISTRATOR:
      return {...state, selectedUser: {...state.selectedUser, administrator: action.payload}};
    case Actions.CHANGE_ENTITY:
      return {...state, selectedUser: {...state.selectedUser, entityId: action.payload}};
    case Actions.CHANGE_OCCUPATION:
      return {...state, selectedUser: {...state.selectedUser, occupationId: action.payload}};
    case Actions.CHANGE_ADMIN_NOTES:
      return {...state, selectedUser: {...state.selectedUser, usersAdminNotes: action.payload}};
    case Actions.SHOW_INACTIVE:
      return {...state, showInactive: action.payload};
    case Actions.CHANGE_USER_STATUS: {
      let users = _.cloneDeep(state.objects);
      let user = users.find(e => e.userId === action.payload.id);
      user.status = action.payload.status;
      return {...state, objects: users};
    }

    case Actions.GET_USERS_SKILLS:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return {...state, gettingUserSkillsIsInProgress: true};
        case Subtypes.Request.Success:
          return {...state, gettingUserSkillsIsInProgress: false, selectedUsersSkills: action.payload};
        case Subtypes.Request.Error:
          return {...state, gettingUserSkillsIsInProgress: false, errorMessage: action.payload};
        default:
          return state;
      }

    case Actions.UPDATE_USERS_SKILLS:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return {...state, savingUserSkillsIsInProgress: true};
        case Subtypes.Request.Success:
          return {...state, savingUserSkillsIsInProgress: false};
        case Subtypes.Request.Error:
          return {...state, savingUserSkillsIsInProgress: false, skillsErrorMessage: action.payload};
        default:
          return state;
      }

    case Actions.CHANGE_SKILLS_ERROR_MESSAGE:
      return {...state, skillsErrorMessage: action.payload};

    case Actions.PREVIOUS_USER:
      return changeUser(state, false);
    case Actions.NEXT_USER:
      return changeUser(state, true);
    case Actions.CHANGE_USER_BY_ID:
      return changeUserById(state, action.payload);
    case Actions.SELECT_USER:
      return {...state, selectedUser: {...action.payload}};
    case Actions.DROP_SELECTED_USER:
      return {
        ...state,
        selectedUser: InitialState.selectedUser,
        selectedUsersSkills: InitialState.selectedUsersSkills,
        userModalIsLoading: false,
        skillsErrorMessage: ''
      };

    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
