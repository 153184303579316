import _ from 'lodash';

export const LIMIT = 15;
export const DEFAULT_DATA = {
  limit: LIMIT,
  offset: 0
};
export function getObjectsWithPaginationData(data = DEFAULT_DATA) {
  return _.assign({limit: DEFAULT_DATA.limit, offset: DEFAULT_DATA.offset}, data);
}
