/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Popover } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import _ from 'lodash';
import { ChromePicker, ColorResult } from 'react-color';

import popover from 'helpers/styles/components/popover.module.sass';
import COLORS from 'helpers/constants/_common/colors';

import s from './styles.module.sass';
import HexInput from './hexInput/hexInput';
import HslPicker from './HslPicker/HslPicker';

export interface ColorPickerProps {
  color: string;
  disabledColors?: string[];
  onChange: (color: string) => void;
}

enum ColorPickerTab {
  Swatches = 'SWATCHES',
  Palette = 'PALETTE',
  HSL = 'HSL',
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange, children, disabledColors = [] }) => {
  const [ colorPopoverVisible, setColorPopoverVisible ] = useState(false);
  const [ currentColorPickerTab, setColorPickerTab ] = useState<ColorPickerTab>(ColorPickerTab.Swatches);

  const renderSwatches = () => (
    _.map(COLORS, (color, key) => (
      <button
        type="button"
        key={color}
        className={s.swatch}
        disabled={_.includes(disabledColors, color)}
        onClick={() => onChange(color)}
        title={_.includes(disabledColors, color) ? 'This color is already in use' : key}
        style={{ backgroundColor: `${color === '#000000' ? '#ffffff40' : `${color}80`}` }}
      >
        <div className={s.swatchInner} style={{ backgroundColor: color }} />
      </button>
    ))
  );

  const renderPallet = () => (
    <div className={s.paletteColorContent}>
      <ChromePicker
        className={s.hueSatBrPickerWrapper}
        onChange={(event: ColorResult) => onChange(event.hex)}
        color={color}
        disableAlpha
      />
      <HexInput value={color} setHex={onChange} styles={{ marginTop: '40px' }} />
    </div>
  );

  const renderHSL = () => (
    <HslPicker value={color} onChange={onChange} />
  );

  const renderTabContent = () => {
    switch (currentColorPickerTab) {
      case ColorPickerTab.Swatches:
        return (
          <div className={s.swatchesPicker}>
            {renderSwatches()}
          </div>
        );
      case ColorPickerTab.Palette:
        return (
          <div className={s.huePicker}>
            {renderPallet()}
          </div>
        );
      case ColorPickerTab.HSL:
        return (
          <div className={s.huePicker}>
            {renderHSL()}
          </div>
        );
    }
  };

  const renderColorPopoverContent = () => (
    <div className={s.colorPickerPopover}>
      <div className={s.colorPickerTabs}>
        {_.map(ColorPickerTab, (tabEnum, tabName) => (
          <Button
            key={tabEnum}
            className={classNames(s.colorPickerButton, { [s.active]: currentColorPickerTab === tabEnum })}
            onClick={() => setColorPickerTab(tabEnum)}
          >
            {tabName}
          </Button>
        ))}
      </div>
      <div className={s.colorPickerContent}>
        {renderTabContent()}
      </div>
    </div>
  );
  
  return (
    <Popover
      overlayClassName={classNames(popover.dotsPopoverOverlay, s.customizationPopover)}
      trigger="click"
      placement="bottom"
      content={renderColorPopoverContent()}
      visible={colorPopoverVisible}
      onVisibleChange={setColorPopoverVisible}
    >
      {children}
    </Popover>
  );
};

export default ColorPicker;
