import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Skeleton } from 'antd';

import { ActivityCardOutput, PartnerDetailsOutput } from 'api/Partners/types';
import { getPartnerActivities } from 'api/Partners/requests';
import Button from 'components/_shared/Button';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { ActivityDrawerData } from 'components/Drawers/ActivityDrawer/helpers/types';
import ActivityDrawer from 'components/Drawers/ActivityDrawer/ActivityDrawer';

import Activity from './Activity';
import s from './styles.module.sass';
import ActivityModal from './ActivityModal';

interface PartnerActivitiesTabProps {
  partner: PartnerDetailsOutput;
}

interface Lists {
  active: ActivityCardOutput[];
  inactive: ActivityCardOutput[];
}

const DEFAULT_DRAWER_DATA = { activity: undefined, visible: false };

const PartnerActivitiesTab: React.FC<PartnerActivitiesTabProps> = ({ partner }) => {
  const [ isGetterLoading, setGetterLoading ] = useState(false);
  const [ isAddModalVisible, setAddModalVisible ] = useState(false);
  const [ activities, setActivities ] = useState<ActivityCardOutput[]>([]);
  const [ editedActivity, setEditedActivity ] = useState<ActivityCardOutput | undefined>();
  const [ activityDrawerData, setActivityDrawerData ] = useState<ActivityDrawerData>(DEFAULT_DRAWER_DATA);

  const { active, inactive } = useMemo(() => _.reduce(activities, (result, activity) => {
    if (activity.active) {
      return {
        ...result,
        active: [ ...result.active, activity ],
      };
    } else {
      return {
        ...result,
        inactive: [ ...result.inactive, activity ],
      };
    }
  }, { active: [], inactive: [] } as Lists), [ activities ]);

  const getActivities = (partnerId: number) => {
    setGetterLoading(true);
    getPartnerActivities(partnerId)
      .then(res => setActivities(res))
      .finally(() => setGetterLoading(false));
  };

  const onCloseDrawer = () => {
    setActivityDrawerData(DEFAULT_DRAWER_DATA);
  };

  const openActivityDrawer = (activity: ActivityCardOutput) => {
    setActivityDrawerData({ activity, partner, visible: true });
  };

  useEffect(() => {
    getActivities(partner.partnerId);
  }, [ partner.partnerId ]);

  const updateActivities = (activity: ActivityCardOutput) => {
    if (_.find(activities, { activityId: activity.activityId })) { // update existed one
      const updatedActivities = _.reduce(activities, (result, item) => {
        if (item.activityId === activity.activityId) {
          return [ ...result, activity ];
        } else {
          return [ ...result, item ];
        }
      }, [] as ActivityCardOutput[]);

      setActivities(updatedActivities);
    } else { // add new one
      setActivities([ ...activities, activity ]);
    }
  };

  const editActivity = (activity: ActivityCardOutput) => {
    setEditedActivity(activity);
    setAddModalVisible(true);
  };

  const renderSkeletonActivity = (index: number) => (
    <div key={index} className={classNames(s.pseudoActivity, s.activity)}>
      <Skeleton.Avatar active={isGetterLoading} />
      <Skeleton.Button active={isGetterLoading} block />
      <Skeleton.Avatar active={isGetterLoading} shape="square" />
      <Skeleton.Avatar active={isGetterLoading} shape="square" />
    </div>
  );

  const renderActivitiesByStatus = (status: boolean) => {
    const existedList = status ? active : inactive;

    if (_.isEmpty(activities) && isGetterLoading) {
      return _.map([ 1, 2 ], index => renderSkeletonActivity(index));
    }

    return (
      _.map(existedList, activity => (
        isGetterLoading
          ? renderSkeletonActivity(activity.activityId)
          : (
            <Activity
              key={activity.activityId} 
              activity={activity}
              partner={partner}
              openActivityDrawer={openActivityDrawer}
              editActivity={editActivity}
              updateActivities={updateActivities}
            />
          )
      ))
    );
  };

  const renderCategory = (status: boolean) => (
    <div className={s.activitiesCategory}>
      <h3 className={s.categoryTitle}>{status ? 'Active' : 'Inactive'}</h3>
      <div className={s.list}>
        {renderActivitiesByStatus(status)}
      </div>
    </div>
  );
  
  return (
    <div className={s.activitiesTab}>
      <header className={s.activitiesTabHeader}>
        <span className={s.headerTitle}>Activities</span>
        <Button
          size="large"
          className={classNames(buttons.qsButton, s.addButton)}
          onClick={() => {
            setAddModalVisible(true);
            setEditedActivity(undefined);
          }}
        >
          <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
          <span>Add new</span>
        </Button>
      </header>

      <main>
        {renderCategory(true)}
        {!_.isEmpty(inactive) && renderCategory(false)}
      </main>

      <ActivityModal
        partner={partner}
        editedActivity={editedActivity}
        visible={isAddModalVisible}
        onVisibleChange={setAddModalVisible}
        updateActivities={updateActivities}
      />

      <ActivityDrawer
        drawerData={activityDrawerData}
        onClose={onCloseDrawer}
        externalButtons={[]}
      />
    </div>
  );
};

export default PartnerActivitiesTab;
