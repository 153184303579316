import _ from 'lodash';

import { DispatchType } from '../../helpers/types/_common';
import * as TablesActions from '../../helpers/constants/_common/actions/Tables';
import Subtypes from '../../V1/constants/__common__/Subtypes';
import * as findSpecialists from '../../api/Specialists/requests';
import { FindSpecialistsBody, RequestSpecialistsBody, SetRequestSpecialistsInfoBody, SetCandidatesStatusBody } from '../../api/Specialists/types';
import { SpecialistsSkills } from '../../components/Specialists/helpers/types';

export const getFindSpecialists = (params: FindSpecialistsBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return findSpecialists.getFindSpecialists(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const getRequestCandidates = (params: RequestSpecialistsBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return findSpecialists.getRequestCandidates(_.omit(params, 'specialistRequestId'), params.specialistRequestId)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const getRequestSpecialists = (params: any, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return findSpecialists.getRequestSpecialists(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const resetSkillColumnSorter = (tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.SET_TABLE_SORTER, sorter: {}, tableName });
};
export const setSkillsExperienceParams = (skills: SpecialistsSkills, specialistsRequestId: string) => findSpecialists.setSkillsExperienceParams(skills, specialistsRequestId);
export const setRequestSpecialistsInfo = (body: SetRequestSpecialistsInfoBody, requestId: string) => findSpecialists.setRequestSpecialistsInfo(body, requestId);
export const getRequestInfo = (requestId: string) => findSpecialists.getRequestInfo(requestId);
export const getRequestSkills = (requestId: string) => findSpecialists.getRequestSkills(requestId);
export const setCandidatesStatus = (body: SetCandidatesStatusBody) => () => findSpecialists.setCandidatesStatus(body);
export const removeCandidatesStatus = (body: SetCandidatesStatusBody) => () => findSpecialists.removeCandidatesStatus(body);
