const sidebarIsCollapsed = window.localStorage.getItem('collapsedSidebar') === 'true';
const breakpointsWhenSidebarCollapsed = {
  2200: {
    slidesPerView: 6,
  },
  1900: {
    slidesPerView: 5,
  },
  1580: {
    slidesPerView: 4,
  },
  1160: {
    slidesPerView: 3,
  },
  800: {
    slidesPerView: 2,
  },
};
const breakpointsWhenSidebarIsNotCollapsed = {
  1740: {
    slidesPerView: 4,
  },
  1460: {
    slidesPerView: 3,
  },
  1000: {
    slidesPerView: 2,
  },
};
const calendarCarouselConfig = {
  sliderPerView: 5,
  centeredSlides: true,
  slideToClickedSlide: true,
  pagination: {
    clickable: true,
  },
  spaceBetween: 16,
  breakpoints: sidebarIsCollapsed ? breakpointsWhenSidebarCollapsed : breakpointsWhenSidebarIsNotCollapsed,
};

export default calendarCarouselConfig;
