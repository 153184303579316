import dayjs from 'dayjs';

const generateEmptyMonthDays = (data) => {
  const { year, month } = data;
  const firstDay = `${year}-${month + 1}-1`;
  const days = Array(dayjs(firstDay).daysInMonth()).fill(null).map((e, i) => ({
    day: dayjs(firstDay).add(i, 'day').format('YYYY-MM-DD'),
    status: [6,0].includes(dayjs(firstDay).add(i, 'day').day()) ? 'DAY_OFF' : 'REGULAR'
  }));

  return [
    {
      year,
      month: month + 1,
      days
    }
  ]
}

export default generateEmptyMonthDays;