export const RECOMMENDATIONS_INITIATE = '[RECOMENDATIONS] RECOMMENDATIONS INITIATE';

export const GET_RECOMMENDATIONS = '[RECOMENDATIONS] GET RECOMMENDATIONS';

export const ADD_RECOMMENDATION = '[RECOMENDATIONS] ADD RECOMMENDATION';
export const EDIT_RECOMMENDATION = '[RECOMENDATIONS] EDIT RECOMMENDATION';
export const ARCHIVE_RECOMMENDATION = '[RECOMENDATIONS] ARCHIVE RECOMMENDATION';

export const RECOMMENDATION_MODAL = '[RECOMENDATIONS] RECOMMENDATION MODAL';
export const EMAIL_CONFIRMATION_MODAL = '[RECOMENDATIONS] EMAIL CONFIRMATION MODAL';

export const SEND_EMAIL_TO_APPLICANT = '[RECOMENDATIONS] SEND EMAIL TO APPLICANT';

export const UPDATE_RECOMMENDATIONS_LIST_WITH_NEW_ONE = '[RECOMENDATIONS] UPDATE RECOMMENDATIONS LIST WITH NEW ONE';
export const UPDATE_RECOMMENDATIONS_LIST_WITH_UPDATED = '[RECOMENDATIONS] UPDATE RECOMMENDATIONS LIST WITH UPDATED';

export const CHANGE_SELECTED_RECOMMENDATION = '[RECOMENDATIONS] CHANGE SELECTED RECOMMENDATION';
export const CHANGE_POSITION_TO_RECOMMEND = '[RECOMENDATIONS] CHANGE POSITION TO RECOMMEND';

export const WRITE_USERS_EMAIL_AS_RECOMMENDERS_EMAIL = '[RECOMENDATIONS] WRITE USERS EMAIL AS RECOMMENDERS EMAIL';

export const ARCHIVED_RECOMMENDATIONS_VISIBILITY_TOGGLE = '[RECOMENDATIONS] ARCHIVED RECOMMENDATIONS VISIBILITY TOGGLE';
export const ONLY_ACTIVE_RECOMMENDATIONS_VISIBILITY_TOGGLE = '[RECOMENDATIONS] ONLY ACTIVE RECOMMENDATIONS VISIBILITY TOGGLE';
