export const INITIATE = 'INITIATE';
export const CLEAR_STORE = '[USERS] CLEAR_STORE';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USERS_REQUEST_V3 = 'GET_USERS_REQUEST_v3';
export const GET_USERS_SUCCESS_V3 = 'GET_USERS_SUCCESS_V3';
export const GET_USERS_ERROR_V3 = 'GET_USERS_ERROR_V3';

export const SELECT_USER = 'SELECT_USER';
export const USER_MODAL_IS_LOADING = 'USER_MODAL_IS_LOADING';
export const DROP_SELECTED_USER = 'DROP_SELECTED_USER';
export const SHOW_USER_DATA_MODAL = 'SHOW_USER_DATA_MODAL';
export const CHANGE_ERROR_MESSAGE = '[USERS] CHANGE_ERROR_MESSAGE';
export const CHANGE_FORENAME = '[USERS] CHANGE_FORENAME';
export const CHANGE_SURNAME = '[USERS] CHANGE_SURNAME';
export const CHANGE_USERNAME = '[USERS] CHANGE_USERNAME';
export const CHANGE_EMAIL = '[USERS] CHANGE_EMAIL';
export const CHANGE_DESCRIPTION = '[USERS] CHANGE_DESCRIPTION';
export const CHANGE_ACTIVE = '[USERS] CHANGE_ACTIVE';
export const CHANGE_ADMINISTRATOR = '[USERS] CHANGE ADMINISTRATOR';
export const CHANGE_CURATOR = '[USERS] CHANGE CURATOR';
export const CHANGE_ENTITY = '[USERS] CHANGE ENTITY';
export const CHANGE_OCCUPATION = '[USERS] CHANGE OCCUPATION';
export const CHANGE_ADMIN_NOTES = '[USERS] CHANGE ADMIN NOTES';
export const SHOW_FOUND_USERS = '[USERS] SHOW FOUND USERS';
export const CHANGE_ENTITY_ID = '[USERS] CHANGE ENTITY ID';
export const SHOW_INACTIVE = '[USERS] SHOW INACTIVE';
export const CHANGE_USER_STATUS = '[USERS] CHANGE USER STATUS';

export const UPDATE_SKILL_SET = '[USERS] UPDATE SKILL SET';

export const CHANGE_VIEW_USER = '[USERS] CHANGE VIEW USER';
export const CHANGE_USER_BY_ID = '[USERS] CHANGE USER BY ID';
export const PREVIOUS_USER = '[USERS] PREVIOUS USER';
export const NEXT_USER = '[USERS] NEXT USER';

export const GET_USERS_SKILLS = '[USERS] GET USERS SKILLS';

export const UPDATE_USERS_SKILLS = '[USERS] UPDATE USERS SKILLS';

export const CHANGE_SKILLS_ERROR_MESSAGE = '[USERS] CHANGE SKILL ERROR MESSAGE';
