import React from 'react';
import { SortOrder } from 'antd/es/table/interface';

import { ReactComponent as SortAsc } from 'helpers/icons/Table/ASCArrow.svg';
import { ReactComponent as SortDesc } from 'helpers/icons/Table/DESCArrow.svg';

import s from './helpers/styles.module.sass';
import { Column } from '../../helpers/types';
import { sortDirectionsDefaultIcon } from './helpers/constants';
import { SORT_DIRECTIONS } from '../../helpers/sorter';

interface TableSortIconProps {
  sortOrder?: boolean | string;
  column: Column;
  sortDirections?: SortOrder[];
}

const TableSortIcon: React.FC<TableSortIconProps> = ({ sortOrder, column, sortDirections }) => {
  const sortOrderIcon = sortOrder === SORT_DIRECTIONS.ascend ? <SortAsc /> : <SortDesc />;

  // Set defaultSortIcon according to first element in sortDirections array.
  const sortDirectionsPlaceholder = sortDirections && sortDirections[0] && sortDirectionsDefaultIcon[sortDirections[0]];
  const sorterIconPlaceholder = column.sorter ? (sortDirectionsPlaceholder || <SortAsc className="placeholder-sort-icon" />) : null;

  return (
    <div className={s.tableSortHeaderCell}>
      <span>{column.title}</span>
      {sortOrder ? sortOrderIcon : sorterIconPlaceholder}
    </div>

  );
};

export default TableSortIcon;
