import _ from 'lodash';
import dayjs from 'dayjs';

import { api } from 'index';
import { convertObjectKeysToLanguageCase } from 'helpers/utils/convertObject';
import configureStore from 'redux/_reducer/configureStore';
import { DEFAULT_PAGINATION_RAW, DefaultPaginationRaw, INCREASED_PAGINATION_RAW } from 'helpers/constants/_common/Pagination';

import { CuratorPartnerDetailedReports, GetCuratorEmployeeBody, GetCuratorItemsBody, UserPage } from './types';
import { MonthButtonBody, UserDetails, UserMonthOutput } from '../User/types';
import { UserPartnerOutput, UserPartnerPage, UserPinnedActivityOutput, UserPinnedActivityOutputPage } from '../Partners/types';
import { USER_ROLES } from '../User/constants';
import { convertCuratorReportResponse } from './helpers';

const CURATORS_REQUESTS = 'curatorsRequests';

export const getAllCuratorEmployees = async (curatorId: number, body: GetCuratorEmployeeBody) => api.get<UserPage>(`/curators/${curatorId}/partners/users/`, {
  ...convertObjectKeysToLanguageCase(body, 'snakeCase'),
  users_active: true,
  partners_active: true,

}, {
  requestId: `${CURATORS_REQUESTS}getAllCuratorEmployees`,
});

export const getCuratorUserById = async (userId: number) => (
  api.get<UserDetails>(`/users/${userId}/`, {}, { requestId: `${CURATORS_REQUESTS}getUserById` })
);

export const getCuratorMonthStatus = (body: MonthButtonBody) => {
  const errorObject = { 404: { hidden: true } };

  const { userId, year, month } = body;
  const curatorId = configureStore.getState().login.userId;

  return api.get<UserMonthOutput>(`/curators/${curatorId}/partners/users/${userId}/months/${year}/${month}/`, {}, { errorObject })
    .catch(() => Promise.reject({ isMonthClosed: true }));
};

export const getCuratorPartnersList = async (userId: number) => {
  const curatorId = configureStore.getState().login.userId;
  const partners: UserPartnerOutput[] = [];

  const recursivelyCuratorPartnersRequest = async (pagination: DefaultPaginationRaw) => {
    await api.get<UserPartnerPage>(`/users/${userId}/partners/`,
      {
        order_by: 'rank',
        active: true,
        role: USER_ROLES.USER,
        ...pagination,
      }, {
        requestId: `${CURATORS_REQUESTS}recursivelyCuratorPartnersRequest`,
      })
      .then((response: UserPartnerPage) => {
        partners.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > partners.length) {
          return recursivelyCuratorPartnersRequest({
            limit: (response.meta.limit || INCREASED_PAGINATION_RAW.limit),
            offset: (response.meta.offset || INCREASED_PAGINATION_RAW.offset) + (response.meta.limit || INCREASED_PAGINATION_RAW.limit),
          });
        }
      });
  };

  await recursivelyCuratorPartnersRequest({
    limit: DEFAULT_PAGINATION_RAW.limit,
    offset: DEFAULT_PAGINATION_RAW.offset,
  });

  return partners;
};

export const getCuratorActivitiesList = async (userId: number) => {
  const activities: UserPinnedActivityOutput[] = [];

  const recursivelyGetCuratorActivities = async (pagination: DefaultPaginationRaw) => {
    await api.get<UserPinnedActivityOutputPage>(`/users/${userId}/pinned_activities/`,
      {
        order_by: 'rank',
        partners_active: true,
        activities_active: true,
        ...pagination,
      }, {
        requestId: `${CURATORS_REQUESTS}recursivelyGetCuratorActivities`,
      })
      .then((response: UserPinnedActivityOutputPage) => {
        activities.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > activities.length) {
          return recursivelyGetCuratorActivities({
            limit: (response.meta.limit || INCREASED_PAGINATION_RAW.limit),
            offset: (response.meta.offset || INCREASED_PAGINATION_RAW.offset) + (response.meta.limit || INCREASED_PAGINATION_RAW.limit),
          });
        }
      });
  };

  await recursivelyGetCuratorActivities({
    limit: INCREASED_PAGINATION_RAW.limit,
    offset: INCREASED_PAGINATION_RAW.offset,
  });

  return activities;
};

export const getCuratorReportData = async (userId: number, dates: string | string[], body?: GetCuratorItemsBody) => {
  if (_.isString(dates)) {
    return getAllCuratorItemsReports(userId, dates, body);
  }

  if (_.isArray(dates)) {
    return getAllItemsCuratorReportsRange(userId, dates, body);
  }
};

/** Returns all items report of a user with given identifier for a given month */
export const getAllCuratorItemsReports = async (userId: number, date: string, body?: GetCuratorItemsBody) => {
  const year = dayjs(date).year();
  const month = dayjs(date).month() + 1;

  return api.get<CuratorPartnerDetailedReports>(`/users/${userId}/reports/all-items/${year}/${month}/`, {
    ...convertObjectKeysToLanguageCase(_.omit(body, 'searchValue'), 'snakeCase'),
    users_fullname_contains: body?.searchValue,
    users_username_contains: body?.searchValue,
    users_email_address_contains: body?.searchValue,
  })
    .then(response => convertCuratorReportResponse(response));
};

/** Returns all items report of a user with given identifier for a given datetime range */
export const getAllItemsCuratorReportsRange = async (userId: number, dates: string[], body?: GetCuratorItemsBody) => api.get<CuratorPartnerDetailedReports>(`/curators/${userId}/partners/reports/`, {
  start_date_gte: dates[0],
  end_date_lte: dates[1],
  ...convertObjectKeysToLanguageCase(_.omit(body, 'searchValue'), 'snakeCase'),
  users_fullname_contains: body?.searchValue,
  users_username_contains: body?.searchValue,
  users_email_address_contains: body?.searchValue,
})
  .then(response => convertCuratorReportResponse(response));
