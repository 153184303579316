export const CTS_LOAD_ALL_PARTNERS = '[CHANGE TIME SHEET] LOAD ALL PARTNERS';
export const CTS_LOAD_PARTNER_ACTIVITIES = '[CHANGE TIME SHEET] LOAD_PARTNER ACTIVITIES';
export const CTS_CHANGE_SINGLE_PARTNER = '[CHANGE TIME SHEET] CHANGE SINGLE PARTNER';
export const CTS_INIT_PARTNERS = '[CHANGE TIME SHEET] INIT PARTNERS';
export const CTS_CHANGE_SINGLE_ACTIVITY = '[CHANGE TIME SHEET] CHANGE SINGLE ACTIVITY';
export const CTS_CHANGE_TIME = '[CHANGE TIME SHEET] CHANGE TIME';
export const CTS_CHANGE_TIMESHEETS = '[CHANGE TIME SHEET] CHANGE TIMESHEETS';
export const CTS_MODAL_OPENED_FROM = '[CHANGE TIME SHEET] MODAL OPENED FROM';
export const CTS_CHANGE_REPORT_TIMESHEETS = '[CHANGE TIME SHEET] CHANGE REPORT TIMESHEETS';
export const CTS_END_DATA_CHANGING = '[CHANGE TIME SHEET] END DATA CHANGING';
