import { Button, Modal } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalProps } from 'antd/es/modal';

import modals from 'helpers/styles/components/modals.module.sass';
import withRightPlural from 'helpers/utils/withRightPlural';
import { replaceAndFillUserHourAction, replaceUserHourAction } from 'redux/TimeSheet/action';
import { DispatchType } from 'helpers/types/_common';

import S from './styles.module.sass';
import { getTableModalInner } from '../CellGroup/helpers/helpers';

interface ChooseHoursActionModalProps {
  selectedHoursAmount: number;
  emptyHoursAmount: number;
  isVisible: boolean;
  userId: number;
  data: {
    day: string,
    startCellIndex: number,
    endCellIndex: number,
    activityId: number,
  };
  getContainer?: ModalProps['getContainer'];
  onOk: () => void;
  onClose: () => void;
}

const ChooseHoursActionModal: React.FC<ChooseHoursActionModalProps> = (props) => {
  const { selectedHoursAmount, emptyHoursAmount, isVisible, onClose, onOk, userId, data } = props;
  const [ isReplaceLoading, setReplaceLoading ] = useState(false);
  const [ isReplaceAndAddLoading, setReplaceAndAddLoading ] = useState(false);

  const dispatch: DispatchType = useDispatch();

  if (!isVisible) return null;

  const doReplace = () => {
    setReplaceLoading(true);
    dispatch(replaceUserHourAction(userId, data))
      .then(() => {
        setReplaceLoading(false);
        onOk();
      })
      .catch(() => {
        setReplaceLoading(false);
      });
  };

  const doReplaceAndAdd = () => {
    setReplaceAndAddLoading(true);
    dispatch(replaceAndFillUserHourAction(userId, data))
      .then(() => {
        setReplaceAndAddLoading(false);
        onOk();
      })
      .catch(() => {
        setReplaceAndAddLoading(false);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  const renderContent = () => (
    <>
      <span>Are you sure you want to:</span>
      <ul>
        <li>
          <span className={S.dot}>•</span>
          <span>
            Replace
            {' '}
            <b>{selectedHoursAmount}</b>
            {' '}
            selected
            {' '}
            {withRightPlural('hour', selectedHoursAmount)}
          </span>
        </li>
        {emptyHoursAmount !== 0 && (
          <li>
            <span className={S.dot}>•</span>
            <span>
              Add
              {' '}
              <b>{emptyHoursAmount}</b> 
              {' '}
              {withRightPlural('hour', emptyHoursAmount)}
              ?
            </span>
          </li>
        )}
      </ul>
    </>
  );

  return (
    <Modal
      visible={isVisible}
      title="Replace activity"
      onOk={doReplace}
      centered
      onCancel={handleCancel}
      getContainer={props.getContainer || getTableModalInner()}
      className={classNames(modals.qsBasicAntdModal, S.confirmModal)}
      footer={[
        <Button
          key="back"
          type="text"
          className={modals.modalCancelBtn}
          style={{ marginRight: '0' }}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="replace"
          type="primary"
          title="Replace activity"
          loading={isReplaceLoading}
          className={modals.modalOkBtn}
          style={{ width: 'auto' }}
          onClick={doReplace}
        >
          Replace
        </Button>,
        <Button
          key="replaceAndAdd"
          type="primary"
          title="Replace and add activity for selected hours"
          loading={isReplaceAndAddLoading}
          hidden={emptyHoursAmount === 0}
          className={modals.modalOkBtn}
          style={{ width: 'auto' }}
          onClick={doReplaceAndAdd}
        >
          Replace & Add
        </Button>,
      ]}
    >
      <div className={S.content}>{renderContent()}</div>
    </Modal>
  );
};

export default ChooseHoursActionModal;
