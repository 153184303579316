import React from 'react';
import { Button, Typography } from 'antd';
import cx from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import buttons from 'helpers/styles/components/buttons.module.sass';
import { PATH_ADMINISTRATION, PATH_PARTNER_INFO, TAB_PARTNERS_NEW } from 'helpers/constants/_common/MenuCases';
import { ReactComponent as GoBackIcon } from 'helpers/icons/go-back-icon.svg';
import { PartnerDetailsOutput } from 'api/Partners/types';
import { EDIT_PARTNER_TABS } from 'helpers/constants/_common/tabs/administration';

import s from './styles.module.sass';
import sidebar from '../helpers/styles.module.sass';
import PartnerMask from '../../_shared/PartnerMask';

interface PartnerInfoSidebarProps {
  partner: PartnerDetailsOutput;
  tab: string;
}

const PartnerInfoSidebar: React.FC<PartnerInfoSidebarProps> = ({ partner, tab }) => {
  const history = useHistory();
  const { partnerId } = useParams<{ partnerId: string }>();

  const renderMenuItems = () => _.map(EDIT_PARTNER_TABS, menuTab => (
    <li
      key={menuTab.path}
      className={cx(sidebar.sidebarListItem, { [sidebar.activeSidebarList]: tab === menuTab.path })}
    >
      <Button
        type="text"
        onClick={() => history.replace(`${PATH_PARTNER_INFO}/${partnerId}/${menuTab.path}`)}
        className={sidebar.sidebarListItemLink}
      >
        <Typography.Text>{menuTab.name}</Typography.Text>
      </Button>
    </li>
  ));

  return (
    <div className={sidebar.sidebarLayout}>
      <div className={sidebar.sidebarContentWrapper}>
        <div className={sidebar.sidebarHeader}>
          <Button
            type="primary"
            className={cx(buttons.qsButtonPrimary, sidebar.goBackButton)}
            onClick={() => history.push(`${PATH_ADMINISTRATION}/${TAB_PARTNERS_NEW}`)}
          >
            <GoBackIcon />
            Back to Partners
          </Button>
        </div>

        <div className={s.partnerLogoWrapper}>
          <PartnerMask
            wrapperClassName={s.partnerLogo}
            wrapperColor={partner.color}
            iconSize={60}
            mask={partner.mask}
            iconColor={partner.maskColor}
            partnerId={partner.partnerId}
            isVacation={partner.isVacation}
          />

          <div className={s.partnerDescription}>
            <span className={s.partnerTitle}>{partner.name}</span>
            <span className={s.partnerId}>{`Partner ID ${partner.partnerId}`}</span>
          </div>
        </div>

        <ul className={sidebar.sidebarList}>
          {renderMenuItems()}
        </ul>
      </div>
    </div>
  );
};

export default PartnerInfoSidebar;
