import { BaseActivityOutput, BasePartnerOutput } from 'api/Partners/types';
import { BCDay, UserHourComment } from 'api/TimeSheet/types';

export interface OriginalRecordingTimeStamps {
  startTime: string;
  endTime: string;
}

export enum CellType {
  Stored = 'STORED',
  Empty = 'EMPTY',
}

export interface CellOfGroup {
  cellIndex: number;
  type: CellType;
  comment: UserHourComment | null;
  timesheetId: number;
}

export interface EmptyCell {
  cellIndex: number;
  type: CellType;
}

export interface GroupOfCells extends EmptyCell {
  groupCells: CellOfGroup[];
  userHourData: UsersHourData;
  timesheetId: number | null; // null for new items with unknown id
}

export interface UsersHourData {
  activity: BaseActivityOutput | undefined;
  partner: BasePartnerOutput | undefined;
}

export interface UserHoursTable {
  [key: string]: UserHoursColumn;
}

export interface UserHoursColumn extends BCDay {
  cells: (GroupOfCells | EmptyCell)[],
}

export interface GroupsOfCellsByCellIndex {
  [key: number]: CellOfGroup,
}

export interface SelectedHour {
  usersHourData: UsersHourData;
  comment: UserHourComment;
  startCellIndex: number;
  endCellIndex: number;
  timesheetId: number | null;
}
