export const gerCorrectHex = (value: string) => {
  const withPrefix = value[0] === '#';
  const hexValue = value.slice(withPrefix ? 1 : 0, withPrefix ? 7 : 6);

  return isHex(`#${hexValue}`) ? `#${hexValue}` : null;
};

export const isHex = (hex: string) => {
  if (hex[0] === '#' && hex.length > 7) return false;

  const re = /[0-9A-Fa-f]{6}/g;
  return re.test(hex);
};
