import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { BookkeepingItem, BookkeepingItemActivity, BookkeepingItemEntity, BookkeepingItemPartner, BookkeepingItemUser } from 'redux/Tables/reducerTypes';
import PartnerMask from 'components/_shared/PartnerMask';
import { ActivityCardOutput, PartnerCardOutput } from 'api/Partners/types';
import { UserCardOutput } from 'api/User/types';
import { Entity } from 'api/Entities/types';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import s from './styles.module.sass';

enum BookkeepingItemType {
  PARTNER = 'partner',
  ACTIVITY = 'activity',
  USER = 'user',
  ENTITY = 'entity',
}

export const getBookkeepingItemType = (record: BookkeepingItem): BookkeepingItemType | null => {
  switch (true) {
    case _.has(record, 'partner'):
      return BookkeepingItemType.PARTNER;
    case _.has(record, 'user'):
      return BookkeepingItemType.USER;
    case _.has(record, 'entity'):
      return BookkeepingItemType.ENTITY;
    case _.has(record, 'activity'):
      return BookkeepingItemType.ACTIVITY;
    default:
      return null;
  }
};

const renderPartnerName = (partner: PartnerCardOutput) => (
  <div className={s.nameIcon} title={partner.name}>
    <PartnerMask
      wrapperColor={partner.color}
      iconColor={partner.maskColor}
      mask={partner.mask}
      wrapperClassName={s.partnerMaskWrapper}
      partnerId={partner.partnerId}
      isVacation={partner.isVacation}
    />
    <span title={partner.name} className={classNames(s.nameStr, s.limited)}>{partner.name}</span>
  </div>
);

const renderUserName = (user: UserCardOutput) => {
  const fullName = `${_.upperFirst(user.forename)} ${_.upperFirst(user.surname)}`;
  
  return (
    <div className={s.nameIcon} title={fullName}>
      <UserAvatar avatarUrl={user.avatarUrl} />
      <span title={fullName} className={classNames(s.nameStr, s.limited)}>{fullName}</span>
    </div>
  );
};

const renderEntityName = (entity: Entity) => (
  <div className={s.nameIcon} title={entity.name}>
    <FontAwesomeIcon className={s.cubes} icon="cubes" style={{ width: 24, height: 24 }} />
    <span title={entity.name} className={classNames(s.nameStr, s.limited)}>{entity.name}</span>
  </div>
);

const renderActivityName = (activity: ActivityCardOutput) => (
  <div className={s.nameIcon} title={activity.name}>
    <PartnerMask
      wrapperColor={activity.partnerColor}
      iconColor={activity.color}
      mask={activity.partnerMask}
      wrapperClassName={s.partnerMaskWrapper}
      isVacation={false} // TODO: Fix to real value after backend is made to provide it
    />
    <span title={activity.name} className={classNames(s.nameStr, s.limited)}>
      <span className={s.partnerName}>
        {activity.partnerName}
        :
      </span>
      <span className={s.activityName}>{activity.name}</span>
    </span>
  </div>
);

export const renderName = (record: BookkeepingItem) => {
  switch (getBookkeepingItemType(record)) {
    case BookkeepingItemType.PARTNER:
      return renderPartnerName((record as BookkeepingItemPartner).partner);
    case BookkeepingItemType.USER:
      return renderUserName((record as BookkeepingItemUser).user);
    case BookkeepingItemType.ENTITY:
      return renderEntityName((record as BookkeepingItemEntity).entity);
    case BookkeepingItemType.ACTIVITY:
      return renderActivityName((record as BookkeepingItemActivity).activity);
    default:
      return '-';
  }
};

export const renderDescription = (record: BookkeepingItem) => {
  switch (getBookkeepingItemType(record)) {
    case BookkeepingItemType.PARTNER:
      return getDescription((record as BookkeepingItemPartner).partner);
    case BookkeepingItemType.ENTITY:
      return getDescription((record as BookkeepingItemEntity).entity);
    case BookkeepingItemType.ACTIVITY:
      return getDescription((record as BookkeepingItemActivity).activity);
    case BookkeepingItemType.USER:
      return getDescription((record as BookkeepingItemUser).user);
    default:
      return '-';
  }
};

export const renderActiveStatus = (record: BookkeepingItem) => {
  switch (getBookkeepingItemType(record)) {
    case BookkeepingItemType.PARTNER:
      return getActiveStatus((record as BookkeepingItemPartner).partner);
    case BookkeepingItemType.USER:
      return getActiveStatus((record as BookkeepingItemUser).user);
    case BookkeepingItemType.ACTIVITY:
      return getActiveStatus((record as BookkeepingItemActivity).activity);
    case BookkeepingItemType.ENTITY:
      return <FontAwesomeIcon title="Active" icon="check-circle" className={s.statusIcon} />;
    default:
      return '-';
  }  
};

export const renderTime = (record: BookkeepingItem, str: 'creationDate' | 'lastUpdate') => {
  switch (getBookkeepingItemType(record)) {
    case BookkeepingItemType.PARTNER:
      return getTimeStr((record as BookkeepingItemPartner).partner, str);
    case BookkeepingItemType.ENTITY:
      return getTimeStr((record as BookkeepingItemEntity).entity, str);
    case BookkeepingItemType.ACTIVITY:
      return getTimeStr((record as BookkeepingItemActivity).activity, str);
    case BookkeepingItemType.USER:
      return getTimeStr((record as BookkeepingItemUser).user, str);
    default:
      return '-';
  }
};

const getDescription = (object: PartnerCardOutput | UserCardOutput | ActivityCardOutput | Entity) => (
  <div className={s.descriptionWrapper}>
    <span
      title={object.description || '-'}
      className={classNames(s.description, s.limited)}
    >
      {object.description || '-'}
    </span>
  </div>
);

const getActiveStatus = (object: PartnerCardOutput | UserCardOutput | ActivityCardOutput) => (
  object.active
    ? <FontAwesomeIcon title="Active" icon="check-circle" className={s.statusIcon} />
    : null
);

const getTimeStr = (object: PartnerCardOutput | ActivityCardOutput | UserCardOutput | Entity, str: 'creationDate' | 'lastUpdate') => {
  const time = dayjs(object[str]);

  return (
    <span title={time.format('DD.MM.YYYY [at] HH:mm')} className={s.date}>{time.format(RU_DOT_DATE_FORMAT)}</span>
  );
};
