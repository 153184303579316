import React, { useEffect, useState } from 'react';
import { Drawer, Input, Skeleton, Space, Button } from 'antd';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as DeleteIcon } from 'helpers/icons/trash.svg';
import { DispatchType, ValidationError } from 'helpers/types/_common';
import {
  getSkillTagStatistic,
  getSkillTagSynonyms,
  deleteSynonymTag,
  addSynonymToTag, deleteSkillTag, getSkillTagUsers,
} from 'redux/SkillTags/actions';
import { SkillTagStatistic, SkillTagSynonym, ExperienceCounts, DrawerUsers } from 'api/SkillTags/types';
import SKILL_CATEGORIES from 'helpers/constants/Skills/Categories';
import { AttitudesEnum, AttitudesEnumLC, SkillCategoriesConstant } from 'helpers/types/Skills';
import { convertObjectKeysToLanguageCase } from 'helpers/utils/convertObject';
import useApi from 'api/hooks/useApi/useApi';
import { User } from 'helpers/types/User';
import { setSessionStorage } from 'helpers/utils/sessionStorageService';
import { DEFAULT_SPECIALISTS_SKILLS } from 'helpers/constants/_common/sessionStorageConstants';
import SKILL_ATTITUDES from 'helpers/constants/Skills/Attitudes';

import s from './helpers/styles.module.sass';
import { OVERALL_EXPERIENCE, MINIMUM_USERS_KNOWN_BY } from './helpers/constants';
import SkillTag from '../../../_shared/SkillTag';
import { SkillTagInfoPanelProps } from './helpers/types';
import { ReactComponent as SkillTagLogo } from './helpers/icons/skill-logo.svg';
import SkillAttitude from '../../../ProfileTabContent/components/SkillSet/components/Attitude';
import UserAvatar from '../../../UserAvatar/UserAvatar';
import EmployeeDrawer from '../../../Drawers/EmployeeDrawer';
import { ReactComponent as GoBackIcon } from '../../../../helpers/icons/go-back-icon.svg';
import { EmployeeDrawerData } from '../../../Drawers/EmployeeDrawer/helpers/types';
import { SkillDeleteConfirmModal } from '../SelectionSkillTagPanel/helpers/helpers';

const cx = classNames.bind(s);

const SkillTagDrawer: React.FC<SkillTagInfoPanelProps> = (props) => {
  const { visible, skillId, onClose, onDeleteCallback } = props;

  const dispatch: DispatchType = useDispatch();

  const [ tagInputName, setTagInputName ] = useState<string>();
  const [ addSynonymError, setAddSynonymError ] = useState<ValidationError>();
  const [ addSynonymLoading, setAddSynonymLoading ] = useState(false);

  const [ employeeDrawerData, setEmployeeDrawerData ] = useState<EmployeeDrawerData>({ userId: undefined, visible: false });

  const [ getStatistic, info, loading ] = useApi<typeof getSkillTagStatistic, SkillTagStatistic>(getSkillTagStatistic);
  const [ getSynonyms, synonyms, loadingSynonyms, setSynonyms ] = useApi<typeof getSkillTagSynonyms, SkillTagSynonym[]>(getSkillTagSynonyms);
  const [ getUsers, users, loadingUsers ] = useApi<typeof getSkillTagUsers, DrawerUsers>(getSkillTagUsers);

  useEffect(() => {
    if (visible && skillId) {
      getStatistic(skillId);
      getSynonyms(skillId);
      getUsers(skillId);
    }
  }, [visible]);

  const renderHeader = () => (
    <div className={cx('manage-skills-drawer_header')}>
      <div className={cx('manage-skills-drawer_header-content')}>
        <div className={cx('manage-skills-drawer_header-logo')}>
          <SkillTagLogo
            className={cx('manage-skills-drawer_header-logo-arrow')}
          />
        </div>
        <div className={cx('manage-skills-drawer_header-title')}>
          <h2>{info?.name}</h2>
          <span className={cx('manage-skills-drawer_header-title_category')}>
            {SKILL_CATEGORIES[info?.category as keyof SkillCategoriesConstant]?.title}
          </span>
        </div>
      </div>
    </div>
  );

  const renderUserItem = (user: User) => (
    <div
      key={user.userId}
      className={cx('drawer-row_user')}
      onClick={() => {
        setEmployeeDrawerData({ userId: user.userId, visible: true });
      }}
    >
      <UserAvatar avatarUrl={user.avatarUrl} />
      {`${user.forename || 'Quantum'} ${user.surname || 'Soft'}`}
    </div>
  );

  const renderAuthor = () => (
    <div className={cx([ 'drawer-row', 'author' ])}>
      <div className={cx('drawer-row_title')}>
        Created by
      </div>
      <div className={cx('drawer-row_description')}>
        <UserAvatar avatarUrl={info?.author?.avatarUrl} />
        {`${info?.author?.forename || 'Quantum'} ${info?.author?.surname || 'Soft'}`}
      </div>
    </div>
  );

  const renderSynonyms = () => {
    const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddSynonymError(undefined);
      setTagInputName(event.currentTarget.value);
    };

    const handleEnterInput = () => {
      if (!tagInputName) {
        return;
      }

      if (skillId) {
        setAddSynonymLoading(true);
        dispatch(addSynonymToTag(tagInputName, skillId))
          .then((newSynonym: SkillTagSynonym) => {
            const convertedNewSynonym = convertObjectKeysToLanguageCase<SkillTagSynonym>(newSynonym);

            setSynonyms([ ...synonyms, convertedNewSynonym ]);
            setTagInputName(undefined);
          })
          .catch((error: AxiosError<ValidationError>) => setAddSynonymError(error.response?.data))
          .finally(() => setAddSynonymLoading(false));
      }
    };

    const renderSynonymsTags = () => {
      const onDelete = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, id: number) => {
        if (skillId) {
          return dispatch(deleteSynonymTag(id, skillId))
            .then(() => {
              const restSynonyms = _.filter(synonyms, s => s.synonymId !== id);
              setSynonyms(restSynonyms);
            });
        }
      };

      return _.map(synonyms, s => (
        <SkillTag
          title={s.name}
          key={s.synonymId}
          id={s.synonymId}
          onDelete={onDelete}
        />
      ));
    };

    const suffix = () => {
      if (addSynonymLoading) {
        return (
          <LoadingOutlined spin />
        );
      }

      return (
        <FontAwesomeIcon
          icon="plus"
          onClick={handleEnterInput}
          className={cx('add-synonym-button', { 'add-synonym-button--show': Boolean(tagInputName) })}
        />
      );
    };

    return (
      <div className={cx([ 'drawer-row', 'synonyms' ])}>
        <div className={cx('drawer-row_title')}>
          Synonyms
        </div>
        <div className={cx('synonyms-wrapper', { 'synonyms-wrapper--loading': loadingSynonyms })}>
          <div className={cx('drawer-row_description')}>
            {renderSynonymsTags()}
            <Input
              value={tagInputName}
              className={cx('synonyms-input')}
              placeholder="Type to add..."
              onChange={handleInputOnChange}
              onPressEnter={handleEnterInput}
              bordered={false}
              maxLength={63}
              disabled={addSynonymLoading}
              suffix={suffix()}
            />
          </div>
        </div>
        <small className={cx(`synonym-error${addSynonymError?.error ? '--show' : ''}`)}>{addSynonymError?.error}</small>
      </div>
    );
  };

  const renderDescription = () => (
    <div className={cx([ 'drawer-row', 'description' ])}>
      <div className={cx('drawer-row_title')}>Description</div>
      <div className={cx('employees-wrapper', { 'employees-wrapper--loading': loadingUsers })}>
        <div className={cx('drawer-row_description')}>
          { info?.description || '-' }
        </div>
      </div>
    </div>
  );

  const renderExperience = () => {
    const renderExpTags = () => {
      if (!info?.experienceShares) {
        return '-';
      }

      const expTags = _.reduce(info?.experienceShares, (acc, value, key) => {
        if (!value) return acc;

        const { title, color } = OVERALL_EXPERIENCE[key as keyof ExperienceCounts];
        const tooltip = info?.experienceCounts && info?.experienceCounts[key as keyof ExperienceCounts];

        return [
          ...acc,
          <SkillTag
            key={key}
            tooltip={`${tooltip} employee(s) set this experience`}
            className={cx('exp-tag')}
            color={color}
            title={`${title} ${value}%`}
          />,
        ];
      }, [] as React.ReactNode[]);

      return _.isEmpty(expTags) ? '-' : _.reverse(expTags);
    };

    return (
      <div className={cx([ 'drawer-row', 'experience' ])}>
        <div className={cx('drawer-row_title')}>
          Overall experience
        </div>
        <div className={cx('drawer-row_description')}>
          {renderExpTags()}
        </div>
      </div>
    );
  };

  const renderEmotions = () => {
    const emotionTags = _.reduce(info?.attitudeShares, (acc, value, key) => {
      if (!value) {
        return acc;
      }

      const emojiName = SKILL_ATTITUDES[_.toUpper(key) as AttitudesEnum].title;
      const tooltip = info?.attitudeCounts && info?.attitudeCounts[key as AttitudesEnumLC];

      return [
        ...acc,
        <SkillTag
          tooltip={`${tooltip} employee(s) reacted with ${emojiName}`}
          className={cx('emotion-tag')}
          key={key}
          title={(
            <div className={cx('emotion-tag_content')}>
              {`${value}%`}
              <SkillAttitude iconName={_.toUpper(key) as AttitudesEnum} />
            </div>
          )}
        />,
      ];
    }, [] as React.ReactNode[]);

    return (
      <div className={cx([ 'drawer-row', 'emotions' ])}>
        <div className={cx('drawer-row_title')}>
          Emotions
        </div>
        <div className={cx('drawer-row_description')}>
          {_.isEmpty(emotionTags) ? '-' : emotionTags}
        </div>
      </div>
    );
  };

  const renderEmployeesFields = () => {
    const usersList = _.isEmpty(users?.objects) ? '-' : _.map(users?.objects, u => renderUserItem(u.user));

    const renderShowAll = () => {
      if (!users || users?.objects?.length < MINIMUM_USERS_KNOWN_BY) {
        return null;
      }

      const showAllCount = users?.meta?.totalCount ? users?.meta?.totalCount - MINIMUM_USERS_KNOWN_BY : 0;

      if (!showAllCount) return null;

      return (
        <div className={cx('drawer-row_user')}>
          <Link
            className={cx('show-all-link')}
            onClick={() => setSessionStorage(DEFAULT_SPECIALISTS_SKILLS, info)}
            to={{ pathname: '/specialists' }}
          >
            <div className={cx('show-all-logo')}>{`+ ${showAllCount}`}</div>
            <span className={cx('show-all-title')}>Show all</span>
          </Link>
        </div>
      );
    };

    return (
      <div className={cx([ 'drawer-row', 'employees' ])}>
        <div className={cx('drawer-row_title')}>Known by</div>
        <div className={cx('employees-wrapper', { 'employees-wrapper--loading': loadingUsers })}>
          <div className={cx('drawer-row_description', { empty: _.isEmpty(users?.objects) })}>
            {usersList || '-'}
            {renderShowAll()}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => (
    <div className={cx('manage-skills-drawer_body')}>
      {renderSynonyms()}
      {renderDescription()}
      {renderExperience()}
      {renderEmployeesFields()}
      {renderEmotions()}
      {renderAuthor()}
    </div>
  );

  const handleDeleteButton = () => {
    if (!skillId) {
      return;
    }

    const handleOkDelete = () => dispatch(deleteSkillTag(skillId))
      .then(() => {
        onClose();
        onDeleteCallback(skillId);
      });

    SkillDeleteConfirmModal(handleOkDelete, info);
  };

  const renderFooter = () => (
    <div className={cx('manage-skills-drawer_footer')}>
      <Space
        size={12}
      >
        <Button
          className={cx(buttons.qsButtonPrimary, s.deleteButton)}
          type="primary"
          disabled={!info || loadingSynonyms}
          onClick={handleDeleteButton}
        >
          <div className={buttons.iconTextWrapper}>
            <DeleteIcon style={{ fill: '#fff' }} />
            Delete
          </div>
        </Button>

        <Button className={buttons.qsButton} onClick={onClose}>Close</Button>
      </Space>

    </div>
  );

  const renderDrawerContent = () => {
    if (!visible) {
      return null;
    }

    return (
      <div className={cx('manage-skills-drawer_wrapper')}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  };

  const onCloseDrawer = () => {
    setEmployeeDrawerData({ userId: undefined, visible: false });
  };

  const customProps = _.omit(props, [ 'info', 'skillId', 'onDeleteCallback' ]);

  return (
    <>
      <Drawer
        {...customProps}
        className={cx('manage-skills-drawer')}
        width={715}
        footer={renderFooter()}
      >
        <Skeleton
          active
          loading={loading}
        >
          {renderDrawerContent()}
        </Skeleton>
      </Drawer>

      <EmployeeDrawer
        className={s.skillsetEmployeeDrawer}
        drawerData={employeeDrawerData}
        onClose={onCloseDrawer}
        closeIcon={false}
        extra={(
          <Button
            type="primary"
            key="go-back-button"
            className={cx(buttons.qsButtonPrimary, s.employeeDrawerHeader)}
            onClick={onCloseDrawer}
          >
            <span>Back to skill tags</span>
            <GoBackIcon className={s.employeeDrawerIcon} />
          </Button>
        )}
      />
    </>

  );
};

export default SkillTagDrawer;
