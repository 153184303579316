import React from 'react';
import classNames from 'classnames/bind';
import S from '../../styles.module.css';
import _ from 'lodash';

const cx = classNames.bind(S);

interface TableHeaderCellComponentProps {
  selected?: boolean;
  sortingMode?: 'single' | 'multi';
  sortingOrder?: 'asc' | 'desc';
  defaultSortingOrder?: 'asc';
  colSpan?: number;
  onClick?: (e?: React.MouseEvent) => void;
  sortable?: boolean;
  width?: number | string;
  id?: string;
  type?: 'text' | 'numbers';
  isLoading?: boolean;
  backendSorting?: boolean;
  style?: React.CSSProperties;
  className?: string;
}
interface TableHeaderCellComponentState {
  selected?: boolean;
  sortingOrder?: 'asc' | 'desc';
  sortingMode?: 'single' | 'multi';
  defaultSortingOrder?: 'asc';
}

export default class TableHeaderCell extends React.Component<
  TableHeaderCellComponentProps,
  TableHeaderCellComponentState
> {
  constructor(props: TableHeaderCellComponentProps) {
    super(props);

    this.state = {
      selected: props.selected,
      sortingOrder: props.sortingOrder || props.defaultSortingOrder || 'asc',
      sortingMode: props.sortingMode || 'single',
      defaultSortingOrder: props.defaultSortingOrder || 'asc'
    };
  }

  componentDidUpdate(prevProps: Readonly<TableHeaderCellComponentProps>): void {
    if (prevProps.selected !== this.props.selected) {
      if (!this.props.selected)
        this.setState({
          selected: this.props.selected,
          sortingOrder: this.state.sortingOrder
        });
      else this.setState({ selected: this.props.selected });
    }
    if (prevProps.sortingOrder !== this.props.sortingOrder) {
      if (this.props.sortingOrder)
        this.setState({ sortingOrder: this.props.sortingOrder });
      else this.setState({ sortingOrder: this.state.defaultSortingOrder });
    }
    if (prevProps.sortingMode !== this.props.sortingMode) {
      this.setState({ sortingMode: this.props.sortingMode });
    }
  }

  handleClick(e: React.MouseEvent): void {
    const {
      selected,
      sortingOrder,
      sortingMode,
      defaultSortingOrder
    } = this.state;
    const { sortable, isLoading, backendSorting, onClick } = this.props;

    if (isLoading) {
      return;
    }

    if (backendSorting) {
      onClick && onClick(e);

      return;
    }

    if (selected) {
      if (sortable) {
        switch (sortingMode) {
          case 'single':
            _.invoke(this.props, 'onClick', e, sortingOrder);
            break;
          case 'multi':
            this.setState({
              sortingOrder: sortingOrder === 'desc' ? 'asc' : 'desc'
            });
            _.invoke(
              this.props,
              'onClick',
              e,
              sortingOrder === 'desc' ? 'asc' : 'desc'
            );
            break;
          default:
            break;
        }
      } else {
        _.invoke(this.props, 'onClick', e);
      }
    } else {
      if (sortable) {
        switch (sortingMode) {
          case 'single':
            _.invoke(this.props, 'onClick', e, defaultSortingOrder);
            break;
          case 'multi':
            this.setState({
              sortingOrder: sortingOrder === 'desc' ? 'asc' : 'desc'
            });
            _.invoke(
              this.props,
              'onClick',
              e,
              sortingOrder === 'desc' ? 'asc' : 'desc'
            );
            break;
          default:
            break;
        }
      } else {
        _.invoke(this.props, 'onClick', e);
      }
    }
  }

  render(): React.ReactNode {
    const { id, type, sortable, selected, className } = this.props;
    const { sortingOrder, sortingMode } = this.state;

    const thClasses = cx(
      {
        'qs-table__head-cell': true,
        'qs-table__head-cell-text': type === 'text',
        'qs-table__head-cell-numbers': type === 'numbers',
        'qs-table__head-cell--sortable': sortable,
        'qs-table__head-cell--selected': selected
      },
      className
    );

    const descArrow = cx({
      'qs-table__sort-arrow': true,
      'qs-table__sort-arrow--descending': true,
      'qs-table__sort-arrow--descending-selected':
        selected && sortingOrder === 'desc'
    });

    const ascArrow = cx({
      'qs-table__sort-arrow': true,
      'qs-table__sort-arrow--ascending': true,
      'qs-table__sort-arrow--ascending-selected':
        selected && sortingOrder === 'asc'
    });

    const calcStyles = {
      ...this.props.style,
      width: this.props.width || 'auto'
    };

    return (
      <th
        id={id}
        style={calcStyles}
        colSpan={this.props.colSpan}
        className={thClasses}
        onClick={this.handleClick.bind(this)}
      >
        <div className={cx('qs-table__head-cell-content')}>
          {this.props.children}
          {sortable && sortingMode === 'multi' && (
            <p className={cx('qs-table__sort-wrapper')}>
              <span className={ascArrow} />
              <span className={descArrow} />
            </p>
          )}
          {sortable && sortingMode === 'single' && sortingOrder === 'desc' && (
            <div className={descArrow} />
          )}
          {sortable && sortingMode === 'single' && sortingOrder === 'asc' && (
            <div className={ascArrow} />
          )}
        </div>
      </th>
    );
  }
}
