import * as Actions from '../constants/TimeTracker';
import dayjs from 'dayjs';
import Subtypes from '../constants/__common__/Subtypes';

let InitialState = {
  month: dayjs().month(),
  year: dayjs().year(),
  panelYear: dayjs().year(),
  selectedPartner: 0,
  selectedActivity: 0,
  favouritePartner : 0,
  favouriteActivity: 0,
  showMonthList: false,
  monthClosed: false,
  monthStatusLoading: false
};

function changeMonth(state, increase) {
  let year = state.year,
    month = state.month,
    date = {};
  if (increase) {
    state.month === 11
      ? (date = { month: 0, year: year + 1 })
      : (date = { month: month + 1, year: year });
  } else {
    state.month === 0
      ? (date = { month: 11, year: year - 1 })
      : (date = { month: month - 1, year: year });
  }
  return { ...state, month: date.month, year: date.year };
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CHANGE_MONTH:
      return { ...state, month: action.payload, year: state.panelYear };
    case Actions.CHANGE_YEAR:
      return { ...state, year: action.payload };
    case Actions.CHANGE_MONTH_YEAR:
      return { ...state, year: action.payload.year, panelYear: action.payload.year, month: action.payload.month };
    case Actions.CHANGE_PARTNER:
      return {
        ...state,
        selectedPartner: action.payload.partner,
        selectedActivity: action.payload.activity
      };
    case Actions.DROP_PARTNER_SELECTION:
      return { ...state, selectedPartner: 0, selectedActivity: 0 };
    case Actions.DROP_LOCAL_FAVOURITES:
      return { ...state, favouritePartner: 0, favouriteActivity: 0};
    case Actions.CHANGE_FAVOURITE_ACTIVITY:
      return { ...state, favouritePartner: action.payload.partner, favouriteActivity: action.payload.activity };
    case Actions.LOAD_FAVOURITES_TO_PANEL:
      return { ...state, selectedPartner: state.favouritePartner, selectedActivity: state.favouriteActivity };
    case Actions.PREVIOUS_MONTH:
      return changeMonth(state, false);
    case Actions.NEXT_MONTH:
      return changeMonth(state, true);
    case Actions.CHANGE_PANEL_YEAR:
      return { ...state, panelYear: action.payload };
    case Actions.SHOW_MONTH_LIST: {
      let panelYear = state.showMonthList ? state.panelYear : state.year;
      return { ...state, showMonthList: !state.showMonthList, panelYear };
    }
    case Actions.TIME_TRACKER_INITIATE:
      return InitialState;
    case Actions.CHECK_CURRENT_MONTH:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, monthStatusLoading: true };
        case Subtypes.Request.Success:
          return { ...state, monthClosed: action.payload, monthStatusLoading: false };
        case Subtypes.Request.Error:
          return { ...state, monthStatusLoading: false };
        default:
          return state;
      }

    case Actions.CLOSE_CURRENT_MONTH:
      switch (action.subtype) {
        case Subtypes.Request.Loading:
          return { ...state, errorWhileClosing: false, monthStatusLoading: true };
        case Subtypes.Request.Success:
          return { ...state, monthClosed: action.payload.closed, errorWhileClosing: false, monthStatusLoading: false };
        case Subtypes.Request.Error:
          return { ...state, errorWhileClosing: true, monthStatusLoading: false };
        default:
          return state;
      }
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
