import React, { useState } from 'react';
import { Button, List, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import cx from 'classnames';
import modal from 'antd/es/modal';

import popover from 'helpers/styles/components/popover.module.sass';
import { ReactComponent as RemovePartnerIcon } from 'helpers/icons/trash.svg';
import { ReactComponent as DragIndicatorIcon } from 'helpers/icons/DragIndicator.svg';
import { UserPartnerOutput } from 'api/Partners/types';
import PartnerMask from 'components/_shared/PartnerMask';
import { removePartnerFromList } from 'api/Partners/requests';
import modals from 'helpers/styles/components/modals.module.sass';

import PartnerActivityListItem from '../PartnerActivityListItem';
import s from './helpers/styles.module.sass';

interface PartnerListItemProps {
  userId: number;
  collapsed: boolean;
  isEdit: boolean;
  partnerObject: UserPartnerOutput;
  onAddActivity: (activityId: number) => Promise<any> | undefined;
  onRemovePartnerCallback: () => void;
  curatorOnlyView?: boolean;
}

const PartnerListItem: React.FC<PartnerListItemProps> = (props) => {
  const {
    isEdit, collapsed, partnerObject, onAddActivity,
    userId, onRemovePartnerCallback, curatorOnlyView,
  } = props;

  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const popoverContent = (partnerObject: UserPartnerOutput) => {
    const options = _.map(partnerObject.partner.activities, activity => (
      <PartnerActivityListItem
        key={activity.activityId}
        activity={activity}
        onAddActivity={onAddActivity}
        partnerObject={partnerObject}
        setPopoverVisible={setPopoverVisible}
        curatorOnlyView={curatorOnlyView}
      />
    ));

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        {_.isEmpty(options) ? <div className={s.emptyActivityOption}>No activities yet.</div> : options}
      </div>
    );
  };

  const handleRemovePartnerModal = (partnerObject: UserPartnerOutput) => {
    const handleOnRemove = () => removePartnerFromList({ userId, partnerObject })
      .then(() => onRemovePartnerCallback());

    modal.confirm({
      className: modals.qsConfirmAntdModal,
      title: 'Unassign partner',
      content: `Are you sure you want to unassign ${partnerObject.partner.name}? This will not affect previously tracked hours, but all activities of this partner will be unpinned.`,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn },
      okText: 'Unassign',
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      icon: null,
      width: 500,
      onOk: handleOnRemove,
    });
  };

  const renderRemoveButton = (partnerObject: UserPartnerOutput) => {
    if (collapsed || !isEdit) {
      return null;
    }

    if ((partnerObject.partner.isVacation)) {
      return null;
    }

    return (
      <Button
        type="text"
        size="small"
        className={s.removePartnerButton}
        title="Remove Partner"
        onClick={() => handleRemovePartnerModal(partnerObject)}
      >
        <RemovePartnerIcon className={s.removePartnerIcon} />
      </Button>
    );
  };

  const renderDragIcon = () => {
    if (collapsed || !isEdit) {
      return null;
    }

    return <DragIndicatorIcon className={s.dragIndicator} />;
  };

  const avatar = (
    <PartnerMask
      wrapperColor={partnerObject.partner.color}
      mask={partnerObject.partner.mask}
      partnerId={partnerObject.partner.partnerId}
      isVacation={partnerObject.partner.isVacation}
      iconColor={partnerObject.partner.activities[0]?.color}
    />
  );

  const renderActivitiesCollapseIcon = () => {
    if (isEdit) {
      return null;
    }

    return (
      <Button
        className={s.chevronPartnerButton}
        type="text"
        size="small"
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    );
  };

  const listItem = (
    <List.Item
      className={cx(s.partnerListItem, { [s.editPartnerListItem]: isEdit, [s.activePartnerListItem]: popoverVisible })}
      title={partnerObject.partner.name}
    >
      <List.Item.Meta
        className={s.partnerListItemMeta}
        description={(
          <div className={s.partnerListItemDescription}>
            <div className={s.descriptionTextWrapper}>
              {renderDragIcon()}
              {avatar}
              <span className={s.descriptionText}>{partnerObject.partner.name}</span>
            </div>

            {renderActivitiesCollapseIcon()}
            {renderRemoveButton(partnerObject)}

          </div>
        )}
      />
    </List.Item>
  );

  return isEdit
    ? listItem
    : (
      <Popover
        align={{ offset: [ -8, 0 ] }}
        trigger="click"
        placement="right"
        overlayClassName={popover.dotsPopoverOverlay}
        content={popoverContent(partnerObject)}
        getPopupContainer={() => (document.getElementById('pages-sidebar') || document.body)}
        onVisibleChange={setPopoverVisible}
        visible={popoverVisible}
      >
        {listItem}
      </Popover>
    );
};

export default PartnerListItem;
