import React from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import S from '../styles.module.css';

const cx = classNames.bind(S);

interface TableRowComponentProps {
  oneLine?: boolean;
  selectable?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  title?: string;
}

class TableRow extends React.Component<TableRowComponentProps, {}> {
  handleClick(e: React.MouseEvent): void {
    if (this.props.onClick) {
      _.invoke(this.props, 'onClick', e);
    }
  }

  render(): React.ReactNode {
    const classNames = cx(
      {
        'qs-table__row': true,
        'qs-table__row--one-line': this.props.oneLine,
        'qs-table__row--selectable': this.props.selectable,
        'qs-table__row--disabled': this.props.disabled
      },
      this.props.className
    );

    return (
      <tr
        className={classNames}
        onClick={this.handleClick.bind(this)}
        title={this.props.title}
      >
        {this.props.children}
      </tr>
    );
  }
}

export default TableRow;
