import { Button, Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { useState } from 'react';
import classNames from 'classnames';

import { FULL_MONTH_FORMAT } from 'helpers/constants/_common/timeFormats';
import { UsersBCMonth } from 'api/TimeSheet/types';

import { ReactComponent as PrevIcon } from './helpers/prevIcon.svg';
import { ReactComponent as NextIcon } from './helpers/nextIcon.svg';
import { ReactComponent as ArrowIcon } from './helpers/arrowIcon.svg';
import S from './helpers/styles.module.sass';

interface MonthSelectorProps {
  selectedDateObj: Dayjs;
  isPickerDisabled: boolean;
  usersBCMonth: UsersBCMonth;
  setSelectedDateObj: (day: Dayjs) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ selectedDateObj, usersBCMonth, setSelectedDateObj, isPickerDisabled }) => {
  const bcDayjs = dayjs(`${usersBCMonth.year}-${usersBCMonth.month}-1`);
  const [ popoverYear, setPopoverYear ] = useState(bcDayjs.year() || dayjs().year());
  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const monthName = bcDayjs.format(FULL_MONTH_FORMAT);
  const year = bcDayjs.year();

  const changeMonth = (monthIndex: number) => { 
    setPopoverVisible(false);
    setSelectedDateObj(selectedDateObj.year(popoverYear).month(monthIndex));
  };

  const renderPopoverContent = () => (
    <div className={S.popoverInner}>
      <div className={S.header}>
        <Button className={S.changeYearButton} onClick={() => setPopoverYear(popoverYear - 1)}><PrevIcon /></Button>
        <span className={S.popoverYear}>{popoverYear}</span>
        <Button className={S.changeYearButton} onClick={() => setPopoverYear(popoverYear + 1)}><NextIcon /></Button>
      </div>
      <div className={S.list}>
        {_.map(_.range(12), monthNumber => (
          <Button
            key={monthNumber}
            className={classNames(S.changeMonthButton, {
              [S.active]: popoverYear === year && monthNumber === usersBCMonth.month - 1,
            })}
            onClick={() => changeMonth(monthNumber)}
          >
            {dayjs().month(monthNumber).format(FULL_MONTH_FORMAT)}
          </Button>
        ))}
      </div>
    </div>
  );

  if (isPickerDisabled) {
    return (
      <div className={S.monthSelector}>
        <span className={S.label}>{`${monthName} ${year}`}</span>
        <ArrowIcon />
      </div>
    );
  }

  return (
    <Popover
      content={renderPopoverContent()}
      trigger="click"
      placement="bottom"
      visible={popoverVisible}
      overlayClassName={S.popover}
      className={S.monthSelector}
      onVisibleChange={(status: boolean) => setPopoverVisible(status)}
    >
      <span className={S.label}>{`${monthName} ${year}`}</span>
      <ArrowIcon />
    </Popover>
  );
};

export default MonthSelector;
