import { EnglishKnowledgeState } from '../../types/Skills';
import { ConstantType, EnglishLevelEnum } from '../../types/_common';

const ENGLISH_KNOWLEDGE_STATES: Array<EnglishKnowledgeState> = [
  {
    knowledgeId: EnglishLevelEnum.Zero,
    name: 'None',
    secondName: 'Any English level',
    descriptions: '',
    value: 0,
  },
  {
    knowledgeId: EnglishLevelEnum.Googler,
    name: 'Googler',
    secondName: 'Googler',
    descriptions: 'Google - moe vse',
    value: 1,
  },
  {
    knowledgeId: EnglishLevelEnum.WrittenOnly,
    name: 'Written only',
    descriptions: 'I find it hard to speak',
    value: 2,
  },
  {
    knowledgeId: EnglishLevelEnum.Confident,
    name: 'Confident',
    descriptions: 'I understand and I am understood when I am speaking',
    value: 3,
  },
  {
    knowledgeId: EnglishLevelEnum.Level80,
    name: '80 lvl',
    descriptions: 'I am fluent in English, and I even make jokes',
    value: 4,
  },
];

export const ENGLISH_KNOWLEDGE_TITLE: ConstantType<string> = {
  ZERO: 'None',
  GOOGLER: 'Googler',
  WRITTEN_ONLY: 'Written only',
  CONFIDENT: 'Confident',
  LEVEL_80: '80 lvl',
};

export default ENGLISH_KNOWLEDGE_STATES;
