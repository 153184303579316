import './helpers/styles/commonStyles.sass';
import './helpers/styles/variables.sass';
import './helpers/styles/globalUtils.css';
import './helpers/styles/fonts/fonts.css';
import 'normalize.css';
import './App.light.less';
import './App.dark.less';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Spin } from 'antd';
import { Route, Router, Switch } from 'react-router-dom';

import { API, DEFAULT_API_PATH, DEFAULT_HEADERS } from 'api/_request';
import AppAuthWrapper from 'components/AppAuthWrapper/AppAuthWrapper';
import AppMobileRoutesLayout from 'components/AppMobileRoutesLayout/AppMobileRoutesLayout';
import AppThemeWrapper from 'components/AppThemeWrapper/AppThemeWrapper';

import configuredStore from './redux/_reducer/configureStore';
import fontAwesomeLibrary from './helpers/utils/fontAwesomeLibrary';
import ErrorHandler from './components/ErrorHandler';
import { hideGoogleAuthSearch } from './helpers/utils/rootUtils';
import { webFontLoader } from './helpers/styles/fonts/fontLoader';
import SpinnerNew from './components/_shared/LoadingSpinnerNew';
import AppRoutesLayout from './components/AppRoutesLayout/AppRoutesLayout';
import history from './redux/_reducer/configureHistory';
import Login from './components/Login';
import { DEFAULT_PATH, WELCOME_PAGE } from './helpers/constants/_common/MenuCases';
import { COMMON_PAGES } from './helpers/constants/_common/routes';
import { BREAKPOINT_TABLET_WIDE } from './helpers/constants/_common/mobile';
import { initiateLocalStorage } from './helpers/utils/initiateLocalStorage';

if (!('scrollBehavior' in document.documentElement.style)) {
  import('scroll-behavior-polyfill');
}

hideGoogleAuthSearch();
fontAwesomeLibrary();
webFontLoader();
document.title = process.env.REACT_APP_TITLE || '';
const store = configuredStore;
Spin.setDefaultIndicator(<SpinnerNew />);
initiateLocalStorage();

// eslint-disable-next-line import/prefer-default-export
export const api = new API(DEFAULT_API_PATH, DEFAULT_HEADERS);
const { innerWidth: screenWidth } = window;

render(
  <Provider store={store}>
    <ErrorHandler />

    <Router history={history}>
      <AppThemeWrapper>
        <AppAuthWrapper>
          <Switch>
            <Route exact path={DEFAULT_PATH} component={Login} />
            <Route exact path={WELCOME_PAGE} component={COMMON_PAGES.welcome.component} />

            { (screenWidth > BREAKPOINT_TABLET_WIDE) ? <AppRoutesLayout /> : <AppMobileRoutesLayout /> }

          </Switch>
        </AppAuthWrapper>
      </AppThemeWrapper>

    </Router>
  </Provider>,
  document.getElementById('app'),
);
