/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useEffect, useState } from 'react';
import Color from 'color';
import classNames from 'classnames';

import S from './styles.module.sass';
import HexInput from '../hexInput/hexInput';

interface HlsPickerProps {
  value: string;
  onChange: (hex: string) => void;
}

const HslPicker: React.FC<HlsPickerProps> = ({ value, onChange }) => {
  const { h, s, l } = Color(value || 'black').hsl().object();

  const [ hue, setHue ] = useState(h);
  const [ lightness, setLightness ] = useState(l);
  const [ saturation, setSaturation ] = useState(s);

  useEffect(() => {
    onChange(Color({ h: hue, s: saturation, l: lightness }).hex());
  }, [ hue, saturation, lightness ]);

  const renderSlider = (value: number, onChange: (value: number) => void, sliderType: string) => {
    const stylesForL = { background: `linear-gradient(90deg, hsl(${hue}deg ${saturation}% 0%) 0%, hsl(${hue}deg ${saturation}% 100%) 100%)` };
    const stylesForS = { background: `linear-gradient(90deg, hsl(${hue}deg 0% ${lightness}%) 0%, hsl(${hue}deg 100% ${lightness}%) 100%)` };
    const stylesForSorL = sliderType === 'saturation' ? stylesForS : stylesForL;

    return (
      <div className={S.slider}>
        <input
          type="range"
          min="0"
          max={sliderType === 'hue' ? 360 : 100}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(+e.target.value)}
          value={value}
          style={sliderType !== 'hue' ? stylesForSorL : {}}
          className={classNames(S.sliderInput, { [S.hueSelector]: sliderType === 'hue' })}
        />
      </div>
    );
  };

  const renderHexInput = () => (
    <HexInput value={value} setHex={onChange} />
  );

  return (
    <div className={S.hslPicker}>
      {renderSlider(hue, setHue, 'hue')}
      {renderSlider(saturation, setSaturation, 'saturation')}
      {renderSlider(lightness, setLightness, 'lightness')}
      {renderHexInput()}
    </div>
  );
};

export default HslPicker;
