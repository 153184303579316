import { Button, Input, Modal, Steps } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { ReactComponent as CloseIcon } from 'helpers/icons/closeIcon.svg';
import modals from 'helpers/styles/components/modals.module.sass';
import { UserHourReportsSettingsState } from 'components/UserHourReports/types';
import { activityReplaceByDateRange, activityReplaceByMonthAndYear, getBookkeepingPartnersReportsByDateRange, getBookkeepingPartnersReportsByYearAndMonth } from 'api/BookkeepingItems/requests';
import { ActivityCardOutput, FoundPartnerOutput } from 'api/Partners/types';
import { getPartnerActivities } from 'api/Partners/requests';
import { CuratorPartnerDetailedReports } from 'api/Curators/types';
import { ReactComponent as ReplaceToolIcon } from 'helpers/icons/Buttons/flip_camera__24dp.svg';
import PartnerMask from 'components/_shared/PartnerMask';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { DispatchType } from 'helpers/types/_common';
import { setError } from 'redux/ErrorHandler/actions';
import inputs from 'helpers/styles/components/inputs.module.sass';

import { ReplacerData, UserReportChecked } from './types';
import S from './styles.module.sass';
import SelectorPartnerFrom from './SelectorPartnerFrom';
import SelectorActivityFrom from './SelectorActivityFrom';
import SelectorPartnerTo from './SelectorPartnerTo';
import SelectorActivityTo from './SelectorActivityTo';
import UserListToRemove from './UserListToRemove';
import CollapsePair from './CollapsePair';

interface UserHourReportsModalProps {
  isVisible: boolean;
  settings: UserHourReportsSettingsState;
  typeOfTimeRange: 'month' | 'custom';
  refreshCalendar: () => void;
  onClose: () => void;
}

const initiateData = {
  partnerFrom: null,
  activityFrom: null,
  partnerTo: null,
  activityTo: null,
  userReports: [],
};

const UserHourReportsModal: React.FC<UserHourReportsModalProps> = ({ isVisible, settings, onClose, typeOfTimeRange, refreshCalendar }) => {
  const [ isLoading, setLoading ] = useState(true);
  const [ currentStep, setCurrentStep ] = useState(0);
  const [ partnersReports, setPartnersReports ] = useState<CuratorPartnerDetailedReports>({ partners: [], hours: 0 });
  const [ activities, setActivities ] = useState<ActivityCardOutput[]>([]);
  const [ partners, setPartners ] = useState<FoundPartnerOutput[]>([]);
  const [ replaceData, setReplaceData ] = useState<ReplacerData>(initiateData);
  const [ confirmationUsersAmount, setConfirmationUsersAmount ] = useState<number>();

  const affectedUsers = useMemo(() => _.filter(replaceData.userReports, report => report.checked), [ replaceData.userReports ]);
  
  const dispatch: DispatchType = useDispatch();
  const isDisabled = useMemo(() => (
    _.includes([ replaceData.partnerFrom, replaceData.activityFrom, replaceData.partnerTo, replaceData.activityTo ], null)
    || (affectedUsers.length !== confirmationUsersAmount)
    || (affectedUsers.length === 0)
  ), [ replaceData.partnerFrom, replaceData.activityFrom, replaceData.partnerTo, replaceData.activityTo, confirmationUsersAmount, affectedUsers ]);

  const getActivitiesList = useCallback((partnerId: number) => {
    setLoading(true);

    getPartnerActivities(partnerId, true)
      .then(res => setActivities(res.filter(activity => activity.active)))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setReplaceData(initiateData);
      setCurrentStep(0);
      setConfirmationUsersAmount(undefined);
    } else if (typeOfTimeRange === 'month') {
      getPartnersReportsByYearAndMonth();
    } else {
      getPartnersReportsByDateRange();
    }
  }, [ isVisible, typeOfTimeRange ]);

  useEffect(() => {
    if (!_.isNull(replaceData.partnerTo)) {
      getActivitiesList(replaceData.partnerTo.partnerId);
    }
  }, [ replaceData.partnerTo, getActivitiesList ]);

  const getPartnersReportsByYearAndMonth = () => {
    const dayjsObj = dayjs(settings.dates.selectedDate);

    if (_.isNull(settings.dates.selectedDate)) return;

    setLoading(true);

    getBookkeepingPartnersReportsByYearAndMonth(dayjsObj.year(), dayjsObj.month() + 1)
      .then(res => setPartnersReports(res))
      .finally(() => setLoading(false));
  };

  const getPartnersReportsByDateRange = () => {
    if (_.isNil(settings.dates.startDateRange) || _.isNil(settings.dates.endDateRange)) return;

    setLoading(true);

    getBookkeepingPartnersReportsByDateRange(settings.dates.startDateRange, settings.dates.endDateRange)
      .then(res => setPartnersReports(res))
      .finally(() => setLoading(false));
  };

  const okButtonHandle = () => {
    const dayjsObj = dayjs(settings.dates.selectedDate);
    const activityId = replaceData.activityFrom?.activity.activityId;
    const newActivityId = replaceData.activityTo?.activityId;
    const userIds = _.map(_.filter(replaceData.userReports, report => report.checked), report => report.user.userId);
    const { startDateRange, endDateRange } = settings.dates;
    const requiredFields = { activityId, startDateRange, endDateRange, newActivityId };

    if (_.isUndefined(activityId) || _.isUndefined(startDateRange) || _.isUndefined(endDateRange) || _.isUndefined(newActivityId)) {
      const keys = _.keys(requiredFields) as (keyof typeof requiredFields)[];
      const erroredFields = keys.filter(key => _.isUndefined(requiredFields[key])).join(', ');

      dispatch(setError({ error: `Some of requires fields are undefined: ${erroredFields}.` }));
      return;
    }

    if (_.isEmpty(userIds)) {
      dispatch(setError({ error: 'You should select at least one of users to replace activities for.' }));
      return;
    }

    if (activityId === newActivityId) {
      dispatch(setError({ error: 'It makes no sense to replace activity with the same.' }));
      return;
    }

    const request = typeOfTimeRange === 'month'
      ? () => activityReplaceByMonthAndYear(dayjsObj.year(), dayjsObj.month() + 1, activityId, {
        newActivityId,
        userIds: userIds.length !== replaceData.userReports.length ? userIds : undefined,
      })
      : () => activityReplaceByDateRange(activityId, {
        startDate: startDateRange,
        endDate: endDateRange,
        newActivityId,
        userIds: userIds.length !== replaceData.userReports.length ? userIds : undefined,
      });
    
    setLoading(true);
    request()
      .then(() => {
        onClose();
        refreshCalendar();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rollBackToStepFour = () => {
    if (currentStep === 5) setCurrentStep(4);
  };

  const onUserCheckboxChange = (i: number) => {
    const updatedReports = _.reduce(replaceData.userReports, (result, report, index) => {
      if (index === i) {
        return [
          ...result,
          {
            ...report,
            checked: !report.checked,
          },
        ];
      } else {
        return [ ...result, report ];
      }
    }, [] as UserReportChecked[]);

    setReplaceData({
      ...replaceData,
      userReports: updatedReports,
    });
  };

  const stepToggle = (dir: 'next' | 'prev') => {
    switch (dir) {
      case 'next':
        setCurrentStep(currentStep + 1);
        break;
      case 'prev':
        setCurrentStep(currentStep - 1);
        break;
      default:
        break;
    }
  };

  const pickPartnerRender = () => (
    <div className={classNames(S.fieldWrapper, S.partnerField)}>
      <span className={S.fieldLabel}>Partner to replace</span>
      <SelectorPartnerFrom
        isLoading={isLoading}
        replaceData={replaceData}
        setReplaceData={setReplaceData}
        currentStep={currentStep}
        stepToggle={stepToggle}
        partnersReports={partnersReports}
      />
    </div>
  );

  const pickActivityRender = () => (
    <div className={classNames(S.fieldWrapper, S.partnerField)}>
      <span className={S.fieldLabel}>Activity to replace</span>
      <SelectorActivityFrom
        isLoading={isLoading}
        replaceData={replaceData}
        setReplaceData={setReplaceData}
        currentStep={currentStep}
        stepToggle={stepToggle}
      />
    </div>
  );

  const pickNewPartnerRender = () => (
    <div className={classNames(S.fieldWrapper, S.partnerField)}>
      <span className={S.fieldLabel}>Partner to replace with</span>
      <SelectorPartnerTo
        isLoading={isLoading}
        replaceData={replaceData}
        partners={partners}
        setPartners={setPartners}
        setReplaceData={setReplaceData}
        currentStep={currentStep}
        stepToggle={stepToggle}
      />
    </div>
  );

  const pickNewActivityRender = () => (
    <div className={classNames(S.fieldWrapper, S.partnerField)}>
      <span className={S.fieldLabel}>Activity to replace with</span>
      <SelectorActivityTo
        isLoading={isLoading}
        replaceData={replaceData}
        setReplaceData={setReplaceData}
        currentStep={currentStep}
        stepToggle={stepToggle}
        activities={activities}
      />
    </div>
  );

  const removeUserRender = () => (
    <div className={classNames(S.fieldWrapper, S.partnerField)}>
      <UserListToRemove
        replaceData={replaceData}
        onUserCheckboxChange={onUserCheckboxChange}
        onNextStepBtnClick={() => stepToggle('next')}
      />
    </div>
  );

  const confirmationRender = () => (
    <div className={S.confirmStep}>
      <CollapsePair
        defaultSection={null}
        onChange={rollBackToStepFour}
        firstTitle={<span className={classNames(S.title)}>{replaceInfoRender()}</span>}
        firstContent={<div className={S.list}>{pickersRender()}</div>}
        secondTitle={<div className={S.collapseTitle}><span>{`${replaceData.userReports.length} users are selected`}</span></div>}
        secondContent={removeUserRender()}
      />
      <div className={S.usersInfo}>
        <p className={S.red}>
          <span>
            You are going to replace
            {' '}
            <b>
              {`${_.reduce(affectedUsers, (result, report) => result + report.hours, 0)}`}
              {' '}
              hours
            </b>
          </span>
          <span>
            of
            {' '}
            <b>
              {affectedUsers.length}
              {' '}
              users
            </b>
          </span>
        </p>
        <p>Please, enter amount of users to replace to confirm your action.</p>
        <Input
          className={classNames(inputs.qsInput, S.confirmationUsersAmount)}
          size="large"
          type="number"
          value={confirmationUsersAmount}
          onChange={e => setConfirmationUsersAmount(+e.currentTarget.value)}
          placeholder={`Type ${affectedUsers.length} to proceed`}
        />
      </div>
    </div>
  );

  const stepsRender = () => (
    <Steps
      current={currentStep}
      className={S.stepsHeader}
      direction="vertical"
    >
      {_.map(steps, step => (
        <Steps.Step key={step.title} className={S.step} title={step.title} />
      ))}
    </Steps>
  );

  const stepContentRender = () => {
    switch (currentStep) {
      case 0:
      case 1:
      case 2:
      case 3:
        return pickersRender();
      case 4:
        return collapseRender();
      case 5:
        return confirmationRender();
    }
  };

  const pickersRender = () => (
    <>
      {pickPartnerRender()}
      {pickActivityRender()}
      {pickNewPartnerRender()}
      {pickNewActivityRender()}
    </>
  );

  const collapseRender = () => {
    const { partnerFrom, activityFrom, partnerTo, activityTo } = replaceData;
    const isReplaceTitleCanBeRendered = _.filter([ partnerFrom, activityFrom, partnerTo, activityTo ], el => _.isNull(el)).length === 0;
    const replaceTitle = isReplaceTitleCanBeRendered 
      ? replaceInfoRender()
      : <div className={S.collapseTitle}><span>No picker partner or activity</span></div>;
    const usersTitle = `${affectedUsers.length} users are selected`;

    return (
      <CollapsePair
        defaultSection="second"
        firstTitle={<span className={classNames(S.title)}>{replaceTitle}</span>}
        firstContent={<div className={S.list}>{pickersRender()}</div>}
        secondTitle={<div className={S.collapseTitle}><span>{usersTitle}</span></div>}
        secondContent={removeUserRender()}
      />
    );
  };

  const replaceInfoRender = () => {
    const { partnerFrom, activityFrom, partnerTo, activityTo } = replaceData;

    return (
      <div className={classNames(S.collapseTitle, S.fromToTitle)}>
        <span className={S.collapseTitlePart}>
          {
            !_.isNull(partnerFrom) && !_.isNull(activityFrom) && (
              <PartnerMask
                mask={partnerFrom.partner.mask}
                iconColor={activityFrom.activity.color}
                wrapperColor={partnerFrom.partner.color}
                partnerId={partnerFrom.partner.partnerId}
                isVacation={partnerFrom.partner.isVacation}
              />
            )
          }
          <span className={S.partnerNameWrapper}>
            <span className={S.partnerName}>{`${partnerFrom?.partner.name}: ${activityFrom?.activity.name}`}</span>
            <span className={S.hours}>{`${_.reduce(affectedUsers, (result, report) => result + report.hours, 0)}h`}</span>
          </span>
        </span>
        <ReplaceToolIcon className={S.replaceIcon} />
        <span className={S.collapseTitlePart}>
          {
            !_.isNull(partnerTo) && !_.isNull(activityTo) && (
              <PartnerMask
                mask={partnerTo.mask}
                iconColor={activityTo.color}
                wrapperColor={partnerTo.color}
                partnerId={partnerTo.partnerId}
                isVacation={partnerTo.isVacation}
              />
            )
          }
          <span>{`${partnerTo?.name}: ${activityTo?.name}`}</span>
        </span>
      </div>
    );
  };

  const steps = [
    { title: 'Pick Partner' },
    { title: 'Pick Activity' },
    { title: 'Pick New Partner' },
    { title: 'Pick New Activity' },
    { title: 'Select Users' },
    { title: 'Confirmation' },
  ];

  const modalSubTitleRender = () => (typeOfTimeRange === 'month' ? (
    <span className={S.subtitle}>
      {`${_.upperFirst(dayjs(settings.dates.selectedDate).format('MMMM YYYY'))}${!settings.dates.modifiedMonth && ':'}`}
      {settings.dates.modifiedMonth && <span className={S.thin}>Modified:</span>}
      <span className={S.thin}>{dayjs(settings.dates.startDateRange).format(RU_DOT_DATE_FORMAT)}</span>
      <span className={S.thin}>-</span>
      <span className={S.thin}>{dayjs(settings.dates.endDateRange).format(RU_DOT_DATE_FORMAT)}</span>
    </span>
  ) : (
    <span className={S.subtitle}>
      <span>Custom period:</span>
      <span className={S.thin}>{`${dayjs(settings.dates.startDateRange).format(RU_DOT_DATE_FORMAT)} - ${dayjs(settings.dates.endDateRange).format(RU_DOT_DATE_FORMAT)}`}</span>
    </span>
  ));

  return (
    <Modal
      title={(
        <>
          Replace timesheets in period
          {modalSubTitleRender()}
        </>
      )}
      visible={isVisible}
      onCancel={onClose}
      width={600}
      closeIcon={<CloseIcon stroke="#9BA3AC" />}
      className={classNames(S.userHourReportModal, modals.qsBasicAntdModal)}
      footer={[
        <Button
          key="back"
          type="text"
          className={classNames(modals.modalCancelBtn, S.modalButton)}
          style={{ marginRight: '0' }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          title="Confirm"
          loading={isLoading}
          disabled={isDisabled}
          className={classNames(modals.modalOkBtn, S.okButton, S.modalButton)}
          style={{ width: '130px' }}
          onClick={okButtonHandle}
        >
          Done
        </Button> ]}
    >
      <div className={S.stepsWrapper}>
        {stepsRender()}
        <div className={S.stepsContent}>
          {stepContentRender()}
        </div>
      </div>
    </Modal>
  );
};

export default UserHourReportsModal;
