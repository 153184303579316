import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import { UsersBCDay, UsersBCMonth } from 'api/TimeSheet/types';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';
import { MobilePicker, PickerItemValue } from 'components/_shared/MobilePicker/MobilePicker';

import MobileTimeSheetDay from '../MobileTimeSheetDay/MobileTimeSheetDay';
import S from './helpers/styles.module.sass';
import calendarDayStyles from '../MobileTimeSheetDay/helpers/styles.module.sass';

interface CalendarRibbonProps {
  BCMonth: UsersBCMonth;
  isBCLoading: boolean;
  selectedDayObj: UsersBCDay;
  selectedDayObjIndex: number;
  isPickerDisabled: boolean;
  selectDayObj: (selectedDayObj: UsersBCDay, dayIndex: number) => void;
}

const CalendarRibbon: React.FC<CalendarRibbonProps> = ({ isBCLoading, BCMonth, selectedDayObj, selectDayObj, selectedDayObjIndex, isPickerDisabled }) => {
  const markersWrapperRef = useRef<HTMLDivElement>(null);

  const calendarMonthsList = useMemo(() => _.reduce(BCMonth.days, (result, dayObj, index) => {
    const monthName = dayjs(dayObj.day).format('MMM');

    switch (true) {
      case index === 0:
        return { [monthName]: 1 };
      case dayjs(dayObj.day).date() === 1:
        return {
          ...result,
          [monthName]: 1,
        };
      default:
        return {
          ...result,
          [monthName]: result[monthName] + 1,
        };
    }
  }, {} as { [key: string]: number }), [ BCMonth ]);

  const onCalendarRibbonScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollLeft } = e.nativeEvent.target as HTMLDivElement;

    if (_.isNil(scrollLeft) || !markersWrapperRef.current) return;

    markersWrapperRef.current.scrollLeft = scrollLeft;
  };

  const onDaySelect = (index: PickerItemValue) => {
    const validIndex = typeof index === 'string' ? parseInt(index, 10) : index;
    const newSelectedDayObj = BCMonth.days[validIndex];
    
    if (_.isUndefined(newSelectedDayObj)) return;
    
    selectDayObj(newSelectedDayObj, validIndex);
  };

  return (
    <div className={S.monthWrapper}>
      { isBCLoading && (<div className={S.loadingWrapper}><SpinnerNew /></div>)}
      <MobilePicker
        defaultValue={selectedDayObjIndex}
        onSelect={onDaySelect}
        onScroll={onCalendarRibbonScroll}
        disabled={isPickerDisabled || isBCLoading}
        activeItemClassName={calendarDayStyles.today}
        itemWidth={46}
      >
        {_.map(BCMonth.days, (dayObj, index) => (          
          <MobilePicker.Item key={dayObj.day} value={index}>
            <MobileTimeSheetDay
              dayObj={dayObj}
              selectedDayObj={selectedDayObj}
              isFirstDay={index === 0 || dayjs(dayObj.day).date() === 1}
              isBCLoading={isBCLoading}
            />
          </MobilePicker.Item>
        ))}
      </MobilePicker>
      <div ref={markersWrapperRef} className={S.markersWrapper}>
        <div className={S.scrollSpace} style={{ flex: '0 0 calc(100% - 23px)' }} />
        {_.map(calendarMonthsList, (daysAmount, monthName) => (
          <React.Fragment key={monthName}>
            <div
              style={{ flex: '0 0 46px' }}
              className={S.monthNameMarker}
            >
              <span className={S.monthName}>{monthName}</span>
            </div>
            <div
              className={S.markerSpace}
              style={{ flex: `0 0 calc(46px * ${daysAmount - 1})` }}
            />
          </React.Fragment>
        ))}
        <div className={S.scrollSpace} style={{ flex: '0 0 calc(100% - 23px)' }} />
      </div>
    </div>
  );
};

export default CalendarRibbon;
