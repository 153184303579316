import _ from 'lodash';

import { convertObjectKeysToLanguageCase } from 'helpers/utils/convertObject';

import { PersonalInfoFields } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mapFieldsForBackend = (fields: PersonalInfoFields) => {
  const body = {
    phone: fields.phone,
    telegram_username: fields.telegramUsername,
    skype_id: fields.skypeId,
    slack_member_id: fields.slackMemberId,
    description: fields.description,
    forename: fields.forename,
    surname: fields.surname,
    email_address: fields.emailAddress,
    secondary_email_address: fields.secondaryEmailAddress,
    has_specialist_profiles_read_access: fields.hasSpecialistProfilesReadAccess,
    username: fields.username,
    occupation: fields.occupation,
    english_knowledge: fields.englishKnowledge,
    location: fields.location,
    timezone_utc_offset: fields.timezoneUtcOffset,
    primary_skill_id: fields.primarySkill?.skill?.skillId,
    title: fields?.title,
    active: fields?.active,
    usertype: fields?.usertype,
    overtimes_allowance: fields?.overtimesAllowance,
    entity_ids: fields?.entityIds,
    marked_skillset_as_actual: fields?.markedSkillsetAsActual,
    preferences: convertObjectKeysToLanguageCase(fields?.preferences, 'snakeCase'),
  };

  return _.omitBy(body, _.isUndefined);
};
