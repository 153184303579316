import qs from 'query-string';

export const hideGoogleAuthSearch = () => {
  if (window.location.search) {
    const { code } = qs.parse(window.location.search);

    if (code) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }
};
