import { api } from '../../index';
import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import shmoment from '../utils/shmoment/shmoment';
import ApiV2 from '../../api/_request/index';

const userResourceUrl = '/user';
const usersResourceUrl = '/users';
let LATEST_USERS_DATA = {};

let LoggedUserId = 0;

const globalLogout = userId => api.delete(`${usersResourceUrl}/${userId}/sessions/`);

const getUnreadCounts = (userId) => {
  if (userId) LoggedUserId = userId;
  return api.get(`${usersResourceUrl}/${LoggedUserId}/unreads/`);
};

const checkHash = () => ApiV2.request(`${userResourceUrl}/check_hash/`, {})
  .then((response) => {
    response.data = {
      ...response.data,
      email: response.data.emailAddress,
      occupationId: response.data.occupationId,
      id: response.data.userId,
    };
    return response;
  });

const getUnreadNotificationsCount = () => new Promise((resolve) => {
  setTimeout(() => {
    const amount = Math.ceil(Math.random() * 100);

    resolve({
      status: true,
      data: {
        count: amount,
        notifications: Array(amount).fill(0).map((e, i) => ({
          notificationId: i,
          date: shmoment().format('DD/MM/YYYY'),
          message: 'Some test notification message.',
          read: false,
        })),
      },
    });
  }, 300);
});

const markNotificationAsRead = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve({ status: true });
  }, 300);
});

const makeUsersOffer = (userId, hotProjectId, helpRequestId) => api.post(`/users/${userId}/help_offers/`, { hot_project_id: hotProjectId, help_request_id: helpRequestId });

const toggleHotProjectReadMark = hotProjectId => api.put(`/users/${LoggedUserId}/views/multipliers/${hotProjectId}/`);

const setFavourite = (activityId, userId) => ApiV2.request(`${userResourceUrl}/set_favourite/`, {
  user_id: userId,
  task_id: activityId,
});

const cleanFavourite = userId => ApiV2.request(`${userResourceUrl}/clean_favourite/`, { user_id: userId });

const getFavourite = userId => ApiV2.request(`${userResourceUrl}/get_favourite/`, { user_id: userId })
  .then(response => ({
    partnerId: response.data.projectId,
    activityId: response.data.taskId,
  }));

const add = userData => api.post(`${usersResourceUrl}/`, convertObjectKeysToLanguageCase(userData, 'snakeCase'));

const edit = (userData, userId) => api.patch(`${usersResourceUrl}/${userId}/`, convertObjectKeysToLanguageCase(userData, 'snakeCase'));

const editUserInfo = userData => ApiV2.request(`${userResourceUrl}/edit_userinfo/`, {
  user_id: userData.userId,
  occupation_id: userData.occupationId,
});

const changeStatus = userData => ApiV2.request(`${userResourceUrl}/change_user_status/`, {
  user_id: userData.id,
  user_status: userData.status,
});

const changeStatusV3 = userData => ApiV2.api.patch(`${usersResourceUrl}/${userData.id}/`, {
  active: userData.status,
});

const getAll = data => ApiV2.request(`${userResourceUrl}/get_all_users/`, data)
  .then(response => (
    {
      ...response,
      data:
          response.data.map(user => ({
            administrator: user.usertype !== 2,
            status: user.active,
            email: user.emailAddress,
            userId: user.userId,
            entityId: user.entityId,
            occupationName: user.occupationName,
            occupationId: user.occupationId,
            entityName: user.entityName,
            usersAdminNotes: user.specialTags,
            ...user,
          })),
    }
  ));

const getAllV3 = (data) => {
  if (data) {
    LATEST_USERS_DATA = data;
  }

  return api.get(`${usersResourceUrl}/`, {
    ...convertObjectKeysToLanguageCase(data || LATEST_USERS_DATA, 'snakeCase'),
  })
    .then(res => (
      {
        meta: {
          next: res.meta.next,
          previous: res.meta.previous,
          limit: res.meta.limit,
          offset: res.meta.offset,
          totalCount: res.meta.totalCount,
        },
        objects: res.objects.map(user => ({
          administrator: user.usertypeId === 1,
          description: user.description || '',
          status: user.active,
          emailAddress: user.emailAddress,
          userId: user.userId,
          entityId: user.entityId,
          occupationName: user.occupationName,
          occupationId: user.occupationId,
          entity: user.entity,
          englishKnowledge: user.englishKnowledge,
          usersAdminNotes: user.specialTags,
          ...user,
        })),
      }
    ));
};

const getAllUsersForSelector = data => ApiV2.request(`${userResourceUrl}/get_all_users/`, data)
  .then(response => ({
    meta: {
      totalCount: response.data.length,
    },
    objects:
      response.data.filter(user => user.active).sort((a, b) => {
        if (a.forename[0] > b.forename[0]) return 1;
        if ((a.forename[0] < b.forename[0])) return -1;
        return 0;
      }).map(user => ({
        userId: user.userId,
        entityId: user.entityId,
        occupationName: user.occupationName,
        occupationId: user.occupationId,
        entityName: user.entityName,
        usersAdminNotes: user.specialTags,
        active: user.activity,
        description: user.description,
        emailAddress: user.emailAddress,
        forename: user.forename,
        role: user.role,
        specialTags: user.specialTags,
        surname: user.surname,
        username: user.username,
        usertype: user.usertype,
        usertypeId: user.usertype,
      })),
  }));

const getUserById = userId => api.get(`${usersResourceUrl}/${userId}/`).then(res => ({
  // there is a little hell with mapping because of a lot of outdated code
  active: res.active,
  administrator: res.usertypeId === 1,
  avatar_url: res.avatarUrl,
  description: res.description,
  emailAddress: res.emailAddress,
  englishKnowledge: res.englishKnowledge,
  entity: res.entity,
  entityId: res.entityId,
  forename: res.forename,
  surname: res.surname,
  username: res.username,
  occupation: res.occupation,
  phone: res.phone,
  skypeId: res.skypeId,
  slackMemberId: res.slackMemberId,
  location: res.location,
  timezoneUtcOffset: res.timezoneUtcOffset,
  primarySkill: res.primarySkill,
  title: res.title,
  telegramUsername: res.telegramUsername,
  status: res.status,
  userId: res.userId,
  usersAdminNotes: res.specialTags,
}));

const getUserSkills = userId => ApiV2.request(`${userResourceUrl}/get_user_skills/`, {
  user_id: userId,
}).then(res => ({
  ...res,
  data: res.data.map(skill => ({
    ...skill,
    attitudes: skill.attitudes || [ null ],
    categoryId: skill.category,
    experienceRateId: skill.experience,
    fullName: skill.fullName,
    shortName: skill.shortName,
    skillId: skill.skillId,
    userId: skill.userId,
    userSkillLinkId: skill.userSkillLinkId,
  })),
}));
const updateUserSkills = data => ApiV2.request(`${userResourceUrl}/update_user_skills/`, {
  deleted: data.deleted,
  experience: data.experience,
  category: data.category,
  new: data.newSkills.map(skill => ({ skill_id: skill.skillId, attitudes: skill.attitudes })),
  updated: data.updatedSkills.map(skill => ({ skill_id: skill.skillId, attitudes: skill.attitudes })),
  user_id: data.userId,
});

export default {
  add,
  getUserById,
  makeUsersOffer,
  toggleHotProjectReadMark,
  getUserSkills,
  updateUserSkills,
  changeStatus,
  changeStatusV3,
  checkHash,
  cleanFavourite,
  edit,
  editUserInfo,
  getAll,
  getAllV3,
  getFavourite,
  globalLogout,
  setFavourite,
  getUnreadNotificationsCount,
  markNotificationAsRead,
  getAllUsersForSelector,
  getUnreadCounts,
};
