import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';

import { Redux } from 'helpers/types/_common';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { TimeSheetTimelineProps } from './helpers/types';
import S from './helpers/styles.module.sass';

const cx = classNames.bind(S);

const DAY_HOURS = 24;
const HOUR_MINUTES = 60;
const HEIGHT_PERCENTS = 100;
const UPDATE_TIMEOUT = 5000;

const TimeSheetTimeline: React.FC<TimeSheetTimelineProps> = ({ isLoading, BCMonth }) => {
  const [ top, changeTop ] = useState(0);
  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);

  const sortedByDate = BCMonth.jointDays.map(dayObj => dayObj.day);
  const todayIndex = useMemo(() => _.indexOf(sortedByDate, dayjs().format(DEFAULT_DATE_FORMAT)), [ sortedByDate ]);

  const leftRedLineWidth = (100 / sortedByDate.length) * todayIndex;
  const middleWidth = 100 / sortedByDate.length;
  const rightRedLineWidth = (100 / sortedByDate.length) * (sortedByDate.length - todayIndex - 1);
  const notCurrentMonth = todayIndex < 0;

  const calcPosition = () => {
    const timeToNow = dayjs().unix();
    const timeToStartOfTheDay = dayjs().startOf('day').unix();
    const elapsedMinutes = Math.floor((timeToNow - timeToStartOfTheDay) / HOUR_MINUTES);

    return (HEIGHT_PERCENTS / (DAY_HOURS * HOUR_MINUTES)) * elapsedMinutes;
  };

  useEffect(() => {
    updateTop(calcPosition());

    const timer = setInterval(() => {
      updateTop(calcPosition());
    }, UPDATE_TIMEOUT);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const updateTop = (x: number) => {
    changeTop(x);
  };

  if ((!isLoading && isMonthClosed) || notCurrentMonth) {
    return null;
  }

  return (
    <div className={S.timelineWrapper}>
      <div
        className={cx(S.timeline, { [S.isLoading]: isLoading })}
        style={{ top: `${top}%` }}
      >
        <div className={S.timelineInner}>
          <div className={cx(S.redLine, S.left)} style={{ flexBasis: `${leftRedLineWidth}%` }} />
          <div className={S.middle} style={{ flexBasis: `${middleWidth}%` }} />
          <div className={cx(S.redLine, S.right)} style={{ flexBasis: `${rightRedLineWidth}%` }} />
        </div>
      </div>
    </div>
  );
};

export default TimeSheetTimeline;
