import SkillTagsRequests from '../../api/SkillTags/requests';
import { getIdsFromSelectedRows } from './helpers';
import { MergeSkillsToTargetActionValues, MergeSkillsWithNewActionValues } from './types';
import { CreateSkillTagBody, EditSkillTagBody, SkillTagProps } from '../../api/SkillTags/types';
import { SkillTagsBody } from '../../helpers/types/Table';
import { DispatchType } from '../../helpers/types/_common';
import * as TablesActions from '../../helpers/constants/_common/actions/Tables';
import Subtypes from '../../V1/constants/__common__/Subtypes';

export const getSkillTags = (params: SkillTagsBody, tableName: string) => (dispatch: DispatchType) => {
  dispatch({ type: TablesActions.GET_TABLE_DATA, subType: Subtypes.Request.Loading, tableName });

  return SkillTagsRequests.getSkillTags(params)
    .then((response) => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Success,
        payload: response,
        tableName,
      });
    })
    .catch(() => {
      dispatch({
        type: TablesActions.GET_TABLE_DATA,
        subType: Subtypes.Request.Error,
        tableName,
      });
    });
};

export const deleteSkillTag = (skillId: number) => () => {
  if (!skillId) return;

  return SkillTagsRequests.deleteSkillTag(skillId);
};

export const mergeSkillsToTarget = (values: MergeSkillsToTargetActionValues) => () => {
  const body = {
    skill_ids: getIdsFromSelectedRows(values.selectedRows),
  };
  return SkillTagsRequests.mergeSkillsToTarget(body, values.skillId);
};

export const mergeSkillsWithNew = (values: MergeSkillsWithNewActionValues) => () => {
  const body = {
    name: values.name,
    category: values.category,
    skill_ids: getIdsFromSelectedRows(values.selectedRows),
  };
  return SkillTagsRequests.mergeSkillsWithNew(body);
};

export const updateSkillTag = (skillTag: SkillTagProps) => () => {
  // approved is an opposite to itself
  const body = {
    approved: !skillTag.approved,
  };

  return SkillTagsRequests.updateSkillTag(body, skillTag.skillId);
};

export const createSkillTag = (body: CreateSkillTagBody) => () => SkillTagsRequests.createSkillTag(body);
export const editSkillTag = (body: EditSkillTagBody, skillId: number) => SkillTagsRequests.editSkillTag(body, skillId);
export const massDeleteSkillTag = (skillTagIds: number[]) => () => SkillTagsRequests.massDeleteSkillTag(skillTagIds);
export const massApproveSkillTag = (skillTagIds: number[], approved: boolean) => () => SkillTagsRequests.massApproveSkillTag(skillTagIds, approved);
export const getSkillTagStatistic = (skillId: number) => SkillTagsRequests.getSkillTagStatistic(skillId);
export const getSkillTagSynonyms = (skillId: number) => SkillTagsRequests.getSkillTagSynonyms(skillId);
export const getSkillTagUsers = (skillId: number) => SkillTagsRequests.getSkillTagUsers(skillId);
export const deleteSynonymTag = (synonymId: number, skillId: number) => () => SkillTagsRequests.deleteSynonymTag(synonymId, skillId);
export const addSynonymToTag = (name: string, skillId: number) => () => SkillTagsRequests.addSynonymToTag(name, skillId);
