import React from 'react';
import { Button, Col, Input, Popover, Row, Space } from 'antd';
import cx from 'classnames/bind';
import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';

import inputs from 'helpers/styles/components/inputs.module.sass';
import FieldCard from 'components/_shared/FieldCard/FieldCard';
import { PartnerDetailsOutput } from 'api/Partners/types';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import { ValidationErrorResponse } from 'helpers/types/_common';
import { ErrorHelpers } from 'helpers/hooks/useErrorForm/useErrorForm';
import { PARTNER_NAME_MIN_LENGTH } from 'helpers/constants/_common/Partners';

import s from './styles.module.sass';
import partners from '../../styles.module.sass';

interface PartnerInfoCardProps {
  partnerFields: PartnerDetailsOutput;
  isEdit: boolean;
  errorHelpers: ErrorHelpers<ValidationErrorResponse>;
  changeField: (key: keyof PartnerDetailsOutput, value: any, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PartnerInfoCard: React.FC<PartnerInfoCardProps> = ({ partnerFields, changeField, isEdit, errorHelpers }) => {
  const renderActivePopoverContent = () => (
    <div className={s.popoverInner}>
      <Button
        className={classNames(s.activeStatusButton, { [s.active]: partnerFields.active })}
        onClick={() => changeField('active', !partnerFields.active)}
      >
        <div className={s.activeStatusMark} />
        {!partnerFields.active ? 'Active' : 'Inactive'}
      </Button>
      {partnerFields.active && (
        <p className={s.activeStatusDescriotion}>
          <b>Warning:</b>
        &nbsp;Making the Partner inactive will disable all activities of this partner
        </p>
      )}
    </div>
  );

  const renderActiveStatusToggler = () => (isEdit 
    ? (
      <Popover
        content={renderActivePopoverContent()}
        trigger="click"
        placement="bottomLeft"
        overlayClassName={s.popover}
      >
        <div className={s.activeStatusPopoverTrigger}>
          <div className={classNames(s.activeStatusMark, { [s.active]: partnerFields.active })} />
          <span className={s.activeStatusText}>{partnerFields.active ? 'Active' : 'Inactive'}</span>
          <ArrowIcon />
        </div>
      </Popover>
    ) : (
      <div className={s.activeStatus}>
        <div className={classNames(s.activeStatusMark, { [s.active]: partnerFields.active })} />
        <span className={s.activeStatusText}>{partnerFields.active ? 'Active' : 'Inactive'}</span>
      </div>
    ));

  return (
    <FieldCard className={partners.primaryCard}>
      <Row>
        <Col span={24}>
          <Space className={partners.fieldWrapper} direction="vertical">
            <span className={s.fieldTitle}>
              Partner name
              {renderActiveStatusToggler()}
            </span>
            {errorHelpers.renderError('name')}
            <Input
              className={cx(inputs.qsInput, partners.partnerInput, { [inputs.error]: errorHelpers.isErrorField('name') })}
              value={partnerFields.name}
              required
              min={PARTNER_NAME_MIN_LENGTH}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeField('name', e.target.value, e)}
              disabled={!isEdit}
            />
          </Space>
        </Col>
      </Row>
  
      <Row>
        <Col span={24}>
          <Space className={partners.fieldWrapper} direction="vertical">
            <span className={partners.fieldTitle}>Description</span>
            {errorHelpers.renderError('description')}
            <TextArea
              rows={2}
              className={cx(inputs.trackerTextArea, s.partnerTextArea, { [inputs.error]: errorHelpers.isErrorField('description') })}
              value={partnerFields.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => changeField('description', e.target.value, e)}
              allowClear
              disabled={!isEdit}
            />
          </Space>
        </Col>
      </Row>
    </FieldCard>
  );
};

export default PartnerInfoCard;
