export interface PartnerCreatedSince {
  [key: string]: PartnerCreatedSinceItem
}

export interface PartnerCreatedSinceItem {
  text: string;
  badgeColor: string;
  ghosted?: boolean;
  value: string;
}

export const PARTNERS_CREATED_SINCE_RANGES = {
  lessThan30: '0to29',
  lessThan60: '30to59',
  lessThan90: '60to89',
  lessThan120: '90to119',
  moreThan120: '120to',
};

export const PARTNERS_CREATED_SINCE: PartnerCreatedSince = {
  any: {
    text: 'Created anytime',
    badgeColor: '#72798180',
    ghosted: true,
    value: '',
  },
  [PARTNERS_CREATED_SINCE_RANGES.lessThan30]: {
    text: '0–29 days',
    badgeColor: '#60BF7A',
    ghosted: true,
    value: PARTNERS_CREATED_SINCE_RANGES.lessThan30,
  },
  [PARTNERS_CREATED_SINCE_RANGES.lessThan60]: {
    text: '30–59 days',
    badgeColor: '#60BF7A',
    value: PARTNERS_CREATED_SINCE_RANGES.lessThan60,
  },
  [PARTNERS_CREATED_SINCE_RANGES.lessThan90]: {
    text: '60–89 days',
    badgeColor: '#EEAC57',
    value: PARTNERS_CREATED_SINCE_RANGES.lessThan90,
  },
  [PARTNERS_CREATED_SINCE_RANGES.lessThan120]: {
    text: '90–119 days',
    badgeColor: '#F4536E',
    value: PARTNERS_CREATED_SINCE_RANGES.lessThan120,
  },
  [PARTNERS_CREATED_SINCE_RANGES.moreThan120]: {
    text: '120+ days',
    badgeColor: '#611B27',
    value: PARTNERS_CREATED_SINCE_RANGES.moreThan120,
  },
};
