export const CHANGE_STARTDATE = '[HOUR REPORT] CHANGE_STARTDATE';
export const CHANGE_ENDDATE = '[HOUR REPORT] CHANGE_ENDDATE';
export const CHANGE_YEAR = '[HOUR REPORT] CHANGE_YEAR';
export const CHANGE_PERIOD = '[HOUR REPORT] CHANGE_PERIOD';
export const CHANGE_HOURLYRATE = '[HOUR REPORT] CHANGE_HOURLYRATE';
export const REMOVE_OBJECTS = '[HOUR REPORT] REMOVE_OBJECTS';

export const RECEIVE_HOURREPORT = '[HOUR REPORT] RECEIVE_HOURREPORT';
export const RECEIVE_HOURREPORT_REQUEST = '[HOUR REPORT] RECEIVE_HOURREPORT_REQUEST';
export const RECEIVE_HOURREPORT_DATA = '[HOUR REPORT] RECEIVE_HOURREPORT_DATA';
export const RECEIVE_HOURREPORT_ERROR = '[HOUR REPORT] RECEIVE_HOURREPORT_ERROR';
export const HOUR_REPORT_INITIATE = '[HOUR REPORT] HOUR_REPORT_INITIATE';
