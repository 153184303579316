export const SKILLS_INITIATE = '[SKILLS] SKILLS_INITIATE';
export const SKILLS_CLEAR = '[SKILLS] SKILLS_CLEAR';

//getters

export const GET_SKILLS = '[SKILLS, PROFILE] GET_SKILLS';
export const GET_ADMIN_NOTES = '[SKILLS, USERS, CURATORS] GET_ADMIN_NOTES';
export const GET_USERS_BY_SKILL_ID = '[SKILLS] GET_USERS_BY_SKILL_ID';

//modals

export const CREATE_NEW_SKILL = '[SKILLS, PROFILE] CREATE_NEW_SKILL';
export const EDIT_SKILL = '[SKILLS] EDIT_SKILL';
export const DELETE_SKILL = '[SKILLS] DELETE_SKILL';
export const REMOVE_DELETED_SKILL = '[SKILLS] REMOVE_DELETED_SKILL';

export const CREATE_NEW_ADMIN_NOTE = '[SKILLS] CREATE_NEW_ADMIN_NOTE';
export const EDIT_ADMIN_NOTE = '[SKILLS] EDIT_ADMIN_NOTE';
export const DELETE_ADMIN_NOTE = '[SKILLS] DELETE_ADMIN_NOTE';
export const REMOVE_DELETED_ADMIN_NOTE = '[SKILLS] REMOVE_DELETED_ADMIN_NOTE';

export const REQUEST_MERGE_TOOL = '[SKILLS] REQUEST_MERGE_TOOL';
export const COMPLETE_MERGE_TOOL = '[SKILLS] COMPLETE_MERGE_TOOL';
export const CHANGE_MERGE_TOOL_ERROR_MESSAGE = '[SKILLS] CHANGE_MERGE_TOOL_ERROR_MESSAGE';

//updaters

export const UPDATE_SKILLS_WITH_NEW_ONE = '[SKILLS] UPDATE_SKILLS_WITH_NEW_ONE';
export const UPDATE_ADMIN_NOTES_WITH_NEW_ONE = '[SKILLS] UPDATE_ADMIN_NOTES_WITH_NEW_ONE';
export const UPDATE_EXISTED_SKILL = '[SKILLS] UPDATE_EXISTED_SKILL';
export const UPDATE_EXISTED_ADMIN_NOTE = '[SKILLS] UPDATE_EXISTED_ADMIN_NOTE';
export const UPDATE_USERS_ADMIN_NOTES_WITH_NEW_ONE = '[USERS] UPDATE_USERS_ADMIN_NOTES_WITH_NEW_ONE';

//modals toggle

export const SKILL_SET_ADD_EDIT_MODAL_TOGGLE = '[SKILLS] SKILL_SET_ADD_EDIT_MODAL_TOGGLE';
export const SKILL_MODAL_TOGGLE = '[SKILLS] SKILL_MODAL_TOGGLE';
export const ADMIN_NOTE_MODAL_TOGGLE = '[SKILLS] ADMIN_NOTE_MODAL_TOGGLE';
export const MERGE_TOOL_TOGGLE = '[SKILLS] MERGE_TOOL_TOGGLE';

//other

export const TOGGLE_UNAPPROVED_SKILLS_VISIBILITY = '[SKILLS] TOGGLE_UNAPPROVED_SKILLS_VISIBILITY';
export const TOGGLE_CREATE_NEW_SKILL_TIP_VISIBILITY = '[SKILLS] TOGGLE_CREATE_NEW_SKILL_TIP_VISIBILITY';

export const SHOW_ADMIN_NOTES_SECTION = '[SKILLS] SHOW_ADMIN_NOTES_SECTION';

export const CHANGE_CATEGORY_ID_TO_FILTER = '[SKILLS] CHANGE_CATEGORY_ID_TO_FILTER';
export const CHANGE_SKILL_NAME_TO_FILTER = '[SKILLS] CHANGE_SKILL_NAME_TO_FILTER';
export const CHANGE_ADMIN_NOTE_TO_FILTER = '[SKILLS] CHANGE_ADMIN_NOTE_TO_FILTER';
export const CHANGE_SKILL_APPROVING_STATUS = '[SKILLS] CHANGE_SKILL_APPROVING_STATUS';

export const CHANGE_SELECTED_SKILL = '[SKILLS] CHANGE_SELECTED_SKILL';
export const CHANGE_SELECTED_ADMIN_NOTE = '[SKILLS] CHANGE_SELECTED_ADMIN_NOTE';

