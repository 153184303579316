import Like from '../../icons/Attitudes/like.png';
import Dislike from '../../icons/Attitudes/dislike.png';
import Ok from '../../icons/Attitudes/ok.png';
import Super from '../../icons/Attitudes/super.png';
import Hate from '../../icons/Attitudes/hate.png';
import { AttitudesEnum, SkillAttitudes } from '../../types/Skills';

const SKILL_ATTITUDES: SkillAttitudes = {
  SUPER: { attitudeId: 1, title: 'Super', key: AttitudesEnum.Super, icon: Super },
  LIKE: { attitudeId: 2, title: 'Like', key: AttitudesEnum.Like, icon: Like },
  OK: { attitudeId: 3, title: 'Ok', key: AttitudesEnum.Ok, icon: Ok },
  DISLIKE: { attitudeId: 4, title: 'Dislike', key: AttitudesEnum.Dislike, icon: Dislike, style: { position: 'relative', top: 2 } },
  HATE: { attitudeId: 5, title: 'Hate', key: AttitudesEnum.Hate, icon: Hate },
};

export default SKILL_ATTITUDES;
