import dayjs from 'dayjs';

import { getDefaultMonth } from './helpers';
import { DayType } from './types';

export default {
  prevMonthDays: getDefaultMonth(dayjs().subtract(1, 'month'), DayType.Cut).days.slice(dayjs().subtract(1, 'month').daysInMonth() - 5),
  currentMonthPeriod: getDefaultMonth(dayjs(), DayType.Original),
  nextMonthDays: getDefaultMonth(dayjs().add(1, 'month'), DayType.Cut).days.slice(0, 5),
};
