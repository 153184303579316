import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

import BusinessMonthSelector from 'components/_shared/BusinessMonthSelector/BusinessMonthSelector';
import { DispatchType, Redux } from 'helpers/types/_common';
import { changeSelectedDate } from 'redux/TimeSheet/action';
import { UserDetails } from 'api/User/types';
import { UserRedux } from 'redux/User/types';
import { BCMonth } from 'api/TimeSheet/types';
import { MONTH_YEAR_FORMAT } from 'helpers/constants/_common/timeFormats';

import { UserHoursTable } from '../../helpers/types';
import { FlatUserHours } from '../Table/components/TableBody/helpers/types';
import S from './styles.module.sass';
import MonthInfo from './components/MonthInfo/MonthInfo';

export interface TimeSheetSettingsProps {
  user: UserDetails | UserRedux;
  isBCLoading: boolean;
  userHoursTable: UserHoursTable;
  BCMonth: BCMonth;
  flatUserHoursTable: FlatUserHours;
  curatorOnlyView?: boolean;
}

const TimeSheetSettings: React.FC<TimeSheetSettingsProps> = (props) => {
  const {
    isBCLoading, BCMonth, userHoursTable,
    user, flatUserHoursTable, curatorOnlyView,
  } = props;

  const dispatch: DispatchType = useDispatch();

  const { selectedDateObj } = useSelector((state: Redux) => state.timeSheet.tableSettings);
  const { selectedActivity } = useSelector((state: Redux) => state.timeSheet);

  const isModified = BCMonth.modified;

  useEffect(() => () => dispatch(changeSelectedDate(dayjs())), []);

  const handleOnChangeMonth = (dayjsObj: Dayjs) => dispatch(changeSelectedDate(dayjsObj));

  return (
    <div className={cx(S.settings, { [S.settingsDisabled]: Boolean(selectedActivity) })}>
      <div className={S.selectorWrapper}>
        <BusinessMonthSelector
          onChangeMonth={handleOnChangeMonth}
          selectedDate={selectedDateObj.format(MONTH_YEAR_FORMAT)}
          isModified={isModified}
          isLoading={isBCLoading}
        />
      </div>
      <div className={S.monthInfoWrapper}>
        <MonthInfo
          user={user}
          BCMonth={BCMonth}
          userHoursTable={userHoursTable}
          flatUserHoursTable={flatUserHoursTable}
          isBCLoading={isBCLoading}
          curatorOnlyView={curatorOnlyView}
        />
      </div>
    </div>
  );
};

export default TimeSheetSettings;
