import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import useGetUsersBusinessCalendar from 'helpers/hooks/useGetUsersBusinessCalendar';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { DispatchType, Redux } from 'helpers/types/_common';
import { BCJointStatus, BCStatuses, UsersBCDay, UserMonthStatusEnum } from 'api/TimeSheet/types';
import { getMonthStatus } from 'redux/TimeSheet/action';
import * as TimesheetActions from 'redux/TimeSheet/constants';

import S from './helpers/styles.module.sass';
import MobilePanel from './components/MobilePanel/MobilePanel';
import ProfileSection from './components/ProfileSection/ProfileSection';
import CalendarRibbon from './components/CalendarRibbon/CalendarRibbon';

const MobileTimesheet: React.FC<{}> = () => {
  const user = useSelector((state: Redux) => state.login);
  const [ selectedDateObj, setSelectedDateObj ] = useState<Dayjs>(dayjs()); // its for month/year switching
  const [ isPickerDisabled, setPickerDisabled ] = useState(false);
  const dispatch: DispatchType = useDispatch();
  
  const defaultSelectedDayObj = {
    day: selectedDateObj.format(DEFAULT_DATE_FORMAT),
    hours: 0,
    status: [ 0, 6 ].includes(selectedDateObj.day()) ? BCStatuses.Holiday : BCStatuses.Regular,
    jointStatus: BCJointStatus.Basic,
    businessDayId: null,
  };
  const [ selectedDayObj, setSelectedDayObj ] = useState<UsersBCDay>(defaultSelectedDayObj); // its for selected day displaying and managing
  const [ selectedDayObjIndex, setSelectedDayObjIndex ] = useState(selectedDateObj.date() - 1);
  const [ BCMonth, isBCLoading, setBCMonth, refreshCalendar ] = useGetUsersBusinessCalendar({
    selectedDate: selectedDateObj.format(DEFAULT_DATE_FORMAT),
    user,
  });

  useEffect(() => {
    if (selectedDateObj.year() !== BCMonth.year || selectedDateObj.month() + 1 !== BCMonth.month) {
      refreshCalendar();
    }
  }, [ selectedDateObj ]);

  useEffect(() => {
    dispatch({
      type: TimesheetActions.SET_LOCK_MONTH_STATUS,
      isMonthClosed: BCMonth.userMonthStatus !== UserMonthStatusEnum.Opened,
    });
  }, [ BCMonth.userMonthStatus ]);

  useEffect(() => {
    if (BCMonth.userMonthStatus === UserMonthStatusEnum.NotOpenedYet) {
      dispatch(getMonthStatus({
        userId: user.userId,
        month: BCMonth.month,
        year: BCMonth.year,
      }));
    }
  }, [ BCMonth.year, BCMonth.month ]);

  useEffect(() => {
    const dateDayjs = selectedDateObj.date(dayjs(selectedDayObj.day).date());
    const newDayIndex = _.findIndex(BCMonth.days, [ 'day', dateDayjs.format(DEFAULT_DATE_FORMAT) ]);

    if (newDayIndex < 0) {
      const todayIndex = _.findIndex(BCMonth.days, [ 'day', dateDayjs.date(dayjs().date()).format(DEFAULT_DATE_FORMAT) ]);

      if (todayIndex < 0) {
        setSelectedDayObjIndex(BCMonth.days.length - 1);
        setSelectedDayObj(BCMonth.days[BCMonth.days.length - 1]);
      } else {
        setSelectedDayObjIndex(todayIndex);
        setSelectedDayObj(BCMonth.days[todayIndex]);
      }
    } else {
      setSelectedDayObjIndex(newDayIndex);
      setSelectedDayObj(BCMonth.days[newDayIndex]);
    }
  }, [ BCMonth ]);

  const selectDayObj = (dayObj: UsersBCDay, dayIndex: number) => {
    setSelectedDayObj(dayObj);
    setSelectedDayObjIndex(dayIndex);
  };

  const addWorkedHoursToDate = (date: string, hours: number) => {
    const updatedBCMonthDays = _.reduce(BCMonth.days, (r, dayObj) => {
      if (dayObj.day !== date) {
        return [ ...r, dayObj ];
      } else {
        return [ ...r, {
          ...dayObj,
          hours: dayObj.hours + hours,
        } ];
      }
    }, [] as UsersBCDay[]);

    setBCMonth({
      ...BCMonth,
      days: updatedBCMonthDays,
    });
  };

  return (
    <div className={S.mobileTimeSheet}>
      <ProfileSection
        usersBCMonth={BCMonth}
        isBCLoading={isBCLoading}
        isPickerDisabled={isPickerDisabled}
        selectedDateObj={selectedDateObj}
        setSelectedDateObj={setSelectedDateObj}
      />
      <CalendarRibbon
        selectDayObj={selectDayObj}
        selectedDayObj={selectedDayObj}
        selectedDayObjIndex={selectedDayObjIndex}
        isPickerDisabled={isPickerDisabled}
        isBCLoading={isBCLoading}
        BCMonth={BCMonth}
      />
      <MobilePanel
        userId={user.userId}
        BCMonth={BCMonth}
        isBCLoading={isBCLoading}
        selectedDayObj={selectedDayObj}
        selectedDateObj={selectedDateObj}
        setPickerDisabled={setPickerDisabled}
        addWorkedHoursToDate={addWorkedHoursToDate}
        refreshCalendar={refreshCalendar}
      />
    </div>
  );
};

export default MobileTimesheet;
