import _ from 'lodash';

import { BadRequestError, ValidationError } from '../../../../helpers/types/_common';

export const inputErrorText = (fieldName: string | string[], error?: ValidationError | BadRequestError) => {
  if (!error) {
    return [];
  }

  // 400 error { BadRequestError interface }
  if (!error.error) {
    return _.reduce(error, (acc, value, key) => {
      if (_.includes(fieldName, key)) {
        const errorText = _.isArray(value) ? _.join(value, ', ') : value;

        return [ ...acc, errorText ];
      }

      return acc;
    }, [] as (string | null)[]);
  }

  // 409 error { ValidationError interface }
  return _.includes(fieldName, error?.field) ? [ error.error ] : [];
};
