import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSkull,
  faBolt,
  faPencilRuler,
  faSadCry,
  faUserGraduate,
  faHandLizard,
  faHandPeace,
  faChevronRight,
  faChevronLeft,
  faEdit,
  faTimes,
  faExclamationTriangle,
  faPaperPlane,
  faBriefcase,
  faUser,
  faQuestionCircle,
  faCheck,
  faCopy,
  faPlus,
  faLock,
  faCaretDown,
  faChevronDown,
  faChevronUp,
  faCheckCircle,
  faMinusCircle,
  faPowerOff,
  faCalendar,
  faTimesCircle,
  faLockOpen,
  faSave,
  faTrash,
  faSortDown,
  faDotCircle,
  faSortUp,
  faUndo,
  faEnvelopeOpenText,
  faCaretUp,
  faSpinner,
  faStar,
  faArrowLeft,
  faExclamation,
  faSort,
  faExclamationCircle,
  faEllipsisV,
  faUserTie,
  faEye,
  faEyeSlash,
  faMapMarkerAlt,
  faUserCircle,
  faPencilAlt,
  faIcons,
  faCog,
  faCircleNotch,
  faTh,
  faGripHorizontal,
  faPlusCircle,
  faSearch,
  faTrashAlt,
  faQuestion,
  faLayerGroup,
  faMinus,
  faEllipsisH,
  faChevronCircleRight,
  faChevronCircleDown,
  faList,
  faMoon,
  faAngleDown,
  faUserAstronaut,
  faUserSecret,
  faUserNinja,
  faLongArrowAltLeft,
  faCubes,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';

export default () => {
  library.add(
    faSpinner,
    faCheck,
    faCopy,
    faTrash,
    faDotCircle,
    faCaretDown,
    faCaretUp,
    faQuestionCircle,
    faQuestion,
    faCheckCircle,
    faMinusCircle,
    faTimesCircle,
    faUser,
    faBriefcase,
    faPaperPlane,
    faExclamationTriangle,
    faTimes,
    faEdit,
    faPowerOff,
    faCalendar,
    faSortDown,
    faSortUp,
    faSort,
    faExclamation,
    faStar,
    faArrowLeft,

    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,

    faChevronCircleRight,
    faChevronCircleDown,

    faSearch,
    faMapMarkerAlt,
    faTrashAlt,
    faPlusCircle,
    faPlus,
    faLock,
    faLockOpen,
    faSave,
    faSkull,
    faBolt,
    faPencilRuler,
    faSadCry,
    faUserGraduate,
    faHandLizard,
    faHandPeace,
    faUndo,
    faEnvelopeOpenText,
    faExclamationCircle,

    faEllipsisH,
    faEllipsisV,
    faUserTie,
    faEye,
    faEyeSlash,
    faUserCircle,
    faPencilAlt,

    faIcons,
    faCog,
    faSpinner,
    faCircleNotch,
    faQuestion,
    faLayerGroup,
    faMinus,
    faTh,
    faGripHorizontal,
    faList,
    faMoon,
    faAngleDown,

    faUserAstronaut,
    faUserSecret,
    faUserNinja,
    faLongArrowAltLeft,

    faCubes,
    faFilter,
  );
};
