import { Button, Modal } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import modals from 'helpers/styles/components/modals.module.sass';

import S from '../../helpers/styles.module.sass';
import { mergeSkillsToTarget, mergeSkillsWithNew } from '../../../../../../redux/SkillTags/actions';
import { ReactComponent as MergeIconBig } from '../../../../../../helpers/icons/merge_icon_big.svg';
import { ReactComponent as MergeIconCreate } from '../../../../../../helpers/icons/merge_icon_with_creating.svg';
import { MergeSkillsModalProps, MergingType } from './helpers/types';
import { DispatchType, ValidationError } from '../../../../../../helpers/types/_common';
import MergeAndCreateNewTag from '../MergeAndCreateNewTag';
import MergeIntoTargetTag from '../MergeIntoTargetTag';

const cx = classNames.bind(S);

const MergeSkillsModal: React.FC<MergeSkillsModalProps> = (props) => {
  const {
    selectedRows,
    visible,
    setVisible,
    resetSelections,
    fetchSkillTags,
    handleOnChangeCheckbox,
  } = props;
  const [ mergingType, changeMergingType ] = useState<MergingType>(MergingType.notSelected);
  const [ error, setError ] = useState<ValidationError | null | undefined>();
  const [ confirmLoading, setConfirmLoading ] = useState(false);
  const [ approved, changeApprovedStatus ] = useState(true);
  const [ targetSkillId, setTargetSkillId ] = useState<number>();
  const [ name, setName ] = useState<string>();
  const [ category, setCategory ] = useState<string>();
  const dispatch: DispatchType = useDispatch();
  const selectedSkillsLength = _.filter(selectedRows, [ 'checked', true ]).length;
  // its enough 2 skills for merging into existed one and only 1 skill for merging into new one
  const mergeIsDisabled = mergingType === MergingType.withNewOne ? !category || !name || selectedSkillsLength < 2 : !targetSkillId || selectedSkillsLength < 1;

  if (selectedSkillsLength === 1 && visible && mergingType !== MergingType.intoExisted) {
    changeMergingType(MergingType.intoExisted);
  }

  useEffect(() => {
    if (!visible) {
      setError(null);
      setName(undefined);
      setCategory(undefined);
      changeMergingType(MergingType.notSelected);
    } else if (selectedSkillsLength === 1) {
      changeMergingType(MergingType.intoExisted);
    } else {
      changeMergingType(MergingType.notSelected);
    }
  }, [ visible ]);
  useEffect(() => {
    setError(null);
    setName(undefined);
    setCategory(undefined);
  }, [ mergingType ]);

  const handleOnChangeCategory = (value?: string) => {
    setCategory(value);
    setError(null);
  };

  const onOk = () => {
    setConfirmLoading(true);
    setError(null);

    if (mergingType === MergingType.intoExisted) {
      onMergeToTarget();
    } else if (mergingType === MergingType.withNewOne) {
      onMergeWithNew();
    }
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onMergeToTarget = () => {
    if (!targetSkillId) return;

    dispatch(mergeSkillsToTarget({ selectedRows, skillId: targetSkillId }))
      .then(() => {
        resetSelections();
        fetchSkillTags();
        setVisible(false);
      })
      .catch((error: AxiosError<ValidationError>) => {
        setError(error?.response?.data);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const onMergeWithNew = () => {
    dispatch(mergeSkillsWithNew({ selectedRows, category, name }))
      .then(() => {
        resetSelections();
        fetchSkillTags();
        setVisible(false);
      })
      .catch((error: AxiosError<ValidationError>) => {
        setError(error?.response?.data);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const renderModalContent = () => {
    const skillList = _.map(selectedRows, (s, key) => (
      <div
        className={cx('skill-list-item')}
        key={key}
      >
        <Checkbox
          value={s.skillId}
          checked={s.checked}
          onChange={(event: CheckboxChangeEvent) => handleOnChangeCheckbox(event, s)}
        >
          {s.name}
        </Checkbox>
      </div>
    ));

    return (
      <div>
        <div className={cx('skill-list')}>
          {skillList}
        </div>
        <div style={{ paddingRight: 20 }}>
          <div className={cx('skill-list_buttons-row')}>
            <Button
              type="text"
              onClick={() => changeMergingType(MergingType.intoExisted)}
              className={cx('skill-list_button', {
                'skill-list_button--active': mergingType === MergingType.intoExisted,
                'skill-list_button--not-default': mergingType !== MergingType.notSelected,
              })}
            >
              <MergeIconBig
                style={{ width: '12px', minWidth: '12px' }}
                className={cx('skill-list-icon')}
              />
              <div>
                <span>Merge into target tag</span>
                { mergingType === MergingType.notSelected
                && (
                  <span className={cx('skill-list_button-text')}>
                    Selected tags will be saved as synonyms for a target tag. Selected tags could also serve as a target one.
                  </span>
                )}
              </div>
            </Button>

            <Button
              type="text"
              onClick={() => {
                setTargetSkillId(undefined);
                changeMergingType(MergingType.withNewOne);
              }}
              disabled={selectedSkillsLength < 2}
              title={selectedSkillsLength < 2 ? 'Unavailable if less than 2 skill tags are selected' : ''}
              className={cx('skill-list_button', {
                'skill-list_button--active': mergingType === MergingType.withNewOne,
                'skill-list_button--not-default': mergingType !== MergingType.notSelected,
              })}
            >
              <MergeIconCreate
                style={{ width: '24px', minWidth: '24px' }}
                className={cx('skill-list-icon')}
              />
              <div>
                <span>Merge & Create new tag</span>
                { mergingType === MergingType.notSelected
                && (
                  <span className={cx('skill-list_button-text')}>
                    A new tag will be created, selected tags will be saved as synonyms for it.
                  </span>
                )}
              </div>
            </Button>
          </div>
          <div className={cx('skill-list_selection-group')}>
            {mergingType === MergingType.intoExisted && (
              <MergeIntoTargetTag
                value={targetSkillId}
                onChange={setTargetSkillId}
                selectedRows={selectedRows}
              />
            )}
            {(mergingType === MergingType.withNewOne) && (
              <MergeAndCreateNewTag
                error={error}
                setError={setError}
                name={name}
                setName={setName}
                category={category}
                handleOnChangeCategory={handleOnChangeCategory}
                approved={approved}
                changeApprovedStatus={changeApprovedStatus}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      className={modals.qsBasicAntdModal}
      title="Merge skill tags"
      cancelButtonProps={{ className: modals.modalCancelBtn, type: 'text' }}
      okButtonProps={{
        className: modals.modalOkBtn,
        size: 'large',
        disabled: mergeIsDisabled || mergingType === MergingType.notSelected,
      }}
      confirmLoading={confirmLoading}
      okText="Merge"
      onOk={onOk}
      onCancel={onCancel}
      centered
    >
      { renderModalContent() }
    </Modal>
  );
};

export default MergeSkillsModal;
