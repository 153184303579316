import { Radio } from 'antd';
import React from 'react';
import cx from 'classnames';

import radio from 'helpers/styles/components/radio.module.sass';
import { EMPLOYEE_TABS } from 'api/User/constants';

import s from './helpers/styles.module.sass';
import { EmployeesFiltersState } from '../../helpers/types';

interface EmployeesRadioProps {
  filters: EmployeesFiltersState;
  onChangeFilters: (value: EmployeesFiltersState) => void;
}

const EmployeesRadio: React.FC<EmployeesRadioProps> = ({ filters, onChangeFilters }) => {
  const handleOnChange = (value: string) => {
    onChangeFilters({
      ...filters,
      active: value,
    });
  };

  return (
    <div className={cx(radio.tableRadio, s.employeesRadio)}>
      <Radio.Group
        value={filters.active}
        className={s.radioWrapper}
        onChange={e => handleOnChange(e.target.value)}
      >
        <Radio
          className={radio.radioItem}
          value={EMPLOYEE_TABS.ACTIVE.radioValue}
        >
          Active
        </Radio>
        <Radio
          className={radio.radioItem}
          value={EMPLOYEE_TABS.INACTIVE.radioValue}
        >
          Inactive
        </Radio>
        <Radio
          className={radio.radioItem}
          value={EMPLOYEE_TABS.INVITED.radioValue}
        >
          Invited
        </Radio>
        <Radio
          className={radio.radioItem}
          value={EMPLOYEE_TABS.ADMINS.radioValue}
        >
          Admins
        </Radio>
        <Radio
          className={radio.radioItem}
          value={EMPLOYEE_TABS.BOT.radioValue}
        >
          Bots
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default EmployeesRadio;
