import React from 'react';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { BCStatuses, UsersBCMonth } from 'api/TimeSheet/types';
import { Redux } from 'helpers/types/_common';
import { PATH_PROFILE } from 'helpers/constants/_common/MenuCases';
import PROFILE_TABS from 'helpers/constants/_common/tabs/profile';
import { ReactComponent as ClockIcon } from 'helpers/icons/clockIcon.svg';

import S from './helpers/styles.module.sass';
import MonthSelector from './MonthSelector/MobileSelector';

interface ProfileSectionProps {
  setSelectedDateObj: (dayjsObj: Dayjs) => void;
  selectedDateObj: Dayjs;
  usersBCMonth: UsersBCMonth;
  isPickerDisabled: boolean;
  isBCLoading: boolean;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ usersBCMonth, isBCLoading, selectedDateObj, setSelectedDateObj, isPickerDisabled }) => {
  const user = useSelector((state: Redux) => state.login);
  const { days } = usersBCMonth;
  
  const { workedHours, maxWorkedHours } = _.reduce(days, (r, dayObj) => ({
    workedHours: r.workedHours + dayObj.hours,
    maxWorkedHours: dayObj.status === BCStatuses.Regular ? r.maxWorkedHours + 8 : r.maxWorkedHours,
  }), { workedHours: 0, maxWorkedHours: 0 } as { workedHours: number, maxWorkedHours: number });

  const renderHoursWithLoading = () => (isBCLoading ? (
    <>
      <LoadingOutlined className={S.spinner} spin />
      &#47;
      <LoadingOutlined className={S.spinner} spin />
    </>
  ) : `${workedHours}/${maxWorkedHours}h`);
  
  return (
    <div className={S.profileSection}>
      <div className={S.panelBox}>
        <Link className={S.avatar} to={`${PATH_PROFILE}/${PROFILE_TABS.general.path}`}>
          <img src={user.avatarUrl} alt={`${user.forename} ${user.surname}`} className={S.avatarImg} />
        </Link>
      </div>
      <div className={S.infoBox}>
        <MonthSelector
          usersBCMonth={usersBCMonth}
          selectedDateObj={selectedDateObj}
          isPickerDisabled={isPickerDisabled}
          setSelectedDateObj={setSelectedDateObj}
        />
        <div className={classNames(S.hoursWrapper, {
          [S.hidden]: isPickerDisabled,
        })}
        >
          <ClockIcon className={S.clockIcon} />
          <span className={S.hours}>Worked in current period:</span>
          <span className={S.hours}>{renderHoursWithLoading()}</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
