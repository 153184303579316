import React from 'react';
import cx from 'classnames';
import { Radio } from 'antd';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'api/User/constants';
import { PATH_REPORTS, TAB_CURATOR_REPORT, TAB_HOUR_REPORT, TAB_VACATION_REPORT } from 'helpers/constants/_common/MenuCases';
import radio from 'helpers/styles/components/radio.module.sass';
import { Redux } from 'helpers/types/_common';
import useTabRedirect from 'helpers/hooks/useTabRedirect';

import s from './styles.module.sass';

interface ReportsRadioTabsProps {
}

const ReportsRadioTabs: React.FC<ReportsRadioTabsProps> = () => {
  const { tab } = useParams<{ tab: string }>();

  const history = useHistory();

  const { permissions } = useSelector((state: Redux) => state.login);

  useTabRedirect({
    tab,
    tabKeys: _.includes(permissions, USER_ROLES.CURATOR)
      ? [ TAB_HOUR_REPORT, TAB_VACATION_REPORT, TAB_CURATOR_REPORT ]
      : [ TAB_HOUR_REPORT, TAB_VACATION_REPORT ],
    redirectUrl: `${PATH_REPORTS}/${TAB_HOUR_REPORT}`,
  });

  const handleOnChangeTab = (tab: string) => {
    history.push(`${PATH_REPORTS}/${tab}`);
  };

  const renderCuratorReport = () => {
    if (!_.includes(permissions, USER_ROLES.CURATOR)) {
      return null;
    }

    return (
      <Radio
        className={radio.radioItem}
        value={TAB_CURATOR_REPORT}
      >
        Curator report
      </Radio>
    );
  };

  return (
    <div className={cx(radio.tableRadio, s.reportsRadio)}>
      <Radio.Group
        value={tab}
        className={s.radioWrapper}
        onChange={e => handleOnChangeTab(e.target.value)}
      >
        <Radio
          className={radio.radioItem}
          value={TAB_HOUR_REPORT}
        >
          Hour report
        </Radio>

        { renderCuratorReport() }
      </Radio.Group>
    </div>
  );
};

export default ReportsRadioTabs;
