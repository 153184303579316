import { useHistory } from 'react-router';
import { useEffect } from 'react';
import _ from 'lodash';

interface UseTabRedirectProps {
  tabKeys: string[];
  redirectUrl: string;
  tab: string
}

const useTabRedirect = (props: UseTabRedirectProps) => {
  const { redirectUrl, tab, tabKeys } = props;

  const history = useHistory();

  useEffect(() => {
    if (!tab || !_.includes(tabKeys, tab)) {
      history.push(redirectUrl);
    }
  }, [ tab, history, tabKeys, redirectUrl ]);
};

export default useTabRedirect;
