import { Select, Spin } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { LabeledValue } from 'antd/lib/select';

import selects from 'helpers/styles/components/selects.module.sass';
import { FoundPartnerOutput, FoundPartnerPage } from 'api/Partners/types';
import { getPartners } from 'api/Partners/requests';
import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';
import useDebounce from 'helpers/hooks/useDebounce';
import { ReactComponent as SelectArrow } from 'helpers/icons/SelectArrow.svg';

import S from './styles.module.sass';
import { ReplacerData } from './types';

interface SelectorPartnerToProps {
  isLoading: boolean;
  replaceData: ReplacerData;
  currentStep: number;
  partners: FoundPartnerOutput[];
  setPartners: (partners: FoundPartnerOutput[]) => void;
  stepToggle: (dir: 'next' | 'prev') => void;
  setReplaceData: (data: ReplacerData) => void;
}

const SelectorPartnerTo: React.FC<SelectorPartnerToProps> = ({ partners, setPartners, isLoading, replaceData, setReplaceData, currentStep, stepToggle }) => {
  const defaultGetterData = {
    meta: {
      ...DEFAULT_SELECT_PAGINATION.meta,
      limit: DEFAULT_SELECT_PAGINATION.meta.limit,
    },
    objects: [],
  };
  const [ getterData, setGetterData ] = useState<FoundPartnerPage>(defaultGetterData);
  const [ searchValue, setSearchValue ] = useState<string>();
  const [ loading, setLoading ] = useState(false);
  
  const debounceSearchValue = useDebounce(searchValue, 600);

  const partnersList = useMemo(() => {
    if (_.isNull(replaceData.partnerTo)) return partners;

    const filteredPartners = _.filter(partners, partner => partner.partnerId !== replaceData.partnerTo?.partnerId);
    const sortByPartnerName = (a: FoundPartnerOutput, b: FoundPartnerOutput) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    };
    return [ ...filteredPartners, replaceData.partnerTo ].sort(sortByPartnerName);
  }, [ partners, replaceData.partnerTo ]);

  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      getData(0);
    }
  }, [ debounceSearchValue ]);

  useEffect(() => {
    if (_.isNil(replaceData.partnerTo)) {
      setGetterData(defaultGetterData);
      setSearchValue(undefined);
      setLoading(false);
    }
  }, [ replaceData.partnerTo ]);

  const getData = (offset?: number) => {
    const correctOffset = _.isNumber(offset) ? offset : getterData.meta?.offset || 0;

    setLoading(true);
    getPartners({
      orderBy: 'name',
      nameContains: searchValue,      
      limit: getterData?.meta?.limit || DEFAULT_SELECT_PAGINATION.meta.limit,
      offset: correctOffset,
    })
      .then((options) => {
        const partners = correctOffset ? [ 
          ...getterData?.objects || [],
          ...options?.objects || [],
        ] : options.objects;
        setGetterData({
          meta: options.meta,
          objects: partners,
        });
        if (!_.isUndefined(partners) && !_.isEmpty(partners)) setPartners(partners);
      })
      .finally(() => setLoading(false));
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin
      spinning={isLoading || loading}
    >
      {menu}
    </Spin>
  );

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (getterData?.meta?.totalCount === getterData?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(getterData?.meta?.offset) ? (getterData.meta?.offset || 0) + DEFAULT_SELECT_PAGINATION.meta.limit : 0;

      getData(increasedPageByOne);
    }
  };

  const onSearch = (searchValue: string) => setSearchValue(searchValue);

  const onFocus = () => {
    if (!_.isEmpty(getterData?.objects) || !_.isEmpty(searchValue)) {
      return;
    }

    getData(0);
  };

  const onItemSelect = (value: string | number | LabeledValue) => {
    const newPartner = _.find(getterData.objects, partner => partner.partnerId === value) || null;

    setReplaceData({
      ...replaceData,
      partnerTo: newPartner,
      activityTo: null,
    });
    if (currentStep === 2) stepToggle('next');
  };

  const renderItem = (partner: FoundPartnerOutput) => (
    <Select.Option
      key={partner.partnerId}
      value={partner.partnerId}
      name={partner.name}
    >
      {partner.name}
    </Select.Option>
  );

  const renderOptions = () => _.map(partnersList, renderItem);
  
  const componentProps = {
    suffixIcon: isLoading ? <LoadingOutlined spin /> : <SelectArrow />,
    dropdownAlign: { offset: [ 0, 7 ] },
    bordered: false,
    onFocus,
    dropdownRender,
    onPopupScroll,
    onSearch,
    searchValue,
    onSelect: onItemSelect,
    showSearch: true,
    showArrow: true,
    value: replaceData.partnerTo?.partnerId || null,
    dropdownClassName: selects.selectDropdown,
    className: classNames(selects.qsSelect, S.reportSelect),
    loading: isLoading || loading,
    placeholder: 'Type to find a partner...',
    disabled: _.isNull(replaceData.activityFrom),
    filterOption: () => true,
  };

  return (
    <Select {...componentProps} size="large">
      {renderOptions()}
    </Select>
  );
};
export default SelectorPartnerTo;
