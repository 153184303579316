import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import cx from 'classnames';
import { Button, Skeleton, Space } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as GoBackIcon } from 'helpers/icons/go-back-icon.svg';
import { ReactComponent as SelectArrowIcon } from 'helpers/icons/SelectArrow.svg';
import { PATH_ADMINISTRATION, PATH_FIND_SPECIALISTS, TAB_EMPLOYEES, TAB_PARTNERS_NEW, TAB_PARTNER_PARTICIPANTS, TAB_USERS_TIMESHEET } from 'helpers/constants/_common/MenuCases';
import { EDIT_PROFILE_TABS } from 'helpers/constants/_common/tabs/profile';
import { UserDetails } from 'api/User/types';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { Redux } from 'helpers/types/_common';
import { AccessEnum } from 'components/Root/helpers/types';

import s from '../helpers/styles.module.sass';
import editStyles from './styles.module.sass';
import EditProfileEmployeesList from './components/EditProfileEmployeesList';
import TrackerSidebar from '../NewTrackerSidebar';
import TrackerLogo from '../../_shared/TrackerLogo/TrackerLogo';

interface EditProfileTabSidebarProps {
  userData?: UserDetails;
  loading: boolean;
}

const EditProfileTabSidebar: React.FC<EditProfileTabSidebarProps> = ({ loading, userData }) => {
  const history = useHistory();
  const { state } = useLocation<{ from: string, pathname?: string }>();
  const { tab } = useParams<{ tab: string }>();
  const user = useSelector((state: Redux) => state.login);

  const [ isSearchMode, setIsSearchMode ] = useState(false);

  const handleOnClickMenu = (path: string) => {
    if (!userData) {
      return;
    }

    history.push(`${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}/${userData.userId}/${path}`);
  };

  const renderMenuItems = () => _.map(EDIT_PROFILE_TABS, (menuTab) => {
    if (
      userData?.usertype === menuTab.hidden 
      || (menuTab.path === 'timesheet' && !_.includes(user.permissions, AccessEnum.Admin))) {
      return null;
    }

    return (
      <li
        key={menuTab.path}
        className={cx(s.sidebarListItem, { [s.activeSidebarList]: tab === menuTab.path })}
      >
        <span className={s.sidebarListItemLink} onClick={() => handleOnClickMenu(menuTab.path)}>
          {menuTab.name}
        </span>
      </li>
    );
  });

  const renderUserInfo = () => {
    if (loading) {
      return (
        <Space>
          <Skeleton.Avatar active size="large" shape="circle" />
          <Skeleton.Input style={{ width: 125 }} active size="large" />
        </Space>
      );
    }

    return (
      <Button
        title={userData ? `${userData.forename} ${userData.surname}` : ''}
        className={editStyles.profilePickerWrapper}
        onClick={() => setIsSearchMode(prevState => !prevState)}
      >
        {
          userData?.avatarUrl
            ? <img className={editStyles.avatar} src={userData?.avatarUrl} alt="avatar" />
            : <TrackerLogo className={editStyles.companyLogo} />
        }

        <span className={cx(editStyles.avatarDesc, { [editStyles.avatarDescEmpty]: _.isEmpty(userData) })}>{userData ? `${userData.forename} ${userData.surname}` : 'Select a user...'}</span>
        { isSearchMode ? <SelectArrowIcon className={s.searchModeArrow} /> : <SelectArrowIcon /> }
      </Button>
    );
  };

  const renderEditUserActivities = () => {
    if ((tab !== EDIT_PROFILE_TABS.timesheet.path) || !userData) {
      return null;
    }

    return (
      <div className={cx(s.sidebarActivities, { [s.hidden]: isSearchMode })}>
        <TrackerSidebar
          collapsed={false}
          userData={userData}
          noProfileHeader
        />
      </div>
    );
  };

  const renderSidebarContent = () => (
    <>
      <ul className={cx(s.sidebarList, editStyles.sidebarList, { [editStyles.sidebarListVisible]: !isSearchMode })}>
        {renderMenuItems()}
      </ul>

      <EditProfileEmployeesList
        visible={isSearchMode}
        setVisible={setIsSearchMode}
      />

      { renderEditUserActivities() }
    </>

  );

  const getCorrectBackButtonTitle = () => {
    switch (state?.from) {
      case TAB_EMPLOYEES:
        return 'Back to employees';
      case TAB_USERS_TIMESHEET:
        return 'Back to administration';
      case PATH_FIND_SPECIALISTS:
        return 'Back to specialists';
      case TAB_PARTNER_PARTICIPANTS:
        return 'Back to participants';
      default:
        return 'Back';
    }
  };

  const backHandling = () => {
    switch (state?.from) {
      case TAB_EMPLOYEES:
        return history.push(`${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}`);
      case TAB_USERS_TIMESHEET:
        return history.push(`${PATH_ADMINISTRATION}/${TAB_USERS_TIMESHEET}`);
      case PATH_FIND_SPECIALISTS:
        return history.push(`${PATH_FIND_SPECIALISTS}/find`);
      case TAB_PARTNER_PARTICIPANTS:
        return history.push(state?.pathname || `${PATH_ADMINISTRATION}/${TAB_PARTNERS_NEW}`);
      default:
        return history.push(`${PATH_ADMINISTRATION}/${TAB_EMPLOYEES}`);
    }
  };

  return (
    <div className={s.sidebarLayout}>
      <div className={s.sidebarContentWrapper}>
        <div className={s.sidebarHeader}>
          <Button
            type="primary"
            className={cx(buttons.qsButtonPrimary, s.goBackButton)}
            onClick={backHandling}
          >
            <GoBackIcon />

            { getCorrectBackButtonTitle() }

          </Button>
          <Space direction="vertical" className={editStyles.headerWrapper}>
            <span className={editStyles.avatarTitle}>
              {user.permissions.includes(AccessEnum.Admin) ? 'Now editing' : 'Now viewing'}
            </span>

            { renderUserInfo() }
          </Space>
        </div>

        { renderSidebarContent() }

      </div>
    </div>
  );
};

export default EditProfileTabSidebar;
