import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { SalaryObject } from 'components/_shared/SalaryField/types';
import { Redux } from 'helpers/types/_common';
import { BCMonth } from 'api/TimeSheet/types';

import s from './styles.module.sass';
import { FlatUserHours } from '../../../../../Table/components/TableBody/helpers/types';
import { selectedActivitiesByHours } from './helpers/helpers';
import MonthInfoPopoverItem from '../MonthInfoPopoverItem/MonthInfoPopoverItem';
import MonthInfoReplaceModal from '../MonthInfoReplaceModal/MonthInfoReplaceModal';
import { MonthInfoModalState } from '../../helpers/types';
import MonthInfoTotalRow from '../MonthInfoTotalRow/MonthInfoTotalRow';

interface MonthInfoPopoverProps {
  BCMonth: BCMonth;
  flatUserHoursTable: FlatUserHours;
  holidaysHours: number;
  userId: number;
  visible: boolean;
  curatorOnlyView?: boolean;
}

const MonthInfoPopover: React.FC<MonthInfoPopoverProps> = ({ flatUserHoursTable, userId, holidaysHours, BCMonth, curatorOnlyView }) => {
  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);

  const [ modalInfo, setModalInfo ] = useState<MonthInfoModalState>({
    visible: false,
    partner: null,
  });

  const [ salaryObject, setSalaryObject ] = useState<SalaryObject>({
    hourly: 0,
    fixed: 0,
  });

  const selectedActivities = useMemo(() => selectedActivitiesByHours(flatUserHoursTable), [ flatUserHoursTable ]);

  const renderTotal = () => {
    if (_.isEmpty(selectedActivities)) {
      return null;
    }

    return (
      <MonthInfoTotalRow
        selectedActivities={selectedActivities}
        holidaysHours={holidaysHours}
        salaryObject={salaryObject}
        setSalaryObject={setSalaryObject}
      />
    );
  };

  return (
    <div className={s.monthPopoverContent}>
      <div className={s.monthPopoverList}>
        {
          _.isEmpty(selectedActivities) && (
            <div className={s.nothingWrapper}>
              <span className={s.nothingText}>No tracked activities yet</span>
            </div>
          )
        }
        {
          _.map(selectedActivities, partner => (
            <MonthInfoPopoverItem
              partner={partner}
              salaryObject={salaryObject}
              key={partner.activity.activityId}
              setModalInfo={setModalInfo}
              isMonthClosed={isMonthClosed}
              curatorOnlyView={curatorOnlyView}
            />
          ))
        }
      </div>

      { renderTotal() }

      {
        !isMonthClosed && (
          <MonthInfoReplaceModal
            userId={userId}
            modalInfo={modalInfo}
            setModalInfo={setModalInfo}
            selectedActivities={selectedActivities}
            BCMonth={BCMonth}
          />
        )
      }

    </div>
  );
};

export default MonthInfoPopover;
