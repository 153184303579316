import dayjs from 'dayjs';
import _ from 'lodash';

import { SelectionActionsEnum } from 'redux/TimeSheet/types';
import configureStore from 'redux/_reducer/configureStore';

import { Coords, DirectionType, SelectionBoxRectangleStyles, SelectionData, SelectionRoundingSettings } from './types';

let selectionZoneWrapper = document.getElementById('table-selection-zone');
let selectionZoneRect = selectionZoneWrapper?.getBoundingClientRect();

window.addEventListener('resize', () => {
  selectionZoneWrapper = document.getElementById('table-selection-zone');
  selectionZoneRect = selectionZoneWrapper?.getBoundingClientRect();
});

/** Returns first cell of timesheet's table, it's needed for table's body left and top offset calc. */
export const getFirstCellRect = () => {
  const getFirstCellNode = document.querySelector('[data-cell-id]');
  
  if (!getFirstCellNode) return null;
  
  return getFirstCellNode.getBoundingClientRect();
};

/** Returns hovered timesheet's cell as Node by x and y coordinates. */
export const findHoveredCellNode = (coords: { x: number, y: number }): HTMLElement | null => {
  const cells = document.querySelectorAll<HTMLElement>('[data-cell-id]');

  if (_.isEmpty(cells)) return null;

  const element = _.find(cells, (cellNode) => {
    const cellRect = cellNode.getBoundingClientRect();
    
    const isXinRect = coords.x >= cellRect.left && coords.x <= (cellRect.left + cellRect.width);
    const isYinRect = coords.y >= cellRect.top && coords.y <= (cellRect.top + cellRect.height);

    return isXinRect && isYinRect;
  });

  return element || null;
};

/** Returns rounded coords to first cell coords. Needed for better selection box size and position calc. */
export const getRoundedCoords = (start: Coords, end: Coords): { start: Coords, end: Coords } => {
  const firstCellRect = getFirstCellRect();

  if (!firstCellRect) return { start, end };

  const directions: DirectionType = getDrawingDirections(start, end);

  const newY = Math.floor((end.y - firstCellRect.top) / firstCellRect.height) * firstCellRect.height + firstCellRect.top;
  const newX = Math.floor((end.x - firstCellRect.left) / firstCellRect.width) * firstCellRect.width + firstCellRect.left;

  return {
    start: { x: newX, y: newY },
    end: {
      x: directions.horizontal === 'toRight' ? newX + firstCellRect.width : newX,
      y: directions.vertical === 'toBottom' ? newY + firstCellRect.height : newY,
    },
  };
};

const isInRange = (coords: { x: number, y: number }, boundingRect: DOMRect) => (
  coords.x > boundingRect.x && coords.x < boundingRect.x + boundingRect.width
  && coords.y > boundingRect.y && coords.y < boundingRect.y + boundingRect.height
);

/** Returns list of timesheet's cellIds, which is overlapped by selectionBox.
 * Keep in mind that overlapping fired on cell's center point, not on edges of it.
 */
export const getOverlappedCellIds = () => {
  const cells = document.querySelectorAll('[data-cell-id]');
  const selectionBox = document.getElementById('timesheet-selection-box-inner');

  if (_.isEmpty(cells) || !selectionBox) return [];

  const boundingRect = selectionBox?.getBoundingClientRect();

  const selectedCellIds = _.reduce(cells, (result, cellNode) => {
    const { width, height, x, y } = cellNode.getBoundingClientRect();

    const valueError = height / 2;
    const cornersOfSelectableCell = {
      leftTop: { x: x + valueError, y: y + valueError },
      rightTop: { x: x + width - valueError, y: y + valueError },
      leftBottom: { x: x + valueError, y: y + height - valueError },
      rightBottom: { x: x + width - valueError, y: y + height - valueError },
    };

    const isLeftTopSelected = isInRange(cornersOfSelectableCell.leftTop, boundingRect);
    const isRightTopSelected = isInRange(cornersOfSelectableCell.rightTop, boundingRect);
    const isLeftBottomSelected = isInRange(cornersOfSelectableCell.leftBottom, boundingRect);
    const isRightBottomSelected = isInRange(cornersOfSelectableCell.rightBottom, boundingRect);

    const cellId = (cellNode as HTMLElement).dataset?.cellId;

    if (!_.isUndefined(cellId) && (isLeftTopSelected || isRightTopSelected || isLeftBottomSelected || isRightBottomSelected)) {
      return [ ...result, cellId ];
    } else {
      return result;
    }
  }, [] as string[]);

  return selectedCellIds;
};

/** Returns selected rows and columns */
export const getOverlappedRowsAndColumns = () => {
  const cellIds = getOverlappedCellIds();
  const firstCellDayObj = dayjs(cellIds[0]).utc();
  const lastCellDayObj = dayjs(cellIds[cellIds.length - 1]).utc();
  const columns = Math.floor((Math.abs(firstCellDayObj.diff(lastCellDayObj) / 1000 / 60 / 60) + 0.5) / 24) + 1;
  const rows = (Math.abs(firstCellDayObj.diff(lastCellDayObj.date(firstCellDayObj.date())) / 1000 / 60 / 60) + 0.5) / 0.5;

  return { columns, rows };
};

/** Returns rounded start and end coords for selection box in accordance with selected cells */
export const getRoundedCoordsWithSelectionBox = (start: Coords, end: Coords) => {
  const cellIds = getOverlappedCellIds();
  const firstCell = document.querySelector(`[data-cell-id="${cellIds[0]}"`);
  const lastCell = document.querySelector(`[data-cell-id="${cellIds[cellIds.length - 1]}"`);

  if (_.isNull(firstCell) || _.isNull(lastCell)) return { start, end };

  const firstCellBoundingRect = firstCell.getBoundingClientRect();
  const lastCellBoundingRect = lastCell.getBoundingClientRect();

  return {
    start: { x: firstCellBoundingRect.x, y: firstCellBoundingRect.y },
    end: { x: lastCellBoundingRect.x + lastCellBoundingRect.width, y: lastCellBoundingRect.y + lastCellBoundingRect.height },
  };
};

/** Returns width and heigh of hours marker */
export const getMarkerSize = (total: number) => {
  const firstCellRect = getFirstCellRect();

  if (!firstCellRect) return { width: 0, height: 0 };  

  const isHourOrMore = total >= 1;

  const { height, width } = firstCellRect;

  const correctedWidth = width - 4; // look at selectionBoxInner width and height calc, and keep it in mind
  const correctedHeight = height - 1; // --//--

  return {
    width: correctedWidth,
    height: isHourOrMore ? correctedHeight * 2 : correctedHeight,
  };
};

/** Returns styles of selection bounding rectangle by coords of start and end points: width, height, left and top. */
export const getBoundingRectStyles = (data: SelectionData, selectionRoundingSettings: SelectionRoundingSettings): SelectionBoxRectangleStyles => {
  const firstCellRect = getFirstCellRect();

  if (_.isNull(firstCellRect)) {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    };
  }

  if (data.selectionAction === SelectionActionsEnum.End) { // at end selection action it should be always rounded
    return {
      width: data.coords.end.x - data.coords.start.x,
      height: data.coords.end.y - data.coords.start.y,
      left: data.coords.start.x - firstCellRect.x,
      top: data.coords.start.y - firstCellRect.y,
    };
  }

  const { start, end } = data.coords;

  const directions: DirectionType = getDrawingDirections(start, end);

  const { left, width } = getWidthAndLeft(data, firstCellRect, directions, selectionRoundingSettings);
  const { top, height } = getHeightAndTop(data, firstCellRect, directions, selectionRoundingSettings);

  return { width, height, left, top };
};

/** Returns drawing direction as constant string by horizontal and vertical: toRight, toLeft, toBottom and toTop. */
const getDrawingDirections = (start: Coords, end: Coords): DirectionType => ({
  horizontal: (end.x - start.x) >= 0 ? 'toRight' : 'toLeft',
  vertical: (end.y - start.y) >= 0 ? 'toBottom' : 'toTop',
});

/** Returns left and width rounded or not, in accordance with rounding settings. */
const getWidthAndLeft = (
  data: SelectionData,
  firstCellRect: DOMRect,
  direction: DirectionType,
  selectionRoundingSettings: SelectionRoundingSettings,
): { left: number, width: number } => {
  const isHorizontalSelectionDisabled = data.userAction === 'select';

  const { start } = data.coords;
  const end = {
    x: !isHorizontalSelectionDisabled ? start.x : data.coords.end.x,
    y: data.coords.end.y,
  };

  if (selectionRoundingSettings.horizontal) {
    const left = direction.horizontal === 'toRight'
      ? Math.floor((start.x - firstCellRect.left) / firstCellRect.width) * firstCellRect.width
      : Math.floor((end.x - firstCellRect.left) / firstCellRect.width) * firstCellRect.width;

    const width = direction.horizontal === 'toRight'
      ? Math.ceil((end.x - left - firstCellRect.left) / firstCellRect.width) * firstCellRect.width
      : Math.ceil((start.x - left - firstCellRect.left + firstCellRect.width) / firstCellRect.width) * firstCellRect.width;

    const widthWithMin = width < firstCellRect.width ? firstCellRect.width : width;

    return { left, width: widthWithMin };
  } else {
    const left = direction.horizontal === 'toRight' ? data.coords.start.x : data.coords.end.x;
    const relativeLeft = left - firstCellRect.left;

    const width = direction.horizontal === 'toRight'
      ? Math.abs(end.x - start.x)
      : Math.ceil((start.x - firstCellRect.left + firstCellRect.width) / firstCellRect.width) * firstCellRect.width - relativeLeft;

    const widthWithMin = width < firstCellRect.width ? firstCellRect.width : width;

    return { left: relativeLeft, width: widthWithMin };
  }
};

const getHeightAndTop = (
  data: SelectionData,
  firstCellRect: DOMRect,
  direction: DirectionType,
  selectionRoundingSettings: SelectionRoundingSettings,
): { top: number, height: number } => {
  const { start, end } = data.coords;

  if (selectionRoundingSettings.vertical) {
    const top = direction.vertical === 'toBottom'
      ? Math.floor((start.y - firstCellRect.top) / firstCellRect.height) * firstCellRect.height
      : Math.floor((end.y - firstCellRect.top) / firstCellRect.height) * firstCellRect.height;
      
    const height = direction.vertical === 'toBottom'
      ? Math.ceil((end.y - top - firstCellRect.top) / firstCellRect.height) * firstCellRect.height
      : Math.ceil((start.y - top - firstCellRect.top) / firstCellRect.height) * firstCellRect.height;

    return { top, height };
  } else {
    const relativeTop = direction.vertical === 'toBottom'
      ? Math.floor((start.y - firstCellRect.top) / firstCellRect.height) * firstCellRect.height
      : end.y - firstCellRect.top;

    const height = direction.vertical === 'toBottom'
      ? Math.abs(end.y - start.y)
      : Math.abs(Math.ceil((start.y - firstCellRect.top + firstCellRect.height) / firstCellRect.height) * firstCellRect.height - relativeTop);

    const heightWithMin = height < firstCellRect.height ? firstCellRect.height : height;

    return { top: relativeTop, height: heightWithMin };
  }
};

/** Returns xLimit: number if activity is selected (so horizontal selection is disabled), else returns x: number instead of it. */
export const getXWithHorizontalLimit = (x: number, xLimit: number) => {
  const isHorizontalDisabled = !_.isNull(configureStore.getState().timeSheet.selectedActivity);

  return isHorizontalDisabled ? xLimit : x;
};

/** Returns coords only inside of table selection zone. */
export const getValidCoords = (coords: Coords) => {
  if (_.isUndefined(selectionZoneRect)) {
    selectionZoneWrapper = document.getElementById('table-selection-zone');
    selectionZoneRect = selectionZoneWrapper?.getBoundingClientRect();

    if (_.isUndefined(selectionZoneRect)) return coords;
  }

  const leftHorizontalException = coords.x < selectionZoneRect.x;
  const rightHorizontalException = coords.x >= selectionZoneRect.x + selectionZoneRect.width;
  const topVerticalException = coords.y < selectionZoneRect.y;
  const bottomVerticalException = coords.y >= selectionZoneRect.y + selectionZoneRect.height;
  const horizontalException = leftHorizontalException || rightHorizontalException;
  const verticalException = topVerticalException || bottomVerticalException;
  const exceptionX = leftHorizontalException ? selectionZoneRect.x : selectionZoneRect.x + selectionZoneRect.width;
  const exceptionY = topVerticalException ? selectionZoneRect.y : selectionZoneRect.y + selectionZoneRect.height;

  const endWithMinEdge = {
    x: horizontalException ? exceptionX : coords.x,
    y: verticalException ? exceptionY : coords.y,
  };
  return endWithMinEdge;
};
