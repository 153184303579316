import * as Actions from '../constants/Skillset';
import _ from 'lodash';
import getColourSet from '../utils/getColourSet';
import Subtypes from '../constants/__common__/Subtypes';
import processCommonEvent from '../utils/processCommonEvent';
import SkillsCategories from '../../helpers/constants/Skills/Categories';

const colors = getColourSet();

const InitialState = {
  showModal: false,
  skills: {
    isLoading: false,
    objects: [],
    showDisapprovedSkills: true,
    showSkillNameToFilter: false,
    skillNameToFilter: '',
    adminNoteToFilter: '',
    categoryToFilter: 1,
    errorMessage: ''
  },
  skillModal: {
    show: false,
    isLoading: false,
    errorMessage: '',
    assignedUsers: [],
    selectedSkill: {
      fullName: '',
      shortName: '',
      description: '',
      approvingStatus: true,
      categoryId: 1,
      color: colors[5].hex
    }
  },
  skillSetModal: {
    show: false,
    isLoading: false,
    selectedCell: {},
    errorMessage: '',
    selectedUsersSkillsSet: []
  },
  adminNotes: {
    show: false,
    adminNoteToFilter: '',
    objects: []
  },
  reactionList: [
    {reactionId: 0, emojiId: 0, description: 'Currently in use'},
    {reactionId: 1, emojiId: 0, description: 'Something more'},
  ],
  adminNoteModal: {
    show: false,
    isLoading: false,
    errorMessage: '',
    selectedAdminNote: {
      name: '',
      description: ''
    }
  },
  mergeToolModal: {
    show: false,
    errorMessage: '',
    isLoading: false
  },
  selectedUsersSkillsSet: [],
  categories: SkillsCategories,
  showCreateNewSkillQuickTip: localStorage.getItem('CNSQuickTip') === 'true',
  colors
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.GET_SKILLS:
      return getSkillsEvent(action, state);
    case Actions.GET_ADMIN_NOTES:
      return getAdminNotesEvent(action, state);
    case Actions.GET_USERS_BY_SKILL_ID:
      return getUsersBySkillId(action, state);

    case Actions.CREATE_NEW_SKILL:
    case Actions.EDIT_SKILL:
    case Actions.DELETE_SKILL:
      return processSkillModalRequestEvents(action, state);

    case Actions.CREATE_NEW_ADMIN_NOTE:
    case Actions.EDIT_ADMIN_NOTE:
    case Actions.DELETE_ADMIN_NOTE:
      return processAdminNoteModalRequestEvents(action, state);

    case Actions.REQUEST_MERGE_TOOL:
      return {...state, mergeToolModal: {...state.mergeToolModal, message: '', isLoading: true}};
    case Actions.COMPLETE_MERGE_TOOL:
      return {...state, mergeToolModal: {...state.mergeToolModal, message: '', isLoading: false}};

    case Actions.UPDATE_SKILLS_WITH_NEW_ONE:
      return {...state, skills: {...state.skills, objects: [...state.skills.objects, action.payload]}};

    case Actions.UPDATE_ADMIN_NOTES_WITH_NEW_ONE:
      return {...state, adminNotes: {...state.adminNotes, objects: [...state.adminNotes, action.payload]}};
    case Actions.UPDATE_USERS_ADMIN_NOTES_WITH_NEW_ONE:
      return {...state, adminNotesObjects: [...state.adminNotesObjects, action.payload]};
    case Actions.UPDATE_EXISTED_SKILL:
      return updateExistedSkill(state, action);
    case Actions.UPDATE_EXISTED_ADMIN_NOTE:
      return updateExistedAdminNote(state, action);
    case Actions.TOGGLE_CREATE_NEW_SKILL_TIP_VISIBILITY:
      return {...state, showCreateNewSkillQuickTip: action.payload};

    case Actions.CHANGE_SKILL_APPROVING_STATUS:
      return changeSkillApprovingStatusEvent(action, state);

    case Actions.REMOVE_DELETED_SKILL:
      return {
        ...state,
        skills: {...state.skills, objects: state.skills.objects.filter(skill => skill.skillId !== action.payload)}
      };
    case Actions.REMOVE_DELETED_ADMIN_NOTE:
      return {
        ...state,
        adminNotes: {
          ...state.adminNotes,
          objects: state.adminNotes.objects.filter(adminNote => adminNote.adminNoteId !== action.payload)
        }
      };

    case Actions.CHANGE_CATEGORY_ID_TO_FILTER:
      return {
        ...state,
        skills: {...state.skills, categoryToFilter: action.payload},
        adminNotes: {...state.adminNotes, show: false}
      };
    case Actions.CHANGE_SKILL_NAME_TO_FILTER:
      return {...state, skills: {...state.skills, skillNameToFilter: action.payload}};
    case Actions.CHANGE_ADMIN_NOTE_TO_FILTER:
      return {...state, adminNotes: {...state.adminNotes, adminNoteToFilter: action.payload}};

    case Actions.SHOW_ADMIN_NOTES_SECTION:
      return {...state, adminNotes: {...state.adminNotes, show: true}};
    case Actions.ADMIN_NOTE_MODAL_TOGGLE:
      return adminNoteModalToggle(action, state);
    case Actions.TOGGLE_UNAPPROVED_SKILLS_VISIBILITY:
      return {...state, skills: {...state.skills, showDisapprovedSkills: !state.skills.showDisapprovedSkills}};
    case Actions.CHANGE_SELECTED_SKILL:
      return {...state, skillModal: {...state.skillModal, selectedSkill: action.payload || InitialState.skillModal.selectedSkill}};
    case Actions.CHANGE_SELECTED_ADMIN_NOTE:
      return {...state, adminNoteModal: {...state.adminNoteModal, selectedAdminNote: action.payload}};


    case Actions.MERGE_TOOL_TOGGLE:
      return mergeToolToggle(action, state);

    case Actions.CHANGE_MERGE_TOOL_ERROR_MESSAGE:
      return {...state, mergeToolModal: {...state.mergeToolModal, errorMessage: action.payload}};

    case Actions.SKILL_SET_ADD_EDIT_MODAL_TOGGLE:
      return skillSetAddEditModalToggle(action, state);

    case Actions.SKILL_MODAL_TOGGLE:
      return skillModalToggle(action, state);

    case Actions.SKILLS_INITIATE:
      return InitialState;

    case Actions.SKILLS_CLEAR:
      return {...InitialState, adminNotes: state.adminNotes};

    default:
      return state;
  }
}

const changeApprovingStatus = (state, action) => {
  let skills = _.cloneDeep(state.skills.objects);
  let skill = skills.find(item => item.skillId === action.payload);

  skill.approvingStatus = !skill.approvingStatus;

  return {
    ...state,
    skills: {
      ...state.skills,
      isLoading: false,
      errorMessage: '',
      objects: skills
    }
  }
};

const updateExistedSkill = (state, action) => {
  return {
    ...state,
    skills: {
      ...state.skills,
      objects: state.skills.objects.map(skill => {
        if (skill.skillId === action.payload.skillId) {
          return {
            ...action.payload,
            color: action.payload.color
          };
        } else {
          return skill;
        }
      })
    }
  }
};

const updateExistedAdminNote = (state, action) => {
  return {
    ...state,
    adminNotes: {
      ...state.adminNotes,
      objects: state.adminNotes.objects.map(adminNote => {
        if (adminNote.adminNoteId === action.payload.adminNoteId) {
          return action.payload;
        } else {
          return adminNote;
        }
      })
    }
  }
};

const getSkillsEvent = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, skills: {...state.skills, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {...state, skills: {...state.skills, isLoading: false, errorMessage: '', objects: action.payload}};
    case Subtypes.Request.Error:
      return {...state, skills: {...state.skills, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const getAdminNotesEvent = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, adminNotes: {...state.adminNotes, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {...state, adminNotes: {...state.adminNotes, isLoading: false, errorMessage: '', objects: action.payload}};
    case Subtypes.Request.Error:
      return {...state, adminNotes: {...state.adminNotes, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const getUsersBySkillId = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, skillModal: {...state.skillModal, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {
        ...state,
        skillModal: {...state.skillModal, isLoading: true, errorMessage: '', assignedUsers: action.payload.users}
      };
    case Subtypes.Request.Error:
      return {...state, skillModal: {...state.skillModal, isLoading: true, errorMessage: action.payload}};
    default:
      return state;
  }
};

const processSkillModalRequestEvents = (action, state) => processCommonEvent.request('skillModal')(action, state);

const processAdminNoteModalRequestEvents = (action, state) => processCommonEvent.request('adminNoteModal')(action, state);

const changeSkillApprovingStatusEvent = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, skills: {...state.skills, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return changeApprovingStatus(state, action);
    case Subtypes.Request.Error:
      return {...state, skills: {...state.skills, isLoading: false, errorMessage: action.payload}};
    default:
      return state;
  }
};

const skillSetAddEditModalToggle = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Modal.Show:
      return {
        ...state,
        skillSetModal: {
          ...state.skillSetModal,
          show: true,
          selectedCell: {
            title: action.payload.title,
            category: action.payload.category,
            experienceRateId: action.payload.experienceRateId
          },
          selectedUsersSkillsSet: action.payload.skillsData
        }
      };
    case Subtypes.Modal.Hide:
      return {
        ...state,
        skillSetModal: {
          ...state.skillSetModal,
          show: false,
          selectedCell: {title: state.skillSetModal.selectedCell.title},
          selectedUsersSkillsSet: InitialState.selectedUsersSkillsSet
        }
      };
    default:
      return state;
  }
};

const skillModalToggle = (action, state) => processCommonEvent.modalToggle('skillModal', {selectedSkill: InitialState.skillModal.selectedSkill})(action, state);

const adminNoteModalToggle = (action, state) => processCommonEvent.modalToggle('adminNoteModal', {selectedAdminNote: InitialState.adminNoteModal.selectedAdminNote})(action, state);

const mergeToolToggle = (action, state) => processCommonEvent.modalToggle('mergeToolModal')(action, state);

export default {
  reducer,
  InitialState
}
