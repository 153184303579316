import _ from 'lodash';

import { updateUserPreferences } from '../../api/User/requests';
import TokenStore from '../../api/_request/helpers/jwtTokenStore';
import { ASK_CONFIRMATION_ON_DELETE_HOURS, ASK_CONFIRMATION_ON_OVERWRITE_HOURS, Themes } from '../../helpers/constants/_common/constants';
import { AHM_AUTO_CLOSE, DHM_AUTO_CLOSE, MAIN_THEME } from '../../helpers/constants/_common/localStorageConstants';
import { getFromLocalStorage, getStringFromLocalStorage } from '../../helpers/utils/localStorageService';
import { api } from '../../index';
import configuredStore from '../../redux/_reducer/configureStore';
// eslint-disable-next-line import/no-cycle
import { externalRedirectToLogin } from '../actions/Login/LoginActions';

const resourceUrl = '/auth';

export const getGoogleAuthPath = redirectUri => api.get(`${resourceUrl}/`, { redirect_uri: redirectUri, provider: "google"}, { withoutAT: true });
export const getMicrosoftAuthPath = redirectUri => api.get(`${resourceUrl}/`, { redirect_uri: `${redirectUri}/?provider=microsoft` , provider: "microsoft"}, { withoutAT: true });

export const applyDefaultSettings = (userId) => {
  const settings = {
    [ASK_CONFIRMATION_ON_DELETE_HOURS]: getFromLocalStorage(DHM_AUTO_CLOSE, undefined),
    [ASK_CONFIRMATION_ON_OVERWRITE_HOURS]: getFromLocalStorage(AHM_AUTO_CLOSE, undefined),
    uiTheme: _.toUpper(getStringFromLocalStorage(MAIN_THEME, Themes.LIGHT)),
    webUiDrawingMode: configuredStore.getState().login.preferences.webUiDrawingMode,
  };

  updateUserPreferences(userId, settings);

  return settings;
};

const getUserInfo = () => api.get(`${resourceUrl}/userinfo/`)
  .then((res) => {
    if (!res.preferences.confirmed) {
      const defaultSettings = applyDefaultSettings(res.userId);

      return {
        ...res,
        preferences: defaultSettings,
      };
    }

    return res;
  });

const updateTokens = () => {
  const refreshToken = TokenStore.getRefreshToken();

  if (!refreshToken) {
    configuredStore.dispatch(externalRedirectToLogin());
    return new Promise((resolve, reject) => reject());
  }

  return api.post(`${resourceUrl}/token/`, {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  }, { withoutAT: true });
};

const googleAuth = params => api.post(`${resourceUrl}/token/`, {
  code: params.code,
  state: params.state,
  redirect_uri: params.redirectUri,
  grant_type: 'authorization_code',
  provider: params.provider,
}, { executeWithoutTokenUpdate: true, withoutAT: true });

export default {
  getUserInfo,
  googleAuth,
  updateTokens,
};
