/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';

import S from './styles.module.sass';

interface CollapsePairProps {
  defaultSection: 'first' | 'second' | null;
  firstTitle: React.ReactNode;
  secondTitle: React.ReactNode;
  firstContent: React.ReactNode;
  secondContent: React.ReactNode;
  onChange?: () => void;
}

const CollapsePair: React.FC<CollapsePairProps> = ({ firstTitle, secondTitle, firstContent, secondContent, defaultSection, onChange }) => {
  const [ openedSection, setOpenedSection ] = useState(defaultSection);

  const toggleActiveItem = (section: 'first' | 'second') => {
    setOpenedSection(section);

    if (onChange) onChange();
  };

  return (
    <div className={S.collapseWrapper}>
      <div className={classNames(S.collapseItem, {
        [S.active]: openedSection === 'first',
      })}
      >
        <div className={S.collapseItemTitle} onClick={() => toggleActiveItem('first')}>{firstTitle}</div>
        <div className={S.collapseItemContent}>{firstContent}</div>
      </div>
      <Button
        type="link"
        onClick={() => toggleActiveItem(openedSection === 'first' ? 'second' : 'first')}
        className={classNames(S.collapseTogglerBtn, { [S.default]: openedSection === null })}
      >
        <span className={S.separator} />
        <DownOutlined className={classNames(S.collapseIcon, { [S.flipped]: openedSection === 'first' })} />
        <span className={S.separator} />
      </Button>
      <div className={classNames(S.collapseItem, { 
        [S.active]: openedSection === 'second',
      })}
      >
        <div className={S.collapseItemTitle} onClick={() => toggleActiveItem('second')}>{secondTitle}</div>
        <div className={S.collapseItemContent}>{secondContent}</div>
      </div>
    </div>
  );
};

export default CollapsePair;
