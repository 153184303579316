import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as SunIcon } from 'helpers/icons/sunIcon.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';
import tooltips from 'helpers/styles/components/tooltips.module.sass';
import { USER_ROLES } from 'api/User/constants';
import { PATH_TRACKER } from 'helpers/constants/_common/MenuCases';
import { DispatchType, Redux } from 'helpers/types/_common';
import NavBarIcon from 'helpers/icons/NavBarMenu/NavBarIcon';
import { logout } from 'redux/User/actions';
import { ThemeContext } from 'components/AppThemeWrapper/AppThemeWrapper';
import { ReactComponent as Logo } from 'helpers/icons/NavBarMenu/Logo.svg';
import { ReactComponent as GrayLogo } from 'helpers/icons/NavBarMenu/GrayLogo.svg';
import { Themes } from 'helpers/constants/_common/constants';

import s from './helpers/styles.module.sass';
import { LinkElement } from './components/LinkElement';
import { ReactComponent as Logout } from './helpers/icons/Logout.svg';
import { MenuField } from './helpers/types';
import { MENU_FIELDS } from './helpers/menuFields';

const Menu: React.FC = () => {
  const dispatch: DispatchType = useDispatch();
  const location = useLocation();
  const { theme, setTheme } = useContext(ThemeContext);

  const { permissions, userDataRequest, userId } = useSelector((state: Redux) => state.login);

  const [ logoutLoading, setLogoutLoading ] = useState(false);

  const commonFields = _.filter(MENU_FIELDS, m => _.includes(m.type, USER_ROLES.USER));
  const specialFields = _.filter(MENU_FIELDS, m => _.includes(m.type, USER_ROLES.ADMIN));

  const currentRoutePath = _.split(location.pathname, '/')[1];

  const renderMenuLink = (menu: MenuField[]) => _.map(menu, (m) => {
    const isAccessConfirmed = _.isArray(m.type) ? !_.isEmpty(_.intersection(permissions, m.type)) : _.includes(permissions, m.type);

    if (!isAccessConfirmed || !m.path || m.hideOn) {
      return null;
    }

    const isActiveMenu = _.includes(m.path().split('?')[0], currentRoutePath);
    const navBarIcon = m.iconFileName ? <NavBarIcon iconFileName={m.iconFileName} /> : <FontAwesomeIcon icon="icons" />;

    return (
      <Tooltip
        key={m.label}
        title={m.label}
        overlayClassName={tooltips.trackerTooltip}
        placement="right"
        getPopupContainer={() => (document.getElementById('main-menu') || document.body)}
      >
        <li>
          <Link
            to={m.path({ userId, permissions })}
            className={s.menuLinkItem}
          >
            <div className={cx(s.menuLinkButton, { [s.activeMenuLinkButton]: isActiveMenu })}>
              <div className={s.menuIconWrapper}>{navBarIcon}</div>
            </div>
          </Link>
        </li>
      </Tooltip>
    );
  });

  const handleLogout = () => {
    if (logoutLoading) {
      return;
    }

    setLogoutLoading(true);

    dispatch(logout())
      .finally(() => setLogoutLoading(false));
  };

  return (
    <aside id="main-menu" className={cx(s.rootMenu, { [s.menuLoading]: userDataRequest.isLoading })}>
      <div className={s.menuInner}>
        <Link to={PATH_TRACKER} className={cx('qs-menu__a-link')}>
          <LinkElement cantBeActive>
            <div className={cx(s.menuLinkButton, s.menuLogoLink)}>
              <div className={s.menuIconWrapper}>
                {
                  (theme === Themes.DARK)
                    ? <GrayLogo className={s.menuLogo} />
                    : <Logo className={s.menuLogo} />
                }
              </div>
            </div>
          </LinkElement>
        </Link>
        <div className={s.menu}>
          <Scrollbars autoHide>
            <ul className={cx(s.menuList, s.menuListCommon)}>
              {renderMenuLink(commonFields)}
            </ul>
            <ul className={cx(s.menuList, s.menuListSpecial)}>
              {renderMenuLink(specialFields)}
            </ul>
          </Scrollbars>
        </div>
        <div className={s.menuProfile}>
          <Tooltip
            placement="right"
            overlayClassName={tooltips.trackerTooltip}
            title={`Switch to ${(theme === Themes.DARK) ? Themes.LIGHT : Themes.DARK}`}
          >
            {(theme === Themes.DARK)
              ? (
                <SunIcon
                  className={s.changeThemeIcon}
                  onClick={() => {
                    setTheme(Themes.LIGHT);
                  }}
                />
              )
              : (
                <FontAwesomeIcon
                  style={{ width: 22, height: 22 }}
                  className={s.changeThemeIcon}
                  icon="moon"
                  onClick={() => {
                    setTheme(Themes.DARK);
                  }}
                />
              )}
          </Tooltip>

          <Button
            type="text"
            className={cx(buttons.qsButton, s.logoutButton)}
            onClick={handleLogout}
          >
            { logoutLoading ? <LoadingOutlined /> : <Logout /> }
          </Button>
        </div>
      </div>
    </aside>
  );
};

export default Menu;
