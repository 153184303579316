import dayjs from 'dayjs';
import React from 'react';
import classNames from 'classnames/bind';

import { UsersBCDay } from 'api/TimeSheet/types';

import S from './helpers/styles.module.sass';

interface MobileTimeSheetDayProps {
  dayObj: UsersBCDay;
  selectedDayObj: UsersBCDay;
  isBCLoading: boolean;
  isFirstDay: boolean;
}

const MobileTimeSheetDay: React.FC<MobileTimeSheetDayProps> = ({ dayObj, isBCLoading, selectedDayObj, isFirstDay }) => {
  const isSelected = dayObj.day === selectedDayObj.day;
  const dayNumber = dayjs(dayObj.day).date();
  const dayName = dayjs(dayObj.day).format('ddd');
  const roundedHours = dayObj.hours <= 8 ? dayObj.hours : 8;
  const filledHeight = roundedHours * 12.5; // filled height shouldn't be more then 100%
  const isHoursHidden = isSelected || dayObj.hours === 0;

  return (
    <div
      className={classNames(S.day, {
        [S.loading]: isBCLoading,
        [S.firstDay]: isFirstDay,
      })}
    >
      <span className={classNames(S.workedHours, {
        [S.hidden]: isHoursHidden,
      })}
      >
        {`${dayObj.hours}h`}
      </span>
      <div className={S.backgroundLine}>
        <div className={S.dash} />
        {Boolean(filledHeight) && !isBCLoading && <div className={S.fill} style={{ height: `${filledHeight}%` }} />}
      </div>
      <div className={classNames(S.dayNameWrapper, {
        [S.loading]: isBCLoading,
      })}
      >
        <span className={S.dayNumber}>{dayNumber}</span>
        <span className={S.dayName}>{dayName}</span>
      </div>
    </div>
  );
};

export default MobileTimeSheetDay;
