import React from 'react';

import BookkeepingItemsSettings from './BookkeepingItemsSettings';
import BookkeepingItemsTable from './BookkeepingItemsTable';
import s from './styles.module.sass';

const BookkeepingItems: React.FC<{}> = () => (
  <div className={s.bookkeepingItemsLayout}>
    <BookkeepingItemsSettings />
    <BookkeepingItemsTable />
  </div>
);

export default BookkeepingItems;
