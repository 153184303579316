import _ from 'lodash';

import { SkillCategoriesConstant, SkillCategoryEnum } from '../../types/Skills';

const SKILL_CATEGORIES: SkillCategoriesConstant = {
  PROGRAMMING_LANGUAGE: { categoryId: 0, key: SkillCategoryEnum.ProgrammingLanguage, title: 'Programming Languages', shortTitle: 'Lang.', eventKey: 'programmingLanguages' },
  TECHNOLOGY: { categoryId: 1, key: SkillCategoryEnum.Technology, title: 'Technologies', shortTitle: 'Tech.', eventKey: 'technologies' },
  TOOL: { categoryId: 2, key: SkillCategoryEnum.Tool, title: 'Tools', shortTitle: 'Tools', eventKey: 'tools' },
  LIBRARY_OR_FRAMEWORK: { categoryId: 3, key: SkillCategoryEnum.LibraryOrFramework, title: 'Frameworks and Libraries', shortTitle: 'Lib&Fw', eventKey: 'frameworksAndLibraries' },
  SERVICE: { categoryId: 4, key: SkillCategoryEnum.Services, title: 'Services', shortTitle: 'Services', eventKey: 'services' },
  SECURITY_TOOL: { categoryId: 5, key: SkillCategoryEnum.SecurityTools, title: 'Security Tools', shortTitle: 'Security', eventKey: 'securityTools' },
  SKILL_FOR_DESIGNERS: { categoryId: 6, key: SkillCategoryEnum.SkillsForDesigners, title: 'Skills for designers', shortTitle: 'Designer', eventKey: 'skillsForDesigners' },
  MARKUP_LANGUAGE: { categoryId: 10, key: SkillCategoryEnum.MarkupLanguage, title: 'Markup Languages', shortTitle: 'Markup', eventKey: 'markupLanguages' },
  MACHINE_LEARNING: { categoryId: 11, key: SkillCategoryEnum.MachineLearning, title: 'Machine Learning', shortTitle: 'ML', eventKey: 'machineLearning' },
  STANDARD: { categoryId: 7, key: SkillCategoryEnum.Standard, title: 'Standards', shortTitle: 'Standards', eventKey: 'Standards' },
  SOFT_SKILL: { categoryId: 8, key: SkillCategoryEnum.SoftSkills, title: 'Soft Skills', shortTitle: 'Soft', eventKey: 'softSkills', description: true },
};

export const DEFAULT_SKILL_CATEGORIES_ORDER = _.map(SKILL_CATEGORIES, s => s.key);

export default SKILL_CATEGORIES;
