import React from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { CellType, GroupOfCells } from 'components/TimeSheet/helpers/types';
import { BCJointStatus, BCStatuses } from 'api/TimeSheet/types';

import { ColumnProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import columnPropsComparing from './helpers/columnPropsComparing';
import ColumnHeader from './components/ColumnHeader/ColumnHeader';
import ColumnBody from './components/ColumnBody/ColumnBody';
import { getMiddleDay } from './helpers/helpers';

const cx = classNames.bind(S);

const Column: React.FC<ColumnProps> = React.memo((props) => {
  const {
    dayObj,
    prevDayObj,
    userHoursTable,
    isMonthClosed,
    isBCLoading,
    groupOfCellsContextMenu,
    curatorOnlyView,
    BCMonth,
  } = props;

  const { day } = dayObj;

  const userHoursColumn = userHoursTable?.[day];
  const isToday = userHoursColumn?.day === dayjs().format(DEFAULT_DATE_FORMAT);
  const isCut = dayObj.jointStatus === BCJointStatus.Cut;

  const getWorkedHoursInfo = () => {
    if (isBCLoading || isMonthClosed || !isToday || isCut) return;

    const dayjsObj = dayjs(dayObj.day);
    const daysToCalc = BCMonth.jointDays.slice(0, _.indexOf(BCMonth.jointDays, dayObj)).filter(day => day.jointStatus !== BCJointStatus.Cut);

    const isNotBasic = dayObj.jointStatus !== BCJointStatus.Basic;
    const isNextMonth = isNotBasic && BCMonth.month < dayjsObj.month() + 1;
    const isPrevMonth = isNotBasic && BCMonth.month > dayjsObj.month() + 1;

    const cellsWithData = _.reduce(daysToCalc, (result, dayObj) => {
      if (_.isNil(userHoursTable[dayObj.day])) {
        return result;
      } else {
        const storedCells = _.filter(userHoursTable[dayObj.day].cells, cell => cell.type === CellType.Stored);
        const storedCellsSum = _.reduce(storedCells, (acc, cell) => acc + (cell as GroupOfCells).groupCells.length, 0);

        return result + storedCellsSum;
      }
    }, 0);
    const actualWorkload = cellsWithData / 2;

    const onlyRegularDays = daysToCalc.filter(day => day.status === BCStatuses.Regular);
    const plannedWorkload = onlyRegularDays.length * 8;

    const result = plannedWorkload - actualWorkload;

    if (
      getMiddleDay(BCMonth).day === dayjs(day).format(DEFAULT_DATE_FORMAT)
      || (isNextMonth && dayjsObj.date() === 1)
      || (isPrevMonth && dayjsObj.date() === dayjsObj.daysInMonth())
    ) {
      return (
        <div className={S.workedHours}>
          <div className={classNames(S.monthMarkerWrapper, S.withWorkload)}>
            {renderWorkloadComponent(result)}
            <span className={S.monthMarker}>{dayjs(day).format('MMM')}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className={S.workedHours}>
          {renderWorkloadComponent(result)}
        </div>        
      );
    }
  };

  const renderWorkloadComponent = (workload: number) => {
    switch (true) {
      case workload === 0:
        return <span className={S.workload} title="Your workload is up to plan"><FontAwesomeIcon icon="check" className={S.checkMark} /></span>;
        break;
      case workload > 0:
        return <span className={S.workload} title={`You're ${`${Math.abs(workload)}h`} behind the plan`}>{`-${Math.abs(workload)}h`}</span>;
        break;
      case workload < 0:
      default:
        return <span className={S.workload} title={`You're ${`${Math.abs(workload)}h`} ahead of the plan`}>{`+${Math.abs(workload)}h`}</span>;
        break;
    }
  };

  return (
    <div
      className={cx(S.column, {
        [S.notInteractive]: isBCLoading,
        [S.closedMonth]: isMonthClosed,
      })}
    >
      {getWorkedHoursInfo()}
      <ColumnHeader
        key={day}
        userHoursColumn={userHoursColumn}
        isBCLoading={isBCLoading}
        isMonthClosed={isMonthClosed}
        prevDayObj={prevDayObj}
        isToday={isToday}
        {...dayObj}
      />
      <ColumnBody
        dayObj={dayObj}
        prevDayObj={prevDayObj}
        userHoursTable={userHoursTable}
        isMonthClosed={isMonthClosed}
        isBCLoading={isBCLoading}
        groupOfCellsContextMenu={groupOfCellsContextMenu}
        curatorOnlyView={curatorOnlyView}
      />
    </div>
  );
}, columnPropsComparing);

export default Column;
