/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dayjs } from 'dayjs';

import { ReactComponent as ClosedMonthIcon } from 'helpers/icons/lockIcon.svg';

import s from './styles.module.sass';

interface ClosedMonthPopupProps {
  isMonthClosed: boolean;
  selectedDateObj: Dayjs;
}

const ClosedMonthPopup: React.FC<ClosedMonthPopupProps> = ({ isMonthClosed, selectedDateObj }) => {
  const [ isClosed, setIsClosed ] = useState(false);

  useEffect(() => {
    setIsClosed(false);
  }, [ selectedDateObj ]);

  if (!isMonthClosed) {
    return null;
  }

  return (
    <div
      className={classNames(s.closedMonthPopup, {
        [s.closed]: isClosed,
      })}
      onClick={() => setIsClosed(!isClosed)}
    >
      <ClosedMonthIcon />
      <span className={s.text}>Marking hours in closed month is not allowed</span>
      <FontAwesomeIcon icon="angle-down" />
    </div>
  );
};

export default ClosedMonthPopup;
