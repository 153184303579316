import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EmployeesFilter from './components/EmployeesFilters';
import EmployeesRadio from './components/EmployeesRadio';
import s from './helpers/styles.module.sass';
import { EmployeesFiltersState, UserStatisticOutputChecked } from './helpers/types';
import EmployeesTable from './components/EmployeesTable';
import EmployeesSelectionPanel from './components/EmployeesSelectionPanel';
import { TABLE_NAMES } from '../../helpers/constants/Tables/tableList';
import { getAllEmployees } from '../../redux/Employees/actions';
import { DispatchType, Redux } from '../../helpers/types/_common';
import { EMPLOYEE_TABS } from '../../api/User/constants';
import { Meta, Sorter } from '../../api/_request/types';

const Employees = () => {
  const dispatch: DispatchType = useDispatch();

  const [ filters, setFilters ] = useState<EmployeesFiltersState>({
    active: EMPLOYEE_TABS.ACTIVE.radioValue,
    skillsCountLte: '',
    overtimesAllowance: '',
    skillsetLastUpdate: '',
    timesheetLastUpdate: '',
    hasSpecialistProfilesReadAccess: false,
    hasPrimarySkill: false,
    entityId: [],
    userId: [],
    partnerId: [],
  });
  const [ selectedRows, setSelectedRows ] = useState<UserStatisticOutputChecked[]>([]);

  const { meta, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.EMPLOYEES]);

  const onChangeFilters = (value: string | number | boolean | number[], fieldName: keyof EmployeesFiltersState) => {
    setFilters(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const getEmployeesData = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      ...filters,
      ...pagination,
      ...sorter,
    };

    return dispatch(getAllEmployees(body, TABLE_NAMES.EMPLOYEES));
  };

  const renderFilters = () => {
    if (!_.isEmpty(selectedRows)) {
      return (
        <EmployeesSelectionPanel
          getEmployeesData={() => getEmployeesData(meta, sorter)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      );
    }

    return (
      <>
        <EmployeesRadio filters={filters} onChangeFilters={setFilters} />
        <EmployeesFilter
          getEmployeesData={() => getEmployeesData(meta, sorter)}
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      </>
    );
  };

  return (
    <div className={s.employee}>
      <div className={s.employeeFilters}>
        { renderFilters() }
      </div>
      <div>
        <EmployeesTable
          getEmployeesData={getEmployeesData}
          filters={filters}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default Employees;
