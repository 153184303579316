import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';

import S from './styles.module.sass';
import { ReplacerData, UserReportChecked } from './types';

interface SelectorActivityFromProps {
  isLoading: boolean;
  replaceData: ReplacerData;
  currentStep: number;
  stepToggle: (dir: 'next' | 'prev') => void;
  setReplaceData: (data: ReplacerData) => void;
}

const SelectorActivityFrom: React.FC<SelectorActivityFromProps> = ({ isLoading, replaceData, setReplaceData, currentStep, stepToggle }) => {
  const onChange = (activityId: number) => {
    const activity = _.find(replaceData.partnerFrom?.activities || [], report => report.activity.activityId === activityId) || null;
    const reports: UserReportChecked[] = _.isNull(activity) ? [] : _.map(_.sortBy(activity.users, [ report => report.user.forename ]), report => ({
      ...report,
      user: {
        ...report.user,
        forename: _.upperFirst(report.user.forename),
      },
      checked: true,
    }));

    setReplaceData({
      ...replaceData,
      userReports: reports,
      activityFrom: activity,
    });
    if (currentStep === 1) stepToggle('next');
  };
  
  return (
    <Select
      className={classNames(selects.qsSelect, S.reportSelect)}
      suffixIcon={isLoading ? <LoadingOutlined className={S.spinner} spin /> : <SelectIcon />}
      size="large"
      bordered={false}
      loading={isLoading}
      disabled={_.isNull(replaceData.partnerFrom)}
      value={replaceData.activityFrom?.activity.activityId || null}
      onChange={onChange}
    >
      <Select.Option
        value={null}
        disabled
      >
        Pick an activity
      </Select.Option>
      {_.map(_.sortBy(replaceData.partnerFrom?.activities, report => report.activity.name), report => (
        <Select.Option
          key={report.activity.activityId + report.activity.name}
          value={report.activity.activityId}
          className={S.option}
        >          
          <span>{report.activity.name}</span>
          {`${report.hours}h`}
        </Select.Option>
      ))}
    </Select>
  );
};
export default SelectorActivityFrom;
