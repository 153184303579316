import _ from 'lodash';
import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { Input, Select, Space, Checkbox, Popover, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import selects from 'helpers/styles/components/selects.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import SKILL_CATEGORIES from 'helpers/constants/Skills/Categories';
import { Redux } from 'helpers/types/_common';
import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import radio from 'helpers/styles/components/radio.module.sass';

import AddNewSkillTag from './components/AddNewSkillTag';
import S from './helpers/styles.module.sass';
import { ManageSkillTagPanelProps } from './helpers/types';

const cx = classNames.bind(S);

const ManageSkillTagPanel: React.FC<ManageSkillTagPanelProps> = (props) => {
  const { onChange, managePanelValues, hidden, onAddNewCallback } = props;

  const { loading } = useSelector((state: Redux) => state.tables[TABLE_NAMES.MANAGE_SKILL_TAGS]);

  const searchInput = useRef<Input>(null);

  const onChangeSearchValue = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...managePanelValues,
      searchValue: e.target.value,
    });
  }, 1000);

  const onChangeRadio = (e: RadioChangeEvent) => {
    const { value } = e.target;

    onChange({
      ...managePanelValues,
      approved: value,
    });
  };

  const onChangeSelect = (category?: string | number) => {
    onChange({
      ...managePanelValues,
      category,
    });
  };

  const onChangeSynonymsCheckbox = (event: CheckboxChangeEvent) => {
    if (loading) {
      searchInput?.current?.focus();
      return;
    }

    onChange({
      ...managePanelValues,
      searchBySynonyms: event.target.checked,
    });

    searchInput?.current?.focus();
  };

  const renderSelect = () => {
    const options = _.map(SKILL_CATEGORIES, (s, key) => (
      <Select.Option key={s.categoryId} value={key}>{s.title}</Select.Option>
    ));

    return (
      <Select
        value={managePanelValues.category}
        placeholder="Select category..."
        className={cx(selects.qsSelect, S.manageSkillsSelect)}
        dropdownClassName={selects.selectDropdown}
        suffixIcon={<SelectIcon />}
        size="large"
        onChange={onChangeSelect}
        dropdownAlign={{ offset: [ 0, 6 ] }}
        bordered={false}
        allowClear
      >
        { options }
      </Select>
    );
  };

  const renderRadio = () => (
    <div className={cx(radio.tableRadio, S.skillTagsRadio)}>
      <Radio.Group
        value={managePanelValues.approved}
        className={S.radioWrapper}
        onChange={onChangeRadio}
      >
        <Radio
          className={radio.radioItem}
          value={undefined}
        >
          All skill tags
        </Radio>
        <Radio
          className={radio.radioItem}
          value={false}
        >
          Not approved
        </Radio>
      </Radio.Group>
    </div>
  );

  return (
    <div className={cx('manage-tag-panel', { 'manage-tag-panel--hidden': hidden })}>
      <div className={cx('manage-tag-panel_radio-wrapper')}>

        { renderRadio() }

      </div>
      <Space className={cx('manage-tag-panel_search-wrapper')}>
        { renderSelect() }
        <Popover
          content={<Checkbox onChange={onChangeSynonymsCheckbox} checked={managePanelValues.searchBySynonyms}>Also search by synonyms</Checkbox>}
          trigger="focus"
          align={{ offset: [ 0.5, -10 ] }}
          overlayClassName={cx('manage-tag-panel_popover-search')}
          placement="bottom"
        >
          <Input
            ref={searchInput}
            placeholder="Search skills..."
            className={cx(inputs.qsInput, S.manageSkillsInput)}
            prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
            size="large"
            onChange={onChangeSearchValue}
            allowClear
          />
        </Popover>

        <AddNewSkillTag
          onSaveCallback={onAddNewCallback}
        />
      </Space>
    </div>
  );
};

export default ManageSkillTagPanel;
