import _ from 'lodash';
import dayjs from 'dayjs';
import qs from 'query-string';

import { api } from '../../index';
import { convertHourReportResponse, convertUserHourReports } from './helpers';
import { Sorter } from '../_request/types';
import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import { AllItemsReport, GetUserHourReportsItemBody, UserDetailedReports } from './types';

export const getHourReportData = async (userId: number, dates: string | string[], sorter?: Sorter) => {
  if (_.isString(dates)) {
    return getAllItemsReports(userId, dates, sorter);
  }

  if (_.isArray(dates)) {
    return getAllItemsReportsRange(userId, dates, sorter);
  }
};

/** Returns all items report of a user with given identifier for a given month */
export const getAllItemsReports = async (userId: number, date: string, sorter?: Sorter) => {
  const year = dayjs(date).year();
  const month = dayjs(date).month() + 1;

  return api.get<AllItemsReport>(`/users/${userId}/reports/all-items/${year}/${month}/`, {
    ...convertObjectKeysToLanguageCase(sorter, 'snakeCase'),
  })
    .then(response => convertHourReportResponse(response));
};

/** Returns all items report of a user with given identifier for a given datetime range */
export const getAllItemsReportsRange = async (userId: number, dates: string[], sorter?: Sorter) => api.get<AllItemsReport>(`/users/${userId}/reports/all-items/`, {
  start_date_gte: dates[0],
  end_date_lte: dates[1],
  ...convertObjectKeysToLanguageCase(sorter, 'snakeCase'),
})
  .then(response => convertHourReportResponse(response));

/*
  USER HOUR REPORTS
 */
export const getUserHourReportsData = async (dates: string | string[], body?: GetUserHourReportsItemBody) => {
  if (_.isString(dates)) {
    return getUserHourReports(dates, body);
  }

  if (_.isArray(dates)) {
    return getUserHourReportsRange(dates, body);
  }
};

/** Returns detailed hour reports of users for given year and month pair */
export const getUserHourReports = async (date: string, body?: GetUserHourReportsItemBody) => {
  const year = dayjs(date).year();
  const month = dayjs(date).month() + 1;

  return api.get<UserDetailedReports>(`/bookkeeping/reports/users/${year}/${month}/`, {
    ...convertObjectKeysToLanguageCase(_.omit(body, 'searchValue'), 'snakeCase'),
    fullname_contains: body?.searchValue,
    username_contains: body?.searchValue,
    email_address_contains: body?.searchValue,
    active: true,
  }, { paramsSerializer: (params: object) => qs.stringify(params) })
    .then(response => convertUserHourReports(response));
};

/** Returns detailed hour reports of users for given year and month pair */
export const getUserHourReportsRange = async (dates: string[], body?: GetUserHourReportsItemBody) => api.get<UserDetailedReports>('/bookkeeping/reports/users/', {
  start_date_gte: dates[0],
  end_date_lte: dates[1],
  ...convertObjectKeysToLanguageCase(_.omit(body, 'searchValue'), 'snakeCase'),
  fullname_contains: body?.searchValue,
  username_contains: body?.searchValue,
  email_address_contains: body?.searchValue,
  active: true,
}, { paramsSerializer: (params: object) => qs.stringify(params) })
  .then(response => convertUserHourReports(response));
