import * as Actions from '../constants/CuratorHourReport';
import dayjs from 'dayjs';
import Subtypes from 'V1/constants/__common__/Subtypes';

const firstDay = dayjs().date(1).format('YYYY-MM-DD');
const lastDay = dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD');

const InitialState = {
  startDate: firstDay,
  endDate: lastDay,
  currentMonth: dayjs().month() + 1,
  currentYear: dayjs().get('year'),
  curatorObjects: [],
  partner: {
    projectId: 1,
    name: ''
  },
  partnerToFilter: null,
  usernameToFilter: null,
  partnersList: [],
  hourlyRate: 0,
  isLoading: true
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.CHANGE_STARTDATE:
      return { ...state, startDate: action.payload };
    case Actions.CHANGE_ENDDATE:
      return { ...state, endDate: action.payload };
    case Actions.CHANGE_PERIOD:
      const { startDate, endDate, currentMonth, changeYear } = action.payload;
      return { ...state, startDate, endDate, currentMonth, changeYear };
    case Actions.CHANGE_HOURLYRATE:
      return { ...state, hourlyRate: action.payload };
    case Actions.CHANGE_PARTNER_TO_FILTER:
      return { ...state, partnerToFilter: action.payload};
    case Actions.CHANGE_USERNAME_TO_FILTER:
      return { ...state, usernameToFilter: action.payload};
    case Actions.RESET_FILTERS:
      let {partnerToFilter, usernameToFilter} = InitialState;
      return { ...state, usernameToFilter, partnerToFilter};
    case Actions.REMOVE_OBJECTS:
      return { ...state, objects: [], curatorObjects: [] };



    case Actions.RECEIVE_HOURREPORT_REQUEST:
      return { ...state, isLoading: true };
    case Actions.RECEIVE_HOURREPORT_DATA:
      return {
        ...state,
        objects: action.payload,
        error: false,
        isLoading: false
      };
    case Actions.RECEIVE_HOURREPORT_ERROR:
      return { ...state, error: true, isLoading: false, data: action.payload };
    case Actions.CURATOR_HOUR_REPORT_INITIATE:
      return InitialState;



    case Actions.RECEIVE_PARTNERS_LIST_REQUEST:
      return { ...state, isLoading: action.payload };
    case Actions.RECEIVE_PARTNERS_LIST_DATA:
      return {
        ...state,
        partnersList: action.payload,
        error: false,
        isLoading: false
      };
    case Actions.RECEIVE_PARTNERS_LIST_ERROR:
      return { ...state, error: true, isLoading: false, data: action.payload };
    case Actions.PARTNERS_LIST_INITIATE:
      return InitialState;



    case Actions.RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_REQUEST:
      return { ...state, isLoading: true };
    case Actions.RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_DATA:
      return {
        ...state,
        curatorObjects: action.payload,
        error: false,
        isLoading: false
      };
    case Actions.RECEIVE_CURATORS_HOURS_PY_PROJECT_NAME_ERROR:
      return { ...state, error: true, isLoading: false, data: action.payload };
    case Actions.CURATORS_HOURS_PY_PROJECT_NAME_INITIATE:
      return InitialState;



    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
