import axios from 'axios';

import { ReverseGeolocationCoordinates } from './types';

const token = process.env.REACT_APP_DADATA_TOKEN;

const daDataHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Token ${token}`,
};

export const getDaDataLocation = async (address: string) => {
  const response = await axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
    query: address,
    count: 3,
    from_bound: { value: 'city' },
    to_bound: { value: 'city' },
    locations: [
      {
        country: '*',
      },
    ],
    language: 'en',
  },
  {
    headers: daDataHeader,
  });

  return response.data?.suggestions;
};

export const getReverseGeocoding = async (coordinates: ReverseGeolocationCoordinates) => {
  const response = await axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address', {
    lat: coordinates.lat,
    lon: coordinates.lon,
    radius_meters: 1000,
    language: 'en',
    count: 1,
  },
  {
    headers: daDataHeader,
  });

  try {
    if (response.data?.suggestions[0]?.data) {
      return {
        city: response.data?.suggestions[0]?.data.city,
        country: response.data?.suggestions[0]?.data.country,
      };
    }
  } catch {}
};
