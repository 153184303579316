import { CSSProperties } from 'react';

import { EnglishLevelEnum } from './_common';

export interface UserSkill {
  userSkillId: number | null;
  experience: SkillExperienceEnum;
  currentlyInUse: boolean;
  skill: Skill;
  attitude: AttitudesEnum | null;
  action?: UserSkillActions | null;
  errored?: boolean;
  creationDate?: string;
  lastUpdate?: string;
  isLoading?: boolean;
}

export enum UserSkillActions {
  create = 'CREATE',
  add = 'ASSIGN',
  delete = 'UNASSIGN',
  update = 'UPDATE',
}

export interface Skill {
  skillId: number | null;
  name: string;
  category: SkillCategoryEnum;
  approved: boolean;
  creationDate?: string;
  lastUpdate?: string;
}

export interface SkillWithAttitudes extends Skill {
  attitude: AttitudesEnum | null;
  experience: SkillExperienceEnum;
}

export type SkillAttitudes = {
  [key in AttitudesEnum]: SkillAttitudeProp
};

export interface SkillAttitudeProp {
  attitudeId: number;
  title: string;
  key: AttitudesEnum;
  icon: string;
  style?: CSSProperties;
}

export interface SkillExperience {
  experienceId: number;
  name: string;
  secondName?: string;
  label: string;
  shortName: string;
  color: string;
}

export interface SkillModifiedExperience extends SkillExperience {
  filterKey: ExperienceFilterNames;
  additionalName?: string;
}

export enum ExperienceFilterNames {
  notSet = 'notSet',
  petProjects = 'petProjects',
  commercialUse = 'commercialUse',
  confident = 'confident',
  level80 = 'level80',
}

export interface EnglishKnowledgeState {
  knowledgeId: EnglishLevelEnum;
  name: string;
  secondName?: string;
  descriptions: string;
  value: number;
}

export interface SkillCategory {
  categoryId: number;
  key: SkillCategoryEnum;
  title: string;
  shortTitle: string;
  eventKey: string;
  description?: boolean;
}

export type SkillExperiencesConstant = {
  [key in SkillExperienceEnum]: SkillExperience
};

export type ExpFiltersConstant = {
  [key in ExpFiltersEnum]: SkillModifiedExperience
};

export type SkillCategoriesConstant = {
  [key in SkillCategoryEnum]: SkillCategory;
};

export type ExperienceColorsConstant = {
  [key in SkillExperienceEnum]: string;
};

export enum SkillExperienceEnum {
  petProjects = 'PET_PROJECTS',
  commercialUse = 'COMMERCIAL_USE',
  confident = 'CONFIDENT',
  level80 = 'LEVEL_80',
}

export type ExpFiltersEnum = SkillExperienceEnum | NotSetExpEnum;

export enum NotSetExpEnum {
  notSet = 'NOT_SET',
}

export enum SkillCategoryEnum {
  ProgrammingLanguage = 'PROGRAMMING_LANGUAGE',
  LibraryOrFramework = 'LIBRARY_OR_FRAMEWORK',
  Tool = 'TOOL',
  Technology = 'TECHNOLOGY',
  Services = 'SERVICE',
  SecurityTools = 'SECURITY_TOOL',
  SkillsForDesigners = 'SKILL_FOR_DESIGNERS',
  Standard = 'STANDARD',
  MarkupLanguage = 'MARKUP_LANGUAGE',
  MachineLearning = 'MACHINE_LEARNING',
  SoftSkills = 'SOFT_SKILL',
}

export enum AttitudesEnum {
  Like = 'LIKE',
  Dislike = 'DISLIKE',
  Hate = 'HATE',
  Ok = 'OK',
  Super = 'SUPER',
}

export enum AttitudesEnumLC {
  like = 'like',
  dislike = 'dislike',
  ok = 'ok',
  super = 'super',
}
