const COLORS = {
  Blue: '#0000FF',
  Red: '#FF0000',
  'Grass green': '#00FF00',
  Yellow: '#FFFF00',
  Black: '#000000',
  'Dark blue': '#00008B',
  'Dark red': '#8B0000',
  'Dark green': '#008000',
  Orange: '#FF8C00',
  'Dark grey': '#696969',
  'Light blue': '#1E90FF',
  Pink: '#CD5C5C',
  Green: '#3CB371',
  'Light orange': '#FFD700',
  Gray: '#A9A9A9',
  'Sky blue': '#87CEFA',
  'Body pink': '#FFA07A',
  'Light green': '#98FB98',
  Cream: '#FFE4B5',
  'Light gray': '#D3D3D3',
};

export default COLORS;
