import _ from 'lodash';

import { AllItemsReport, ConvertedUserDetailedReports, UserDetailedReports } from './types';

export const convertHourReportResponse = (response: AllItemsReport) => ({
  hours: response.hours,
  holidays: response.holidays,
  partners: {
    hours: response.partners.hours,

    partners: _.map(response.partners.partners, partner => ({
      ...partner,

      activities: _.map(partner.activities, activity => ({
        ...activity,
        partner: _.omit(partner, 'activities'),
      })),
    })),
  },
});

export const convertUserHourReports = (response: UserDetailedReports): ConvertedUserDetailedReports => ({
  hours: response.hours,
  users: _.map(response.users, user => ({
    user: user.user,
    hours: user.hours,
    periodIsClosed: user.periodIsClosed,
    timesheetLastUpdate: user.timesheetLastUpdate,

    children: _.map(user.partners, partner => ({
      partner: partner.partner,
      hours: partner.hours,
      user: user.user,

      children: _.map(partner.activities, activity => ({
        activity: activity.activity,
        hours: activity.hours,
        partner: partner.partner,
        user: user.user,
      })),
    })),
  })),
});
