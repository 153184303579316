import dayjs from 'dayjs';
import _ from 'lodash';

import { UiDrawMode, USER_TYPES } from '../../helpers/constants/_common/constants';
import * as Actions from '../constants/Login';
import * as NotificationActions from '../constants/Notification';
import Subtypes from '../constants/__common__/Subtypes';

const CONSTANTS = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  CURATOR: 'CURATOR',
};

const InitialState = {
  id: 0,
  userId: 0,
  logged: false,
  curator: [],
  avatarUrl: '',
  forename: '',
  surname: '',
  username: '',
  active: true,
  description: '',
  emailAddress: '',
  entityId: 1,
  entityName: '',
  occupationId: 1,
  occupationName: '',
  permissions: [ 'USER' ],
  role: 1,
  usertype: USER_TYPES.USER,
  usertypeId: 0,
  notifications: {
    isLoading: false,
    objects: [],
    count: 0,
  },
  userDataRequest: {
    isLoading: false,
    message: '',
    error: false,
  },
  skillsetActuality: null,
  contentChanging: true,
  showLogoutModal: false,
  curatorStatus: null,
  isFilledProfile: undefined,
  availableRoutes: [],
  isMobileDevice: false,

  preferences: {
    uiTheme: undefined,
    webUiDrawingMode: UiDrawMode.MOUSE,
    askConfirmationOnDeleteHours: false,
    askConfirmationOnOverwriteHours: false,
    confirmed: false,
  },
};

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.LOGOUT:
      return InitialState;
    case Actions.CHANGE_PROFILES_OCCUPATION:
      return { ...state, occupationId: action.payload };

    case Actions.CHANGE_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notifications: { ...state.notifications, count: action.payload },
      };

    case NotificationActions.GET_ALL_NOTIFICATIONS:
      return getAllNotification(state, action);

    case NotificationActions.MARK_NOTIFICATION_AS_READ:
      return markNotificationAsRead(state, action);

    case NotificationActions.MARK_ALL_NOTIFICATIONS_AS_READ:
      return markAllNotificationsAsRead(state, action);

    case Actions.GET_USER_DATA:
      return getUserData(state, action);

    case Actions.UPDATE_USER_DATA:
      const skillsetActuality = action.payload.markedSkillsetAsActual ? { markedAsActual: dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]'), isExpired: false } : null;

      return {
        ...state,
        ..._.omit(action.payload, 'markedSkillsetAsActual'),
        skillsetActuality,
      };

    case Actions.CHANGE_CONTENT:
      return { ...state, contentChanging: !state.contentChanging };
    case Actions.SHOW_LOGOUT_MODAL:
      return { ...state, showLogoutModal: !state.showLogoutModal };

    case Actions.SET_USER_LAYOUT_TO_MOBILE:
      return {
        ...state,
        isMobileDevice: true,
      };

    case Actions.SET_USER_PREFERENCES:
      return {
        ...state,

        preferences: {
          ...state.preferences,
          ...action.preferences,
        },
      };

    default:
      return state;
  }
}

function getAllNotification(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, notifications: { ...state.notifications, isLoading: true, errorMessage: '' } };
    case Subtypes.Request.Success:
      return {
        ...state,
        notifications: { ...state.notifications, isLoading: false, errorMessage: '', objects: action.payload.objects },
      };
    case Subtypes.Request.Error:
      return { ...state, notifications: { ...state.notifications, isLoading: false, errorMessage: action.payload } };
    default:
      return state;
  }
}

function markNotificationAsRead(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, notifications: { ...state.notifications, isLoading: true, errorMessage: '' } };
    case Subtypes.Request.Success:
      const notifications = _.cloneDeep(state.notifications.objects);
      notifications.find(n => n.notificationId === action.payload).isRead = true;
      return {
        ...state,
        notifications: { ...state.notifications, isLoading: false, errorMessage: '', objects: notifications },
      };
    case Subtypes.Request.Error:
      return { ...state, notifications: { ...state.notifications, isLoading: false, errorMessage: action.payload } };
    default:
      return state;
  }
}

function markAllNotificationsAsRead(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, notifications: { ...state.notifications, isLoading: true, errorMessage: '' } };
    case Subtypes.Request.Success:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          errorMessage: '',
          objects: state.notifications.objects.map(notification => ({
            ...notification,
            isRead: true,
          })),
        },
      };
    case Subtypes.Request.Error:
      return { ...state, notifications: { ...state.notifications, isLoading: false, errorMessage: action.payload } };
    default:
      return state;
  }
}

function getUserData(state, action) {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return { ...state, userDataRequest: { ...state.userDataRequest, isLoading: true } };
    case Subtypes.Request.Success:
      return {
        ...state,
        ...action.payload,
        logged: true,
        isFilledProfile: action.isFilledProfile,
        availableRoutes: action.availableRoutes || [],
        userDataRequest: InitialState.userDataRequest,
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        id: InitialState.id,
        logged: false,
        userDataRequest: {
          isLoading: false,
          message: action.payload,
          error: true,
        },
      };

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer,
};
