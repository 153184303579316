import * as Actions from '../../helpers/constants/_common/actions/SkillSet';
import { SkillSetReducerInterface } from './types';
import processCommonEvent from '../../V1/utils/processCommonEvent';
import Subtypes from '../../V1/constants/__common__/Subtypes';

const InitialState: SkillSetReducerInterface = {
  usersSkillsTable: {
    isLoading: false,
    objects: [],
  },
};

const updateUsersSkills = (actions: any, state: SkillSetReducerInterface) => {
  switch (actions.subtype) {
    case Subtypes.Request.Loading:
      return {
        ...state,
        usersSkillsTable: {
          ...state.usersSkillsTable,
          isLoading: true,
        },
      };
    case Subtypes.Request.Success:
      return {
        ...state,
        usersSkillsTable: {
          ...state.usersSkillsTable,
          isLoading: false,
        },
      };
    case Subtypes.Request.Error:
      return {
        ...state,
        usersSkillsTable: {
          ...state.usersSkillsTable,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

const getSkillsByUserId = (action: any, state: SkillSetReducerInterface) => processCommonEvent.get('usersSkillsTable')(action, state);

function reducer(state = InitialState, action: any): any {
  switch (action.type) {
    case Actions.INITIATE:
      return InitialState;

    case Actions.GET_USER_SKILLS_BY_USER_ID:
      return getSkillsByUserId(action, state);

    case Actions.UPDATE_USERS_SKILLS:
      return updateUsersSkills(action, state);

    default:
      return state;
  }
}

export default {
  InitialState,
  reducer,
};
