export interface Day extends DayObj {
  day: string
}

export interface DayObj {
  businessDayId: number | null;
  status: DayStatuses;  
}

export enum DayStatuses {
  Regular = 'REGULAR',
  SuggestedHoliday = 'SUGGESTED_HOLIDAY',
  DayOff = 'DAY_OFF',
  Holiday = 'HOLIDAY',
}

export interface Month {
  businessMonthId: number | null;
  days: Day[];
  month: number;
  year: number;
}

export interface BusinessMonthRangeOutput {
  businessMonthId: number | undefined;
  startDay: string;
  endDay: string;
  year: number;
  month: number;
  modified: boolean;
}

export type StoreDestinationType = 'prevMonthDays' | 'currentMonthDays' | 'nextMonthDays';
