import dayjs from 'dayjs';
import React from 'react';
import classNames from 'classnames';

import { DayStatuses } from 'api/BusinessCalendar/types';

import S from './styles.module.sass';
import { JointDay, JointStatus } from '../../helpers/types';

interface DayComponentProps {
  BCDay: JointDay;
  backupDay: JointDay;
  daysToCut: string[];
  changingMode: 'amount' | 'status';
  currentMonthNumber: number;
  changeDayStatus: (BCDay: JointDay) => void;
  highlightDaysForCut: (date: JointDay) => void;
  changeAmountOfDaysByArr: () => void;
}

const DayComponent: React.FC<DayComponentProps> = (props) => {
  const { BCDay, changeAmountOfDaysByArr, highlightDaysForCut, currentMonthNumber, changeDayStatus, changingMode, daysToCut, backupDay } = props;
  const isEdited = BCDay?.status !== backupDay?.status || BCDay?.jointStatus !== backupDay?.jointStatus;

  const onClick = () => {
    if (changingMode === 'amount') {
      changeAmountOfDaysByArr();
    } else {
      changeDayStatus(BCDay);
    }
  };

  const onMouseEnter = () => {
    if (changingMode === 'amount') {
      highlightDaysForCut(BCDay);
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={classNames(S.dayComponent, {
        [S.currentMonth]: dayjs(BCDay.day).month() + 1 === currentMonthNumber,
        [S.cutDay]: BCDay.jointStatus === JointStatus.Cut,
        [S.highlighted]: daysToCut.includes(BCDay.day),
        [S.statusChangingMode]: changingMode === 'status',
        [S.holiday]: BCDay.status !== DayStatuses.Regular,
        [S.edited]: isEdited,
      })}
    >
      <div className={S.inner}>
        <div className={S.content}>
          <span className={S.date}>
            {dayjs(BCDay.day).date()}
          </span>
        </div>
      </div>
    </button>
  );
};

export default DayComponent;
