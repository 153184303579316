import { useEffect, useState } from 'react';
import _ from 'lodash';

const useScrollPosition = () => {
  const [ scrollPosition, setScrollPosition ] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', _.throttle(updatePosition, 60));

    _.throttle(updatePosition, 60);
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
