import React from 'react';

import CuratorViewEmployeesList from 'components/Sidebars/CuratorViewSidebar/components/CuratorViewEmployeesList';

import s from './styles.module.sass';

interface CuratorAdministrationTabProps {
}

const CuratorAdministrationTab: React.FC<CuratorAdministrationTabProps> = () => (
  <div className={s.curatorsListWrapper}>
    <div className={s.curatorsListTitle}>Please, select user to view his profile</div>
    <CuratorViewEmployeesList
      styleClasses={{ wrapper: s.curatorsList, item: s.curatorsItem }}
      alwaysVisible
    />
  </div>
);

export default CuratorAdministrationTab;
