import React from 'react';
import Container from './Container';
import Row from './Row';
import Col from './Col';
import Centered from './Centered';

class Layout extends React.Component<{}, {}> {
  public static Container = Container;
  public static Row = Row;
  public static Col = Col;
  public static Centered = Centered;

  render(): React.ReactNode {
    return null;
  }
}

Layout.Container = Container;
Layout.Row = Row;
Layout.Col = Col;
Layout.Centered = Centered;

export default Layout;
