import React from 'react';
import cx from 'classnames';

import S from './helpers/styles.module.sass';
import InfoPanel from './components/InfoPanel/InfoPanel';
import { MonthProps } from './helpers/types';
import MonthInner from './components/MonthInner/MonthInner';
import { CalendarMonthData, MonthInnerType } from './components/MonthInner/helpers/types';
import { DayType } from '../../helpers/types';
import { filterByMonth } from './helpers/helpers';

const Month: React.FC<MonthProps> = (props) => {
  const { isActiveMonth } = props;
  const { days, month, year } = props.monthData;
  const monthInnerData: CalendarMonthData = {
    month,
    year,
    days: {
      prevMonth: filterByMonth(days, month - 1 === 0 ? 12 : month - 1, DayType.Original), // conditions needed for
      currentMonth: filterByMonth(days, month, DayType.Original),
      nextMonth: filterByMonth(days, month + 1 === 13 ? 1 : month + 1, DayType.Original), // january and december cases
    },
  };

  return (
    <div className={cx(S.month, {
      [S.active]: isActiveMonth,
      [S.loading]: props.isLoading,
    })}
    >
      <MonthInner
        onDrawerOpen={props.onDrawerOpen}
        monthInnerData={monthInnerData}
        monthInnerType={MonthInnerType.Regular}
      />
      <InfoPanel
        days={days}
        isLoading={props.isLoading}
      />
    </div>
  );
};

export default Month;
