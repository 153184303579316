import { useEffect, useState } from 'react';
/**
* Returns boolean that will be true after mounting and some timeout delay that you can provide to the function.
* If you provide nothing -- class will be added after mounting instantly (useful for displaying animation)
*/
const useAddClassAfterMount = (timeoutDelay: number = 0) => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  let timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    timer = setTimeout(() => {
      setIsLoaded(true);
    }, timeoutDelay);
    
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return isLoaded;
};

export default useAddClassAfterMount;
