export const TABLE_OVERLAY_INITIATE = 'TABLE_OVERLAY_INITIATE';
export const SHOW_POPUP = 'SHOW_POPUP';
export const SHOW_ADD_ACTIVITY_MODAL = 'SHOW_ADD_ACTIVITY_MODAL';
export const CHANGE_ADD_ACTIVITY_MODAL_PARTNER = 'CHANGE_ADD_ACTIVITY_MODAL_PARTNER';
export const CHANGE_ADD_ACTIVITY_MODAL_MESSAGE = 'CHANGE_ADD_ACTIVITY_MODAL_MESSAGE';
export const CHANGE_ADD_ACTIVITY_MODAL_START_TIME = 'CHANGE_ADD_ACTIVITY_MODAL_START_TIME';
export const CHANGE_ADD_ACTIVITY_MODAL_END_TIME = 'CHANGE_ADD_ACTIVITY_MODAL_END_TIME';
export const TO_POST_TIME_SHEET_REQUEST = 'TO_POST_TIME_SHEET_REQUEST';
export const TO_POST_TIME_SHEET_OK = 'TO_POST_TIME_SHEET_OK';
export const TO_POST_TIME_SHEET_ERROR = 'TO_POST_TIME_SHEET_ERROR';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const SHOW_DELETE_MODAL = 'SHOW_DELETE_MODAL';
export const CHANGE_DATE = 'CHANGE_DATE';
export const SHOW_POPUP_NO_POS = 'SHOW_POPUP_NO_POS';
export const SHOW_CLOSE_MONTH_MODAL = 'SHOW_CLOSE_MONTH_MODAL';
export const SHOW_QUICK_TIP = 'SHOW_QUICK_TIP';
export const SHOW_UPDATE_HOUR_MODAL = 'SHOW_UPDATE_HOUR_MODAL';
export const SHOW_ADD_PARTNER_MODAL = 'SHOW_ADD_PARTNER_MODAL';
export const SHOW_EDIT_ACTIVITY_MODAL = 'SHOW_EDIT_ACTIVITY_MODAL';
export const LOAD_EDIT_ACTIVITY_MODAL_DATA = 'LOAD_EDIT_ACTIVITY_MODAL_DATA';
export const CHANGE_OVERLAY_START_TIME = 'CHANGE_OVERLAY_START_TIME';
export const CHANGE_OVERLAY_END_TIME = 'CHANGE_OVERLAY_END_TIME';
export const CHANGE_SHOW_ONLY_REPLACE_BUTTON_STATUS = 'CHANGE_SHOW_ONLY_REPLACE_BUTTON_STATUS';
export const SHOW_TIP_IN_EDIT_ACTIVITY_MODAL = 'SHOW_TIP_IN_EDIT_ACTIVITY_MODAL';
export const CHANGE_ERROR_STATUS_IN_EDIT_MODAL = 'CHANGE_ERROR_STATUS_IN_EDIT_MODAL';
export const EDIT_ACTIVITY_MODAL_REQUEST_LOADING = 'EDIT_ACTIVITY_MODAL_REQUEST_LOADING';
export const EDIT_ACTIVITY_MODAL_REQUEST_OK = 'EDIT_ACTIVITY_MODAL_REQUEST_OK';
export const EDIT_ACTIVITY_MODAL_REQUEST_ERROR = 'EDIT_ACTIVITY_MODAL_REQUEST_ERROR';
export const CHANGE_EDIT_ACTIVITY_MODAL_PARTNER = 'CHANGE_EDIT_ACTIVITY_MODAL_PARTNER';
export const LOAD_ADD_ACTIVITY_MODAL_DATA = 'LOAD_ADD_ACTIVITY_MODAL_DATA';
export const ADD_PARTNER_MODAL_CHANGE_LINK = 'ADD_PARTNER_MODAL_CHANGE_LINK';
export const ADD_PARTNER_MODAL_POST_ERROR = 'ADD_PARTNER_MODAL_POST_ERROR';
export const CHANGE_ADD_ACTIVITY_MODAL_TIME_RANGE = 'CHANGE_ADD_ACTIVITY_MODAL_TIME_RANGE';
export const ADD_ACTIVITY_MODAL_SHOW_DATE_PICKER = 'ADD_ACTIVITY_MODAL_SHOW_DATE_PICKER';
export const CHANGE_EDIT_ACTIVITY_MODAL_TIME_RANGE = 'CHANGE_EDIT_ACTIVITY_MODAL_TIME_RANGE';
