import React from 'react';
import { List, Space } from 'antd';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';

import { UserPinnedActivityOutput } from 'api/Partners/types';
import scrollbar from 'helpers/styles/components/scrollbar.module.sass';

import S from './helpers/styles.module.sass';
import ReplaceHourActivitiesListProps from './helpers/types';
import ReplaceHourActivityListItem from '../ReplaceHourActivityListItem/ReplaceHourActivityListItem';

const ReplaceHourActivitiesList: React.FC<ReplaceHourActivitiesListProps> = ({ activities, handleSave }) => {
  const renderItem = (activityObject: UserPinnedActivityOutput) => (
    <ReplaceHourActivityListItem
      activityObject={activityObject}
      handleSave={handleSave}
    />
  );

  const emptyActivitiesFooter = () => (
    <Space direction="vertical" className={S.emptyActivitiesFooter} size={0}>
      <span className={S.emptyActivitiesFooterTitle}>No current activities.</span>
      <span className={S.emptyActivitiesFooterDesc}>Select partner’s activity from the list below</span>
    </Space>
  );

  return (
    <div className={S.activitiesList}>
      <h4 className={S.activityListTitle}>Pinned Activities</h4>
      <Scrollbars
        autoHeight
        autoHeightMax={500}
        className={classNames(scrollbar.customScrollbar, S.activitiesCustomScrollbar)}
        renderThumbVertical={() => <div className={scrollbar.thumbVertical} />}
      >
        <List
          className={S.activityList}
          itemLayout="horizontal"
          dataSource={activities}
          renderItem={renderItem}
          locale={{ emptyText: _.isEmpty(activities) ? <div /> : emptyActivitiesFooter() }}
        />
      </Scrollbars>
    </div>
  );
};

export default ReplaceHourActivitiesList;
