import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CustomTagProps } from 'rc-select/lib/interface/generator';

import S from './helpers/styles.module.sass';
import { DispatchType } from '../../../../../../../../helpers/types/_common';
import { CategoryOptionsObject } from '../../../../../../../ProfileTabContent/components/SkillSet/components/SkillSetCategory/components/SkillSetExperience/components/SkillSearcher/helpers/types';
import { getSkillsByCategoryAndName } from '../../../../../../../../redux/SkillSet/action';
import SkillTag from '../../../../../../../_shared/SkillTag';
import { ExperienceSelectProps, ExperienceValue } from './helpers/types';
import useDebounce from '../../../../../../../../helpers/hooks/useDebounce';
import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';

const cx = classNames.bind(S);

const ExperienceSelect: React.FC<ExperienceSelectProps> = (props) => {
  const { onChangeExperience, settings, experience, onExperienceBlur, setSelectFocus, selectFocus } = props;

  const dispatch: DispatchType = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>();
  const [ isSelectOpen, setSelectOpen ] = useState(false);
  const [ optionsWithPagination, setOptionsWithPagination ] = useState<CategoryOptionsObject>(DEFAULT_SELECT_PAGINATION);

  const debounceSearchValue = useDebounce(searchValue, 600);

  const filteredSkills = _.reduce(settings.skills, (acc, value, key) => {
    if (value.experience === experience.filterKey) {
      return [ ...acc, { value: Number(key), label: value.name } ];
    }

    return acc;
  }, [] as any);

  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      getCategory(debounceSearchValue);
    }
  }, [ debounceSearchValue ]);

  const getCategory = (skillName?: string) => {
    setLoading(true);
    dispatch(getSkillsByCategoryAndName(skillName || ''))
      .then((options: CategoryOptionsObject) => setOptionsWithPagination(options))
      .finally(() => setLoading(false));
  };

  const handleOnFocus = () => {
    setSelectFocus(true);

    if (!_.isEmpty(optionsWithPagination?.objects) || !_.isEmpty(searchValue)) {
      return;
    }

    getCategory();
  };

  const renderTags = (props: CustomTagProps) => (
    <SkillTag
      className={cx('experience-skill-tag', { focused: selectFocus })}
      title={props.label}
      onClose={props.onClose}
    />
  );

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin
      spinning={loading}
    >
      {menu}
    </Spin>
  );

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (optionsWithPagination?.meta?.totalCount === optionsWithPagination?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(optionsWithPagination?.meta?.offset) ? optionsWithPagination.meta.offset + DEFAULT_SELECT_PAGINATION.meta.limit : 0;

      setLoading(true);
      dispatch(getSkillsByCategoryAndName('', undefined, increasedPageByOne))
        .then((options: CategoryOptionsObject) => {
          setOptionsWithPagination({
            meta: options.meta,
            objects: [ ...optionsWithPagination.objects, ...options.objects ],
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onSelect = (value: ExperienceValue) => {
    if (!_.isEmpty(searchValue)) {
      getCategory();
    }

    onChangeExperience({
      ...settings.skills,
      [value.value]: {
        experience: experience.filterKey,
        name: value.label,
      },
    });

    setSearchValue(undefined);
  };

  const onDeselect = (value: ExperienceValue) => {
    if (!isSelectOpen) {
      onExperienceBlur(_.omit(settings.skills, value.value));
    }

    onChangeExperience(_.omit(settings.skills, value.value));
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      onExperienceBlur(settings.skills);
    }

    setSelectOpen(open);
  };

  const options = _.map(optionsWithPagination.objects, skill => (
    <Select.Option
      key={skill.skillId || skill.name}
      value={skill.skillId}
      name={skill.name}
    >
      {skill.name}
    </Select.Option>
  ));

  return (
    <Select
      dropdownAlign={{ offset: [ 0, 7 ] }}
      value={filteredSkills}
      labelInValue
      className={cx('specialists-settings_exp-select')}
      dropdownClassName={cx('specialists-settings_dropdown', { focused: selectFocus })}
      bordered={false}
      mode="multiple"
      onFocus={handleOnFocus}
      tagRender={renderTags}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      disabled={!experience.filterKey}
      placeholder="Type skill name to add..."
      showArrow
      searchValue={searchValue}
      onSearch={onSearch}
      filterOption={() => true}
      onPopupScroll={onPopupScroll}
      dropdownRender={dropdownRender}
      onBlur={() => {
        setSelectFocus(false);
        onExperienceBlur(settings.skills);
      }}
    >
      {options}
    </Select>
  );
};

export default ExperienceSelect;
