import _ from 'lodash';
import qs from 'query-string';

import { api } from 'index';

import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import {
  AllUsersRequest,
  ChangeMonthBody,
  MonthButtonBody,
  UserDetails,
  UserMonthOutput,
  UserSkillsBody,
  UserStatisticBody,
  UserStatisticMassUpdateFields,
  UserStatisticPage,
  UserStatisticUpdateFields,
  VacationReport,
  VacationReportBody,
  VacationReportPage,
} from './types';
import { convertUserStatisticsBody } from './helpers';
import { NotificationCreate } from '../Notifications/types';
import { EMPLOYEE_TABS } from './constants';
import { PreferencesBody } from '../../redux/User/types';

const USER_REQUESTS = 'userRequests';

export const getUserById = async (userId: number) => api.get<UserDetails>(`/users/${userId}/`, {}, { requestId: `${USER_REQUESTS}getUserById` });

export const getAllUsers = async (body: any) => api.get<AllUsersRequest>('/users/', {
  ...convertObjectKeysToLanguageCase(body, 'snakeCase'),
  exclude_usertype: EMPLOYEE_TABS.BOT.radioValue,
}, {
  requestId: 'commonRequests',
});

export const getUsersStatistics = (body: UserStatisticBody) => api.get<UserStatisticPage>('/users/statistics/', {
  ...convertUserStatisticsBody(body),
  email_address_contains: body.fullnameContains,
  username_contains: body.fullnameContains,
  slack_member_id_contains: body.fullnameContains,
}, {
  requestId: `${USER_REQUESTS}getUsersStatistics`,
  paramsSerializer: (params: object) => qs.stringify(params, { skipNull: true }),
});

export const getUserStatisticsById = async (userId: number) => api.get(`/users/${userId}/statistics/`, {}, { requestId: `${USER_REQUESTS}getUserStatisticsById` });

export const getUserSkills = async (params: UserSkillsBody) => {
  const { userId, limit, offset, orderBy, nameContains } = params;

  return api.get(`/users/${userId}/skills/`, {
    order_by: orderBy || 'start_time',
    order_direction: 'ASC',
    limit,
    offset,
    ...(nameContains ? { name_contains: nameContains } : {}),
  }, { requestId: `${USER_REQUESTS}getUserSkills` });
};

export const sendNotificationToUser = (body: NotificationCreate) => {
  const { userId, content, type } = body;

  return api.post(`/notifications/users/${userId}/`, {
    type,
    content,
  });
};

export const changeMonthStatus = (body: ChangeMonthBody) => {
  const { userId, year, month, isMonthClosed } = body;

  return api.patch(`/users/${userId}/months/${year}/${month}/`, {
    closed: isMonthClosed,
  });
};

export const getMonthStatus = (body: MonthButtonBody) => {
  const { userId, year, month } = body;

  return api.get<UserMonthOutput>(`/users/${userId}/months/${year}/${month}/`);
};

export const createNewUser = (userData: UserStatisticUpdateFields) => api.post('/users/', convertObjectKeysToLanguageCase(userData, 'snakeCase'));

export const updateExistingUser = (userData: UserStatisticUpdateFields, userId: number) => api.patch(`/users/${userId}/`, convertObjectKeysToLanguageCase(userData, 'snakeCase'));
export const massUserUpdateStatus = (userData: UserStatisticMassUpdateFields) => api.put('/users/activations/', convertObjectKeysToLanguageCase(userData, 'snakeCase'));

export const getVacationReport = async (body: VacationReportBody) => {
  const { year, showAll, searchValue } = body;

  const url = showAll ? '/bookkeeping/reports/vacations/' : `/bookkeeping/reports/vacations/${year}/`;

  return api.get<VacationReportPage>(url, {
    ...convertObjectKeysToLanguageCase(_.omit(body, [ 'year', 'showAll', 'searchValue', 'active' ]), 'snakeCase'),
    ...(!body.active ? {} : { active: true }),
    fullname_contains: searchValue,
    username_contains: searchValue,
    email_address_contains: searchValue,
  }, {
    requestId: `${USER_REQUESTS}getVacationReport`,
    paramsSerializer: (params: object) => qs.stringify(params),
  });
};

export const getUserVacation = async (userId: number) => api.get<VacationReport>(`/users/${userId}/reports/vacations/`);

export const globalLogout = (userId: number) => api.delete(`/users/${userId}/sessions/`);

export const updateUserPreferences = (userId: number, body: PreferencesBody) => api.patch(`/users/${userId}/preferences/`, convertObjectKeysToLanguageCase(body, 'snakeCase'));
