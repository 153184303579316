import qs from 'query-string';
import _ from 'lodash';

import {
  ActivitySpecialistRequestsPage,
  BaseRequiredSkillOutput,
  CreateNewRequestBody,
  FindSpecialistsBody,
  FoundSpecialistPage,
  GetPartners,
  GetSpecialistsBody,
  RequestSpecialistsBody,
  RequiredSkillPage,
  SetCandidatesStatusBody,
  SetRequestSpecialistsInfoBody,
} from './types';
import {
  convertCandidatesByMatchedByKey,
  convertFindSpecialistsBody,
  convertObjectsByMatchedByKey,
  convertRequestDetailsBody,
  parseRequestSkills,
} from './helpers';
import { RequestsSettings } from '../../components/Specialists/components/Requests/components/PartnersList/helpers/types';
import { convertObjectKeysToLanguageCase } from '../../helpers/utils/convertObject';
import { Partner } from '../../helpers/types/Partners';
import { DEFAULT_PAGINATION_RAW } from '../../helpers/constants/_common/Pagination';
import { SpecialistsSkills } from '../../components/Specialists/helpers/types';
import { SKILLS_EXPERIENCE_MAPPER } from './constants';
import { DefaultSREditValues } from '../../components/Specialists/components/Requests/components/RequestsTable/components/EditSRModal/helpers/types';
import { api } from '../../index';
import { Meta } from '../_request/types';
import { PartnerCardOutput } from '../Partners/types';

export const REQUEST_API = 'requestApi';

const getAllPartners = (settings: RequestsSettings, withCleaning: boolean) => api.get('/partners/', {
  active: true, // we need to show only active users, i guess
  order_by: 'name',
  limit: settings.pageSize,
  offset: withCleaning ? 0 : settings.offset + settings.pageSize,
  ...(settings.nameContains ? { name_contains: settings.nameContains } : {}), // it's necessary to send empty string to search by
})
  .then((res: GetPartners) => ({
    meta: res.meta,
    objects: _.reduce((res.objects), (r, partner) => [ ...r, partner ], [] as (Partner | PartnerCardOutput)[]) as Partner[],
  }));

export const getFindSpecialists = async (body: FindSpecialistsBody) => {
  const response = await api.get('/skills/specialists/', convertFindSpecialistsBody(body),
    {
      paramsSerializer: (params: object) => qs.stringify(params),
      requestId: `${REQUEST_API}.getFindSpecialists`,
    });

  return {
    meta: response.meta,
    objects: convertObjectsByMatchedByKey(response.objects),
  };
};

const deleteSpecialRequest = (specialRequestId: number) => api.delete(`/specialist_requests/${specialRequestId}/`);

const editSpecialRequest = (body: DefaultSREditValues, specialRequestId: number) => api.patch(`/specialist_requests/${specialRequestId}/`, body);

const getSRByPartnerId = (partnerId: number, paginationProps: any) => api.get<ActivitySpecialistRequestsPage>(`/partners/${partnerId}/activities/specialist_requests/`, paginationProps);

export const getRequestSpecialists = async (body: GetSpecialistsBody) => {
  const requestBody = _.omit(body, 'specialistRequestId');

  const response = await api.get<FoundSpecialistPage>(`/specialist_requests/${body.specialistRequestId}/specialists/`,
    convertRequestDetailsBody(requestBody),
    { paramsSerializer: (params: object) => qs.stringify(params) });

  return {
    meta: response.meta,
    objects: convertObjectsByMatchedByKey(response.objects),
  };
};

export const createNewRequest = (body: CreateNewRequestBody) => api.post('/specialist_requests/', convertObjectKeysToLanguageCase(body, 'snakeCase'));

export const getRequestCandidates = async (body: Omit<RequestSpecialistsBody, 'specialistRequestId'>, requestId: string) => {
  const response = await api.get(`/specialist_requests/${requestId}/users/`,
    convertRequestDetailsBody(body));

  return {
    meta: response.meta,
    objects: convertCandidatesByMatchedByKey(response.objects),
  };
};

export const getRequestInfo = (requestId: string) => api.get(`/specialist_requests/${requestId}/`);

export const getRequestSkills = async (requestId: string) => {
  const skills: BaseRequiredSkillOutput[] = [];

  const recursivelySkillRequest: any = (pagination: any) => api.get<RequiredSkillPage>(`/specialist_requests/${requestId}/skills/`, {
    order_direction: 'ASC',
    ...pagination,
  })
    .then((response: { meta: Meta, objects: BaseRequiredSkillOutput[] }) => {
      skills.push(...response.objects);

      if (!response?.meta?.totalCount) {
        return;
      }

      if (response.meta.totalCount > skills.length) {
        return recursivelySkillRequest({ limit: response.meta.limit, offset: (response.meta.offset || 0) + (response.meta.limit || 0) });
      }
    });

  await recursivelySkillRequest({ limit: DEFAULT_PAGINATION_RAW.limit, offset: DEFAULT_PAGINATION_RAW.offset });

  return parseRequestSkills(skills);
};

export const setSkillsExperienceParams = async (skills: SpecialistsSkills, specialistsRequestId: string) => api.put(`/specialist_requests/${specialistsRequestId}/skills/`,
  {
    skills: _.map(skills, (s, key) => ({ skill_id: key, experience: SKILLS_EXPERIENCE_MAPPER[s.experience] })),
  });

export const setRequestSpecialistsInfo = async (body: SetRequestSpecialistsInfoBody, requestId: string) => api.patch(`/specialist_requests/${requestId}/`, convertObjectKeysToLanguageCase(body, 'snakeCase'));

export const setCandidatesStatus = async (body: SetCandidatesStatusBody) => api.post(`/specialist_requests/${body.specialistRequestId}/users/${body.userId}/`);

export const removeCandidatesStatus = async (body: SetCandidatesStatusBody) => api.delete(`/specialist_requests/${body.specialistRequestId}/users/${body.userId}/`);

export default {
  createNewRequest,
  getAllPartners,
  getFindSpecialists,
  getSRByPartnerId,
  getRequestSpecialists,
  getRequestCandidates,
  setSkillsExperienceParams,
  setRequestSpecialistsInfo,
  getRequestInfo,
  deleteSpecialRequest,
  editSpecialRequest,
};
