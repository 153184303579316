import React from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';

import { BCJointStatus, BCStatuses } from 'api/TimeSheet/types';
import TABLE_CELLS from 'components/TimeSheet/components/Table/helpers/constants';
import { CellType, EmptyCell, GroupOfCells } from 'components/TimeSheet/helpers/types';

import { ColumnProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import CellGroup from '../../../CellGroup/CellGroup';
import Cell from '../../../Cell/Cell';

const cx = classNames.bind(S);

const ColumnBody: React.FC<ColumnProps> = (props) => {
  const {
    dayObj,
    prevDayObj,
    userHoursTable,
    isMonthClosed,
    isBCLoading,
    groupOfCellsContextMenu,
    curatorOnlyView,
  } = props;
  const { day, status, jointStatus } = dayObj;

  const isWeekend = status !== BCStatuses.Regular;
  const isCut = jointStatus === BCJointStatus.Cut;
  const isPrevWeekend = prevDayObj && prevDayObj.status !== BCStatuses.Regular;
  const isPrevCut = prevDayObj && prevDayObj.jointStatus === BCJointStatus.Cut;
  const tableColumn = userHoursTable[day];

  const cellsToRender = tableColumn?.cells && !isBCLoading ? tableColumn?.cells : TABLE_CELLS;

  const renderEmptyCell = (cellObj: EmptyCell) => (
    <Cell
      cellObj={cellObj}
      key={`${day}-${cellObj.cellIndex}-empty`}
      day={day}
      isCut={isCut}
      isMonthClosed={isMonthClosed}
      curatorOnlyView={curatorOnlyView}
    />
  );

  const renderGroupOfStoredCell = (cellObj: GroupOfCells) => (
    <CellGroup
      key={`${day}-${cellObj.cellIndex}-grouped`}
      day={day}
      isCut={isCut}
      cellObj={cellObj}
      groupOfCellsContextMenu={groupOfCellsContextMenu}
      isMonthClosed={isMonthClosed}
      curatorOnlyView={curatorOnlyView}
    />
  );

  const renderCell = (cellObj: GroupOfCells | EmptyCell) => {
    if (cellObj?.type === CellType.Empty) {
      return renderEmptyCell(cellObj as EmptyCell);
    } else {
      return renderGroupOfStoredCell(cellObj as GroupOfCells);
    }
  };

  return (
    <div
      id={`column-body-${dayObj.day}`}
      className={cx(S.columnBody, {
        [S.weekend]: isWeekend && !isCut,
        [S.afterWeekend]: isPrevWeekend && !isPrevCut,
        [S.cutDay]: isCut,
        [S.afterCut]: isPrevCut,
        [S.closedMonth]: isMonthClosed,
      })}
    >
      {_.map(cellsToRender, cellObj => renderCell(cellObj))}
    </div>
  );
};

export default ColumnBody;
