import _ from 'lodash';

import { CuratorPartnerDetailedReports, FrontCuratorPartnerDetailedReports } from './types';

export const convertCuratorReportResponse = (response: CuratorPartnerDetailedReports): FrontCuratorPartnerDetailedReports => ({
  hours: response.hours,
  partners: _.map(response.partners, partner => ({
    partner: partner.partner,
    hours: partner.hours,

    children: _.map(partner.activities, activity => ({
      hours: activity.hours,
      partner: partner.partner,
      activity: activity.activity,

      children: _.map(activity.users, user => ({
        user: user.user,
        activity: activity.activity,
        hours: user.hours,
      })),
    })),
  })),
});
