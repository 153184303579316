import cx from 'classnames';
import React, { useState } from 'react';
import _ from 'lodash';
import { Select, Spin } from 'antd';
import { Link } from 'react-router-dom';

import PROFILE_TABS from 'helpers/constants/_common/tabs/profile';
import selects from 'helpers/styles/components/selects.module.sass';
import { DEFAULT_SELECT_PAGINATION } from 'helpers/constants/_common/Pagination';
import { getUserSkills } from 'api/User/requests';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { PATH_PROFILE } from 'helpers/constants/_common/MenuCases';

import s from './helpers/styles.module.sass';
import { SkillsWithPagination } from './helpers/types';
import { InitialUserValues } from '../../helpers/types';

interface SelectLazySkillsProps {
  isEdit: boolean;
  editByAdmin?: boolean;
  onChangePosition: (value: any, fieldName: keyof InitialUserValues) => void;
  userValues: InitialUserValues;
  userId: number;
}

const SelectLazySkills: React.FC<SelectLazySkillsProps> = ({ isEdit, onChangePosition, userValues, userId, editByAdmin }) => {
  const [ skillsWithPagination, setSkillsWithPagination ] = useState<SkillsWithPagination>({});
  const [ skillLoading, setSkillLoading ] = useState(false);
  const [ searchSkillName, setSearchSkillName ] = useState('');

  const getSkills = (searchValue?: string) => {
    setSkillLoading(true);
    getUserSkills({
      orderBy: 'skill_name',
      userId,
      limit: DEFAULT_SELECT_PAGINATION.meta.limit,
      offset: 0,
      nameContains: searchValue,
    })
      .then((skills: SkillsWithPagination) => setSkillsWithPagination(skills))
      .finally(() => setSkillLoading(false));
  };

  const searchBySkillName = _.debounce((searchSkillName: string) => {
    setSearchSkillName(searchSkillName);
    getSkills(searchSkillName);
  }, 500);

  const handleOnFocus = () => {
    if (!_.isEmpty(skillsWithPagination?.objects)) {
      return;
    }

    getSkills();
  };

  const handleChangeMainSkill = (value: string, option: any) => {
    if (option?.skill) {
      onChangePosition({ ...option.skill, title: undefined }, 'primarySkill');
    }

    getSkills('');
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.persist();
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (skillsWithPagination?.meta?.totalCount === skillsWithPagination?.objects?.length) {
        return;
      }

      const increasedPageByOne = _.isNumber(skillsWithPagination?.meta?.offset)
        ? (skillsWithPagination?.meta?.offset || 0) + DEFAULT_SELECT_PAGINATION.meta.limit
        : 0;

      setSkillLoading(true);
      getUserSkills({
        orderBy: 'skill_name',
        userId,
        limit: DEFAULT_SELECT_PAGINATION.meta.limit,
        offset: increasedPageByOne,
      })
        .then((options: any) => {
          setSkillsWithPagination({
            meta: options.meta,
            objects: skillsWithPagination.objects ? [ ...skillsWithPagination.objects, ...options.objects ] : options.objects,
          });
        })
        .finally(() => setSkillLoading(false));
    }
  };

  const dropdownRender = (menu: React.ReactElement) => (
    <Spin spinning={skillLoading} size="small">
      {menu}
    </Spin>
  );

  const options = _.map(skillsWithPagination.objects, s => <Select.Option key={s.skill.name} value={s.skill.name} skill={s}>{s.skill.name}</Select.Option>);

  const notFoundListContent = () => {
    if (!editByAdmin && skillsWithPagination?.objects && _.isArray(skillsWithPagination.objects) && _.isEmpty(skillsWithPagination?.objects)) {
      return (
        <p className={s.noSkillsWrapper} style={{ color: '#000' }}>
          <span>No skills to pick from.</span>
          <Link to={`${PATH_PROFILE}/${PROFILE_TABS.skillset.path}`}>
            Please, update your skillset first
          </Link>
        </p>
      );
    }

    return undefined;
  };

  const notFoundSearchContent = () => {
    if (!editByAdmin && skillsWithPagination?.objects && _.isArray(skillsWithPagination.objects) && _.isEmpty(skillsWithPagination?.objects)) {
      return (
        <p className={s.noSkillsWrapper} style={{ color: '#000' }}>
          <span>No skills to pick from.</span>
          <span>Please, refine your search request.</span>
        </p>
      );
    }

    return undefined;
  };

  return (
    <Select
      placeholder={isEdit ? 'Choose main skill...' : 'Not specified'}
      onChange={handleChangeMainSkill}
      className={cx(selects.qsSelect, s.profileSelect)}
      dropdownClassName={selects.selectDropdown}
      size="large"
      bordered={false}
      onFocus={handleOnFocus}
      onPopupScroll={onPopupScroll}
      value={userValues.primarySkill?.skill?.name}
      suffixIcon={<SelectIcon />}
      dropdownRender={dropdownRender}
      disabled={!isEdit}
      loading={skillLoading}
      showSearch
      filterOption={() => true}
      onSearch={searchBySkillName}
      autoClearSearchValue
      getPopupContainer={triggerNode => triggerNode.parentElement}
      dropdownAlign={{
        points: [ 'br', 'tr' ],
        offset: [ 0, -4 ],
      }}
      notFoundContent={searchSkillName.length ? notFoundSearchContent() : notFoundListContent()}
    >
      {options}
    </Select>
  );
};

export default SelectLazySkills;
