import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Meta, Sorter } from 'api/_request/types';

import SpecialistsSettings from './components/SpecialistsSettings';
import SpecialistsTable from './components/SpecialistsTable';
import { FindSpecialistsSettings } from './helpers/types';
import { SettingsValue } from './components/SpecialistsSettings/helpers/types';
import { TABLE_NAMES } from '../../../../helpers/constants/Tables/tableList';
import { getFindSpecialists, resetSkillColumnSorter } from '../../../../redux/Specialists/actions';
import { DispatchType, EnglishLevelEnum, Redux } from '../../../../helpers/types/_common';
import { defaultSkillsState } from './helpers/helpers';
import { SpecialistsSkills } from '../../helpers/types';
import { parseSkillsByType } from '../../helpers/helpers';

interface FindSpecialistsProps {
  isProfilesViewerMode: boolean;
}

const FindSpecialistsTab: React.FC<FindSpecialistsProps> = ({ isProfilesViewerMode }) => {
  const dispatch: DispatchType = useDispatch();

  const [ settings, setSettings ] = useState<FindSpecialistsSettings>(() => defaultSkillsState());
  const { sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.FIND_SPECIALISTS]);

  const fetchSpecialists = (pagination: Meta, sorter?: Sorter) => {
    const body = {
      englishKnowledge: settings?.englishKnowledge,
      occupation: settings?.occupation,
      entityId: settings.entityId,
      userId: settings.userId,
      partnerId: settings.partnerId,
      fullnameContains: settings?.contains,
      usernameContains: settings?.contains,
      emailAddressContains: settings?.contains,
      active: true,
      ...parseSkillsByType(settings.skills),
      ...pagination,
      ...sorter,
    };
    return dispatch(getFindSpecialists(body, TABLE_NAMES.FIND_SPECIALISTS));
  };

  const onExperienceBlur = (updatedSkills?: SpecialistsSkills) => {
    if (!_.isEqual(settings.skillsColumns, updatedSkills)) {
      if (sorter?.orderBy && updatedSkills && !updatedSkills[Number(sorter.orderBy)]) {
        dispatch(resetSkillColumnSorter(TABLE_NAMES.FIND_SPECIALISTS));
      }

      setSettings(prevSettings => ({
        ...prevSettings,
        skillsColumns: updatedSkills || prevSettings.skills,
      }));
    }
  };

  const onClearSettings = () => {
    if (_.isEmpty(settings.skills)
      && settings.englishKnowledge === EnglishLevelEnum.Zero
      && _.isUndefined(settings.contains)
      && _.isUndefined(settings.occupation)) {
      return;
    }

    dispatch(resetSkillColumnSorter(TABLE_NAMES.FIND_SPECIALISTS));
    setSettings({ skills: {}, englishKnowledge: 'ZERO', skillsColumns: {}, occupation: undefined });
  };

  const onChangeSettings = (value: SettingsValue) => {
    setSettings({
      ...settings,
      ...value,
    });
  };

  const onChangeExperienceSettings = (value: SpecialistsSkills) => {
    setSettings({
      ...settings,
      skills: {
        ...value,
      },
    });
  };

  return (
    <div>
      <SpecialistsSettings
        settings={settings}
        onChange={onChangeSettings}
        onClear={onClearSettings}
        onChangeExperience={onChangeExperienceSettings}
        onExperienceBlur={onExperienceBlur}
        isProfilesViewerMode={isProfilesViewerMode}
      />

      <SpecialistsTable
        settings={settings}
        fetchSpecialists={fetchSpecialists}
        isProfilesViewerMode={isProfilesViewerMode}
      />
    </div>
  );
};

export default FindSpecialistsTab;
