export const BASE_PIE_OPTIONS = {
  legend: {
    top: '5%',
    left: 'center',
  },
  color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: [ '40%', '70%' ],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: false,
          fontSize: '20',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 50, name: 'Overused vacation', itemStyle: { color: '#3ba272' } },
        { value: 192, name: 'Available vacation', itemStyle: { color: '#98fb98' } },
        // { value: 18, name: 'Overused vacation', itemStyle: { color: '#F4536E' } },
      ],
    },
  ],
};
