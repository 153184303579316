import BusinessCalendar from 'components/BusinessCalendar/BusinessCalendar';
import Logs from 'V1/components/Pages/v1/AdminPanel/Logs';
import Entities from 'components/Entities/Entities';
import BookkeepingItems from 'components/BookkeepingItems/BookkeepingItems';

import {
  TAB_BUSINESS_CALENDAR,
  TAB_ENTITIES,
  TAB_LOGS,
  TAB_SKILL_TAGS,
  TAB_BOOKKEEPING_ITEMS,
} from '../MenuCases';
import SkillTags from '../../../../components/SkillTags';
import { SidebarTabs } from './types';

const TOOLS_TABS: SidebarTabs = {
  'skill-tags': {
    path: TAB_SKILL_TAGS,
    name: 'Skill tags',
    component: SkillTags,
  },
  logs: {
    path: TAB_LOGS,
    name: 'Logs',
    component: Logs,
  },
  'bookkeeping-items': {
    path: TAB_BOOKKEEPING_ITEMS,
    name: 'Bookkeeping Items',
    component: BookkeepingItems,
  },
  entities: {
    path: TAB_ENTITIES,
    name: 'Entities',
    component: Entities,
  },
  'business-calendar': {
    path: TAB_BUSINESS_CALENDAR,
    name: 'Business Calendar',
    component: BusinessCalendar,
  },
};

export default TOOLS_TABS;
