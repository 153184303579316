import * as notificationRequests from 'api/Notifications/requests';

import * as NotificationActions from '../../V1/constants/Notification';
import { DispatchType } from '../../helpers/types/_common';
import { NotificationResponse } from '../../api/Notifications/types';

export const getUnreadFields = (userId: number) => (dispatch: DispatchType) => {
  dispatch({ type: NotificationActions.GET_UNREAD_FIELDS, payload: {}, loading: true });

  return notificationRequests.getUnreadFields(userId)
    .then((response: NotificationResponse) => {
      dispatch({ type: NotificationActions.GET_UNREAD_FIELDS, payload: response, loading: false });
    }, () => {
      dispatch({ type: NotificationActions.GET_UNREAD_FIELDS, payload: {}, loading: false });
    });
};
