import { PartnerMaskType } from 'api/Partners/types';

export interface PanelsDataProps {
  activePanel: MobileTimesheetPanel;
  isInProgress: boolean;
}

export interface UserHourData {
  startTime: string;
  endTime: string;
  comment: string;
  activity: ChosenActivityInfo;
}

export interface ChosenActivityInfo {
  activityId: number;
  partnerId: number;
  mask: PartnerMaskType;
  partnerName: string;
  activityName: string;
  partnerColor: string;
  activityColor: string;
}

export enum MobileTimesheetPanel {
  Regular = 'REGULAR',
  ChooseActivity = 'CHOOSE_ACTIVITY',
  HourDetails = 'HOURS_DETAILS',
  UserHoursList = 'USER_HOURS_LIST',
}
