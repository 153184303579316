import * as Actions from '../constants/Occupations';
import Subtypes from '../constants/__common__/Subtypes';

const InitialState = {
  objects: [],
  gettingOccupationsIsInProgress: false,
  errorMessage: '',
  mainTable: {
    objects: [],
    isLoading: false,
    errorMessage: '',
  },
  occupationNameToFilter: '',
  showOccupationsModal: false
};

function reducer(state = InitialState, action) {
  switch(action.type) {
    case Actions.OCCUPATIONS_INITIATE:
      return InitialState;

    case Actions.GET_OCCUPATIONS:
      return getOccupations(action, state);

    case Actions.CHANGE_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}

const getOccupations = (action, state) => {
  switch (action.subtype) {
    case Subtypes.Request.Loading:
      return {...state, mainTable: {...state.mainTable, isLoading: true, errorMessage: ''}};
    case Subtypes.Request.Success:
      return {...state, mainTable: {...state.mainTable, isLoading: false, errorMessage: '', objects: action.payload}};
    case Subtypes.Request.Error:
      return {...state, mainTable: {...state.mainTable, isLoading: false, errorMessage: action.payload}};
    default: return state;
  }
};


export default {
  InitialState,
  reducer
}
