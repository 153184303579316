import { Select } from 'antd';
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import EXPERIENCE_FILTERS from '../../../../../../../../helpers/constants/Skills/ExpFilters';
import { ExperienceFilterNames, ExpFiltersEnum } from '../../../../../../../../helpers/types/Skills';
import S from './helpers/styles.module.sass';
import ExperienceSelect from '../ExperienceSelect';
import { ReactComponent as SelectIcon } from '../../../../../../../../helpers/icons/SelectArrow.svg';
import { ExperienceFieldProps } from './helpers/types';
import { SpecialistsSkills } from '../../../../../../helpers/types';

const cx = classNames.bind(S);
const UNKNOWN_EXPERIENCE = {
  name: 'Select experience',
  filterKey: null,
};

const ExperienceField: React.FC<ExperienceFieldProps> = (props) => {
  const {
    specifiedExperiences,
    currentExpFilterKey,
    fieldIndex,
    settings,
    onChangeExperience,
    changeExpFilters,
    removeExperienceFilter,
    onExperienceBlur,
  } = props;

  const [ selectFocus, setSelectFocus ] = useState(false);

  const currentExperience = EXPERIENCE_FILTERS[_.findKey(EXPERIENCE_FILTERS, exp => exp?.filterKey === currentExpFilterKey) as ExpFiltersEnum]
   || UNKNOWN_EXPERIENCE;
  const expIsAny = currentExperience.filterKey === ExperienceFilterNames.notSet;

  const onExperienceFilterChange = (filterKeyToChange: ExperienceFilterNames) => {
    const needToMerge = specifiedExperiences.includes(filterKeyToChange);
    const changingToNotSet = filterKeyToChange === ExperienceFilterNames.notSet;
    const updatedSpecifiedExperiences = specifiedExperiences.reduce((r, expFilter, i) => {
      if ((i === fieldIndex) && !changingToNotSet) return [ ...r, filterKeyToChange ]; // just rename exp by index (but not to notSet filter)
      if (needToMerge && !changingToNotSet && (expFilter === filterKeyToChange)) return r; // if we change exp to existed one -- we need to remove existed
      if ((i === fieldIndex) && changingToNotSet) {
        return r; // if we change exp to notSet -- remove this exp (not remove existed notSet and merge his skills to this one)
      }

      return [ ...r, expFilter ];
    }, [] as (ExperienceFilterNames | null)[]);

    const updatedSkills: SpecialistsSkills = _.mapValues(settings.skills, (s) => {
      if (s.experience === currentExpFilterKey) {
        return ({
          ...s,
          experience: filterKeyToChange,
        });
      }

      return s;
    });

    onChangeExperience(updatedSkills);
    changeExpFilters(updatedSpecifiedExperiences);
    onExperienceBlur(updatedSkills);
  };

  const renderOptions = (unknownExperience: boolean) => (Object.keys(EXPERIENCE_FILTERS) as (ExpFiltersEnum)[]).map((f) => {
    const experience = EXPERIENCE_FILTERS[f];

    if (!experience) return null;

    const alreadyExist = experience.filterKey === currentExperience.filterKey;
    const anyDisabledForUnknown = experience.filterKey === ExperienceFilterNames.notSet && unknownExperience;

    return (
      <Select.Option
        key={experience.experienceId}
        value={experience.filterKey}
        label={experience.label}
        disabled={alreadyExist || anyDisabledForUnknown}
      >
        {experience.additionalName || experience.label}
      </Select.Option>
    );
  });

  const renderSpecifiedExperience = () => (
    <div className={cx('set-experience-wrapper', { focused: selectFocus })}>
      <div className={cx('set-experience-wrapper_title')}>
        <Select
          value={currentExperience.filterKey}
          className={cx('set-experience-wrapper_select')}
          onChange={onExperienceFilterChange}
          suffixIcon={<SelectIcon />}
          disabled={expIsAny}
          optionLabelProp="label"
          size="small"
          dropdownMatchSelectWidth={false}
          showArrow={!expIsAny}
          placeholder="Select experience"
          bordered={false}
        >
          {renderOptions(!currentExperience.filterKey)}
        </Select>
        {!expIsAny && (
          <button
            type="button"
            className={cx('set-experience-wrapper_button')}
            onClick={() => removeExperienceFilter(currentExperience.filterKey)}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
      <ExperienceSelect
        onChangeExperience={onChangeExperience}
        experience={currentExperience}
        settings={settings}
        onExperienceBlur={onExperienceBlur}
        setSelectFocus={setSelectFocus}
        selectFocus={selectFocus}
      />
    </div>
  );

  return renderSpecifiedExperience();
};

export default ExperienceField;
