export const MANAGE_PARTNERS_INITIATE = 'MANAGE_PARTNERS_INITIATE';
export const RECEIVE_ALL_PARTNERS_REQUEST = 'RECEIVE_ALL_PARTNERS_REQUEST';
export const RECEIVE_ALL_PARTNERS_SUCCESS = 'RECEIVE_ALL_PARTNERS_SUCCESS';
export const RECEIVE_ALL_PARTNERS_ERROR = 'RECEIVE_ALL_PARTNERS_ERROR';
export const SHOW_CLOSED_PARTNERS = 'SHOW_CLOSED_PARTNERS';
export const CHANGE_PARTNER_NAME_TO_FIND = 'CHANGE_PARTNER_NAME_TO_FIND';
export const CHANGE_ERROR_MESSAGE = 'CHANGE_ERROR_MESSAGE';
export const TOGGLE_PARTNER_STATUS = 'TOGGLE_PARTNER_STATUS';
export const SELECT_PARTNER = 'SELECT_PARTNER';
export const PARTNER_MODAL_IS_LOADING = 'PARTNER_MODAL_IS_LOADING';
export const ADD_PARTNER = 'ADD_PARTNER';
export const EDIT_PARTNER = 'EDIT_PARTNER';

export const GET_BY_PARTNER_ID = 'GET_BY_PARTNER_ID';

export const PARTNER_MODAL = 'PARTNER_MODAL';
export const DISCARD_PARTNER_DATA_MODAL = 'DISCARD_PARTNER_DATA_MODAL';
export const REQUEST_PARTNER_ACTIVITIES = 'REQUEST_PARTNER_ACTIVITIES';
export const CHANGE_PARTNER_ACTIVITIES = 'CHANGE_PARTNER_ACTIVITIES';
export const CHANGE_SELECTED_ACTIVITIES = 'CHANGE_SELECTED_ACTIVITIES';
export const CHANGE_PARTNER_NAME = 'CHANGE_PARTNER_NAME';
export const CHANGE_PARTNER_COLOR = 'CHANGE_PARTNER_COLOR';
export const CHANGE_PARTNER_STATUS = 'CHANGE_PARTNER_STATUS';
export const CHANGE_PARTNER_DESCRIPTION = 'CHANGE_PARTNER_DESCRIPTION';
export const CHANGE_PARTNER_MODAL_ERROR_MESSAGE = 'CHANGE_PARTNER_MODAL_ERROR_MESSAGE';
export const CHANGE_NEW_ACTIVITY_NAME = 'CHANGE_NEW_ACTIVITY_NAME';
export const CHANGE_ACTIVITY_INPUT_SHOW_STATUS = 'CHANGE_ACTIVITY_INPUT_SHOW_STATUS';
export const PARTNER_MODAL_MULTIPLE_ERRORS = 'PARTNER_MODAL_MULTIPLE_ERRORS';

export const RELATED_LINKS_MODAL = 'RELATED_LINKS_MODAL';
export const RECEIVE_ALL_PARTNER_USERS_LINKS = 'RECEIVE_ALL_PARTNER_USERS_LINKS';
export const SHOW_INACTIVE_USERS = 'SHOW_INACTIVE_USERS';
export const SHOW_INACTIVE_PARTNERS = 'SHOW_INACTIVE_PARTNERS';
export const CHANGE_PARTNER_MODAL_SELECTED_ACTIVITY = 'CHANGE_PARTNER_MODAL_SELECTED_ACTIVITY';
export const PARTNER_MODAL_ADD_ACTIVITY = 'PARTNER_MODAL_ADD_ACTIVITY';
export const PARTNER_MODAL_EDIT_ACTIVITY = 'PARTNER_MODAL_EDIT_ACTIVITY';
export const PARTNER_MODAL_UPDATE_ACTIVITY = 'PARTNER_MODAL_UPDATE_ACTIVITY';
export const PARTNER_MODAL_CLEAR_EDIT_ACTIVITY = 'PARTNER_MODAL_CLEAR_EDIT_ACTIVITY ';
export const PARTNER_MODAL_CHANGE_ACTIVITY_COLOR = 'PARTNER_MODAL_CHANGE_ACTIVITY_COLOR';
export const PARTNER_MODAL_CHANGE_ACTIVITY_STATUS = 'PARTNER_MODAL_CHANGE_ACTIVITY_STATUS';
export const PARTNER_MODAL_DELETE_ACTIVITY = 'PARTNER_MODAL_DELETE_ACTIVITY';
export const PU_LINKS_MODAL_SORT_BY = 'PU_LINKS_MODAL_SORT_BY';

export const PARTNERS_ERROR_MODAL_SHOW = 'PARTNERS_ERROR_MODAL_SHOW';
export const PARTNERS_ERROR_MODAL_CHANGE_CONTENT = 'PARTNERS_ERROR_MODAL_CHANGE_CONTENT';

export const ADD_CURATOR = 'ADD_CURATOR';
export const REMOVE_CURATOR = 'REMOVE_CURATOR';
