import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import HourReport from 'components/HourReport/HourReport';
import { TAB_CURATOR_REPORT, TAB_HOUR_REPORT, TAB_VACATION_REPORT } from 'helpers/constants/_common/MenuCases';
import { USER_ROLES } from 'api/User/constants';
import { Redux } from 'helpers/types/_common';

import ReportsRadioTabs from './components/ReportsRadioTabs/ReportsRadioTabs';
import s from './styles.module.sass';
import VacationReportPage from '../VacationReportPage/VacationReportPage';
import CuratorReport from '../CuratorReport/CuratorReport';

interface ReportsPageProps {
}

const ReportsPage: React.FC<ReportsPageProps> = () => {
  const { tab } = useParams<{ tab: string }>();

  const { login } = useSelector((state: Redux) => state);

  const renderTabContent = () => {
    switch (tab) {
      case TAB_HOUR_REPORT:
        return <HourReport user={login} />;
      case TAB_VACATION_REPORT:
        return <VacationReportPage />;
      case TAB_CURATOR_REPORT:
        return _.includes(login.permissions, USER_ROLES.CURATOR) ? <CuratorReport user={login} /> : null;
      default:
        return null;
    }
  };

  return (
    <div className={s.reportsPage}>
      <ReportsRadioTabs />

      <div>
        { renderTabContent() }
      </div>
    </div>
  );
};

export default ReportsPage;
