import React, { useEffect } from 'react';
import { Drawer, Space, Button, DrawerProps } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';
import dayjs from 'dayjs';

import buttons from 'helpers/styles/components/buttons.module.sass';
import PartnerMask from 'components/_shared/PartnerMask';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { RU_DOT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { DrawerExternalButtons, ActivityDrawerData } from './helpers/types';
import S from './styles.module.sass';

const cx = classNames.bind(S);

interface ActivityDrawerProps extends DrawerProps {
  drawerData: ActivityDrawerData;
  externalButtons?: DrawerExternalButtons[];
  onClose?: () => void;
}

const ActivityDrawerDrawer: React.FC<ActivityDrawerProps> = (props) => {
  const { onClose, className, drawerData, externalButtons } = props;
  const { activity, partner } = drawerData;

  useEffect(() => {
    
  }, [ drawerData.visible ]);

  const renderTime = (time: string) => <span title={dayjs(time).format('YYYY.MM.DD [at] HH:mm')}>{dayjs(time).format(RU_DOT_DATE_FORMAT)}</span>;

  const renderMask = () => {
    if (_.isUndefined(partner) || _.isUndefined(activity)) return null;

    return (
      <PartnerMask
        mask={partner.mask}
        iconColor={activity.color}
        wrapperColor={partner.color}
        wrapperClassName={S.partnerMaskWrapper}
        iconClassName={S.partnerMask}
        partnerId={partner.partnerId}
        isVacation={partner.isVacation}
        iconSize={60}
      />
    );
  };

  const renderHeader = () => !_.isUndefined(activity) && !_.isUndefined(partner) && (
    <Space className={S.drawerHeader} size={24}>
        {renderMask()}
      <Space direction="vertical" size={10}>
        <div className={S.headerName}>
            <b>{`${partner?.name}:`}</b> {activity.name}
        </div>    
        <div className={S.headerIdentifier}>
          <span>{`Activity ID ${activity.activityId}`}</span>
        </div>  
      </Space>
    </Space>
    
  );

  const renderDrawerBody = () => !_.isUndefined(activity) && (
    <Space
      direction="vertical"
      size={20}
      className={S.drawerBody}
    >
      <div className={S.row}>
        <span className={S.rowName}>Description:</span>

        <div className={S.descriptionWrapper}>
          <p className={S.description}>{_.isEmpty(activity.description) ? '-' : activity.description}</p>          
          <div className={S.pair}>
            <div className={S.info}>
              <span className={S.title}>Creation date</span>
              <span className={S.text}>{renderTime(activity.creationDate)}</span>
            </div>
            
            <div className={S.info}>
              <span className={S.title}>Last update</span>
              <span className={S.text}>{renderTime(activity.lastUpdate)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={S.row}>
        <span className={S.rowName}>Overtimes:</span>

        <div className={S.descriptionWrapper}>
          <p className={S.description}>{ activity.isOvertimesAllowed ? "Allowed" : "Not allowed" }</p>          

        </div>
      </div>
      
      <div className={S.row}>
        <span className={S.rowName}>Author:</span>
        <div className={S.author}>
          <UserAvatar
            avatarUrl={activity.author.avatarUrl}
          />
          <span className={S.authorName}>{_.join(_.map([ activity.author.forename, activity.author.surname ], str => _.capitalize(str)), ' ')}</span>
        </div>
      </div>
    </Space>
  );

  const renderDrawerContent = () => {
    if (_.isEmpty(activity)) return null;

    return (
      <div className={cx('drawer-content')}>
        {renderHeader()}
        {renderDrawerBody()}
      </div>
    );
  };

  const renderFooter = () => {
    const footerButtons = _.map(externalButtons, action => action(activity));

    return (
      <div className={S.activityDrawerFooter}>
        <div>
          {_.isEmpty(activity) ? null : footerButtons}
        </div>
        <Button
          className={buttons.qsButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    );
  };

  const omittedProps = _.omit(props, [ 'userId', 'drawerData', 'externalButtons', 'visible' ]);

  return (
    <Drawer
      {...omittedProps}
      visible={drawerData.visible}
      width={715}
      className={cx(S.activityDrawer, className)}
      footer={renderFooter()}
      destroyOnClose
    >
      {renderDrawerContent()}
    </Drawer>
  );
};

export default ActivityDrawerDrawer;
