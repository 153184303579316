import { EditableUserHourComment, GroupedUserHourComment, UsersHour } from 'api/TimeSheet/types';
import { GroupOfCells } from 'components/TimeSheet/helpers/types';

export interface UserHourCommentsProps {
  pureSelectedHours: PureSelectedHours;
  isMonthClosed: boolean;
  cellObj: GroupOfCells;
  isCut: boolean;
  day: string;
  editableCommentsList: EditableUserHourComment[];
  isCommentEditingInProgress: boolean;
  setEditableCommentsList: (currentList: EditableUserHourComment[], backup?: EditableUserHourComment[]) => void;
  curatorOnlyView?: boolean;
}

export interface FilteredByCommentIdComments {
  [ key: number ]: GroupedUserHourComment,
}

export enum CommentEditingStates {
  None = 'NONE',
  Active = 'ACTIVE',
}

export interface AvailableTimeCellsRange {
  [ key: number ]: number | string | null | undefined;
}

export interface PureSelectedHours {
  [ key: number ]: UsersHour;
}
