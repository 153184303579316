import _ from 'lodash';

import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import { CellOfGroup, GroupsOfCellsByCellIndex } from 'components/TimeSheet/helpers/types';

import { AvailableTimeCellsRange, FilteredByCommentIdComments, PureSelectedHours } from './types';

interface AvailableRangesToSelect {
  rangeOfAvailableStartCells: AvailableTimeCellsRange,
  rangeOfAvailableEndCells: AvailableTimeCellsRange,
}

export const getAvailableTimeRangeToSelect = (pureSelectedHours: PureSelectedHours) => _.reduce(pureSelectedHours, (result, usersHour) => {
  const startTimeCell = getDateAndCellIndexByTime(usersHour.startTime).cellIndex;
  const endTimeCell = getDateAndCellIndexByTime(usersHour.endTime).cellIndex;

  if (_.isNil(startTimeCell) || _.isNil(endTimeCell)) return result;

  return {
    rangeOfAvailableStartCells: {
      ...result.rangeOfAvailableStartCells,
      [startTimeCell]: usersHour.comment?.commentId,
    },
    rangeOfAvailableEndCells: {
      ...result.rangeOfAvailableEndCells,
      [endTimeCell || 48]: usersHour.comment?.commentId,
    },
  };
}, { rangeOfAvailableStartCells: {}, rangeOfAvailableEndCells: {} } as AvailableRangesToSelect);

export const getGroupCellsByCellIndex = (groupCells: CellOfGroup[]) => _.reduce(groupCells, (result, cell) => ({
  ...result,
  [cell.cellIndex]: cell,
}), {} as GroupsOfCellsByCellIndex);

export const filterGroupCellsByCommentId = (groupCellsByCellIndex: GroupsOfCellsByCellIndex) => _.reduce(groupCellsByCellIndex, (result, cell, key) => {
  const { comment } = cell;
  const existedComment = result[comment?.commentId as number];
  const keyNumber = parseInt(key, 10);

  if (_.isNil(comment)) return result;

  if (typeof comment?.commentId === 'number' && existedComment) {
    return {
      ...result,
      [comment.commentId]: {
        ...existedComment,
        endCellIndex: keyNumber + 1,
      },
    };
  } else {
    return {
      ...result,
      [comment?.commentId]: {
        ...comment,
        startCellIndex: keyNumber,
        endCellIndex: keyNumber + 1,
      },
    };
  }
}, {} as FilteredByCommentIdComments);

export const sortCommentsByTime = (filteredByCommentId: FilteredByCommentIdComments) => _.map(filteredByCommentId).sort((a, b) => {
  if (a.startCellIndex > b.startCellIndex) return 1;
  if (a.startCellIndex < b.startCellIndex) return -1;
  return 0;
});
