import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';

import { SLACK_GROUP_ID } from '../../../../helpers/constants/_common/constants';

export const convertTextToLink = (fieldName: string, value?: string | number | React.ReactNode) => {
  if (!value) {
    return <div style={{ padding: '5px 0' }}>—</div>;
  }

  switch (fieldName) {
    case 'Skype':
      return <a href={`skype:${value}?chat`} target="_blank" rel="noreferrer">{value}</a>;

    case 'Telegram':
      return <a href={`https://t.me/${value}`} target="_blank" rel="noreferrer">{value}</a>;

    case 'Slack':
      return <a href={`slack://user?team=${SLACK_GROUP_ID}&id=${value}`} target="_blank" rel="noreferrer">{value}</a>;

    case 'Email':
      return <a href={`mailto:${value}`}>{value}</a>;

    default:
      return value;
  }
};

export const utcOffsetToCurrentTime = (offset?: number) => {
  if (_.isNil(offset)) {
    return offset;
  }

  const currentFullTime = dayjs().format('YYYY-MM-DD HH:mm');
  const offsetFullTime = dayjs().utcOffset(offset).format('YYYY-MM-DD HH:mm');
  const hoursDifference = dayjs(currentFullTime).diff(dayjs(offsetFullTime), 'h');
  const hourDifferenceSuffix = hoursDifference > 0 ? '-' : '+';
  const offsetTime = dayjs().utcOffset(offset).format('HH:mm');

  const additionalMinutes = (offset % 60) ? ` ${60 - (offset % 60)}m` : '';

  return `${offsetTime} (${hourDifferenceSuffix}${Math.abs(hoursDifference)}hrs${additionalMinutes})`;
};
