import _ from 'lodash';

import { AccessEnum } from '../../components/Root/helpers/types';
import ROUTES from '../constants/_common/routes';

const availableRouteKeys = (permissions: AccessEnum[]) => _.reduce(ROUTES, (acc, route) => {
  const sortByArrayOfAccesses = () => (
    _.intersection(permissions, route.accessType).length > 0
      ? [ ...acc, route.path ]
      : acc
  );

  const sortBySingleAccess = () => (
    _.includes(permissions, route.accessType)
      ? [ ...acc, route.path ]
      : acc
  );

  if (typeof route.accessType === 'object') {
    return sortByArrayOfAccesses();
  } else {
    return sortBySingleAccess();
  }
}, [] as string[]);

export default availableRouteKeys;
