import { BaseTableBody } from '../../components/_shared/Table/helpers/types';
import { AttitudesEnum, EnglishKnowledgeState, ExperienceFilterNames, SkillExperienceEnum, Skill } from '../../helpers/types/Skills';
import { EnglishLevelEnum } from '../../helpers/types/_common';
import { Partner } from '../../helpers/types/Partners';
import { Meta } from '../_request/types';
import { TrustedUser } from '../../helpers/types/User';
import { EmployeeOccupationEnum } from '../../helpers/constants/Occupations/types';
import { ActivityCardOutput } from '../Partners/types';
import { SkillsetActuality, UserSkillOutput } from '../User/types';

export interface GetPartners {
  meta: Meta,
  objects: Partner[],
}

export interface SpecialistsRequest {
  specialistRequestId: number,
  status: SRStatuses,
  occupation: EmployeeOccupationEnum,
  englishKnowledge: EnglishKnowledgeState,
  creationDate: string,
  lastUpdate: string,
  author: TrustedUser,
  candidates: Candidate[]
}

export interface Candidate {
  specialistCandidateId: 0,
  creationDate: string,
  lastUpdate: string,
  user: TrustedUser
}

export enum SRStatuses {
  Hiring = 'HIRING',
  Staffed = 'STAFFED',
}

export interface FindSpecialistsBody extends BaseTableBody {
  englishKnowledge?: string;
  occupation?: string;
  active: boolean;

  [ExperienceFilterNames.notSet]?: number[];
  [ExperienceFilterNames.petProjects]?: number[];
  [ExperienceFilterNames.commercialUse]?: number[];
  [ExperienceFilterNames.confident]?: number[];
  [ExperienceFilterNames.level80]?: number[];
}

export interface CreateNewRequestBody {
  activityId: number;
  occupation: EmployeeOccupationEnum;
  englishKnowledge?: EnglishLevelEnum;
}

export interface RequestSpecialistsBody extends BaseTableBody {
  usersActive: boolean;
  specialistRequestId: string;
}

export interface GetSpecialistsBody extends BaseTableBody {
  active: boolean;
  specialistRequestId: string;
  fullnameContains?: string;
  usernameContains?: string;
  emailAddressContains?: string;
}

export type AttitudesObjectColumn = {
  [key: number]: AttitudesTextField
};

export interface AttitudesTextField {
  attitude: AttitudesEnum | null;
  experience: string;
}

export interface MatchedByField {
  attitude: AttitudesEnum | null;
  creationDate: string
  currentlyInUse: boolean;
  experience: SkillExperienceEnum;
  lastUpdate: string;
  skill: Skill;
  userSkillId: number;
}

export interface SpecialistsRow {
  active: boolean;
  avatarUrl: string;
  creationDate: string;
  emailAddress: string;
  englishKnowledge: EnglishLevelEnum;
  entity: string;
  entityId: number;
  forename: string;
  lastUpdate: string;
  occupation: string;
  matchedBy: MatchedByField[],
  surname: string;
  userId: number;
  username: string;
  usertypeId: number;

  [skillExpKey: number]: AttitudesTextField
}

export interface Activity {
  activityId: number;
  name: string;
  description: string;
  color: string;
  active: boolean;
  creationDate: string;
  lastUpdate: string;
  hasHours: boolean;
}

export interface SpecialistRequestExtraDetails {
  specialistRequestId?: number,
  status?: SRStatuses,
  occupation?: EmployeeOccupationEnum,
  englishKnowledge?: string,
  creationDate?: string;
  lastUpdate?: string;
  author?: TrustedUser,
  activity?: Activity,
  partner?: Partner
}

export interface SpecialistCandidateOutput extends SpecialistsRow {
  specialistCandidateId: number;
  creationDate: string;
  lastUpdate: string;

  [key: number]: AttitudesTextField;
}

export interface SetCandidatesStatusBody {
  userId: number;
  specialistRequestId: string;
}

export interface SetRequestSpecialistsInfoBody {
  status?: SRStatuses;
  occupation?: EmployeeOccupationEnum;
  englishKnowledge?: string;
}

export interface ActivitySpecialistRequestsPage {
  meta: Meta;
  objects: ActivityCardOutput[];
}

export interface PrimarySkill {
  attitude: AttitudesEnum | null;
  creationDate: string
  currentlyInUse: boolean;
  experience: SkillExperienceEnum;
  lastUpdate: string;
  skill: Skill;
  userSkillId: number;
}

export interface FoundSpecialist {
  userId: number;
  forename: string;
  surname: string;
  emailAddress: string;
  active: boolean;
  usertypeId: number;
  username: string;
  avatarUrl: string;
  englishKnowledge: EnglishLevelEnum;
  occupation: EmployeeOccupationEnum;
  creationDate: string;
  lastUpdate: string;
  entity: string;
  entityId: number;
  matchedBy: PrimarySkill[];
}

export interface FoundSpecialistPage {
  meta: Meta;
  objects: FoundSpecialist[];
}

export interface BaseRequiredSkillOutput {
  requiredSkillId: number
  experience: string;
  skill: Skill;
}

export interface RequiredSkillPage {
  meta: Meta;
  objects: BaseRequiredSkillOutput[];
}
