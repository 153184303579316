import { PaginationProps } from 'antd/es/pagination';
import _ from 'lodash';

import { DEFAULT_PAGINATION_RAW } from '../../../../helpers/constants/_common/Pagination';
import { TablePagination } from './types';

export const convertPaginationForAntd = (pagination?: TablePagination) => {
  if (!pagination) {
    return ({
      current: DEFAULT_PAGINATION_RAW.offset,
      pageSize: DEFAULT_PAGINATION_RAW.limit,
      total: DEFAULT_PAGINATION_RAW.totalCount,
    });
  }

  const { totalCount, limit, offset } = pagination;

  return ({
    current: offset && (offset > 0) ? (offset / (limit || DEFAULT_PAGINATION_RAW.limit)) + 1 : 1,
    pageSize: limit,
    total: totalCount,
  });
};

export const convertPaginationForBackend = (pagination: PaginationProps) => {
  if (_.isBoolean(pagination)) {
    return {
      limit: DEFAULT_PAGINATION_RAW.limit,
      offset: DEFAULT_PAGINATION_RAW.offset,
    };
  }

  return {
    limit: pagination.pageSize,
    offset: pagination.current && (pagination.current !== 1) ? ((pagination.current - 1) * (pagination.pageSize || DEFAULT_PAGINATION_RAW.limit)) : 0,
  };
};
