import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import popover from 'helpers/styles/components/popover.module.sass';

import { SkillTagProps } from '../../../../api/SkillTags/types';
import { deleteSkillTag, updateSkillTag } from '../../../../redux/SkillTags/actions';
import { DispatchType } from '../../../../helpers/types/_common';
import { SkillTagsPopoverProps } from './helpers/types';
import s from './helpers/styles.module.sass';
import { SkillDeleteConfirmModal } from '../SelectionSkillTagPanel/helpers/helpers';

const SkillTagsPopover: React.FC<SkillTagsPopoverProps> = ({ record, onDeleteCallback, onApproveCallback, onEdit, onViewDetail }) => {
  const dispatch: DispatchType = useDispatch();

  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const [ approveLoading, setApproveLoading ] = useState(false);

  const renderPopoverContent = () => {
    const handleOkDelete = () => {
      setDeleteLoading(true);
      dispatch(deleteSkillTag(record.skillId))
        .then(() => onDeleteCallback(record.skillId))
        .finally(() => setDeleteLoading(false));
    };

    const handleOnDeleteClick = (record: SkillTagProps) => {
      if (!record.employeesKnow) {
        handleOkDelete();
        return;
      }

      SkillDeleteConfirmModal(handleOkDelete, record);
    };

    const handleApproveButton = (record: SkillTagProps) => {
      setApproveLoading(true);

      return dispatch(updateSkillTag(record))
        .then(() => onApproveCallback(record))
        .finally(() => setApproveLoading(false));
    };

    return (
      <div className={popover.dotsPopoverItemWrapper}>
        <span className={cx(popover.dotsPopoverItem, s.popoverLoadingItem)} onClick={() => handleApproveButton(record)}>
          { approveLoading ? <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin /> : null }
          { record.approved ? 'Disapprove' : 'Approve' }
        </span>
        <span className={popover.dotsPopoverItem} onClick={() => onEdit(record)}>Edit skill tag</span>
        <span className={popover.dotsPopoverItem} onClick={() => onViewDetail(record)}>View details</span>
        <span className={cx(popover.dotsPopoverItem, s.popoverLoadingItem)} onClick={() => handleOnDeleteClick(record)}>
          { deleteLoading ? <LoadingOutlined style={{ fontSize: 10 }} spin /> : null }
          Delete
        </span>
      </div>
    );
  };

  return (
    <div className={popover.dotsPopover}>
      <Popover
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
        content={renderPopoverContent()}
      >
        <Button type="text" size="small" className="action-column-button">
          <FontAwesomeIcon className={s.skillTagRowIcon} icon="ellipsis-v" />
        </Button>
      </Popover>
    </div>
  );
};

export default SkillTagsPopover;
