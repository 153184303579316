export const INITIATE = '[HOT PROJECTS] INITIATE';
export const GET_HOT_PROJECTS = '[HOT PROJECTS] GET HOT PROJECTS';
export const ADD_HOT_PROJECT_MODAL_PROCESS = '[HOT PROJECTS] ADD HOT PROJECT MODAL PROCESS';
export const HOT_PROJECT_MODAL_VIEW = '[HOT PROJECTS] HOT PROJECT MODAL VIEW';
export const HOT_PROJECT_CONFIRMATION_MODAL_VIEW = '[HOT PROJECTS] HOT PROJECT CONFIRMATION MODAL VIEW';

export const UPDATE_HOT_PROJECTS_LIST_WITH_NEW_ONE = '[HOT PROJECTS] UPDATE HOT PROJECTS LIST WITH NEW ONE';
export const UPDATE_EXISTED_HOT_PROJECTS_LIST = '[HOT PROJECTS] UPDATE EXISTED HOT PROJECTS LIST';

export const GET_ALL_PARTNERS = '[HOT PROJECTS] GET ALL PARTNERS';
export const GET_HOT_PROJECT_BY_ID = '[HOT PROJECTS] GET HOT PROJECT BY ID';

export const CHANGE_NO_PARTICIPANTS = '[HOT PROJECTS] CHANGE NO PARTICIPANTS';
export const CHANGE_STATUS = '[HOT PROJECTS] CHANGE STATUS';
export const CHANGE_MATCH_MY_SKILLS = '[HOT PROJECTS] CHANGE MATCH MY SKILLS';

export const MARK_ALL_HOT_PROJECTS_AS_READ = '[HOT PROJECTS] MARK ALL HOT PROJECTS AS READ';
export const MARK_SELECTED_HOT_PROJECTS_AS_READ = '[HOT PROJECTS] MARK SELECTED HOT PROJECTS AS READ';

export const UPDATE_HOT_PROJECT_AS_READ = '[HOT PROJECTS] UPDATE HOT PROJECT AS READ';
export const UPDATE_SELECTED_HOT_PROJECTS_AS_READ = '[HOT PROJECTS] UPDATE SELECTED HOT PROJECTS AS READ';
export const UPDATE_HOT_PROJECTS_UNREAD_COUNT = '[HOT PROJECTS] UPDATE HOT PROJECTS UNREAD COUNT';

export const HANDLE_HOT_PROJECT_SELECTION = '[HOT PROJECTS] HANDLE HOT PROJECT SELECTION';
export const CHANGE_SELECTED_HELP_REQUEST = '[HOT PROJECTS] CHANGE SELECTED HELP REQUEST';
export const TOGGLE_HELP_REQUEST_STATUS = '[HOT PROJECTS] TOGGLE HELP REQUEST STATUS';
export const MAKE_HELP_OFFER = '[HOT PROJECTS] MAKE HELP OFFER';
