import React from 'react';
import classNames from 'classnames/bind';

import { GroupOfCells } from 'components/TimeSheet/helpers/types';
import { getTimeByDateAndCellIndex } from 'components/TimeSheet/helpers/helpers';

import S from './helpers/styles.module.sass';
import { CellProps } from './helpers/types';

const cx = classNames.bind(S);

const Cell: React.FC<CellProps> = (props) => {
  const {
    day,
    cellObj,
    groupStartCell,
    isCut,
    isMonthClosed,
  } = props;
  const { cellIndex } = cellObj as GroupOfCells;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events
    <div
      data-cell-id={getTimeByDateAndCellIndex(day, cellIndex)}
      data-group-start-index={groupStartCell || cellIndex}
      data-cell-index={cellIndex}
      data-is-cut={isCut}
      data-day={day}
      key={`${day}-${cellIndex}-hour`}
      className={cx(S.cell, {
        [S.nonInteractive]: isMonthClosed,
        [S.cutDay]: isCut,
        [S.withBottomBorder]: cellIndex % 2 !== 0,
      })}
    />
  );
};

export default Cell;
