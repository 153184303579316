import React from 'react';
import _ from 'lodash';
import { Space } from 'antd';

import SalaryField from 'components/_shared/SalaryField/SalaryField';
import { SalaryObject } from 'components/_shared/SalaryField/types';

import { TimesheetActivitiesHours } from '../MonthInfoPopover/helpers/helpers';
import s from './styles.module.sass';

interface MonthInfoTotalRowProps {
  salaryObject: SalaryObject;
  holidaysHours: number;
  setSalaryObject: React.Dispatch<React.SetStateAction<SalaryObject>>
  selectedActivities: TimesheetActivitiesHours;
}

const MonthInfoTotalRow: React.FC<MonthInfoTotalRowProps> = ({ salaryObject, setSalaryObject, holidaysHours, selectedActivities }) => {
  const totalTime = _.reduce(selectedActivities, (acc, activity) => acc + activity.hours, 0) + holidaysHours;

  return (
    <div className={s.totalRow}>
      <span>
        Total
      </span>
      <Space size={12}>

        <SalaryField
          salaryObject={salaryObject}
          onChange={setSalaryObject}
          totalHours={totalTime}
        />

        <span>
          {`${totalTime}h`}
        </span>
      </Space>
    </div>
  );
};

export default MonthInfoTotalRow;
