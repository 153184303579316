import _ from 'lodash';

import { Themes } from 'helpers/constants/_common/constants';

export const darkThemeVariables = {
  // custom variables
  '--background-gray': '#1B2228',
  '--font-white': '#000',
  '--font-black': '#ffffff',
  '--white': '#1B2228',
  '--grey-background-break-gray': '#82888F1A',
};

export const lightThemeVariables = {
  '--background-gray': '#F2F4F6',
  '--white': '#ffffff',
  '--font-white': '#fff',
  '--font-black': '#000',
  '--grey-background-break-gray': '#D1DBE3',
};

export const applyPrimaryColors = (theme: string) => {
  const themeObject = (theme === Themes.LIGHT) ? lightThemeVariables : darkThemeVariables;

  _.forEach(themeObject, (value, key) => {
    document.documentElement.style.setProperty(key, value);
  });
};
