import { ColumnProps } from './types';

/** Compares props to prevent unnecessary re-renders. Returns false for render. */
export default (prevProps: ColumnProps, nextProps: ColumnProps) => {
  const isNewSelectionColumn = nextProps.dayObj.day === nextProps.selectionDay;
  const isLastSelectionColumn = nextProps.dayObj.day === prevProps.selectionDay;

  const isNewGroupContextMenuColumn = nextProps.dayObj.day === nextProps.groupOfCellsContextMenu.day;
  const isLastGroupContextMenuColumn = nextProps.dayObj.day === prevProps.groupOfCellsContextMenu.day;

  if (
    prevProps.dayObj !== nextProps.dayObj
    || prevProps.isMonthClosed !== nextProps.isMonthClosed
    || prevProps.additionalClass !== nextProps.additionalClass
    || prevProps.userHoursTable !== nextProps.userHoursTable
    || prevProps.isBCLoading !== nextProps.isBCLoading
    
    || (isNewGroupContextMenuColumn || isLastGroupContextMenuColumn)
    || (isNewSelectionColumn || isLastSelectionColumn)
  ) {
    return false; // render ( i don't know why is it false, is't because of shallow comparing of React.memo or something like that)
  } else {
    return true; // don't render
  }
};
