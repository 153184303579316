import React from 'react';
import _ from 'lodash';

import s from './styles.module.sass';
import Month from '../Month/Month';
import { CalendarMonthData } from '../Month/components/MonthInner/helpers/types';
import { Month as MonthProps } from '../../../../api/BusinessCalendar/types';

interface GridCalendarProps {
  onOpenDrawer: (monthInnerData: CalendarMonthData) => void;
  calendar: MonthProps[];
  loading: boolean;
}

const GridCalendar: React.FC<GridCalendarProps> = ({ onOpenDrawer, loading, calendar }) => (
  <div className={s.gridCalendarWrapper}>
    {
      _.map(calendar, monthObj => (
        <div
          className={s.gridCalendarItem}
          key={`${monthObj.year}-${monthObj.month}`}
        >
          <Month
            onDrawerOpen={onOpenDrawer}
            isLoading={loading}
            monthData={monthObj}
          />
        </div>

      ))
    }
  </div>
);

export default GridCalendar;
