import { Button } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { ReactComponent as RightArrowIcon } from 'helpers/icons/rightArrowIcon.svg';
import withRightPlural from 'helpers/utils/withRightPlural';

import { ModifiedUsersHour } from '../../../UserHoursList/helpers/groupUserHoursByActivity';
import S from './styles.module.sass';

interface MainInnerPanelProps {
  userHour: ModifiedUsersHour | null;
  isLoading: boolean;
  hours: number;
  deleteUserHourHandler: () => void;
  setInnerPanel: (panel: 'main' | 'replace') => void;
}

const MainInnerPanel: React.FC<MainInnerPanelProps> = ({ userHour, isLoading, hours, deleteUserHourHandler, setInnerPanel }) => {
  if (!userHour) return null;

  return (
    <>
      <div className={S.workedHours}>
        <span>{`${hours} ${withRightPlural('hour', hours)} reported`}</span>
      </div>
      <ul className={S.comments}>
        {_.map(userHour.comments, comment => (
          <li key={comment?.commentId} className={S.comment}>{comment?.comment}</li>
        ))}
      </ul>
      <div className={classNames(S.actions, { [S.loading]: isLoading })}>
        <span className={S.label}>Actions</span>
        <div className={S.buttons}>
          <Button
            className={classNames(S.deleteButton, S.button)}
            onClick={deleteUserHourHandler}
            disabled={isLoading}
            loading={isLoading}
          >
            Delete
          </Button>
          <Button
            className={classNames(S.replaceButton, S.button)}
            onClick={() => setInnerPanel('replace')}
          >
            Replace activity
            <RightArrowIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default MainInnerPanel;
