import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classNames from 'classnames';

import { DispatchType, Redux } from 'helpers/types/_common';
import { ContextMenuType, SelectionActionsEnum } from 'redux/TimeSheet/types';
import { dropTimeSelecting, initiateContextMenus, setSelectedCells, startTimeSelecting } from 'redux/TimeSheet/action';
import { UiDrawMode } from 'helpers/constants/_common/constants';

import { findHoveredCellNode, getBoundingRectStyles, getXWithHorizontalLimit, getMarkerSize, getOverlappedCellIds, getRoundedCoords, getValidCoords, getRoundedCoordsWithSelectionBox } from './helpers/helpers';
import { Coords, SelectionData, SelectionRoundingSettings } from './helpers/types';
import S from './styles.module.sass';
import SelectionContextMenu from '../../../SelectionContextMenu/SelectionContextMenu';

interface SelectionBoxProps {
  onMouseUpCallback: (data: SelectionData) => void;
  curatorOnlyView: boolean | undefined;
  isDisabled: boolean;
}

const CLICK_TIMEOUT = 150;

// Attention! To make it possible to select with horizontal way just edit getXWithHorizontalLimit() helper

const selectionBoxRoundingSettings: SelectionRoundingSettings = {
  horizontal: true,
  vertical: false,
};

const SelectionBox: React.FC<SelectionBoxProps> = ({ onMouseUpCallback, curatorOnlyView, isDisabled: isDisabledFromOutside }) => {
  const InitialData: SelectionData = {
    coords: {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
    },
    clickedCell: null,
    mouseAction: null,
    selectionAction: null,
    userAction: null,
  };
  const [ selectionData, setSelectionData ] = useState<SelectionData>(InitialData);
  
  const { selectedActivity, contextMenus, replaceDrawer, deleteUserHoursModal, tableSettings } = useSelector((state: Redux) => state.timeSheet);
  const { isShown, isLoading } = useSelector((state: Redux) => state.timeSheet.selection);
  const { isCtrlPressed } = useSelector((state: Redux) => state.timeSheet.tableSettings);
  const { login: user } = useSelector((state: Redux) => state);
  const isCtrlMode = user.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL;

  const dataRef = useRef<SelectionData>(selectionData);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch: DispatchType = useDispatch();
  
  const { isVisible } = contextMenus[ContextMenuType.Selection];
  const isSelectedActivityExists = typeof selectedActivity === 'object';
    
  const isMenusAreShown = _.values(contextMenus).filter(menu => menu.isVisible).length > 0; // selection or group context menu is shown
  const isHoursDrawerShown = replaceDrawer.isVisible;
  const isDeleteModalShown = deleteUserHoursModal.isVisible;
  const isSelectionHidden = _.isNull(selectionData.selectionAction) || selectionData.selectionAction === SelectionActionsEnum.Start;
  const isDisabled = isMenusAreShown || isHoursDrawerShown || isDeleteModalShown || isDisabledFromOutside;

  const { width, height, left, top } = getBoundingRectStyles(selectionData, selectionBoxRoundingSettings);
  
  const hours = getOverlappedCellIds().length / 2;
  const hoursNiceString = Math.floor(hours) === hours ? `${hours}.0` : hours;
  const markerSize = getMarkerSize(hours);

  // listeners

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape' && selectionData.selectionAction === SelectionActionsEnum.Continue) {
      dropSelection();
    }
  }, [ selectionData.selectionAction ]);

  const onMouseDown = (nativeEvent: MouseEvent<HTMLDivElement>) => {
    if (nativeEvent.button !== 0) return;

    const isUserActionExists = !_.isNull(selectionData.userAction);
    const isSelectionActionExists = !_.isNull(selectionData.selectionAction);
    const isSelectionInProcess = isSelectionActionExists || isUserActionExists;
    
    if (isSelectionInProcess && !isDisabled && !isLoading) return dropSelection();

    if (isDisabled || isLoading) return;
    
    const start = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    const end = { ...start };
    const coords = getRoundedCoords(start, end);
    const cell = findHoveredCellNode({ x: nativeEvent.clientX, y: nativeEvent.clientY });

    dispatch(startTimeSelecting());
    setSelectionData({
      selectionAction: SelectionActionsEnum.Start,
      userAction: null,
      mouseAction: 'down',
      clickedCell: cell,
      coords,
    });
    startClickTimer();
  };

  const onMouseMove = (nativeEvent: MouseEvent<HTMLDivElement>) => {
    if (isDisabled || isLoading || nativeEvent.button !== 0) return;
    
    if (tableSettings.isMonthClosed) return;

    const isSelectionInProgress = !_.isNull(selectionData.selectionAction);
    const isSelectionEnd = selectionData.selectionAction === SelectionActionsEnum.End;
    const end: Coords = { x: getXWithHorizontalLimit(nativeEvent.clientX, selectionData.coords.start.x), y: nativeEvent.clientY };

    if (!isSelectionInProgress || isSelectionEnd) return;

    setSelectionData({
      ...selectionData,
      selectionAction: SelectionActionsEnum.Continue,
      mouseAction: 'move',
      coords: {
        ...selectionData.coords,
        end: isSelectionInProgress ? getValidCoords(end) : end,
      },
    });
  };

  const onMouseUp = (nativeEvent: MouseEvent<HTMLDivElement>) => {
    clearClickTimer();

    if (isDisabled || isLoading || nativeEvent.button !== 0) return;

    const isSelectionInProgress = !_.isNull(selectionData.selectionAction);

    const end = { x: getXWithHorizontalLimit(nativeEvent.clientX, selectionData.coords.start.x), y: nativeEvent.clientY };
    const roundedCoords = getRoundedCoordsWithSelectionBox(selectionData.coords.start, end);

    if (!isSelectionInProgress) return setSelectionData(InitialData);

    setSelectionData({
      ...selectionData,
      selectionAction: SelectionActionsEnum.End,
      userAction: selectionData.selectionAction === SelectionActionsEnum.Start ? 'click' : selectionData.userAction,
      mouseAction: 'up',
      coords: roundedCoords,
    });
  };

  const onMouseLeave = () => {
    if (_.isNull(selectionData.selectionAction) || selectionData.selectionAction === SelectionActionsEnum.End) return;
    endOfSelections();
  };

  // effects

  useEffect(() => { // its because we need to store new selection box size, then find all overlapped cells and only after that to make right decision
    if (selectionData.mouseAction === 'up') endOfSelections();
  }, [ selectionData, timerRef ]);

  useEffect(() => { // its for data state providing inside of setTimeout (clickTimer)
    dataRef.current = selectionData;
  }, [ selectionData ]);

  useEffect(() => { // drop selecting from outside of component
    if (!isShown) dropSelection();
  }, [ isShown ]);

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);

    return () => document.removeEventListener('keyup', onKeyUp);
  }, [ onKeyUp ]);

  // functions

  const endOfSelections = () => {
    const isItClickEvent = selectionData.userAction === 'click';

    if (tableSettings.isMonthClosed) return callClickEvent(); // if month is closed only click event available

    if (isItClickEvent) {
      callClickEvent();
    } else {
      callDrawOrSelectionEvent();
    }
  };

  const callClickEvent = () => {
    if (selectionData.clickedCell) { // only if clicked cell detected
      const newData: SelectionData = { ...InitialData, clickedCell: selectionData.clickedCell, userAction: 'click' };

      setSelectionData(newData);
      onMouseUpCallback(newData);
    } else {
      dropSelection();
    }
  };

  const callDrawOrSelectionEvent = () => {
    const isActivitySelected = !_.isNil(selectedActivity);
    const userAction = isActivitySelected || (isCtrlMode && isCtrlPressed) ? 'draw' : 'select';

    dispatch(setSelectedCells(getOverlappedCellIds()));

    const newData: SelectionData = { ...selectionData, userAction, mouseAction: null };

    setSelectionData(newData);
    onMouseUpCallback(newData);
  };

  const startClickTimer = () => {
    const newTimerId = setTimeout(() => {
      const { selectionAction } = dataRef.current;

      timerRef.current = null;

      if (selectionAction === SelectionActionsEnum.Start) {
        setSelectionData({
          ...dataRef.current,
          selectionAction: SelectionActionsEnum.Continue,
          userAction: !isSelectedActivityExists ? 'select' : 'draw',
        });
      }
    }, CLICK_TIMEOUT);

    timerRef.current = newTimerId;
  };

  const clearClickTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const dropSelection = () => {
    setSelectionData(InitialData);
    
    dispatch(initiateContextMenus());
    dispatch(dropTimeSelecting());
  };

  // renderers

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      className={S.selectionBoxWrapper}
    >
      <Popover
        visible={isVisible}
        placement="rightTop"
        content={<SelectionContextMenu curatorOnlyView={curatorOnlyView} />}
        destroyTooltipOnHide
      >
        <div
          className={classNames(S.selectionBox, { [S.visible]: !isSelectionHidden })}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            left: `${left}px`,
            top: `${top}px`,
          }}
        >
          <div id="timesheet-selection-box-inner" className={S.selectionBoxInner}>
            <div
              className={S.selector}
              style={{
                maxHeight: markerSize.height === 0 ? 'auto' : `${markerSize.height}px`,
                maxWidth: markerSize.width === 0 ? 'auto' : `${markerSize.width}px`,
              }}
            >
              <span className={classNames(S.hoursMarker, { [S.lessThenHour]: hours < 1 })}>
                {isLoading ? (<LoadingOutlined className={S.spinner} spin />) : hoursNiceString}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SelectionBox;
