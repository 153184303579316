import { SkillExperienceEnum, ExperienceFilterNames, NotSetExpEnum, ExpFiltersConstant } from '../../types/Skills';
import SKILL_EXPERIENCES from './Experience';

const EXPERIENCE_FILTERS: ExpFiltersConstant = {
  [NotSetExpEnum.notSet]: { experienceId: 0, label: 'Skills with any experience', name: 'Skills with any experience', additionalName: 'Any', shortName: 'any', color: '#bbb', filterKey: ExperienceFilterNames.notSet },
  [SkillExperienceEnum.petProjects]: { ...SKILL_EXPERIENCES[SkillExperienceEnum.petProjects], filterKey: ExperienceFilterNames.petProjects },
  [SkillExperienceEnum.commercialUse]: { ...SKILL_EXPERIENCES[SkillExperienceEnum.commercialUse], filterKey: ExperienceFilterNames.commercialUse },
  [SkillExperienceEnum.confident]: {
    ...SKILL_EXPERIENCES[SkillExperienceEnum.confident],
    filterKey: ExperienceFilterNames.confident,
  },
  [SkillExperienceEnum.level80]: { ...SKILL_EXPERIENCES[SkillExperienceEnum.level80], filterKey: ExperienceFilterNames.level80 },
};

export default EXPERIENCE_FILTERS;
