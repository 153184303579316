import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Redux } from 'helpers/types/_common';
import useApi from 'api/hooks/useApi/useApi';
import { UserDetails } from 'api/User/types';
import { PATH_ADMINISTRATION, TAB_CURATORS_TIMESHEET } from 'helpers/constants/_common/MenuCases';
import useTabRedirect from 'helpers/hooks/useTabRedirect';
import { USER_TYPES } from 'helpers/constants/_common/constants';
import { EDIT_CURATOR_TABS } from 'helpers/constants/_common/tabs/curator';
import SkillSet from 'components/ProfileTabContent/components/SkillSet';
import PersonalInfo from 'components/ProfileTabContent/components/PersonalInfo';

import TimeSheet from '../TimeSheet/TimeSheet';
import CuratorViewSidebar from '../Sidebars/CuratorViewSidebar';
import PagesSidebar from '../Root/components/PagesSidebar';
import s from './styles.module.sass';
import { getCuratorUserById } from '../../api/Curators/requests';

const CuratorsView = () => {
  const { login } = useSelector((state: Redux) => state);

  const { userId, tab } = useParams<{ userId: string, tab: string }>();

  const [ getCuratorUserData, userData, loading, setCuratorUserData ] = useApi<typeof getCuratorUserById, UserDetails>(getCuratorUserById);

  useTabRedirect({
    redirectUrl: `${PATH_ADMINISTRATION}/${TAB_CURATORS_TIMESHEET}/${userId}/${EDIT_CURATOR_TABS.timesheet.path}`,
    tab,
    tabKeys: _.keys(EDIT_CURATOR_TABS),
  });

  useEffect(() => {
    getCuratorUserData(Number(userId) || login.userId);
  }, [ userId ]);

  const renderPersonalInfoContent = () => {
    if ((tab !== EDIT_CURATOR_TABS.general.path) || !userData) {
      return null;
    }

    return <PersonalInfo user={userData} setUserData={setCuratorUserData} curatorOnlyView />;
  };

  const renderSkillSetContent = () => {
    if ((tab !== EDIT_CURATOR_TABS.skillset.path) || !userData) {
      return null;
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <SkillSet
          user={userData}
          setUserData={setCuratorUserData}
          curatorOnlyView
        />
      </DndProvider>
    );
  };

  const renderTimesheetContent = () => {
    if ((tab !== EDIT_CURATOR_TABS.timesheet.path) || !userData || (userData.usertype === USER_TYPES.BOT)) {
      return null;
    }

    return (
      <TimeSheet user={userData} curatorOnlyView />
    );
  };

  return (
    <div className={s.curatorViewLayout}>
      <PagesSidebar>
        <CuratorViewSidebar
          userData={userData}
          loading={loading}
        />
      </PagesSidebar>

      <Spin
        wrapperClassName={s.curatorViewSpinner}
        spinning={loading}
      >
        <div className={s.curatorViewContent}>
          { renderTimesheetContent() }
          { renderSkillSetContent() }
          { renderPersonalInfoContent() }
        </div>
      </Spin>
    </div>
  );
};

export default CuratorsView;
