import { Column } from 'components/_shared/Table/helpers/types';

export default [
  {
    title: 'type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 400,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    width: 30,
    align: 'center',
  },
  {
    title: 'Creation date',
    dataIndex: 'creationDate',
    key: 'creationDate',
    width: 120,
  },
  {
    title: 'Last update',
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    width: 120,
  },
] as Column[];
