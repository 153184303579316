import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Modal, Button, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as MergeIconSmall } from 'helpers/icons/merge_icon_small.svg';
import {
  massDeleteSkillTag,
  massApproveSkillTag,
} from 'redux/SkillTags/actions';
import { DispatchType } from 'helpers/types/_common';
import SkillTag from 'components/_shared/SkillTag';
import { SkillTagProps } from 'api/SkillTags/types';
import modals from 'helpers/styles/components/modals.module.sass';

import S from './helpers/styles.module.sass';
import { SelectionSkillTagPanelProps } from './helpers/types';
import MergeSkillsModal from './components/MergeSkillsModal';
import TableSelectionList from '../../../TableComponents/TableSelectionList';
import { SkillDeleteConfirmModal } from './helpers/helpers';

const cx = classNames.bind(S);

const SelectionSkillTagPanel: React.FC<SelectionSkillTagPanelProps> = (props) => {
  const { selectedRows, setSelectedRows, fetchSkillTags, resetSelections, onMassApproveCallback } = props;
  const dispatch: DispatchType = useDispatch();

  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    // after merge modal closing needs to check all selected skills for reverting changes
    if (visible) {
      const updatedSelectedRows = _.map(selectedRows, skill => ({ ...skill, checked: true }));
      setSelectedRows(updatedSelectedRows);
    }
  }, [ visible ]);

  const handleOnChangeCheckbox = (event: CheckboxChangeEvent, targetRow: any) => {
    const { skillId } = targetRow;
    const checked = event?.target?.checked;

    const updatedSelectedRows = _.map(selectedRows, (s) => {
      if (s.skillId === skillId) {
        return { ...s, checked };
      }

      return s;
    });

    setSelectedRows(updatedSelectedRows);
  };

  const isEmptyUnApprovedSelectedRows = _.isEmpty(_.filter(selectedRows, [ 'approved', false ]));

  const handleDeleteButton = () => {
    const selectedKeys = _.map(selectedRows, row => row.skillId);

    const handleOnDelete = () => {
      dispatch(massDeleteSkillTag(selectedKeys)).then(() => {
        resetSelections();
        fetchSkillTags();
      });
    };
    
    SkillDeleteConfirmModal(handleOnDelete, selectedRows);
  };

  const handleApproveButton = () => {
    const selectedKeys = _.map(selectedRows, row => row.skillId);

    const actionTitle = isEmptyUnApprovedSelectedRows ? 'Disapprove' : 'Approve';

    const content = (
      <div>
        {`You are going to ${_.toLower(actionTitle)}`}
        <SkillTag
          className={S.drawerSkillTag}
          title={`${selectedKeys.length} skill tag(-s)`}
        />
      </div>
    );

    const onMassApproveSkillTags = () => {
      const approved = !isEmptyUnApprovedSelectedRows;

      return dispatch(massApproveSkillTag(selectedKeys, approved))
        .then(() => onMassApproveCallback());
    };

    Modal.confirm({
      className: classNames(modals.qsConfirmAntdModal, S.skillTagModal),
      title: `${actionTitle} multiple skill tags`,
      content,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      okText: actionTitle,
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      icon: null,
      width: 500,
      onOk: onMassApproveSkillTags,
    });
  };

  return (
    <div className={cx('selection-skill-panel')}>
      <MergeSkillsModal
        selectedRows={selectedRows}
        visible={visible}
        setVisible={setVisible}
        resetSelections={resetSelections}
        fetchSkillTags={fetchSkillTags}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
      />

      <div className={cx('selection-skill-panel_selection-count')}>
        <Typography.Text>{`${selectedRows.length} selected`}</Typography.Text>

        <TableSelectionList
          resetSelections={resetSelections}
          onChangeCheckbox={handleOnChangeCheckbox}
          renderTitle={(row: SkillTagProps) => row.name}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
      <Space>
        <Button
          className={buttons.qsButton}
          onClick={handleDeleteButton}
          size="large"
        >
          <div className={buttons.iconTextWrapper}>
            <FontAwesomeIcon icon="trash-alt" />
            Delete
          </div>
        </Button>

        <Button
          className={buttons.qsButton}
          onClick={handleApproveButton}
          size="large"
        >
          <div className={buttons.iconTextWrapper}>
            {isEmptyUnApprovedSelectedRows ? <FontAwesomeIcon icon="minus-circle" /> : <FontAwesomeIcon icon="check-circle" />}
            {isEmptyUnApprovedSelectedRows ? 'Disapprove' : 'Approve'}
          </div>
        </Button>

        <Button
          type="primary"
          size="large"
          className={buttons.qsButtonPrimary}
          onClick={() => setVisible(true)}
        >
          <div className={buttons.iconTextWrapper}>
            <MergeIconSmall />
            Merge selected
          </div>
        </Button>
      </Space>
    </div>
  );
};

export default SelectionSkillTagPanel;
