import _ from 'lodash';

import { Skill, UserSkill, UserSkillActions } from '../../helpers/types/Skills';

interface UpdateUserSkillsBody {
  skill_id?: number | null;
  operation?: string;
  experience?: string;
  skill?: Skill
}

export const updatedSkillTags = (updatedUsersSkills: UserSkill[]) => _.reduce(updatedUsersSkills, (acc, skill) => {
  if (!skill.action) {
    return acc;
  }

  return [
    ...acc,
    parsedOperation(skill),
  ];
}, [] as UpdateUserSkillsBody[]);

const parsedOperation = (userSkill: UserSkill) => {
  switch (userSkill.action) {
    case UserSkillActions.delete:
      return {
        skill_id: userSkill.skill.skillId,
        operation: userSkill.action,
      };

    case UserSkillActions.create:
      return {
        skill: userSkill.skill,
        experience: userSkill.experience,
        operation: userSkill.action,
      };

    case UserSkillActions.update:
    case UserSkillActions.add:
      return {
        skill_id: userSkill.skill.skillId,
        experience: userSkill.experience,
        operation: userSkill.action,
      };
    default:
      return {};
  }
};
