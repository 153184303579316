import React, { useState } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classNames from 'classnames';

import { PartnerDetailsOutput } from 'api/Partners/types';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as EditIcon } from 'helpers/icons/Buttons/edit-icon.svg';
import { updatePartner } from 'api/Partners/requests';
import { ValidationErrorResponse } from 'helpers/types/_common';
import useErrorForm from 'helpers/hooks/useErrorForm/useErrorForm';

import s from './styles.module.sass';
import PartnerInfoCard from './components/PartnerInfoCard/PartnerInfoCard';
import CreatedByCard from './components/CreatedByCard/CreatedByCard';
import AppearanceCard from './components/AppearanceCard/AppearanceCard';
import CuratorsCard from './components/CuratorsCard/CuratorsCard';

interface PartnerInfoTabProps {
  setPartner: (partner: PartnerDetailsOutput) => void;
  partner: PartnerDetailsOutput;
}

const PartnerInfoTab: React.FC<PartnerInfoTabProps> = ({ partner, setPartner }) => {
  const [ partnerFields, setPartnerFields ] = useState<PartnerDetailsOutput>(() => partner);
  const [ changedFields, setChangedFields ] = useState<Partial<PartnerDetailsOutput>>({});
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorHelpers, setErrors ] = useErrorForm<ValidationErrorResponse>();

  const handleOnCancelEdit = () => {
    setPartnerFields(partner);
    setChangedFields({});
    setIsEdit(false);
  };

  const changeField = (
    key: keyof PartnerDetailsOutput,
    value: any,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isRequired?: boolean,
  ) => {
    errorHelpers.doValidateByFieldChanging(key, value, event, isRequired);

    setChangedFields({ ...changedFields, [key]: value });
    setPartnerFields({ ...partnerFields, [key]: value });
  };

  const handleOnSaveEdit = () => {
    if (errorHelpers.isWithErrors()) return;

    const body = _.reduce(changedFields, (result, value, key) => {
      if (_.includes([ 'curators' ], key) || value === partner[key as keyof PartnerDetailsOutput]) {
        return result;
      } else {
        return {
          ...result,
          [key]: value,
        };
      }
    }, {} as Partial<PartnerDetailsOutput>);
    
    if (!_.isEmpty(body)) {
      setIsLoading(true);
      updatePartner({
        partnerId: partnerFields.partnerId,
        data: body,
      })
        .then(() => {
          setPartner({
            ...partner,
            ...changedFields,
          });
          setIsEdit(false);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setErrors(error.response.data);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsEdit(false);
    }
  };

  const editButtonGroup = (
    <Space>
      <Button
        className={buttons.qsButton}
        onClick={handleOnCancelEdit}
      >
        Cancel
      </Button>

      <Button
        type="primary"
        className={buttons.qsButtonPrimary}
        disabled={errorHelpers.isWithErrors()}
        onClick={handleOnSaveEdit}
        loading={isLoading}
      >
        <FontAwesomeIcon icon="check-circle" />
        Save
      </Button>
    </Space>
  );

  const editButton = (
    <Button
      className={buttons.qsButton}
      onClick={() => setIsEdit(edit => !edit)}
    >
      <EditIcon />
      Edit partner
    </Button>
  );

  return (
    <div className={s.partnerInfoTab}>
      <header className={s.partnerInfoTabHeader}>
        <span className={s.headerTitle}>General Info</span>

        { isEdit ? editButtonGroup : editButton}
      </header>

      <main>
        <Row gutter={[ 20, 0 ]}>
          <Col span={12}>
            <PartnerInfoCard
              partnerFields={partnerFields}
              changeField={changeField}
              isEdit={isEdit}
              errorHelpers={errorHelpers}
            />
          </Col>
          <Col span={12}>
            <CreatedByCard
              changeField={changeField}
              partnerFields={partnerFields}
              isEdit={isEdit}
            />
          </Col>
        </Row>

        <Row gutter={[ 20, 0 ]}>
          <Col span={12}>
            <AppearanceCard
              partnerFields={partnerFields}
              changeField={changeField}
              isEdit={isEdit}
            />
          </Col>
        </Row>

        <Row gutter={[ 20, 0 ]} className={classNames(s.transition, { [s.hiddenSection]: isEdit })}>
          <CuratorsCard
            partnerFields={partnerFields}
          />
        </Row>
      </main>
    </div>
  );
};

export default PartnerInfoTab;
