import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import _ from 'lodash';
import classNames from 'classnames/bind';

import { ReactComponent as SpecifyExpIcon } from '../Requests/components/RequestDetail/components/RequestSettings/helpers/icons/specify-exp.svg';
import EXPERIENCE_FILTERS from '../../../../helpers/constants/Skills/ExpFilters';
import { ExpFiltersEnum, NotSetExpEnum } from '../../../../helpers/types/Skills';
import S from './helpers/styles.module.sass';
import { SpecifyExpButtonProps } from './helpers/types';

const cx = classNames.bind(S);

const SpecifyExpButton: React.FC<SpecifyExpButtonProps> = ({ specifiedExperiences, specifyExperienceFilter, isViewMode }) => {
  const [ visible, changeVisible ] = useState(false);

  const renderContent = () => {
    const expButtons = (Object.keys(EXPERIENCE_FILTERS) as ExpFiltersEnum[])
      .filter(k => k !== NotSetExpEnum.notSet)
      .map(expFilter => (
        <Button
          key={expFilter}
          type="text"
          className={cx('specify-experience-list_item')}
          disabled={_.includes(specifiedExperiences, EXPERIENCE_FILTERS[expFilter].filterKey)}
          onClick={() => specifyExperienceFilter(EXPERIENCE_FILTERS[expFilter].filterKey)}
        >
          {EXPERIENCE_FILTERS[expFilter].label}
        </Button>
      ));

    return (
      <div className={cx('specify-experience-list-wrapper')}>
        {expButtons}
      </div>
    );
  };

  if (isViewMode) {
    return (
      <div className={cx('specify-experience', { disabled: true })}>
        <SpecifyExpIcon className={cx('specify-experience_icon')} />
        <span className={cx('specify-experience_label')}>Specify experience</span>
      </div>
    );
  }

  return (
    <Popover
      content={renderContent()}
      trigger="hover"
      onVisibleChange={(status: boolean) => {
        changeVisible(status);
      }}
      placement="bottomRight"
      align={{ offset: [ 0, 0 ] }}
      overlayClassName={cx('specify-experience-list')}
    >
      <div className={cx('specify-experience', { 'specify-experience--popover': visible })}>
        <SpecifyExpIcon className={cx('specify-experience_icon')} />
        <span className={cx('specify-experience_label')}>Specify experience</span>
      </div>
    </Popover>
  );
};

export default SpecifyExpButton;
