const Subtypes = {
  Request: {
    Error: '[REQUEST] ERROR',
    Loading: '[REQUEST] LOADING',
    Success: '[REQUEST] SUCCESS',
  },
  Modal: {
    Show: '[MODAL] SHOW',
    Hide: '[MODAL] HIDE'
  }
};

export default Subtypes;

