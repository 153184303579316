import { Day } from 'api/BusinessCalendar/types';

export interface JointMonth {
  businessMonthId: number | null;
  prevBusinessMonthId: number | null;
  nextBusinessMonthId: number | null;
  days: JointDay[];
  month: number;
  year: number;
}

export enum JointStatus {
  Cut = 'CUT',
  Regular = 'REGULAR',
}

export interface JointDay extends Day {
  jointStatus?: JointStatus;
  highlightStatus?: boolean;
}

export interface MonthsList {
  [key: string]: JointMonth;
}
