import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { Month as MonthObject } from 'api/BusinessCalendar/types';

import { JointDay, JointMonth, JointStatus, MonthsList } from './types';

export const convertToJointsMonths = (monthsList: MonthObject[], additionalMonths: { january: MonthObject, december: MonthObject }): MonthsList => {
  const jointMonths = _.reduce(monthsList, (result, month, index) => {
    const prevMonth = monthsList[index - 1] || additionalMonths.december;
    const nextMonth = monthsList[index + 1] || additionalMonths.january;

    const generateMonthDays = (direction: 'prev' | 'current' | 'next') => {
      let dayjsObj = dayjs(`${month.year}-${month.month}-1`);

      switch (direction) {
        case 'prev':
          dayjsObj = dayjsObj.subtract(1, 'month');
          break;
        case 'next':
          dayjsObj = dayjsObj.add(1, 'month');
          break;
        default:
          break;
      }

      return _.range(dayjsObj.daysInMonth()).map(date => dayjsObj.date(date + 1).format(DEFAULT_DATE_FORMAT));
    };

    const allDaysFake = [
      ...generateMonthDays('prev'),
      ...generateMonthDays('current'),
      ...generateMonthDays('next'),
    ];

    if (dayjs(allDaysFake[0]).day() !== 1) {
      const day = dayjs(allDaysFake[0]).day() || 7;
      allDaysFake.splice(0, 7 - day + 1);
    }

    if (dayjs(allDaysFake[allDaysFake.length - 1]).day() !== 0) {
      const day = dayjs(allDaysFake[allDaysFake.length - 1]).day();
      allDaysFake.splice(allDaysFake.length - day, day);
    }

    allDaysFake.splice(0, 14);
    allDaysFake.splice(56);

    const allDays: JointDay[] = [
      ...prevMonth.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Cut })),
      ...month.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Regular })),
      ...nextMonth.days.map(dayObj => ({ ...dayObj, jointStatus: JointStatus.Cut })),
    ];
    const allDaysOriginal: JointDay[] = _.filter(allDays, dayObj => allDaysFake.includes(dayObj.day));

    return {
      ...result,
      [month.month]: {
        ...month,
        days: allDaysOriginal,
      },
    };
  }, {} as MonthsList);

  return jointMonths;
};

export const getDaysWithHighlighted = (targetDay: JointDay, BCMonth: JointMonth): string[] => {
  const isTargetDayIsRegular = targetDay.jointStatus === JointStatus.Regular;

  const days = _.reduce(BCMonth.days, (result, dayObj) => {
    const currentMonthMedian = `${BCMonth.year}-${BCMonth.month}-15`;

    const isDayRegular = dayObj.jointStatus === JointStatus.Regular;
    const isDayBeforeMedian = dayjs(dayObj.day).isBefore(currentMonthMedian);
    const isTargetDayBeforeMedian = dayjs(targetDay.day).isBefore(currentMonthMedian);
    const isDayBeforeTargetDay = dayjs(dayObj.day).isBefore(targetDay.day);

    switch (true) {
      case isTargetDayBeforeMedian && !isTargetDayIsRegular: // all days before month's start but after target day
        if (!isDayBeforeTargetDay && isDayBeforeMedian && !isDayRegular) {
          return [ ...result, dayObj.day ];
        } else {
          return result;
        }
      case isTargetDayBeforeMedian && isTargetDayIsRegular: // all days after month's start and before month's median
        if (isDayBeforeTargetDay && isDayBeforeMedian && isDayRegular) {
          return [ ...result, dayObj.day ];
        } else {
          return result;
        }
      case !isTargetDayBeforeMedian && isTargetDayIsRegular: // all days after month's median and before month's end
        if (!isDayBeforeTargetDay && !isDayBeforeMedian && isDayRegular) {
          return [ ...result, dayObj.day ];
        } else {
          return result;
        }
      case !isTargetDayBeforeMedian && !isTargetDayIsRegular: // all days after month's end but before target day
        if (isDayBeforeTargetDay && !isDayBeforeMedian && !isDayRegular) {
          return [ ...result, dayObj.day ];
        } else {
          return result;
        }
      default:
        return result;
    }
  }, [] as string[]);

  return days;
};

export const getDaysAmount = (result: { firstPeriodMonthDays: number, lastPeriodMonthDays: number }, dayObj: JointDay, BCMonth: JointMonth) => {
  switch (true) {
    case dayjs(dayObj.day).isBefore(`${BCMonth.year}-${BCMonth.month}-1`):
      return {
        ...result,
        firstPeriodMonthDays: result.firstPeriodMonthDays + 1,
      };
    case dayjs(dayObj.day).isAfter(`${BCMonth.year}-${BCMonth.month}-${dayjs(`${BCMonth.year}-${BCMonth.month}-1`).daysInMonth()}`):
      return {
        ...result,
        lastPeriodMonthDays: result.lastPeriodMonthDays + 1,
      };

    default:
      return result;
  }
};
