import { NotificationUserPreferences } from 'redux/User/types';
import { convertObjectKeysToLanguageCase } from 'helpers/utils/convertObject';

import { mapFieldsForBackend } from './helpers';
import { PersonalInfoFields } from './types';
import { api } from '../../index';

const resourceUrlUsers = '/users';

const errorObject = {
  400: {
    hidden: true,
  },
};

const savePersonalInfoFields = (newFields: PersonalInfoFields, userId: number) => {
  const fields = mapFieldsForBackend(newFields);

  return api.patch(`${resourceUrlUsers}/${userId}/profile/`, fields, { errorObject });
};

const saveUserPreferences = (newFields: NotificationUserPreferences, userId: number) => (
  api.patch(`${resourceUrlUsers}/${userId}/preferences/`, convertObjectKeysToLanguageCase(newFields, 'snakeCase'), { errorObject })
);

const saveEmployeePersonalInfoFields = (newFields: PersonalInfoFields, userId: number) => {
  const fields = mapFieldsForBackend(newFields);

  return api.patch(`${resourceUrlUsers}/${userId}/`, fields, { errorObject });
};

export default {
  savePersonalInfoFields,
  saveEmployeePersonalInfoFields,
  saveUserPreferences,
};
