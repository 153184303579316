import _ from 'lodash';

type Obj = { [key: string]: any };

const compareObjects = (object: Obj | undefined, base: Obj) => _.reduce(object, (acc, value, key) => {
  if (base && base[key] === value) {
    return acc;
  }

  return {
    ...acc,
    [key]: _.isString(value) ? _.trim(value) : value,
  };
}, {});

export default compareObjects;
