export interface NotificationsRedux {
  multipliers?: number;
  notifications?: number;
  skillsetIsOutdated?: boolean;
  hasWeeklyOvertime?: boolean;
  vacancies?: number;
  loading?: boolean;
}

export interface NotificationResponse {
  multipliers?: number;
  notifications?: number;
  skillset_is_outdated: boolean;
  has_weekly_overtime: boolean;
  vacancies?: number;
}

export enum NotificationTypes {
  updateSkillset = 'UPDATE_SKILLSET',
  closeMonth = 'CLOSE_MONTH',
}

export interface NotificationCreate {
  type: NotificationTypes;
  content?: string;
  userId: number;
}
