import React, { useState } from 'react';
import { Button, Modal, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import _ from 'lodash';
import classNames from 'classnames';

import modals from 'helpers/styles/components/modals.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import TableAvatar from 'components/TableComponents/TableAvatar';
import TableSelectionList from 'components/TableComponents/TableSelectionList';
import { grantCuratorStatusMultiply, revokeCuratorStatusMultiply, unassignUserFromPartnerMultiply } from 'api/Partners/requests';
import { PartnerDetailsOutput } from 'api/Partners/types';

import s from './styles.module.sass';
import { UserRoles, PartnerParticipantsOutputChecked } from '../../helpers/types';

interface ParticipantsSelectionPanelProps {
  partner: PartnerDetailsOutput;
  selectedRows: PartnerParticipantsOutputChecked[];
  setSelectedRows: (selectedRows: PartnerParticipantsOutputChecked[]) => void;
  getParticipants: () => void;
}

const ParticipantsSelectionPanel: React.FC<ParticipantsSelectionPanelProps> = ({ partner, setSelectedRows, selectedRows, getParticipants }) => {
  const [ isUnassigningLoading, setUnassigningLoading ] = useState(false);
  const [ isStatusChangingLoading, setStatusChangingLoading ] = useState(false);

  const onChangeCheckbox = (event: CheckboxChangeEvent, targetRow: PartnerParticipantsOutputChecked) => {
    const { userId } = targetRow.user;
    const checked = event?.target?.checked;

    const updatedSelectedRows = _.map(selectedRows, (s) => {
      if (s.user.userId === userId) {
        return { ...s, checked };
      }

      return s;
    });

    setSelectedRows(updatedSelectedRows);
  };

  const resetSelections = () => {
    setSelectedRows([]);
  };

  const handleChangeStatus = (isAnyCurator: boolean) => {
    const selectedUsers = _.map(selectedRows, row => (
      <Space className={s.statusModalUser} align="center" key={row.user.userId}>
        <TableAvatar
          url={row.user.avatarUrl}
        />
        <span>{`${row.user.forename} ${row.user.surname}`}</span>
      </Space>
    ));

    const modalContent = (
      <Space size={18} direction="vertical" style={{ width: '100%' }}>
        <span>{`You are going to ${isAnyCurator ? 'revoke' : 'grant'} curator permission ${isAnyCurator ? 'from' : 'to'} following users:`}</span>
        <Space direction="vertical" split={<div className={classNames('splitter')} />} className={s.statusModalList}>
          {selectedUsers}
        </Space>
      </Space>
    );

    const onOk = async () => {
      const userIds = _.map(selectedRows, r => r.user.userId);
      const request = isAnyCurator ? revokeCuratorStatusMultiply : grantCuratorStatusMultiply;

      setStatusChangingLoading(true);
      request(partner.partnerId, userIds)
        .then(() => {
          setSelectedRows([]);
          getParticipants();          
        })
        .finally(() => setStatusChangingLoading(false));
    };

    Modal.confirm({
      title: `${isAnyCurator ? 'Revoke' : 'Grant'} curator permissions`,
      className: modals.qsConfirmAntdModal,
      content: modalContent,
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk,
      okText: isAnyCurator ? 'Revoke' : 'Grant',
    });
  };

  const handleUnassignUsersFromPartner = () => {
    const selectedUsers = _.map(selectedRows, row => (
      <Space className={s.statusModalUser} align="center" key={row.user.userId}>
        <TableAvatar
          url={row.user.avatarUrl}
        />
        <span>{`${row.user.forename} ${row.user.surname}`}</span>
      </Space>
    ));

    const modalContent = (
      <Space size={18} direction="vertical" style={{ width: '100%' }}>
        <span>You are going to unassign following users from partner (curator permissions will also be revoked)</span>
        <Space direction="vertical" split={<div className={classNames('splitter')} />} className={s.statusModalList}>
          {selectedUsers}
        </Space>
      </Space>
    );

    const onOk = async () => {
      const userIds = _.map(selectedRows, r => r.user.userId);

      setUnassigningLoading(true);
      unassignUserFromPartnerMultiply(partner.partnerId, userIds)
        .then(() => {
          setSelectedRows([]);
          getParticipants();          
        })
        .finally(() => setUnassigningLoading(false));
    };

    Modal.confirm({
      title: 'Unassign users from partner',
      className: modals.qsConfirmAntdModal,
      content: modalContent,
      centered: true,
      closable: true,
      maskClosable: true,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      autoFocusButton: null,
      icon: null,
      width: 500,
      onOk,
      okText: 'Unassign',
    });
  };

  const changeStatusButton = () => {
    const isAnyCurator = _.some(selectedRows, partnerUser => _.includes(partnerUser.roles, UserRoles.Curator));

    return (
      <Button
        className={buttons.qsButtonPrimary}
        type="primary"
        size="large"
        loading={isStatusChangingLoading}
        onClick={() => handleChangeStatus(isAnyCurator)}
      >
        {`${isAnyCurator ? 'Revoke' : 'Grant'} curator permissions`}
      </Button>
    );
  };

  return (
    <div className={s.selectionPanel}>
      <Space>
        <Typography.Text className={s.selectionPanelCount}>
          {`${selectedRows.length} selected`}
        </Typography.Text>

        <TableSelectionList
          resetSelections={resetSelections}
          onChangeCheckbox={onChangeCheckbox}
          renderTitle={(row: PartnerParticipantsOutputChecked) => `${row.user.forename} ${row.user.surname}`}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Space>
      <Space>
        <Button
          className={buttons.qsButtonPrimary}
          type="primary"
          size="large"
          onClick={handleUnassignUsersFromPartner}
          loading={isUnassigningLoading}
        >
          Unassign from partner
        </Button>
        {changeStatusButton()}
      </Space>
    </div>
  );
};

export default ParticipantsSelectionPanel;
