/*
  PATHS
 */
export const PATH_TRACKER = '/tracker';
export const PATH_TOOLS = '/tools';
export const PATH_REPORTS = '/reports';
export const PATH_PROFILE = '/profile';
export const PATH_FIND_SPECIALISTS = '/specialists';
export const DEFAULT_PATH = '/';
export const WELCOME_PAGE = '/welcome';
export const PATH_ADMINISTRATION = '/administration';
export const PATH_PARTNER_INFO = `${PATH_ADMINISTRATION}/partners`;

/*
  SPECIFIC ROUTES
 */
export const ROUTE_SPECIALISTS = '/specialists/:tab?/:specialistRequestId?';

/*
  V1 PATHS
 */
export const PATH_VACANCIES = '/vacancies';
export const PATH_SKILLS = '/skills';
export const PATH_BUSINESS_CALENDAR = '/business-calendar';
export const PATH_HOT_PROJECTS = '/hot-projects';
/*
  TABS
 */
export const TAB_VIEW_TIME_SHEET = 'time-sheet';
export const TAB_TRACKER_TIME = 'time';
export const TAB_SKILL_TAGS = 'skill-tags';
export const TAB_ADMIN_HOUR_REPORT = 'user-hour-reports';
export const TAB_VACATION_HOUR_REPORT = 'vacation-hour-reports';
export const TAB_USERS_TIMESHEET = 'users-timesheet';
export const TAB_CURATORS_TIMESHEET = 'curated-employees';
export const TAB_PARTNERS_USERS = 'partners-and-users';
export const TAB_EMPLOYEES = 'employees';
export const TAB_PARTNERS = 'partners';
export const TAB_PARTNERS_NEW = 'partners-new';
export const TAB_LOGS = 'logs';
export const TAB_BOOKKEEPING_ITEMS = 'bookkeeping-items';
export const TAB_GLOBAL_SEARCH = 'global-search';
export const TAB_ENTITIES = 'entities';
export const TAB_BUSINESS_CALENDAR = 'business-calendar';

export const TAB_PARTNER_PARTICIPANTS = 'participants';
export const TAB_PARTNER_ACTICITIES = 'activities';
export const TAB_PARTNER_INFO = 'info';

export const TAB_HOUR_REPORT = 'activity';
export const TAB_CURATOR_REPORT = 'curator';
export const TAB_VACATION_REPORT = 'vacation';
