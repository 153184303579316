import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import _ from 'lodash';

interface MarkupEditorViewProps {
  value: string | undefined;
}

const MarkupRender: React.FC<MarkupEditorViewProps> = ({ value }) => (
  <MDEditor.Markdown
    source={value}
    rehypePlugins={[ rehypeSanitize ]}
    rehypeRewrite={(node, index, parent) => {
      if (!_.isNil(parent) && parent.type === 'element' && parent.tagName === 'a') {
        // eslint-disable-next-line no-param-reassign
        parent.properties = { ...parent.properties, target: '_blank' };
      }
    }}
  />
);

export default MarkupRender;
