import { SidebarTabs } from './types';
import { USER_TYPES } from '../constants';

const PROFILE_TABS: SidebarTabs = {
  general: {
    path: 'general',
    name: 'Personal Info',
  },
  skillset: {
    path: 'skillset',
    name: 'Skillset',
  },
};

export const EDIT_PROFILE_TABS: SidebarTabs = {
  general: {
    path: 'general',
    name: 'Personal Info',
  },
  skillset: {
    hidden: USER_TYPES.BOT,
    path: 'skillset',
    name: 'Skillset',
  },
  timesheet: {
    hidden: USER_TYPES.BOT,
    path: 'timesheet',
    name: 'Timesheet',
  },
};

export default PROFILE_TABS;
