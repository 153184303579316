import _ from 'lodash';

import { SkillTagsBody } from '../../helpers/types/Table';
import {
  BaseSkillSynonym,
  CreateSkillTagBody,
  EditSkillTagBody,
  MergeSkillsToTargetBody,
  SkillDetailedStatistic,
  SkillTagResponse,
  SkillUserPage,
  UpdateSkillTagBody,
} from './types';
import { MINIMUM_USERS_KNOWN_BY } from '../../components/SkillTags/components/SkillTagDrawer/helpers/constants';
import { api } from '../../index';

const getSkillTags = (params: SkillTagsBody): Promise<SkillTagResponse> => {
  const body = _.reduce(params, (acc, value, key) => {
    const snakeKey = _.snakeCase(key);
    return {
      ...acc,
      [snakeKey]: value,
    };
  }, {});

  return api.get<SkillTagResponse>('/skills/statistics/', body)
    .then(response => response)
    .catch(error => error);
};

const deleteSkillTag = (skillId: number) => api.delete(`/skills/${skillId}/`);

const mergeSkillsToTarget = (body: MergeSkillsToTargetBody, skillId: number) => api.post(`/skills/${skillId}/mergers/`, body);

const mergeSkillsWithNew = (body: MergeSkillsToTargetBody) => api.post('/skills/mergers/', body);

const updateSkillTag = (body: UpdateSkillTagBody, skillId: number) => api.patch(`/skills/${skillId}/`, body);

const createSkillTag = (body: CreateSkillTagBody) => api.post('/skills/', body);

const editSkillTag = (body: EditSkillTagBody, skillId: number) => api.patch(`/skills/${skillId}/`, body);

const massDeleteSkillTag = (skillTagIds: number[]) => api.delete('/skills/', {
  skill_ids: skillTagIds,
});

const massApproveSkillTag = (skillTagIds: number[], approved: boolean) => api.put('/skills/approvals/', {
  skill_ids: skillTagIds,
  approved,
});

const getSkillTagStatistic = async (skillId: number) => api.get<SkillDetailedStatistic>(`/skills/${skillId}/statistics/`);

const getSkillTagSynonyms = async (skillId: number) => api.get<BaseSkillSynonym>(`/skills/${skillId}/synonyms/`);

const getSkillTagUsers = async (skillId: number) => api.get<SkillUserPage>(`/skills/${skillId}/users/`, {
  limit: MINIMUM_USERS_KNOWN_BY,
  offset: 0,
  order_direction: 'DESC',
  order_by: 'experience',
  users_active: true,
});

const deleteSynonymTag = (synonymId: number, skillId: number) => api.delete(`/skills/${skillId}/synonyms/${synonymId}/`);

const addSynonymToTag = async (name: string, skillId: number) => api.post(`/skills/${skillId}/synonyms/`, { name });

export default {
  getSkillTags,
  deleteSkillTag,
  mergeSkillsToTarget,
  mergeSkillsWithNew,
  updateSkillTag,
  createSkillTag,
  editSkillTag,
  massDeleteSkillTag,
  massApproveSkillTag,
  getSkillTagStatistic,
  getSkillTagSynonyms,
  getSkillTagUsers,
  deleteSynonymTag,
  addSynonymToTag,
};
