import _ from 'lodash';
import React, { useRef } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames/bind';

import { CellOfGroup, GroupOfCells } from 'components/TimeSheet/helpers/types';
import PartnerMask from 'components/_shared/PartnerMask';
import useAddClassAfterMount from 'helpers/hooks/useTimeoutClass';

import GroupOfCellsContextMenu from '../GroupOfCellsContextMenu/GroupOfCellsContextMenu';
import S from './helpers/styles.module.sass';
import Cell from '../Cell/Cell';
import { CellGroupProps } from './helpers/types';
import { getTimesheetPopupContainer } from './helpers/helpers';

const cx = classNames.bind(S);

const CellGroup: React.FC<CellGroupProps> = (props) => {
  const {
    day,
    cellObj,
    isCut,
    groupOfCellsContextMenu,
    isMonthClosed,
    curatorOnlyView,
  } = props;

  const cellObjIsGroup = cellObj as GroupOfCells;

  const isCommentsExists = Boolean(_.filter(cellObj.groupCells, cell => !_.isNil(cell.comment)).length);

  const cellGroupRef = useRef<HTMLDivElement>(null);

  const isContextMenuShowed = groupOfCellsContextMenu.isVisible
    && day === groupOfCellsContextMenu.day
    && cellObj.groupCells.map(group => group.cellIndex).includes(groupOfCellsContextMenu.cellIndex);
  const isOneHourOrMore = cellObjIsGroup.groupCells.length >= 2;
  const isMounted = useAddClassAfterMount(0); // for animation after mounting

  const getRightColor = (color: string | undefined) => {
    if (!color) return '#eeeeee';

    const colorCode = color.split('#')[1];

    if (!colorCode) {
      return color;
    }

    return colorCode?.length === 3 ? `#${colorCode}${colorCode}` : `#${colorCode}`;
  };

  const renderStoredCell = (cellObj: GroupOfCells, groupCellObj: CellOfGroup) => (
    <Cell
      cellObj={{
        ...cellObj,
        cellIndex: groupCellObj.cellIndex,
      }}
      isCut={isCut}
      key={`${day}-${groupCellObj.cellIndex}-grouped`}
      day={day}
      groupStartCell={cellObj.cellIndex}
      isMonthClosed={isMonthClosed}
      curatorOnlyView={curatorOnlyView}
    />
  );

  return (
    <div
      key={`${day}`}
      ref={cellGroupRef}
      style={{ height: `calc((100% / 48) * ${cellObj?.groupCells?.length || 1})` }}
      className={cx(S.cellsGroup, {
        [S.loaded]: isMounted,
      })}
    >
      <Popover
        content={(
          <GroupOfCellsContextMenu
            groupCellsLength={cellObj.groupCells.length}
            userHourData={cellObj.userHourData}
            isCut={isCut}
            cellObj={cellObj}
            day={day}
            curatorOnlyView={curatorOnlyView}
          />
        )}
        getPopupContainer={getTimesheetPopupContainer}
        autoAdjustOverflow
        overlayClassName={S.popoverOverlay}
        style={{ width: '100%', height: '100%' }}
        placement="rightTop"
        visible={isContextMenuShowed}
        destroyTooltipOnHide
      >
        {
          _.map(cellObj.groupCells, groupCellObj => renderStoredCell(cellObj, groupCellObj))
        }
      </Popover>
      <div className={S.groupFillWrapper}>
        <div
          className={S.groupFill}
          style={{
            backgroundColor: getRightColor(cellObjIsGroup.userHourData?.partner?.color),
            borderColor: `${getRightColor(cellObjIsGroup.userHourData?.activity?.color)}80`,
          }}
        >
          {isCommentsExists && <div className={S.commentMarker} />}
          <div
            className={cx(S.partnerMaskWrapper, {
              [S.isOneHourOrMore]: isOneHourOrMore,
            })}
            style={{ height: `calc(100% / ${cellObjIsGroup.groupCells.length})` }}
          >
            <div className={S.partnerMaskInner}>
              <PartnerMask
                iconSize={24}
                mask={cellObjIsGroup.userHourData?.partner?.mask}
                iconColor={cellObjIsGroup.userHourData?.activity?.color}
                partnerId={cellObjIsGroup.userHourData?.partner?.partnerId}
                iconClassName={S.partnerMask}
                withoutWrapper
                isVacation={cellObjIsGroup.userHourData?.partner?.isVacation || false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellGroup;
