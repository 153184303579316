import _ from 'lodash';

import { SkillTagProps } from '../../../api/SkillTags/types';

export const deleteSelectedRowsBySkillId = (selectedRows: SkillTagProps[], skillId: number) => _.filter(selectedRows, row => row.skillId !== skillId);

export const updateSelectedRowsByApprove = (selectedRows: SkillTagProps[], record: SkillTagProps) => _.reduce(selectedRows, (acc, value) => {
  if (value.skillId === record.skillId) {
    return acc;
  }

  return [
    ...acc,
    value,
  ];
}, [] as SkillTagProps[]);
