import _ from 'lodash';
import { Button, Popover, Select, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { LoadingOutlined } from '@ant-design/icons';

import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { createNewRequest } from 'api/Specialists/requests';
import { EmployeeOccupationEnum } from 'helpers/constants/Occupations/types';

import { AddNewRequestProps } from './helpers/types';
import S from './helpers/styles.module.sass';

const cx = classNames.bind(S);

const AddNewRequest: React.FC<AddNewRequestProps> = ({ activity, afterCreatingNewRequest }) => {
  const [ occupation, setOccupation ] = useState<EmployeeOccupationEnum>(EmployeeOccupationEnum.FullstackEngineer);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ popoverVisible, setPopoverVisible ] = useState(false);

  const options = _.map(OCCUPATIONS, (s, key) => (
    <Select.Option key={key} value={key}>{s.name}</Select.Option>
  ));

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      setOccupation(EmployeeOccupationEnum.FullstackEngineer);
    }

    setPopoverVisible(visible);
  };

  const handleOnSave = () => {
    if (!occupation) {
      return;
    }

    const body = {
      occupation,
      activityId: activity.activityId,
    };
    setSaveLoading(true);

    return createNewRequest(body)
      .then(() => {
        afterCreatingNewRequest();
        setOccupation(EmployeeOccupationEnum.FullstackEngineer);
        setPopoverVisible(false);
      })
      .finally(() => setSaveLoading(false));
  };

  const content = (
    <Space
      direction="vertical"
      size={10}
    >
      <div>
        <Select
          showSearch
          value={occupation}
          onChange={value => setOccupation(value)}
          size="large"
          placeholder="Select category"
          className={cx(selects.qsSelect, S.createRequestSelect)}
          dropdownClassName={selects.selectDropdown}
          suffixIcon={<SelectIcon />}
          bordered={false}
          allowClear
        >
          {options}
        </Select>
      </div>

      <div className={cx('create-request_button-wrapper')}>
        <Button
          type="primary"
          loading={saveLoading}
          onClick={handleOnSave}
          disabled={_.isEmpty(occupation)}
          className={buttons.qsButtonPrimary}
        >
          Add request
        </Button>
      </div>

    </Space>
  );

  return (
    <Popover
      align={{ offset: [ -1, 0 ] }}
      overlayClassName={cx('create-request')}
      placement="bottomRight"
      content={content}
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={onVisibleChange}
      destroyTooltipOnHide
    >
      <Button
        size="large"
        className={cx(buttons.qsButton, { 'create-request_request-button--popover-visible': popoverVisible })}
      >
        <FontAwesomeIcon icon="plus-circle" />
        Request
      </Button>
    </Popover>
  );
};

export default AddNewRequest;
