import * as Actions from '../constants/TableOverlay';
import formatDate from '../utils/formatDate';
import _ from 'lodash';

let InitialState = {
  popUp: {
    show: false,
    posX: 0,
    posY: 0,
    width: 46,
    height: 15
  },
  errorModal: {
    show: false,
    /* param need for load actual data when work in 2 and more browsers */
    needToGetActualData: false,
    message: ''
  },
  closeMonthModal: {
    show: false,
    closingMonth: true
  },
  deleteModal: {
    show: false,
    data: { day: 0, time: {}, changedData: {}, amountOfHours: 0 }
  },
  editActivityModal: {
    show: false,
    showTip: true,
    onlyReplace: false,
    day: 1,
    month: 0,
    year: 0,
    partnerId: 0,
    activityId: 0,
    startTime: '00:00',
    endTime: '00:30',
    startChanged: false,
    errorType: 0,
    errorStatus: false,
    loading: false
  },
  addActivityModal: {
    show: false,
    loading: false,
    partnerId: 0,
    activityId: 0,
    startTime: '00:00',
    endTime: '00:30',
    startChanged: false,
    message: '',
    replaceWarning: false,
    showDatePicker: false,
    /* 0 - no error, 1 - wrond partner, 2 - wrong time, 3 - duplicates */
    selectedDate: formatDate(new Date()),
    requestStatus: false
  },
  addPartnerModal: {
    show: false,
    errorStatus: false,
    changedLinks: {}
  },
  showQuickTip: true,
  showUpdateHourModal: false,
};

function changeLinks(currentLinks, changedLink) {
  const { partnerId, assigned } = changedLink;
  let current = _.cloneDeep(currentLinks);
  let partnerLink = Object.keys(current).find(e => +e === partnerId);
  if(partnerLink) {
    delete current[partnerId];
  } else {
    current = Object.assign({}, current, {[partnerId]: assigned});
  }
  return current;
}

function reducer(state = InitialState, action) {
  switch (action.type) {
    case Actions.TABLE_OVERLAY_INITIATE:
      return InitialState;
    case Actions.SHOW_POPUP: {
      const { show, posX, posY, width, height } = action.payload;
      return { ...state, popUp: { ...state.popUp, show, posX, posY, width, height }};
    }
    case Actions.SHOW_POPUP_NO_POS:
      return { ...state, popUp: { ...state.popUp, show: action.payload }};
    case Actions.SHOW_DELETE_MODAL: {
      const { show, data } = action.payload;
      return { ...state, deleteModal: { ...state.deleteModal, show, data }};
    }
    case Actions.SHOW_ERROR_MODAL: {
      const { show, message, needToGetActualData } = action.payload;
      return { ...state, errorModal: { ...state.errorModal, show, message, needToGetActualData }};
    }
    case Actions.SHOW_ADD_ACTIVITY_MODAL:
      return { ...state, addActivityModal: { ...state.addActivityModal, show: action.payload, requestStatus: false }};
    case Actions.LOAD_ADD_ACTIVITY_MODAL_DATA: {
      const { partnerId, activityId, startTime, endTime, selectedDate } = action.payload;
      return { ...state, addActivityModal: { ...state.addActivityModal, partnerId, activityId, startTime, endTime, selectedDate }};
    }
    case Actions.CHANGE_ADD_ACTIVITY_MODAL_PARTNER: {
      const { partnerId, activityId } = action.payload;
      return { ...state, addActivityModal: { ...state.addActivityModal, partnerId, activityId }};
    }
    case Actions.CHANGE_ADD_ACTIVITY_MODAL_MESSAGE: {
      const { message, warning } = action.payload;
      return { ...state, addActivityModal: { ...state.addActivityModal, message, replaceWarning: warning }};
    }
    case Actions.CHANGE_DATE:
      return { ...state, addActivityModal: { ...state.addActivityModal, selectedDate: action.payload }};
    case Actions.TO_POST_TIME_SHEET_REQUEST:
      return { ...state, addActivityModal: { ...state.addActivityModal, loading: true }};
    case Actions.TO_POST_TIME_SHEET_OK:
      return { ...state, addActivityModal: { ...state.addActivityModal, requestStatus: action.payload, loading: false }};
    case Actions.TO_POST_TIME_SHEET_ERROR: {
      const { requestStatus, message } = action.payload;
      return { ...state, addActivityModal: { ...state.addActivityModal, requestStatus, message, replaceWarning: false, loading: false }};
    }
    case Actions.CHANGE_ADD_ACTIVITY_MODAL_START_TIME:
      return { ...state, addActivityModal: { ...state.addActivityModal, startTime: action.payload }};
    case Actions.CHANGE_ADD_ACTIVITY_MODAL_END_TIME:
      return { ...state, addActivityModal: { ...state.addActivityModal, endTime: action.payload }};
    case Actions.ADD_ACTIVITY_MODAL_SHOW_DATE_PICKER:
      return { ...state, addActivityModal: { ...state.addActivityModal, showDatePicker: !state.addActivityModal.showDatePicker }};
    case Actions.CHANGE_ADD_ACTIVITY_MODAL_TIME_RANGE:
      return { ...state, addActivityModal: { ...state.addActivityModal,
          endTime: action.payload.end,
          startTime: action.payload.start,
          startChanged: state.addActivityModal.startTime !== action.payload.start
        }};
    case Actions.SHOW_CLOSE_MONTH_MODAL: {
      const { show, closing } = action.payload;
      return { ...state, closeMonthModal: { ...state.closeMonthModal, show, closing }};
    }
    case Actions.SHOW_QUICK_TIP:
      return { ...state, showQuickTip: action.payload };
    case Actions.SHOW_UPDATE_HOUR_MODAL:
      return { ...state, showUpdateHourModal: action.payload };
    case Actions.SHOW_ADD_PARTNER_MODAL:
      return { ...state, addPartnerModal: {
          ...state.addPartnerModal,
          show: action.payload,
          changedLinks: {},
          errorStatus: false
        }
      };
    case Actions.ADD_PARTNER_MODAL_POST_ERROR:
      return { ...state, addPartnerModal: { ...state.addPartnerModal, errorStatus: true }};
    case Actions.ADD_PARTNER_MODAL_CHANGE_LINK:
      return { ...state,
        addPartnerModal: { ...state.addPartnerModal,
          changedLinks: changeLinks(state.addPartnerModal.changedLinks, action.payload)
        }
      };
    case Actions.SHOW_EDIT_ACTIVITY_MODAL:
      return { ...state, editActivityModal: { ...state.editActivityModal, show: action.payload, errorStatus: false, errorType: 0 }};
    case Actions.SHOW_TIP_IN_EDIT_ACTIVITY_MODAL:
      return { ...state, editActivityModal: { ...state.editActivityModal, showTip: action.payload }};
    case Actions.CHANGE_ERROR_STATUS_IN_EDIT_MODAL: {
      const { errorStatus, errorType } = action.payload;
      return { ...state,
        editActivityModal: { ...state.editActivityModal, errorStatus, errorType }
      };
    }
    case Actions.CHANGE_OVERLAY_START_TIME:
      return { ...state, editActivityModal: { ...state.editActivityModal, startTime: action.payload }};
    case Actions.CHANGE_OVERLAY_END_TIME:
      return { ...state, editActivityModal: { ...state.editActivityModal, endTime: action.payload }};
    case Actions.CHANGE_EDIT_ACTIVITY_MODAL_TIME_RANGE:
      return { ...state, editActivityModal: { ...state.editActivityModal,
          endTime: action.payload.end,
          startTime: action.payload.start,
          startChanged: state.editActivityModal.startTime !== action.payload.start
        }};
    case Actions.LOAD_EDIT_ACTIVITY_MODAL_DATA: {
      const { activityId, partnerId, startTime, endTime, onlyReplace, year, month, day } = action.payload;
      return { ...state, editActivityModal:
          { ...state.editActivityModal, activityId, partnerId, startTime, endTime, onlyReplace, year, month, day}
      };
    }
    case Actions.CHANGE_SHOW_ONLY_REPLACE_BUTTON_STATUS:
      return { ...state, editActivityModal: { ...state.editActivityModal, onlyReplace: action.payload}};
    case Actions.EDIT_ACTIVITY_MODAL_REQUEST_ERROR:
      return { ...state, editActivityModal: { ...state.editActivityModal, errorStatus: true, errorType: 4, loading: false}};
    case Actions.EDIT_ACTIVITY_MODAL_REQUEST_OK:
      return { ...state, editActivityModal: { ...state.editActivityModal, errorStatus: false, errorType: 0, show: false, loading: false}};
    case Actions.EDIT_ACTIVITY_MODAL_REQUEST_LOADING:
      return { ...state, editActivityModal: { ...state.editActivityModal, loading: true }};
    case Actions.CHANGE_EDIT_ACTIVITY_MODAL_PARTNER: {
      const { partnerId, activityId } = action.payload;
      return { ...state, editActivityModal: { ...state.editActivityModal, partnerId, activityId }};
    }
    default:
      return state;
  }
}


export default {
  InitialState,
  reducer
}
