/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import S from './helpers/styles.module.sass';

const SCROLL_END_EVENT_TIMEOUT = 250;

export type PickerItemValue = string | number;

interface MobilePickerProps {
  itemWidth: number;
  defaultValue: PickerItemValue;
  disabled?: boolean;
  activeItemClassName?: string;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
  onSelect: (value: PickerItemValue) => void;
}

interface MobilePickerSubComponents {
  Item: typeof MobilePickerItem;
}

interface MobilePickerItemProps {
  parentsProps?: any;
  value: PickerItemValue;
  disabled?: boolean;
}

type NullableElement = Element | null | undefined;

export const MobilePicker: React.FC<MobilePickerProps> & MobilePickerSubComponents = (props) => {
  const { children, itemWidth, onScroll, onSelect, defaultValue, disabled, activeItemClassName } = props;
  const pickerContainerRef = useRef(null);
  const [ selectedValue, setSelectedValue ] = useState<PickerItemValue>(0);
  const [ isScrolling, setIsScrolling ] = useState(false);

  useEffect(() => {
    if (!matchValues(defaultValue, selectedValue)) {
      scrollToItemByValue(defaultValue);
    }
  }, [ defaultValue ]);

  const scrollToItemByValue = (value: PickerItemValue) => {
    if (!pickerContainerRef.current) return;

    const container = (pickerContainerRef.current as HTMLDivElement);
    const element = container.querySelector(`[data-value='${value}']`);

    if (!element) return;

    scrollToNode(element as HTMLElement);
  };

  const scrollToNode = (element: HTMLElement) => {
    if (!pickerContainerRef.current || !element) return;

    const container = (pickerContainerRef.current as HTMLDivElement);
    const offsetLeft = _.get(element, 'offsetLeft');
    const containerWidth = container.clientWidth;
    const { clientWidth } = element;
    const left = (offsetLeft - containerWidth / 2 + clientWidth / 2);
    
    container.scrollTo({
      left: left || 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  const onItemClick = (value: PickerItemValue) => {
    if (!matchValues(value, selectedValue) && !disabled) {
      scrollToItemByValue(value);
    }
  };

  const onScrollHandler = (e: React.UIEvent<HTMLElement>) => {
    const element = findSelectedElement();
    
    if (disabled) {
      e.preventDefault();
      return;
    }

    const activeElement = getElementWithActiveClass();
    
    if (element && activeElement !== element && activeItemClassName) {
      activeElement?.classList.remove(activeItemClassName);
      element?.classList.add(activeItemClassName);
    }

    if (onScroll) onScroll(e);

    if (!isScrolling) onScrollEndDebounced(element);
  };

  const onScrollEndDebounced = _.debounce((element: NullableElement) => {
    if (element) {
      let nonDisabledElement = element;

      if ((element as HTMLDivElement).dataset.disabled === 'true') {
        nonDisabledElement = getClosestNonDisabledSibling(element, element) || element;
        scrollToNode(nonDisabledElement as HTMLElement);
        return;
      }
      
      const value = (nonDisabledElement as HTMLDivElement).dataset.value || defaultValue;

      if (!value) {
        scrollToItemByValue(selectedValue);
        return;
      }

      if (!matchValues(value, selectedValue)) {
        setValue(value);
      } else {
        scrollToItemByValue(defaultValue);
      }
    }
  }, SCROLL_END_EVENT_TIMEOUT);

  const setValue = (value: PickerItemValue) => {
    setSelectedValue(value);

    if (onSelect) onSelect(value);
  };

  const getElementWithActiveClass = () => {
    if (_.isUndefined(pickerContainerRef.current) || _.isNull(pickerContainerRef.current)) return undefined;

    const container = pickerContainerRef.current as HTMLDivElement;

    return container.querySelector(`.${activeItemClassName}`);
  };

  const findSelectedElement = (): NullableElement => {
    if (_.isUndefined(pickerContainerRef.current) || _.isNull(pickerContainerRef.current)) return undefined;

    const container = pickerContainerRef.current as HTMLDivElement;

    return _.reduce(container.children, (result, children) => {
      const containerCenter = container.clientWidth / 2;
      const elementRec = children.getBoundingClientRect();
      const elementCenter = elementRec.left + elementRec.width / 2;

      if (Math.abs(containerCenter - elementCenter) <= (elementRec.width / 2 - 5)) return children;

      if (!result) return null;

      return result;
    }, null as null | Element);
  };

  const getClosestNonDisabledSibling = (elementToLeft: NullableElement, elementToRight: NullableElement): NullableElement => {
    const getPrevSib = (element: Element) => element.previousElementSibling;
    const getNextSib = (element: Element) => element.nextElementSibling;

    const prevSib = elementToLeft && getPrevSib(elementToLeft);
    const nextSib = elementToRight && getNextSib(elementToRight);

    switch (true) {
      case prevSib && (prevSib as HTMLDivElement).dataset.disabled === 'false':
        return prevSib;
      case nextSib && (nextSib as HTMLDivElement).dataset.disabled === 'false':
        return nextSib;
      case !nextSib && !prevSib:
        return undefined;
      default:
        return getClosestNonDisabledSibling(prevSib, nextSib);
    }
  };

  const matchValues = (v1: PickerItemValue, v2: PickerItemValue) => {
    const numberV1 = typeof v1 === 'number' ? v1 : parseInt(v1, 10);
    const numberV2 = typeof v2 === 'number' ? v2 : parseInt(v2, 10);

    return numberV1 === numberV2;
  };
  
  const renderChildren = (children: React.ReactNode) => {
    if (!children) return null;
    
    const props = {
      onClick: onItemClick,
      width: itemWidth,
    };

    return React.cloneElement(
      (children as React.ReactElement),
      {
        parentsProps: props,
      },
    );
  };

  return (
    <div
      ref={pickerContainerRef}
      className={classNames(S.mobilePicker, {
        [S.disabled]: disabled,
      })}
      onScroll={onScrollHandler}
      onMouseDown={() => setIsScrolling(true)}
      onMouseUp={() => setIsScrolling(false)}
    >
      <div className={S.scrollSpace} style={{ flex: `0 0 calc(100% - ${itemWidth / 2}px)` }} />
      {React.Children.map(children, renderChildren)}
      <div className={S.scrollSpace} style={{ flex: `0 0 calc(100% - ${itemWidth / 2}px)` }} />
    </div>
  );
};

const MobilePickerItem: React.FC<MobilePickerItemProps> = ({ children, parentsProps, value, disabled }) => {
  const { onClick, width } = parentsProps;
  
  return (
    <div
      data-value={value}
      data-disabled={disabled}
      className={S.mobilePickerItem}
      onClick={() => onClick(value)}
      style={{
        flex: `0 0 ${width}px`,
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
      }}
    >
      {children}
    </div>
  );
};

MobilePicker.Item = MobilePickerItem;
