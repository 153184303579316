import classNames from 'classnames';
import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { isHex } from '../helpers';
import S from './styles.module.sass';

interface HexInputProps {
  styles?: CSSProperties;
  value: string;
  setHex: (hex: string) => void;
}

const HexInput: React.FC<HexInputProps> = ({ value, setHex, styles }) => {
  const [ cashedHex, setCashedHex ] = useState(value.slice(1, 7));
  const [ isWrongHex, setHexStatus ] = useState(false);

  useEffect(() => {
    setCashedHex(value.slice(1, 7));
  }, [ value ]);

  useEffect(() => {
    setHexStatus(!isHex(`#${cashedHex}`));
  }, [ cashedHex ]);

  const onHexChange = (hex: string) => {
    const hexWithPrefix = `#${hex}`;
    setCashedHex(hex);

    if (isHex(hexWithPrefix)) {
      setHex(hexWithPrefix);
      setHexStatus(false);
    } else {
      setHexStatus(true);
    }
  };

  return (
    <div className={S.hexField} style={styles}>
      <span className={S.hexTitle}>Hex</span>
      <div className={classNames(S.hexInputWrapper, { [S.withHexError]: isWrongHex })}>
        <div className={S.hexInputRect} style={{ background: `${value}` }} />
        <span className={S.prefix}>#</span>
        <input
          type="text"
          value={cashedHex}
          title={isWrongHex ? 'String is not hexadecimal' : 'Specify hex directly'}
          className={S.hexInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onHexChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default HexInput;
