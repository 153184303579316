/* eslint-disable import/prefer-default-export */
import { Column } from 'components/_shared/Table/helpers/types';

export const PARTICIPANTS_COLUMNS: Column[] = [
  {
    title: 'ID',
    dataIndex: 'userId',
    key: 'id',
    width: 20,
    sorter: true,
  },
  {
    title: 'Full Name',
    dataIndex: 'fullname',
    key: 'fullname',
    sorter: true,
  },
  {
    title: 'Position',
    dataIndex: 'occupation',
    key: 'occupation',
  },
  {
    title: 'Entity',
    dataIndex: 'entity',
    key: 'entity',
    width: 200,
  },
  // {
  //   title: 'Activities',
  //   dataIndex: 'activities',
  //   key: 'activities',
  //   width: 200,
  // },
  {
    title: 'Hrs/Month',
    dataIndex: 'hours',
    key: 'hours',
    width: 125,
    className: 'borderless',
    sorter: true,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    width: 30,
    className: 'borderless action-column',
  },
];
