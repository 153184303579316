import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useParams } from 'react-router';
import _ from 'lodash';
import classnames from 'classnames';

import PartnerMask from 'components/_shared/PartnerMask';
import { SelectedHour } from 'components/TimeSheet/helpers/types';
import { useOnClickOutside } from 'helpers/hooks/useOnClickOutside';
import { DispatchType, Redux } from 'helpers/types/_common';
import { addUserHourAction, initiateContextMenus, closeSelectionContextMenu, deleteUserHourAction, dropTimeSelecting, openDeleteUserHoursModal, setReplaceDrawer, replaceUserHourAction as replaceAction, replaceAndFillUserHourAction as replaceAndFillAction } from 'redux/TimeSheet/action';
import { ContextMenuType, DeleteUserHoursModalProps, HoursDrawerMode } from 'redux/TimeSheet/types';
import KeyCodes from 'helpers/constants/_common/KeyCodes';
import withRightPlural from 'helpers/utils/withRightPlural';
import getFromStore from 'helpers/utils/getFromStore';
import { BaseActivityOutput, BasePartnerOutput, UserPinnedActivityOutput } from 'api/Partners/types';
import { HoursDataToAdd } from 'api/TimeSheet/types';
import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';

import S from './helpers/styles.module.sass';
import { SelectionContextMenuProps, ConfirmModalData } from './helpers/types';
import { UiDrawMode } from '../../../../../../../../helpers/constants/_common/constants';
import { getSelectionHoursStat } from '../../helpers/helpers';
import ChooseHoursActionModal from '../ChooseHoursActionModal/ChooseHoursActionModal';

const cx = classNames.bind(S);

const confirmModalInitData: ConfirmModalData = {
  isVisible: false,
  selectedHoursAmount: 0,
  emptyHoursAmount: 0,
  userId: 0,
  data: {
    day: '',
    startCellIndex: 1,
    endCellIndex: 1,
    activityId: 1,
  },
  onOk: () => {},
};

const SelectionContextMenu: React.FC<SelectionContextMenuProps> = ({ curatorOnlyView }) => {
  const selectionPopoverRef = useRef<HTMLDivElement>(null);

  const { userId: editUserId } = useParams<{ userId: string }>();

  const { selection } = useSelector((state: Redux) => state.timeSheet);
  const { activities, selectedActivity } = useSelector((state: Redux) => state.timeSheet);
  const { isVisible, selectedHours } = useSelector((state: Redux) => state.timeSheet.contextMenus[ContextMenuType.Selection]);
  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);
  const { login: user } = useSelector((state: Redux) => state);

  const selectedCellIds = _.keys(selection.selectedCells);
  const { day: startSelectionDay, cellIndex: startCellIndex } = getDateAndCellIndexByTime(selectedCellIds[0]) || 0;
  const { cellIndex: endCellIndex } = getDateAndCellIndexByTime(selectedCellIds[selectedCellIds.length - 1]);

  const [ isLoading, setLoading ] = useState(false);
  const [ loadingActivityId, setLoadingActivityId ] = useState<number | null>(null);
  const [ storedSelectedActivity, setSelectedActivity ] = useState<UserPinnedActivityOutput | null>(null);
  const [ confirmModalData, setConfirmModalData ] = useState<ConfirmModalData>(confirmModalInitData);

  const dispatch: DispatchType = useDispatch();

  const { total: totalCellAmount, filled: filledCellAmount } = getSelectionHoursStat();

  const hoursLabelPlural = withRightPlural('hour', totalCellAmount);

  const isEmptySelectedCellsExists = filledCellAmount !== totalCellAmount;
  const isDeletingConfirmModalNeeded = user.preferences.askConfirmationOnDeleteHours;
  const isSelectedHoursExists = filledCellAmount !== 0;
  const isSelectedSkillExists = (user.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL) ? Boolean(storedSelectedActivity) : Boolean(selectedActivity);

  const keyUpListener = (e: KeyboardEvent) => {
    if (e.keyCode === KeyCodes.DELETE) {
      deleteUserHoursWithRightWay();
    }

    if (e.key === 'Escape') {
      dispatch(dropTimeSelecting());
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', keyUpListener);
    setSelectedActivity(getFromStore('timeSheet.selectedActivity'));

    return () => { document.removeEventListener('keyup', keyUpListener); };
  }, []);

  useOnClickOutside(selectionPopoverRef, () => {
    const deleteUserHoursModal = getFromStore<DeleteUserHoursModalProps>('timeSheet.deleteUserHoursModal');

    if (!deleteUserHoursModal.isVisible && !confirmModalData.isVisible) dispatch(closeSelectionContextMenu());

    if (isVisible && !deleteUserHoursModal.isVisible && !confirmModalData.isVisible) {
      dispatch(dropTimeSelecting());
    }
  });

  if (!selectedHours || _.isNull(startSelectionDay) || _.isNull(startCellIndex) || _.isNull(endCellIndex)) return null;

  // handlers

  const deleteUserHours = () => {
    setLoading(true);

    dispatch(deleteUserHourAction(startSelectionDay, startCellIndex, endCellIndex, editUserId))
      .catch(() => {
        // isLoading = false only at catch case because if request resolved -- component unmounting will be called
        setLoading(false);
      });
  };

  const deleteUserHoursWithConfirmation = () => {
    dispatch(openDeleteUserHoursModal({
      selectionDay: startSelectionDay,
      startCellIndex,
      endCellIndex,
    }));
  };

  const deleteUserHoursWithRightWay = isDeletingConfirmModalNeeded ? deleteUserHoursWithConfirmation : deleteUserHours;

  const openHoursDrawer = (mode: HoursDrawerMode) => {
    dispatch(setReplaceDrawer({ isVisible: true, mode, data: { selectedHours, startCellIndex, endCellIndex, selectionDay: startSelectionDay } }));
    dispatch(closeSelectionContextMenu());
  };

  const addUserHours = (activityId: number) => {
    if (_.isUndefined(activityId)) return;

    setLoadingActivityId(activityId);

    const emptyCellsAmount = totalCellAmount - filledCellAmount;
    const doReplaceAction = emptyCellsAmount === 0 ? replaceAction : replaceAndFillAction;
    const userId = editUserId || user.userId.toString();

    if (!startSelectionDay) return;

    const data: HoursDataToAdd = { activityId, startCellIndex, endCellIndex, day: startSelectionDay };
    const isCtrlMode = user.preferences.webUiDrawingMode === UiDrawMode.MOUSE_AND_CTRL;

    if (!isSelectedHoursExists) {
      return dispatch(addUserHourAction(userId, data))
        .then(() => {
          dispatch(dropTimeSelecting());
          dispatch(initiateContextMenus());
        })
        .catch(() => {
          setLoadingActivityId(null);
        });
    }

    if (isCtrlMode && !_.isUndefined(selectedActivity)) {
      dispatch(doReplaceAction(userId, data))
        .then(() => {
          dispatch(dropTimeSelecting());
          dispatch(initiateContextMenus());
        })
        .catch(() => {
          setLoadingActivityId(null);
        });
    } else { // open confirm modal
      setConfirmModalData({
        isVisible: true,
        selectedHoursAmount: filledCellAmount,
        emptyHoursAmount: emptyCellsAmount,
        userId: parseInt(editUserId, 10) || user.userId,
        data: {
          startCellIndex,
          endCellIndex,
          activityId,
          day: startSelectionDay,
        },
        onOk: () => {
          dispatch(dropTimeSelecting());
          dispatch(initiateContextMenus());
        },
      });
    }
  };

  // renderers

  const renderActivity = (activity: BaseActivityOutput, partner: BasePartnerOutput) => (
    <div className={S.groupPopoverHeader}>
      <div className={S.partnerMaskWrapper}>
        <div
          className={S.partnerMask}
          style={{
            borderColor: activity?.color,
            backgroundColor: partner?.color,
          }}
        >
          <PartnerMask
            withoutWrapper
            mask={partner?.mask}
            iconColor={activity?.color}
            partnerId={partner?.partnerId}
            isVacation={partner?.isVacation}
          />
        </div>
      </div>
      <span className={S.partnerAndActivityName}>{`${partner?.name}: ${activity?.name}`}</span>
    </div>
  );

  const renderActivityInfo = (selectedHour: SelectedHour) => {
    const { partner, activity } = selectedHour.usersHourData;

    if (!partner || !activity) return null;

    return (
      <div
        key={`${partner?.name}-${activity?.name}`}
        className={S.activityInfo}
      >
        {renderActivity(activity, partner)}
        <div className={cx(S.groupPopoverHoursSpent, S.withBottomLine)}>
          <span className={S.hoursLabel}>{`${totalCellAmount} ${hoursLabelPlural} selected`}</span>
        </div>
      </div>
    );
  };

  const renderSumInfo = () => (
    <div className={S.activityInfo}>
      <div className={cx(S.groupPopoverHoursSpent, S.withBottomLine)}>
        <span className={S.hoursLabel}>{`${totalCellAmount} ${hoursLabelPlural} selected`}</span>
      </div>
    </div>
  );

  const renderInfo = () => {
    if (selectedHours.length === 1) {
      return renderActivityInfo(selectedHours[0]);
    } else {
      return renderSumInfo();
    }
  };

  const renderPinnedActivitiesList = () => _.map(activities.slice(0, 5), (activityObj) => {
    const { partner } = activityObj.activity;
    const activity: BaseActivityOutput = {
      activityId: activityObj.activity.activityId,
      active: activityObj.activity.active,
      name: activityObj.activity.name,
      color: activityObj.activity.color,
    };
    return (
      <Button
        type="link"
        key={activity.activityId}
        loading={activity.activityId === loadingActivityId}
        disabled={!_.isNull(loadingActivityId)}
        onClick={() => addUserHours(activity.activityId)}
        className={cx(S.pinnedActivity, S.popoverButton)}
      >
        {renderActivity(activity, partner)}
      </Button>
    );
  });

  const renderPinnedActivities = () => {
    const forNotSelectedActivity = !isEmptySelectedCellsExists ? HoursDrawerMode.Replace : HoursDrawerMode.ReplaceAndFill;
    const drawerType = !isSelectedHoursExists ? HoursDrawerMode.Add : forNotSelectedActivity;

    if (curatorOnlyView) return null;

    const isEmptyList = _.isEmpty(activities);
    const isMoreThenFive = activities.length > 5;
    
    return (
      <div className={cx(S.activities, { [S.withoutFooter]: isSelectedHoursExists })}>
        <span className={S.activitiesLabel}>Add activity</span>
        {isEmptyList && (<span className={S.emptyListLabel}>No current activities.</span>)}
        {renderPinnedActivitiesList()}
        {(isEmptyList || isMoreThenFive) && (
          <Button
            size="small"
            onClick={() => openHoursDrawer(drawerType)}
            className={S.showMoreButton}
          >
            Show more...
          </Button>
        )}
      </div>
    );
  };

  const renderFooter = () => (!isMonthClosed && isSelectedHoursExists && !curatorOnlyView) && (
    <div className={classnames(S.groupPopoverFooter)}>
      <span className={S.groupPopoverFooterLabel}>Actions</span>
      <div className={S.popoverActionButtons}>
        {(isSelectedSkillExists && isEmptySelectedCellsExists) && (
          <Button
            className={cx(S.popoverButton, S.withBottomLine)}
            onClick={() => openHoursDrawer(HoursDrawerMode.ReplaceAndFill)}
            title="Replace existed activities and fill empty cells"
          >
            Replace & add
          </Button>
        )}
        {!selectedActivity && (
          <Button
            className={cx(S.popoverButton, S.withBottomLine)}
            onClick={() => openHoursDrawer(HoursDrawerMode.Replace)}
          >
            Replace activity
          </Button>
        )}
        <Button
          onClick={deleteUserHoursWithRightWay}
          loading={isLoading}
          className={S.popoverButton}
        >
          Delete
        </Button>
      </div>
    </div>
  );

  return (
    <div
      ref={selectionPopoverRef}
      id="selection-context-menu"
      className={S.groupPopover}
    >
      {renderInfo()}
      {renderFooter()}
      {renderPinnedActivities()}
      <ChooseHoursActionModal
        {...confirmModalData}
        onClose={() => {
          setConfirmModalData(confirmModalInitData);
          dispatch(closeSelectionContextMenu());
          dispatch(dropTimeSelecting());
        }}
      />
    </div>
  );
};

export default SelectionContextMenu;
