import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPartnersUsersTable } from 'redux/PartnersUsers/actions';

import s from './styles.module.sass';
import PartnersUsersSettings from './PartnersUsersSettings/PartnersUsersSettings';
import PartnersUsersTable from './PartnersUsersTable/PartnersUsersTable';
import { GetPartnersUsersBody, PartnersUsersFiltersState, PartnerUserOutputChecked } from './types';
import { TABLE_NAMES } from '../../helpers/constants/Tables/tableList';
import { DispatchType } from '../../helpers/types/_common';
import { Meta, Sorter } from '../../api/_request/types';

const PartnersUsers = () => {
  const dispatch: DispatchType = useDispatch();

  const [ filters, setFilters ] = useState<PartnersUsersFiltersState>({ active: 'ALL', partnerActive: true, userActive: true });
  const [ selectedRows, setSelectedRows ] = useState<PartnerUserOutputChecked[]>([]);

  const getPartnersData = (pagination: Meta, sorter?: Sorter) => {
    const body: GetPartnersUsersBody = {
      ...filters,
      ...pagination,
      ...sorter,
    };

    return dispatch(getPartnersUsersTable(body, TABLE_NAMES.PARTNERS_USERS));
  };

  return (
    <div className={s.partnersUsers}>
      <PartnersUsersSettings
        filters={filters}
        setFilters={setFilters}
      />

      <PartnersUsersTable
        getPartnersData={getPartnersData}
        filters={filters}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default PartnersUsers;
